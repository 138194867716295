export const footer = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 271,
  w: 1080,
  h: 1080,
  nm: 'Footer-Container',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'Trifecta – Small',
      fr: 60,
      layers: [
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'SHAPES BLEND 1 - Blends',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [720, 440, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.125, 136.062],
                                  [-395.812, -367.75],
                                  [-503.749, -80.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.312, 136.125],
                                  [-395.562, -367.5],
                                  [-503.374, -80.188],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.562, 136.187],
                                  [-395.25, -367.062],
                                  [-502.937, -80.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.937, 136.312],
                                  [-394.75, -366.5],
                                  [-502.249, -80.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.375, 136.5],
                                  [-394.125, -365.75],
                                  [-501.374, -80.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.937, 136.687],
                                  [-393.437, -364.875],
                                  [-500.374, -81.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-218.625, 136.937],
                                  [-392.5, -363.75],
                                  [-499.124, -81.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.375, 137.25],
                                  [-391.437, -362.5],
                                  [-497.624, -81.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.312, 137.562],
                                  [-390.187, -361],
                                  [-495.874, -82.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.437, 137.937],
                                  [-388.75, -359.187],
                                  [-493.812, -82.938],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.687, 138.437],
                                  [-387.062, -357.187],
                                  [-491.437, -83.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.125, 138.937],
                                  [-385.062, -354.812],
                                  [-488.687, -84.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-225.812, 139.562],
                                  [-382.812, -352.062],
                                  [-485.562, -85.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.75, 140.25],
                                  [-380.25, -348.937],
                                  [-481.937, -86.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-229.937, 141],
                                  [-377.312, -345.375],
                                  [-477.749, -87.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-232.5, 141.937],
                                  [-373.875, -341.312],
                                  [-472.999, -88.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-235.437, 143],
                                  [-370, -336.562],
                                  [-467.499, -90.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-238.75, 144.187],
                                  [-365.5, -331.125],
                                  [-461.187, -92.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.625, 145.562],
                                  [-360.312, -324.812],
                                  [-453.937, -94.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.125, 147.187],
                                  [-354.25, -317.562],
                                  [-445.437, -96.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-252.375, 149.062],
                                  [-347.312, -309.125],
                                  [-435.687, -99.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-258.375, 151.25],
                                  [-339.187, -299.375],
                                  [-424.312, -102.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.312, 153.75],
                                  [-329.875, -288.062],
                                  [-411.25, -106.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-273.187, 156.562],
                                  [-319.375, -275.312],
                                  [-396.437, -110.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-281.875, 159.687],
                                  [-307.687, -261.25],
                                  [-380.125, -115.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-291.062, 163],
                                  [-295.437, -246.375],
                                  [-362.875, -120.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.312, 166.375],
                                  [-283, -231.437],
                                  [-345.5, -125.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-309.187, 169.562],
                                  [-271.125, -217],
                                  [-328.75, -130.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-317.5, 172.562],
                                  [-260, -203.562],
                                  [-313.187, -134.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.062, 175.25],
                                  [-249.812, -191.25],
                                  [-298.937, -138.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-331.937, 177.75],
                                  [-240.625, -180.125],
                                  [-286, -142.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-338.187, 180],
                                  [-232.312, -170.062],
                                  [-274.312, -146],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-343.812, 182],
                                  [-224.75, -161],
                                  [-263.75, -149],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-348.875, 183.812],
                                  [-217.937, -152.687],
                                  [-254.187, -151.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-353.562, 185.5],
                                  [-211.687, -145.187],
                                  [-245.437, -154.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.812, 187.062],
                                  [-206, -138.25],
                                  [-237.437, -156.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-361.687, 188.437],
                                  [-200.75, -131.937],
                                  [-230.062, -158.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-365.312, 189.75],
                                  [-195.937, -126.125],
                                  [-223.312, -160.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-368.625, 190.937],
                                  [-191.437, -120.687],
                                  [-217, -162.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.75, 192.062],
                                  [-187.312, -115.687],
                                  [-211.187, -164.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-374.625, 193.125],
                                  [-183.437, -111.062],
                                  [-205.812, -165.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-377.312, 194.062],
                                  [-179.875, -106.687],
                                  [-200.75, -167.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-379.812, 194.937],
                                  [-176.5, -102.625],
                                  [-196.062, -168.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-382.125, 195.812],
                                  [-173.375, -98.875],
                                  [-191.625, -169.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-384.312, 196.625],
                                  [-170.437, -95.312],
                                  [-187.5, -170.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.375, 197.312],
                                  [-167.687, -91.937],
                                  [-183.625, -172],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-388.312, 198.062],
                                  [-165.062, -88.812],
                                  [-180, -173.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-390.125, 198.687],
                                  [-162.625, -85.875],
                                  [-176.562, -174.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-391.875, 199.312],
                                  [-160.312, -83.062],
                                  [-173.375, -175],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-393.5, 199.875],
                                  [-158.187, -80.5],
                                  [-170.312, -175.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-395, 200.437],
                                  [-156.125, -78],
                                  [-167.437, -176.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-396.437, 200.937],
                                  [-154.187, -75.687],
                                  [-164.75, -177.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-397.812, 201.437],
                                  [-152.375, -73.5],
                                  [-162.187, -178.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-399.062, 201.937],
                                  [-150.687, -71.375],
                                  [-159.812, -178.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-400.312, 202.375],
                                  [-149.062, -69.437],
                                  [-157.5, -179.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-401.437, 202.75],
                                  [-147.5, -67.562],
                                  [-155.375, -180.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-402.5, 203.125],
                                  [-146.062, -65.812],
                                  [-153.312, -180.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-403.562, 203.5],
                                  [-144.687, -64.187],
                                  [-151.437, -181.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-404.5, 203.875],
                                  [-143.437, -62.625],
                                  [-149.625, -181.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-405.437, 204.187],
                                  [-142.187, -61.187],
                                  [-147.937, -182.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-406.25, 204.5],
                                  [-141.062, -59.75],
                                  [-146.312, -182.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.062, 204.812],
                                  [-139.937, -58.437],
                                  [-144.812, -183.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.812, 205.062],
                                  [-138.937, -57.25],
                                  [-143.375, -183.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-408.562, 205.312],
                                  [-138, -56.062],
                                  [-142, -184],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-409.25, 205.562],
                                  [-137.062, -55],
                                  [-140.75, -184.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-409.875, 205.812],
                                  [-136.25, -53.937],
                                  [-139.562, -184.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-410.437, 206],
                                  [-135.437, -53],
                                  [-138.437, -185],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-411, 206.187],
                                  [-134.687, -52.125],
                                  [-137.375, -185.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-411.5, 206.375],
                                  [-134, -51.25],
                                  [-136.437, -185.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-412, 206.562],
                                  [-133.375, -50.5],
                                  [-135.5, -185.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-412.437, 206.75],
                                  [-132.75, -49.75],
                                  [-134.687, -186.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-412.875, 206.875],
                                  [-132.187, -49.062],
                                  [-133.875, -186.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-413.25, 207],
                                  [-131.687, -48.437],
                                  [-133.125, -186.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-413.625, 207.125],
                                  [-131.187, -47.875],
                                  [-132.5, -186.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-413.937, 207.25],
                                  [-130.75, -47.312],
                                  [-131.875, -186.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-414.25, 207.375],
                                  [-130.312, -46.812],
                                  [-131.312, -187.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-414.5, 207.5],
                                  [-130, -46.375],
                                  [-130.75, -187.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-414.75, 207.562],
                                  [-129.625, -46],
                                  [-130.312, -187.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415, 207.625],
                                  [-129.375, -45.625],
                                  [-129.875, -187.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.187, 207.687],
                                  [-129.062, -45.312],
                                  [-129.5, -187.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.375, 207.75],
                                  [-128.812, -45],
                                  [-129.187, -187.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.5, 207.812],
                                  [-128.625, -44.75],
                                  [-128.875, -187.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.625, 207.875],
                                  [-128.437, -44.562],
                                  [-128.625, -187.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.75, 207.937],
                                  [-128.312, -44.375],
                                  [-128.437, -187.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.875, 207.937],
                                  [-128.187, -44.25],
                                  [-128.312, -187.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.937, 208],
                                  [-128.125, -44.125],
                                  [-128.187, -187.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.937, 208],
                                  [-128.062, -44.062],
                                  [-128.062, -188],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-416, 208],
                                  [-128, -44],
                                  [-128, -188],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-416, 208],
                                  [-128, -44],
                                  [-128, -188],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.937, 208],
                                  [-127.937, -44.062],
                                  [-127.875, -188],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.812, 208.062],
                                  [-127.75, -44.187],
                                  [-127.562, -187.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.562, 208.187],
                                  [-127.437, -44.375],
                                  [-127, -187.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.187, 208.312],
                                  [-126.937, -44.75],
                                  [-126.25, -187.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-414.687, 208.5],
                                  [-126.312, -45.187],
                                  [-125.125, -187.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-414, 208.75],
                                  [-125.5, -45.75],
                                  [-123.75, -186.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-413.187, 209.062],
                                  [-124.5, -46.437],
                                  [-122.062, -186.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-412.25, 209.437],
                                  [-123.312, -47.312],
                                  [-120, -185.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-411.125, 209.812],
                                  [-121.875, -48.312],
                                  [-117.625, -184.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-409.75, 210.312],
                                  [-120.187, -49.437],
                                  [-114.75, -184.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-408.187, 210.937],
                                  [-118.25, -50.812],
                                  [-111.375, -183.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-406.375, 211.625],
                                  [-115.937, -52.437],
                                  [-107.5, -182],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-404.25, 212.375],
                                  [-113.312, -54.25],
                                  [-103.062, -180.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-401.875, 213.312],
                                  [-110.312, -56.375],
                                  [-97.938, -179.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-399.062, 214.375],
                                  [-106.875, -58.812],
                                  [-92.062, -177.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-395.875, 215.562],
                                  [-102.875, -61.562],
                                  [-85.312, -175.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-392.25, 216.875],
                                  [-98.312, -64.75],
                                  [-77.562, -173.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-388.062, 218.5],
                                  [-93.062, -68.437],
                                  [-68.625, -170.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-383.187, 220.312],
                                  [-87, -72.687],
                                  [-58.312, -167.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-377.625, 222.375],
                                  [-80.062, -77.562],
                                  [-46.438, -164],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.187, 224.812],
                                  [-71.937, -83.25],
                                  [-32.75, -160],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-363.687, 227.625],
                                  [-62.562, -89.812],
                                  [-16.813, -155.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-355, 230.875],
                                  [-51.687, -97.375],
                                  [1.687, -149.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-345, 234.625],
                                  [-39.187, -106.125],
                                  [22.937, -143.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-333.625, 238.875],
                                  [-25.062, -116.062],
                                  [47.062, -136.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-321.125, 243.562],
                                  [-9.437, -127],
                                  [73.625, -128.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-307.937, 248.562],
                                  [7.125, -138.562],
                                  [101.687, -120.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.625, 253.5],
                                  [23.75, -150.25],
                                  [130, -112.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-281.812, 258.312],
                                  [39.75, -161.437],
                                  [157.187, -104.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-269.812, 262.812],
                                  [54.688, -171.875],
                                  [182.625, -96.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-258.937, 266.937],
                                  [68.375, -181.437],
                                  [205.812, -89.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249, 270.625],
                                  [80.75, -190.125],
                                  [226.812, -83.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-240.063, 274],
                                  [91.875, -197.937],
                                  [245.812, -78.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-232, 277],
                                  [102, -205],
                                  [263.062, -73],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.625, 279.75],
                                  [111.187, -211.437],
                                  [278.625, -68.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.938, 282.25],
                                  [119.562, -217.312],
                                  [292.875, -64.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.813, 284.562],
                                  [127.25, -222.687],
                                  [305.937, -60.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.188, 286.687],
                                  [134.312, -227.625],
                                  [317.875, -56.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201, 288.625],
                                  [140.75, -232.125],
                                  [328.937, -53.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.188, 290.437],
                                  [146.75, -236.312],
                                  [339.125, -50.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.75, 292.125],
                                  [152.312, -240.25],
                                  [348.562, -47.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.563, 293.625],
                                  [157.5, -243.875],
                                  [357.375, -45.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.75, 295.125],
                                  [162.312, -247.25],
                                  [365.562, -42.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.125, 296.437],
                                  [166.812, -250.375],
                                  [373.25, -40.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.75, 297.687],
                                  [171.062, -253.312],
                                  [380.375, -38.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.625, 298.875],
                                  [175, -256.125],
                                  [387.125, -36.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.625, 300],
                                  [178.687, -258.687],
                                  [393.437, -34.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.813, 301.062],
                                  [182.187, -261.125],
                                  [399.312, -32.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.188, 302.062],
                                  [185.5, -263.437],
                                  [404.937, -31.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.75, 303],
                                  [188.562, -265.625],
                                  [410.187, -29.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.438, 303.812],
                                  [191.5, -267.625],
                                  [415.125, -28.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.25, 304.687],
                                  [194.25, -269.562],
                                  [419.75, -26.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.125, 305.437],
                                  [196.812, -271.375],
                                  [424.187, -25.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.188, 306.187],
                                  [199.25, -273.062],
                                  [428.312, -24.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.375, 306.875],
                                  [201.562, -274.687],
                                  [432.25, -23.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.625, 307.5],
                                  [203.75, -276.187],
                                  [435.937, -22.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.938, 308.125],
                                  [205.812, -277.625],
                                  [439.437, -21.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.438, 308.75],
                                  [207.75, -279],
                                  [442.75, -20.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.938, 309.25],
                                  [209.562, -280.312],
                                  [445.875, -19.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.563, 309.812],
                                  [211.312, -281.5],
                                  [448.812, -18.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.25, 310.25],
                                  [212.937, -282.625],
                                  [451.562, -17.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-142, 310.75],
                                  [214.5, -283.75],
                                  [454.187, -16.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.875, 311.187],
                                  [215.937, -284.75],
                                  [456.687, -16.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.75, 311.562],
                                  [217.312, -285.687],
                                  [459, -15.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.75, 312],
                                  [218.562, -286.625],
                                  [461.187, -14.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.75, 312.312],
                                  [219.812, -287.437],
                                  [463.25, -14.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-136.875, 312.687],
                                  [220.937, -288.25],
                                  [465.187, -13.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-136, 313],
                                  [222, -289],
                                  [467, -13],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.188, 313.312],
                                  [223, -289.687],
                                  [468.687, -12.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.438, 313.562],
                                  [223.937, -290.375],
                                  [470.312, -12],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.75, 313.812],
                                  [224.812, -290.937],
                                  [471.75, -11.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.125, 314.062],
                                  [225.625, -291.562],
                                  [473.187, -11.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.5, 314.312],
                                  [226.375, -292.062],
                                  [474.437, -10.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.938, 314.5],
                                  [227.062, -292.562],
                                  [475.625, -10.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.438, 314.687],
                                  [227.687, -293],
                                  [476.75, -10.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.938, 314.875],
                                  [228.312, -293.437],
                                  [477.75, -9.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.5, 315.062],
                                  [228.875, -293.812],
                                  [478.625, -9.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.125, 315.187],
                                  [229.375, -294.125],
                                  [479.5, -9.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.75, 315.312],
                                  [229.812, -294.437],
                                  [480.25, -9.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.438, 315.437],
                                  [230.187, -294.75],
                                  [480.937, -8.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.188, 315.562],
                                  [230.562, -295],
                                  [481.562, -8.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.875, 315.687],
                                  [230.875, -295.187],
                                  [482.062, -8.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.688, 315.75],
                                  [231.125, -295.375],
                                  [482.562, -8.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.5, 315.812],
                                  [231.375, -295.562],
                                  [482.937, -8.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.375, 315.875],
                                  [231.562, -295.687],
                                  [483.25, -8.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.188, 315.937],
                                  [231.75, -295.812],
                                  [483.562, -8.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.125, 315.937],
                                  [231.875, -295.875],
                                  [483.75, -8.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.063, 315.999],
                                  [231.937, -295.937],
                                  [483.875, -8.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128, 315.999],
                                  [232, -296],
                                  [484, -8],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128, 315.999],
                                  [231.875, -296],
                                  [483.812, -8],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.063, 315.874],
                                  [231.562, -296.062],
                                  [483.312, -8.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.125, 315.687],
                                  [231, -296.125],
                                  [482.437, -8.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.25, 315.5],
                                  [230.187, -296.187],
                                  [481.125, -8.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.438, 315.187],
                                  [229.062, -296.312],
                                  [479.375, -8.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.625, 314.75],
                                  [227.687, -296.5],
                                  [477.187, -8.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.875, 314.25],
                                  [225.937, -296.687],
                                  [474.437, -8.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.125, 313.625],
                                  [223.812, -296.937],
                                  [471.125, -8.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.5, 312.937],
                                  [221.312, -297.25],
                                  [467.187, -9.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.938, 312.125],
                                  [218.375, -297.562],
                                  [462.562, -9.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.375, 311.125],
                                  [214.937, -297.937],
                                  [457.187, -9.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.938, 310],
                                  [211, -298.437],
                                  [450.937, -10.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.563, 308.687],
                                  [206.437, -298.937],
                                  [443.75, -10.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.313, 307.187],
                                  [201.125, -299.562],
                                  [435.5, -11.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.188, 305.437],
                                  [195.125, -300.25],
                                  [425.937, -12.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.125, 303.437],
                                  [188.187, -301],
                                  [415.062, -13],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.25, 301.187],
                                  [180.187, -301.937],
                                  [402.5, -13.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-136.563, 298.562],
                                  [171.062, -303],
                                  [388.125, -15],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138, 295.5],
                                  [160.5, -304.187],
                                  [371.562, -16.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.75, 292],
                                  [148.312, -305.562],
                                  [352.375, -17.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.688, 288],
                                  [134.25, -307.187],
                                  [330.187, -19.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144, 283.312],
                                  [117.875, -309.062],
                                  [304.437, -21.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.625, 277.875],
                                  [98.937, -311.25],
                                  [274.625, -23.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.688, 271.625],
                                  [77.125, -313.75],
                                  [240.312, -25.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.188, 264.5],
                                  [52.375, -316.562],
                                  [201.437, -28.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157, 256.687],
                                  [25.125, -319.687],
                                  [158.5, -31.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161, 248.437],
                                  [-3.688, -323],
                                  [113.188, -35],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.125, 240.125],
                                  [-32.75, -326.375],
                                  [67.563, -38.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169, 232.125],
                                  [-60.687, -329.562],
                                  [23.563, -41.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.688, 224.625],
                                  [-86.687, -332.562],
                                  [-17.437, -44.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176, 217.812],
                                  [-110.5, -335.25],
                                  [-54.875, -47.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179, 211.625],
                                  [-132.125, -337.75],
                                  [-88.812, -49.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.75, 206.062],
                                  [-151.625, -340],
                                  [-119.5, -52],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.25, 201],
                                  [-169.25, -342],
                                  [-147.25, -54],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.5, 196.375],
                                  [-185.312, -343.812],
                                  [-172.5, -55.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.5, 192.187],
                                  [-199.875, -345.5],
                                  [-195.5, -57.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.375, 188.375],
                                  [-213.25, -347.062],
                                  [-216.5, -59.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.125, 184.875],
                                  [-225.562, -348.437],
                                  [-235.812, -60.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.688, 181.625],
                                  [-236.875, -349.75],
                                  [-253.625, -61.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.188, 178.625],
                                  [-247.312, -350.937],
                                  [-270.125, -62.938],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.5, 175.812],
                                  [-257.062, -352.062],
                                  [-285.375, -64.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.812, 173.25],
                                  [-266.062, -353.125],
                                  [-299.562, -65.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199, 170.812],
                                  [-274.5, -354.062],
                                  [-312.812, -66.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.062, 168.562],
                                  [-282.312, -354.937],
                                  [-325.187, -66.938],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.125, 166.5],
                                  [-289.687, -355.812],
                                  [-336.75, -67.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.062, 164.5],
                                  [-296.562, -356.625],
                                  [-347.562, -68.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203, 162.625],
                                  [-303.062, -357.312],
                                  [-357.75, -69.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.812, 160.875],
                                  [-309.125, -358.062],
                                  [-367.312, -70.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.625, 159.25],
                                  [-314.875, -358.687],
                                  [-376.312, -70.688],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.375, 157.687],
                                  [-320.25, -359.312],
                                  [-384.812, -71.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.125, 156.25],
                                  [-325.312, -359.875],
                                  [-392.812, -71.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.75, 154.875],
                                  [-330.125, -360.437],
                                  [-400.312, -72.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.375, 153.625],
                                  [-334.625, -360.937],
                                  [-407.437, -72.938],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208, 152.375],
                                  [-338.875, -361.437],
                                  [-414.125, -73.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.562, 151.25],
                                  [-342.875, -361.937],
                                  [-420.437, -73.938],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.062, 150.125],
                                  [-346.687, -362.375],
                                  [-426.437, -74.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.625, 149.125],
                                  [-350.312, -362.75],
                                  [-432.062, -74.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.062, 148.125],
                                  [-353.687, -363.125],
                                  [-437.437, -75.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.5, 147.187],
                                  [-356.875, -363.5],
                                  [-442.437, -75.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.937, 146.375],
                                  [-359.875, -363.875],
                                  [-447.187, -75.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.312, 145.562],
                                  [-362.75, -364.187],
                                  [-451.687, -76.188],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.687, 144.75],
                                  [-365.437, -364.5],
                                  [-455.937, -76.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.062, 144.062],
                                  [-368, -364.812],
                                  [-459.874, -76.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.437, 143.375],
                                  [-370.375, -365.062],
                                  [-463.687, -77.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.75, 142.687],
                                  [-372.625, -365.312],
                                  [-467.187, -77.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213, 142.125],
                                  [-374.687, -365.562],
                                  [-470.499, -77.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.312, 141.562],
                                  [-376.687, -365.812],
                                  [-473.624, -77.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.562, 141],
                                  [-378.562, -366],
                                  [-476.562, -78],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.812, 140.5],
                                  [-380.312, -366.187],
                                  [-479.312, -78.188],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214, 140],
                                  [-381.937, -366.375],
                                  [-481.874, -78.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.25, 139.625],
                                  [-383.437, -366.562],
                                  [-484.249, -78.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.437, 139.187],
                                  [-384.875, -366.75],
                                  [-486.499, -78.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.625, 138.812],
                                  [-386.187, -366.875],
                                  [-488.562, -78.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.812, 138.437],
                                  [-387.375, -367],
                                  [-490.499, -79],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.937, 138.125],
                                  [-388.5, -367.125],
                                  [-492.249, -79.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.125, 137.875],
                                  [-389.562, -367.25],
                                  [-493.874, -79.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.25, 137.562],
                                  [-390.5, -367.375],
                                  [-495.374, -79.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.375, 137.312],
                                  [-391.375, -367.5],
                                  [-496.687, -79.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.437, 137.125],
                                  [-392.125, -367.562],
                                  [-497.937, -79.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.562, 136.875],
                                  [-392.875, -367.625],
                                  [-499.062, -79.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.625, 136.75],
                                  [-393.5, -367.687],
                                  [-499.999, -79.688],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.75, 136.562],
                                  [-394, -367.75],
                                  [-500.874, -79.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.812, 136.437],
                                  [-394.5, -367.812],
                                  [-501.624, -79.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.875, 136.312],
                                  [-394.937, -367.875],
                                  [-502.312, -79.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.875, 136.187],
                                  [-395.25, -367.937],
                                  [-502.812, -79.938],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.937, 136.125],
                                  [-395.5, -367.937],
                                  [-503.249, -79.938],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.937, 136.062],
                                  [-395.75, -368],
                                  [-503.562, -80],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-357.695, -260.798],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-357.584, -260.565],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-357.392, -260.257],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-357.156, -259.777],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-356.782, -259.136],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-356.298, -258.262],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-355.777, -257.244],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-355.067, -255.958],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-354.258, -254.515],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-353.318, -252.792],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-352.233, -250.689],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-350.96, -248.332],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-349.471, -245.619],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-347.796, -242.414],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-345.903, -238.761],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-343.746, -234.599],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-341.264, -229.841],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-338.472, -224.203],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-335.272, -217.773],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-331.656, -210.279],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-327.513, -201.567],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.911, -191.361],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-317.683, -179.465],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-311.971, -165.545],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-305.937, -149.668],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-299.761, -131.988],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.012, -113.062],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-288.982, -93.976],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-285.055, -75.52],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-282.182, -58.463],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-280.22, -43.15],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-279.004, -29.708],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.243, -18.198],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-277.59, -8.775],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.831, -1.335],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-275.735, 4.098],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-274.213, 7.921],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-272.311, 10.519],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-270.218, 12.404],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-267.932, 13.847],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-265.649, 15.025],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-263.34, 16.066],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-261.046, 16.959],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-258.788, 17.81],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.613, 18.631],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.482, 19.408],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.415, 20.129],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.41, 20.859],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-248.496, 21.535],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.651, 22.222],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-244.957, 22.887],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-243.219, 23.519],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-241.612, 24.143],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.082, 24.749],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-238.593, 25.357],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-237.219, 25.935],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-235.836, 26.47],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-234.542, 26.98],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.367, 27.511],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-232.207, 27.993],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-231.108, 28.451],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.048, 28.921],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.035, 29.368],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.137, 29.773],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.25, 30.157],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-226.431, 30.552],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.631, 30.911],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.875, 31.234],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.182, 31.54],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-223.52, 31.859],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.95, 32.144],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.352, 32.424],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.813, 32.661],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.335, 32.892],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.884, 33.108],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.443, 33.315],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.03, 33.505],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.716, 33.684],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.388, 33.827],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.131, 33.958],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.841, 34.072],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.586, 34.181],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.394, 34.282],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.232, 34.367],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.105, 34.445],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.003, 34.482],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.94, 34.553],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.777, 34.567],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.728, 34.605],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.612, 34.67],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.423, 34.69],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.189, 34.758],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.874, 34.856],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.483, 34.971],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.065, 35.108],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.502, 35.24],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.795, 35.43],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.045, 35.669],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.118, 35.918],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.017, 36.206],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.785, 36.56],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.302, 36.955],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.519, 37.412],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.417, 37.868],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.925, 38.423],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.879, 38.977],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.181, 39.483],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-191.538, 39.873],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.629, 39.921],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-177.938, 39.256],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.858, 37.245],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.75, 33.117],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.54, 26.49],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.017, 17.66],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.487, 7.302],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.293, -3.434],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.163, -14.007],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.581, -23.964],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.519, -33.254],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.968, -41.766],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.69, -49.591],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.429, -56.747],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.552, -63.358],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.797, -69.414],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.266, -74.997],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.038, -80.186],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.222, -84.975],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.882, -89.493],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.091, -93.7],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.818, -97.584],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.189, -101.238],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.211, -104.634],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.903, -107.919],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.306, -110.934],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.441, -113.757],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.343, -116.443],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.98, -119.01],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.43, -121.38],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.663, -123.618],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.724, -125.776],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.593, -127.762],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.301, -129.663],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.877, -131.452],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.305, -133.136],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.574, -134.744],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.745, -136.335],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.77, -137.704],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.687, -139.061],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.522, -140.373],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.204, -141.561],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.823, -142.692],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.309, -143.796],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.754, -144.767],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.066, -145.711],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.326, -146.615],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.495, -147.426],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.609, -148.264],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.606, -148.916],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.567, -149.66],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.442, -150.245],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.262, -150.857],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.002, -151.393],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.71, -151.874],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.353, -152.305],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.935, -152.705],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.451, -153.073],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.917, -153.462],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.324, -153.729],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.703, -153.957],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.007, -154.203],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.289, -154.413],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.505, -154.566],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.727, -154.707],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.861, -154.794],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.931, -154.841],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.922, -154.932],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.661, -154.998],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.211, -155.084],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.536, -155.139],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.61, -155.278],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.485, -155.482],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.055, -155.698],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.339, -155.993],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.282, -156.31],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.874, -156.654],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.091, -157.104],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.859, -157.616],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.144, -158.189],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.824, -158.9],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.911, -159.647],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.286, -160.537],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.775, -161.571],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.35, -162.766],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.806, -164.172],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.92, -165.763],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.541, -167.612],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.314, -169.808],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.059, -172.394],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.521, -175.406],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.676, -178.906],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.119, -182.832],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-41.092, -187.128],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.237, -191.625],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.432, -196.054],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.093, -200.354],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126, -204.375],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.182, -208.166],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.706, -211.65],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.762, -214.87],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.589, -217.862],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.233, -220.643],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.959, -223.228],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.861, -225.599],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-226.967, -227.843],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-235.401, -229.926],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-243.275, -231.891],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.58, -233.72],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-257.435, -235.429],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-263.786, -237.025],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-269.801, -238.527],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-275.408, -239.969],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-280.733, -241.287],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-285.689, -242.578],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-290.407, -243.74],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.823, -244.879],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-298.998, -245.926],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.948, -246.924],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-306.657, -247.843],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-310.175, -248.762],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-313.479, -249.619],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-316.628, -250.437],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-319.641, -251.156],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.437, -251.885],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-325.08, -252.572],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-327.571, -253.205],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-329.954, -253.793],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-332.193, -254.387],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-334.32, -254.904],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-336.314, -255.413],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-338.191, -255.899],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-339.901, -256.336],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-341.574, -256.765],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-343.14, -257.156],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-344.603, -257.519],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-345.954, -257.877],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-347.212, -258.183],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-348.411, -258.508],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-349.511, -258.771],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-350.518, -259.046],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-351.45, -259.271],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-352.341, -259.468],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-353.129, -259.7],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-353.856, -259.887],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-354.478, -260.028],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-355.113, -260.184],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-355.624, -260.276],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-356.065, -260.425],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-356.479, -260.518],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-356.844, -260.617],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-357.097, -260.711],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-357.318, -260.754],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-357.627, -260.819],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-357.695, -260.798],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-357.584, -260.565],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-357.392, -260.257],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-357.156, -259.777],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-356.782, -259.136],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-356.298, -258.262],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-355.777, -257.244],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-355.067, -255.958],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-354.258, -254.515],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-353.318, -252.792],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-352.233, -250.689],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-350.96, -248.332],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-349.471, -245.619],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-347.796, -242.414],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-345.903, -238.761],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-343.746, -234.599],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-341.264, -229.841],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-338.472, -224.203],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-335.272, -217.773],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-331.656, -210.279],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-327.513, -201.567],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.911, -191.361],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-317.683, -179.465],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-311.971, -165.545],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-305.937, -149.668],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-299.761, -131.988],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.012, -113.062],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-288.982, -93.976],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-285.055, -75.52],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-282.182, -58.463],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-280.22, -43.15],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-279.004, -29.708],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.243, -18.198],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-277.59, -8.775],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.831, -1.335],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-275.735, 4.098],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-274.213, 7.921],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-272.311, 10.519],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-270.218, 12.404],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-267.932, 13.847],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-265.649, 15.025],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-263.34, 16.066],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-261.046, 16.959],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-258.788, 17.81],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.613, 18.631],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.482, 19.408],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.415, 20.129],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.41, 20.859],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-248.496, 21.535],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.651, 22.222],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-244.957, 22.887],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-243.219, 23.519],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-241.612, 24.143],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.082, 24.749],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-238.593, 25.357],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-237.219, 25.935],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-235.836, 26.47],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-234.542, 26.98],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.367, 27.511],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-232.207, 27.993],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-231.108, 28.451],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.048, 28.921],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.035, 29.368],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.137, 29.773],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.25, 30.157],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-226.431, 30.552],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.631, 30.911],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.875, 31.234],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.182, 31.54],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-223.52, 31.859],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.95, 32.144],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.352, 32.424],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.813, 32.661],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.335, 32.892],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.884, 33.108],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.443, 33.315],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.03, 33.505],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.716, 33.684],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.388, 33.827],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.131, 33.958],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.841, 34.072],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.586, 34.181],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.394, 34.282],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.232, 34.367],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.105, 34.445],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.003, 34.482],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.94, 34.553],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.777, 34.567],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.728, 34.605],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.612, 34.67],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.423, 34.69],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.189, 34.758],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.874, 34.856],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.483, 34.971],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.065, 35.108],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.502, 35.24],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.795, 35.43],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.045, 35.669],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.118, 35.918],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.017, 36.206],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.785, 36.56],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.302, 36.955],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.519, 37.412],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.417, 37.868],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.925, 38.423],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.879, 38.977],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.181, 39.483],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-191.538, 39.873],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.629, 39.921],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-177.938, 39.256],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.858, 37.245],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.75, 33.117],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.54, 26.49],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.017, 17.66],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.487, 7.302],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.293, -3.434],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.163, -14.007],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.581, -23.964],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.519, -33.254],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.968, -41.766],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.69, -49.591],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.429, -56.747],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.552, -63.358],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.797, -69.414],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.266, -74.997],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.038, -80.186],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.222, -84.975],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.882, -89.493],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.091, -93.7],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.818, -97.584],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.189, -101.238],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.211, -104.634],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.903, -107.919],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.306, -110.934],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.441, -113.757],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.343, -116.443],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.98, -119.01],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.43, -121.38],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.663, -123.618],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.724, -125.776],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.593, -127.762],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.301, -129.663],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.877, -131.452],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.305, -133.136],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.574, -134.744],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.745, -136.335],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.77, -137.704],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.687, -139.061],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.522, -140.373],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.204, -141.561],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.823, -142.692],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.309, -143.796],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.754, -144.767],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.066, -145.711],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.326, -146.615],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.495, -147.426],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.609, -148.264],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.606, -148.916],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.567, -149.66],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.442, -150.245],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.262, -150.857],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.002, -151.393],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.71, -151.874],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.353, -152.305],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.935, -152.705],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.451, -153.073],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.917, -153.462],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.324, -153.729],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.703, -153.957],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.007, -154.203],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.289, -154.413],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.505, -154.566],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.727, -154.707],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.861, -154.794],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.931, -154.841],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.922, -154.932],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.661, -154.998],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.211, -155.084],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.536, -155.139],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.61, -155.278],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.485, -155.482],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.055, -155.698],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.339, -155.993],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.282, -156.31],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.874, -156.654],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.091, -157.104],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.859, -157.616],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.144, -158.189],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.824, -158.9],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.911, -159.647],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.286, -160.537],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.775, -161.571],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.35, -162.766],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.806, -164.172],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.92, -165.763],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.541, -167.612],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.314, -169.808],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.059, -172.394],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.521, -175.406],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.676, -178.906],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.119, -182.832],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-41.092, -187.128],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.237, -191.625],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.432, -196.054],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.093, -200.354],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126, -204.375],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.182, -208.166],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.706, -211.65],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.762, -214.87],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.589, -217.862],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.233, -220.643],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.959, -223.228],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.861, -225.599],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-226.967, -227.843],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-235.401, -229.926],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-243.275, -231.891],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.58, -233.72],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-257.435, -235.429],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-263.786, -237.025],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-269.801, -238.527],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-275.408, -239.969],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-280.733, -241.287],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-285.689, -242.578],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-290.407, -243.74],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.823, -244.879],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-298.998, -245.926],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.948, -246.924],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-306.657, -247.843],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-310.175, -248.762],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-313.479, -249.619],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-316.628, -250.437],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-319.641, -251.156],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.437, -251.885],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-325.08, -252.572],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-327.571, -253.205],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-329.954, -253.793],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-332.193, -254.387],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-334.32, -254.904],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-336.314, -255.413],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-338.191, -255.899],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-339.901, -256.336],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-341.574, -256.765],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-343.14, -257.156],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-344.603, -257.519],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-345.954, -257.877],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-347.212, -258.183],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-348.411, -258.508],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-349.511, -258.771],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-350.518, -259.046],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-351.45, -259.271],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-352.341, -259.468],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-353.129, -259.7],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-353.856, -259.887],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-354.478, -260.028],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-355.113, -260.184],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-355.624, -260.276],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-356.065, -260.425],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-356.479, -260.518],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-356.844, -260.617],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-357.097, -260.711],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-357.318, -260.754],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-357.627, -260.819],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 1',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.315, 128.871],
                                  [-367.032, -336.588],
                                  [-465.396, -92.039],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.483, 128.939],
                                  [-366.799, -336.372],
                                  [-465.065, -92.136],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.706, 129.013],
                                  [-366.507, -335.985],
                                  [-464.69, -92.222],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.046, 129.151],
                                  [-366.036, -335.49],
                                  [-464.087, -92.357],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.438, 129.351],
                                  [-365.448, -334.828],
                                  [-463.321, -92.537],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.946, 129.557],
                                  [-364.801, -334.058],
                                  [-462.449, -92.765],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.564, 129.831],
                                  [-363.921, -333.064],
                                  [-461.357, -93.041],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.237, 130.168],
                                  [-362.916, -331.957],
                                  [-460.046, -93.303],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.082, 130.515],
                                  [-361.741, -330.629],
                                  [-458.51, -93.664],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.095, 130.929],
                                  [-360.383, -329.023],
                                  [-456.698, -94.127],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.221, 131.466],
                                  [-358.784, -327.25],
                                  [-454.611, -94.631],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-218.513, 132.016],
                                  [-356.893, -325.136],
                                  [-452.188, -95.172],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.035, 132.692],
                                  [-354.758, -322.689],
                                  [-449.433, -95.869],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.776, 133.44],
                                  [-352.328, -319.904],
                                  [-446.229, -96.654],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.749, 134.264],
                                  [-349.533, -316.724],
                                  [-442.522, -97.53],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-226.055, 135.276],
                                  [-346.263, -313.089],
                                  [-438.311, -98.494],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-228.702, 136.427],
                                  [-342.57, -308.835],
                                  [-433.428, -99.713],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-231.687, 137.712],
                                  [-338.274, -303.956],
                                  [-427.811, -101.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-235.182, 139.202],
                                  [-333.312, -298.277],
                                  [-421.349, -102.602],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-239.238, 140.955],
                                  [-327.506, -291.749],
                                  [-413.754, -104.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.974, 142.975],
                                  [-320.855, -284.135],
                                  [-405.028, -106.558],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.391, 145.325],
                                  [-313.055, -275.322],
                                  [-394.826, -109.016],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.654, 148.011],
                                  [-304.1, -265.078],
                                  [-383.091, -111.863],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-262.77, 151.034],
                                  [-293.992, -253.517],
                                  [-369.766, -115.099],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-270.624, 154.384],
                                  [-282.738, -240.75],
                                  [-355.066, -118.682],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.944, 157.934],
                                  [-270.935, -227.235],
                                  [-339.511, -122.526],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.339, 161.542],
                                  [-258.961, -213.65],
                                  [-323.829, -126.316],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.406, 164.962],
                                  [-247.537, -200.519],
                                  [-308.707, -130.034],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.99, 168.175],
                                  [-236.852, -188.297],
                                  [-294.658, -133.524],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-309.915, 171.066],
                                  [-227.089, -177.1],
                                  [-281.792, -136.745],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-316.239, 173.747],
                                  [-218.307, -166.983],
                                  [-270.113, -139.627],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-322.018, 176.163],
                                  [-210.385, -157.839],
                                  [-259.569, -142.29],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-327.25, 178.32],
                                  [-203.206, -149.605],
                                  [-250.044, -144.667],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-331.995, 180.279],
                                  [-196.768, -142.055],
                                  [-241.425, -146.865],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-336.422, 182.102],
                                  [-190.888, -135.249],
                                  [-233.541, -148.883],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-340.469, 183.79],
                                  [-185.57, -128.957],
                                  [-226.334, -150.772],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.201, 185.287],
                                  [-180.691, -123.237],
                                  [-219.698, -152.528],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-347.727, 186.711],
                                  [-176.251, -117.973],
                                  [-213.627, -154.147],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-350.992, 188.01],
                                  [-172.13, -113.051],
                                  [-207.952, -155.685],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-354.11, 189.238],
                                  [-168.388, -108.532],
                                  [-202.73, -157.142],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.027, 190.4],
                                  [-164.91, -104.352],
                                  [-197.908, -158.453],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-359.796, 191.438],
                                  [-161.748, -100.404],
                                  [-193.366, -159.801],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-362.421, 192.412],
                                  [-158.789, -96.739],
                                  [-189.168, -160.998],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-364.901, 193.379],
                                  [-156.091, -93.362],
                                  [-185.194, -162.167],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.293, 194.283],
                                  [-153.594, -90.156],
                                  [-181.504, -163.308],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.6, 195.065],
                                  [-151.298, -87.12],
                                  [-178.039, -164.414],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.815, 195.902],
                                  [-149.14, -84.314],
                                  [-174.8, -165.487],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.94, 196.617],
                                  [-147.18, -81.679],
                                  [-171.729, -166.527],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-376.031, 197.329],
                                  [-145.353, -79.154],
                                  [-168.883, -167.532],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-378.022, 197.978],
                                  [-143.716, -76.854],
                                  [-166.148, -168.435],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-379.908, 198.621],
                                  [-142.146, -74.61],
                                  [-163.576, -169.415],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-381.742, 199.203],
                                  [-140.692, -72.531],
                                  [-161.168, -170.285],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-383.515, 199.777],
                                  [-139.355, -70.563],
                                  [-158.867, -171.167],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-385.165, 200.349],
                                  [-138.124, -68.641],
                                  [-156.732, -171.994],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.802, 200.855],
                                  [-136.939, -66.888],
                                  [-154.642, -172.759],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-388.3, 201.295],
                                  [-135.793, -65.184],
                                  [-152.718, -173.522],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-389.721, 201.731],
                                  [-134.748, -63.59],
                                  [-150.84, -174.284],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-391.119, 202.159],
                                  [-133.736, -62.104],
                                  [-149.132, -174.916],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-392.374, 202.586],
                                  [-132.825, -60.671],
                                  [-147.47, -175.603],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-393.607, 202.944],
                                  [-131.893, -59.347],
                                  [-145.92, -176.222],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-394.699, 203.3],
                                  [-131.059, -58.018],
                                  [-144.419, -176.772],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-395.771, 203.651],
                                  [-130.205, -56.802],
                                  [-143.033, -177.318],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-396.762, 203.938],
                                  [-129.457, -55.7],
                                  [-141.699, -177.794],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-397.737, 204.222],
                                  [-128.75, -54.595],
                                  [-140.418, -178.326],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.635, 204.503],
                                  [-128.031, -53.603],
                                  [-139.253, -178.729],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-399.459, 204.781],
                                  [-127.419, -52.609],
                                  [-138.143, -179.189],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-400.208, 204.996],
                                  [-126.794, -51.731],
                                  [-137.089, -179.581],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-400.946, 205.207],
                                  [-126.22, -50.912],
                                  [-136.092, -179.97],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-401.61, 205.418],
                                  [-125.695, -50.088],
                                  [-135.213, -180.293],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-402.267, 205.627],
                                  [-125.224, -49.383],
                                  [-134.332, -180.613],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-402.851, 205.833],
                                  [-124.743, -48.677],
                                  [-133.568, -180.93],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-403.427, 205.978],
                                  [-124.317, -48.029],
                                  [-132.801, -181.242],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-403.934, 206.12],
                                  [-123.944, -47.44],
                                  [-132.093, -181.491],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-404.435, 206.262],
                                  [-123.564, -46.91],
                                  [-131.505, -181.737],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-404.867, 206.401],
                                  [-123.24, -46.378],
                                  [-130.915, -181.98],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-405.293, 206.541],
                                  [-122.91, -45.907],
                                  [-130.384, -182.22],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-405.654, 206.678],
                                  [-122.699, -45.494],
                                  [-129.852, -182.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-406.007, 206.754],
                                  [-122.422, -45.142],
                                  [-129.44, -182.574],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-406.357, 206.828],
                                  [-122.262, -44.788],
                                  [-129.027, -182.747],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-406.64, 206.902],
                                  [-122.036, -44.495],
                                  [-128.673, -182.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-406.919, 206.975],
                                  [-121.869, -44.201],
                                  [-128.381, -183.089],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.133, 207.048],
                                  [-121.76, -43.967],
                                  [-128.086, -183.197],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.342, 207.118],
                                  [-121.647, -43.793],
                                  [-127.851, -183.303],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.549, 207.189],
                                  [-121.594, -43.618],
                                  [-127.678, -183.407],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.753, 207.197],
                                  [-121.537, -43.505],
                                  [-127.565, -183.51],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.892, 207.267],
                                  [-121.539, -43.39],
                                  [-127.451, -183.551],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.966, 207.275],
                                  [-121.538, -43.336],
                                  [-127.336, -183.652],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-408.099, 207.282],
                                  [-121.535, -43.282],
                                  [-127.282, -183.69],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-408.168, 207.288],
                                  [-121.592, -43.288],
                                  [-127.288, -183.728],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-408.175, 207.293],
                                  [-121.586, -43.355],
                                  [-127.17, -183.763],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-408.12, 207.356],
                                  [-121.456, -43.484],
                                  [-126.868, -183.673],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.943, 207.479],
                                  [-121.205, -43.673],
                                  [-126.318, -183.519],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.644, 207.597],
                                  [-120.767, -44.046],
                                  [-125.582, -183.36],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.224, 207.774],
                                  [-120.207, -44.481],
                                  [-124.477, -183.076],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-406.62, 208.01],
                                  [-119.464, -45.038],
                                  [-123.123, -182.666],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-405.895, 208.303],
                                  [-118.536, -45.718],
                                  [-121.461, -182.192],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-405.051, 208.654],
                                  [-117.426, -46.581],
                                  [-119.427, -181.59],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-404.023, 209.001],
                                  [-116.07, -47.568],
                                  [-117.083, -180.924],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-402.753, 209.466],
                                  [-114.469, -48.677],
                                  [-114.246, -180.131],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-401.303, 210.049],
                                  [-112.625, -50.032],
                                  [-110.914, -179.15],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-399.61, 210.688],
                                  [-110.414, -51.632],
                                  [-107.088, -178.04],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-397.615, 211.383],
                                  [-107.897, -53.416],
                                  [-102.704, -176.742],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-395.381, 212.255],
                                  [-105.014, -55.508],
                                  [-97.641, -175.253],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-392.723, 213.244],
                                  [-101.704, -57.907],
                                  [-91.835, -173.513],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-389.704, 214.345],
                                  [-97.845, -60.613],
                                  [-85.164, -171.521],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.263, 215.561],
                                  [-93.438, -63.75],
                                  [-77.503, -169.275],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-382.282, 217.072],
                                  [-88.36, -67.378],
                                  [-68.668, -166.652],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-377.636, 218.754],
                                  [-82.49, -71.559],
                                  [-58.472, -163.588],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-372.331, 220.668],
                                  [-75.768, -76.355],
                                  [-46.729, -160.083],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-366.183, 222.931],
                                  [-67.888, -81.949],
                                  [-33.193, -156.073],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-359.013, 225.542],
                                  [-58.79, -88.405],
                                  [-17.431, -151.371],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-350.7, 228.56],
                                  [-48.229, -95.844],
                                  [0.867, -145.915],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-341.124, 232.043],
                                  [-36.085, -104.452],
                                  [21.886, -139.639],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-330.223, 235.992],
                                  [-22.357, -114.228],
                                  [45.75, -132.483],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-318.239, 240.347],
                                  [-7.166, -124.988],
                                  [72.027, -124.634],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-305.591, 244.997],
                                  [8.941, -136.363],
                                  [99.791, -116.345],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-292.818, 249.587],
                                  [25.112, -147.863],
                                  [127.803, -107.991],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-280.521, 254.066],
                                  [40.679, -158.873],
                                  [154.705, -99.954],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-269, 258.258],
                                  [55.215, -169.146],
                                  [179.877, -92.42],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-258.557, 262.103],
                                  [68.538, -178.559],
                                  [202.826, -85.577],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.009, 265.542],
                                  [80.585, -187.113],
                                  [223.612, -79.363],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-240.422, 268.695],
                                  [91.417, -194.807],
                                  [242.419, -73.777],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-232.674, 271.497],
                                  [101.277, -201.764],
                                  [259.497, -68.696],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-225.583, 274.069],
                                  [110.226, -208.106],
                                  [274.906, -64.055],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.152, 276.409],
                                  [118.385, -213.895],
                                  [289.017, -59.853],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.26, 278.576],
                                  [125.877, -219.193],
                                  [301.953, -56.028],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.848, 280.569],
                                  [132.76, -224.061],
                                  [313.777, -52.518],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.855, 282.388],
                                  [139.035, -228.498],
                                  [324.735, -49.26],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.222, 284.092],
                                  [144.884, -232.628],
                                  [334.828, -46.252],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.949, 285.679],
                                  [150.309, -236.512],
                                  [344.178, -43.433],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.915, 287.089],
                                  [155.368, -240.088],
                                  [352.911, -40.865],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.242, 288.504],
                                  [160.062, -243.418],
                                  [361.024, -38.423],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.748, 289.739],
                                  [164.453, -246.503],
                                  [368.643, -36.17],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.495, 290.917],
                                  [168.6, -249.402],
                                  [375.706, -34.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.483, 292.039],
                                  [172.443, -252.179],
                                  [382.397, -32.101],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.588, 293.102],
                                  [176.042, -254.709],
                                  [388.654, -30.224],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.876, 294.107],
                                  [179.459, -257.117],
                                  [394.48, -28.473],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.342, 295.053],
                                  [182.694, -259.401],
                                  [400.057, -26.846],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.991, 295.941],
                                  [185.684, -261.562],
                                  [405.263, -25.282],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.759, 296.708],
                                  [188.553, -263.538],
                                  [410.16, -23.844],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.647, 297.54],
                                  [191.24, -265.453],
                                  [414.747, -22.469],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.594, 298.25],
                                  [193.743, -267.244],
                                  [419.148, -21.218],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.723, 298.963],
                                  [196.124, -268.912],
                                  [423.241, -19.969],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.972, 299.615],
                                  [198.385, -270.519],
                                  [427.146, -18.843],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.282, 300.208],
                                  [200.522, -272.002],
                                  [430.805, -17.719],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.65, 300.802],
                                  [202.539, -273.424],
                                  [434.277, -16.719],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.201, 301.398],
                                  [204.433, -274.783],
                                  [437.564, -15.72],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.75, 301.873],
                                  [206.205, -276.082],
                                  [440.665, -14.783],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.421, 302.409],
                                  [207.916, -277.256],
                                  [443.58, -13.97],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.151, 302.825],
                                  [209.505, -278.37],
                                  [446.31, -13.159],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.943, 303.302],
                                  [211.033, -279.482],
                                  [448.915, -12.347],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.854, 303.72],
                                  [212.439, -280.472],
                                  [451.396, -11.66],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.765, 304.076],
                                  [213.784, -281.4],
                                  [453.692, -10.974],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-142.799, 304.496],
                                  [215.007, -282.328],
                                  [455.863, -10.287],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.83, 304.792],
                                  [216.23, -283.132],
                                  [457.911, -9.725],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.984, 305.151],
                                  [217.331, -283.936],
                                  [459.834, -9.163],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.136, 305.45],
                                  [218.371, -284.679],
                                  [461.633, -8.602],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.35, 305.749],
                                  [219.349, -285.36],
                                  [463.309, -8.103],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.623, 305.987],
                                  [220.267, -286.04],
                                  [464.922, -7.604],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.958, 306.226],
                                  [221.124, -286.598],
                                  [466.35, -7.23],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.353, 306.465],
                                  [221.919, -287.216],
                                  [467.777, -6.793],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-136.747, 306.705],
                                  [222.653, -287.712],
                                  [469.019, -6.419],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-136.202, 306.884],
                                  [223.326, -288.207],
                                  [470.198, -6.045],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.719, 307.064],
                                  [223.938, -288.64],
                                  [471.315, -5.733],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.233, 307.243],
                                  [224.55, -289.074],
                                  [472.308, -5.483],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.81, 307.424],
                                  [225.101, -289.445],
                                  [473.178, -5.171],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.446, 307.543],
                                  [225.59, -289.755],
                                  [474.047, -4.923],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.083, 307.663],
                                  [226.019, -290.065],
                                  [474.792, -4.735],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.78, 307.783],
                                  [226.386, -290.374],
                                  [475.475, -4.486],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.539, 307.903],
                                  [226.754, -290.622],
                                  [476.095, -4.361],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.234, 308.024],
                                  [227.06, -290.808],
                                  [476.592, -4.175],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.053, 308.084],
                                  [227.304, -290.994],
                                  [477.089, -4.05],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.871, 308.143],
                                  [227.549, -291.18],
                                  [477.461, -3.925],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.75, 308.204],
                                  [227.733, -291.304],
                                  [477.772, -3.801],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.568, 308.264],
                                  [227.917, -291.427],
                                  [478.081, -3.738],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.508, 308.263],
                                  [228.039, -291.49],
                                  [478.269, -3.676],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.447, 308.324],
                                  [228.101, -291.552],
                                  [478.392, -3.676],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.387, 308.323],
                                  [228.162, -291.613],
                                  [478.517, -3.613],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.387, 308.322],
                                  [228.038, -291.612],
                                  [478.331, -3.613],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.45, 308.194],
                                  [227.728, -291.669],
                                  [477.833, -3.676],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.514, 308.003],
                                  [227.169, -291.723],
                                  [476.964, -3.739],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.64, 307.806],
                                  [226.363, -291.774],
                                  [475.659, -3.802],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.83, 307.483],
                                  [225.245, -291.882],
                                  [473.919, -3.928],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.02, 307.034],
                                  [223.88, -292.047],
                                  [471.745, -4.117],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.273, 306.517],
                                  [222.144, -292.208],
                                  [469.012, -4.306],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.527, 305.874],
                                  [220.036, -292.425],
                                  [465.72, -4.557],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.907, 305.163],
                                  [217.554, -292.699],
                                  [461.808, -4.871],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.35, 304.323],
                                  [214.64, -292.966],
                                  [457.214, -5.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.793, 303.293],
                                  [211.231, -293.287],
                                  [451.874, -5.566],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.362, 302.132],
                                  [207.326, -293.723],
                                  [445.668, -6.07],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.995, 300.778],
                                  [202.803, -294.15],
                                  [438.532, -6.577],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-136.751, 299.23],
                                  [197.539, -294.687],
                                  [430.343, -7.21],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.634, 297.427],
                                  [191.595, -295.274],
                                  [420.855, -7.909],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.58, 295.365],
                                  [184.726, -295.907],
                                  [410.069, -8.674],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.712, 293.045],
                                  [176.809, -296.707],
                                  [397.615, -9.629],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.032, 290.34],
                                  [167.784, -297.605],
                                  [383.371, -10.715],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-142.475, 287.187],
                                  [157.346, -298.6],
                                  [366.971, -11.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.226, 283.585],
                                  [145.312, -299.745],
                                  [347.987, -13.355],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.161, 279.468],
                                  [131.442, -301.093],
                                  [326.054, -15.038],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.463, 274.648],
                                  [115.314, -302.632],
                                  [300.63, -16.993],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.066, 269.063],
                                  [96.692, -304.41],
                                  [271.235, -19.29],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.088, 262.651],
                                  [75.285, -306.41],
                                  [237.461, -21.939],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.518, 255.352],
                                  [51.055, -308.616],
                                  [199.276, -24.954],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.228, 247.362],
                                  [24.459, -311.018],
                                  [157.211, -28.347],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.087, 238.941],
                                  [-3.562, -313.498],
                                  [112.955, -31.999],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.025, 230.477],
                                  [-31.709, -315.959],
                                  [68.55, -35.777],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.678, 222.355],
                                  [-58.644, -318.189],
                                  [25.893, -39.425],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.112, 214.767],
                                  [-83.593, -320.22],
                                  [-13.694, -42.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.154, 207.899],
                                  [-106.333, -321.957],
                                  [-49.695, -46.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.873, 201.687],
                                  [-126.889, -323.538],
                                  [-82.196, -49.146],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.333, 196.122],
                                  [-145.337, -324.907],
                                  [-111.47, -51.919],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.54, 191.079],
                                  [-161.937, -326.07],
                                  [-137.842, -54.435],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.501, 186.49],
                                  [-177.002, -327.086],
                                  [-161.75, -56.756],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.217, 182.352],
                                  [-190.602, -328.016],
                                  [-183.451, -58.938],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.81, 178.595],
                                  [-203.046, -328.856],
                                  [-203.199, -60.982],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.283, 175.162],
                                  [-214.458, -329.546],
                                  [-221.302, -62.826],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.578, 171.983],
                                  [-224.905, -330.206],
                                  [-237.948, -64.593],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.813, 169.061],
                                  [-234.51, -330.773],
                                  [-253.323, -66.22],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.873, 166.329],
                                  [-243.455, -331.304],
                                  [-267.492, -67.768],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.933, 163.848],
                                  [-251.683, -331.803],
                                  [-280.639, -69.24],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.88, 161.495],
                                  [-259.376, -332.201],
                                  [-292.885, -70.576],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.712, 159.329],
                                  [-266.476, -332.565],
                                  [-304.293, -71.835],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.547, 157.351],
                                  [-273.163, -332.948],
                                  [-314.928, -73.078],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.266, 155.438],
                                  [-279.377, -333.291],
                                  [-324.849, -74.246],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.99, 153.646],
                                  [-285.24, -333.532],
                                  [-334.179, -75.28],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.6, 151.982],
                                  [-290.691, -333.85],
                                  [-342.916, -76.36],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.213, 150.438],
                                  [-295.852, -334.067],
                                  [-351.121, -77.306],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.774, 148.959],
                                  [-300.663, -334.3],
                                  [-358.861, -78.237],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.339, 147.6],
                                  [-305.185, -334.493],
                                  [-366.126, -79.093],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.79, 146.302],
                                  [-309.476, -334.694],
                                  [-372.929, -79.939],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.243, 145.126],
                                  [-313.48, -334.856],
                                  [-379.379, -80.712],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.705, 143.954],
                                  [-317.253, -335.028],
                                  [-385.42, -81.474],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.11, 142.899],
                                  [-320.796, -335.215],
                                  [-391.118, -82.222],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.462, 141.847],
                                  [-324.168, -335.357],
                                  [-396.525, -82.902],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.876, 140.91],
                                  [-327.372, -335.451],
                                  [-401.582, -83.508],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.175, 139.979],
                                  [-330.346, -335.554],
                                  [-406.415, -84.106],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.481, 139.108],
                                  [-333.154, -335.674],
                                  [-410.899, -84.692],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.79, 138.351],
                                  [-335.791, -335.8],
                                  [-415.156, -85.264],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.044, 137.598],
                                  [-338.312, -335.883],
                                  [-419.185, -85.769],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.306, 136.845],
                                  [-340.671, -335.972],
                                  [-422.987, -86.264],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.568, 136.21],
                                  [-342.915, -336.072],
                                  [-426.5, -86.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.837, 135.574],
                                  [-344.992, -336.127],
                                  [-429.903, -87.168],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.048, 134.937],
                                  [-346.954, -336.185],
                                  [-433.021, -87.577],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.205, 134.42],
                                  [-348.75, -336.259],
                                  [-435.97, -87.975],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.429, 133.903],
                                  [-350.497, -336.336],
                                  [-438.747, -88.364],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.594, 133.384],
                                  [-352.129, -336.366],
                                  [-441.361, -88.689],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.764, 132.926],
                                  [-353.649, -336.404],
                                  [-443.802, -89.003],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.876, 132.466],
                                  [-355.062, -336.452],
                                  [-446.075, -89.311],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.055, 132.121],
                                  [-356.363, -336.507],
                                  [-448.18, -89.606],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.176, 131.72],
                                  [-357.612, -336.569],
                                  [-450.175, -89.898],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.302, 131.376],
                                  [-358.748, -336.578],
                                  [-452.001, -90.122],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.433, 131.033],
                                  [-359.773, -336.598],
                                  [-453.718, -90.339],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.505, 130.746],
                                  [-360.749, -336.624],
                                  [-455.263, -90.547],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.641, 130.52],
                                  [-361.668, -336.656],
                                  [-456.697, -90.748],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.72, 130.232],
                                  [-362.479, -336.694],
                                  [-458.026, -90.944],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.803, 130.005],
                                  [-363.236, -336.741],
                                  [-459.181, -91.133],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.832, 129.833],
                                  [-363.877, -336.736],
                                  [-460.285, -91.258],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.921, 129.604],
                                  [-364.527, -336.735],
                                  [-461.278, -91.377],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.951, 129.489],
                                  [-365.07, -336.74],
                                  [-462.103, -91.485],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.05, 129.317],
                                  [-365.496, -336.75],
                                  [-462.871, -91.592],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.086, 129.205],
                                  [-365.926, -336.77],
                                  [-463.532, -91.689],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.132, 129.089],
                                  [-366.307, -336.793],
                                  [-464.141, -91.783],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.115, 128.975],
                                  [-366.57, -336.822],
                                  [-464.576, -91.872],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.162, 128.916],
                                  [-366.784, -336.797],
                                  [-464.958, -91.893],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.155, 128.862],
                                  [-366.998, -336.838],
                                  [-465.234, -91.97],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.249, 0.249, 0.249, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.249, 0.249, 0.249, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-331.089, -229.055],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.989, -228.849],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.819, -228.58],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.611, -228.159],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.278, -227.596],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-329.847, -226.827],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-329.387, -225.938],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-328.757, -224.808],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-328.036, -223.54],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-327.202, -222.028],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-326.237, -220.175],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-325.1, -218.103],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-323.766, -215.699],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.264, -212.854],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-320.566, -209.608],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-318.625, -205.905],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-316.395, -201.683],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-313.884, -196.665],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-310.995, -190.921],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-307.729, -184.224],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.983, -176.434],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-299.822, -167.286],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.09, -156.625],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-289.918, -144.145],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-284.453, -129.916],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.855, -114.1],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-273.638, -97.226],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-269.047, -80.297],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-265.411, -64.047],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-262.657, -49.213],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-260.636, -36.085],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-259.181, -24.771],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-258.025, -15.302],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.896, -7.668],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.693, -1.598],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.322, 3.074],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.759, 6.701],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.024, 9.473],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.232, 11.702],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-247.339, 13.541],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.499, 15.102],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-243.665, 16.477],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-241.879, 17.676],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.141, 18.778],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-238.505, 19.81],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-236.928, 20.763],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-235.425, 21.626],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.993, 22.465],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-232.661, 23.228],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-231.397, 23.975],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.281, 24.684],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.121, 25.34],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.075, 25.975],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.09, 26.58],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-226.127, 27.18],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.249, 27.742],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.332, 28.254],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-223.478, 28.741],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.705, 29.243],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.928, 29.705],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.176, 30.133],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.438, 30.574],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.72, 30.989],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.095, 31.367],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.462, 31.725],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.877, 32.093],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.299, 32.431],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.749, 32.733],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.244, 33.015],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.755, 33.31],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.349, 33.574],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.902, 33.832],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.506, 34.049],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.161, 34.261],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.834, 34.458],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.511, 34.646],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.209, 34.819],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.999, 34.982],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.768, 35.109],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.605, 35.227],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.402, 35.327],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.232, 35.423],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.119, 35.513],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.029, 35.585],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.973, 35.652],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.936, 35.678],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.934, 35.738],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.996, 35.742],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.937, 35.799],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.805, 35.811],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.635, 35.874],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.39, 35.969],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.073, 36.084],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.737, 36.222],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.26, 36.356],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.644, 36.547],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.991, 36.789],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.177, 37.046],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.2, 37.345],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.095, 37.705],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.765, 38.118],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.146, 38.59],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.235, 39.072],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.955, 39.653],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.158, 40.247],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.738, 40.797],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.417, 41.24],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.866, 41.343],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.552, 40.711],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.829, 38.672],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.011, 34.4],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.031, 27.531],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.727, 18.43],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.444, 7.846],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.516, -3.063],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.654, -13.762],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.328, -23.812],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.505, -33.172],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.174, -41.734],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.095, -49.601],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.844, -56.791],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.799, -63.427],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.89, -69.502],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.223, -75.11],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.866, -80.31],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.935, -85.116],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.487, -89.647],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.593, -93.86],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.225, -97.749],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.518, -101.423],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.457, -104.824],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.073, -108.11],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.404, -111.127],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.47, -113.953],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.319, -116.655],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.9, -119.229],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.295, -121.6],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.476, -123.839],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.488, -125.996],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.311, -127.984],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.976, -129.885],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.514, -131.68],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.912, -133.376],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.15, -134.991],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.288, -136.58],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.281, -137.95],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.168, -139.307],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.974, -140.619],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.631, -141.806],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.225, -142.936],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.688, -144.039],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.111, -145.01],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.402, -145.954],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.643, -146.857],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.795, -147.668],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.895, -148.509],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.879, -149.166],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.829, -149.915],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.694, -150.505],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.504, -151.12],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.236, -151.66],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.935, -152.145],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.569, -152.576],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.142, -152.975],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.651, -153.343],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.109, -153.731],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.51, -153.998],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.884, -154.227],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.183, -154.471],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.462, -154.681],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.674, -154.835],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.894, -154.975],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.025, -155.063],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.094, -155.109],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.085, -155.2],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.825, -155.262],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.376, -155.344],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.705, -155.395],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.783, -155.526],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.664, -155.719],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.241, -155.922],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.534, -156.201],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.488, -156.5],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.093, -156.823],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.324, -157.245],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.112, -157.726],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.418, -158.261],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.125, -158.926],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.243, -159.619],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.656, -160.445],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.191, -161.402],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.82, -162.497],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.343, -163.782],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.542, -165.223],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.269, -166.885],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.181, -168.841],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.106, -171.119],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.808, -173.736],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.279, -176.727],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.11, -180.004],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-41.572, -183.498],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.102, -187.056],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.594, -190.442],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.484, -193.635],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.572, -196.524],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-139.907, -199.187],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.569, -201.561],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.759, -203.705],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.721, -205.656],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-190.511, -207.429],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.393, -209.045],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.463, -210.489],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.758, -211.846],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.398, -213.08],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-232.496, -214.235],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-239.048, -215.294],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.169, -216.262],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.812, -217.157],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.137, -217.988],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-261.076, -218.787],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-265.753, -219.494],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-270.084, -220.198],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-274.196, -220.803],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.029, -221.408],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-281.644, -221.952],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-285.05, -222.465],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-288.239, -222.925],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-291.256, -223.395],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.078, -223.829],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-296.761, -224.244],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-299.327, -224.582],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-301.696, -224.943],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.934, -225.284],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-306.038, -225.587],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-308.044, -225.868],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-309.93, -226.161],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-311.716, -226.396],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-313.389, -226.64],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-314.956, -226.872],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-316.38, -227.074],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-317.779, -227.274],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-319.082, -227.455],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-320.298, -227.618],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-321.418, -227.789],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.463, -227.923],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-323.456, -228.081],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-324.366, -228.193],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-325.196, -228.326],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-325.966, -228.421],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-326.703, -228.497],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-327.351, -228.615],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-327.951, -228.7],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-328.456, -228.753],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-328.983, -228.824],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-329.403, -228.843],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-329.763, -228.921],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.101, -228.959],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.406, -229.008],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.605, -229.058],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.786, -229.069],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-331.038, -229.089],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-331.089, -229.055],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.989, -228.849],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.819, -228.58],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.611, -228.159],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.278, -227.596],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-329.847, -226.827],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-329.387, -225.938],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-328.757, -224.808],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-328.036, -223.54],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-327.202, -222.028],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-326.237, -220.175],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-325.1, -218.103],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-323.766, -215.699],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.264, -212.854],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-320.566, -209.608],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-318.625, -205.905],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-316.395, -201.683],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-313.884, -196.665],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-310.995, -190.921],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-307.729, -184.224],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.983, -176.434],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-299.822, -167.286],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.09, -156.625],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-289.918, -144.145],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-284.453, -129.916],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.855, -114.1],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-273.638, -97.226],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-269.047, -80.297],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-265.411, -64.047],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-262.657, -49.213],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-260.636, -36.085],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-259.181, -24.771],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-258.025, -15.302],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.896, -7.668],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.693, -1.598],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.322, 3.074],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.759, 6.701],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.024, 9.473],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.232, 11.702],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-247.339, 13.541],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.499, 15.102],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-243.665, 16.477],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-241.879, 17.676],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.141, 18.778],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-238.505, 19.81],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-236.928, 20.763],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-235.425, 21.626],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.993, 22.465],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-232.661, 23.228],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-231.397, 23.975],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.281, 24.684],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.121, 25.34],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.075, 25.975],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.09, 26.58],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-226.127, 27.18],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.249, 27.742],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.332, 28.254],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-223.478, 28.741],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.705, 29.243],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.928, 29.705],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.176, 30.133],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.438, 30.574],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.72, 30.989],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.095, 31.367],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.462, 31.725],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.877, 32.093],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.299, 32.431],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.749, 32.733],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.244, 33.015],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.755, 33.31],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.349, 33.574],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.902, 33.832],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.506, 34.049],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.161, 34.261],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.834, 34.458],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.511, 34.646],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.209, 34.819],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.999, 34.982],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.768, 35.109],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.605, 35.227],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.402, 35.327],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.232, 35.423],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.119, 35.513],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.029, 35.585],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.973, 35.652],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.936, 35.678],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.934, 35.738],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.996, 35.742],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.937, 35.799],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.805, 35.811],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.635, 35.874],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.39, 35.969],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.073, 36.084],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.737, 36.222],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.26, 36.356],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.644, 36.547],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.991, 36.789],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.177, 37.046],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.2, 37.345],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.095, 37.705],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.765, 38.118],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.146, 38.59],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.235, 39.072],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.955, 39.653],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.158, 40.247],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.738, 40.797],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.417, 41.24],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.866, 41.343],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.552, 40.711],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.829, 38.672],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.011, 34.4],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.031, 27.531],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.727, 18.43],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.444, 7.846],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.516, -3.063],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.654, -13.762],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.328, -23.812],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.505, -33.172],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.174, -41.734],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.095, -49.601],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.844, -56.791],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.799, -63.427],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.89, -69.502],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.223, -75.11],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.866, -80.31],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.935, -85.116],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.487, -89.647],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.593, -93.86],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.225, -97.749],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.518, -101.423],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.457, -104.824],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.073, -108.11],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.404, -111.127],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.47, -113.953],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.319, -116.655],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.9, -119.229],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.295, -121.6],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.476, -123.839],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.488, -125.996],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.311, -127.984],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.976, -129.885],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.514, -131.68],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.912, -133.376],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.15, -134.991],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.288, -136.58],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.281, -137.95],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.168, -139.307],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.974, -140.619],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.631, -141.806],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.225, -142.936],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.688, -144.039],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.111, -145.01],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.402, -145.954],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.643, -146.857],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.795, -147.668],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.895, -148.509],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.879, -149.166],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.829, -149.915],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.694, -150.505],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.504, -151.12],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.236, -151.66],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.935, -152.145],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.569, -152.576],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.142, -152.975],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.651, -153.343],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.109, -153.731],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.51, -153.998],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.884, -154.227],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.183, -154.471],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.462, -154.681],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.674, -154.835],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.894, -154.975],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.025, -155.063],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.094, -155.109],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.085, -155.2],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.825, -155.262],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.376, -155.344],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.705, -155.395],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.783, -155.526],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.664, -155.719],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.241, -155.922],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.534, -156.201],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.488, -156.5],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.093, -156.823],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.324, -157.245],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.112, -157.726],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.418, -158.261],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.125, -158.926],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.243, -159.619],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.656, -160.445],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.191, -161.402],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.82, -162.497],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.343, -163.782],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.542, -165.223],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.269, -166.885],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.181, -168.841],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.106, -171.119],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.808, -173.736],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.279, -176.727],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.11, -180.004],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-41.572, -183.498],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.102, -187.056],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.594, -190.442],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.484, -193.635],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.572, -196.524],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-139.907, -199.187],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.569, -201.561],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.759, -203.705],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.721, -205.656],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-190.511, -207.429],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.393, -209.045],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.463, -210.489],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.758, -211.846],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.398, -213.08],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-232.496, -214.235],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-239.048, -215.294],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.169, -216.262],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.812, -217.157],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.137, -217.988],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-261.076, -218.787],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-265.753, -219.494],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-270.084, -220.198],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-274.196, -220.803],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.029, -221.408],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-281.644, -221.952],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-285.05, -222.465],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-288.239, -222.925],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-291.256, -223.395],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.078, -223.829],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-296.761, -224.244],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-299.327, -224.582],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-301.696, -224.943],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.934, -225.284],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-306.038, -225.587],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-308.044, -225.868],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-309.93, -226.161],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-311.716, -226.396],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-313.389, -226.64],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-314.956, -226.872],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-316.38, -227.074],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-317.779, -227.274],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-319.082, -227.455],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-320.298, -227.618],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-321.418, -227.789],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.463, -227.923],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-323.456, -228.081],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-324.366, -228.193],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-325.196, -228.326],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-325.966, -228.421],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-326.703, -228.497],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-327.351, -228.615],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-327.951, -228.7],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-328.456, -228.753],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-328.983, -228.824],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-329.403, -228.843],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-329.763, -228.921],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.101, -228.959],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.406, -229.008],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.605, -229.058],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-330.786, -229.069],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-331.038, -229.089],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 2',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.504, 121.676],
                                  [-338.242, -305.415],
                                  [-427.028, -104.02],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.652, 121.751],
                                  [-338.025, -305.232],
                                  [-426.741, -104.089],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.848, 121.836],
                                  [-337.752, -304.894],
                                  [-426.426, -104.138],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.153, 121.985],
                                  [-337.307, -304.464],
                                  [-425.903, -104.221],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.497, 122.198],
                                  [-336.753, -303.888],
                                  [-425.244, -104.331],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.951, 122.422],
                                  [-336.143, -303.219],
                                  [-424.495, -104.475],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.498, 122.719],
                                  [-335.317, -302.35],
                                  [-423.556, -104.654],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.093, 123.079],
                                  [-334.365, -301.381],
                                  [-422.427, -104.806],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.844, 123.459],
                                  [-333.259, -300.221],
                                  [-421.1, -105.029],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.744, 123.911],
                                  [-331.974, -298.814],
                                  [-419.529, -105.333],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.746, 124.482],
                                  [-330.457, -297.262],
                                  [-417.722, -105.656],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.889, 125.08],
                                  [-328.669, -295.402],
                                  [-415.618, -105.989],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.244, 125.806],
                                  [-326.641, -293.25],
                                  [-413.223, -106.45],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.787, 126.612],
                                  [-324.335, -290.797],
                                  [-410.43, -106.959],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.543, 127.508],
                                  [-321.672, -287.989],
                                  [-407.191, -107.527],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.589, 128.593],
                                  [-318.56, -284.772],
                                  [-403.507, -108.144],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.941, 129.83],
                                  [-315.038, -281.005],
                                  [-399.229, -108.959],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.595, 131.21],
                                  [-310.934, -276.673],
                                  [-394.295, -109.848],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.704, 132.813],
                                  [-306.186, -271.618],
                                  [-388.609, -110.867],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-231.311, 134.691],
                                  [-300.624, -265.801],
                                  [-381.906, -112.102],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-235.525, 136.853],
                                  [-294.245, -259.002],
                                  [-374.191, -113.531],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-240.35, 139.362],
                                  [-286.755, -251.117],
                                  [-365.151, -115.196],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.929, 142.231],
                                  [-278.145, -241.932],
                                  [-354.734, -117.139],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-252.272, 145.462],
                                  [-268.413, -231.553],
                                  [-342.887, -119.355],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-259.277, 149.036],
                                  [-257.577, -220.076],
                                  [-329.793, -121.828],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.713, 152.822],
                                  [-246.204, -207.917],
                                  [-315.929, -124.508],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-274.233, 156.66],
                                  [-234.677, -195.682],
                                  [-301.937, -127.141],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-281.471, 160.311],
                                  [-223.685, -183.854],
                                  [-288.441, -129.753],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-288.304, 163.733],
                                  [-213.424, -172.846],
                                  [-275.903, -132.219],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.567, 166.827],
                                  [-204.066, -162.763],
                                  [-264.419, -134.523],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.315, 169.687],
                                  [-195.668, -153.653],
                                  [-253.998, -136.585],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-305.595, 172.265],
                                  [-188.114, -145.424],
                                  [-244.595, -138.522],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-310.407, 174.578],
                                  [-181.297, -138.016],
                                  [-236.105, -140.261],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.805, 176.681],
                                  [-175.209, -131.227],
                                  [-228.428, -141.891],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-318.94, 178.637],
                                  [-169.674, -125.113],
                                  [-221.407, -143.409],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-322.75, 180.447],
                                  [-164.699, -119.463],
                                  [-214.99, -144.856],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-326.306, 182.062],
                                  [-160.163, -114.333],
                                  [-209.091, -146.224],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.697, 183.594],
                                  [-156.066, -109.615],
                                  [-203.696, -147.505],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-332.876, 185.003],
                                  [-152.294, -105.206],
                                  [-198.656, -148.748],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-335.948, 186.329],
                                  [-148.902, -101.164],
                                  [-194.021, -149.953],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-338.866, 187.588],
                                  [-145.79, -97.426],
                                  [-189.751, -151.051],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-341.673, 188.723],
                                  [-142.993, -93.903],
                                  [-185.727, -152.222],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.378, 189.791],
                                  [-140.416, -90.632],
                                  [-182.015, -153.28],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-346.98, 190.848],
                                  [-138.108, -87.626],
                                  [-178.502, -154.343],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-349.53, 191.838],
                                  [-136.015, -84.774],
                                  [-175.246, -155.411],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-352.033, 192.711],
                                  [-134.135, -82.075],
                                  [-172.19, -156.47],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-354.475, 193.63],
                                  [-132.405, -79.587],
                                  [-169.335, -157.526],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.865, 194.432],
                                  [-130.885, -77.252],
                                  [-166.63, -158.577],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-359.246, 195.227],
                                  [-129.506, -75.012],
                                  [-164.125, -159.62],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-361.555, 195.96],
                                  [-128.319, -72.976],
                                  [-161.717, -160.587],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-363.78, 196.681],
                                  [-127.207, -70.987],
                                  [-159.449, -161.644],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-365.967, 197.341],
                                  [-126.205, -69.143],
                                  [-157.322, -162.607],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-368.095, 197.987],
                                  [-125.313, -67.394],
                                  [-155.286, -163.595],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-370.107, 198.628],
                                  [-124.511, -65.679],
                                  [-153.395, -164.537],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-372.094, 199.2],
                                  [-123.742, -64.112],
                                  [-151.532, -165.422],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.933, 199.704],
                                  [-122.991, -62.582],
                                  [-149.813, -166.301],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.684, 200.2],
                                  [-122.32, -61.149],
                                  [-148.125, -167.181],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-377.393, 200.68],
                                  [-121.656, -59.805],
                                  [-146.589, -167.928],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-378.942, 201.158],
                                  [-121.071, -58.506],
                                  [-145.083, -168.725],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-380.452, 201.562],
                                  [-120.446, -57.301],
                                  [-143.676, -169.449],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-381.808, 201.961],
                                  [-119.895, -56.085],
                                  [-142.306, -170.101],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-383.123, 202.35],
                                  [-119.304, -54.971],
                                  [-141.04, -170.743],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-384.343, 202.675],
                                  [-118.802, -53.958],
                                  [-139.815, -171.31],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-385.532, 202.992],
                                  [-118.321, -52.94],
                                  [-138.633, -171.928],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.631, 203.305],
                                  [-117.818, -52.023],
                                  [-137.56, -172.414],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.645, 203.61],
                                  [-117.403, -51.102],
                                  [-136.532, -172.952],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-388.574, 203.853],
                                  [-116.964, -50.288],
                                  [-135.556, -173.419],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-389.482, 204.09],
                                  [-116.564, -49.528],
                                  [-134.629, -173.877],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-390.304, 204.324],
                                  [-116.201, -48.759],
                                  [-133.814, -174.269],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-391.112, 204.555],
                                  [-115.882, -48.104],
                                  [-132.993, -174.655],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-391.839, 204.781],
                                  [-115.546, -47.444],
                                  [-132.283, -175.034],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-392.551, 204.945],
                                  [-115.256, -46.84],
                                  [-131.565, -175.405],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-393.188, 205.104],
                                  [-115.01, -46.289],
                                  [-130.903, -175.712],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-393.813, 205.265],
                                  [-114.751, -45.794],
                                  [-130.356, -176.014],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-394.362, 205.418],
                                  [-114.542, -45.297],
                                  [-129.803, -176.31],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-394.9, 205.573],
                                  [-114.322, -44.856],
                                  [-129.307, -176.602],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-395.371, 205.723],
                                  [-114.212, -44.47],
                                  [-128.809, -176.832],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-395.827, 205.812],
                                  [-114.034, -44.142],
                                  [-128.424, -177.055],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-396.275, 205.898],
                                  [-113.965, -43.812],
                                  [-128.038, -177.277],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-396.655, 205.985],
                                  [-113.828, -43.54],
                                  [-127.707, -177.496],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-397.025, 206.069],
                                  [-113.746, -43.267],
                                  [-127.437, -177.708],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-397.33, 206.152],
                                  [-113.716, -43.049],
                                  [-127.162, -177.862],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-397.624, 206.23],
                                  [-113.68, -42.89],
                                  [-126.943, -178.011],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-397.913, 206.309],
                                  [-113.699, -42.729],
                                  [-126.786, -178.156],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.197, 206.327],
                                  [-113.712, -42.628],
                                  [-126.685, -178.3],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.413, 206.404],
                                  [-113.779, -42.523],
                                  [-126.583, -178.384],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.562, 206.42],
                                  [-113.841, -42.48],
                                  [-126.48, -178.524],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.768, 206.433],
                                  [-113.901, -42.433],
                                  [-126.433, -178.601],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.908, 206.446],
                                  [-114.016, -42.446],
                                  [-126.446, -178.677],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.985, 206.456],
                                  [-114.069, -42.518],
                                  [-126.336, -178.748],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-399.003, 206.519],
                                  [-113.998, -42.65],
                                  [-126.046, -178.693],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.904, 206.637],
                                  [-113.811, -42.841],
                                  [-125.508, -178.574],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.685, 206.747],
                                  [-113.44, -43.211],
                                  [-124.79, -178.444],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.352, 206.912],
                                  [-112.951, -43.641],
                                  [-123.706, -178.189],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-397.84, 207.129],
                                  [-112.283, -44.191],
                                  [-122.377, -177.808],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-397.21, 207.398],
                                  [-111.433, -44.86],
                                  [-120.744, -177.359],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-396.469, 207.72],
                                  [-110.408, -45.71],
                                  [-118.744, -176.78],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-395.55, 208.032],
                                  [-109.144, -46.679],
                                  [-116.437, -176.134],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-394.397, 208.455],
                                  [-107.641, -47.769],
                                  [-113.645, -175.362],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-393.074, 208.988],
                                  [-105.902, -49.098],
                                  [-110.364, -174.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-391.519, 209.567],
                                  [-103.806, -50.668],
                                  [-106.594, -173.303],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-389.674, 210.195],
                                  [-101.414, -52.419],
                                  [-102.276, -172.018],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.601, 210.989],
                                  [-98.667, -54.47],
                                  [-97.285, -170.54],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-385.122, 211.887],
                                  [-95.506, -56.822],
                                  [-91.563, -168.808],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-382.3, 212.885],
                                  [-91.812, -59.475],
                                  [-84.986, -166.823],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-379.076, 213.983],
                                  [-87.587, -62.549],
                                  [-77.432, -164.578],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.337, 215.356],
                                  [-82.712, -66.104],
                                  [-68.719, -161.954],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-370.963, 216.881],
                                  [-77.069, -70.202],
                                  [-58.663, -158.885],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-365.963, 218.614],
                                  [-70.602, -74.902],
                                  [-47.081, -155.372],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.16, 220.666],
                                  [-63.014, -80.384],
                                  [-33.726, -151.345],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-353.382, 223.034],
                                  [-54.246, -86.71],
                                  [-18.175, -146.623],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-345.519, 225.77],
                                  [-44.061, -94],
                                  [-0.123, -141.142],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-336.45, 228.929],
                                  [-32.344, -102.434],
                                  [20.617, -134.833],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-326.118, 232.513],
                                  [-19.094, -112.014],
                                  [44.166, -127.637],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.754, 236.466],
                                  [-4.424, -122.559],
                                  [70.098, -119.742],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.757, 240.691],
                                  [11.134, -133.708],
                                  [97.5, -111.402],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.635, 244.858],
                                  [26.758, -144.979],
                                  [125.149, -102.996],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.96, 248.932],
                                  [41.801, -155.772],
                                  [151.705, -94.911],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-268.016, 252.749],
                                  [55.853, -165.845],
                                  [176.554, -87.334],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-258.096, 256.254],
                                  [68.735, -175.077],
                                  [199.211, -80.451],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.021, 259.388],
                                  [80.384, -183.467],
                                  [219.736, -74.202],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-240.857, 262.27],
                                  [90.861, -191.016],
                                  [238.31, -68.587],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-233.49, 264.828],
                                  [100.401, -197.842],
                                  [255.176, -63.481],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-226.744, 267.182],
                                  [109.061, -204.068],
                                  [270.397, -58.818],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.624, 269.325],
                                  [116.957, -209.752],
                                  [284.339, -54.597],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.016, 271.313],
                                  [124.211, -214.954],
                                  [297.119, -50.755],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.863, 273.143],
                                  [130.875, -219.735],
                                  [308.804, -47.229],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.107, 274.816],
                                  [136.952, -224.094],
                                  [319.632, -43.959],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.693, 276.384],
                                  [142.617, -228.152],
                                  [329.609, -40.941],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.621, 277.848],
                                  [147.874, -231.969],
                                  [338.851, -38.113],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.773, 279.144],
                                  [152.776, -235.485],
                                  [347.485, -35.535],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.273, 280.454],
                                  [157.327, -238.76],
                                  [355.507, -33.087],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.938, 281.593],
                                  [161.583, -241.793],
                                  [363.039, -30.828],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.833, 282.681],
                                  [165.604, -244.646],
                                  [370.025, -28.755],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.96, 283.719],
                                  [169.33, -247.377],
                                  [376.643, -26.747],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.196, 284.706],
                                  [172.823, -249.867],
                                  [382.832, -24.868],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.605, 285.638],
                                  [176.137, -252.236],
                                  [388.596, -23.113],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.184, 286.516],
                                  [179.277, -254.485],
                                  [394.112, -21.482],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.939, 287.342],
                                  [182.178, -256.612],
                                  [399.264, -19.916],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.806, 288.052],
                                  [184.963, -258.558],
                                  [404.109, -18.476],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.787, 288.827],
                                  [187.572, -260.444],
                                  [408.648, -17.098],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.823, 289.488],
                                  [190.002, -262.208],
                                  [413.004, -15.845],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.034, 290.153],
                                  [192.313, -263.852],
                                  [417.056, -14.595],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.359, 290.761],
                                  [194.508, -265.435],
                                  [420.922, -13.468],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.743, 291.312],
                                  [196.585, -266.896],
                                  [424.544, -12.344],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.18, 291.867],
                                  [198.545, -268.297],
                                  [427.98, -11.343],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.794, 292.426],
                                  [200.385, -269.638],
                                  [431.235, -10.344],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.403, 292.868],
                                  [202.106, -270.917],
                                  [434.306, -9.406],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.132, 293.371],
                                  [203.77, -272.076],
                                  [437.191, -8.593],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.914, 293.758],
                                  [205.314, -273.173],
                                  [439.895, -7.782],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.757, 294.206],
                                  [206.8, -274.271],
                                  [442.476, -6.97],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.715, 294.598],
                                  [208.166, -275.247],
                                  [444.933, -6.282],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.671, 294.93],
                                  [209.474, -276.162],
                                  [447.207, -5.597],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.745, 295.327],
                                  [210.664, -277.077],
                                  [449.357, -4.911],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.816, 295.602],
                                  [211.853, -277.871],
                                  [451.386, -4.349],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.005, 295.941],
                                  [212.923, -278.665],
                                  [453.291, -3.787],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.193, 296.221],
                                  [213.935, -279.397],
                                  [455.074, -3.227],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.439, 296.503],
                                  [214.886, -280.069],
                                  [456.735, -2.728],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.741, 296.725],
                                  [215.779, -280.741],
                                  [458.333, -2.229],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.102, 296.949],
                                  [216.613, -281.291],
                                  [459.747, -1.855],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-142.524, 297.175],
                                  [217.387, -281.902],
                                  [461.161, -1.418],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.942, 297.402],
                                  [218.102, -282.391],
                                  [462.391, -1.045],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.418, 297.57],
                                  [218.756, -282.88],
                                  [463.561, -0.672],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.955, 297.74],
                                  [219.352, -283.308],
                                  [464.667, -0.361],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.488, 297.908],
                                  [219.947, -283.736],
                                  [465.652, -0.11],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.082, 298.079],
                                  [220.484, -284.103],
                                  [466.514, 0.2],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.733, 298.19],
                                  [220.96, -284.409],
                                  [467.375, 0.448],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.383, 298.303],
                                  [221.378, -284.715],
                                  [468.113, 0.636],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.093, 298.417],
                                  [221.735, -285.021],
                                  [468.791, 0.884],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.863, 298.531],
                                  [222.093, -285.265],
                                  [469.405, 1.01],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.569, 298.646],
                                  [222.391, -285.449],
                                  [469.898, 1.195],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.396, 298.703],
                                  [222.629, -285.633],
                                  [470.39, 1.319],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.221, 298.758],
                                  [222.868, -285.816],
                                  [470.76, 1.444],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.104, 298.816],
                                  [223.047, -285.939],
                                  [471.068, 1.567],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.928, 298.872],
                                  [223.227, -286.061],
                                  [471.374, 1.63],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.871, 298.87],
                                  [223.344, -286.123],
                                  [471.561, 1.692],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.814, 298.93],
                                  [223.406, -286.184],
                                  [471.683, 1.693],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.755, 298.928],
                                  [223.464, -286.245],
                                  [471.806, 1.755],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.755, 298.927],
                                  [223.342, -286.243],
                                  [471.622, 1.755],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.819, 298.795],
                                  [223.035, -286.294],
                                  [471.128, 1.692],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.883, 298.601],
                                  [222.481, -286.337],
                                  [470.267, 1.627],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.009, 298.396],
                                  [221.684, -286.375],
                                  [468.974, 1.561],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.2, 298.064],
                                  [220.579, -286.465],
                                  [467.249, 1.433],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.391, 297.604],
                                  [219.228, -286.606],
                                  [465.093, 1.241],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.645, 297.072],
                                  [217.511, -286.737],
                                  [462.385, 1.046],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.901, 296.413],
                                  [215.425, -286.918],
                                  [459.123, 0.789],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.282, 295.682],
                                  [212.971, -287.149],
                                  [455.248, 0.469],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.726, 294.817],
                                  [210.089, -287.366],
                                  [450.696, 0.145],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.171, 293.76],
                                  [206.719, -287.627],
                                  [445.407, -0.245],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.74, 292.567],
                                  [202.861, -287.991],
                                  [439.262, -0.762],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.374, 291.177],
                                  [198.391, -288.338],
                                  [432.197, -1.285],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-142.13, 289.588],
                                  [193.194, -288.78],
                                  [424.093, -1.935],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.011, 287.737],
                                  [187.327, -289.255],
                                  [414.708, -2.659],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.955, 285.623],
                                  [180.549, -289.76],
                                  [404.043, -3.452],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.084, 283.243],
                                  [172.743, -290.41],
                                  [391.734, -4.442],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.395, 280.47],
                                  [163.85, -291.129],
                                  [377.665, -5.573],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.828, 277.243],
                                  [153.573, -291.915],
                                  [361.478, -6.852],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.559, 273.556],
                                  [141.737, -292.813],
                                  [342.757, -8.341],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.468, 269.345],
                                  [128.112, -293.862],
                                  [321.15, -10.116],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.73, 264.423],
                                  [112.293, -295.042],
                                  [296.136, -12.19],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.277, 258.725],
                                  [94.057, -296.385],
                                  [267.258, -14.644],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.215, 252.194],
                                  [73.141, -297.857],
                                  [234.138, -17.499],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.525, 244.777],
                                  [49.529, -299.429],
                                  [196.778, -20.783],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.075, 236.672],
                                  [23.695, -301.082],
                                  [155.734, -24.518],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.729, 228.155],
                                  [-3.42, -302.707],
                                  [112.692, -28.59],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.413, 219.621],
                                  [-30.537, -304.24],
                                  [69.661, -32.855],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.779, 211.46],
                                  [-56.365, -305.506],
                                  [28.491, -37.042],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.898, 203.867],
                                  [-80.171, -306.572],
                                  [-9.555, -41.103],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.614, 197.022],
                                  [-101.761, -307.372],
                                  [-44.011, -44.89],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.003, 190.859],
                                  [-121.184, -308.053],
                                  [-74.986, -48.487],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.131, 185.359],
                                  [-138.529, -308.565],
                                  [-102.775, -51.832],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.006, 180.398],
                                  [-154.065, -308.921],
                                  [-127.715, -54.903],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.643, 175.903],
                                  [-168.102, -309.173],
                                  [-150.238, -57.767],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.047, 171.868],
                                  [-180.718, -309.379],
                                  [-170.608, -60.472],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.332, 168.216],
                                  [-192.216, -309.533],
                                  [-189.083, -63.019],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.507, 164.894],
                                  [-202.719, -309.576],
                                  [-205.962, -65.352],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.516, 161.828],
                                  [-212.298, -309.622],
                                  [-221.436, -67.587],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.469, 159.022],
                                  [-221.072, -309.606],
                                  [-235.686, -69.666],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.263, 156.405],
                                  [-229.216, -309.583],
                                  [-248.779, -71.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.059, 154.039],
                                  [-236.68, -309.557],
                                  [-260.894, -73.533],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.755, 151.8],
                                  [-243.639, -309.454],
                                  [-272.149, -75.273],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.349, 149.746],
                                  [-250.039, -309.345],
                                  [-282.606, -76.919],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.948, 147.878],
                                  [-256.054, -309.274],
                                  [-292.333, -78.53],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.443, 146.075],
                                  [-261.622, -309.183],
                                  [-301.383, -80.054],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.948, 144.39],
                                  [-266.866, -309.016],
                                  [-309.877, -81.433],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.353, 142.832],
                                  [-271.723, -308.937],
                                  [-317.813, -82.839],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.764, 141.387],
                                  [-276.315, -308.78],
                                  [-325.248, -84.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.133, 140.009],
                                  [-280.581, -308.655],
                                  [-332.253, -85.337],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.511, 138.746],
                                  [-284.583, -308.512],
                                  [-338.81, -86.482],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.788, 137.541],
                                  [-288.373, -308.385],
                                  [-344.942, -87.606],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.068, 136.454],
                                  [-291.901, -308.239],
                                  [-350.746, -88.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.366, 135.373],
                                  [-295.218, -308.114],
                                  [-356.167, -89.665],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.613, 134.4],
                                  [-298.325, -308.017],
                                  [-361.277, -90.653],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.818, 133.432],
                                  [-301.275, -307.892],
                                  [-366.118, -91.571],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.084, 132.57],
                                  [-304.078, -307.732],
                                  [-370.634, -92.402],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.246, 131.717],
                                  [-306.672, -307.59],
                                  [-374.951, -93.215],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.421, 130.921],
                                  [-309.119, -307.481],
                                  [-378.944, -94.004],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.604, 130.229],
                                  [-311.412, -307.382],
                                  [-382.733, -94.768],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.739, 129.544],
                                  [-313.599, -307.26],
                                  [-386.316, -95.458],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.889, 128.858],
                                  [-315.648, -307.147],
                                  [-389.694, -96.13],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.039, 128.282],
                                  [-317.59, -307.057],
                                  [-392.805, -96.784],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.205, 127.704],
                                  [-319.387, -306.937],
                                  [-395.821, -97.363],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.317, 127.124],
                                  [-321.077, -306.823],
                                  [-398.578, -97.924],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.382, 126.659],
                                  [-322.622, -306.739],
                                  [-401.186, -98.465],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.519, 126.191],
                                  [-324.132, -306.662],
                                  [-403.636, -98.987],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.602, 125.721],
                                  [-325.534, -306.552],
                                  [-405.944, -99.442],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.694, 125.309],
                                  [-326.839, -306.455],
                                  [-408.095, -99.878],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.732, 124.894],
                                  [-328.052, -306.38],
                                  [-410.096, -100.302],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.842, 124.584],
                                  [-329.168, -306.317],
                                  [-411.947, -100.699],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.898, 124.223],
                                  [-330.238, -306.266],
                                  [-413.703, -101.091],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.964, 123.912],
                                  [-331.21, -306.173],
                                  [-415.309, -101.409],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.039, 123.605],
                                  [-332.084, -306.099],
                                  [-416.819, -101.715],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.061, 123.347],
                                  [-332.92, -306.037],
                                  [-418.171, -102.001],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.147, 123.146],
                                  [-333.704, -305.986],
                                  [-419.427, -102.275],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.179, 122.886],
                                  [-334.396, -305.946],
                                  [-420.595, -102.538],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.221, 122.683],
                                  [-335.044, -305.924],
                                  [-421.604, -102.788],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.219, 122.529],
                                  [-335.583, -305.858],
                                  [-422.571, -102.972],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.272, 122.323],
                                  [-336.14, -305.803],
                                  [-423.441, -103.145],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.272, 122.22],
                                  [-336.605, -305.756],
                                  [-424.16, -103.297],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.345, 122.065],
                                  [-336.963, -305.72],
                                  [-424.828, -103.446],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.356, 121.966],
                                  [-337.328, -305.7],
                                  [-425.406, -103.575],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.385, 121.861],
                                  [-337.655, -305.69],
                                  [-425.942, -103.7],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.353, 121.758],
                                  [-337.874, -305.688],
                                  [-426.317, -103.814],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.384, 121.703],
                                  [-338.055, -305.641],
                                  [-426.648, -103.854],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.371, 121.659],
                                  [-338.235, -305.664],
                                  [-426.889, -103.945],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.283, 0.283, 0.283, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.283, 0.283, 0.283, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-304.904, -197.219],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.813, -197.04],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.666, -196.811],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.485, -196.449],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.193, -195.965],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.813, -195.303],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.411, -194.536],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.857, -193.553],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.217, -192.453],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-301.484, -191.139],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-300.632, -189.524],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-299.625, -187.725],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-298.445, -185.635],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-297.113, -183.161],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.609, -180.335],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-293.883, -177.106],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-291.894, -173.4],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-289.655, -168.991],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-287.076, -163.963],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-284.154, -158.082],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-280.793, -151.217],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-277.065, -143.18],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-272.811, -133.784],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-268.155, -122.812],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-263.223, -110.323],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-258.146, -96.492],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.373, -81.822],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.098, -67.231],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.591, -53.384],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-242.771, -40.934],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.495, -30.095],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-238.634, -20.897],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-236.987, -13.265],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-235.381, -7.07],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.801, -1.991],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-232.206, 2.137],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.592, 5.572],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.955, 8.39],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.374, 10.802],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.782, 12.895],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.304, 14.731],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.882, 16.375],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.541, 17.833],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.278, 19.169],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.145, 20.414],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.085, 21.549],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.122, 22.577],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.239, 23.555],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.47, 24.44],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.77, 25.282],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.219, 26.072],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.63, 26.795],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.139, 27.481],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.696, 28.122],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.255, 28.75],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.875, 29.331],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.428, 29.853],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.019, 30.345],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.653, 30.844],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.261, 31.303],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.867, 31.727],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.463, 32.161],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.048, 32.562],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.706, 32.93],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.335, 33.274],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.991, 33.627],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.641, 33.951],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.301, 34.239],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.997, 34.51],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.692, 34.792],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.46, 35.046],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.172, 35.288],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.929, 35.491],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.725, 35.691],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.528, 35.873],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.33, 36.048],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.145, 36.207],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.047, 36.36],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.918, 36.474],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.853, 36.581],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.742, 36.669],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.66, 36.754],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.63, 36.834],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.617, 36.895],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.635, 36.952],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.668, 36.968],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.764, 37.004],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.947, 36.995],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.085, 36.984],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.09, 37.036],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.025, 37.044],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.928, 37.106],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.762, 37.201],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.528, 37.316],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.285, 37.458],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.901, 37.591],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.389, 37.785],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.857, 38.037],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.174, 38.305],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.334, 38.611],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.39, 38.989],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.241, 39.425],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.817, 39.915],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.138, 40.433],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.114, 41.048],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-191.619, 41.693],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.547, 42.307],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.62, 42.82],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.522, 43.009],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.685, 42.437],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.407, 40.376],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.929, 35.925],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.204, 28.742],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.155, 19.311],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.165, 8.457],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.558, -2.653],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.022, -13.494],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.005, -23.65],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.471, -33.088],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.406, -41.704],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.568, -49.615],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.152, -56.844],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.904, -63.505],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.815, -69.607],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.977, -75.233],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.462, -80.442],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.395, -85.273],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.814, -89.811],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.795, -94.029],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.319, -97.934],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.509, -101.618],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.346, -105.023],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.869, -108.31],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.113, -111.328],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.109, -114.176],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.883, -116.882],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.393, -119.456],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.719, -121.826],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.837, -124.066],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.788, -126.222],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.561, -128.219],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.183, -130.133],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.675, -131.934],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.026, -133.629],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.22, -135.244],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.316, -136.831],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.27, -138.201],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.121, -139.556],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.892, -140.867],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.516, -142.053],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.08, -143.182],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.52, -144.29],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.919, -145.269],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.191, -146.219],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.412, -147.128],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.545, -147.945],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.626, -148.786],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.592, -149.443],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.525, -150.19],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.373, -150.78],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.168, -151.394],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.886, -151.932],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.571, -152.418],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.193, -152.849],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.757, -153.247],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.255, -153.615],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.706, -154.001],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.099, -154.268],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.466, -154.497],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.759, -154.741],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.033, -154.951],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.242, -155.104],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.457, -155.245],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.585, -155.332],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.653, -155.379],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.643, -155.469],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.385, -155.527],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.94, -155.603],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.274, -155.648],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.359, -155.769],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.247, -155.949],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.837, -156.136],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.143, -156.395],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.113, -156.672],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.737, -156.967],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.991, -157.354],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.805, -157.796],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.142, -158.286],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.888, -158.893],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.051, -159.52],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.518, -160.267],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.117, -161.131],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.823, -162.111],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.44, -163.255],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.756, -164.525],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.626, -165.972],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.721, -167.655],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.876, -169.586],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.871, -171.752],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.718, -174.163],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.195, -176.711],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.075, -179.318],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.914, -181.862],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.635, -184.138],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.695, -186.165],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.916, -187.873],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.366, -189.367],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.137, -190.599],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.439, -191.642],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.522, -192.532],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.451, -193.289],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.486, -193.936],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.726, -194.456],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.216, -194.932],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.072, -195.325],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.406, -195.684],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.219, -195.985],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-232.621, -196.229],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-237.573, -196.44],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-242.228, -196.62],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.52, -196.798],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.568, -196.918],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.296, -197.057],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-257.825, -197.129],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-261.098, -197.226],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-264.174, -197.292],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-267.061, -197.343],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-269.754, -197.369],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-272.292, -197.419],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-274.657, -197.459],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.897, -197.499],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-279.041, -197.484],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-281.004, -197.501],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-282.859, -197.522],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-284.598, -197.518],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-286.248, -197.514],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-287.801, -197.529],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-289.267, -197.505],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-290.639, -197.506],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-291.915, -197.503],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-293.069, -197.49],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.213, -197.48],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.27, -197.469],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-296.254, -197.449],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-297.157, -197.45],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-298.003, -197.427],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-298.804, -197.434],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-299.537, -197.408],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-300.203, -197.412],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-300.821, -197.39],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-301.414, -197.357],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-301.933, -197.371],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.415, -197.364],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.812, -197.337],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.236, -197.331],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.572, -197.285],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.858, -197.299],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.126, -197.287],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.374, -197.291],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.668, -197.283],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-304.904, -197.219],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.813, -197.04],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.666, -196.811],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.485, -196.449],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.193, -195.965],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.813, -195.303],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.411, -194.536],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.857, -193.553],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.217, -192.453],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-301.484, -191.139],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-300.632, -189.524],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-299.625, -187.725],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-298.445, -185.635],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-297.113, -183.161],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.609, -180.335],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-293.883, -177.106],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-291.894, -173.4],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-289.655, -168.991],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-287.076, -163.963],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-284.154, -158.082],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-280.793, -151.217],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-277.065, -143.18],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-272.811, -133.784],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-268.155, -122.812],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-263.223, -110.323],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-258.146, -96.492],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.373, -81.822],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.098, -67.231],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.591, -53.384],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-242.771, -40.934],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.495, -30.095],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-238.634, -20.897],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-236.987, -13.265],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-235.381, -7.07],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.801, -1.991],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-232.206, 2.137],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.592, 5.572],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.955, 8.39],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.374, 10.802],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.782, 12.895],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.304, 14.731],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.882, 16.375],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.541, 17.833],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.278, 19.169],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.145, 20.414],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.085, 21.549],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.122, 22.577],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.239, 23.555],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.47, 24.44],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.77, 25.282],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.219, 26.072],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.63, 26.795],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.139, 27.481],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.696, 28.122],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.255, 28.75],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.875, 29.331],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.428, 29.853],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.019, 30.345],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.653, 30.844],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.261, 31.303],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.867, 31.727],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.463, 32.161],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.048, 32.562],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.706, 32.93],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.335, 33.274],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.991, 33.627],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.641, 33.951],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.301, 34.239],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.997, 34.51],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.692, 34.792],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.46, 35.046],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.172, 35.288],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.929, 35.491],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.725, 35.691],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.528, 35.873],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.33, 36.048],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.145, 36.207],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.047, 36.36],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.918, 36.474],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.853, 36.581],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.742, 36.669],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.66, 36.754],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.63, 36.834],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.617, 36.895],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.635, 36.952],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.668, 36.968],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.764, 37.004],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.947, 36.995],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.085, 36.984],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.09, 37.036],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.025, 37.044],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.928, 37.106],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.762, 37.201],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.528, 37.316],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.285, 37.458],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.901, 37.591],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.389, 37.785],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.857, 38.037],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.174, 38.305],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.334, 38.611],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.39, 38.989],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.241, 39.425],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.817, 39.915],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.138, 40.433],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.114, 41.048],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-191.619, 41.693],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.547, 42.307],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.62, 42.82],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.522, 43.009],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.685, 42.437],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.407, 40.376],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.929, 35.925],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.204, 28.742],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.155, 19.311],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.165, 8.457],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.558, -2.653],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.022, -13.494],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.005, -23.65],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.471, -33.088],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.406, -41.704],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.568, -49.615],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.152, -56.844],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.904, -63.505],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.815, -69.607],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.977, -75.233],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.462, -80.442],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.395, -85.273],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.814, -89.811],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.795, -94.029],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.319, -97.934],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.509, -101.618],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.346, -105.023],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.869, -108.31],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.113, -111.328],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.109, -114.176],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.883, -116.882],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.393, -119.456],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.719, -121.826],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.837, -124.066],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.788, -126.222],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.561, -128.219],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.183, -130.133],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.675, -131.934],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.026, -133.629],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.22, -135.244],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.316, -136.831],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.27, -138.201],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.121, -139.556],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.892, -140.867],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.516, -142.053],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.08, -143.182],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.52, -144.29],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.919, -145.269],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.191, -146.219],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.412, -147.128],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.545, -147.945],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.626, -148.786],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.592, -149.443],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.525, -150.19],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.373, -150.78],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.168, -151.394],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.886, -151.932],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.571, -152.418],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.193, -152.849],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.757, -153.247],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.255, -153.615],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.706, -154.001],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.099, -154.268],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.466, -154.497],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.759, -154.741],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.033, -154.951],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.242, -155.104],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.457, -155.245],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.585, -155.332],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.653, -155.379],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.643, -155.469],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.385, -155.527],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.94, -155.603],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.274, -155.648],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.359, -155.769],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.247, -155.949],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.837, -156.136],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.143, -156.395],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.113, -156.672],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.737, -156.967],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.991, -157.354],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.805, -157.796],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.142, -158.286],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.888, -158.893],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.051, -159.52],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.518, -160.267],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.117, -161.131],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.823, -162.111],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.44, -163.255],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.756, -164.525],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.626, -165.972],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.721, -167.655],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.876, -169.586],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.871, -171.752],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.718, -174.163],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.195, -176.711],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.075, -179.318],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.914, -181.862],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.635, -184.138],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.695, -186.165],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.916, -187.873],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.366, -189.367],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.137, -190.599],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.439, -191.642],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.522, -192.532],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.451, -193.289],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.486, -193.936],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.726, -194.456],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.216, -194.932],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.072, -195.325],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.406, -195.684],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.219, -195.985],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-232.621, -196.229],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-237.573, -196.44],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-242.228, -196.62],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.52, -196.798],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.568, -196.918],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.296, -197.057],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-257.825, -197.129],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-261.098, -197.226],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-264.174, -197.292],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-267.061, -197.343],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-269.754, -197.369],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-272.292, -197.419],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-274.657, -197.459],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.897, -197.499],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-279.041, -197.484],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-281.004, -197.501],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-282.859, -197.522],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-284.598, -197.518],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-286.248, -197.514],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-287.801, -197.529],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-289.267, -197.505],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-290.639, -197.506],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-291.915, -197.503],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-293.069, -197.49],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.213, -197.48],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.27, -197.469],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-296.254, -197.449],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-297.157, -197.45],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-298.003, -197.427],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-298.804, -197.434],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-299.537, -197.408],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-300.203, -197.412],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-300.821, -197.39],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-301.414, -197.357],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-301.933, -197.371],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.415, -197.364],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.812, -197.337],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.236, -197.331],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.572, -197.285],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.858, -197.299],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.126, -197.287],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.374, -197.291],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.668, -197.283],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 3',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.692, 114.482],
                                  [-309.451, -274.241],
                                  [-388.66, -116.002],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.821, 114.562],
                                  [-309.248, -274.09],
                                  [-388.414, -116.042],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.989, 114.658],
                                  [-308.993, -273.799],
                                  [-388.157, -116.054],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.258, 114.818],
                                  [-308.572, -273.431],
                                  [-387.712, -116.087],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.555, 115.043],
                                  [-308.048, -272.937],
                                  [-387.155, -116.129],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.954, 115.284],
                                  [-307.473, -272.366],
                                  [-386.525, -116.191],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.43, 115.602],
                                  [-306.696, -271.619],
                                  [-385.733, -116.275],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.946, 115.985],
                                  [-305.793, -270.783],
                                  [-384.78, -116.317],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.602, 116.396],
                                  [-304.751, -269.784],
                                  [-383.654, -116.405],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.387, 116.885],
                                  [-303.534, -268.571],
                                  [-382.317, -116.552],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.263, 117.489],
                                  [-302.091, -267.233],
                                  [-380.783, -116.696],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.257, 118.134],
                                  [-300.399, -265.619],
                                  [-378.987, -116.825],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.442, 118.906],
                                  [-298.469, -263.753],
                                  [-376.943, -117.051],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.784, 119.769],
                                  [-296.278, -261.624],
                                  [-374.549, -117.288],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.32, 120.734],
                                  [-293.738, -259.18],
                                  [-371.769, -117.549],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.103, 121.89],
                                  [-290.774, -256.37],
                                  [-368.599, -117.823],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.158, 123.21],
                                  [-287.412, -253.081],
                                  [-364.914, -118.237],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.475, 124.683],
                                  [-283.489, -249.285],
                                  [-360.649, -118.668],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.194, 126.395],
                                  [-278.943, -244.843],
                                  [-355.726, -119.168],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.345, 128.396],
                                  [-273.61, -239.727],
                                  [-349.903, -119.804],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.03, 130.698],
                                  [-267.491, -233.731],
                                  [-343.188, -120.542],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-231.255, 133.363],
                                  [-260.297, -226.764],
                                  [-335.297, -121.413],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.138, 136.413],
                                  [-252.015, -218.631],
                                  [-326.186, -122.449],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.697, 139.849],
                                  [-242.645, -209.426],
                                  [-315.81, -123.643],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.838, 143.645],
                                  [-232.21, -199.233],
                                  [-304.314, -125],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.372, 147.663],
                                  [-221.251, -188.426],
                                  [-292.135, -126.508],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-260.997, 151.73],
                                  [-210.155, -177.537],
                                  [-279.829, -127.974],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-267.386, 155.609],
                                  [-199.577, -167.009],
                                  [-267.957, -129.469],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-273.445, 159.24],
                                  [-189.719, -157.213],
                                  [-256.928, -130.9],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-279.022, 162.534],
                                  [-180.746, -148.241],
                                  [-246.822, -132.273],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.168, 165.569],
                                  [-172.712, -140.135],
                                  [-237.656, -133.501],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-288.923, 168.308],
                                  [-165.504, -132.82],
                                  [-229.393, -134.696],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-293.285, 170.774],
                                  [-159.024, -126.236],
                                  [-221.936, -135.782],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-297.306, 173.018],
                                  [-153.263, -120.204],
                                  [-215.196, -136.827],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-301.117, 175.104],
                                  [-148.046, -114.779],
                                  [-209.036, -137.828],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-304.657, 177.033],
                                  [-143.387, -109.768],
                                  [-203.406, -138.815],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-308.001, 178.763],
                                  [-139.165, -105.225],
                                  [-198.24, -139.775],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-311.219, 180.401],
                                  [-135.381, -101.049],
                                  [-193.519, -140.698],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.273, 181.914],
                                  [-131.925, -97.151],
                                  [-189.111, -141.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-317.258, 183.336],
                                  [-128.851, -93.582],
                                  [-185.06, -142.555],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-320.135, 184.687],
                                  [-126.069, -90.283],
                                  [-181.337, -143.417],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-322.936, 185.916],
                                  [-123.602, -87.182],
                                  [-177.829, -144.387],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.673, 187.074],
                                  [-121.368, -84.301],
                                  [-174.599, -145.279],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.349, 188.216],
                                  [-119.412, -81.662],
                                  [-171.545, -146.209],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-331.006, 189.289],
                                  [-117.682, -79.161],
                                  [-168.719, -147.175],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-333.653, 190.249],
                                  [-116.178, -76.797],
                                  [-166.07, -148.158],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-336.269, 191.246],
                                  [-114.835, -74.623],
                                  [-163.597, -149.167],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-338.868, 192.129],
                                  [-113.711, -72.586],
                                  [-161.256, -150.198],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-341.487, 193.004],
                                  [-112.739, -70.631],
                                  [-159.091, -151.248],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.06, 193.816],
                                  [-111.961, -68.855],
                                  [-157.009, -152.248],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-346.571, 194.61],
                                  [-111.267, -67.122],
                                  [-155.046, -153.351],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-349.059, 195.345],
                                  [-110.678, -65.511],
                                  [-153.2, -154.378],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-351.495, 196.059],
                                  [-110.195, -63.984],
                                  [-151.431, -155.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-353.822, 196.767],
                                  [-109.789, -62.476],
                                  [-149.785, -156.473],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.117, 197.403],
                                  [-109.407, -61.096],
                                  [-148.153, -157.451],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-358.259, 197.968],
                                  [-109.024, -59.744],
                                  [-146.644, -158.423],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.306, 198.522],
                                  [-108.704, -58.475],
                                  [-145.15, -159.399],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-362.294, 199.053],
                                  [-108.367, -57.276],
                                  [-143.792, -160.242],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-364.109, 199.58],
                                  [-108.091, -56.116],
                                  [-142.447, -161.129],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-365.87, 200.03],
                                  [-107.757, -55.032],
                                  [-141.189, -161.941],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.467, 200.471],
                                  [-107.475, -53.935],
                                  [-139.956, -162.679],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.006, 200.897],
                                  [-107.137, -52.926],
                                  [-138.815, -163.405],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-370.437, 201.26],
                                  [-106.872, -52.007],
                                  [-137.705, -164.051],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.824, 201.611],
                                  [-106.608, -51.081],
                                  [-136.63, -164.743],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.11, 201.955],
                                  [-106.313, -50.243],
                                  [-135.652, -165.301],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-374.302, 202.288],
                                  [-106.091, -49.399],
                                  [-134.714, -165.908],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.399, 202.56],
                                  [-105.832, -48.654],
                                  [-133.82, -166.44],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-376.466, 202.821],
                                  [-105.601, -47.957],
                                  [-132.968, -166.961],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-377.438, 203.078],
                                  [-105.398, -47.247],
                                  [-132.221, -167.414],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-378.391, 203.332],
                                  [-105.229, -46.644],
                                  [-131.466, -167.859],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-379.253, 203.577],
                                  [-105.034, -46.035],
                                  [-130.813, -168.296],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-380.095, 203.762],
                                  [-104.879, -45.478],
                                  [-130.149, -168.72],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-380.856, 203.939],
                                  [-104.759, -44.968],
                                  [-129.537, -169.08],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-381.6, 204.118],
                                  [-104.619, -44.511],
                                  [-129.034, -169.435],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-382.261, 204.286],
                                  [-104.524, -44.051],
                                  [-128.522, -169.779],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-382.909, 204.456],
                                  [-104.412, -43.644],
                                  [-128.064, -170.119],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-383.486, 204.619],
                                  [-104.404, -43.287],
                                  [-127.603, -170.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-384.042, 204.723],
                                  [-104.324, -42.985],
                                  [-127.249, -170.668],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-384.587, 204.82],
                                  [-104.346, -42.68],
                                  [-126.892, -170.935],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-385.061, 204.92],
                                  [-104.298, -42.431],
                                  [-126.585, -171.199],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-385.52, 205.015],
                                  [-104.301, -42.18],
                                  [-126.339, -171.452],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-385.914, 205.108],
                                  [-104.35, -41.98],
                                  [-126.086, -171.649],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.292, 205.195],
                                  [-104.39, -41.836],
                                  [-125.885, -171.841],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.663, 205.282],
                                  [-104.482, -41.691],
                                  [-125.745, -172.026],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.027, 205.309],
                                  [-104.565, -41.603],
                                  [-125.656, -172.211],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.32, 205.393],
                                  [-104.698, -41.509],
                                  [-125.567, -172.336],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.544, 205.418],
                                  [-104.824, -41.476],
                                  [-125.476, -172.515],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.822, 205.438],
                                  [-104.946, -41.438],
                                  [-125.438, -172.63],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-388.034, 205.458],
                                  [-105.119, -41.458],
                                  [-125.458, -172.746],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-388.184, 205.472],
                                  [-105.231, -41.534],
                                  [-125.356, -172.852],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-388.276, 205.535],
                                  [-105.221, -41.668],
                                  [-125.078, -172.834],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-388.258, 205.647],
                                  [-105.102, -41.86],
                                  [-124.554, -172.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-388.124, 205.746],
                                  [-104.801, -42.226],
                                  [-123.855, -172.648],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.883, 205.894],
                                  [-104.387, -42.65],
                                  [-122.796, -172.423],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.469, 206.088],
                                  [-103.8, -43.19],
                                  [-121.495, -172.069],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.943, 206.329],
                                  [-103.037, -43.847],
                                  [-119.897, -171.645],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.315, 206.615],
                                  [-102.105, -44.678],
                                  [-117.935, -171.089],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-385.517, 206.885],
                                  [-100.943, -45.627],
                                  [-115.672, -170.464],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-384.495, 207.257],
                                  [-99.55, -46.692],
                                  [-112.932, -169.711],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-383.316, 207.729],
                                  [-97.929, -47.991],
                                  [-109.711, -168.764],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-381.916, 208.237],
                                  [-95.965, -49.525],
                                  [-106.009, -167.681],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-380.241, 208.784],
                                  [-93.714, -51.234],
                                  [-101.766, -166.409],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-378.354, 209.484],
                                  [-91.123, -53.236],
                                  [-96.863, -164.939],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-376.082, 210.274],
                                  [-88.133, -55.531],
                                  [-91.239, -163.213],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.488, 211.147],
                                  [-84.63, -58.119],
                                  [-84.774, -161.231],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-370.516, 212.104],
                                  [-80.618, -61.118],
                                  [-77.347, -158.985],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.062, 213.312],
                                  [-75.98, -64.587],
                                  [-68.78, -156.355],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-363.006, 214.647],
                                  [-70.605, -68.584],
                                  [-58.891, -153.278],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-358.365, 216.164],
                                  [-64.439, -73.168],
                                  [-47.5, -149.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-352.969, 217.962],
                                  [-57.195, -78.515],
                                  [-34.362, -145.702],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-346.657, 220.038],
                                  [-48.818, -84.685],
                                  [-19.065, -140.953],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-339.326, 222.435],
                                  [-39.08, -91.795],
                                  [-1.305, -135.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-330.861, 225.206],
                                  [-27.871, -100.022],
                                  [19.101, -129.086],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-321.207, 228.351],
                                  [-15.189, -109.365],
                                  [42.271, -121.839],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-310.584, 231.821],
                                  [-1.143, -119.651],
                                  [67.789, -113.885],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-299.363, 235.534],
                                  [13.76, -130.527],
                                  [94.756, -105.482],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-288.019, 239.192],
                                  [28.73, -141.524],
                                  [121.968, -97.012],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.089, 242.778],
                                  [43.147, -152.056],
                                  [148.108, -88.866],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.837, 246.142],
                                  [56.619, -161.887],
                                  [172.568, -81.235],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-257.544, 249.236],
                                  [68.971, -170.898],
                                  [194.875, -74.302],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.034, 252.003],
                                  [80.144, -179.091],
                                  [215.085, -68.009],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.38, 254.555],
                                  [90.195, -186.463],
                                  [233.375, -62.356],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.47, 256.819],
                                  [99.349, -193.132],
                                  [249.986, -57.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-228.14, 258.906],
                                  [107.662, -199.215],
                                  [264.98, -52.525],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.394, 260.81],
                                  [115.241, -204.77],
                                  [278.715, -48.279],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.128, 262.579],
                                  [122.207, -209.856],
                                  [291.306, -44.413],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.287, 264.211],
                                  [128.608, -214.531],
                                  [302.821, -40.867],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.817, 265.705],
                                  [134.446, -218.795],
                                  [313.492, -37.581],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.668, 267.107],
                                  [139.889, -222.765],
                                  [323.327, -34.549],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.838, 268.418],
                                  [144.942, -226.5],
                                  [332.437, -31.706],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.217, 269.575],
                                  [149.655, -229.941],
                                  [340.949, -29.116],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.923, 270.755],
                                  [154.031, -233.147],
                                  [348.859, -26.658],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.783, 271.775],
                                  [158.125, -236.117],
                                  [356.287, -24.39],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.859, 272.752],
                                  [161.992, -238.911],
                                  [363.178, -22.308],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.153, 273.687],
                                  [165.578, -241.587],
                                  [369.705, -20.292],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.547, 274.578],
                                  [168.939, -244.027],
                                  [375.809, -18.407],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.105, 275.42],
                                  [172.128, -246.348],
                                  [381.497, -16.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.821, 276.214],
                                  [175.153, -248.552],
                                  [386.938, -15.01],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.705, 276.963],
                                  [177.945, -250.637],
                                  [392.022, -13.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.692, 277.6],
                                  [180.628, -252.546],
                                  [396.804, -11.995],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.786, 278.306],
                                  [183.142, -254.395],
                                  [401.284, -10.613],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.932, 278.905],
                                  [185.482, -256.126],
                                  [405.583, -9.355],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.243, 279.51],
                                  [187.709, -257.738],
                                  [409.584, -8.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.661, 280.062],
                                  [189.825, -259.291],
                                  [413.4, -6.973],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.134, 280.561],
                                  [191.826, -260.725],
                                  [416.977, -5.849],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.655, 281.067],
                                  [193.718, -262.1],
                                  [420.368, -4.845],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.347, 281.579],
                                  [195.491, -263.416],
                                  [423.583, -3.844],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.031, 281.979],
                                  [197.149, -264.672],
                                  [426.616, -2.905],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.829, 282.441],
                                  [198.756, -265.81],
                                  [429.465, -2.09],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.676, 282.791],
                                  [200.245, -266.888],
                                  [432.136, -1.277],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.583, 283.202],
                                  [201.678, -267.965],
                                  [434.686, -0.464],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.596, 283.56],
                                  [202.996, -268.924],
                                  [437.113, 0.224],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.607, 283.863],
                                  [204.259, -269.824],
                                  [439.359, 0.91],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.732, 284.23],
                                  [205.407, -270.723],
                                  [441.483, 1.597],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.851, 284.478],
                                  [206.555, -271.503],
                                  [443.488, 2.159],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.084, 284.791],
                                  [207.587, -272.283],
                                  [445.371, 2.722],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.314, 285.048],
                                  [208.565, -273.003],
                                  [447.132, 3.282],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.6, 285.307],
                                  [209.483, -273.663],
                                  [448.774, 3.78],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.938, 285.51],
                                  [210.344, -274.323],
                                  [450.353, 4.279],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.333, 285.715],
                                  [211.151, -274.865],
                                  [451.751, 4.653],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.786, 285.923],
                                  [211.897, -275.465],
                                  [453.148, 5.091],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.233, 286.133],
                                  [212.588, -275.946],
                                  [454.364, 5.463],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.736, 286.287],
                                  [213.22, -276.427],
                                  [455.52, 5.836],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.299, 286.443],
                                  [213.795, -276.848],
                                  [456.613, 6.147],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.855, 286.598],
                                  [214.371, -277.269],
                                  [457.588, 6.4],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.47, 286.756],
                                  [214.89, -277.63],
                                  [458.44, 6.709],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.138, 286.857],
                                  [215.35, -277.931],
                                  [459.291, 6.956],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.807, 286.961],
                                  [215.754, -278.232],
                                  [460.021, 7.144],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.531, 287.068],
                                  [216.098, -278.533],
                                  [460.69, 7.392],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.315, 287.173],
                                  [216.446, -278.773],
                                  [461.297, 7.519],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.033, 287.281],
                                  [216.734, -278.955],
                                  [461.785, 7.702],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.87, 287.333],
                                  [216.962, -279.136],
                                  [462.272, 7.827],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.704, 287.384],
                                  [217.194, -279.316],
                                  [462.637, 7.952],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.594, 287.437],
                                  [217.366, -279.437],
                                  [462.943, 8.074],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.425, 287.489],
                                  [217.542, -279.557],
                                  [463.244, 8.138],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.372, 287.485],
                                  [217.654, -279.618],
                                  [463.43, 8.198],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.319, 287.543],
                                  [217.714, -279.678],
                                  [463.55, 8.201],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.262, 287.541],
                                  [217.77, -279.738],
                                  [463.672, 8.262],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.262, 287.538],
                                  [217.649, -279.734],
                                  [463.491, 8.262],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.325, 287.404],
                                  [217.348, -279.778],
                                  [463.002, 8.197],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.39, 287.208],
                                  [216.801, -279.811],
                                  [462.152, 8.129],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.514, 286.996],
                                  [216.017, -279.835],
                                  [460.875, 8.059],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.704, 286.656],
                                  [214.926, -279.904],
                                  [459.17, 7.926],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.893, 286.188],
                                  [213.595, -280.018],
                                  [457.041, 7.726],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.146, 285.644],
                                  [211.904, -280.117],
                                  [454.367, 7.522],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.399, 284.972],
                                  [209.85, -280.258],
                                  [451.146, 7.254],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.776, 284.225],
                                  [207.432, -280.443],
                                  [447.32, 6.922],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.216, 283.341],
                                  [204.595, -280.605],
                                  [442.827, 6.582],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.657, 282.262],
                                  [201.278, -280.8],
                                  [437.607, 6.173],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.218, 281.045],
                                  [197.481, -281.087],
                                  [431.544, 5.633],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.847, 279.626],
                                  [193.083, -281.347],
                                  [424.577, 5.082],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.592, 278.006],
                                  [187.974, -281.684],
                                  [416.586, 4.4],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.459, 276.12],
                                  [182.208, -282.039],
                                  [407.338, 3.636],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.388, 273.966],
                                  [175.551, -282.405],
                                  [396.831, 2.795],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.496, 271.541],
                                  [167.888, -282.892],
                                  [384.713, 1.751],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.782, 268.719],
                                  [159.165, -283.42],
                                  [370.872, 0.551],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.182, 265.44],
                                  [149.095, -283.981],
                                  [354.958, -0.816],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.868, 261.696],
                                  [137.509, -284.614],
                                  [336.572, -2.41],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.719, 257.422],
                                  [124.189, -285.345],
                                  [315.374, -4.318],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.905, 252.436],
                                  [108.75, -286.145],
                                  [290.868, -6.56],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.351, 246.674],
                                  [90.986, -287.03],
                                  [262.622, -9.228],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.153, 240.082],
                                  [70.658, -287.951],
                                  [230.29, -12.356],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.283, 232.616],
                                  [47.775, -288.865],
                                  [193.905, -15.986],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.603, 224.48],
                                  [22.824, -289.749],
                                  [154.049, -20.151],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.977, 215.959],
                                  [-3.259, -290.504],
                                  [112.394, -24.735],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.332, 207.452],
                                  [-29.224, -291.103],
                                  [70.907, -29.578],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.337, 199.352],
                                  [-53.833, -291.409],
                                  [31.379, -34.394],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.073, 191.849],
                                  [-76.398, -291.525],
                                  [-4.991, -39.1],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.402, 185.117],
                                  [-96.756, -291.408],
                                  [-37.789, -43.539],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.406, 179.086],
                                  [-114.981, -291.217],
                                  [-67.147, -47.771],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.153, 173.729],
                                  [-131.172, -290.906],
                                  [-93.38, -51.738],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.655, 168.919],
                                  [-145.605, -290.492],
                                  [-116.831, -55.406],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.934, 164.582],
                                  [-158.585, -290.019],
                                  [-137.927, -58.848],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.996, 160.709],
                                  [-170.197, -289.542],
                                  [-156.937, -62.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.946, 157.214],
                                  [-180.737, -289.051],
                                  [-174.121, -65.178],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.799, 154.052],
                                  [-190.323, -288.49],
                                  [-189.766, -68.018],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.503, 151.144],
                                  [-199.034, -287.964],
                                  [-204.063, -70.737],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.157, 148.494],
                                  [-206.979, -287.409],
                                  [-217.19, -73.28],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.67, 146.03],
                                  [-214.33, -286.874],
                                  [-229.216, -75.7],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.19, 143.813],
                                  [-221.04, -286.366],
                                  [-240.311, -78.008],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.626, 141.72],
                                  [-227.277, -285.803],
                                  [-250.59, -80.156],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.972, 139.807],
                                  [-232.992, -285.263],
                                  [-260.114, -82.191],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.329, 138.076],
                                  [-238.35, -284.778],
                                  [-268.953, -84.171],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.594, 136.409],
                                  [-243.291, -284.293],
                                  [-277.154, -86.05],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.875, 134.852],
                                  [-247.933, -283.755],
                                  [-284.838, -87.772],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.07, 133.423],
                                  [-252.217, -283.317],
                                  [-291.998, -89.503],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.275, 132.096],
                                  [-256.26, -282.823],
                                  [-298.689, -91.082],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.451, 130.839],
                                  [-260.002, -282.375],
                                  [-304.988, -92.613],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.641, 129.688],
                                  [-263.506, -281.931],
                                  [-310.864, -94.041],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.742, 128.591],
                                  [-266.815, -281.508],
                                  [-316.353, -95.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.848, 127.607],
                                  [-269.89, -281.088],
                                  [-321.537, -96.74],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.983, 126.631],
                                  [-272.772, -280.697],
                                  [-326.367, -98.008],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.073, 125.754],
                                  [-275.463, -280.347],
                                  [-330.918, -99.231],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.132, 124.881],
                                  [-278.013, -279.984],
                                  [-335.22, -100.379],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.25, 124.104],
                                  [-280.436, -279.598],
                                  [-339.222, -101.428],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.276, 123.341],
                                  [-282.67, -279.238],
                                  [-343.05, -102.451],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.323, 122.63],
                                  [-284.776, -278.926],
                                  [-346.58, -103.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.38, 122.01],
                                  [-286.745, -278.627],
                                  [-349.926, -104.384],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.398, 121.401],
                                  [-288.616, -278.324],
                                  [-353.088, -105.253],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.438, 120.791],
                                  [-290.373, -278.032],
                                  [-356.067, -106.095],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.478, 120.281],
                                  [-292.031, -277.775],
                                  [-358.8, -106.909],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.543, 119.768],
                                  [-293.565, -277.5],
                                  [-361.452, -107.644],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.557, 119.251],
                                  [-295, -277.234],
                                  [-363.871, -108.351],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.533, 118.843],
                                  [-296.311, -277.012],
                                  [-366.157, -109.028],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.584, 118.431],
                                  [-297.597, -276.799],
                                  [-368.3, -109.677],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.586, 118.014],
                                  [-298.786, -276.565],
                                  [-370.323, -110.258],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.602, 117.653],
                                  [-299.888, -276.351],
                                  [-372.201, -110.81],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.568, 117.287],
                                  [-300.916, -276.167],
                                  [-373.948, -111.345],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.61, 117.015],
                                  [-301.858, -276],
                                  [-375.563, -111.838],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.604, 116.698],
                                  [-302.763, -275.85],
                                  [-377.095, -112.326],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.611, 116.424],
                                  [-303.582, -275.667],
                                  [-378.495, -112.733],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.632, 116.155],
                                  [-304.313, -275.512],
                                  [-379.813, -113.124],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.605, 115.929],
                                  [-305.021, -275.373],
                                  [-380.985, -113.484],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.642, 115.757],
                                  [-305.679, -275.249],
                                  [-382.075, -113.827],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.63, 115.526],
                                  [-306.261, -275.14],
                                  [-383.094, -114.153],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.633, 115.35],
                                  [-306.807, -275.058],
                                  [-383.967, -114.461],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.6, 115.215],
                                  [-307.252, -274.94],
                                  [-384.807, -114.702],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.619, 115.034],
                                  [-307.722, -274.836],
                                  [-385.562, -114.925],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.589, 114.944],
                                  [-308.116, -274.745],
                                  [-386.184, -115.119],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.636, 114.807],
                                  [-308.409, -274.667],
                                  [-386.758, -115.308],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.623, 114.723],
                                  [-308.714, -274.614],
                                  [-387.26, -115.467],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.636, 114.63],
                                  [-308.993, -274.574],
                                  [-387.728, -115.622],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.589, 114.539],
                                  [-309.169, -274.545],
                                  [-388.047, -115.76],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.605, 114.489],
                                  [-309.32, -274.479],
                                  [-388.33, -115.818],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.586, 114.454],
                                  [-309.468, -274.486],
                                  [-388.54, -115.922],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.316, 0.316, 0.316, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.316, 0.316, 0.316, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-279.291, -165.305],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-279.21, -165.151],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-279.084, -164.961],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.929, -164.658],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.675, -164.252],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.345, -163.695],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278, -163.052],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-277.521, -162.22],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.96, -161.291],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.327, -160.18],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-275.586, -158.811],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-274.706, -157.288],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-273.673, -155.506],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-272.507, -153.403],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-271.19, -150.997],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-269.672, -148.244],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-267.922, -145.079],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-265.95, -141.311],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-263.668, -136.999],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-261.079, -131.962],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-258.093, -126.086],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.774, -119.186],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.974, -111.159],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.789, -101.778],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-242.324, -91.167],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-237.668, -79.489],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.196, -67.217],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.052, -55.156],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.458, -43.873],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.351, -33.876],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.635, -25.264],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.259, -17.952],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.094, -11.827],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.049, -6.723],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.147, -2.371],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.358, 1.334],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.68, 4.57],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.095, 7.353],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.655, 9.832],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.284, 12.057],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.086, 14.057],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.998, 15.882],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.032, 17.531],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.176, 19.05],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.482, 20.47],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.891, 21.775],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.423, 22.967],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.048, 24.087],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.803, 25.104],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.638, 26.064],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.627, 26.963],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.589, 27.783],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.637, 28.553],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.724, 29.266],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.795, 29.953],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.909, 30.587],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.93, 31.151],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.968, 31.682],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.01, 32.206],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.008, 32.689],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.974, 33.13],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.912, 33.58],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.808, 33.989],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.756, 34.365],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.657, 34.715],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.563, 35.068],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.449, 35.392],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.327, 35.68],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.228, 35.949],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.112, 36.226],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.059, 36.477],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.94, 36.714],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.86, 36.916],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.806, 37.113],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.748, 37.29],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.683, 37.458],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.622, 37.61],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.642, 37.759],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.621, 37.865],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.661, 37.966],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.647, 38.047],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.659, 38.125],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.717, 38.199],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.785, 38.253],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.881, 38.304],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.988, 38.313],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.119, 38.357],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.219, 38.338],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.358, 38.341],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.524, 38.317],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.662, 38.281],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.784, 38.297],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.86, 38.346],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.867, 38.352],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.853, 38.416],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.774, 38.512],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.632, 38.628],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.486, 38.772],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.213, 38.91],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.827, 39.112],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.434, 39.378],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.898, 39.655],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.224, 39.977],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-193.47, 40.377],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.526, 40.833],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-191.345, 41.357],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.934, 41.909],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.23, 42.579],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.096, 43.285],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.452, 43.985],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-180.022, 44.604],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.49, 44.915],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.265, 44.453],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.538, 42.382],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.456, 37.706],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.006, 30.123],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.245, 20.288],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.602, 9.118],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.38, -2.219],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.234, -13.219],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.592, -23.488],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.404, -33.007],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.658, -41.681],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.111, -49.636],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.348, -56.904],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.856, -63.587],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.55, -69.718],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.507, -75.358],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.809, -80.583],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.573, -85.429],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.83, -89.971],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.665, -94.2],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.061, -98.122],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.121, -101.806],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.837, -105.213],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.252, -108.506],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.404, -111.543],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.301, -114.392],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.982, -117.096],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.404, -119.667],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.648, -122.036],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.699, -124.288],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.587, -126.457],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.295, -128.458],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.853, -130.37],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.285, -132.169],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.579, -133.861],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.72, -135.473],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.766, -137.056],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.674, -138.429],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.487, -139.793],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.223, -141.112],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.814, -142.306],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.345, -143.441],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.752, -144.547],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.118, -145.525],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.359, -146.473],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.552, -147.38],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.658, -148.196],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.714, -149.034],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.657, -149.691],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.567, -150.435],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.395, -151.024],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.172, -151.636],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.873, -152.172],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.542, -152.658],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.15, -153.089],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.7, -153.487],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.187, -153.853],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.628, -154.238],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.013, -154.507],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.372, -154.739],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.66, -154.984],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.929, -155.194],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.134, -155.349],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.345, -155.49],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.47, -155.579],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.537, -155.625],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.527, -155.715],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.272, -155.769],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.83, -155.838],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.172, -155.874],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.266, -155.983],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.166, -156.145],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.771, -156.311],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.096, -156.544],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.087, -156.793],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.738, -157.054],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.023, -157.398],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.874, -157.788],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.254, -158.221],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.053, -158.757],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.276, -159.302],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.815, -159.953],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.502, -160.706],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.31, -161.548],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.051, -162.524],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.519, -163.593],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.573, -164.788],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.898, -166.158],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.339, -167.691],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.694, -169.353],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.007, -171.125],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.363, -172.88],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.58, -174.532],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.655, -175.98],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.54, -177.066],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.714, -177.873],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.024, -178.354],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.556, -178.645],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.415, -178.704],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.818, -178.623],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.016, -178.436],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.084, -178.174],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.278, -177.856],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-191.697, -177.458],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.395, -177.063],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.483, -176.627],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.071, -176.201],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.165, -175.759],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.871, -175.294],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.155, -174.841],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.163, -174.391],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-231.832, -173.969],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-235.278, -173.523],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-238.428, -173.119],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-241.399, -172.681],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-244.137, -172.292],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.702, -171.904],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.094, -171.518],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.315, -171.134],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.401, -170.789],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.334, -170.46],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-257.156, -170.149],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-258.9, -169.804],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-260.484, -169.504],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-261.979, -169.23],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-263.376, -168.948],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-264.694, -168.687],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-265.936, -168.449],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-267.102, -168.191],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-268.194, -167.973],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-269.199, -167.76],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-270.104, -167.555],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-271.011, -167.358],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-271.839, -167.177],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-272.609, -166.994],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-273.312, -166.844],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-273.974, -166.684],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-274.598, -166.557],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-275.167, -166.409],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-275.682, -166.301],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.161, -166.176],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.622, -166.046],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-277.023, -165.97],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-277.395, -165.882],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-277.694, -165.787],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.024, -165.714],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.283, -165.612],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.5, -165.568],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.705, -165.514],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.9, -165.479],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-279.116, -165.412],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-279.291, -165.305],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-279.21, -165.151],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-279.084, -164.961],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.929, -164.658],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.675, -164.252],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.345, -163.695],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278, -163.052],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-277.521, -162.22],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.96, -161.291],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.327, -160.18],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-275.586, -158.811],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-274.706, -157.288],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-273.673, -155.506],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-272.507, -153.403],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-271.19, -150.997],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-269.672, -148.244],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-267.922, -145.079],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-265.95, -141.311],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-263.668, -136.999],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-261.079, -131.962],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-258.093, -126.086],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.774, -119.186],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.974, -111.159],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.789, -101.778],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-242.324, -91.167],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-237.668, -79.489],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.196, -67.217],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.052, -55.156],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.458, -43.873],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.351, -33.876],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.635, -25.264],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.259, -17.952],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.094, -11.827],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.049, -6.723],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.147, -2.371],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.358, 1.334],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.68, 4.57],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.095, 7.353],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.655, 9.832],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.284, 12.057],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.086, 14.057],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.998, 15.882],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.032, 17.531],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.176, 19.05],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.482, 20.47],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.891, 21.775],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.423, 22.967],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.048, 24.087],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.803, 25.104],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.638, 26.064],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.627, 26.963],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.589, 27.783],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.637, 28.553],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.724, 29.266],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.795, 29.953],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.909, 30.587],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.93, 31.151],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.968, 31.682],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.01, 32.206],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.008, 32.689],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.974, 33.13],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.912, 33.58],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.808, 33.989],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.756, 34.365],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.657, 34.715],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.563, 35.068],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.449, 35.392],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.327, 35.68],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.228, 35.949],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.112, 36.226],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.059, 36.477],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.94, 36.714],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.86, 36.916],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.806, 37.113],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.748, 37.29],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.683, 37.458],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.622, 37.61],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.642, 37.759],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.621, 37.865],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.661, 37.966],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.647, 38.047],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.659, 38.125],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.717, 38.199],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.785, 38.253],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.881, 38.304],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.988, 38.313],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.119, 38.357],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.219, 38.338],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.358, 38.341],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.524, 38.317],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.662, 38.281],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.784, 38.297],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.86, 38.346],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.867, 38.352],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.853, 38.416],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.774, 38.512],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.632, 38.628],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.486, 38.772],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.213, 38.91],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.827, 39.112],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.434, 39.378],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.898, 39.655],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.224, 39.977],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-193.47, 40.377],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.526, 40.833],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-191.345, 41.357],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.934, 41.909],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.23, 42.579],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.096, 43.285],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.452, 43.985],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-180.022, 44.604],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.49, 44.915],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.265, 44.453],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.538, 42.382],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.456, 37.706],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.006, 30.123],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.245, 20.288],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.602, 9.118],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.38, -2.219],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.234, -13.219],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.592, -23.488],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.404, -33.007],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.658, -41.681],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.111, -49.636],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.348, -56.904],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.856, -63.587],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.55, -69.718],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.507, -75.358],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.809, -80.583],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.573, -85.429],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.83, -89.971],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.665, -94.2],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.061, -98.122],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.121, -101.806],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.837, -105.213],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.252, -108.506],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.404, -111.543],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.301, -114.392],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.982, -117.096],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.404, -119.667],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.648, -122.036],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.699, -124.288],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.587, -126.457],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.295, -128.458],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.853, -130.37],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.285, -132.169],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.579, -133.861],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.72, -135.473],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.766, -137.056],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.674, -138.429],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.487, -139.793],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.223, -141.112],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.814, -142.306],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.345, -143.441],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.752, -144.547],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.118, -145.525],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.359, -146.473],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.552, -147.38],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.658, -148.196],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.714, -149.034],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.657, -149.691],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.567, -150.435],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.395, -151.024],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.172, -151.636],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.873, -152.172],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.542, -152.658],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.15, -153.089],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.7, -153.487],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.187, -153.853],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.628, -154.238],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.013, -154.507],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.372, -154.739],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.66, -154.984],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.929, -155.194],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.134, -155.349],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.345, -155.49],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.47, -155.579],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.537, -155.625],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.527, -155.715],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.272, -155.769],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.83, -155.838],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.172, -155.874],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.266, -155.983],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.166, -156.145],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.771, -156.311],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.096, -156.544],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.087, -156.793],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.738, -157.054],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.023, -157.398],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.874, -157.788],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.254, -158.221],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.053, -158.757],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.276, -159.302],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.815, -159.953],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.502, -160.706],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.31, -161.548],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.051, -162.524],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.519, -163.593],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.573, -164.788],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.898, -166.158],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.339, -167.691],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.694, -169.353],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.007, -171.125],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.363, -172.88],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.58, -174.532],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.655, -175.98],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.54, -177.066],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.714, -177.873],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.024, -178.354],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.556, -178.645],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.415, -178.704],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.818, -178.623],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.016, -178.436],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.084, -178.174],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.278, -177.856],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-191.697, -177.458],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.395, -177.063],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.483, -176.627],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.071, -176.201],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.165, -175.759],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.871, -175.294],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.155, -174.841],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.163, -174.391],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-231.832, -173.969],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-235.278, -173.523],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-238.428, -173.119],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-241.399, -172.681],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-244.137, -172.292],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.702, -171.904],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.094, -171.518],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.315, -171.134],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.401, -170.789],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.334, -170.46],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-257.156, -170.149],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-258.9, -169.804],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-260.484, -169.504],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-261.979, -169.23],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-263.376, -168.948],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-264.694, -168.687],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-265.936, -168.449],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-267.102, -168.191],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-268.194, -167.973],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-269.199, -167.76],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-270.104, -167.555],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-271.011, -167.358],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-271.839, -167.177],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-272.609, -166.994],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-273.312, -166.844],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-273.974, -166.684],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-274.598, -166.557],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-275.167, -166.409],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-275.682, -166.301],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.161, -166.176],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.622, -166.046],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-277.023, -165.97],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-277.395, -165.882],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-277.694, -165.787],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.024, -165.714],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.283, -165.612],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.5, -165.568],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.705, -165.514],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.9, -165.479],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-279.116, -165.412],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 4',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.88, 107.287],
                                  [-280.659, -243.066],
                                  [-350.29, -127.983],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.989, 107.373],
                                  [-280.47, -242.945],
                                  [-350.085, -127.997],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.129, 107.478],
                                  [-280.229, -242.7],
                                  [-349.882, -127.973],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.363, 107.649],
                                  [-279.83, -242.392],
                                  [-349.512, -127.956],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.611, 107.886],
                                  [-279.335, -241.976],
                                  [-349.054, -127.93],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.954, 108.142],
                                  [-278.79, -241.5],
                                  [-348.537, -127.912],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.358, 108.481],
                                  [-278.059, -240.87],
                                  [-347.888, -127.902],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.794, 108.885],
                                  [-277.199, -240.162],
                                  [-347.105, -127.837],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.355, 109.327],
                                  [-276.216, -239.319],
                                  [-346.173, -127.792],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.024, 109.85],
                                  [-275.06, -238.294],
                                  [-345.064, -127.784],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.773, 110.486],
                                  [-273.686, -237.162],
                                  [-343.792, -127.752],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.615, 111.175],
                                  [-272.081, -235.787],
                                  [-342.296, -127.678],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.629, 111.993],
                                  [-270.243, -234.2],
                                  [-340.592, -127.672],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.768, 112.91],
                                  [-268.158, -232.385],
                                  [-338.588, -127.64],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.082, 113.943],
                                  [-265.732, -230.295],
                                  [-336.254, -127.598],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.598, 115.166],
                                  [-262.906, -227.884],
                                  [-333.587, -127.532],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.351, 116.568],
                                  [-259.693, -225.061],
                                  [-330.483, -127.547],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.328, 118.131],
                                  [-255.938, -221.792],
                                  [-326.875, -127.522],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.651, 119.95],
                                  [-251.581, -217.952],
                                  [-322.702, -127.505],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.34, 122.071],
                                  [-246.466, -213.526],
                                  [-317.745, -127.543],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-218.489, 124.509],
                                  [-240.592, -208.324],
                                  [-312.016, -127.591],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.104, 127.327],
                                  [-233.679, -202.265],
                                  [-305.263, -127.668],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-226.281, 130.556],
                                  [-225.711, -195.174],
                                  [-297.448, -127.795],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-231.043, 134.194],
                                  [-216.686, -187.136],
                                  [-288.533, -127.963],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.305, 138.209],
                                  [-206.637, -178.22],
                                  [-278.628, -128.198],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.92, 142.458],
                                  [-196.074, -168.759],
                                  [-268.128, -128.526],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.632, 146.752],
                                  [-185.391, -159.213],
                                  [-257.505, -128.815],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-253.149, 150.857],
                                  [-175.209, -149.983],
                                  [-247.252, -129.182],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-258.411, 154.694],
                                  [-165.736, -141.397],
                                  [-237.729, -129.564],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-263.278, 158.185],
                                  [-157.128, -133.534],
                                  [-229.001, -129.994],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-267.796, 161.394],
                                  [-149.435, -126.429],
                                  [-221.087, -130.374],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-271.997, 164.291],
                                  [-142.551, -120.025],
                                  [-213.961, -130.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-275.88, 166.907],
                                  [-136.385, -114.261],
                                  [-207.533, -131.23],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-279.493, 169.29],
                                  [-130.923, -108.984],
                                  [-201.728, -131.672],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-282.948, 171.503],
                                  [-125.998, -104.244],
                                  [-196.425, -132.139],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-286.184, 173.547],
                                  [-121.627, -99.869],
                                  [-191.579, -132.647],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-289.279, 175.39],
                                  [-117.689, -95.909],
                                  [-187.143, -133.18],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-292.286, 177.128],
                                  [-114.187, -92.273],
                                  [-183.091, -133.724],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.176, 178.744],
                                  [-111.014, -88.88],
                                  [-179.312, -134.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-298.031, 180.257],
                                  [-108.223, -85.782],
                                  [-175.841, -134.944],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.82, 181.696],
                                  [-105.734, -82.918],
                                  [-172.661, -135.545],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-303.567, 183.014],
                                  [-103.558, -80.234],
                                  [-169.664, -136.288],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-306.289, 184.259],
                                  [-101.628, -77.74],
                                  [-166.914, -136.988],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-308.986, 185.481],
                                  [-99.981, -75.465],
                                  [-164.315, -137.754],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-311.696, 186.631],
                                  [-98.571, -73.31],
                                  [-161.916, -138.589],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.431, 187.673],
                                  [-97.398, -71.277],
                                  [-159.67, -139.465],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-317.164, 188.743],
                                  [-96.396, -69.414],
                                  [-157.575, -140.394],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-319.914, 189.704],
                                  [-95.624, -67.672],
                                  [-155.596, -141.374],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-322.71, 190.653],
                                  [-95.011, -65.998],
                                  [-153.768, -142.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.487, 191.54],
                                  [-94.596, -64.481],
                                  [-152.012, -143.396],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.226, 192.402],
                                  [-94.274, -63.001],
                                  [-150.352, -144.511],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-330.957, 193.209],
                                  [-94.054, -61.623],
                                  [-148.787, -145.568],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-333.644, 193.987],
                                  [-93.94, -60.316],
                                  [-147.286, -146.678],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-336.235, 194.757],
                                  [-93.89, -59.016],
                                  [-145.888, -147.765],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-338.79, 195.454],
                                  [-93.859, -57.826],
                                  [-144.488, -148.806],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-341.187, 196.077],
                                  [-93.812, -56.653],
                                  [-143.192, -149.843],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-343.488, 196.688],
                                  [-93.813, -55.55],
                                  [-141.897, -150.889],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-345.716, 197.266],
                                  [-93.777, -54.5],
                                  [-140.721, -151.803],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-347.763, 197.842],
                                  [-93.786, -53.482],
                                  [-139.542, -152.758],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-349.744, 198.335],
                                  [-93.724, -52.524],
                                  [-138.438, -153.639],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-351.553, 198.817],
                                  [-93.693, -51.549],
                                  [-137.348, -154.444],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-353.291, 199.28],
                                  [-93.592, -50.65],
                                  [-136.338, -155.236],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-354.908, 199.681],
                                  [-93.549, -49.829],
                                  [-135.349, -155.944],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.472, 200.064],
                                  [-93.491, -48.999],
                                  [-134.385, -156.696],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.925, 200.439],
                                  [-93.393, -48.244],
                                  [-133.511, -157.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-359.278, 200.799],
                                  [-93.353, -47.483],
                                  [-132.666, -157.976],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.528, 201.099],
                                  [-93.266, -46.81],
                                  [-131.86, -158.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-361.74, 201.385],
                                  [-93.198, -46.18],
                                  [-131.089, -159.135],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-362.848, 201.666],
                                  [-93.147, -45.532],
                                  [-130.415, -159.641],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-363.933, 201.942],
                                  [-93.121, -44.985],
                                  [-129.731, -160.136],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-364.92, 202.207],
                                  [-93.064, -44.431],
                                  [-129.14, -160.622],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-365.882, 202.412],
                                  [-93.038, -43.924],
                                  [-128.533, -161.092],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-366.758, 202.607],
                                  [-93.04, -43.458],
                                  [-127.975, -161.499],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.614, 202.805],
                                  [-93.016, -43.042],
                                  [-127.52, -161.9],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-368.38, 202.987],
                                  [-93.031, -42.621],
                                  [-127.053, -162.287],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.13, 203.172],
                                  [-93.024, -42.251],
                                  [-126.636, -162.67],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.808, 203.348],
                                  [-93.115, -41.925],
                                  [-126.215, -162.993],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-370.458, 203.466],
                                  [-93.132, -41.651],
                                  [-125.893, -163.305],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.096, 203.576],
                                  [-93.243, -41.374],
                                  [-125.568, -163.614],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.659, 203.69],
                                  [-93.281, -41.149],
                                  [-125.288, -163.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-372.204, 203.795],
                                  [-93.367, -40.922],
                                  [-125.068, -164.21],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-372.684, 203.899],
                                  [-93.495, -40.741],
                                  [-124.839, -164.449],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.142, 203.994],
                                  [-93.609, -40.614],
                                  [-124.657, -164.681],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.591, 204.088],
                                  [-93.773, -40.485],
                                  [-124.535, -164.904],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-374.034, 204.125],
                                  [-93.926, -40.411],
                                  [-124.46, -165.127],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-374.401, 204.216],
                                  [-94.123, -40.328],
                                  [-124.384, -165.294],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-374.699, 204.25],
                                  [-94.31, -40.306],
                                  [-124.306, -165.509],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.048, 204.277],
                                  [-94.494, -40.277],
                                  [-124.277, -165.663],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.33, 204.303],
                                  [-94.725, -40.303],
                                  [-124.303, -165.816],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.552, 204.321],
                                  [-94.897, -40.384],
                                  [-124.209, -165.958],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.719, 204.382],
                                  [-94.948, -40.52],
                                  [-123.945, -165.976],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.784, 204.486],
                                  [-94.898, -40.711],
                                  [-123.437, -165.925],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.738, 204.571],
                                  [-94.671, -41.071],
                                  [-122.759, -165.851],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.595, 204.7],
                                  [-94.336, -41.487],
                                  [-121.729, -165.654],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.286, 204.866],
                                  [-93.836, -42.015],
                                  [-120.46, -165.327],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-374.873, 205.071],
                                  [-93.165, -42.655],
                                  [-118.901, -164.928],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-374.368, 205.315],
                                  [-92.336, -43.465],
                                  [-116.984, -164.393],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.703, 205.535],
                                  [-91.286, -44.388],
                                  [-114.771, -163.786],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-372.827, 205.845],
                                  [-90.014, -45.424],
                                  [-112.092, -163.051],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.808, 206.245],
                                  [-88.527, -46.686],
                                  [-108.941, -162.119],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-370.584, 206.667],
                                  [-86.711, -48.175],
                                  [-105.318, -161.047],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.103, 207.118],
                                  [-84.622, -49.834],
                                  [-101.165, -159.784],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.427, 207.705],
                                  [-82.209, -51.777],
                                  [-96.363, -158.319],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-365.393, 208.366],
                                  [-79.416, -54.004],
                                  [-90.856, -156.596],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-363.062, 209.091],
                                  [-76.134, -56.516],
                                  [-84.523, -154.614],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.383, 209.879],
                                  [-72.368, -59.425],
                                  [-77.246, -152.363],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.259, 210.89],
                                  [-68.006, -62.79],
                                  [-68.853, -149.723],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-353.575, 211.999],
                                  [-62.943, -66.666],
                                  [-59.162, -146.632],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-349.354, 213.259],
                                  [-57.129, -71.112],
                                  [-47.997, -143.084],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.436, 214.754],
                                  [-50.29, -76.298],
                                  [-35.117, -139.005],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-338.672, 216.481],
                                  [-42.374, -82.281],
                                  [-20.121, -134.221],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-331.97, 218.474],
                                  [-33.164, -89.177],
                                  [-2.71, -128.663],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-324.22, 220.781],
                                  [-22.555, -97.155],
                                  [17.298, -122.256],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-315.368, 223.403],
                                  [-10.547, -106.217],
                                  [40.018, -114.946],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-305.623, 226.295],
                                  [2.761, -116.192],
                                  [65.043, -106.919],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.325, 229.397],
                                  [16.886, -126.742],
                                  [91.491, -98.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.905, 232.447],
                                  [31.078, -137.41],
                                  [118.182, -89.887],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-274.861, 235.449],
                                  [44.75, -147.629],
                                  [143.824, -81.666],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.432, 238.27],
                                  [57.531, -157.17],
                                  [167.819, -73.967],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-256.885, 240.87],
                                  [69.253, -165.918],
                                  [189.705, -66.972],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.05, 243.195],
                                  [79.858, -173.872],
                                  [209.539, -60.623],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.004, 245.351],
                                  [89.4, -181.032],
                                  [227.488, -54.921],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-235.64, 247.26],
                                  [98.094, -187.511],
                                  [243.792, -49.74],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-229.806, 249.026],
                                  [105.99, -193.422],
                                  [258.512, -45.013],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.508, 250.64],
                                  [113.191, -198.821],
                                  [271.998, -40.732],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.651, 252.145],
                                  [119.814, -203.766],
                                  [284.361, -36.837],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.184, 253.536],
                                  [125.898, -208.312],
                                  [295.671, -33.264],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.057, 254.812],
                                  [131.449, -212.46],
                                  [306.152, -29.957],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.225, 256.013],
                                  [136.627, -216.323],
                                  [315.814, -26.904],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.687, 257.138],
                                  [141.435, -219.958],
                                  [324.765, -24.042],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.337, 258.126],
                                  [145.919, -223.308],
                                  [333.129, -21.435],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.293, 259.146],
                                  [150.086, -226.429],
                                  [340.902, -18.962],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.386, 260.021],
                                  [153.984, -229.322],
                                  [348.202, -16.681],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.679, 260.861],
                                  [157.667, -232.044],
                                  [354.977, -14.587],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.176, 261.671],
                                  [161.082, -234.651],
                                  [361.394, -12.559],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.76, 262.444],
                                  [164.286, -237.029],
                                  [367.394, -10.666],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.497, 263.175],
                                  [167.325, -239.292],
                                  [372.989, -8.896],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.378, 263.865],
                                  [170.209, -241.44],
                                  [378.339, -7.251],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.419, 264.518],
                                  [172.871, -243.474],
                                  [383.34, -5.672],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.552, 265.066],
                                  [175.429, -245.335],
                                  [388.044, -4.222],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.783, 265.686],
                                  [177.828, -247.139],
                                  [392.451, -2.834],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.061, 266.207],
                                  [180.06, -248.828],
                                  [396.68, -1.569],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.493, 266.738],
                                  [182.183, -250.402],
                                  [400.618, -0.314],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.023, 267.221],
                                  [184.203, -251.917],
                                  [404.373, 0.822],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.606, 267.655],
                                  [186.114, -253.318],
                                  [407.893, 1.949],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.23, 268.099],
                                  [187.921, -254.661],
                                  [411.23, 2.957],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.015, 268.553],
                                  [189.613, -255.945],
                                  [414.394, 3.961],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.789, 268.901],
                                  [191.197, -257.172],
                                  [417.381, 4.904],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.673, 269.311],
                                  [192.733, -258.283],
                                  [420.184, 5.722],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.599, 269.615],
                                  [194.154, -259.337],
                                  [422.815, 6.537],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.582, 269.98],
                                  [195.523, -260.389],
                                  [425.325, 7.352],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.664, 270.297],
                                  [196.783, -261.327],
                                  [427.715, 8.043],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.741, 270.561],
                                  [197.99, -262.206],
                                  [429.927, 8.73],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.928, 270.891],
                                  [199.088, -263.084],
                                  [432.018, 9.418],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.105, 271.105],
                                  [200.185, -263.847],
                                  [433.993, 9.982],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.392, 271.386],
                                  [201.172, -264.61],
                                  [435.848, 10.547],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.675, 271.613],
                                  [202.107, -265.314],
                                  [437.582, 11.107],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.009, 271.844],
                                  [202.984, -265.96],
                                  [439.201, 11.606],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.392, 272.021],
                                  [203.809, -266.605],
                                  [440.756, 12.106],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.826, 272.202],
                                  [204.581, -267.136],
                                  [442.133, 12.482],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.319, 272.388],
                                  [205.295, -267.722],
                                  [443.509, 12.921],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.802, 272.577],
                                  [205.956, -268.193],
                                  [444.707, 13.293],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.338, 272.712],
                                  [206.56, -268.663],
                                  [445.847, 13.666],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.932, 272.852],
                                  [207.111, -269.076],
                                  [446.923, 13.978],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.515, 272.99],
                                  [207.661, -269.487],
                                  [447.884, 14.233],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.157, 273.132],
                                  [208.159, -269.841],
                                  [448.725, 14.54],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.847, 273.22],
                                  [208.598, -270.136],
                                  [449.564, 14.786],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.537, 273.312],
                                  [208.985, -270.431],
                                  [450.282, 14.976],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.28, 273.409],
                                  [209.315, -270.725],
                                  [450.943, 15.224],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.08, 273.504],
                                  [209.648, -270.961],
                                  [451.539, 15.352],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.814, 273.603],
                                  [209.924, -271.138],
                                  [452.021, 15.534],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.663, 273.648],
                                  [210.142, -271.315],
                                  [452.501, 15.659],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.508, 273.693],
                                  [210.365, -271.492],
                                  [452.861, 15.784],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.405, 273.741],
                                  [210.529, -271.61],
                                  [453.162, 15.906],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.246, 273.788],
                                  [210.699, -271.728],
                                  [453.458, 15.97],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.197, 273.782],
                                  [210.805, -271.788],
                                  [453.643, 16.03],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.148, 273.837],
                                  [210.864, -271.846],
                                  [453.76, 16.034],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.095, 273.833],
                                  [210.917, -271.905],
                                  [453.881, 16.095],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.095, 273.831],
                                  [210.798, -271.9],
                                  [453.703, 16.095],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.157, 273.695],
                                  [210.504, -271.937],
                                  [453.221, 16.026],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.219, 273.498],
                                  [209.965, -271.957],
                                  [452.387, 15.953],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.34, 273.28],
                                  [209.198, -271.966],
                                  [451.131, 15.877],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.527, 272.936],
                                  [208.128, -272.013],
                                  [449.454, 15.735],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.711, 272.463],
                                  [206.823, -272.098],
                                  [447.36, 15.523],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.957, 271.911],
                                  [205.168, -272.161],
                                  [444.732, 15.304],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.203, 271.232],
                                  [203.155, -272.259],
                                  [441.565, 15.018],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.571, 270.474],
                                  [200.786, -272.395],
                                  [437.806, 14.666],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.999, 269.578],
                                  [198.006, -272.497],
                                  [433.391, 14.301],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.428, 268.487],
                                  [194.759, -272.621],
                                  [428.262, 13.861],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.972, 267.255],
                                  [191.043, -272.824],
                                  [422.309, 13.287],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.583, 265.82],
                                  [186.739, -272.99],
                                  [415.468, 12.693],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.303, 264.182],
                                  [181.744, -273.214],
                                  [407.627, 11.962],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.141, 262.277],
                                  [176.109, -273.44],
                                  [398.555, 11.137],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.038, 260.102],
                                  [169.607, -273.658],
                                  [388.255, 10.225],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.106, 257.655],
                                  [162.127, -273.972],
                                  [376.382, 9.099],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.342, 254.81],
                                  [153.62, -274.294],
                                  [362.831, 7.798],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.682, 251.51],
                                  [143.81, -274.617],
                                  [347.264, 6.308],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.288, 247.744],
                                  [132.535, -274.97],
                                  [329.297, 4.565],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.044, 243.453],
                                  [119.592, -275.366],
                                  [308.606, 2.476],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.107, 238.454],
                                  [104.618, -275.768],
                                  [284.724, 0.007],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.399, 232.69],
                                  [87.423, -276.175],
                                  [257.242, -2.943],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.002, 226.114],
                                  [67.795, -276.526],
                                  [225.851, -6.424],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.877, 218.688],
                                  [45.765, -276.766],
                                  [190.615, -10.493],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.886, 210.623],
                                  [21.834, -276.867],
                                  [152.134, -15.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.894, 202.21],
                                  [-3.078, -276.748],
                                  [112.057, -20.39],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.831, 193.849],
                                  [-27.756, -276.418],
                                  [72.299, -25.916],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.391, 185.926],
                                  [-51.025, -275.78],
                                  [34.581, -31.457],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.666, 178.625],
                                  [-72.246, -274.968],
                                  [0.031, -36.896],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.54, 172.111],
                                  [-91.289, -273.967],
                                  [-30.993, -42.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.1, 166.307],
                                  [-108.248, -272.943],
                                  [-58.639, -46.994],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.415, 161.178],
                                  [-123.232, -271.849],
                                  [-83.241, -51.636],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.499, 156.598],
                                  [-136.523, -270.709],
                                  [-105.148, -55.946],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.382, 152.489],
                                  [-148.419, -269.558],
                                  [-124.777, -60.003],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.067, 148.841],
                                  [-159.008, -268.445],
                                  [-142.399, -63.839],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.655, 145.562],
                                  [-168.579, -267.358],
                                  [-158.274, -67.464],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.163, 142.612],
                                  [-177.245, -266.241],
                                  [-172.676, -70.832],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.541, 139.909],
                                  [-185.086, -265.191],
                                  [-185.795, -74.05],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.878, 137.46],
                                  [-192.209, -264.143],
                                  [-197.805, -77.067],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.096, 135.188],
                                  [-198.774, -263.143],
                                  [-208.773, -79.936],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.327, 133.157],
                                  [-204.742, -262.199],
                                  [-218.861, -82.672],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.491, 131.243],
                                  [-210.271, -261.221],
                                  [-228.182, -85.231],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.582, 129.501],
                                  [-215.318, -260.294],
                                  [-236.794, -87.657],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.688, 127.936],
                                  [-220.036, -259.438],
                                  [-244.768, -90.006],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.717, 126.431],
                                  [-224.369, -258.601],
                                  [-252.145, -92.239],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.769, 125.027],
                                  [-228.43, -257.733],
                                  [-259.044, -94.303],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.751, 123.748],
                                  [-232.162, -256.975],
                                  [-265.455, -96.354],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.748, 122.561],
                                  [-235.677, -256.183],
                                  [-271.431, -98.244],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.728, 121.442],
                                  [-238.916, -255.449],
                                  [-277.051, -100.067],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.727, 120.422],
                                  [-241.944, -254.739],
                                  [-282.276, -101.773],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.652, 119.449],
                                  [-244.795, -254.056],
                                  [-287.151, -103.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.585, 118.583],
                                  [-247.438, -253.394],
                                  [-291.746, -104.994],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.556, 117.727],
                                  [-249.908, -252.77],
                                  [-296.014, -106.507],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.49, 116.957],
                                  [-252.205, -252.198],
                                  [-300.034, -107.958],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.403, 116.193],
                                  [-254.378, -251.628],
                                  [-303.826, -109.329],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.375, 115.512],
                                  [-256.442, -251.045],
                                  [-307.342, -110.588],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.265, 114.848],
                                  [-258.336, -250.494],
                                  [-310.708, -111.814],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.185, 114.233],
                                  [-260.122, -250.006],
                                  [-313.802, -112.99],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.118, 113.695],
                                  [-261.787, -249.533],
                                  [-316.731, -114.114],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.02, 113.17],
                                  [-263.361, -249.073],
                                  [-319.498, -115.155],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.952, 112.643],
                                  [-264.844, -248.626],
                                  [-322.102, -116.16],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.885, 112.206],
                                  [-266.237, -248.223],
                                  [-324.482, -117.128],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.849, 111.764],
                                  [-267.525, -247.815],
                                  [-326.793, -118.012],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.768, 111.317],
                                  [-268.722, -247.417],
                                  [-328.895, -118.858],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.656, 110.972],
                                  [-269.814, -247.077],
                                  [-330.883, -119.666],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.625, 110.621],
                                  [-270.894, -246.745],
                                  [-332.739, -120.436],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.548, 110.262],
                                  [-271.883, -246.406],
                                  [-334.496, -121.137],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.489, 109.957],
                                  [-272.798, -246.09],
                                  [-336.121, -121.799],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.385, 109.644],
                                  [-273.653, -245.812],
                                  [-337.632, -122.439],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.361, 109.414],
                                  [-274.434, -245.556],
                                  [-339.026, -123.024],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.293, 109.145],
                                  [-275.186, -245.321],
                                  [-340.351, -123.602],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.244, 108.911],
                                  [-275.862, -245.061],
                                  [-341.56, -124.095],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.213, 108.684],
                                  [-276.463, -244.836],
                                  [-342.699, -124.565],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.138, 108.492],
                                  [-277.051, -244.631],
                                  [-343.706, -124.997],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.127, 108.351],
                                  [-277.592, -244.445],
                                  [-344.641, -125.405],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.073, 108.153],
                                  [-278.074, -244.277],
                                  [-345.523, -125.79],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.037, 108.005],
                                  [-278.525, -244.143],
                                  [-346.27, -126.153],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.975, 107.892],
                                  [-278.883, -243.98],
                                  [-346.992, -126.448],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.96, 107.737],
                                  [-279.272, -243.835],
                                  [-347.642, -126.719],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.902, 107.662],
                                  [-279.601, -243.706],
                                  [-348.173, -126.952],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.925, 107.544],
                                  [-279.836, -243.592],
                                  [-348.662, -127.179],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.887, 107.476],
                                  [-280.085, -243.511],
                                  [-349.094, -127.366],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.885, 107.396],
                                  [-280.318, -243.445],
                                  [-349.498, -127.549],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.823, 107.319],
                                  [-280.457, -243.394],
                                  [-349.766, -127.708],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.826, 107.274],
                                  [-280.579, -243.311],
                                  [-350.005, -127.784],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.801, 107.249],
                                  [-280.697, -243.304],
                                  [-350.187, -127.9],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.35, 0.35, 0.35, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.35, 0.35, 0.35, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-254.483, -133.428],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.411, -133.302],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.306, -133.151],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.175, -132.907],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.959, -132.579],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.676, -132.129],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.385, -131.612],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.978, -130.935],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.495, -130.182],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.957, -129.28],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.324, -128.164],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.567, -126.93],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.678, -125.475],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-248.672, -123.755],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-247.534, -121.785],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.216, -119.527],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-244.693, -116.927],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-242.977, -113.832],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.978, -110.297],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-238.701, -106.156],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-236.06, -101.337],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.112, -95.69],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.706, -89.131],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.91, -81.523],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.787, -72.966],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.379, -63.65],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.983, -53.976],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.711, -44.577],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.775, -35.862],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.176, -28.169],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.899, -21.488],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.969, -15.703],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.31, -10.733],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.877, -6.441],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.698, -2.644],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.735, 0.709],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.986, 3.742],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.415, 6.429],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.062, 8.886],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.844, 11.143],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.849, 13.209],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.014, 15.123],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-177.343, 16.881],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.819, 18.517],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.49, 20.058],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.294, 21.486],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.253, 22.808],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.326, 24.053],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.554, 25.196],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.877, 26.278],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-177.361, 27.293],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-177.835, 28.222],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.388, 29.093],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.976, 29.899],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.54, 30.673],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-180.131, 31.387],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-180.61, 32.024],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.088, 32.621],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.534, 33.197],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.921, 33.732],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.247, 34.215],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.526, 34.702],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.737, 35.142],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.98, 35.547],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.158, 35.923],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.32, 36.295],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.45, 36.637],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.552, 36.939],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.666, 37.221],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.746, 37.508],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.88, 37.768],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.934, 38.01],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.021, 38.22],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.121, 38.422],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.207, 38.601],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.282, 38.774],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.353, 38.929],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.499, 39.082],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.594, 39.189],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.745, 39.293],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.835, 39.375],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.947, 39.451],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.097, 39.525],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.251, 39.578],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.431, 39.627],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.615, 39.634],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.818, 39.676],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.99, 39.657],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.195, 39.657],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.423, 39.631],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.625, 39.595],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.813, 39.609],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.965, 39.658],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.051, 39.666],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.125, 39.732],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.139, 39.828],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.099, 39.948],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.071, 40.1],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.926, 40.248],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.684, 40.464],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.444, 40.741],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.08, 41.032],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.603, 41.375],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.065, 41.798],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.368, 42.285],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.47, 42.845],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.383, 43.443],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.055, 44.173],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.366, 44.963],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-177.242, 45.773],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.431, 46.537],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.628, 47.039],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.194, 46.762],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.178, 44.729],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.536, 39.755],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.364, 31.654],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.918, 21.326],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.685, 9.794],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.926, -1.791],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.251, -12.956],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.057, -23.34],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.284, -32.94],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.917, -41.671],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.721, -49.662],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.426, -56.965],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.643, -63.666],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.081, -69.822],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.792, -75.47],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.882, -80.717],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.438, -85.566],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.506, -90.111],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.177, -94.359],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.406, -98.278],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.312, -101.958],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.894, -105.38],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.185, -108.682],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.211, -111.714],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.988, -114.559],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.559, -117.259],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.89, -119.843],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.048, -122.225],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.008, -124.476],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.81, -126.639],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.436, -128.635],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.917, -130.542],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.279, -132.34],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.513, -134.041],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.599, -135.662],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.592, -137.251],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.448, -138.628],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.208, -139.988],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.891, -141.302],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.435, -142.492],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.922, -143.624],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.287, -144.725],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.613, -145.701],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.818, -146.646],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.979, -147.553],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.056, -148.374],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.087, -149.215],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.005, -149.876],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.894, -150.621],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.701, -151.214],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.459, -151.829],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.141, -152.367],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.794, -152.856],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.386, -153.29],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.923, -153.689],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.397, -154.057],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.827, -154.442],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.201, -154.71],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.55, -154.942],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.829, -155.185],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.091, -155.395],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.29, -155.549],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.496, -155.689],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.617, -155.779],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.681, -155.825],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.671, -155.914],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.419, -155.962],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.984, -156.022],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.337, -156.049],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.444, -156.143],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.36, -156.284],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.987, -156.425],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.337, -156.627],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.36, -156.843],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.047, -157.063],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.375, -157.354],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.277, -157.686],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.714, -158.05],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.585, -158.502],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.888, -158.949],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.522, -159.485],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.32, -160.1],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.26, -160.775],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.158, -161.55],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.816, -162.378],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.097, -163.277],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.705, -164.278],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.491, -165.351],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.273, -166.436],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-2.897, -167.495],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.61, -168.384],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-43.076, -169.01],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.306, -169.304],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.288, -169.148],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.528, -168.672],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.891, -167.888],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.483, -166.943],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.419, -165.805],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.92, -164.598],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.238, -163.34],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.458, -162.049],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.828, -160.75],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.448, -159.435],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.376, -158.177],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-193.722, -156.922],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.591, -155.724],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.995, -154.552],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.034, -153.403],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.681, -152.308],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.074, -151.244],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.15, -150.24],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.024, -149.247],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.627, -148.32],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.07, -147.398],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.305, -146.554],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.388, -145.743],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-231.316, -144.952],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.096, -144.191],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-234.76, -143.483],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-236.289, -142.817],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-237.723, -142.188],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-239.096, -141.547],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.326, -140.962],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-241.488, -140.424],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-242.569, -139.894],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-243.579, -139.406],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-244.536, -138.946],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.426, -138.484],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.261, -138.076],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-247.017, -137.681],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-247.693, -137.312],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-248.384, -136.955],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.004, -136.629],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.578, -136.31],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.098, -136.032],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.594, -135.758],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.057, -135.519],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.478, -135.271],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.857, -135.071],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.209, -134.861],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.551, -134.654],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.844, -134.504],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.119, -134.349],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.328, -134.2],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.574, -134.073],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.763, -133.926],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.92, -133.836],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.067, -133.749],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.282, -133.63],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.427, -133.536],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-254.483, -133.428],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.411, -133.302],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.306, -133.151],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.175, -132.907],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.959, -132.579],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.676, -132.129],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.385, -131.612],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.978, -130.935],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.495, -130.182],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.957, -129.28],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.324, -128.164],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.567, -126.93],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.678, -125.475],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-248.672, -123.755],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-247.534, -121.785],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.216, -119.527],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-244.693, -116.927],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-242.977, -113.832],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.978, -110.297],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-238.701, -106.156],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-236.06, -101.337],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.112, -95.69],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.706, -89.131],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.91, -81.523],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.787, -72.966],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.379, -63.65],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.983, -53.976],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.711, -44.577],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.775, -35.862],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.176, -28.169],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.899, -21.488],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.969, -15.703],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.31, -10.733],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.877, -6.441],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.698, -2.644],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.735, 0.709],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.986, 3.742],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.415, 6.429],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.062, 8.886],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.844, 11.143],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.849, 13.209],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.014, 15.123],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-177.343, 16.881],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.819, 18.517],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.49, 20.058],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.294, 21.486],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.253, 22.808],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.326, 24.053],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.554, 25.196],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.877, 26.278],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-177.361, 27.293],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-177.835, 28.222],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.388, 29.093],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.976, 29.899],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.54, 30.673],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-180.131, 31.387],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-180.61, 32.024],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.088, 32.621],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.534, 33.197],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.921, 33.732],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.247, 34.215],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.526, 34.702],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.737, 35.142],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.98, 35.547],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.158, 35.923],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.32, 36.295],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.45, 36.637],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.552, 36.939],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.666, 37.221],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.746, 37.508],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.88, 37.768],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.934, 38.01],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.021, 38.22],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.121, 38.422],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.207, 38.601],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.282, 38.774],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.353, 38.929],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.499, 39.082],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.594, 39.189],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.745, 39.293],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.835, 39.375],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.947, 39.451],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.097, 39.525],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.251, 39.578],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.431, 39.627],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.615, 39.634],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.818, 39.676],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.99, 39.657],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.195, 39.657],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.423, 39.631],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.625, 39.595],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.813, 39.609],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.965, 39.658],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.051, 39.666],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.125, 39.732],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.139, 39.828],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.099, 39.948],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.071, 40.1],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.926, 40.248],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.684, 40.464],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.444, 40.741],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.08, 41.032],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.603, 41.375],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.065, 41.798],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.368, 42.285],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.47, 42.845],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.383, 43.443],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.055, 44.173],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.366, 44.963],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-177.242, 45.773],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.431, 46.537],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.628, 47.039],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.194, 46.762],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.178, 44.729],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.536, 39.755],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.364, 31.654],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.918, 21.326],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.685, 9.794],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.926, -1.791],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.251, -12.956],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.057, -23.34],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.284, -32.94],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.917, -41.671],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.721, -49.662],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.426, -56.965],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.643, -63.666],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.081, -69.822],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.792, -75.47],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.882, -80.717],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.438, -85.566],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.506, -90.111],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.177, -94.359],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.406, -98.278],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.312, -101.958],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.894, -105.38],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.185, -108.682],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.211, -111.714],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.988, -114.559],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.559, -117.259],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.89, -119.843],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.048, -122.225],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.008, -124.476],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.81, -126.639],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.436, -128.635],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.917, -130.542],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.279, -132.34],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.513, -134.041],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.599, -135.662],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.592, -137.251],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.448, -138.628],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.208, -139.988],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.891, -141.302],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.435, -142.492],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.922, -143.624],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.287, -144.725],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.613, -145.701],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.818, -146.646],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.979, -147.553],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.056, -148.374],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.087, -149.215],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.005, -149.876],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.894, -150.621],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.701, -151.214],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.459, -151.829],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.141, -152.367],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.794, -152.856],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.386, -153.29],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.923, -153.689],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.397, -154.057],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.827, -154.442],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.201, -154.71],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.55, -154.942],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.829, -155.185],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.091, -155.395],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.29, -155.549],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.496, -155.689],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.617, -155.779],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.681, -155.825],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.671, -155.914],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.419, -155.962],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.984, -156.022],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.337, -156.049],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.444, -156.143],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.36, -156.284],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.987, -156.425],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.337, -156.627],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.36, -156.843],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.047, -157.063],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.375, -157.354],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.277, -157.686],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.714, -158.05],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.585, -158.502],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.888, -158.949],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.522, -159.485],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.32, -160.1],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.26, -160.775],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.158, -161.55],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.816, -162.378],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.097, -163.277],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.705, -164.278],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.491, -165.351],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.273, -166.436],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-2.897, -167.495],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.61, -168.384],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-43.076, -169.01],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.306, -169.304],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.288, -169.148],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.528, -168.672],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.891, -167.888],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.483, -166.943],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.419, -165.805],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.92, -164.598],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.238, -163.34],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.458, -162.049],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.828, -160.75],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.448, -159.435],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.376, -158.177],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-193.722, -156.922],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.591, -155.724],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.995, -154.552],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.034, -153.403],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.681, -152.308],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.074, -151.244],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.15, -150.24],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.024, -149.247],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.627, -148.32],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.07, -147.398],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.305, -146.554],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.388, -145.743],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-231.316, -144.952],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.096, -144.191],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-234.76, -143.483],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-236.289, -142.817],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-237.723, -142.188],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-239.096, -141.547],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.326, -140.962],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-241.488, -140.424],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-242.569, -139.894],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-243.579, -139.406],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-244.536, -138.946],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.426, -138.484],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.261, -138.076],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-247.017, -137.681],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-247.693, -137.312],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-248.384, -136.955],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.004, -136.629],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.578, -136.31],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.098, -136.032],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.594, -135.758],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.057, -135.519],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.478, -135.271],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.857, -135.071],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.209, -134.861],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.551, -134.654],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.844, -134.504],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.119, -134.349],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.328, -134.2],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.574, -134.073],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.763, -133.926],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.92, -133.836],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.067, -133.749],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.282, -133.63],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.427, -133.536],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 5',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 5,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.068, 100.092],
                                  [-251.866, -211.89],
                                  [-311.919, -139.965],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.157, 100.183],
                                  [-251.689, -211.798],
                                  [-311.752, -139.953],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.268, 100.298],
                                  [-251.462, -211.596],
                                  [-311.602, -139.893],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.466, 100.479],
                                  [-251.082, -211.346],
                                  [-311.304, -139.827],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.666, 100.726],
                                  [-250.613, -211.006],
                                  [-310.94, -139.736],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.952, 100.997],
                                  [-250.095, -210.619],
                                  [-310.533, -139.638],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.284, 101.356],
                                  [-249.404, -210.103],
                                  [-310.02, -139.536],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.639, 101.781],
                                  [-248.584, -209.518],
                                  [-309.402, -139.365],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.103, 102.251],
                                  [-247.655, -208.825],
                                  [-308.658, -139.189],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.655, 102.808],
                                  [-246.554, -207.982],
                                  [-307.767, -139.029],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.275, 103.474],
                                  [-245.242, -207.05],
                                  [-306.75, -138.822],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.964, 104.206],
                                  [-243.718, -205.906],
                                  [-305.545, -138.549],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.805, 105.067],
                                  [-241.962, -204.589],
                                  [-304.171, -138.314],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.738, 106.036],
                                  [-239.975, -203.08],
                                  [-302.546, -138.015],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.827, 107.134],
                                  [-237.653, -201.335],
                                  [-300.646, -137.673],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.073, 108.423],
                                  [-234.954, -199.313],
                                  [-298.471, -137.269],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.522, 109.903],
                                  [-231.879, -196.946],
                                  [-295.934, -136.889],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.153, 111.553],
                                  [-228.282, -194.193],
                                  [-292.97, -136.41],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.076, 113.477],
                                  [-224.101, -190.945],
                                  [-289.534, -135.879],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.296, 115.715],
                                  [-219.189, -187.198],
                                  [-285.43, -135.32],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.902, 118.287],
                                  [-213.547, -182.779],
                                  [-280.675, -134.678],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.898, 121.256],
                                  [-206.901, -177.619],
                                  [-275.048, -133.961],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.359, 124.66],
                                  [-199.231, -171.56],
                                  [-268.518, -133.177],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.311, 128.498],
                                  [-190.536, -164.681],
                                  [-261.054, -132.315],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.678, 132.729],
                                  [-180.855, -157.036],
                                  [-252.733, -131.422],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-229.357, 137.207],
                                  [-170.673, -148.918],
                                  [-243.906, -130.561],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.136, 141.725],
                                  [-160.384, -140.71],
                                  [-234.961, -129.665],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-238.76, 146.054],
                                  [-150.581, -132.775],
                                  [-226.326, -128.891],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.201, 150.094],
                                  [-141.472, -125.395],
                                  [-218.306, -128.213],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.332, 153.781],
                                  [-133.208, -118.638],
                                  [-210.951, -127.685],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.196, 157.162],
                                  [-125.835, -112.533],
                                  [-204.287, -127.203],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.815, 160.214],
                                  [-119.251, -107.036],
                                  [-198.295, -126.87],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-258.189, 162.977],
                                  [-113.373, -102.09],
                                  [-192.893, -126.602],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-261.363, 165.495],
                                  [-108.186, -97.564],
                                  [-188.02, -126.426],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.428, 167.832],
                                  [-103.524, -93.506],
                                  [-183.57, -126.34],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-267.324, 169.988],
                                  [-99.411, -89.763],
                                  [-179.504, -126.35],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-270.133, 171.94],
                                  [-95.726, -86.383],
                                  [-175.795, -126.435],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-272.89, 173.776],
                                  [-92.473, -83.281],
                                  [-172.408, -126.578],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-275.572, 175.489],
                                  [-89.549, -80.391],
                                  [-169.253, -126.805],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.252, 177.089],
                                  [-87.004, -77.759],
                                  [-166.358, -127.115],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-280.908, 178.613],
                                  [-84.77, -75.324],
                                  [-163.717, -127.43],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-283.552, 180.015],
                                  [-82.844, -73.055],
                                  [-161.227, -127.919],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-286.205, 181.342],
                                  [-81.175, -70.943],
                                  [-158.952, -128.397],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-288.869, 182.639],
                                  [-79.794, -69.026],
                                  [-156.803, -128.971],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-291.575, 183.862],
                                  [-78.658, -67.214],
                                  [-154.827, -129.643],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.339, 184.982],
                                  [-77.767, -65.507],
                                  [-152.979, -130.379],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-297.126, 186.119],
                                  [-77.057, -63.951],
                                  [-151.26, -131.194],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-299.964, 187.151],
                                  [-76.585, -62.499],
                                  [-149.638, -132.085],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.871, 188.169],
                                  [-76.28, -61.104],
                                  [-148.143, -133.046],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-305.786, 189.126],
                                  [-76.176, -59.841],
                                  [-146.711, -134.006],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-308.687, 190.051],
                                  [-76.175, -58.612],
                                  [-145.353, -135.096],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-311.597, 190.924],
                                  [-76.275, -57.465],
                                  [-144.067, -136.145],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.472, 191.761],
                                  [-76.481, -56.377],
                                  [-142.834, -137.263],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-317.266, 192.589],
                                  [-76.742, -55.285],
                                  [-141.683, -138.372],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-320.022, 193.343],
                                  [-77.019, -54.284],
                                  [-140.518, -139.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-322.623, 194.021],
                                  [-77.269, -53.291],
                                  [-139.437, -140.513],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.127, 194.685],
                                  [-77.557, -52.357],
                                  [-138.345, -141.598],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-327.549, 195.308],
                                  [-77.789, -51.458],
                                  [-137.355, -142.554],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.785, 195.931],
                                  [-78.053, -50.585],
                                  [-136.348, -143.552],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-331.946, 196.465],
                                  [-78.237, -49.755],
                                  [-135.402, -144.475],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-333.932, 196.986],
                                  [-78.433, -48.908],
                                  [-134.46, -145.325],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-335.835, 197.484],
                                  [-78.547, -48.122],
                                  [-133.587, -146.162],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.609, 197.921],
                                  [-78.708, -47.403],
                                  [-132.725, -146.913],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-339.323, 198.336],
                                  [-78.837, -46.673],
                                  [-131.878, -147.706],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-340.917, 198.741],
                                  [-78.922, -46.005],
                                  [-131.112, -148.366],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-342.408, 199.128],
                                  [-79.05, -45.331],
                                  [-130.366, -149.07],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-343.79, 199.456],
                                  [-79.123, -44.734],
                                  [-129.654, -149.696],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-345.128, 199.766],
                                  [-79.207, -44.175],
                                  [-128.968, -150.307],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-346.354, 200.07],
                                  [-79.296, -43.594],
                                  [-128.373, -150.853],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-347.555, 200.368],
                                  [-79.406, -43.106],
                                  [-127.765, -151.388],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-348.652, 200.651],
                                  [-79.476, -42.609],
                                  [-127.24, -151.912],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-349.719, 200.877],
                                  [-79.573, -42.156],
                                  [-126.696, -152.417],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-350.698, 201.089],
                                  [-79.689, -41.738],
                                  [-126.196, -152.863],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-351.653, 201.307],
                                  [-79.774, -41.366],
                                  [-125.793, -153.301],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-352.513, 201.502],
                                  [-79.894, -40.987],
                                  [-125.374, -153.724],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-353.355, 201.703],
                                  [-79.988, -40.656],
                                  [-125.001, -154.142],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-354.125, 201.891],
                                  [-80.172, -40.363],
                                  [-124.623, -154.503],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-354.861, 202.024],
                                  [-80.281, -40.119],
                                  [-124.337, -154.85],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-355.582, 202.145],
                                  [-80.475, -39.872],
                                  [-124.046, -155.195],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.227, 202.272],
                                  [-80.596, -39.673],
                                  [-123.795, -155.538],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.851, 202.388],
                                  [-80.762, -39.472],
                                  [-123.604, -155.861],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.411, 202.502],
                                  [-80.963, -39.311],
                                  [-123.4, -156.138],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.943, 202.606],
                                  [-81.149, -39.202],
                                  [-123.237, -156.405],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-358.467, 202.707],
                                  [-81.382, -39.09],
                                  [-123.135, -156.663],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-358.983, 202.753],
                                  [-81.601, -39.03],
                                  [-123.074, -156.922],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-359.42, 202.851],
                                  [-81.86, -38.959],
                                  [-123.012, -157.127],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-359.788, 202.895],
                                  [-82.106, -38.948],
                                  [-122.948, -157.377],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.205, 202.928],
                                  [-82.349, -38.928],
                                  [-122.928, -157.566],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.554, 202.959],
                                  [-82.635, -38.959],
                                  [-122.959, -157.757],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.845, 202.981],
                                  [-82.865, -39.044],
                                  [-122.874, -157.932],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-361.087, 203.039],
                                  [-82.977, -39.181],
                                  [-122.625, -157.983],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-361.235, 203.132],
                                  [-82.997, -39.371],
                                  [-122.134, -157.966],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-361.28, 203.2],
                                  [-82.846, -39.723],
                                  [-121.479, -157.917],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-361.239, 203.304],
                                  [-82.593, -40.128],
                                  [-120.482, -157.746],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-361.041, 203.437],
                                  [-82.186, -40.64],
                                  [-119.249, -157.445],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.749, 203.6],
                                  [-81.615, -41.261],
                                  [-117.736, -157.068],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.378, 203.792],
                                  [-80.897, -42.044],
                                  [-115.871, -156.552],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-359.858, 203.952],
                                  [-79.97, -42.936],
                                  [-113.715, -155.961],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-359.143, 204.189],
                                  [-78.833, -43.936],
                                  [-111.107, -155.241],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-358.303, 204.503],
                                  [-77.494, -45.154],
                                  [-108.038, -154.321],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.276, 204.824],
                                  [-75.844, -46.59],
                                  [-104.506, -153.256],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.014, 205.16],
                                  [-73.938, -48.19],
                                  [-100.458, -152],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-354.58, 205.613],
                                  [-71.728, -50.062],
                                  [-95.776, -150.536],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-352.817, 206.122],
                                  [-69.159, -52.208],
                                  [-90.405, -148.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-350.789, 206.67],
                                  [-66.131, -54.628],
                                  [-84.228, -146.826],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-348.446, 207.258],
                                  [-62.651, -57.431],
                                  [-77.127, -144.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-345.705, 208.035],
                                  [-58.608, -60.671],
                                  [-68.938, -141.906],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-342.454, 208.877],
                                  [-53.909, -64.405],
                                  [-59.481, -138.794],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-338.723, 209.83],
                                  [-48.506, -68.686],
                                  [-48.583, -135.218],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-334.364, 210.967],
                                  [-42.14, -73.681],
                                  [-36.009, -131.101],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.243, 212.28],
                                  [-34.764, -79.443],
                                  [-21.368, -126.27],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-323.279, 213.794],
                                  [-26.173, -86.083],
                                  [-4.369, -120.657],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-316.369, 215.551],
                                  [-16.271, -93.766],
                                  [15.168, -114.183],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-308.463, 217.55],
                                  [-5.057, -102.492],
                                  [37.354, -106.793],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-299.753, 219.757],
                                  [7.38, -112.1],
                                  [61.793, -98.676],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.544, 222.133],
                                  [20.586, -122.261],
                                  [87.626, -90.098],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-281.217, 224.459],
                                  [33.858, -132.539],
                                  [113.697, -81.45],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-272.22, 226.765],
                                  [46.649, -142.385],
                                  [138.748, -73.136],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-263.767, 228.94],
                                  [58.612, -151.579],
                                  [162.19, -65.353],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-256.104, 230.951],
                                  [69.587, -160.012],
                                  [183.577, -58.281],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.069, 232.748],
                                  [79.518, -167.682],
                                  [202.96, -51.863],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.744, 234.431],
                                  [88.456, -174.588],
                                  [220.504, -46.1],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-237.029, 235.913],
                                  [96.604, -180.839],
                                  [236.441, -40.867],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-231.784, 237.296],
                                  [104.006, -186.543],
                                  [250.832, -36.092],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.019, 238.561],
                                  [110.756, -191.755],
                                  [264.021, -31.77],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.649, 239.748],
                                  [116.97, -196.53],
                                  [276.109, -27.836],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-218.627, 240.849],
                                  [122.678, -200.921],
                                  [287.174, -24.229],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.909, 241.862],
                                  [127.888, -204.929],
                                  [297.426, -20.892],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.455, 242.819],
                                  [132.747, -208.662],
                                  [306.88, -17.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.265, 243.721],
                                  [137.263, -212.176],
                                  [315.638, -14.926],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.239, 244.502],
                                  [141.475, -215.415],
                                  [323.825, -12.295],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.494, 245.329],
                                  [145.39, -218.434],
                                  [331.432, -9.804],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.866, 246.029],
                                  [149.055, -221.233],
                                  [338.577, -7.505],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.419, 246.702],
                                  [152.517, -223.866],
                                  [345.212, -5.393],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.159, 247.358],
                                  [155.728, -226.389],
                                  [351.494, -3.349],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.971, 247.988],
                                  [158.743, -228.692],
                                  [357.369, -1.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.923, 248.584],
                                  [161.601, -230.883],
                                  [362.851, 0.339],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.003, 249.146],
                                  [164.317, -232.964],
                                  [368.089, 1.997],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.231, 249.682],
                                  [166.821, -234.934],
                                  [372.989, 3.587],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.54, 250.121],
                                  [169.231, -236.738],
                                  [377.598, 5.046],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.936, 250.636],
                                  [171.49, -238.486],
                                  [381.916, 6.444],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.371, 251.061],
                                  [173.592, -240.123],
                                  [386.06, 7.719],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.95, 251.5],
                                  [175.592, -241.649],
                                  [389.921, 8.979],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.616, 251.897],
                                  [177.495, -243.118],
                                  [393.601, 10.124],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.33, 252.253],
                                  [179.297, -244.477],
                                  [397.051, 11.256],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.077, 252.621],
                                  [181.002, -245.78],
                                  [400.321, 12.271],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.975, 253.002],
                                  [182.597, -247.026],
                                  [403.424, 13.28],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.859, 253.286],
                                  [184.089, -248.216],
                                  [406.353, 14.228],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.846, 253.63],
                                  [185.54, -249.295],
                                  [409.101, 15.052],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.866, 253.878],
                                  [186.88, -250.318],
                                  [411.682, 15.87],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.942, 254.186],
                                  [188.172, -251.339],
                                  [414.144, 16.689],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.107, 254.451],
                                  [189.36, -252.25],
                                  [416.488, 17.385],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.266, 254.667],
                                  [190.5, -253.104],
                                  [418.657, 18.074],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.528, 254.951],
                                  [191.537, -253.957],
                                  [420.707, 18.765],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.776, 255.123],
                                  [192.572, -254.698],
                                  [422.646, 19.332],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.128, 255.363],
                                  [193.504, -255.439],
                                  [424.466, 19.9],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.473, 255.553],
                                  [194.388, -256.123],
                                  [426.167, 20.462],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.867, 255.748],
                                  [195.216, -256.751],
                                  [427.756, 20.963],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.302, 255.894],
                                  [195.994, -257.378],
                                  [429.282, 21.464],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.787, 256.046],
                                  [196.725, -257.894],
                                  [430.633, 21.842],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.327, 256.203],
                                  [197.399, -258.463],
                                  [431.983, 22.284],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.853, 256.365],
                                  [198.025, -258.922],
                                  [433.159, 22.657],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.429, 256.478],
                                  [198.594, -259.379],
                                  [434.278, 23.03],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.06, 256.596],
                                  [199.115, -259.78],
                                  [435.334, 23.343],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.678, 256.712],
                                  [199.635, -260.18],
                                  [436.278, 23.601],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.352, 256.834],
                                  [200.107, -260.524],
                                  [437.103, 23.908],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.068, 256.907],
                                  [200.522, -260.811],
                                  [437.927, 24.154],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.784, 256.985],
                                  [200.889, -261.098],
                                  [438.632, 24.345],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.549, 257.068],
                                  [201.199, -261.384],
                                  [439.28, 24.593],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.371, 257.15],
                                  [201.516, -261.613],
                                  [439.865, 24.724],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.123, 257.238],
                                  [201.777, -261.786],
                                  [440.338, 24.904],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.987, 257.275],
                                  [201.982, -261.959],
                                  [440.81, 25.03],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.845, 257.312],
                                  [202.194, -262.13],
                                  [441.164, 25.156],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.751, 257.354],
                                  [202.349, -262.246],
                                  [441.46, 25.276],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.604, 257.394],
                                  [202.511, -262.36],
                                  [441.749, 25.342],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.56, 257.385],
                                  [202.609, -262.418],
                                  [441.933, 25.401],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.517, 257.436],
                                  [202.667, -262.476],
                                  [442.046, 25.407],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.468, 257.431],
                                  [202.716, -262.532],
                                  [442.165, 25.468],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.466, 257.429],
                                  [202.6, -262.526],
                                  [441.992, 25.466],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.526, 257.292],
                                  [202.314, -262.555],
                                  [441.519, 25.394],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.586, 257.097],
                                  [201.788, -262.563],
                                  [440.705, 25.314],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.701, 256.876],
                                  [201.043, -262.555],
                                  [439.478, 25.227],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.88, 256.531],
                                  [200, -262.578],
                                  [437.837, 25.071],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.054, 256.058],
                                  [198.73, -262.632],
                                  [435.789, 24.842],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.289, 255.505],
                                  [197.119, -262.657],
                                  [433.221, 24.601],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.521, 254.826],
                                  [195.161, -262.709],
                                  [430.126, 24.289],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.872, 254.067],
                                  [192.854, -262.79],
                                  [426.453, 23.907],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.28, 253.168],
                                  [190.15, -262.83],
                                  [422.14, 23.504],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.684, 252.078],
                                  [186.993, -262.877],
                                  [417.129, 23.02],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.199, 250.846],
                                  [183.382, -262.992],
                                  [411.318, 22.394],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.778, 249.411],
                                  [179.199, -263.057],
                                  [404.642, 21.738],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.456, 247.774],
                                  [174.349, -263.162],
                                  [396.992, 20.938],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.246, 245.871],
                                  [168.881, -263.25],
                                  [388.147, 20.027],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.088, 243.702],
                                  [162.576, -263.311],
                                  [378.11, 19.015],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.089, 241.263],
                                  [155.326, -263.442],
                                  [366.547, 17.773],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.244, 238.43],
                                  [147.09, -263.546],
                                  [353.361, 16.334],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.489, 235.149],
                                  [137.602, -263.62],
                                  [338.227, 14.674],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.974, 231.412],
                                  [126.712, -263.68],
                                  [320.78, 12.732],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.586, 227.159],
                                  [114.23, -263.727],
                                  [300.713, 10.399],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.471, 222.217],
                                  [99.819, -263.716],
                                  [277.588, 7.634],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.543, 216.532],
                                  [83.305, -263.632],
                                  [251.026, 4.319],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.871, 210.066],
                                  [64.504, -263.4],
                                  [220.752, 0.39],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.404, 202.791],
                                  [43.472, -262.957],
                                  [186.859, -4.222],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.004, 194.923],
                                  [20.712, -262.273],
                                  [149.964, -9.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.546, 186.755],
                                  [-2.874, -261.285],
                                  [111.68, -15.506],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.963, 178.68],
                                  [-26.119, -260.042],
                                  [73.852, -21.832],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.983, 171.072],
                                  [-47.918, -258.487],
                                  [38.124, -28.207],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.711, 164.103],
                                  [-67.687, -256.785],
                                  [5.545, -34.475],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.053, 157.924],
                                  [-85.325, -254.943],
                                  [-23.579, -40.453],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.104, 152.455],
                                  [-100.95, -253.134],
                                  [-49.416, -46.151],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.931, 147.65],
                                  [-114.675, -251.309],
                                  [-72.313, -51.526],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.549, 143.387],
                                  [-126.786, -249.497],
                                  [-92.621, -56.525],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.994, 139.584],
                                  [-137.569, -247.722],
                                  [-110.743, -61.235],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.269, 136.231],
                                  [-147.12, -246.029],
                                  [-126.951, -65.684],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.465, 133.23],
                                  [-155.712, -244.399],
                                  [-141.501, -69.885],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.601, 130.549],
                                  [-163.453, -242.779],
                                  [-154.654, -73.798],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.632, 128.102],
                                  [-170.429, -241.259],
                                  [-166.598, -77.531],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.634, 125.9],
                                  [-176.735, -239.771],
                                  [-177.497, -81.035],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.541, 123.864],
                                  [-182.525, -238.356],
                                  [-187.419, -84.361],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.469, 122.057],
                                  [-187.765, -237.026],
                                  [-196.519, -87.53],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.351, 120.358],
                                  [-192.601, -235.681],
                                  [-204.901, -90.504],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.177, 118.82],
                                  [-196.998, -234.414],
                                  [-212.623, -93.323],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.025, 117.45],
                                  [-201.097, -233.232],
                                  [-219.756, -96.041],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.812, 116.134],
                                  [-204.842, -232.087],
                                  [-226.337, -98.626],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.63, 114.908],
                                  [-208.344, -230.932],
                                  [-232.479, -101.029],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.395, 113.802],
                                  [-211.545, -229.896],
                                  [-238.17, -103.397],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.18, 112.776],
                                  [-214.555, -228.845],
                                  [-243.459, -105.593],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.963, 111.816],
                                  [-217.315, -227.864],
                                  [-248.431, -107.704],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.771, 110.944],
                                  [-219.89, -226.927],
                                  [-253.034, -109.682],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.518, 110.113],
                                  [-222.306, -226.019],
                                  [-257.327, -111.607],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.276, 109.38],
                                  [-224.541, -225.151],
                                  [-261.362, -113.413],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.084, 108.657],
                                  [-226.621, -224.327],
                                  [-265.098, -115.163],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.862, 108.009],
                                  [-228.547, -223.564],
                                  [-268.618, -116.834],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.629, 107.366],
                                  [-230.364, -222.817],
                                  [-271.929, -118.422],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.456, 106.793],
                                  [-232.091, -222.067],
                                  [-274.988, -119.885],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.213, 106.239],
                                  [-233.667, -221.354],
                                  [-277.921, -121.306],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.006, 105.728],
                                  [-235.154, -220.717],
                                  [-280.605, -122.664],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.818, 105.281],
                                  [-236.534, -220.095],
                                  [-283.146, -123.959],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.605, 104.85],
                                  [-237.831, -219.504],
                                  [-285.543, -125.165],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.432, 104.414],
                                  [-239.061, -218.926],
                                  [-287.798, -126.326],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.258, 104.057],
                                  [-240.207, -218.399],
                                  [-289.849, -127.44],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.125, 103.694],
                                  [-241.266, -217.881],
                                  [-291.842, -128.466],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.949, 103.322],
                                  [-242.242, -217.372],
                                  [-293.651, -129.446],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.752, 103.047],
                                  [-243.132, -216.932],
                                  [-295.361, -130.378],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.639, 102.761],
                                  [-244.021, -216.499],
                                  [-296.951, -131.263],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.486, 102.466],
                                  [-244.826, -216.072],
                                  [-298.462, -132.078],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.354, 102.221],
                                  [-245.566, -215.671],
                                  [-299.854, -132.845],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.182, 101.966],
                                  [-246.263, -215.316],
                                  [-301.145, -133.585],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.094, 101.782],
                                  [-246.896, -214.985],
                                  [-302.337, -134.256],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.967, 101.563],
                                  [-247.506, -214.679],
                                  [-303.471, -134.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.863, 101.374],
                                  [-248.052, -214.354],
                                  [-304.504, -135.494],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.781, 101.191],
                                  [-248.532, -214.072],
                                  [-305.479, -136.04],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.659, 101.036],
                                  [-249.01, -213.812],
                                  [-306.332, -136.538],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.603, 100.929],
                                  [-249.444, -213.573],
                                  [-307.126, -137.008],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.507, 100.765],
                                  [-249.833, -213.355],
                                  [-307.881, -137.45],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.434, 100.649],
                                  [-250.198, -213.179],
                                  [-308.513, -137.864],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.343, 100.559],
                                  [-250.476, -212.98],
                                  [-309.127, -138.209],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.296, 100.432],
                                  [-250.791, -212.8],
                                  [-309.68, -138.526],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.21, 100.373],
                                  [-251.061, -212.639],
                                  [-310.129, -138.795],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.21, 100.276],
                                  [-251.242, -212.496],
                                  [-310.538, -139.058],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.149, 100.226],
                                  [-251.44, -212.391],
                                  [-310.906, -139.272],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.133, 100.159],
                                  [-251.632, -212.304],
                                  [-311.253, -139.48],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.056, 100.096],
                                  [-251.736, -212.233],
                                  [-311.474, -139.661],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.045, 100.057],
                                  [-251.833, -212.137],
                                  [-311.673, -139.752],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.015, 100.044],
                                  [-251.924, -212.119],
                                  [-311.829, -139.879],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.383, 0.383, 0.383, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.383, 0.383, 0.383, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-230.85, -102.012],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.787, -101.915],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.7, -101.805],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.591, -101.625],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.411, -101.381],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.172, -101.046],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.932, -100.664],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.593, -100.154],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.181, -99.589],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.733, -98.909],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.199, -98.063],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.556, -97.139],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-226.799, -96.036],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.942, -94.738],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.967, -93.244],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-223.832, -91.533],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.514, -89.559],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.022, -87.212],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.27, -84.544],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.256, -81.413],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.893, -77.782],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.224, -73.538],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.087, -68.644],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.508, -62.993],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.503, -56.692],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.061, -49.88],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.43, -42.837],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.736, -35.966],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.235, -29.52],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.018, -23.716],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.147, -18.527],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.692, -13.877],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.598, -9.754],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.831, -6.072],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.414, -2.717],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.295, 0.325],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.466, 3.138],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.88, 5.677],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.567, 8.04],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.441, 10.245],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.58, 12.289],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.926, 14.21],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.469, 15.995],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.195, 17.676],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.147, 19.27],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.265, 20.764],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.569, 22.166],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.011, 23.493],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.634, 24.729],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.373, 25.907],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.283, 27.02],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.211, 28.052],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.218, 29.029],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.264, 29.936],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.282, 30.808],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.317, 31.617],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.225, 32.343],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.123, 33.027],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.954, 33.675],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.718, 34.284],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.395, 34.83],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-166.008, 35.375],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-166.526, 35.863],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.062, 36.317],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.517, 36.738],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.936, 37.148],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.313, 37.526],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.643, 37.86],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.973, 38.172],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.253, 38.483],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.577, 38.768],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.81, 39.028],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.07, 39.258],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.33, 39.478],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.564, 39.671],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.784, 39.857],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.99, 40.024],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.266, 40.19],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.479, 40.306],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.745, 40.42],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.943, 40.51],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.159, 40.594],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.408, 40.677],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.652, 40.735],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.92, 40.79],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.186, 40.801],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.465, 40.848],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.712, 40.834],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.988, 40.838],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.28, 40.814],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.551, 40.783],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.808, 40.799],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.041, 40.851],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.21, 40.861],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.382, 40.935],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.503, 41.039],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.58, 41.167],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.684, 41.332],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.683, 41.492],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.597, 41.718],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.535, 42.01],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.372, 42.322],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.119, 42.687],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.829, 43.133],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.422, 43.655],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.848, 44.249],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.146, 44.905],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.259, 45.7],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.098, 46.586],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.613, 47.538],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.568, 48.495],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.701, 49.278],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.34, 49.336],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.267, 47.439],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.094, 42.06],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.151, 33.261],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.055, 22.346],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.318, 10.425],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.12, -1.414],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.014, -12.74],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.358, -23.231],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.082, -32.9],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.167, -41.678],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.387, -49.691],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.39, -57.02],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.272, -63.741],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.394, -69.899],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.827, -75.562],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.655, -80.806],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.964, -85.65],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.825, -90.212],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.283, -94.448],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.318, -98.359],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.059, -102.053],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.475, -105.474],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.606, -108.763],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.486, -111.787],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.137, -114.644],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.59, -117.352],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.797, -119.924],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.838, -122.296],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.688, -124.538],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.397, -126.704],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.937, -128.708],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.338, -130.621],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.619, -132.419],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.772, -134.11],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.781, -135.723],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.702, -137.302],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.49, -138.672],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.193, -140.035],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.823, -141.353],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.317, -142.547],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.757, -143.681],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.079, -144.783],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.362, -145.763],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.528, -146.711],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.65, -147.614],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.687, -148.431],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.682, -149.265],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.567, -149.923],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.423, -150.663],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.202, -151.253],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.932, -151.863],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.59, -152.396],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.22, -152.885],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.79, -153.316],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.31, -153.716],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.77, -154.085],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.188, -154.469],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.549, -154.739],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.887, -154.973],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.158, -155.215],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.412, -155.425],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.605, -155.58],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.804, -155.72],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.921, -155.811],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.983, -155.857],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.973, -155.945],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.727, -155.986],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.299, -156.035],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.666, -156.051],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.79, -156.125],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.727, -156.24],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.383, -156.352],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.768, -156.515],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.83, -156.689],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.565, -156.858],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.95, -157.085],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.917, -157.343],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.43, -157.624],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.392, -157.974],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.8, -158.302],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.556, -158.699],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.498, -159.153],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.605, -159.631],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.704, -160.175],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.603, -160.73],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.172, -161.294],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.129, -161.883],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.337, -162.448],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.634, -162.903],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.918, -163.178],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.906, -163.11],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-43.542, -162.618],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.846, -161.698],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.863, -160.254],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.129, -158.457],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.521, -156.378],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.166, -154.176],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.182, -151.846],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.797, -149.5],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.26, -147.147],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.659, -144.841],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.239, -142.581],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.096, -140.351],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.295, -138.236],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.939, -136.168],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.13, -134.209],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-190.886, -132.335],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.3, -130.507],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.35, -128.776],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.168, -127.119],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.691, -125.56],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.033, -124.05],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.129, -122.632],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.083, -121.251],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.851, -119.979],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.489, -118.775],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.989, -117.61],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.361, -116.506],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.638, -115.469],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.796, -114.504],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.875, -113.603],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.909, -112.711],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.817, -111.889],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.677, -111.133],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.47, -110.399],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-223.202, -109.728],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-223.901, -109.09],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.542, -108.467],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.146, -107.915],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.679, -107.384],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-226.151, -106.898],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-226.65, -106.425],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.085, -106.001],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.486, -105.589],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.845, -105.228],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.195, -104.881],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.517, -104.571],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.808, -104.262],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.068, -104.008],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.311, -103.747],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.549, -103.494],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.749, -103.302],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.938, -103.109],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.072, -102.934],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.245, -102.776],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.373, -102.604],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.48, -102.49],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.576, -102.386],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.686, -102.305],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.718, -102.236],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.772, -102.165],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.844, -102.072],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-230.85, -102.012],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.787, -101.915],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.7, -101.805],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.591, -101.625],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.411, -101.381],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.172, -101.046],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.932, -100.664],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.593, -100.154],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.181, -99.589],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.733, -98.909],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.199, -98.063],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.556, -97.139],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-226.799, -96.036],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.942, -94.738],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.967, -93.244],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-223.832, -91.533],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.514, -89.559],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.022, -87.212],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.27, -84.544],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.256, -81.413],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-214.893, -77.782],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.224, -73.538],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.087, -68.644],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.508, -62.993],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.503, -56.692],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.061, -49.88],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.43, -42.837],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.736, -35.966],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.235, -29.52],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.018, -23.716],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.147, -18.527],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.692, -13.877],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.598, -9.754],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.831, -6.072],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.414, -2.717],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.295, 0.325],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.466, 3.138],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.88, 5.677],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.567, 8.04],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.441, 10.245],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.58, 12.289],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.926, 14.21],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.469, 15.995],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.195, 17.676],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.147, 19.27],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.265, 20.764],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.569, 22.166],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.011, 23.493],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.634, 24.729],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.373, 25.907],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.283, 27.02],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.211, 28.052],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.218, 29.029],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.264, 29.936],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.282, 30.808],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.317, 31.617],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.225, 32.343],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.123, 33.027],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.954, 33.675],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.718, 34.284],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.395, 34.83],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-166.008, 35.375],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-166.526, 35.863],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.062, 36.317],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.517, 36.738],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.936, 37.148],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.313, 37.526],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.643, 37.86],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.973, 38.172],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.253, 38.483],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.577, 38.768],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.81, 39.028],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.07, 39.258],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.33, 39.478],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.564, 39.671],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.784, 39.857],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.99, 40.024],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.266, 40.19],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.479, 40.306],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.745, 40.42],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.943, 40.51],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.159, 40.594],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.408, 40.677],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.652, 40.735],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.92, 40.79],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.186, 40.801],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.465, 40.848],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.712, 40.834],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.988, 40.838],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.28, 40.814],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.551, 40.783],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.808, 40.799],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.041, 40.851],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.21, 40.861],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.382, 40.935],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.503, 41.039],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.58, 41.167],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.684, 41.332],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.683, 41.492],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.597, 41.718],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.535, 42.01],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.372, 42.322],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.119, 42.687],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.829, 43.133],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.422, 43.655],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.848, 44.249],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.146, 44.905],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.259, 45.7],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.098, 46.586],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.613, 47.538],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.568, 48.495],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.701, 49.278],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.34, 49.336],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.267, 47.439],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.094, 42.06],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.151, 33.261],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.055, 22.346],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.318, 10.425],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.12, -1.414],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.014, -12.74],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.358, -23.231],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.082, -32.9],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.167, -41.678],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.387, -49.691],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.39, -57.02],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.272, -63.741],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.394, -69.899],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.827, -75.562],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.655, -80.806],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.964, -85.65],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.825, -90.212],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.283, -94.448],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.318, -98.359],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.059, -102.053],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.475, -105.474],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.606, -108.763],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.486, -111.787],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.137, -114.644],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.59, -117.352],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.797, -119.924],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.838, -122.296],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.688, -124.538],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.397, -126.704],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.937, -128.708],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.338, -130.621],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.619, -132.419],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.772, -134.11],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.781, -135.723],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.702, -137.302],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.49, -138.672],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.193, -140.035],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.823, -141.353],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.317, -142.547],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.757, -143.681],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.079, -144.783],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.362, -145.763],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.528, -146.711],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.65, -147.614],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.687, -148.431],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.682, -149.265],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.567, -149.923],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.423, -150.663],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.202, -151.253],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.932, -151.863],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.59, -152.396],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.22, -152.885],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.79, -153.316],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.31, -153.716],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.77, -154.085],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.188, -154.469],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.549, -154.739],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.887, -154.973],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.158, -155.215],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.412, -155.425],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.605, -155.58],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.804, -155.72],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.921, -155.811],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.983, -155.857],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.973, -155.945],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.727, -155.986],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.299, -156.035],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.666, -156.051],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.79, -156.125],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.727, -156.24],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.383, -156.352],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.768, -156.515],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.83, -156.689],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.565, -156.858],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.95, -157.085],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.917, -157.343],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.43, -157.624],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.392, -157.974],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.8, -158.302],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.556, -158.699],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.498, -159.153],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.605, -159.631],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.704, -160.175],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.603, -160.73],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.172, -161.294],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.129, -161.883],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.337, -162.448],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.634, -162.903],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.918, -163.178],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.906, -163.11],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-43.542, -162.618],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.846, -161.698],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.863, -160.254],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.129, -158.457],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.521, -156.378],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.166, -154.176],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.182, -151.846],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.797, -149.5],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.26, -147.147],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.659, -144.841],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.239, -142.581],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.096, -140.351],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.295, -138.236],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.939, -136.168],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.13, -134.209],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-190.886, -132.335],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.3, -130.507],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.35, -128.776],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.168, -127.119],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.691, -125.56],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.033, -124.05],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.129, -122.632],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.083, -121.251],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-210.851, -119.979],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.489, -118.775],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.989, -117.61],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.361, -116.506],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.638, -115.469],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-217.796, -114.504],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.875, -113.603],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-219.909, -112.711],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.817, -111.889],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.677, -111.133],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.47, -110.399],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-223.202, -109.728],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-223.901, -109.09],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.542, -108.467],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.146, -107.915],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.679, -107.384],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-226.151, -106.898],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-226.65, -106.425],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.085, -106.001],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.486, -105.589],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.845, -105.228],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.195, -104.881],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.517, -104.571],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.808, -104.262],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.068, -104.008],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.311, -103.747],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.549, -103.494],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.749, -103.302],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-229.938, -103.109],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.072, -102.934],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.245, -102.776],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.373, -102.604],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.48, -102.49],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.576, -102.386],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.686, -102.305],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.718, -102.236],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.772, -102.165],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.844, -102.072],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 6',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 6,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.24, 92.804],
                                  [-223.178, -180.779],
                                  [-273.583, -151.969],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.256, 92.896],
                                  [-223.072, -180.713],
                                  [-273.546, -151.948],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.324, 92.992],
                                  [-222.906, -180.649],
                                  [-273.416, -151.909],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.407, 93.116],
                                  [-222.691, -180.488],
                                  [-273.317, -151.814],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.568, 93.307],
                                  [-222.328, -180.293],
                                  [-273.087, -151.701],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.719, 93.564],
                                  [-221.881, -180.026],
                                  [-272.815, -151.545],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.948, 93.849],
                                  [-221.387, -179.726],
                                  [-272.512, -151.37],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.206, 94.227],
                                  [-220.734, -179.318],
                                  [-272.131, -151.176],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.48, 94.67],
                                  [-219.948, -178.852],
                                  [-271.671, -150.902],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.847, 95.168],
                                  [-219.066, -178.303],
                                  [-271.107, -150.597],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.28, 95.758],
                                  [-218.016, -177.635],
                                  [-270.428, -150.286],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.77, 96.452],
                                  [-216.759, -176.896],
                                  [-269.657, -149.908],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.303, 97.225],
                                  [-215.308, -175.976],
                                  [-268.733, -149.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.969, 98.127],
                                  [-213.627, -174.922],
                                  [-267.68, -148.977],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.695, 99.146],
                                  [-211.728, -173.709],
                                  [-266.422, -148.414],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.555, 100.307],
                                  [-209.501, -172.3],
                                  [-264.946, -147.774],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.529, 101.66],
                                  [-206.918, -170.656],
                                  [-263.25, -147.035],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.669, 103.216],
                                  [-203.971, -168.736],
                                  [-261.268, -146.262],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.951, 104.951],
                                  [-200.519, -166.488],
                                  [-258.935, -145.332],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.468, 106.976],
                                  [-196.502, -163.821],
                                  [-256.222, -144.288],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.213, 109.328],
                                  [-191.781, -160.743],
                                  [-252.959, -143.135],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.268, 112.031],
                                  [-186.355, -157.096],
                                  [-249.164, -141.804],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.637, 115.147],
                                  [-179.961, -152.824],
                                  [-244.651, -140.291],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.37, 118.724],
                                  [-172.574, -147.789],
                                  [-239.395, -138.595],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.499, 122.76],
                                  [-164.193, -142.061],
                                  [-233.373, -136.698],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.958, 127.205],
                                  [-154.864, -135.681],
                                  [-226.627, -134.671],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.683, 131.908],
                                  [-145.045, -128.899],
                                  [-219.469, -132.615],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.508, 136.649],
                                  [-135.133, -122.026],
                                  [-212.197, -130.522],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.216, 141.199],
                                  [-125.688, -115.382],
                                  [-205.175, -128.598],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.814, 145.441],
                                  [-116.924, -109.206],
                                  [-198.655, -126.847],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-231.184, 149.321],
                                  [-108.983, -103.553],
                                  [-192.672, -125.348],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.365, 152.87],
                                  [-101.907, -98.443],
                                  [-187.254, -123.988],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-237.374, 156.075],
                                  [-95.599, -93.851],
                                  [-182.392, -122.868],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-240.209, 158.982],
                                  [-89.985, -89.719],
                                  [-178.013, -121.898],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.911, 161.633],
                                  [-85.044, -85.941],
                                  [-174.069, -121.086],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.551, 164.09],
                                  [-80.618, -82.561],
                                  [-170.469, -120.429],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-248.071, 166.355],
                                  [-76.733, -79.447],
                                  [-167.178, -119.921],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.556, 168.412],
                                  [-73.269, -76.642],
                                  [-164.191, -119.539],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-253.021, 170.342],
                                  [-70.23, -74.071],
                                  [-161.465, -119.259],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.452, 172.149],
                                  [-67.519, -71.678],
                                  [-158.929, -119.1],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-257.911, 173.832],
                                  [-65.181, -69.507],
                                  [-156.605, -119.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-260.385, 175.434],
                                  [-63.163, -67.498],
                                  [-154.499, -119.065],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-262.873, 176.917],
                                  [-61.444, -65.637],
                                  [-152.511, -119.272],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.403, 178.321],
                                  [-59.992, -63.902],
                                  [-150.705, -119.499],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-267.976, 179.688],
                                  [-58.829, -62.338],
                                  [-149.002, -119.849],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-270.616, 180.978],
                                  [-57.916, -60.863],
                                  [-147.443, -120.325],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-273.345, 182.169],
                                  [-57.256, -59.478],
                                  [-145.989, -120.885],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-276.12, 183.367],
                                  [-56.784, -58.224],
                                  [-144.639, -121.549],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.976, 184.465],
                                  [-56.557, -57.058],
                                  [-143.371, -122.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-281.924, 185.547],
                                  [-56.503, -55.936],
                                  [-142.205, -123.172],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.905, 186.567],
                                  [-56.652, -54.923],
                                  [-141.092, -124.054],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.895, 187.549],
                                  [-56.916, -53.942],
                                  [-140.032, -125.076],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.91, 188.482],
                                  [-57.278, -53.021],
                                  [-139.024, -126.077],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-293.902, 189.372],
                                  [-57.749, -52.151],
                                  [-138.057, -127.163],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-296.831, 190.253],
                                  [-58.268, -51.265],
                                  [-137.154, -128.253],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-299.722, 191.059],
                                  [-58.804, -50.453],
                                  [-136.225, -129.315],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.462, 191.788],
                                  [-59.304, -49.641],
                                  [-135.361, -130.38],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-305.112, 192.501],
                                  [-59.835, -48.876],
                                  [-134.473, -131.47],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-307.673, 193.166],
                                  [-60.296, -48.129],
                                  [-133.672, -132.436],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-310.046, 193.832],
                                  [-60.779, -47.404],
                                  [-132.84, -133.444],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-312.34, 194.405],
                                  [-61.176, -46.705],
                                  [-132.058, -134.381],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.459, 194.963],
                                  [-61.57, -45.988],
                                  [-131.268, -135.248],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-316.488, 195.494],
                                  [-61.872, -45.32],
                                  [-130.538, -136.105],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-318.382, 195.965],
                                  [-62.212, -44.706],
                                  [-129.809, -136.876],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-320.21, 196.41],
                                  [-62.505, -44.082],
                                  [-129.084, -137.688],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-321.914, 196.844],
                                  [-62.754, -43.504],
                                  [-128.431, -138.369],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-323.513, 197.256],
                                  [-63.03, -42.92],
                                  [-127.79, -139.095],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-324.999, 197.611],
                                  [-63.245, -42.403],
                                  [-127.178, -139.742],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-326.438, 197.944],
                                  [-63.465, -41.919],
                                  [-126.583, -140.374],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-327.758, 198.27],
                                  [-63.681, -41.408],
                                  [-126.071, -140.945],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.054, 198.59],
                                  [-63.912, -40.984],
                                  [-125.544, -141.505],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-330.239, 198.891],
                                  [-64.098, -40.548],
                                  [-125.091, -142.054],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-331.394, 199.137],
                                  [-64.306, -40.152],
                                  [-124.613, -142.582],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-332.457, 199.366],
                                  [-64.525, -39.784],
                                  [-124.175, -143.054],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-333.495, 199.602],
                                  [-64.71, -39.458],
                                  [-123.828, -143.519],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-334.434, 199.81],
                                  [-64.925, -39.126],
                                  [-123.46, -143.966],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-335.353, 200.026],
                                  [-65.11, -38.836],
                                  [-123.136, -144.41],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-336.203, 200.226],
                                  [-65.38, -38.579],
                                  [-122.805, -144.8],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.011, 200.373],
                                  [-65.574, -38.367],
                                  [-122.556, -145.175],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.804, 200.505],
                                  [-65.844, -38.15],
                                  [-122.302, -145.549],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-338.521, 200.646],
                                  [-66.042, -37.98],
                                  [-122.082, -145.921],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-339.213, 200.773],
                                  [-66.281, -37.806],
                                  [-121.921, -146.269],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-339.845, 200.897],
                                  [-66.55, -37.667],
                                  [-121.744, -146.578],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-340.442, 201.007],
                                  [-66.801, -37.575],
                                  [-121.602, -146.876],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-341.031, 201.115],
                                  [-67.097, -37.482],
                                  [-121.52, -147.163],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-341.613, 201.17],
                                  [-67.377, -37.436],
                                  [-121.475, -147.452],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-342.113, 201.275],
                                  [-67.693, -37.377],
                                  [-121.427, -147.692],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-342.545, 201.327],
                                  [-67.993, -37.378],
                                  [-121.378, -147.973],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-343.023, 201.366],
                                  [-68.292, -37.366],
                                  [-121.366, -148.195],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-343.435, 201.403],
                                  [-68.628, -37.403],
                                  [-121.403, -148.419],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-343.791, 201.428],
                                  [-68.913, -37.49],
                                  [-121.326, -148.624],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.105, 201.48],
                                  [-69.083, -37.627],
                                  [-121.093, -148.707],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.335, 201.56],
                                  [-69.173, -37.815],
                                  [-120.62, -148.72],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.472, 201.605],
                                  [-69.097, -38.155],
                                  [-119.992, -148.693],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.536, 201.68],
                                  [-68.93, -38.546],
                                  [-119.031, -148.546],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.455, 201.773],
                                  [-68.62, -39.04],
                                  [-117.84, -148.267],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.29, 201.885],
                                  [-68.155, -39.636],
                                  [-116.378, -147.909],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.064, 202.017],
                                  [-67.557, -40.387],
                                  [-114.572, -147.409],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-343.702, 202.105],
                                  [-66.764, -41.241],
                                  [-112.483, -146.83],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-343.164, 202.255],
                                  [-65.775, -42.199],
                                  [-109.957, -146.121],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-342.523, 202.467],
                                  [-64.601, -43.364],
                                  [-106.982, -145.21],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-341.716, 202.668],
                                  [-63.138, -44.737],
                                  [-103.558, -144.147],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-340.701, 202.87],
                                  [-61.437, -46.266],
                                  [-99.631, -142.892],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-339.539, 203.165],
                                  [-59.457, -48.055],
                                  [-95.089, -141.425],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-338.086, 203.493],
                                  [-57.145, -50.105],
                                  [-89.878, -139.693],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-336.404, 203.833],
                                  [-54.408, -52.416],
                                  [-83.882, -137.697],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-334.449, 204.185],
                                  [-51.255, -55.092],
                                  [-76.988, -135.416],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-332.149, 204.686],
                                  [-47.581, -58.186],
                                  [-69.038, -132.735],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.398, 205.211],
                                  [-43.302, -61.75],
                                  [-59.855, -129.594],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-326.237, 205.804],
                                  [-38.377, -65.837],
                                  [-49.271, -125.98],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-322.528, 206.517],
                                  [-32.563, -70.605],
                                  [-37.056, -121.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-318.156, 207.341],
                                  [-25.817, -76.105],
                                  [-22.834, -116.923],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-313.056, 208.29],
                                  [-17.95, -82.443],
                                  [-6.321, -111.241],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-307.13, 209.395],
                                  [-8.877, -89.777],
                                  [12.66, -104.682],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.333, 210.661],
                                  [1.407, -98.108],
                                  [34.217, -97.195],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-292.84, 212.057],
                                  [12.82, -107.28],
                                  [57.965, -88.969],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.911, 213.573],
                                  [24.945, -116.982],
                                  [83.072, -80.273],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-276.87, 215.043],
                                  [37.136, -126.797],
                                  [108.412, -71.504],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-269.107, 216.525],
                                  [48.889, -136.201],
                                  [132.763, -63.078],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-261.803, 217.934],
                                  [59.887, -144.985],
                                  [155.55, -55.193],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.182, 219.246],
                                  [69.981, -153.043],
                                  [176.344, -48.025],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.092, 220.415],
                                  [79.116, -160.375],
                                  [195.194, -41.522],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.618, 221.535],
                                  [87.342, -166.978],
                                  [212.255, -35.683],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-238.669, 222.51],
                                  [94.843, -172.957],
                                  [227.756, -30.384],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.121, 223.433],
                                  [101.661, -178.415],
                                  [241.757, -25.552],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-229.987, 224.283],
                                  [107.878, -183.403],
                                  [254.591, -21.175],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-226.193, 225.09],
                                  [113.607, -187.974],
                                  [266.353, -17.193],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.7, 225.843],
                                  [118.869, -192.179],
                                  [277.124, -13.541],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.466, 226.541],
                                  [123.673, -196.018],
                                  [287.102, -10.168],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.461, 227.206],
                                  [128.155, -199.596],
                                  [296.307, -7.053],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.684, 227.837],
                                  [132.325, -202.964],
                                  [304.834, -4.134],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.043, 228.371],
                                  [136.213, -206.069],
                                  [312.807, -1.474],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.654, 228.965],
                                  [139.829, -208.964],
                                  [320.216, 1.044],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.358, 229.451],
                                  [143.216, -211.649],
                                  [327.175, 3.366],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.22, 229.924],
                                  [146.414, -214.176],
                                  [333.641, 5.502],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.25, 230.394],
                                  [149.382, -216.598],
                                  [339.762, 7.568],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.333, 230.85],
                                  [152.171, -218.808],
                                  [345.485, 9.49],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.543, 231.281],
                                  [154.813, -220.913],
                                  [350.829, 11.289],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.859, 231.689],
                                  [157.329, -222.911],
                                  [355.933, 12.965],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.312, 232.082],
                                  [159.645, -224.803],
                                  [360.71, 14.571],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.83, 232.388],
                                  [161.876, -226.537],
                                  [365.204, 16.043],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.423, 232.775],
                                  [163.97, -228.217],
                                  [369.414, 17.454],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.048, 233.084],
                                  [165.915, -229.791],
                                  [373.456, 18.742],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.802, 233.412],
                                  [167.766, -231.258],
                                  [377.223, 20.012],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.631, 233.704],
                                  [169.531, -232.671],
                                  [380.811, 21.169],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.503, 233.961],
                                  [171.201, -233.978],
                                  [384.177, 22.307],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.398, 234.236],
                                  [172.784, -235.232],
                                  [387.365, 23.332],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.432, 234.529],
                                  [174.262, -236.431],
                                  [390.393, 24.349],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.446, 234.734],
                                  [175.644, -237.576],
                                  [393.251, 25.305],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.557, 234.999],
                                  [176.993, -238.615],
                                  [395.931, 26.137],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.692, 235.176],
                                  [178.235, -239.6],
                                  [398.451, 26.961],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.88, 235.413],
                                  [179.434, -240.583],
                                  [400.855, 27.787],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.144, 235.615],
                                  [180.537, -241.46],
                                  [403.142, 28.489],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.4, 235.772],
                                  [181.596, -242.283],
                                  [405.259, 29.183],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.752, 236],
                                  [182.559, -243.104],
                                  [407.26, 29.878],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.086, 236.119],
                                  [183.521, -243.819],
                                  [409.154, 30.45],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.516, 236.308],
                                  [184.386, -244.532],
                                  [410.93, 31.023],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.937, 236.453],
                                  [185.207, -245.192],
                                  [412.591, 31.588],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.403, 236.605],
                                  [185.975, -245.797],
                                  [414.144, 32.091],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.902, 236.711],
                                  [186.699, -246.402],
                                  [415.633, 32.596],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.446, 236.825],
                                  [187.379, -246.899],
                                  [416.952, 32.978],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.044, 236.947],
                                  [188.006, -247.448],
                                  [418.27, 33.423],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.623, 237.077],
                                  [188.588, -247.89],
                                  [419.419, 33.797],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.246, 237.161],
                                  [189.117, -248.331],
                                  [420.513, 34.173],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.923, 237.252],
                                  [189.601, -248.718],
                                  [421.543, 34.487],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.582, 237.342],
                                  [190.085, -249.104],
                                  [422.466, 34.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.295, 237.439],
                                  [190.524, -249.436],
                                  [423.273, 35.057],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.042, 237.492],
                                  [190.91, -249.713],
                                  [424.078, 35.303],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.79, 237.551],
                                  [191.252, -249.99],
                                  [424.766, 35.497],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.583, 237.619],
                                  [191.54, -250.266],
                                  [425.4, 35.745],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.429, 237.684],
                                  [191.837, -250.487],
                                  [425.97, 35.879],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.204, 237.757],
                                  [192.08, -250.655],
                                  [426.433, 36.058],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.086, 237.785],
                                  [192.269, -250.821],
                                  [426.894, 36.184],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.96, 237.812],
                                  [192.467, -250.986],
                                  [427.239, 36.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.877, 237.846],
                                  [192.611, -251.098],
                                  [427.53, 36.431],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.743, 237.878],
                                  [192.764, -251.208],
                                  [427.81, 36.499],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.706, 237.865],
                                  [192.853, -251.265],
                                  [427.992, 36.558],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.67, 237.912],
                                  [192.908, -251.32],
                                  [428.101, 36.565],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.626, 237.905],
                                  [192.953, -251.374],
                                  [428.218, 36.626],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.623, 237.903],
                                  [192.841, -251.367],
                                  [428.051, 36.623],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.679, 237.767],
                                  [192.566, -251.388],
                                  [427.59, 36.545],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.733, 237.577],
                                  [192.056, -251.381],
                                  [426.801, 36.454],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.838, 237.356],
                                  [191.339, -251.356],
                                  [425.61, 36.352],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.006, 237.015],
                                  [190.331, -251.355],
                                  [424.017, 36.178],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.166, 236.55],
                                  [189.104, -251.374],
                                  [422.029, 35.924],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.381, 236.003],
                                  [187.552, -251.36],
                                  [419.538, 35.652],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.592, 235.335],
                                  [185.663, -251.362],
                                  [416.535, 35.303],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.915, 234.586],
                                  [183.437, -251.388],
                                  [412.974, 34.879],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.29, 233.699],
                                  [180.829, -251.36],
                                  [408.791, 34.424],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.658, 232.626],
                                  [177.787, -251.327],
                                  [403.933, 33.878],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.126, 231.412],
                                  [174.308, -251.347],
                                  [398.302, 33.18],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.654, 230],
                                  [170.279, -251.307],
                                  [391.836, 32.439],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.27, 228.39],
                                  [165.614, -251.286],
                                  [384.428, 31.541],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.986, 226.521],
                                  [160.355, -251.23],
                                  [375.871, 30.512],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.745, 224.391],
                                  [154.296, -251.127],
                                  [366.164, 29.365],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.645, 221.999],
                                  [147.334, -251.067],
                                  [354.989, 27.967],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.682, 219.224],
                                  [139.434, -250.946],
                                  [342.258, 26.341],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.788, 216.018],
                                  [130.344, -250.76],
                                  [327.66, 24.457],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.101, 212.372],
                                  [119.924, -250.517],
                                  [310.851, 22.252],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.51, 208.231],
                                  [108.002, -250.207],
                                  [291.543, 19.604],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.148, 203.432],
                                  [94.268, -249.773],
                                  [269.333, 16.457],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.92, 197.928],
                                  [78.564, -249.19],
                                  [243.869, 12.68],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.88, 191.691],
                                  [60.737, -248.371],
                                  [214.913, 8.193],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.967, 184.704],
                                  [40.863, -247.245],
                                  [182.586, 2.912],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.047, 177.185],
                                  [19.445, -245.785],
                                  [147.513, -3.209],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.003, 169.426],
                                  [-2.645, -243.946],
                                  [111.256, -10.029],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.785, 161.803],
                                  [-24.298, -241.822],
                                  [75.579, -17.288],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.158, 154.67],
                                  [-44.487, -239.392],
                                  [42.036, -24.62],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.241, 148.181],
                                  [-62.688, -236.85],
                                  [11.592, -31.822],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.969, 142.473],
                                  [-78.831, -234.225],
                                  [-15.505, -38.699],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.438, 137.461],
                                  [-93.049, -231.69],
                                  [-39.431, -45.239],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.716, 133.087],
                                  [-105.463, -229.197],
                                  [-60.549, -51.408],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.815, 129.235],
                                  [-116.356, -226.776],
                                  [-79.202, -57.145],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.779, 125.824],
                                  [-126.001, -224.44],
                                  [-95.78, -62.549],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.606, 122.843],
                                  [-134.497, -222.228],
                                  [-110.55, -67.642],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.378, 120.187],
                                  [-142.103, -220.117],
                                  [-123.763, -72.444],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.116, 117.837],
                                  [-148.919, -218.055],
                                  [-135.663, -76.925],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.778, 115.701],
                                  [-155.034, -216.123],
                                  [-146.434, -81.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.425, 113.796],
                                  [-160.533, -214.251],
                                  [-156.233, -85.19],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.006, 112.04],
                                  [-165.56, -212.476],
                                  [-165.124, -88.981],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.618, 110.498],
                                  [-170.087, -210.813],
                                  [-173.254, -92.588],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.206, 109.052],
                                  [-174.249, -209.154],
                                  [-180.72, -95.981],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.757, 107.752],
                                  [-178.015, -207.597],
                                  [-187.576, -99.193],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.34, 106.609],
                                  [-181.516, -206.138],
                                  [-193.896, -102.28],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.878, 105.51],
                                  [-184.695, -204.732],
                                  [-199.709, -105.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.458, 104.488],
                                  [-187.661, -203.334],
                                  [-205.124, -107.954],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.002, 103.58],
                                  [-190.355, -202.064],
                                  [-210.126, -110.635],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.572, 102.737],
                                  [-192.884, -200.796],
                                  [-214.76, -113.134],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.155, 101.956],
                                  [-195.189, -199.609],
                                  [-219.116, -115.527],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.769, 101.251],
                                  [-197.334, -198.483],
                                  [-223.129, -117.771],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.339, 100.578],
                                  [-199.34, -197.388],
                                  [-226.871, -119.95],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.922, 99.995],
                                  [-201.191, -196.349],
                                  [-230.378, -121.999],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.566, 99.421],
                                  [-202.905, -195.359],
                                  [-233.613, -123.978],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.188, 98.908],
                                  [-204.484, -194.439],
                                  [-236.663, -125.863],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.812, 98.398],
                                  [-205.967, -193.548],
                                  [-239.523, -127.66],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.495, 97.944],
                                  [-207.379, -192.66],
                                  [-242.155, -129.32],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.119, 97.512],
                                  [-208.659, -191.815],
                                  [-244.684, -130.929],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.788, 97.116],
                                  [-209.868, -191.056],
                                  [-246.987, -132.462],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.479, 96.769],
                                  [-210.985, -190.313],
                                  [-249.166, -133.919],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.154, 96.439],
                                  [-212.025, -189.615],
                                  [-251.221, -135.283],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.876, 96.103],
                                  [-213.02, -188.93],
                                  [-253.152, -136.593],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.598, 95.834],
                                  [-213.937, -188.303],
                                  [-254.898, -137.848],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.369, 95.555],
                                  [-214.787, -187.695],
                                  [-256.598, -139.009],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.102, 95.266],
                                  [-215.559, -187.095],
                                  [-258.136, -140.115],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.821, 95.065],
                                  [-216.263, -186.575],
                                  [-259.591, -141.165],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.629, 94.852],
                                  [-216.977, -186.062],
                                  [-260.936, -142.158],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.4, 94.624],
                                  [-217.612, -185.564],
                                  [-262.222, -143.082],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.198, 94.445],
                                  [-218.193, -185.095],
                                  [-263.398, -143.948],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.959, 94.251],
                                  [-218.745, -184.677],
                                  [-264.489, -144.783],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.81, 94.117],
                                  [-219.242, -184.287],
                                  [-265.495, -145.536],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.625, 93.954],
                                  [-219.723, -183.923],
                                  [-266.454, -146.281],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.467, 93.812],
                                  [-220.15, -183.546],
                                  [-267.326, -146.931],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.336, 93.677],
                                  [-220.521, -183.218],
                                  [-268.151, -147.548],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.17, 93.562],
                                  [-220.899, -182.915],
                                  [-268.865, -148.108],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.069, 93.491],
                                  [-221.235, -182.634],
                                  [-269.529, -148.636],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.933, 93.364],
                                  [-221.54, -182.376],
                                  [-270.169, -149.131],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.824, 93.281],
                                  [-221.826, -182.165],
                                  [-270.697, -149.593],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.706, 93.216],
                                  [-222.031, -181.938],
                                  [-271.212, -149.986],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.627, 93.119],
                                  [-222.279, -181.731],
                                  [-271.676, -150.346],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.514, 93.078],
                                  [-222.495, -181.544],
                                  [-272.052, -150.649],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.491, 93.003],
                                  [-222.629, -181.377],
                                  [-272.388, -150.945],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.409, 92.971],
                                  [-222.78, -181.254],
                                  [-272.698, -151.184],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.378, 92.919],
                                  [-222.935, -181.15],
                                  [-272.992, -151.416],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.288, 92.871],
                                  [-223.006, -181.063],
                                  [-273.171, -151.616],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.264, 92.839],
                                  [-223.081, -180.957],
                                  [-273.333, -151.722],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.229, 92.837],
                                  [-223.147, -180.93],
                                  [-273.467, -151.86],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.416, 0.416, 0.416, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.416, 0.416, 0.416, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-208.99, -72.772],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.935, -72.713],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.862, -72.646],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.77, -72.541],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.62, -72.392],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.416, -72.187],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.214, -71.957],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.93, -71.642],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.574, -71.297],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.195, -70.87],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.738, -70.338],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.182, -69.774],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.524, -69.08],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.777, -68.275],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.918, -67.333],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.909, -66.253],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.724, -65.004],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.372, -63.516],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.758, -61.83],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.873, -59.842],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.621, -57.529],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.023, -54.808],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.899, -51.659],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.229, -47.992],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-180.995, -43.849],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.167, -39.281],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.994, -34.426],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.671, -29.501],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.508, -24.706],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.676, -20.217],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.268, -16.051],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.367, -12.193],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.908, -8.691],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.865, -5.485],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.246, -2.508],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.982, 0.234],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.068, 2.806],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.446, 5.155],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.134, 7.365],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.05, 9.451],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.261, 11.399],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.717, 13.25],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.396, 14.984],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.288, 16.633],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.434, 18.21],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.774, 19.702],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.332, 21.123],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.05, 22.477],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.977, 23.755],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.044, 24.988],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.293, 26.164],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.592, 27.269],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.976, 28.328],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.408, 29.32],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.815, 30.279],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.235, 31.179],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-141.523, 31.994],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.798, 32.767],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.976, 33.494],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.085, 34.186],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.087, 34.807],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.016, 35.424],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.826, 35.975],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.641, 36.494],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-149.363, 36.974],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.029, 37.437],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.647, 37.866],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.2, 38.247],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.744, 38.602],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-152.222, 38.952],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-152.735, 39.274],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.146, 39.566],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.58, 39.83],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.001, 40.08],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.385, 40.3],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.751, 40.51],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.096, 40.7],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.504, 40.89],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.839, 41.025],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.222, 41.159],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.53, 41.266],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.854, 41.367],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.203, 41.466],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.539, 41.537],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.897, 41.605],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.248, 41.628],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.605, 41.686],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.929, 41.685],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.278, 41.698],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.637, 41.684],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.98, 41.663],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.31, 41.687],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.631, 41.748],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.891, 41.767],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.17, 41.853],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.407, 41.967],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.61, 42.105],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.859, 42.284],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.015, 42.456],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.106, 42.694],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.245, 43.005],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.306, 43.334],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.3, 43.715],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.297, 44.187],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.214, 44.738],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.013, 45.365],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.745, 46.067],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.371, 46.929],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.819, 47.907],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.078, 49.007],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.963, 50.197],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.294, 51.373],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.441, 52.025],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.756, 50.534],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.993, 44.539],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.166, 34.79],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.472, 23.214],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.354, 10.905],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.852, -1.164],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.439, -12.622],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-43.432, -23.193],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.751, -32.906],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.373, -41.706],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.078, -49.731],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.25, -57.05],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.738, -63.775],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.489, -69.917],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.596, -75.581],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.109, -80.799],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.152, -85.651],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.745, -90.187],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.963, -94.412],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.788, -98.325],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.312, -101.992],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.531, -105.4],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.493, -108.688],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.209, -111.706],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.694, -114.543],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.992, -117.232],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.07, -119.803],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.986, -122.175],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.722, -124.417],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.309, -126.565],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.733, -128.553],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.031, -130.454],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.22, -132.251],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.288, -133.94],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.217, -135.553],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.062, -137.128],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.777, -138.499],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.404, -139.849],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.962, -141.155],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.39, -142.337],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.768, -143.464],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.039, -144.563],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.271, -145.544],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.392, -146.491],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.469, -147.392],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.465, -148.209],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.423, -149.04],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.272, -149.699],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.095, -150.435],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.843, -151.026],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.546, -151.635],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.177, -152.165],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.782, -152.656],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.33, -153.087],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.827, -153.484],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.267, -153.849],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.667, -154.227],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.012, -154.494],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.335, -154.727],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.593, -154.966],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.837, -155.174],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.022, -155.327],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.213, -155.466],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.324, -155.557],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.383, -155.602],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.373, -155.688],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.133, -155.72],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.715, -155.756],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.101, -155.758],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.248, -155.81],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.213, -155.894],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.907, -155.969],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.336, -156.087],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.451, -156.211],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.248, -156.319],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.706, -156.471],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.759, -156.643],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.37, -156.824],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.451, -157.054],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.992, -157.245],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.905, -157.481],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.03, -157.748],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.35, -158.001],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.697, -158.273],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.89, -158.508],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.805, -158.684],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.179, -158.799],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.884, -158.784],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.776, -158.529],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.069, -157.939],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.226, -156.867],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-43.935, -155.216],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.256, -152.999],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.256, -150.235],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.519, -147.099],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.937, -143.715],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.647, -140.261],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.773, -136.736],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.542, -133.258],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.197, -129.842],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-152.833, -126.518],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.682, -123.305],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.837, -120.182],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.371, -117.213],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.377, -114.35],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.952, -111.646],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.122, -109.063],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.972, -106.575],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.486, -104.23],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.785, -101.999],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.812, -99.898],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-190.674, -97.887],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.314, -96.008],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-193.828, -94.208],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.177, -92.54],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.414, -90.982],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.529, -89.495],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.536, -88.106],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.466, -86.806],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.293, -85.614],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.054, -84.509],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.785, -83.443],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.408, -82.47],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203, -81.587],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.541, -80.748],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.027, -79.999],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.502, -79.293],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.928, -78.62],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.334, -78.039],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.677, -77.489],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.976, -77.001],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.313, -76.525],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.592, -76.118],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.849, -75.727],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.075, -75.392],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.305, -75.079],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.511, -74.804],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.697, -74.537],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.864, -74.328],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.018, -74.106],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.174, -73.895],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.3, -73.746],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.423, -73.589],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.499, -73.463],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.614, -73.341],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.695, -73.201],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.765, -73.118],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.822, -73.043],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.902, -72.988],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.908, -72.94],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.943, -72.885],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.989, -72.82],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-208.99, -72.772],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.935, -72.713],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.862, -72.646],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.77, -72.541],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.62, -72.392],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.416, -72.187],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.214, -71.957],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.93, -71.642],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.574, -71.297],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.195, -70.87],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.738, -70.338],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.182, -69.774],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.524, -69.08],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.777, -68.275],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.918, -67.333],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.909, -66.253],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.724, -65.004],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.372, -63.516],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.758, -61.83],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.873, -59.842],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.621, -57.529],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.023, -54.808],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.899, -51.659],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.229, -47.992],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-180.995, -43.849],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.167, -39.281],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.994, -34.426],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.671, -29.501],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.508, -24.706],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.676, -20.217],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.268, -16.051],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.367, -12.193],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.908, -8.691],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.865, -5.485],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.246, -2.508],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.982, 0.234],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.068, 2.806],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.446, 5.155],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.134, 7.365],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.05, 9.451],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.261, 11.399],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.717, 13.25],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.396, 14.984],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.288, 16.633],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.434, 18.21],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.774, 19.702],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.332, 21.123],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.05, 22.477],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.977, 23.755],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.044, 24.988],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.293, 26.164],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.592, 27.269],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.976, 28.328],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.408, 29.32],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.815, 30.279],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.235, 31.179],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-141.523, 31.994],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.798, 32.767],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.976, 33.494],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.085, 34.186],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.087, 34.807],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.016, 35.424],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.826, 35.975],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.641, 36.494],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-149.363, 36.974],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.029, 37.437],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.647, 37.866],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.2, 38.247],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.744, 38.602],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-152.222, 38.952],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-152.735, 39.274],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.146, 39.566],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.58, 39.83],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.001, 40.08],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.385, 40.3],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.751, 40.51],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.096, 40.7],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.504, 40.89],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.839, 41.025],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.222, 41.159],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.53, 41.266],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.854, 41.367],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.203, 41.466],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.539, 41.537],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.897, 41.605],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.248, 41.628],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.605, 41.686],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.929, 41.685],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.278, 41.698],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.637, 41.684],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.98, 41.663],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.31, 41.687],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.631, 41.748],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.891, 41.767],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.17, 41.853],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.407, 41.967],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.61, 42.105],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.859, 42.284],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.015, 42.456],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.106, 42.694],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.245, 43.005],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.306, 43.334],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.3, 43.715],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.297, 44.187],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.214, 44.738],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.013, 45.365],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.745, 46.067],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.371, 46.929],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.819, 47.907],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.078, 49.007],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.963, 50.197],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.294, 51.373],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.441, 52.025],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.756, 50.534],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.993, 44.539],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.166, 34.79],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.472, 23.214],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.354, 10.905],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.852, -1.164],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.439, -12.622],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-43.432, -23.193],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.751, -32.906],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.373, -41.706],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.078, -49.731],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.25, -57.05],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.738, -63.775],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.489, -69.917],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.596, -75.581],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.109, -80.799],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.152, -85.651],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.745, -90.187],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.963, -94.412],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.788, -98.325],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.312, -101.992],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.531, -105.4],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.493, -108.688],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.209, -111.706],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.694, -114.543],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.992, -117.232],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.07, -119.803],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.986, -122.175],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.722, -124.417],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.309, -126.565],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.733, -128.553],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.031, -130.454],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.22, -132.251],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.288, -133.94],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.217, -135.553],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.062, -137.128],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.777, -138.499],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.404, -139.849],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.962, -141.155],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.39, -142.337],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.768, -143.464],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.039, -144.563],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.271, -145.544],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.392, -146.491],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.469, -147.392],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.465, -148.209],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.423, -149.04],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.272, -149.699],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.095, -150.435],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.843, -151.026],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.546, -151.635],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.177, -152.165],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.782, -152.656],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.33, -153.087],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.827, -153.484],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.267, -153.849],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.667, -154.227],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.012, -154.494],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.335, -154.727],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.593, -154.966],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.837, -155.174],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.022, -155.327],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.213, -155.466],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.324, -155.557],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.383, -155.602],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.373, -155.688],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.133, -155.72],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.715, -155.756],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.101, -155.758],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.248, -155.81],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.213, -155.894],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.907, -155.969],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.336, -156.087],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.451, -156.211],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.248, -156.319],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.706, -156.471],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.759, -156.643],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.37, -156.824],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.451, -157.054],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.992, -157.245],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.905, -157.481],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.03, -157.748],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.35, -158.001],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.697, -158.273],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.89, -158.508],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.805, -158.684],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.179, -158.799],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.884, -158.784],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.776, -158.529],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.069, -157.939],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.226, -156.867],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-43.935, -155.216],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.256, -152.999],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.256, -150.235],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.519, -147.099],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.937, -143.715],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.647, -140.261],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.773, -136.736],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.542, -133.258],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.197, -129.842],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-152.833, -126.518],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.682, -123.305],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.837, -120.182],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.371, -117.213],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.377, -114.35],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.952, -111.646],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.122, -109.063],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.972, -106.575],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.486, -104.23],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.785, -101.999],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.812, -99.898],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-190.674, -97.887],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.314, -96.008],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-193.828, -94.208],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.177, -92.54],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.414, -90.982],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.529, -89.495],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.536, -88.106],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.466, -86.806],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.293, -85.614],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.054, -84.509],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.785, -83.443],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-202.408, -82.47],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203, -81.587],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.541, -80.748],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.027, -79.999],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.502, -79.293],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-204.928, -78.62],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.334, -78.039],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.677, -77.489],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.976, -77.001],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.313, -76.525],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.592, -76.118],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-206.849, -75.727],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.075, -75.392],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.305, -75.079],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.511, -74.804],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.697, -74.537],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.864, -74.328],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.018, -74.106],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.174, -73.895],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.3, -73.746],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.423, -73.589],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.499, -73.463],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.614, -73.341],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.695, -73.201],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.765, -73.118],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.822, -73.043],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.902, -72.988],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.908, -72.94],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.943, -72.885],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.989, -72.82],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 7',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 7,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.436, 85.604],
                                  [-194.382, -149.583],
                                  [-235.187, -163.964],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.443, 85.701],
                                  [-194.277, -149.535],
                                  [-235.173, -163.931],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.491, 85.801],
                                  [-194.12, -149.497],
                                  [-235.077, -163.867],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.545, 85.934],
                                  [-193.916, -149.376],
                                  [-235.026, -163.738],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.669, 86.134],
                                  [-193.568, -149.233],
                                  [-234.862, -163.578],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.771, 86.399],
                                  [-193.14, -149.036],
                                  [-234.677, -163.358],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.942, 86.698],
                                  [-192.666, -148.819],
                                  [-234.475, -163.106],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.126, 87.093],
                                  [-192.046, -148.515],
                                  [-234.22, -162.824],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.318, 87.555],
                                  [-191.29, -148.162],
                                  [-233.912, -162.447],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.585, 88.079],
                                  [-190.452, -147.753],
                                  [-233.522, -162.015],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.899, 88.699],
                                  [-189.444, -147.253],
                                  [-233.046, -161.557],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.256, 89.42],
                                  [-188.236, -146.701],
                                  [-232.513, -161.009],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.633, 90.232],
                                  [-186.851, -145.996],
                                  [-231.861, -160.345],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.122, 91.174],
                                  [-185.236, -145.197],
                                  [-231.118, -159.66],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.638, 92.241],
                                  [-183.418, -144.272],
                                  [-230.218, -158.836],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.268, 93.463],
                                  [-181.275, -143.19],
                                  [-229.153, -157.902],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.966, 94.876],
                                  [-178.8, -141.914],
                                  [-227.924, -156.83],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.793, 96.506],
                                  [-175.968, -140.43],
                                  [-226.484, -155.667],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.721, 98.323],
                                  [-172.65, -138.677],
                                  [-224.77, -154.289],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.827, 100.446],
                                  [-168.783, -136.58],
                                  [-222.767, -152.734],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.091, 102.91],
                                  [-164.239, -134.159],
                                  [-220.331, -150.987],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.587, 105.741],
                                  [-159.017, -131.274],
                                  [-217.483, -148.968],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.32, 109.002],
                                  [-152.86, -127.879],
                                  [-214.071, -146.66],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.315, 112.749],
                                  [-145.74, -123.86],
                                  [-210.078, -144.048],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.608, 116.98],
                                  [-137.656, -119.274],
                                  [-205.488, -141.114],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.142, 121.636],
                                  [-128.663, -114.152],
                                  [-200.31, -137.948],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.895, 126.563],
                                  [-119.189, -108.702],
                                  [-194.814, -134.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.747, 131.523],
                                  [-109.636, -103.16],
                                  [-189.211, -131.388],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.518, 136.293],
                                  [-100.53, -97.804],
                                  [-183.799, -128.301],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.247, 140.733],
                                  [-92.09, -92.828],
                                  [-178.776, -125.464],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.83, 144.804],
                                  [-84.45, -88.276],
                                  [-174.161, -122.98],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.303, 148.519],
                                  [-77.649, -84.159],
                                  [-169.986, -120.729],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.671, 151.873],
                                  [-71.592, -80.469],
                                  [-166.251, -118.806],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.936, 154.922],
                                  [-66.215, -77.147],
                                  [-162.892, -117.118],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.133, 157.702],
                                  [-61.494, -74.113],
                                  [-159.871, -115.652],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-226.314, 160.277],
                                  [-57.273, -71.406],
                                  [-157.116, -114.406],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-228.419, 162.647],
                                  [-53.585, -68.917],
                                  [-154.597, -113.36],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-230.54, 164.805],
                                  [-50.308, -66.683],
                                  [-152.327, -112.488],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-232.67, 166.824],
                                  [-47.448, -64.637],
                                  [-150.256, -111.762],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.806, 168.722],
                                  [-44.912, -62.738],
                                  [-148.335, -111.194],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.996, 170.482],
                                  [-42.742, -61.022],
                                  [-146.576, -110.784],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-239.236, 172.159],
                                  [-40.897, -59.433],
                                  [-144.999, -110.445],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.514, 173.717],
                                  [-39.341, -57.975],
                                  [-143.508, -110.341],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.862, 175.192],
                                  [-38.057, -56.612],
                                  [-142.166, -110.285],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-246.284, 176.623],
                                  [-37.061, -55.395],
                                  [-140.902, -110.378],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-248.794, 177.974],
                                  [-36.32, -54.252],
                                  [-139.755, -110.623],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.42, 179.231],
                                  [-35.835, -53.181],
                                  [-138.688, -110.97],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.111, 180.484],
                                  [-35.542, -52.223],
                                  [-137.702, -111.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-256.909, 181.642],
                                  [-35.499, -51.337],
                                  [-136.781, -112.039],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-259.82, 182.78],
                                  [-35.634, -50.482],
                                  [-135.939, -112.753],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-262.787, 183.857],
                                  [-35.972, -49.713],
                                  [-135.141, -113.512],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.786, 184.888],
                                  [-36.437, -48.976],
                                  [-134.375, -114.423],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-268.826, 185.876],
                                  [-36.997, -48.278],
                                  [-133.641, -115.329],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-271.855, 186.812],
                                  [-37.671, -47.621],
                                  [-132.937, -116.336],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-274.84, 187.74],
                                  [-38.388, -46.939],
                                  [-132.28, -117.364],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.791, 188.592],
                                  [-39.126, -46.314],
                                  [-131.586, -118.374],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-280.598, 189.366],
                                  [-39.822, -45.682],
                                  [-130.94, -119.391],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-283.325, 190.124],
                                  [-40.545, -45.087],
                                  [-130.258, -120.446],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-285.96, 190.826],
                                  [-41.186, -44.493],
                                  [-129.65, -121.383],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-288.411, 191.532],
                                  [-41.845, -43.918],
                                  [-128.995, -122.364],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.78, 192.139],
                                  [-42.416, -43.352],
                                  [-128.38, -123.282],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-292.981, 192.732],
                                  [-42.969, -42.768],
                                  [-127.748, -124.133],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.085, 193.292],
                                  [-43.425, -42.221],
                                  [-127.165, -124.979],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-297.053, 193.795],
                                  [-43.913, -41.714],
                                  [-126.573, -125.741],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-298.953, 194.268],
                                  [-44.342, -41.199],
                                  [-125.977, -126.546],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.727, 194.73],
                                  [-44.727, -40.715],
                                  [-125.443, -127.224],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.398, 195.164],
                                  [-45.129, -40.227],
                                  [-124.912, -127.948],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-303.954, 195.544],
                                  [-45.463, -39.793],
                                  [-124.405, -128.594],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-305.461, 195.899],
                                  [-45.797, -39.388],
                                  [-123.906, -129.227],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-306.846, 196.246],
                                  [-46.121, -38.951],
                                  [-123.482, -129.803],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-308.208, 196.586],
                                  [-46.454, -38.592],
                                  [-123.042, -130.369],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-309.456, 196.904],
                                  [-46.739, -38.221],
                                  [-122.664, -130.927],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-310.672, 197.169],
                                  [-47.042, -37.886],
                                  [-122.258, -131.461],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-311.796, 197.413],
                                  [-47.351, -37.572],
                                  [-121.887, -131.943],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-312.895, 197.669],
                                  [-47.621, -37.295],
                                  [-121.599, -132.421],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-313.893, 197.887],
                                  [-47.918, -37.01],
                                  [-121.286, -132.88],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.87, 198.118],
                                  [-48.183, -36.765],
                                  [-121.013, -133.336],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-315.781, 198.329],
                                  [-48.526, -36.545],
                                  [-120.732, -133.744],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-316.645, 198.49],
                                  [-48.794, -36.367],
                                  [-120.524, -134.136],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-317.494, 198.632],
                                  [-49.129, -36.183],
                                  [-120.31, -134.528],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-318.267, 198.786],
                                  [-49.393, -36.043],
                                  [-120.123, -134.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-319.014, 198.922],
                                  [-49.696, -35.898],
                                  [-119.994, -135.284],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-319.704, 199.056],
                                  [-50.024, -35.781],
                                  [-119.845, -135.617],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-320.354, 199.172],
                                  [-50.332, -35.708],
                                  [-119.726, -135.938],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-320.997, 199.285],
                                  [-50.684, -35.633],
                                  [-119.666, -136.247],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-321.633, 199.35],
                                  [-51.017, -35.603],
                                  [-119.635, -136.56],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-322.187, 199.46],
                                  [-51.381, -35.555],
                                  [-119.603, -136.829],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-322.673, 199.52],
                                  [-51.728, -35.568],
                                  [-119.568, -137.135],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-323.203, 199.564],
                                  [-52.076, -35.564],
                                  [-119.564, -137.384],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-323.668, 199.606],
                                  [-52.456, -35.606],
                                  [-119.606, -137.637],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-324.083, 199.632],
                                  [-52.789, -35.695],
                                  [-119.536, -137.867],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-324.463, 199.677],
                                  [-53.013, -35.83],
                                  [-119.321, -137.978],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-324.773, 199.739],
                                  [-53.171, -36.013],
                                  [-118.868, -138.018],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325, 199.759],
                                  [-53.171, -36.339],
                                  [-118.268, -138.008],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.172, 199.798],
                                  [-53.09, -36.713],
                                  [-117.348, -137.879],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.211, 199.843],
                                  [-52.88, -37.183],
                                  [-116.204, -137.618],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.181, 199.895],
                                  [-52.527, -37.75],
                                  [-114.802, -137.274],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.11, 199.954],
                                  [-52.058, -38.462],
                                  [-113.063, -136.785],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-324.919, 199.958],
                                  [-51.41, -39.271],
                                  [-111.051, -136.214],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-324.575, 200.006],
                                  [-50.584, -40.178],
                                  [-108.619, -135.511],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-324.153, 200.098],
                                  [-49.592, -41.281],
                                  [-105.753, -134.603],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-323.591, 200.157],
                                  [-48.337, -42.578],
                                  [-102.453, -133.536],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-322.852, 200.2],
                                  [-46.867, -44.023],
                                  [-98.668, -132.277],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-321.999, 200.31],
                                  [-45.147, -45.714],
                                  [-94.287, -130.799],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-320.897, 200.425],
                                  [-43.126, -47.65],
                                  [-89.262, -129.053],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-319.609, 200.52],
                                  [-40.721, -49.832],
                                  [-83.478, -127.039],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-318.098, 200.595],
                                  [-37.943, -52.359],
                                  [-76.826, -124.731],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-316.305, 200.772],
                                  [-34.693, -55.281],
                                  [-69.156, -122.017],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.131, 200.925],
                                  [-30.9, -58.646],
                                  [-60.293, -118.835],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-311.629, 201.093],
                                  [-26.527, -62.504],
                                  [-50.077, -115.173],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-308.675, 201.308],
                                  [-21.353, -67.005],
                                  [-38.282, -110.94],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-305.175, 201.558],
                                  [-15.34, -72.197],
                                  [-24.551, -105.978],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-301.079, 201.841],
                                  [-8.317, -78.18],
                                  [-8.608, -100.21],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-296.301, 202.181],
                                  [-0.21, -85.103],
                                  [9.722, -93.547],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.801, 202.583],
                                  [8.986, -92.967],
                                  [30.539, -85.942],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.731, 203.024],
                                  [19.201, -101.627],
                                  [53.476, -77.581],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.301, 203.529],
                                  [30.06, -110.787],
                                  [77.728, -68.743],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-271.767, 203.99],
                                  [40.983, -120.056],
                                  [102.207, -59.829],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.451, 204.501],
                                  [51.518, -128.939],
                                  [125.734, -51.266],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-259.495, 205.005],
                                  [61.385, -137.238],
                                  [147.75, -43.256],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.099, 205.49],
                                  [70.445, -144.854],
                                  [167.844, -35.972],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.118, 205.917],
                                  [78.645, -151.785],
                                  [186.064, -29.364],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-244.646, 206.37],
                                  [86.031, -158.029],
                                  [202.555, -23.433],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-240.599, 206.743],
                                  [92.772, -163.685],
                                  [217.54, -18.053],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.872, 207.121],
                                  [98.902, -168.849],
                                  [231.078, -13.148],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-233.48, 207.476],
                                  [104.49, -173.572],
                                  [243.492, -8.705],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-230.366, 207.83],
                                  [109.648, -177.9],
                                  [254.865, -4.661],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.496, 208.169],
                                  [114.383, -181.883],
                                  [265.286, -0.954],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.835, 208.491],
                                  [118.709, -185.521],
                                  [274.939, 2.466],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.36, 208.806],
                                  [122.745, -188.912],
                                  [283.848, 5.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.072, 209.114],
                                  [126.504, -192.105],
                                  [292.099, 8.586],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.887, 209.352],
                                  [130.008, -195.05],
                                  [299.817, 11.286],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.918, 209.666],
                                  [133.271, -197.796],
                                  [306.988, 13.837],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.016, 209.897],
                                  [136.327, -200.344],
                                  [313.724, 16.19],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.245, 210.128],
                                  [139.213, -202.743],
                                  [319.988, 18.357],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.618, 210.374],
                                  [141.892, -205.042],
                                  [325.915, 20.451],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.024, 210.62],
                                  [144.413, -207.141],
                                  [331.456, 22.396],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.539, 210.853],
                                  [146.8, -209.141],
                                  [336.636, 24.218],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.137, 211.074],
                                  [149.077, -211.039],
                                  [341.578, 25.917],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.857, 211.295],
                                  [151.169, -212.838],
                                  [346.208, 27.543],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.624, 211.44],
                                  [153.187, -214.486],
                                  [350.563, 29.033],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.451, 211.671],
                                  [155.083, -216.084],
                                  [354.643, 30.463],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.303, 211.84],
                                  [156.843, -217.58],
                                  [358.559, 31.769],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.265, 212.031],
                                  [158.516, -218.976],
                                  [362.213, 33.052],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.288, 212.196],
                                  [160.116, -220.321],
                                  [365.691, 34.225],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.348, 212.334],
                                  [161.628, -221.565],
                                  [368.955, 35.374],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.42, 212.496],
                                  [163.066, -222.758],
                                  [372.043, 36.413],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.616, 212.681],
                                  [164.404, -223.9],
                                  [374.98, 37.44],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.787, 212.789],
                                  [165.655, -224.99],
                                  [377.754, 38.407],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.046, 212.957],
                                  [166.881, -225.98],
                                  [380.351, 39.251],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.317, 213.048],
                                  [168.007, -226.918],
                                  [382.797, 40.084],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.638, 213.2],
                                  [169.095, -227.855],
                                  [385.129, 40.919],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.022, 213.324],
                                  [170.095, -228.691],
                                  [387.347, 41.63],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.395, 213.409],
                                  [171.057, -229.475],
                                  [389.402, 42.331],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.855, 213.566],
                                  [171.931, -230.258],
                                  [391.342, 43.033],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.291, 213.621],
                                  [172.805, -230.939],
                                  [393.18, 43.611],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.815, 213.749],
                                  [173.59, -231.62],
                                  [394.905, 44.192],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.328, 213.837],
                                  [174.337, -232.249],
                                  [396.515, 44.761],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.879, 213.935],
                                  [175.033, -232.826],
                                  [398.024, 45.27],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.455, 213.993],
                                  [175.691, -233.402],
                                  [399.469, 45.78],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.071, 214.061],
                                  [176.311, -233.877],
                                  [400.75, 46.166],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.737, 214.14],
                                  [176.88, -234.401],
                                  [402.028, 46.617],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.379, 214.229],
                                  [177.41, -234.823],
                                  [403.143, 46.994],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.06, 214.277],
                                  [177.889, -235.244],
                                  [404.206, 47.372],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.792, 214.336],
                                  [178.33, -235.613],
                                  [405.206, 47.689],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.5, 214.393],
                                  [178.77, -235.982],
                                  [406.103, 47.958],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.26, 214.46],
                                  [179.171, -236.298],
                                  [406.886, 48.266],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.045, 214.487],
                                  [179.521, -236.564],
                                  [407.668, 48.513],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.831, 214.524],
                                  [179.833, -236.828],
                                  [408.336, 48.711],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.657, 214.572],
                                  [180.094, -237.091],
                                  [408.952, 48.96],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.533, 214.617],
                                  [180.366, -237.303],
                                  [409.503, 49.098],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.336, 214.673],
                                  [180.587, -237.463],
                                  [409.954, 49.275],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.24, 214.688],
                                  [180.757, -237.622],
                                  [410.403, 49.404],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.132, 214.702],
                                  [180.939, -237.78],
                                  [410.737, 49.533],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.063, 214.726],
                                  [181.07, -237.886],
                                  [411.02, 49.651],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.946, 214.748],
                                  [181.212, -237.992],
                                  [411.289, 49.721],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.917, 214.73],
                                  [181.29, -238.046],
                                  [411.47, 49.779],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.889, 214.773],
                                  [181.343, -238.099],
                                  [411.573, 49.789],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.85, 214.763],
                                  [181.382, -238.15],
                                  [411.688, 49.85],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.845, 214.762],
                                  [181.275, -238.142],
                                  [411.528, 49.845],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.896, 214.628],
                                  [181.014, -238.153],
                                  [411.083, 49.759],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.942, 214.448],
                                  [180.525, -238.132],
                                  [410.327, 49.654],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.033, 214.232],
                                  [179.843, -238.09],
                                  [409.183, 49.533],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.184, 213.902],
                                  [178.88, -238.062],
                                  [407.649, 49.333],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.321, 213.454],
                                  [177.709, -238.046],
                                  [405.737, 49.044],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.508, 212.923],
                                  [176.23, -237.991],
                                  [403.345, 48.731],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.686, 212.28],
                                  [174.429, -237.941],
                                  [400.459, 48.332],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.969, 211.557],
                                  [172.304, -237.908],
                                  [397.039, 47.85],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.297, 210.7],
                                  [169.818, -237.81],
                                  [393.022, 47.323],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.61, 209.665],
                                  [166.92, -237.694],
                                  [388.356, 46.693],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.011, 208.496],
                                  [163.609, -237.615],
                                  [382.954, 45.899],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.466, 207.135],
                                  [159.772, -237.468],
                                  [376.751, 45.042],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.991, 205.586],
                                  [155.337, -237.315],
                                  [369.648, 44.015],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.602, 203.789],
                                  [150.34, -237.109],
                                  [361.449, 42.83],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.242, 201.744],
                                  [144.586, -236.838],
                                  [352.155, 41.502],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.001, 199.451],
                                  [137.979, -236.582],
                                  [341.461, 39.898],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.873, 196.794],
                                  [130.492, -236.229],
                                  [329.29, 38.029],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.786, 193.732],
                                  [121.888, -235.778],
                                  [315.349, 35.854],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.863, 190.256],
                                  [112.04, -235.229],
                                  [299.319, 33.31],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.998, 186.321],
                                  [100.793, -234.556],
                                  [280.928, 30.258],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.304, 181.773],
                                  [87.867, -233.698],
                                  [259.815, 26.63],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.681, 176.577],
                                  [73.123, -232.616],
                                  [235.655, 22.276],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.165, 170.715],
                                  [56.437, -231.215],
                                  [208.248, 17.1],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.682, 164.183],
                                  [37.902, -229.419],
                                  [177.738, 11.006],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.109, 157.198],
                                  [18.017, -227.205],
                                  [144.751, 3.95],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.346, 150.04],
                                  [-2.39, -224.551],
                                  [110.782, -3.903],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.36, 143.064],
                                  [-22.275, -221.593],
                                  [77.497, -12.243],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.963, 136.591],
                                  [-40.706, -218.347],
                                  [46.348, -20.665],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.294, 130.754],
                                  [-57.217, -215.03],
                                  [18.209, -28.917],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.315, 125.669],
                                  [-71.767, -211.692],
                                  [-6.724, -36.792],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.125, 121.248],
                                  [-84.507, -208.505],
                                  [-28.637, -44.253],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.786, 117.426],
                                  [-95.556, -205.417],
                                  [-47.897, -51.281],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.311, 114.091],
                                  [-105.194, -202.461],
                                  [-64.843, -57.809],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.745, 111.165],
                                  [-113.678, -199.637],
                                  [-79.839, -63.949],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.086, 108.638],
                                  [-121.105, -196.977],
                                  [-93.148, -69.719],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.4, 106.402],
                                  [-127.72, -194.453],
                                  [-105.015, -75.15],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.712, 104.448],
                                  [-133.612, -192.015],
                                  [-115.661, -80.218],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.98, 102.683],
                                  [-138.872, -189.735],
                                  [-125.267, -85.025],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.253, 101.128],
                                  [-143.576, -187.542],
                                  [-133.978, -89.538],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.491, 99.7],
                                  [-147.855, -185.467],
                                  [-141.856, -93.802],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.772, 98.467],
                                  [-151.686, -183.529],
                                  [-149.037, -97.853],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.055, 97.313],
                                  [-155.194, -181.61],
                                  [-155.612, -101.668],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.322, 96.287],
                                  [-158.35, -179.817],
                                  [-161.631, -105.275],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.632, 95.403],
                                  [-161.275, -178.132],
                                  [-167.167, -108.73],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.915, 94.551],
                                  [-163.913, -176.514],
                                  [-172.242, -112.013],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.251, 93.761],
                                  [-166.367, -174.922],
                                  [-176.961, -115.084],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.569, 93.076],
                                  [-168.579, -173.463],
                                  [-181.307, -118.074],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.922, 92.438],
                                  [-170.653, -172.022],
                                  [-185.319, -120.869],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.303, 91.857],
                                  [-172.528, -170.671],
                                  [-189.091, -123.538],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.723, 91.339],
                                  [-174.27, -169.396],
                                  [-192.548, -126.043],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.113, 90.842],
                                  [-175.89, -168.152],
                                  [-195.771, -128.47],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.521, 90.426],
                                  [-177.381, -166.98],
                                  [-198.784, -130.753],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.003, 90.015],
                                  [-178.754, -165.86],
                                  [-201.55, -132.955],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.469, 89.651],
                                  [-180.009, -164.817],
                                  [-204.162, -135.046],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.95, 89.288],
                                  [-181.183, -163.813],
                                  [-206.603, -137.044],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.489, 88.965],
                                  [-182.303, -162.82],
                                  [-208.838, -138.894],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.982, 88.665],
                                  [-183.309, -161.871],
                                  [-210.992, -140.683],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.529, 88.394],
                                  [-184.262, -161.02],
                                  [-212.943, -142.384],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.101, 88.157],
                                  [-185.137, -160.181],
                                  [-214.788, -143.995],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.665, 87.938],
                                  [-185.94, -159.403],
                                  [-216.527, -145.51],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.285, 87.708],
                                  [-186.72, -158.635],
                                  [-218.161, -146.962],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.905, 87.535],
                                  [-187.428, -157.931],
                                  [-219.628, -148.35],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.581, 87.349],
                                  [-188.086, -157.256],
                                  [-221.058, -149.64],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.225, 87.148],
                                  [-188.672, -156.587],
                                  [-222.35, -150.866],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.863, 87.028],
                                  [-189.207, -156.007],
                                  [-223.571, -152.027],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.593, 86.892],
                                  [-189.761, -155.431],
                                  [-224.693, -153.123],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.292, 86.738],
                                  [-190.242, -154.88],
                                  [-225.773, -154.149],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.02, 86.629],
                                  [-190.678, -154.36],
                                  [-226.753, -155.109],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.717, 86.501],
                                  [-191.099, -153.896],
                                  [-227.662, -156.033],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.508, 86.421],
                                  [-191.474, -153.461],
                                  [-228.499, -156.862],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.266, 86.316],
                                  [-191.838, -153.053],
                                  [-229.299, -157.683],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.057, 86.225],
                                  [-192.156, -152.637],
                                  [-230.026, -158.405],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.878, 86.14],
                                  [-192.428, -152.276],
                                  [-230.715, -159.089],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.669, 86.068],
                                  [-192.717, -151.94],
                                  [-231.302, -159.708],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.525, 86.037],
                                  [-192.964, -151.628],
                                  [-231.85, -160.289],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.35, 85.949],
                                  [-193.193, -151.339],
                                  [-232.386, -160.833],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.207, 85.901],
                                  [-193.409, -151.103],
                                  [-232.82, -161.341],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.062, 85.863],
                                  [-193.548, -150.854],
                                  [-233.246, -161.778],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.953, 85.798],
                                  [-193.736, -150.628],
                                  [-233.631, -162.179],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.814, 85.776],
                                  [-193.904, -150.422],
                                  [-233.94, -162.513],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.77, 85.725],
                                  [-193.995, -150.237],
                                  [-234.211, -162.841],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.665, 85.713],
                                  [-194.103, -150.1],
                                  [-234.468, -163.102],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.622, 85.676],
                                  [-194.225, -149.983],
                                  [-234.716, -163.357],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.519, 85.644],
                                  [-194.269, -149.885],
                                  [-234.857, -163.575],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.481, 85.62],
                                  [-194.324, -149.771],
                                  [-234.987, -163.695],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.442, 85.629],
                                  [-194.367, -149.738],
                                  [-235.101, -163.841],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.45, 0.45, 0.45, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.45, 0.45, 0.45, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-189.45, -53.569],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.377, -53.526],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.282, -53.471],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.13, -53.386],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.922, -53.267],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.716, -53.135],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.429, -52.964],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.064, -52.768],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.678, -52.513],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.211, -52.215],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.638, -51.904],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.964, -51.503],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.191, -51.067],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.298, -50.524],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.243, -49.904],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.998, -49.175],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-180.565, -48.312],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.845, -47.32],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.813, -46.133],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.369, -44.722],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.519, -43.007],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.07, -40.976],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.979, -38.529],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.23, -35.651],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.811, -32.342],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.996, -28.691],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.048, -24.831],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.31, -20.953],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.98, -17.234],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126.159, -13.714],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-121.919, -10.4],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.187, -7.359],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.929, -4.549],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.145, -1.918],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.754, 0.522],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.75, 2.825],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.07, 4.937],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.725, 6.938],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.635, 8.839],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.86, 10.623],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.358, 12.333],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.097, 13.944],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.073, 15.492],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.326, 16.981],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.796, 18.404],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.51, 19.777],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.405, 21.094],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.531, 22.354],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.819, 23.585],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.302, 24.771],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.869, 25.902],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.529, 27.001],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.252, 28.04],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.959, 29.057],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.682, 30.021],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.275, 30.905],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.859, 31.754],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-121.324, 32.55],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.726, 33.317],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.007, 34.011],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.208, 34.697],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126.273, 35.312],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.334, 35.896],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.293, 36.439],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.183, 36.96],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.02, 37.446],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.777, 37.88],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.519, 38.286],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.18, 38.683],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.871, 39.051],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.451, 39.384],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.051, 39.692],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.625, 39.98],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.152, 40.235],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.661, 40.481],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.14, 40.703],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.676, 40.925],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.131, 41.088],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.628, 41.252],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.047, 41.385],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.476, 41.51],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.925, 41.633],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-139.353, 41.727],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-139.801, 41.815],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.236, 41.859],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.671, 41.934],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-141.073, 41.953],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-141.495, 41.983],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-141.923, 41.985],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.339, 41.983],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.742, 42.018],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.155, 42.094],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.51, 42.125],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.901, 42.225],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.261, 42.351],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.598, 42.499],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145, 42.691],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.329, 42.876],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.613, 43.125],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.968, 43.448],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.272, 43.786],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.539, 44.178],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.846, 44.661],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.116, 45.223],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.324, 45.863],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.529, 46.587],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.712, 47.481],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.823, 48.51],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.895, 49.709],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.806, 51.082],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.529, 52.658],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.737, 54.216],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.577, 54],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.835, 46.849],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.979, 35.877],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.862, 23.683],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.57, 11.068],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.955, -1.153],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.4, -12.678],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.184, -23.272],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.218, -32.978],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.484, -41.754],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.764, -49.75],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.957, -57.036],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.054, -63.716],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.388, -69.832],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.09, -75.445],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.264, -80.643],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.964, -85.443],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.266, -89.955],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.204, -94.147],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.763, -98.019],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.057, -101.667],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.054, -105.053],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.795, -108.295],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.324, -111.294],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.632, -114.118],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.763, -116.789],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.669, -119.321],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.436, -121.674],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.036, -123.904],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.493, -126.036],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.796, -128.012],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.975, -129.895],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.042, -131.663],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.009, -133.341],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.843, -134.943],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.599, -136.505],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.228, -137.868],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.776, -139.209],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.256, -140.505],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.614, -141.678],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.921, -142.792],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.124, -143.872],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.295, -144.848],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.362, -145.788],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.388, -146.681],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.334, -147.494],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.247, -148.316],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.054, -148.972],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.838, -149.701],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.549, -150.288],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.219, -150.891],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.819, -151.416],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.394, -151.905],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.915, -152.335],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.389, -152.728],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.807, -153.09],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.188, -153.463],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.515, -153.729],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.823, -153.962],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.068, -154.198],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.301, -154.403],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.477, -154.556],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.66, -154.694],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.764, -154.785],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.82, -154.829],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.81, -154.913],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.579, -154.935],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.174, -154.955],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.584, -154.939],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.759, -154.964],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.76, -155.01],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.501, -155.041],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.986, -155.103],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.167, -155.167],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.043, -155.202],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.593, -155.262],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.752, -155.329],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.486, -155.392],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.714, -155.478],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.421, -155.504],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.527, -155.549],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.877, -155.596],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.455, -155.583],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.105, -155.543],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.655, -155.412],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.986, -155.148],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.857, -154.733],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.146, -154.077],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.727, -153.047],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.298, -151.552],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.539, -149.398],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.251, -146.519],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.511, -143.016],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.472, -138.9],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.729, -134.44],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.197, -129.77],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.013, -125.105],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.315, -120.415],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.315, -115.844],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-139.248, -111.399],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.215, -107.098],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.43, -102.964],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.987, -98.962],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.956, -95.174],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.428, -91.524],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.489, -88.081],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.17, -84.808],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.553, -81.657],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.622, -78.709],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.489, -75.905],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.101, -73.279],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-177.561, -70.791],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.816, -68.478],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.955, -66.298],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-180.944, -64.31],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.835, -62.497],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.614, -60.805],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.297, -59.284],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.921, -57.916],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.452, -56.736],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.927, -55.711],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.386, -54.786],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.751, -54.03],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.103, -53.412],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.418, -52.901],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.686, -52.529],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.964, -52.232],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.202, -51.995],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.438, -51.874],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.62, -51.8],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.773, -51.786],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.978, -51.773],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.131, -51.838],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.273, -51.903],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.394, -52.006],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.53, -52.104],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.649, -52.233],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.755, -52.35],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.85, -52.5],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.941, -52.601],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.035, -52.7],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.107, -52.842],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.184, -52.943],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.216, -53.064],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.291, -53.162],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.34, -53.213],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.384, -53.307],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.412, -53.375],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.472, -53.451],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.461, -53.514],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.483, -53.541],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.495, -53.582],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-189.45, -53.569],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.377, -53.526],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.282, -53.471],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.13, -53.386],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.922, -53.267],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.716, -53.135],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.429, -52.964],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.064, -52.768],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.678, -52.513],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.211, -52.215],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.638, -51.904],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.964, -51.503],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.191, -51.067],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.298, -50.524],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.243, -49.904],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.998, -49.175],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-180.565, -48.312],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.845, -47.32],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.813, -46.133],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.369, -44.722],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.519, -43.007],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.07, -40.976],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.979, -38.529],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.23, -35.651],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.811, -32.342],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.996, -28.691],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.048, -24.831],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.31, -20.953],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.98, -17.234],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126.159, -13.714],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-121.919, -10.4],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.187, -7.359],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.929, -4.549],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.145, -1.918],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.754, 0.522],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.75, 2.825],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.07, 4.937],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.725, 6.938],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.635, 8.839],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.86, 10.623],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.358, 12.333],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.097, 13.944],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.073, 15.492],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.326, 16.981],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.796, 18.404],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.51, 19.777],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.405, 21.094],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.531, 22.354],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.819, 23.585],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.302, 24.771],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.869, 25.902],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.529, 27.001],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.252, 28.04],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.959, 29.057],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.682, 30.021],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.275, 30.905],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.859, 31.754],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-121.324, 32.55],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.726, 33.317],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.007, 34.011],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.208, 34.697],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126.273, 35.312],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.334, 35.896],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.293, 36.439],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.183, 36.96],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.02, 37.446],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.777, 37.88],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.519, 38.286],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.18, 38.683],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.871, 39.051],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.451, 39.384],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.051, 39.692],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.625, 39.98],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.152, 40.235],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.661, 40.481],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.14, 40.703],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.676, 40.925],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.131, 41.088],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.628, 41.252],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.047, 41.385],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.476, 41.51],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.925, 41.633],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-139.353, 41.727],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-139.801, 41.815],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.236, 41.859],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.671, 41.934],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-141.073, 41.953],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-141.495, 41.983],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-141.923, 41.985],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.339, 41.983],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.742, 42.018],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.155, 42.094],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.51, 42.125],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.901, 42.225],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.261, 42.351],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.598, 42.499],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145, 42.691],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.329, 42.876],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.613, 43.125],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.968, 43.448],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.272, 43.786],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.539, 44.178],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.846, 44.661],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.116, 45.223],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.324, 45.863],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.529, 46.587],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.712, 47.481],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.823, 48.51],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.895, 49.709],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.806, 51.082],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.529, 52.658],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.737, 54.216],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.577, 54],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.835, 46.849],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.979, 35.877],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.862, 23.683],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.57, 11.068],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.955, -1.153],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.4, -12.678],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.184, -23.272],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.218, -32.978],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.484, -41.754],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.764, -49.75],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.957, -57.036],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.054, -63.716],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.388, -69.832],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.09, -75.445],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.264, -80.643],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.964, -85.443],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.266, -89.955],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.204, -94.147],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.763, -98.019],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.057, -101.667],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.054, -105.053],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.795, -108.295],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.324, -111.294],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.632, -114.118],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.763, -116.789],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.669, -119.321],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.436, -121.674],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.036, -123.904],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.493, -126.036],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.796, -128.012],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.975, -129.895],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.042, -131.663],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.009, -133.341],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.843, -134.943],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.599, -136.505],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.228, -137.868],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.776, -139.209],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.256, -140.505],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.614, -141.678],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.921, -142.792],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.124, -143.872],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.295, -144.848],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.362, -145.788],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.388, -146.681],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.334, -147.494],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.247, -148.316],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.054, -148.972],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.838, -149.701],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.549, -150.288],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.219, -150.891],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.819, -151.416],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.394, -151.905],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.915, -152.335],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.389, -152.728],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.807, -153.09],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.188, -153.463],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.515, -153.729],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.823, -153.962],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.068, -154.198],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.301, -154.403],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.477, -154.556],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.66, -154.694],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.764, -154.785],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.82, -154.829],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.81, -154.913],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.579, -154.935],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.174, -154.955],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.584, -154.939],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.759, -154.964],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.76, -155.01],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.501, -155.041],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.986, -155.103],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.167, -155.167],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.043, -155.202],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.593, -155.262],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.752, -155.329],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.486, -155.392],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.714, -155.478],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.421, -155.504],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.527, -155.549],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.877, -155.596],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.455, -155.583],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.105, -155.543],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.655, -155.412],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.986, -155.148],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.857, -154.733],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.146, -154.077],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.727, -153.047],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.298, -151.552],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.539, -149.398],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.251, -146.519],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.511, -143.016],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.472, -138.9],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.729, -134.44],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.197, -129.77],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.013, -125.105],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.315, -120.415],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.315, -115.844],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-139.248, -111.399],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.215, -107.098],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.43, -102.964],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.987, -98.962],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.956, -95.174],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.428, -91.524],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.489, -88.081],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.17, -84.808],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.553, -81.657],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.622, -78.709],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.489, -75.905],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-176.101, -73.279],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-177.561, -70.791],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.816, -68.478],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.955, -66.298],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-180.944, -64.31],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.835, -62.497],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.614, -60.805],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.297, -59.284],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-183.921, -57.916],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.452, -56.736],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.927, -55.711],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.386, -54.786],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.751, -54.03],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.103, -53.412],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.418, -52.901],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.686, -52.529],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-186.964, -52.232],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.202, -51.995],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.438, -51.874],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.62, -51.8],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.773, -51.786],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.978, -51.773],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.131, -51.838],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.273, -51.903],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.394, -52.006],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.53, -52.104],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.649, -52.233],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.755, -52.35],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.85, -52.5],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.941, -52.601],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.035, -52.7],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.107, -52.842],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.184, -52.943],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.216, -53.064],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.291, -53.162],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.34, -53.213],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.384, -53.307],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.412, -53.375],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.472, -53.451],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.461, -53.514],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.483, -53.541],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.495, -53.582],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 8',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 8,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.632, 78.404],
                                  [-165.586, -118.387],
                                  [-196.791, -175.96],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.631, 78.505],
                                  [-165.482, -118.355],
                                  [-196.798, -175.914],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.658, 78.609],
                                  [-165.333, -118.343],
                                  [-196.736, -175.825],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.683, 78.751],
                                  [-165.137, -118.259],
                                  [-196.73, -175.663],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.769, 78.959],
                                  [-164.801, -118.166],
                                  [-196.629, -175.457],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.821, 79.233],
                                  [-164.39, -118.036],
                                  [-196.527, -175.174],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.934, 79.544],
                                  [-163.933, -117.898],
                                  [-196.421, -174.848],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.043, 79.956],
                                  [-163.342, -117.694],
                                  [-196.287, -174.478],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.151, 80.434],
                                  [-162.611, -117.45],
                                  [-196.124, -174.001],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.318, 80.983],
                                  [-161.81, -117.174],
                                  [-195.901, -173.444],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.512, 81.632],
                                  [-160.841, -116.837],
                                  [-195.621, -172.84],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.736, 82.378],
                                  [-159.674, -116.464],
                                  [-195.318, -172.126],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.954, 83.228],
                                  [-158.347, -115.968],
                                  [-194.928, -171.27],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.264, 84.208],
                                  [-156.791, -115.414],
                                  [-194.486, -170.364],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.567, 85.321],
                                  [-155.044, -114.769],
                                  [-193.931, -169.281],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.964, 86.601],
                                  [-152.977, -114.004],
                                  [-193.267, -168.055],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.383, 88.072],
                                  [-150.597, -113.087],
                                  [-192.493, -166.654],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.893, 89.773],
                                  [-147.87, -112.028],
                                  [-191.583, -165.103],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.463, 91.669],
                                  [-144.673, -110.759],
                                  [-190.473, -163.28],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.153, 93.889],
                                  [-140.945, -109.221],
                                  [-189.167, -161.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.93, 96.461],
                                  [-136.565, -107.447],
                                  [-187.544, -158.877],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.86, 99.418],
                                  [-131.53, -105.313],
                                  [-185.631, -156.17],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.948, 102.821],
                                  [-125.596, -102.786],
                                  [-183.308, -153.067],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.193, 106.734],
                                  [-118.727, -99.771],
                                  [-180.567, -149.538],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.638, 111.157],
                                  [-110.924, -96.32],
                                  [-177.398, -145.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.231, 116.022],
                                  [-102.25, -92.449],
                                  [-173.781, -141.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.994, 121.17],
                                  [-93.103, -88.326],
                                  [-169.94, -136.778],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.852, 126.347],
                                  [-83.89, -84.11],
                                  [-166.002, -132.263],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.663, 131.334],
                                  [-75.105, -80.039],
                                  [-162.195, -128.001],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.499, 135.971],
                                  [-66.968, -76.261],
                                  [-158.666, -124.065],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.27, 140.23],
                                  [-59.607, -72.806],
                                  [-155.415, -120.583],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.005, 144.108],
                                  [-53.056, -69.678],
                                  [-152.48, -117.425],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.703, 147.609],
                                  [-47.226, -66.886],
                                  [-149.868, -114.684],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.366, 150.797],
                                  [-42.06, -64.37],
                                  [-147.524, -112.261],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.024, 153.703],
                                  [-37.529, -62.077],
                                  [-145.423, -110.122],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.709, 156.391],
                                  [-33.484, -60.04],
                                  [-143.509, -108.267],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.362, 158.863],
                                  [-29.959, -58.17],
                                  [-141.756, -106.663],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.077, 161.118],
                                  [-26.835, -56.502],
                                  [-140.198, -105.279],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.83, 163.222],
                                  [-24.118, -54.977],
                                  [-138.778, -104.085],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.623, 165.205],
                                  [-21.718, -53.564],
                                  [-137.466, -103.083],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.494, 167.039],
                                  [-19.674, -52.3],
                                  [-136.266, -102.273],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.447, 168.785],
                                  [-17.957, -51.124],
                                  [-135.212, -101.565],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.458, 170.412],
                                  [-16.515, -50.064],
                                  [-134.21, -101.118],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.564, 171.954],
                                  [-15.349, -49.064],
                                  [-133.326, -100.747],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.769, 173.443],
                                  [-14.468, -48.188],
                                  [-132.495, -100.548],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-226.08, 174.848],
                                  [-13.841, -47.369],
                                  [-131.753, -100.524],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-228.53, 176.164],
                                  [-13.471, -46.608],
                                  [-131.067, -100.618],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-231.059, 177.465],
                                  [-13.294, -45.938],
                                  [-130.437, -100.859],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-233.721, 178.675],
                                  [-13.37, -45.325],
                                  [-129.857, -101.243],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.51, 179.861],
                                  [-13.626, -44.731],
                                  [-129.331, -101.766],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-239.377, 180.988],
                                  [-14.084, -44.199],
                                  [-128.841, -102.354],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.295, 182.061],
                                  [-14.678, -43.699],
                                  [-128.365, -103.103],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.271, 183.095],
                                  [-15.366, -43.219],
                                  [-127.899, -103.864],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-248.247, 184.071],
                                  [-16.172, -42.771],
                                  [-127.455, -104.743],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.201, 185.038],
                                  [-17.018, -42.289],
                                  [-127.041, -105.659],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.124, 185.929],
                                  [-17.89, -41.847],
                                  [-126.581, -106.566],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-256.916, 186.743],
                                  [-18.719, -41.394],
                                  [-126.151, -107.488],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-259.641, 187.541],
                                  [-19.575, -40.968],
                                  [-125.676, -108.461],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-262.275, 188.274],
                                  [-20.341, -40.526],
                                  [-125.262, -109.325],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.732, 189.014],
                                  [-21.123, -40.102],
                                  [-124.787, -110.239],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-267.11, 189.652],
                                  [-21.819, -39.669],
                                  [-124.343, -111.095],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-269.331, 190.275],
                                  [-22.487, -39.222],
                                  [-123.871, -111.893],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-271.452, 190.86],
                                  [-23.055, -38.798],
                                  [-123.44, -112.694],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-273.438, 191.392],
                                  [-23.653, -38.402],
                                  [-122.991, -113.413],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-275.358, 191.89],
                                  [-24.181, -38],
                                  [-122.528, -114.178],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.153, 192.376],
                                  [-24.67, -37.611],
                                  [-122.118, -114.823],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.85, 192.831],
                                  [-25.164, -37.223],
                                  [-121.702, -115.516],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-280.433, 193.235],
                                  [-25.589, -36.875],
                                  [-121.305, -116.135],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-281.969, 193.609],
                                  [-26.012, -36.552],
                                  [-120.908, -116.743],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-283.38, 193.975],
                                  [-26.416, -36.193],
                                  [-120.577, -117.301],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.774, 194.334],
                                  [-26.829, -35.903],
                                  [-120.23, -117.851],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-286.05, 194.666],
                                  [-27.191, -35.601],
                                  [-119.932, -118.395],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.296, 194.95],
                                  [-27.569, -35.33],
                                  [-119.601, -118.916],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-288.453, 195.208],
                                  [-27.946, -35.071],
                                  [-119.301, -119.39],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-289.585, 195.48],
                                  [-28.282, -34.846],
                                  [-119.076, -119.863],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.614, 195.709],
                                  [-28.644, -34.613],
                                  [-118.822, -120.316],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-291.624, 195.952],
                                  [-28.972, -34.415],
                                  [-118.604, -120.769],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-292.574, 196.173],
                                  [-29.373, -34.235],
                                  [-118.377, -121.18],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-293.47, 196.346],
                                  [-29.7, -34.091],
                                  [-118.211, -121.574],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.354, 196.497],
                                  [-30.086, -33.943],
                                  [-118.04, -121.971],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.165, 196.664],
                                  [-30.402, -33.833],
                                  [-117.887, -122.372],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.947, 196.809],
                                  [-30.756, -33.719],
                                  [-117.793, -122.739],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-296.677, 196.951],
                                  [-31.131, -33.625],
                                  [-117.675, -123.086],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-297.363, 197.071],
                                  [-31.484, -33.572],
                                  [-117.578, -123.42],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-298.044, 197.19],
                                  [-31.879, -33.516],
                                  [-117.541, -123.741],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-298.72, 197.262],
                                  [-32.254, -33.5],
                                  [-117.526, -124.068],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-299.312, 197.376],
                                  [-32.656, -33.464],
                                  [-117.508, -124.359],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-299.839, 197.444],
                                  [-33.04, -33.488],
                                  [-117.488, -124.682],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.409, 197.492],
                                  [-33.426, -33.492],
                                  [-117.492, -124.951],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.916, 197.538],
                                  [-33.841, -33.538],
                                  [-117.538, -125.227],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-301.379, 197.564],
                                  [-34.214, -33.627],
                                  [-117.475, -125.476],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-301.816, 197.598],
                                  [-34.485, -33.759],
                                  [-117.278, -125.608],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.2, 197.639],
                                  [-34.706, -33.934],
                                  [-116.846, -125.669],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.514, 197.626],
                                  [-34.78, -34.242],
                                  [-116.278, -125.668],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.794, 197.622],
                                  [-34.786, -34.595],
                                  [-115.404, -125.552],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.957, 197.61],
                                  [-34.679, -35.036],
                                  [-114.313, -125.304],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-303.068, 197.591],
                                  [-34.442, -35.567],
                                  [-112.977, -124.968],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-303.16, 197.565],
                                  [-34.11, -36.233],
                                  [-111.316, -124.483],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-303.153, 197.47],
                                  [-33.619, -36.989],
                                  [-109.391, -123.912],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-303.02, 197.398],
                                  [-32.97, -37.835],
                                  [-107.068, -123.209],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.84, 197.348],
                                  [-32.179, -38.863],
                                  [-104.327, -122.296],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.549, 197.242],
                                  [-31.155, -40.072],
                                  [-101.17, -121.217],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.119, 197.099],
                                  [-29.943, -41.419],
                                  [-97.548, -119.947],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-301.612, 196.991],
                                  [-28.515, -42.993],
                                  [-93.356, -118.449],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.908, 196.858],
                                  [-26.824, -44.795],
                                  [-88.545, -116.679],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.068, 196.666],
                                  [-24.795, -46.827],
                                  [-83.008, -114.638],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-299.063, 196.416],
                                  [-22.446, -49.179],
                                  [-76.636, -112.292],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-297.852, 196.212],
                                  [-19.683, -51.897],
                                  [-69.292, -109.533],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-296.342, 195.931],
                                  [-16.449, -55.028],
                                  [-60.804, -106.299],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.599, 195.602],
                                  [-12.712, -58.619],
                                  [-51.016, -102.573],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-292.518, 195.233],
                                  [-8.278, -62.806],
                                  [-39.712, -98.259],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.027, 194.81],
                                  [-3.115, -67.636],
                                  [-26.554, -93.206],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.099, 194.313],
                                  [2.928, -73.203],
                                  [-11.277, -87.332],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-283.655, 193.757],
                                  [9.912, -79.644],
                                  [6.29, -80.543],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-279.664, 193.145],
                                  [17.84, -86.961],
                                  [26.242, -72.794],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-275.253, 192.467],
                                  [26.659, -95.019],
                                  [48.228, -64.272],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-270.572, 191.785],
                                  [36.042, -103.544],
                                  [71.48, -55.262],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.798, 191.061],
                                  [45.483, -112.171],
                                  [94.949, -46.172],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-261.172, 190.43],
                                  [54.596, -120.441],
                                  [117.51, -37.445],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-256.794, 189.87],
                                  [63.139, -128.17],
                                  [138.62, -29.283],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-252.831, 189.383],
                                  [70.987, -135.264],
                                  [157.892, -21.859],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.149, 188.936],
                                  [78.092, -141.723],
                                  [175.37, -15.124],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.85, 188.601],
                                  [84.496, -147.544],
                                  [191.19, -9.08],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.86, 188.263],
                                  [90.345, -152.817],
                                  [205.566, -3.6],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-240.096, 187.997],
                                  [95.667, -157.635],
                                  [218.558, 1.395],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-237.577, 187.766],
                                  [100.517, -162.041],
                                  [230.474, 5.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-235.262, 187.583],
                                  [105.003, -166.082],
                                  [241.389, 10.04],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-233.125, 187.429],
                                  [109.12, -169.801],
                                  [251.396, 13.817],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-231.137, 187.305],
                                  [112.881, -173.199],
                                  [260.662, 17.296],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-229.286, 187.204],
                                  [116.392, -176.368],
                                  [269.22, 20.511],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.574, 187.127],
                                  [119.668, -179.352],
                                  [277.144, 23.525],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-225.927, 187.01],
                                  [122.72, -182.106],
                                  [284.558, 26.274],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.453, 186.992],
                                  [125.565, -184.675],
                                  [291.447, 28.868],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.016, 186.916],
                                  [128.232, -187.059],
                                  [297.917, 31.26],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.678, 186.859],
                                  [130.749, -189.304],
                                  [303.94, 33.467],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.457, 186.836],
                                  [133.087, -191.456],
                                  [309.636, 35.597],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.245, 186.831],
                                  [135.291, -193.422],
                                  [314.959, 37.572],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-218.12, 186.826],
                                  [137.374, -195.294],
                                  [319.942, 39.425],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.051, 186.823],
                                  [139.368, -197.074],
                                  [324.69, 41.154],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.087, 186.836],
                                  [141.195, -198.759],
                                  [329.144, 42.808],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.15, 186.787],
                                  [142.962, -200.304],
                                  [333.332, 44.322],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.256, 186.831],
                                  [144.624, -201.802],
                                  [337.255, 45.775],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.375, 186.829],
                                  [146.163, -203.206],
                                  [341.022, 47.106],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.584, 186.856],
                                  [147.625, -204.515],
                                  [344.54, 48.407],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.838, 186.867],
                                  [149.028, -205.777],
                                  [347.884, 49.601],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.122, 186.862],
                                  [150.354, -206.945],
                                  [351.026, 50.765],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.404, 186.886],
                                  [151.619, -208.065],
                                  [353.995, 51.822],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.793, 186.94],
                                  [152.79, -209.136],
                                  [356.822, 52.864],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.15, 186.93],
                                  [153.885, -210.16],
                                  [359.493, 53.847],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.585, 186.981],
                                  [154.965, -211.09],
                                  [361.99, 54.706],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.018, 186.968],
                                  [155.952, -211.971],
                                  [364.347, 55.551],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.499, 187.015],
                                  [156.907, -212.851],
                                  [366.593, 56.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.026, 187.044],
                                  [157.785, -213.637],
                                  [368.727, 57.122],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.537, 187.041],
                                  [158.631, -214.374],
                                  [370.705, 57.832],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.126, 187.113],
                                  [159.4, -215.11],
                                  [372.572, 58.544],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.684, 187.09],
                                  [160.167, -215.751],
                                  [374.344, 59.133],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.321, 187.142],
                                  [160.857, -216.391],
                                  [376.005, 59.723],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.942, 187.161],
                                  [161.515, -216.982],
                                  [377.555, 60.299],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.595, 187.194],
                                  [162.126, -217.526],
                                  [379.01, 60.814],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.264, 187.193],
                                  [162.705, -218.068],
                                  [380.401, 61.332],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.965, 187.205],
                                  [163.253, -218.515],
                                  [381.634, 61.725],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.713, 187.231],
                                  [163.752, -219.007],
                                  [382.865, 62.183],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.431, 187.269],
                                  [164.22, -219.405],
                                  [383.939, 62.565],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.181, 187.275],
                                  [164.641, -219.801],
                                  [384.965, 62.947],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.979, 187.294],
                                  [165.029, -220.149],
                                  [385.926, 63.269],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.746, 187.31],
                                  [165.416, -220.495],
                                  [386.792, 63.546],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.561, 187.339],
                                  [165.772, -220.794],
                                  [387.547, 63.855],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.392, 187.336],
                                  [166.08, -221.044],
                                  [388.3, 64.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.224, 187.345],
                                  [166.356, -221.293],
                                  [388.943, 64.307],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.089, 187.368],
                                  [166.584, -221.54],
                                  [389.537, 64.558],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.001, 187.389],
                                  [166.827, -221.74],
                                  [390.067, 64.702],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.837, 187.423],
                                  [167.022, -221.891],
                                  [390.502, 64.878],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.766, 187.423],
                                  [167.169, -222.041],
                                  [390.935, 65.008],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.682, 187.422],
                                  [167.331, -222.189],
                                  [391.257, 65.14],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.629, 187.433],
                                  [167.445, -222.29],
                                  [391.531, 65.258],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.531, 187.443],
                                  [167.574, -222.389],
                                  [391.787, 65.33],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.512, 187.419],
                                  [167.64, -222.44],
                                  [391.965, 65.388],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.494, 187.455],
                                  [167.689, -222.49],
                                  [392.062, 65.401],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.454, 187.443],
                                  [167.62, -222.53],
                                  [392.023, 65.454],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.498, 187.314],
                                  [167.378, -222.531],
                                  [391.597, 65.357],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.532, 187.149],
                                  [166.915, -222.494],
                                  [390.882, 65.234],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.603, 186.944],
                                  [166.277, -222.435],
                                  [389.797, 65.086],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.73, 186.634],
                                  [165.37, -222.38],
                                  [388.339, 64.852],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.835, 186.215],
                                  [164.269, -222.328],
                                  [386.524, 64.518],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.984, 185.715],
                                  [162.882, -222.229],
                                  [384.256, 64.149],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.116, 185.114],
                                  [161.191, -222.127],
                                  [381.517, 63.683],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.342, 184.438],
                                  [159.195, -222.033],
                                  [378.275, 63.124],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.604, 183.634],
                                  [156.861, -221.865],
                                  [374.465, 62.504],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.84, 182.667],
                                  [154.143, -221.664],
                                  [370.041, 61.763],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.148, 181.575],
                                  [151.04, -221.484],
                                  [364.923, 60.841],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.501, 180.303],
                                  [147.442, -221.226],
                                  [359.048, 59.834],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.901, 178.858],
                                  [143.291, -220.939],
                                  [352.325, 58.636],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.368, 177.182],
                                  [138.617, -220.582],
                                  [344.569, 57.247],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.846, 175.28],
                                  [133.239, -220.14],
                                  [335.784, 55.686],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.414, 173.151],
                                  [127.068, -219.686],
                                  [325.681, 53.816],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.062, 170.687],
                                  [120.084, -219.1],
                                  [314.197, 51.633],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.715, 167.856],
                                  [112.071, -218.384],
                                  [301.057, 49.086],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.481, 164.651],
                                  [102.911, -217.53],
                                  [285.967, 46.112],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.253, 161.039],
                                  [92.474, -216.496],
                                  [268.68, 42.553],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.129, 156.877],
                                  [80.51, -215.219],
                                  [248.874, 38.323],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.996, 152.143],
                                  [66.897, -213.65],
                                  [226.255, 33.256],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.873, 146.835],
                                  [51.541, -211.684],
                                  [200.661, 27.241],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.678, 140.96],
                                  [34.551, -209.245],
                                  [172.252, 20.166],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.298, 134.726],
                                  [16.412, -206.317],
                                  [141.645, 12],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.658, 128.401],
                                  [-2.104, -202.9],
                                  [110.253, 2.936],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.754, 122.297],
                                  [-20.034, -199.174],
                                  [79.623, -6.651],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.451, 116.699],
                                  [-36.545, -195.189],
                                  [51.092, -16.314],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.911, 111.707],
                                  [-51.237, -191.182],
                                  [25.443, -25.743],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.121, 107.417],
                                  [-64.095, -187.218],
                                  [2.814, -34.72],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.186, 103.74],
                                  [-75.283, -183.467],
                                  [-16.979, -43.188],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.159, 100.601],
                                  [-84.913, -179.871],
                                  [-34.306, -51.145],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.048, 97.9],
                                  [-93.26, -176.465],
                                  [-49.49, -58.518],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.903, 95.561],
                                  [-100.56, -173.234],
                                  [-62.871, -65.439],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.715, 93.578],
                                  [-106.907, -170.206],
                                  [-74.7, -71.922],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.536, 91.841],
                                  [-112.527, -167.345],
                                  [-85.212, -78.007],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.392, 90.354],
                                  [-117.5, -164.605],
                                  [-94.607, -83.684],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.242, 89.023],
                                  [-121.914, -162.047],
                                  [-103.057, -89.053],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.119, 87.876],
                                  [-125.836, -159.6],
                                  [-110.696, -94.087],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.997, 86.827],
                                  [-129.384, -157.29],
                                  [-117.582, -98.832],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.933, 85.949],
                                  [-132.539, -155.139],
                                  [-123.84, -103.332],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.898, 85.128],
                                  [-135.415, -153.02],
                                  [-129.55, -107.571],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.872, 84.413],
                                  [-137.985, -151.047],
                                  [-134.761, -111.573],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.9, 83.822],
                                  [-140.36, -149.191],
                                  [-139.545, -115.394],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.922, 83.249],
                                  [-142.481, -147.414],
                                  [-143.915, -119.024],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.008, 82.719],
                                  [-144.449, -145.677],
                                  [-147.973, -122.424],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.098, 82.283],
                                  [-146.206, -144.076],
                                  [-151.696, -125.717],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.23, 81.874],
                                  [-147.85, -142.509],
                                  [-155.121, -128.803],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.406, 81.516],
                                  [-149.322, -141.036],
                                  [-158.345, -131.743],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.63, 81.204],
                                  [-150.686, -139.655],
                                  [-161.279, -134.501],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.841, 80.902],
                                  [-151.947, -138.302],
                                  [-164.019, -137.168],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.074, 80.669],
                                  [-153.104, -137.035],
                                  [-166.57, -139.679],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.392, 80.438],
                                  [-154.161, -135.822],
                                  [-168.901, -142.096],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.704, 80.237],
                                  [-155.117, -134.69],
                                  [-171.108, -144.386],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.043, 80.033],
                                  [-156.006, -133.607],
                                  [-173.161, -146.578],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.44, 79.853],
                                  [-156.858, -132.541],
                                  [-175.03, -148.608],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.803, 79.697],
                                  [-157.613, -131.519],
                                  [-176.841, -150.57],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.228, 79.562],
                                  [-158.333, -130.604],
                                  [-178.469, -152.431],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.683, 79.444],
                                  [-158.987, -129.698],
                                  [-180.009, -154.19],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.139, 79.345],
                                  [-159.574, -128.865],
                                  [-181.46, -155.847],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.658, 79.231],
                                  [-160.159, -128.039],
                                  [-182.823, -157.433],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.178, 79.161],
                                  [-160.677, -127.282],
                                  [-184.036, -158.948],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.763, 79.074],
                                  [-161.162, -126.563],
                                  [-185.222, -160.36],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.318, 78.968],
                                  [-161.578, -125.845],
                                  [-186.29, -161.699],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.877, 78.935],
                                  [-161.962, -125.226],
                                  [-187.3, -162.965],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.532, 78.882],
                                  [-162.373, -124.607],
                                  [-188.22, -164.158],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.159, 78.807],
                                  [-162.715, -124.021],
                                  [-189.114, -165.28],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.821, 78.772],
                                  [-163.021, -123.465],
                                  [-189.918, -166.328],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.455, 78.715],
                                  [-163.324, -122.972],
                                  [-190.663, -167.335],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.187, 78.693],
                                  [-163.59, -122.506],
                                  [-191.35, -168.236],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.892, 78.65],
                                  [-163.849, -122.068],
                                  [-192.007, -169.128],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.632, 78.613],
                                  [-164.071, -121.627],
                                  [-192.604, -169.916],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.408, 78.582],
                                  [-164.255, -121.245],
                                  [-193.172, -170.663],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.157, 78.556],
                                  [-164.464, -120.887],
                                  [-193.646, -171.336],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.971, 78.566],
                                  [-164.632, -120.554],
                                  [-194.089, -171.968],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.758, 78.52],
                                  [-164.794, -120.244],
                                  [-194.533, -172.558],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.582, 78.51],
                                  [-164.947, -119.991],
                                  [-194.883, -173.107],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.412, 78.501],
                                  [-165.028, -119.73],
                                  [-195.23, -173.586],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.274, 78.469],
                                  [-165.161, -119.49],
                                  [-195.543, -174.024],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.11, 78.467],
                                  [-165.288, -119.272],
                                  [-195.794, -174.388],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.045, 78.442],
                                  [-165.341, -119.074],
                                  [-196.007, -174.745],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.919, 78.45],
                                  [-165.412, -118.93],
                                  [-196.219, -175.027],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.864, 78.43],
                                  [-165.505, -118.804],
                                  [-196.424, -175.303],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.748, 78.414],
                                  [-165.523, -118.697],
                                  [-196.532, -175.538],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.698, 78.399],
                                  [-165.562, -118.58],
                                  [-196.632, -175.67],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.654, 78.421],
                                  [-165.584, -118.542],
                                  [-196.73, -175.825],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.604, 78.406],
                                  [-165.625, -118.427],
                                  [-196.804, -175.989],
                                ],
                                c: false,
                              },
                            ],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.483, 0.483, 0.483, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.483, 0.483, 0.483, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-169.608, -52.677],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.538, -52.629],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.45, -52.556],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.327, -52.453],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.134, -52.305],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.879, -52.107],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.614, -51.88],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.258, -51.606],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.807, -51.278],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.333, -50.881],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-166.752, -50.438],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-166.051, -49.944],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.239, -49.35],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.3, -48.715],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.232, -47.959],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.976, -47.112],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.509, -46.146],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.829, -45.058],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.838, -43.827],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.499, -42.415],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.719, -40.801],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.5, -38.921],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.656, -36.757],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.134, -34.248],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.936, -31.38],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.067, -28.176],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.82, -24.729],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.488, -21.149],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.407, -17.627],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.794, -14.284],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.735, -11.155],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.292, -8.233],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.379, -5.578],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.972, -3.128],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.064, -0.839],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.56, 1.277],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.466, 3.274],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.709, 5.103],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.297, 6.84],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.157, 8.496],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.341, 10.053],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.818, 11.558],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.543, 12.978],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.523, 14.357],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.79, 15.687],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.291, 16.97],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.057, 18.223],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.018, 19.436],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.234, 20.613],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.631, 21.779],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.231, 22.917],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.949, 24.015],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.766, 25.098],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.661, 26.129],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.553, 27.152],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.47, 28.135],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.266, 29.047],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.062, 29.933],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.726, 30.766],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.339, 31.58],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.826, 32.324],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.231, 33.058],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.489, 33.719],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.739, 34.355],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.883, 34.948],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.951, 35.517],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.966, 36.052],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.889, 36.534],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.794, 36.988],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.608, 37.428],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.448, 37.841],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.172, 38.214],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.915, 38.566],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.622, 38.895],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.274, 39.189],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.909, 39.471],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.508, 39.73],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.158, 39.987],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.72, 40.185],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.321, 40.381],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.839, 40.547],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.367, 40.702],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.907, 40.855],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.419, 40.975],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.95, 41.089],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.466, 41.16],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.973, 41.26],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.447, 41.303],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.941, 41.357],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.433, 41.382],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.921, 41.404],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-121.395, 41.456],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-121.902, 41.553],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.353, 41.6],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.86, 41.718],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.345, 41.854],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.818, 42.008],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.376, 42.207],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.881, 42.397],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.373, 42.655],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.954, 42.977],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126.507, 43.307],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.065, 43.693],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.693, 44.16],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.33, 44.7],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.958, 45.31],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.651, 46.004],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.39, 46.846],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.155, 47.82],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.005, 48.966],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.855, 50.288],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.773, 51.88],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.721, 53.762],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.506, 55.785],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.915, 47.446],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.696, 35.773],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.696, 23.345],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.616, 10.667],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.177, -1.541],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.711, -13.005],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.471, -23.52],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.375, -33.134],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.415, -41.809],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.384, -49.708],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-2.188, -56.905],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.271, -63.496],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.092, -69.512],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.354, -75.061],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.098, -80.162],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.429, -84.903],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.368, -89.327],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.992, -93.462],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.254, -97.273],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.264, -100.852],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.008, -104.192],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.514, -107.383],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.809, -110.32],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.909, -113.103],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.848, -115.731],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.572, -118.222],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.16, -120.53],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.601, -122.725],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.907, -124.821],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.066, -126.765],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.112, -128.616],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.052, -130.355],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.9, -132.004],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.623, -133.58],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.274, -135.113],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.801, -136.454],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.255, -137.771],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.645, -139.045],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.919, -140.197],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.151, -141.295],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.29, -142.363],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.39, -143.323],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.392, -144.248],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.357, -145.123],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.246, -145.923],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.105, -146.727],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.862, -147.374],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.6, -148.086],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.268, -148.664],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.897, -149.255],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.46, -149.768],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.001, -150.252],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.49, -150.674],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.935, -151.06],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.328, -151.415],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.688, -151.78],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.996, -152.044],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.288, -152.277],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.52, -152.51],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.741, -152.714],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.909, -152.866],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.082, -153.001],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.18, -153.093],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.232, -153.137],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.224, -153.219],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.003, -153.228],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.615, -153.229],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.053, -153.192],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.264, -153.184],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.308, -153.184],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.108, -153.161],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.662, -153.157],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.925, -153.149],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.898, -153.095],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.561, -153.046],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.85, -152.989],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.734, -152.911],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.14, -152.826],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.047, -152.658],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.39, -152.481],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.013, -152.275],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.905, -151.958],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.919, -151.563],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.897, -151.022],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.72, -150.269],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.174, -149.269],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.139, -147.917],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.508, -146.06],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.608, -143.593],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.849, -140.316],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.483, -136.219],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.656, -131.414],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.569, -125.998],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.847, -120.283],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.428, -114.407],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.441, -108.616],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.024, -102.87],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126.375, -97.315],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.725, -91.932],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.17, -86.744],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.905, -81.772],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.021, -76.973],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.591, -72.415],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.692, -68.028],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.404, -63.878],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.762, -59.921],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.841, -56.111],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.624, -52.529],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.21, -49.129],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.55, -45.943],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-166.735, -42.972],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.708, -40.28],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.535, -37.928],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.16, -36.125],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.61, -35.022],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.861, -34.675],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.971, -34.954],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.024, -35.604],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.009, -36.472],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.978, -37.43],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.976, -38.357],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.918, -39.327],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.896, -40.25],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.867, -41.146],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.821, -42.012],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.818, -42.846],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.796, -43.621],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.795, -44.375],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.754, -45.102],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.713, -45.767],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.738, -46.384],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.719, -47],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.703, -47.552],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.684, -48.091],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.689, -48.566],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.685, -49.045],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.678, -49.469],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.667, -49.878],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.669, -50.229],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.673, -50.548],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.667, -50.879],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.675, -51.157],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.64, -51.419],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.654, -51.656],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.661, -51.836],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.653, -52.032],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.642, -52.179],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.67, -52.33],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.631, -52.457],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.631, -52.531],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.625, -52.604],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.623, -52.653],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-169.608, -52.677],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.538, -52.629],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.45, -52.556],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.327, -52.453],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.134, -52.305],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.879, -52.107],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.614, -51.88],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.258, -51.606],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.807, -51.278],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.333, -50.881],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-166.752, -50.438],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-166.051, -49.944],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.239, -49.35],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.3, -48.715],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.232, -47.959],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.976, -47.112],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.509, -46.146],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.829, -45.058],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.838, -43.827],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.499, -42.415],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.719, -40.801],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.5, -38.921],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.656, -36.757],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.134, -34.248],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.936, -31.38],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.067, -28.176],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.82, -24.729],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.488, -21.149],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.407, -17.627],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.794, -14.284],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.735, -11.155],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.292, -8.233],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.379, -5.578],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.972, -3.128],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.064, -0.839],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.56, 1.277],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.466, 3.274],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.709, 5.103],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.297, 6.84],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.157, 8.496],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.341, 10.053],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.818, 11.558],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.543, 12.978],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.523, 14.357],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.79, 15.687],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.291, 16.97],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.057, 18.223],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.018, 19.436],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.234, 20.613],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.631, 21.779],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.231, 22.917],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.949, 24.015],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.766, 25.098],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.661, 26.129],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.553, 27.152],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.47, 28.135],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.266, 29.047],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.062, 29.933],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.726, 30.766],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.339, 31.58],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.826, 32.324],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.231, 33.058],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.489, 33.719],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.739, 34.355],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.883, 34.948],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.951, 35.517],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.966, 36.052],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.889, 36.534],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.794, 36.988],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.608, 37.428],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.448, 37.841],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.172, 38.214],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.915, 38.566],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.622, 38.895],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.274, 39.189],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.909, 39.471],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.508, 39.73],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.158, 39.987],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.72, 40.185],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.321, 40.381],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.839, 40.547],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.367, 40.702],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.907, 40.855],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.419, 40.975],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.95, 41.089],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.466, 41.16],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.973, 41.26],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.447, 41.303],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.941, 41.357],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.433, 41.382],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.921, 41.404],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-121.395, 41.456],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-121.902, 41.553],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.353, 41.6],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.86, 41.718],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.345, 41.854],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.818, 42.008],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.376, 42.207],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.881, 42.397],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.373, 42.655],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.954, 42.977],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126.507, 43.307],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.065, 43.693],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.693, 44.16],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.33, 44.7],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.958, 45.31],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.651, 46.004],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.39, 46.846],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.155, 47.82],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.005, 48.966],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.855, 50.288],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.773, 51.88],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.721, 53.762],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.506, 55.785],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.915, 47.446],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.696, 35.773],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.696, 23.345],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.616, 10.667],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.177, -1.541],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.711, -13.005],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.471, -23.52],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.375, -33.134],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.415, -41.809],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.384, -49.708],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-2.188, -56.905],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.271, -63.496],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.092, -69.512],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.354, -75.061],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.098, -80.162],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.429, -84.903],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.368, -89.327],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.992, -93.462],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.254, -97.273],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.264, -100.852],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.008, -104.192],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.514, -107.383],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.809, -110.32],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.909, -113.103],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.848, -115.731],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.572, -118.222],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.16, -120.53],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.601, -122.725],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.907, -124.821],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.066, -126.765],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.112, -128.616],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.052, -130.355],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.9, -132.004],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.623, -133.58],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.274, -135.113],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.801, -136.454],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.255, -137.771],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.645, -139.045],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.919, -140.197],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.151, -141.295],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.29, -142.363],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.39, -143.323],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.392, -144.248],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.357, -145.123],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.246, -145.923],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.105, -146.727],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.862, -147.374],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.6, -148.086],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.268, -148.664],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.897, -149.255],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.46, -149.768],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.001, -150.252],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.49, -150.674],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.935, -151.06],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.328, -151.415],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.688, -151.78],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.996, -152.044],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.288, -152.277],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.52, -152.51],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.741, -152.714],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.909, -152.866],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.082, -153.001],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.18, -153.093],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.232, -153.137],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.224, -153.219],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.003, -153.228],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.615, -153.229],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.053, -153.192],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.264, -153.184],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.308, -153.184],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.108, -153.161],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.662, -153.157],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.925, -153.149],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.898, -153.095],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.561, -153.046],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.85, -152.989],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.734, -152.911],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.14, -152.826],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.047, -152.658],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.39, -152.481],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.013, -152.275],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.905, -151.958],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.919, -151.563],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.897, -151.022],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.72, -150.269],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.174, -149.269],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.139, -147.917],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.508, -146.06],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.608, -143.593],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.849, -140.316],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.483, -136.219],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.656, -131.414],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.569, -125.998],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.847, -120.283],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.428, -114.407],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.441, -108.616],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.024, -102.87],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126.375, -97.315],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.725, -91.932],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.17, -86.744],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.905, -81.772],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.021, -76.973],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.591, -72.415],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.692, -68.028],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.404, -63.878],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.762, -59.921],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.841, -56.111],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.624, -52.529],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.21, -49.129],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-165.55, -45.943],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-166.735, -42.972],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.708, -40.28],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.535, -37.928],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.16, -36.125],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.61, -35.022],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.861, -34.675],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.971, -34.954],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.024, -35.604],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.009, -36.472],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.978, -37.43],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.976, -38.357],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.918, -39.327],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.896, -40.25],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.867, -41.146],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.821, -42.012],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.818, -42.846],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.796, -43.621],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.795, -44.375],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.754, -45.102],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.713, -45.767],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.738, -46.384],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.719, -47],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.703, -47.552],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.684, -48.091],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.689, -48.566],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.685, -49.045],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.678, -49.469],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.667, -49.878],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.669, -50.229],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.673, -50.548],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.667, -50.879],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.675, -51.157],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.64, -51.419],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.654, -51.656],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.661, -51.836],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.653, -52.032],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.642, -52.179],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.67, -52.33],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.631, -52.457],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.631, -52.531],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.625, -52.604],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-169.623, -52.653],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 9',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 9,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.828, 71.204],
                                  [-136.79, -87.191],
                                  [-158.395, -187.956],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.818, 71.309],
                                  [-136.685, -87.175],
                                  [-158.421, -187.898],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.824, 71.417],
                                  [-136.543, -87.186],
                                  [-158.391, -187.784],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.82, 71.566],
                                  [-136.353, -87.138],
                                  [-158.429, -187.589],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.868, 71.782],
                                  [-136.028, -87.093],
                                  [-158.387, -187.339],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.869, 72.064],
                                  [-135.631, -87.026],
                                  [-158.365, -186.995],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.923, 72.387],
                                  [-135.187, -86.963],
                                  [-158.35, -186.595],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.956, 72.814],
                                  [-134.621, -86.855],
                                  [-158.331, -186.139],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.98, 73.308],
                                  [-133.911, -86.715],
                                  [-158.309, -185.564],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.047, 73.881],
                                  [-133.142, -86.566],
                                  [-158.246, -184.884],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.118, 74.558],
                                  [-132.204, -86.385],
                                  [-158.154, -184.136],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.207, 75.327],
                                  [-131.072, -86.185],
                                  [-158.071, -183.258],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.266, 76.212],
                                  [-129.797, -85.89],
                                  [-157.935, -182.213],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.395, 77.228],
                                  [-128.291, -85.574],
                                  [-157.783, -181.089],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.483, 78.385],
                                  [-126.606, -85.2],
                                  [-157.564, -179.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.644, 79.721],
                                  [-124.604, -84.742],
                                  [-157.288, -178.235],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.781, 81.248],
                                  [-122.311, -84.174],
                                  [-156.956, -176.508],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.97, 83.018],
                                  [-119.677, -83.53],
                                  [-156.564, -174.572],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.178, 84.99],
                                  [-116.59, -82.734],
                                  [-156.046, -172.305],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.446, 87.303],
                                  [-112.987, -81.744],
                                  [-155.422, -169.735],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.73, 89.981],
                                  [-108.756, -80.605],
                                  [-154.6, -166.806],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.085, 93.06],
                                  [-103.896, -79.211],
                                  [-153.608, -163.412],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.519, 96.602],
                                  [-98.169, -77.542],
                                  [-152.361, -159.512],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.004, 100.68],
                                  [-91.536, -75.523],
                                  [-150.859, -155.065],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.586, 105.291],
                                  [-83.997, -73.198],
                                  [-149.103, -150.044],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.223, 110.362],
                                  [-75.624, -70.572],
                                  [-147.037, -144.58],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.978, 115.729],
                                  [-66.787, -67.769],
                                  [-144.846, -138.887],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.822, 121.121],
                                  [-57.895, -64.875],
                                  [-142.567, -133.145],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.65, 126.323],
                                  [-49.409, -62.086],
                                  [-140.362, -127.699],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.569, 131.154],
                                  [-41.555, -59.501],
                                  [-138.323, -122.651],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.5, 135.598],
                                  [-34.451, -57.141],
                                  [-136.433, -118.155],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.469, 139.637],
                                  [-28.126, -54.999],
                                  [-134.734, -114.076],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.468, 143.281],
                                  [-22.497, -53.1],
                                  [-133.241, -110.499],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.496, 146.604],
                                  [-17.514, -51.387],
                                  [-131.908, -107.324],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.581, 149.633],
                                  [-13.145, -49.829],
                                  [-130.722, -104.496],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.733, 152.431],
                                  [-9.243, -48.457],
                                  [-129.644, -102.012],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.893, 155],
                                  [-5.849, -47.202],
                                  [-128.651, -99.829],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.161, 157.349],
                                  [-2.842, -46.095],
                                  [-127.8, -97.911],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.49, 159.534],
                                  [-0.229, -45.084],
                                  [-127.025, -96.224],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.892, 161.597],
                                  [2.076, -44.154],
                                  [-126.315, -94.761],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.392, 163.499],
                                  [4.038, -43.334],
                                  [-125.669, -93.524],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.002, 165.31],
                                  [5.674, -42.565],
                                  [-125.131, -92.417],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.687, 167.001],
                                  [7.05, -41.895],
                                  [-124.612, -91.596],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.487, 168.602],
                                  [8.151, -41.254],
                                  [-124.177, -90.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.407, 170.143],
                                  [8.976, -40.711],
                                  [-123.772, -90.348],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.447, 171.596],
                                  [9.549, -40.209],
                                  [-123.426, -90.016],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.642, 172.964],
                                  [9.868, -39.748],
                                  [-123.113, -89.815],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.928, 174.304],
                                  [9.995, -39.359],
                                  [-122.831, -89.779],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.365, 175.558],
                                  [9.872, -39.01],
                                  [-122.583, -89.904],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.942, 176.785],
                                  [9.57, -38.67],
                                  [-122.366, -90.185],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.613, 177.953],
                                  [9.07, -38.367],
                                  [-122.178, -90.551],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.353, 179.06],
                                  [8.425, -38.097],
                                  [-121.983, -91.085],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.166, 180.132],
                                  [7.689, -37.827],
                                  [-121.778, -91.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.988, 181.138],
                                  [6.83, -37.581],
                                  [-121.589, -92.34],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-225.812, 182.136],
                                  [5.934, -37.295],
                                  [-121.414, -93.087],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-228.612, 183.059],
                                  [5.002, -37.032],
                                  [-121.185, -93.838],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-231.294, 183.905],
                                  [4.113, -36.754],
                                  [-120.969, -94.611],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-233.926, 184.736],
                                  [3.193, -36.497],
                                  [-120.702, -95.449],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.472, 185.493],
                                  [2.368, -36.205],
                                  [-120.481, -96.19],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-238.853, 186.262],
                                  [1.525, -35.932],
                                  [-120.188, -96.986],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.162, 186.925],
                                  [0.761, -35.633],
                                  [-119.917, -97.736],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.328, 187.573],
                                  [0.031, -35.324],
                                  [-119.61, -98.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.397, 188.18],
                                  [-0.599, -35.025],
                                  [-119.334, -99.15],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.336, 188.737],
                                  [-1.259, -34.74],
                                  [-119.031, -99.787],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.213, 189.256],
                                  [-1.841, -34.455],
                                  [-118.706, -100.473],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.971, 189.763],
                                  [-2.393, -34.165],
                                  [-118.425, -101.05],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-252.64, 190.234],
                                  [-2.943, -33.879],
                                  [-118.129, -101.679],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.199, 190.659],
                                  [-3.422, -33.622],
                                  [-117.848, -102.238],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.714, 191.049],
                                  [-3.898, -33.384],
                                  [-117.558, -102.79],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-257.105, 191.432],
                                  [-4.353, -33.105],
                                  [-117.324, -103.301],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-258.486, 191.807],
                                  [-4.814, -32.887],
                                  [-117.074, -103.809],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-259.749, 192.151],
                                  [-5.225, -32.657],
                                  [-116.861, -104.314],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-260.987, 192.451],
                                  [-5.65, -32.453],
                                  [-116.61, -104.795],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-262.138, 192.721],
                                  [-6.07, -32.253],
                                  [-116.386, -105.239],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-263.268, 193.01],
                                  [-6.449, -32.082],
                                  [-116.228, -105.686],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.294, 193.246],
                                  [-6.853, -31.903],
                                  [-116.037, -106.111],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.305, 193.501],
                                  [-7.222, -31.755],
                                  [-115.876, -106.541],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.264, 193.729],
                                  [-7.659, -31.615],
                                  [-115.707, -106.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-267.165, 193.914],
                                  [-8.026, -31.508],
                                  [-115.587, -107.316],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-268.056, 194.072],
                                  [-8.443, -31.396],
                                  [-115.46, -107.701],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-268.878, 194.25],
                                  [-8.794, -31.319],
                                  [-115.344, -108.094],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-269.67, 194.401],
                                  [-9.182, -31.236],
                                  [-115.286, -108.449],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-270.419, 194.55],
                                  [-9.586, -31.167],
                                  [-115.2, -108.796],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-271.118, 194.674],
                                  [-9.968, -31.133],
                                  [-115.127, -109.13],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-271.815, 194.795],
                                  [-10.391, -31.096],
                                  [-115.113, -109.45],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-272.512, 194.874],
                                  [-10.794, -31.096],
                                  [-115.113, -109.781],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-273.124, 194.99],
                                  [-11.218, -31.07],
                                  [-115.11, -110.082],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-273.674, 195.065],
                                  [-11.624, -31.105],
                                  [-115.105, -110.412],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-274.267, 195.115],
                                  [-12.036, -31.115],
                                  [-115.115, -110.691],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-274.8, 195.164],
                                  [-12.472, -31.164],
                                  [-115.164, -110.982],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-275.296, 195.188],
                                  [-12.875, -31.251],
                                  [-115.108, -111.24],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-275.778, 195.207],
                                  [-13.182, -31.377],
                                  [-114.929, -111.386],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-276.228, 195.222],
                                  [-13.461, -31.542],
                                  [-114.52, -111.46],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-276.623, 195.17],
                                  [-13.603, -31.828],
                                  [-113.987, -111.46],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.007, 195.115],
                                  [-13.693, -32.153],
                                  [-113.164, -111.348],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.296, 195.036],
                                  [-13.691, -32.56],
                                  [-112.132, -111.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.551, 194.933],
                                  [-13.574, -33.048],
                                  [-110.872, -110.768],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.816, 194.807],
                                  [-13.386, -33.659],
                                  [-109.298, -110.278],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.005, 194.596],
                                  [-13.063, -34.351],
                                  [-107.473, -109.698],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.101, 194.382],
                                  [-12.606, -35.126],
                                  [-105.274, -108.986],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.185, 194.168],
                                  [-12.035, -36.066],
                                  [-102.678, -108.06],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.194, 193.869],
                                  [-11.267, -37.172],
                                  [-99.685, -106.959],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.108, 193.507],
                                  [-10.343, -38.402],
                                  [-96.252, -105.666],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.989, 193.145],
                                  [-9.242, -39.84],
                                  [-92.276, -104.138],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.733, 192.722],
                                  [-7.923, -41.486],
                                  [-87.715, -102.334],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.401, 192.195],
                                  [-6.323, -43.341],
                                  [-82.463, -100.254],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-276.973, 191.567],
                                  [-4.461, -45.488],
                                  [-76.417, -97.856],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-276.426, 190.919],
                                  [-2.255, -47.969],
                                  [-69.451, -95.037],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-275.677, 190.129],
                                  [0.339, -50.826],
                                  [-61.396, -91.736],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-274.807, 189.219],
                                  [3.343, -54.103],
                                  [-52.107, -87.93],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-273.731, 188.169],
                                  [6.924, -57.924],
                                  [-41.374, -83.516],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-272.406, 186.96],
                                  [11.105, -62.332],
                                  [-28.884, -78.349],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-270.829, 185.552],
                                  [16.014, -67.411],
                                  [-14.384, -72.346],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-268.933, 183.949],
                                  [21.695, -73.289],
                                  [2.295, -65.404],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.694, 182.153],
                                  [28.153, -79.966],
                                  [21.238, -57.481],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.211, 180.167],
                                  [35.348, -87.32],
                                  [42.115, -48.766],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-261.564, 178.098],
                                  [43.013, -95.102],
                                  [64.198, -39.549],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-258.838, 175.986],
                                  [50.73, -102.978],
                                  [86.487, -30.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-256.182, 174.019],
                                  [58.185, -110.53],
                                  [107.918, -21.324],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-253.643, 172.212],
                                  [65.184, -117.589],
                                  [127.967, -12.981],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.351, 170.584],
                                  [71.621, -124.072],
                                  [146.276, -5.387],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.186, 169.109],
                                  [77.447, -129.975],
                                  [162.885, 1.502],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.256, 167.848],
                                  [82.703, -135.298],
                                  [177.916, 7.684],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.503, 166.674],
                                  [87.509, -140.122],
                                  [191.577, 13.285],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.865, 165.648],
                                  [91.886, -144.53],
                                  [203.927, 18.389],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.366, 164.726],
                                  [95.873, -148.564],
                                  [215.258, 23.016],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-240.987, 163.909],
                                  [99.572, -152.263],
                                  [225.631, 27.23],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-239.707, 163.173],
                                  [102.963, -155.671],
                                  [235.15, 31.092],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-238.509, 162.519],
                                  [106.064, -158.785],
                                  [243.96, 34.645],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-237.391, 161.926],
                                  [108.958, -161.689],
                                  [252.102, 37.93],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.355, 161.392],
                                  [111.666, -164.426],
                                  [259.639, 41.009],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-235.339, 160.855],
                                  [114.188, -166.952],
                                  [266.694, 43.821],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.448, 160.44],
                                  [116.542, -169.31],
                                  [273.248, 46.469],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-233.557, 160],
                                  [118.75, -171.498],
                                  [279.403, 48.911],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-232.729, 159.598],
                                  [120.833, -173.56],
                                  [285.139, 51.169],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-231.986, 159.255],
                                  [122.769, -175.536],
                                  [290.56, 53.346],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-231.223, 158.949],
                                  [124.599, -177.342],
                                  [295.625, 55.36],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-230.524, 158.66],
                                  [126.325, -179.063],
                                  [300.373, 57.251],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-229.848, 158.388],
                                  [127.986, -180.699],
                                  [304.89, 59.02],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-229.257, 158.153],
                                  [129.499, -182.249],
                                  [309.133, 60.708],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-228.669, 157.872],
                                  [130.969, -183.67],
                                  [313.122, 62.253],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-228.103, 157.691],
                                  [132.353, -185.048],
                                  [316.858, 63.738],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.539, 157.484],
                                  [133.631, -186.34],
                                  [320.446, 65.101],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.041, 157.314],
                                  [134.845, -187.545],
                                  [323.801, 66.425],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-226.567, 157.14],
                                  [136.014, -188.707],
                                  [326.986, 67.647],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-226.116, 156.961],
                                  [137.119, -189.783],
                                  [329.98, 68.831],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-225.646, 156.82],
                                  [138.179, -190.814],
                                  [332.806, 69.912],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-225.264, 156.716],
                                  [139.154, -191.802],
                                  [335.502, 70.975],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.843, 156.565],
                                  [140.063, -192.745],
                                  [338.049, 71.977],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.486, 156.473],
                                  [140.97, -193.602],
                                  [340.427, 72.857],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.113, 156.334],
                                  [141.791, -194.415],
                                  [342.675, 73.719],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.782, 156.254],
                                  [142.589, -195.226],
                                  [344.817, 74.583],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.478, 156.17],
                                  [143.322, -195.951],
                                  [346.851, 75.324],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.154, 156.059],
                                  [144.031, -196.631],
                                  [348.737, 76.047],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.896, 156.029],
                                  [144.674, -197.31],
                                  [350.515, 76.772],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.6, 155.91],
                                  [145.316, -197.902],
                                  [352.206, 77.373],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.369, 155.871],
                                  [145.892, -198.492],
                                  [353.79, 77.977],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.12, 155.806],
                                  [146.445, -199.038],
                                  [355.268, 78.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.895, 155.759],
                                  [146.953, -199.54],
                                  [356.658, 79.088],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.674, 155.686],
                                  [147.439, -200.04],
                                  [357.983, 79.615],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.476, 155.63],
                                  [147.9, -200.453],
                                  [359.159, 80.019],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.322, 155.591],
                                  [148.317, -200.907],
                                  [360.333, 80.487],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.13, 155.569],
                                  [148.711, -201.275],
                                  [361.358, 80.874],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.962, 155.522],
                                  [149.061, -201.641],
                                  [362.337, 81.263],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.838, 155.492],
                                  [149.387, -201.962],
                                  [363.253, 81.591],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.675, 155.459],
                                  [149.712, -202.283],
                                  [364.081, 81.879],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.557, 155.443],
                                  [150.013, -202.558],
                                  [364.802, 82.191],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.443, 155.401],
                                  [150.27, -202.79],
                                  [365.521, 82.441],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.33, 155.377],
                                  [150.503, -203.02],
                                  [366.133, 82.652],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.241, 155.37],
                                  [150.692, -203.249],
                                  [366.7, 82.906],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.196, 155.36],
                                  [150.9, -203.433],
                                  [367.203, 83.056],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.071, 155.367],
                                  [151.064, -203.573],
                                  [367.619, 83.231],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.032, 155.349],
                                  [151.184, -203.712],
                                  [368.033, 83.365],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.974, 155.329],
                                  [151.323, -203.849],
                                  [368.34, 83.501],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.941, 155.325],
                                  [151.417, -203.942],
                                  [368.603, 83.618],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.867, 155.32],
                                  [151.531, -204.034],
                                  [368.844, 83.694],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.853, 155.317],
                                  [151.626, -204.127],
                                  [369.107, 83.768],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.818, 155.304],
                                  [151.557, -204.163],
                                  [369.076, 83.818],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.852, 155.184],
                                  [151.337, -204.154],
                                  [368.676, 83.707],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.869, 155.04],
                                  [150.906, -204.102],
                                  [368.012, 83.559],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.914, 154.854],
                                  [150.323, -204.024],
                                  [366.999, 83.377],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.008, 154.575],
                                  [149.486, -203.943],
                                  [365.637, 83.098],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.07, 154.201],
                                  [148.474, -203.854],
                                  [363.942, 82.705],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.166, 153.749],
                                  [147.201, -203.712],
                                  [361.828, 82.262],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.235, 153.213],
                                  [145.646, -203.556],
                                  [359.273, 81.71],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.385, 152.609],
                                  [143.808, -203.403],
                                  [356.252, 81.05],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.556, 151.89],
                                  [141.664, -203.164],
                                  [352.701, 80.308],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.689, 151.026],
                                  [139.169, -202.876],
                                  [348.575, 79.423],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.872, 150.053],
                                  [136.323, -202.595],
                                  [343.81, 78.336],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.088, 148.918],
                                  [133.02, -202.228],
                                  [338.342, 77.135],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.321, 147.632],
                                  [129.219, -201.808],
                                  [332.086, 75.717],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.596, 146.141],
                                  [124.941, -201.3],
                                  [324.876, 74.067],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.857, 144.452],
                                  [120.021, -200.69],
                                  [316.714, 72.208],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.172, 142.57],
                                  [114.381, -200.041],
                                  [307.334, 69.998],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.526, 140.394],
                                  [108.007, -199.224],
                                  [296.684, 67.417],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.84, 137.903],
                                  [100.706, -198.25],
                                  [284.512, 64.403],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.201, 135.095],
                                  [92.374, -197.098],
                                  [270.554, 60.89],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.506, 131.948],
                                  [82.901, -195.715],
                                  [254.587, 56.7],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.83, 128.338],
                                  [72.075, -194.036],
                                  [236.332, 51.728],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.052, 124.256],
                                  [59.79, -192.002],
                                  [215.527, 45.79],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.17, 119.716],
                                  [45.982, -189.504],
                                  [192.044, 38.756],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.092, 114.737],
                                  [30.768, -186.466],
                                  [166.057, 30.509],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.728, 109.516],
                                  [14.611, -182.882],
                                  [138.161, 21.03],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.035, 104.29],
                                  [-1.786, -178.777],
                                  [109.664, 10.556],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.041, 99.323],
                                  [-17.555, -174.372],
                                  [81.974, -0.466],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.679, 94.844],
                                  [-31.974, -169.746],
                                  [56.305, -11.533],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.132, 90.917],
                                  [-44.711, -165.152],
                                  [33.337, -22.278],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.421, 87.618],
                                  [-55.772, -160.668],
                                  [13.161, -32.473],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.646, 84.852],
                                  [-65.331, -156.456],
                                  [-4.403, -42.04],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.852, 82.543],
                                  [-73.49, -152.453],
                                  [-19.718, -50.999],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.041, 80.604],
                                  [-80.512, -148.695],
                                  [-33.09, -59.276],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.263, 78.963],
                                  [-86.606, -145.151],
                                  [-44.821, -67.024],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.501, 77.623],
                                  [-91.864, -141.843],
                                  [-55.153, -74.255],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.791, 76.47],
                                  [-96.489, -138.729],
                                  [-64.307, -81.024],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.159, 75.527],
                                  [-100.548, -135.767],
                                  [-72.456, -87.331],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.566, 74.697],
                                  [-104.13, -133.008],
                                  [-79.763, -93.277],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.025, 74.018],
                                  [-107.285, -130.381],
                                  [-86.349, -98.844],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.524, 73.402],
                                  [-110.122, -127.906],
                                  [-92.268, -104.078],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.1, 72.927],
                                  [-112.624, -125.608],
                                  [-97.629, -109.031],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.735, 72.483],
                                  [-114.89, -123.352],
                                  [-102.507, -113.696],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.406, 72.12],
                                  [-116.901, -121.261],
                                  [-106.942, -118.094],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.144, 71.858],
                                  [-118.75, -119.291],
                                  [-111.007, -122.28],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.898, 71.596],
                                  [-120.382, -117.409],
                                  [-114.708, -126.252],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.73, 71.355],
                                  [-121.892, -115.581],
                                  [-118.141, -129.977],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.587, 71.195],
                                  [-123.221, -113.888],
                                  [-121.278, -133.569],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.495, 71.04],
                                  [-124.464, -112.241],
                                  [-124.151, -136.94],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.464, 70.927],
                                  [-125.561, -110.694],
                                  [-126.863, -140.143],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.491, 70.842],
                                  [-126.576, -109.25],
                                  [-129.311, -143.147],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.521, 70.754],
                                  [-127.504, -107.829],
                                  [-131.604, -146.048],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.578, 70.721],
                                  [-128.355, -106.507],
                                  [-133.728, -148.779],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.734, 70.686],
                                  [-129.121, -105.237],
                                  [-135.659, -151.404],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.892, 70.663],
                                  [-129.804, -104.053],
                                  [-137.494, -153.883],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.089, 70.633],
                                  [-130.432, -102.926],
                                  [-139.193, -156.261],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.345, 70.609],
                                  [-131.041, -101.818],
                                  [-140.728, -158.465],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.581, 70.608],
                                  [-131.568, -100.754],
                                  [-142.225, -160.592],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.886, 70.619],
                                  [-132.077, -99.805],
                                  [-143.561, -162.605],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.226, 70.63],
                                  [-132.533, -98.859],
                                  [-144.824, -164.503],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.575, 70.66],
                                  [-132.925, -97.999],
                                  [-146.016, -166.296],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.996, 70.669],
                                  [-133.335, -97.141],
                                  [-147.135, -168.009],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.417, 70.711],
                                  [-133.683, -96.354],
                                  [-148.12, -169.642],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.912, 70.73],
                                  [-134.013, -95.613],
                                  [-149.086, -171.169],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.381, 70.726],
                                  [-134.279, -94.869],
                                  [-149.954, -172.614],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.863, 70.786],
                                  [-134.528, -94.231],
                                  [-150.776, -173.979],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.445, 70.821],
                                  [-134.811, -93.587],
                                  [-151.516, -175.262],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.003, 70.83],
                                  [-135.03, -92.985],
                                  [-152.246, -176.474],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.6, 70.875],
                                  [-135.221, -92.411],
                                  [-152.893, -177.605],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.174, 70.892],
                                  [-135.42, -91.903],
                                  [-153.493, -178.69],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.849, 70.933],
                                  [-135.59, -91.423],
                                  [-154.046, -179.657],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.501, 70.956],
                                  [-135.756, -90.969],
                                  [-154.576, -180.615],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.193, 70.976],
                                  [-135.893, -90.515],
                                  [-155.059, -181.466],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.924, 71.003],
                                  [-136, -90.124],
                                  [-155.52, -182.271],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.633, 71.025],
                                  [-136.14, -89.756],
                                  [-155.894, -182.994],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.407, 71.079],
                                  [-136.238, -89.412],
                                  [-156.245, -183.672],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.158, 71.077],
                                  [-136.342, -89.091],
                                  [-156.609, -184.305],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.949, 71.106],
                                  [-136.44, -88.829],
                                  [-156.886, -184.893],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.756, 71.129],
                                  [-136.469, -88.564],
                                  [-157.163, -185.41],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.59, 71.132],
                                  [-136.555, -88.319],
                                  [-157.414, -185.883],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.402, 71.153],
                                  [-136.646, -88.095],
                                  [-157.615, -186.274],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.316, 71.153],
                                  [-136.666, -87.89],
                                  [-157.776, -186.657],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.171, 71.184],
                                  [-136.705, -87.742],
                                  [-157.948, -186.959],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.103, 71.181],
                                  [-136.772, -87.612],
                                  [-158.117, -187.253],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.976, 71.183],
                                  [-136.769, -87.501],
                                  [-158.196, -187.504],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.913, 71.177],
                                  [-136.794, -87.382],
                                  [-158.271, -187.647],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.866, 71.212],
                                  [-136.797, -87.343],
                                  [-158.355, -187.81],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.846, 71.201],
                                  [-136.803, -87.258],
                                  [-158.396, -187.903],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.517, 0.517, 0.517, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.517, 0.517, 0.517, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-143.378, -58.238],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.299, -58.166],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.206, -58.062],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.07, -57.907],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.847, -57.686],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.566, -57.397],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.268, -57.061],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-141.873, -56.653],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-141.37, -56.163],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.848, -55.581],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.202, -54.924],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-139.421, -54.181],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.534, -53.306],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.497, -52.345],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.341, -51.228],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.972, -49.971],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.395, -48.551],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.601, -46.953],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.49, -45.162],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.026, -43.13],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.134, -40.843],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.817, -38.265],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.898, -35.376],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.323, -32.161],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.101, -28.66],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.244, -24.947],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.017, -21.18],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.712, -17.475],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.641, -14.012],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.02, -10.861],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.929, -8.017],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.429, -5.436],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.442, -3.149],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.953, -1.067],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56.958, 0.852],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.359, 2.606],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.17, 4.248],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.316, 5.742],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.806, 7.158],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.571, 8.509],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.658, 9.777],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.05, 11.015],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.688, 12.178],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.588, 13.322],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.781, 14.431],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.218, 15.508],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.933, 16.577],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.852, 17.619],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.037, 18.644],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.418, 19.678],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.006, 20.699],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.735, 21.689],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.575, 22.689],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.51, 23.651],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.456, 24.62],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.44, 25.564],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.316, 26.45],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.204, 27.317],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.948, 28.137],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.663, 28.946],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.254, 29.698],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.768, 30.439],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.135, 31.113],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.495, 31.77],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.749, 32.384],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.928, 32.978],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.056, 33.538],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.083, 34.045],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.094, 34.526],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.008, 34.994],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.946, 35.434],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.77, 35.835],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.614, 36.219],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.414, 36.578],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.156, 36.901],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.882, 37.213],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.57, 37.501],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.306, 37.788],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.949, 38.016],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.628, 38.242],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.225, 38.438],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.828, 38.62],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.44, 38.802],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.019, 38.949],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.617, 39.09],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.197, 39.189],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.763, 39.314],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.296, 39.385],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.849, 39.466],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.395, 39.516],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.945, 39.566],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.48, 39.635],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.073, 39.752],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.61, 39.811],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.221, 39.94],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.828, 40.084],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.435, 40.241],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.151, 40.442],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.836, 40.632],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.527, 40.877],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.333, 41.181],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.143, 41.487],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.985, 41.834],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.936, 42.254],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.937, 42.727],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.98, 43.25],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.142, 43.833],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.413, 44.528],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.771, 45.297],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.286, 46.173],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.853, 47.103],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.474, 48.09],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.858, 48.885],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.778, 48.389],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.479, 43.277],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.831, 33.386],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.144, 21.646],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.951, 9.378],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.135, -2.528],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.088, -13.72],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-38.07, -23.998],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.042, -33.383],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.02, -41.841],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.822, -49.532],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-3.367, -56.533],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.431, -62.94],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.672, -68.801],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.391, -74.187],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.651, -79.14],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.534, -83.741],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.072, -88.037],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.315, -92.043],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.234, -95.741],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.92, -99.206],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.367, -102.449],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.596, -105.537],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.637, -108.385],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.494, -111.082],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.205, -113.626],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.716, -116.035],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.115, -118.281],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.369, -120.406],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.495, -122.428],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.486, -124.309],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.387, -126.108],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.19, -127.801],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.898, -129.394],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.49, -130.915],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.016, -132.392],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.423, -133.687],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.776, -134.968],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.073, -136.21],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.26, -137.333],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.404, -138.397],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.458, -139.425],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.473, -140.353],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.399, -141.244],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.29, -142.086],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.109, -142.857],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.911, -143.635],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.616, -144.267],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.306, -144.958],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.928, -145.523],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.518, -146.097],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.041, -146.595],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.547, -147.069],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.002, -147.481],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.417, -147.857],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.783, -148.202],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.12, -148.555],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.404, -148.81],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.674, -149.036],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.887, -149.259],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.093, -149.455],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.248, -149.602],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.409, -149.732],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.499, -149.822],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.545, -149.864],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.539, -149.941],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.33, -149.935],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.963, -149.913],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.437, -149.852],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.692, -149.806],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.789, -149.754],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.66, -149.669],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.299, -149.587],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.664, -149.497],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.755, -149.341],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.555, -149.166],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.004, -148.968],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.07, -148.729],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.693, -148.45],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.842, -148.063],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.462, -147.63],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.407, -147.132],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.662, -146.468],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.098, -145.67],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.564, -144.665],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.953, -143.371],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.077, -141.744],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.81, -139.657],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.055, -136.935],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.071, -133.474],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.236, -129.09],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.761, -123.794],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.833, -117.786],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.724, -111.206],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.092, -104.4],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.884, -97.521],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.219, -90.817],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.229, -84.248],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-121.093, -77.948],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.032, -71.883],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.134, -66.071],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.57, -60.532],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.43, -55.212],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.784, -50.177],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.696, -45.354],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-149.224, -40.832],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.399, -36.587],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.253, -32.665],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.666, -29.403],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.407, -27.57],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.236, -27.803],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.675, -29.069],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.977, -30.623],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.293, -32.221],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-152.62, -33.788],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-152.004, -35.286],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.39, -36.757],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.816, -38.136],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.286, -39.469],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-149.767, -40.74],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-149.278, -41.969],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.851, -43.104],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.403, -44.2],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.026, -45.235],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.658, -46.211],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.296, -47.131],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.994, -48.019],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.688, -48.847],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.423, -49.636],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.123, -50.394],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.859, -51.084],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.664, -51.735],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.437, -52.369],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.223, -52.943],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.032, -53.506],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.871, -53.995],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.71, -54.491],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.554, -54.927],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.402, -55.344],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.29, -55.714],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.174, -56.04],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.065, -56.382],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.981, -56.677],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.853, -56.941],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.786, -57.186],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.734, -57.385],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.65, -57.583],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.591, -57.732],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.573, -57.888],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.496, -58.018],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.465, -58.095],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.435, -58.165],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.409, -58.215],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-143.378, -58.238],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.299, -58.166],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.206, -58.062],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.07, -57.907],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.847, -57.686],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.566, -57.397],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.268, -57.061],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-141.873, -56.653],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-141.37, -56.163],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.848, -55.581],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.202, -54.924],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-139.421, -54.181],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.534, -53.306],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.497, -52.345],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.341, -51.228],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.972, -49.971],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.395, -48.551],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.601, -46.953],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.49, -45.162],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.026, -43.13],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.134, -40.843],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.817, -38.265],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.898, -35.376],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.323, -32.161],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.101, -28.66],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.244, -24.947],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.017, -21.18],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.712, -17.475],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.641, -14.012],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.02, -10.861],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.929, -8.017],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.429, -5.436],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.442, -3.149],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.953, -1.067],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56.958, 0.852],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.359, 2.606],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.17, 4.248],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.316, 5.742],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.806, 7.158],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.571, 8.509],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.658, 9.777],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.05, 11.015],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.688, 12.178],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.588, 13.322],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.781, 14.431],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.218, 15.508],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.933, 16.577],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.852, 17.619],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.037, 18.644],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.418, 19.678],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.006, 20.699],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.735, 21.689],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.575, 22.689],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.51, 23.651],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.456, 24.62],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.44, 25.564],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.316, 26.45],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.204, 27.317],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.948, 28.137],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.663, 28.946],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.254, 29.698],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.768, 30.439],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.135, 31.113],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.495, 31.77],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.749, 32.384],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.928, 32.978],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.056, 33.538],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.083, 34.045],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.094, 34.526],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.008, 34.994],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.946, 35.434],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.77, 35.835],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.614, 36.219],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.414, 36.578],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.156, 36.901],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.882, 37.213],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.57, 37.501],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.306, 37.788],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.949, 38.016],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.628, 38.242],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.225, 38.438],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.828, 38.62],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.44, 38.802],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.019, 38.949],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.617, 39.09],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.197, 39.189],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.763, 39.314],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.296, 39.385],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.849, 39.466],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.395, 39.516],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.945, 39.566],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.48, 39.635],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.073, 39.752],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.61, 39.811],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.221, 39.94],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.828, 40.084],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.435, 40.241],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.151, 40.442],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.836, 40.632],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.527, 40.877],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.333, 41.181],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.143, 41.487],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.985, 41.834],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.936, 42.254],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.937, 42.727],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.98, 43.25],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.142, 43.833],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.413, 44.528],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.771, 45.297],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.286, 46.173],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.853, 47.103],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.474, 48.09],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.858, 48.885],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.778, 48.389],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.479, 43.277],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.831, 33.386],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.144, 21.646],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.951, 9.378],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.135, -2.528],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.088, -13.72],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-38.07, -23.998],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.042, -33.383],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.02, -41.841],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.822, -49.532],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-3.367, -56.533],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.431, -62.94],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.672, -68.801],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.391, -74.187],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.651, -79.14],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.534, -83.741],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.072, -88.037],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.315, -92.043],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.234, -95.741],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.92, -99.206],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.367, -102.449],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.596, -105.537],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.637, -108.385],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.494, -111.082],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.205, -113.626],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.716, -116.035],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.115, -118.281],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.369, -120.406],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.495, -122.428],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.486, -124.309],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.387, -126.108],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.19, -127.801],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.898, -129.394],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.49, -130.915],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.016, -132.392],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.423, -133.687],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.776, -134.968],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.073, -136.21],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.26, -137.333],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.404, -138.397],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.458, -139.425],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.473, -140.353],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.399, -141.244],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.29, -142.086],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.109, -142.857],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.911, -143.635],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.616, -144.267],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.306, -144.958],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.928, -145.523],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.518, -146.097],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.041, -146.595],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.547, -147.069],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.002, -147.481],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.417, -147.857],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.783, -148.202],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.12, -148.555],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.404, -148.81],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.674, -149.036],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.887, -149.259],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.093, -149.455],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.248, -149.602],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.409, -149.732],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.499, -149.822],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.545, -149.864],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.539, -149.941],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.33, -149.935],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.963, -149.913],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.437, -149.852],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.692, -149.806],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.789, -149.754],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.66, -149.669],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.299, -149.587],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.664, -149.497],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.755, -149.341],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.555, -149.166],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.004, -148.968],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.07, -148.729],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.693, -148.45],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.842, -148.063],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.462, -147.63],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.407, -147.132],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.662, -146.468],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.098, -145.67],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.564, -144.665],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.953, -143.371],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.077, -141.744],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.81, -139.657],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.055, -136.935],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.071, -133.474],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.236, -129.09],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.761, -123.794],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.833, -117.786],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.724, -111.206],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.092, -104.4],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.884, -97.521],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.219, -90.817],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.229, -84.248],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-121.093, -77.948],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.032, -71.883],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.134, -66.071],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.57, -60.532],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.43, -55.212],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.784, -50.177],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.696, -45.354],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-149.224, -40.832],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.399, -36.587],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.253, -32.665],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.666, -29.403],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.407, -27.57],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.236, -27.803],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.675, -29.069],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.977, -30.623],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.293, -32.221],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-152.62, -33.788],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-152.004, -35.286],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.39, -36.757],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.816, -38.136],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.286, -39.469],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-149.767, -40.74],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-149.278, -41.969],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.851, -43.104],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.403, -44.2],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.026, -45.235],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.658, -46.211],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.296, -47.131],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.994, -48.019],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.688, -48.847],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.423, -49.636],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.123, -50.394],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.859, -51.084],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.664, -51.735],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.437, -52.369],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.223, -52.943],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.032, -53.506],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.871, -53.995],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.71, -54.491],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.554, -54.927],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.402, -55.344],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.29, -55.714],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.174, -56.04],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.065, -56.382],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.981, -56.677],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.853, -56.941],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.786, -57.186],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.734, -57.385],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.65, -57.583],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.591, -57.732],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.573, -57.888],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.496, -58.018],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.465, -58.095],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.435, -58.165],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.409, -58.215],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 10',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 10,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.023, 64.004],
                                  [-107.994, -55.995],
                                  [-119.999, -199.952],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.005, 64.112],
                                  [-107.887, -55.994],
                                  [-120.044, -199.882],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.99, 64.224],
                                  [-107.751, -56.027],
                                  [-120.043, -199.744],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.956, 64.381],
                                  [-107.566, -56.013],
                                  [-120.123, -199.518],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.966, 64.604],
                                  [-107.249, -56.012],
                                  [-120.137, -199.223],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.916, 64.893],
                                  [-106.863, -56.006],
                                  [-120.19, -198.819],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.911, 65.227],
                                  [-106.428, -56.015],
                                  [-120.263, -198.347],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.867, 65.668],
                                  [-105.884, -55.999],
                                  [-120.354, -197.806],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.806, 66.176],
                                  [-105.189, -55.956],
                                  [-120.465, -197.135],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.77, 66.772],
                                  [-104.447, -55.93],
                                  [-120.555, -196.334],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.718, 67.475],
                                  [-103.535, -55.899],
                                  [-120.644, -195.445],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.671, 68.266],
                                  [-102.431, -55.864],
                                  [-120.773, -194.405],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.568, 69.185],
                                  [-101.199, -55.762],
                                  [-120.881, -193.173],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.514, 70.234],
                                  [-99.736, -55.676],
                                  [-121.009, -191.834],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.385, 71.433],
                                  [-98.104, -55.564],
                                  [-121.114, -190.243],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.307, 72.823],
                                  [-96.159, -55.404],
                                  [-121.216, -188.442],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.159, 74.404],
                                  [-93.941, -55.175],
                                  [-121.314, -186.391],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.024, 76.239],
                                  [-91.389, -54.935],
                                  [-121.426, -184.073],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.865, 78.286],
                                  [-88.399, -54.602],
                                  [-121.486, -181.364],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.706, 80.689],
                                  [-84.909, -54.149],
                                  [-121.532, -178.29],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.489, 83.469],
                                  [-80.814, -53.634],
                                  [-121.496, -174.773],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.264, 86.668],
                                  [-76.113, -52.97],
                                  [-121.412, -170.693],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.034, 90.346],
                                  [-70.577, -52.147],
                                  [-121.228, -165.995],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.748, 94.585],
                                  [-64.164, -51.114],
                                  [-120.955, -160.628],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.454, 99.383],
                                  [-56.872, -49.907],
                                  [-120.601, -154.557],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.119, 104.657],
                                  [-48.784, -48.518],
                                  [-120.078, -147.936],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.848, 110.241],
                                  [-40.237, -47.03],
                                  [-119.53, -141.014],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.656, 115.844],
                                  [-31.648, -45.454],
                                  [-118.905, -134.037],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.479, 121.259],
                                  [-23.441, -43.942],
                                  [-118.298, -127.392],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.455, 126.281],
                                  [-15.848, -42.548],
                                  [-117.745, -121.219],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.519, 130.908],
                                  [-8.978, -41.278],
                                  [-117.212, -115.697],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.694, 135.104],
                                  [-2.855, -40.119],
                                  [-116.745, -110.681],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.961, 138.889],
                                  [2.6, -39.11],
                                  [-116.367, -106.253],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.322, 142.344],
                                  [7.428, -38.195],
                                  [-116.04, -102.309],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.798, 145.492],
                                  [11.666, -37.368],
                                  [-115.764, -98.771],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.379, 148.397],
                                  [15.456, -36.655],
                                  [-115.517, -95.639],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.007, 151.059],
                                  [18.754, -36.01],
                                  [-115.279, -92.855],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.784, 153.497],
                                  [21.68, -35.458],
                                  [-115.129, -90.381],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.643, 155.758],
                                  [24.23, -34.957],
                                  [-114.991, -88.176],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.603, 157.897],
                                  [26.482, -34.502],
                                  [-114.878, -86.226],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.677, 159.861],
                                  [28.408, -34.119],
                                  [-114.778, -84.533],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.887, 161.73],
                                  [30.01, -33.75],
                                  [-114.748, -82.996],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.183, 163.479],
                                  [31.374, -33.464],
                                  [-114.704, -81.768],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.61, 165.134],
                                  [32.466, -33.172],
                                  [-114.711, -80.662],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.173, 166.72],
                                  [33.294, -32.954],
                                  [-114.723, -79.767],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.864, 168.213],
                                  [33.878, -32.76],
                                  [-114.766, -79.087],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.723, 169.625],
                                  [34.214, -32.592],
                                  [-114.815, -78.546],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.677, 170.996],
                                  [34.365, -32.474],
                                  [-114.872, -78.184],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.796, 172.286],
                                  [34.272, -32.381],
                                  [-114.948, -77.999],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.062, 173.545],
                                  [34.004, -32.285],
                                  [-115.03, -77.986],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.434, 174.745],
                                  [33.547, -32.201],
                                  [-115.134, -78.074],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.89, 175.875],
                                  [32.937, -32.153],
                                  [-115.212, -78.333],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.43, 176.976],
                                  [32.242, -32.084],
                                  [-115.261, -78.633],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.988, 178.003],
                                  [31.418, -32.034],
                                  [-115.319, -79.082],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.571, 179.022],
                                  [30.56, -31.936],
                                  [-115.376, -79.598],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.137, 179.968],
                                  [29.654, -31.846],
                                  [-115.374, -80.131],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.602, 180.838],
                                  [28.789, -31.74],
                                  [-115.37, -80.693],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.039, 181.693],
                                  [27.884, -31.647],
                                  [-115.307, -81.338],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.396, 182.468],
                                  [27.077, -31.503],
                                  [-115.28, -81.898],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.606, 183.259],
                                  [26.244, -31.38],
                                  [-115.168, -82.521],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.755, 183.94],
                                  [25.48, -31.214],
                                  [-115.071, -83.111],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.781, 184.607],
                                  [24.754, -31.044],
                                  [-114.931, -83.663],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.715, 185.229],
                                  [24.122, -30.871],
                                  [-114.813, -84.241],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-218.53, 185.807],
                                  [23.455, -30.7],
                                  [-114.661, -84.748],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.291, 186.341],
                                  [22.872, -30.533],
                                  [-114.478, -85.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.942, 186.865],
                                  [22.306, -30.344],
                                  [-114.33, -85.779],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.516, 187.349],
                                  [21.749, -30.164],
                                  [-114.16, -86.304],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.988, 187.79],
                                  [21.26, -29.999],
                                  [-113.998, -86.765],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-226.425, 188.194],
                                  [20.771, -29.849],
                                  [-113.82, -87.225],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.738, 188.59],
                                  [20.307, -29.654],
                                  [-113.689, -87.655],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-229.053, 188.978],
                                  [19.834, -29.51],
                                  [-113.542, -88.086],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-230.253, 189.331],
                                  [19.411, -29.355],
                                  [-113.417, -88.522],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-231.433, 189.645],
                                  [18.971, -29.221],
                                  [-113.251, -88.934],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-232.533, 189.923],
                                  [18.54, -29.082],
                                  [-113.106, -89.319],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-233.618, 190.226],
                                  [18.149, -28.968],
                                  [-113.019, -89.712],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.6, 190.467],
                                  [17.732, -28.845],
                                  [-112.894, -90.085],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-235.572, 190.731],
                                  [17.35, -28.749],
                                  [-112.794, -90.467],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.503, 190.964],
                                  [16.903, -28.652],
                                  [-112.687, -90.826],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-237.372, 191.158],
                                  [16.521, -28.583],
                                  [-112.614, -91.167],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-238.236, 191.321],
                                  [16.098, -28.509],
                                  [-112.535, -91.52],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-239.036, 191.509],
                                  [15.736, -28.464],
                                  [-112.457, -91.886],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-239.808, 191.666],
                                  [15.336, -28.416],
                                  [-112.436, -92.209],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-240.545, 191.819],
                                  [14.925, -28.37],
                                  [-112.384, -92.539],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.229, 191.943],
                                  [14.536, -28.355],
                                  [-112.335, -92.855],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.916, 192.065],
                                  [14.105, -28.337],
                                  [-112.345, -93.159],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.607, 192.149],
                                  [13.694, -28.352],
                                  [-112.359, -93.478],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.214, 192.266],
                                  [13.266, -28.336],
                                  [-112.371, -93.777],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.764, 192.345],
                                  [12.856, -28.381],
                                  [-112.381, -94.1],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-244.357, 192.396],
                                  [12.435, -28.396],
                                  [-112.396, -94.377],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-244.895, 192.445],
                                  [11.995, -28.445],
                                  [-112.445, -94.67],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.406, 192.465],
                                  [11.578, -28.528],
                                  [-112.394, -94.927],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.917, 192.466],
                                  [11.248, -28.645],
                                  [-112.235, -95.076],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-246.42, 192.448],
                                  [10.923, -28.796],
                                  [-111.85, -95.153],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-246.886, 192.35],
                                  [10.72, -29.054],
                                  [-111.355, -95.141],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.37, 192.234],
                                  [10.549, -29.348],
                                  [-110.59, -95.023],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.782, 192.075],
                                  [10.448, -29.712],
                                  [-109.624, -94.773],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-248.184, 191.874],
                                  [10.442, -30.149],
                                  [-108.449, -94.426],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-248.629, 191.63],
                                  [10.48, -30.694],
                                  [-106.975, -93.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.027, 191.283],
                                  [10.623, -31.312],
                                  [-105.263, -93.319],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.368, 190.906],
                                  [10.874, -32.003],
                                  [-103.206, -92.587],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.74, 190.499],
                                  [11.205, -32.84],
                                  [-100.775, -91.635],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.08, 189.974],
                                  [11.69, -33.823],
                                  [-97.97, -90.501],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.376, 189.359],
                                  [12.295, -34.918],
                                  [-94.755, -89.173],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.69, 188.702],
                                  [13.028, -36.196],
                                  [-91.029, -87.601],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.939, 187.94],
                                  [13.929, -37.659],
                                  [-86.755, -85.748],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.181, 187.024],
                                  [15.046, -39.308],
                                  [-81.833, -83.615],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.407, 185.954],
                                  [16.353, -41.216],
                                  [-76.163, -81.149],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.617, 184.79],
                                  [17.925, -43.42],
                                  [-69.634, -78.254],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.738, 183.408],
                                  [19.787, -45.959],
                                  [-62.083, -74.865],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.869, 181.823],
                                  [21.95, -48.869],
                                  [-53.372, -70.959],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.949, 179.979],
                                  [24.549, -52.263],
                                  [-43.302, -66.421],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.968, 177.855],
                                  [27.599, -56.178],
                                  [-31.587, -61.117],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.95, 175.386],
                                  [31.199, -60.69],
                                  [-17.989, -54.957],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.843, 172.563],
                                  [35.374, -65.911],
                                  [-2.343, -47.83],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.631, 169.387],
                                  [40.128, -71.843],
                                  [15.426, -39.698],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.382, 165.877],
                                  [45.443, -78.376],
                                  [35.012, -30.751],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.095, 162.19],
                                  [51.115, -85.29],
                                  [55.734, -21.289],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.747, 158.461],
                                  [56.83, -92.29],
                                  [76.649, -11.738],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.379, 154.933],
                                  [62.359, -99.003],
                                  [96.762, -2.576],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.976, 151.669],
                                  [67.565, -105.28],
                                  [115.573, 5.985],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.628, 148.706],
                                  [72.357, -111.046],
                                  [132.758, 13.783],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.228, 146.028],
                                  [76.696, -116.299],
                                  [148.35, 20.856],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-248.894, 143.682],
                                  [80.615, -121.037],
                                  [162.46, 27.204],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-248.58, 141.526],
                                  [84.206, -125.333],
                                  [175.282, 32.953],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-248.256, 139.607],
                                  [87.481, -129.26],
                                  [186.88, 38.19],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.948, 137.873],
                                  [90.46, -132.855],
                                  [197.523, 42.941],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.66, 136.309],
                                  [93.241, -136.154],
                                  [207.261, 47.27],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.384, 134.888],
                                  [95.784, -139.193],
                                  [216.206, 51.237],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.108, 133.609],
                                  [98.112, -141.971],
                                  [224.479, 54.881],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-246.846, 132.433],
                                  [100.285, -144.564],
                                  [232.131, 58.253],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-246.602, 131.359],
                                  [102.329, -147.007],
                                  [239.211, 61.414],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-246.325, 130.324],
                                  [104.228, -149.264],
                                  [245.842, 64.303],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-246.117, 129.439],
                                  [106.007, -151.37],
                                  [252, 67.018],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.867, 128.567],
                                  [107.677, -153.326],
                                  [257.783, 69.524],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.638, 127.757],
                                  [109.251, -155.17],
                                  [263.179, 71.845],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.455, 127.032],
                                  [110.715, -156.937],
                                  [268.274, 74.081],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.22, 126.369],
                                  [112.106, -158.553],
                                  [273.032, 76.144],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.02, 125.742],
                                  [113.411, -160.094],
                                  [277.501, 78.085],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-244.807, 125.15],
                                  [114.68, -161.558],
                                  [281.745, 79.903],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-244.655, 124.619],
                                  [115.825, -162.945],
                                  [285.736, 81.636],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-244.477, 124.059],
                                  [116.945, -164.219],
                                  [289.489, 83.221],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-244.299, 123.61],
                                  [118.003, -165.453],
                                  [293.003, 84.747],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-244.108, 123.157],
                                  [118.973, -166.611],
                                  [296.378, 86.15],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.955, 122.752],
                                  [119.893, -167.692],
                                  [299.538, 87.505],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.803, 122.356],
                                  [120.787, -168.734],
                                  [302.533, 88.762],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.663, 121.97],
                                  [121.631, -169.699],
                                  [305.351, 89.972],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.487, 121.63],
                                  [122.449, -170.624],
                                  [308.005, 91.085],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.375, 121.337],
                                  [123.191, -171.51],
                                  [310.544, 92.174],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.214, 121.015],
                                  [123.882, -172.357],
                                  [312.943, 93.204],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.105, 120.753],
                                  [124.584, -173.126],
                                  [315.178, 94.11],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.959, 120.461],
                                  [125.209, -173.856],
                                  [317.297, 94.994],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.852, 120.23],
                                  [125.821, -174.584],
                                  [319.314, 95.88],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.748, 120.006],
                                  [126.382, -175.235],
                                  [321.228, 96.643],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.619, 119.767],
                                  [126.928, -175.847],
                                  [323.003, 97.383],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.543, 119.612],
                                  [127.422, -176.456],
                                  [324.675, 98.126],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.418, 119.379],
                                  [127.916, -176.988],
                                  [326.27, 98.745],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.346, 119.229],
                                  [128.357, -177.519],
                                  [327.762, 99.366],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.25, 119.064],
                                  [128.784, -178.01],
                                  [329.151, 99.965],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.169, 118.92],
                                  [129.172, -178.461],
                                  [330.463, 100.503],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.078, 118.759],
                                  [129.546, -178.911],
                                  [331.71, 101.043],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.001, 118.621],
                                  [129.905, -179.283],
                                  [332.817, 101.46],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.963, 118.504],
                                  [130.225, -179.691],
                                  [333.922, 101.941],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.878, 118.408],
                                  [130.531, -180.022],
                                  [334.886, 102.337],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.807, 118.297],
                                  [130.797, -180.351],
                                  [335.811, 102.735],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.776, 118.207],
                                  [131.049, -180.641],
                                  [336.671, 103.072],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.697, 118.114],
                                  [131.299, -180.929],
                                  [337.453, 103.373],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.657, 118.043],
                                  [131.535, -181.177],
                                  [338.133, 103.688],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.608, 117.956],
                                  [131.732, -181.386],
                                  [338.81, 103.944],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.56, 117.891],
                                  [131.914, -181.593],
                                  [339.386, 104.162],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.527, 117.847],
                                  [132.057, -181.799],
                                  [339.921, 104.421],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.533, 117.8],
                                  [132.223, -181.965],
                                  [340.392, 104.58],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.454, 117.776],
                                  [132.35, -182.091],
                                  [340.785, 104.755],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.452, 117.735],
                                  [132.437, -182.216],
                                  [341.176, 104.893],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.427, 117.691],
                                  [132.548, -182.34],
                                  [341.465, 105.033],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.417, 117.67],
                                  [132.62, -182.424],
                                  [341.714, 105.15],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.371, 117.645],
                                  [132.715, -182.506],
                                  [341.936, 105.23],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.385, 117.625],
                                  [132.787, -182.591],
                                  [342.186, 105.309],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.354, 117.612],
                                  [132.718, -182.621],
                                  [342.163, 105.354],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.375, 117.504],
                                  [132.524, -182.602],
                                  [341.794, 105.226],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.371, 117.39],
                                  [132.134, -182.534],
                                  [341.194, 105.047],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.381, 117.232],
                                  [131.62, -182.441],
                                  [340.272, 104.82],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.431, 116.999],
                                  [130.869, -182.333],
                                  [339.028, 104.483],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.436, 116.689],
                                  [129.966, -182.207],
                                  [337.483, 104.015],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.462, 116.308],
                                  [128.834, -182.023],
                                  [335.559, 103.479],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.447, 115.865],
                                  [127.448, -181.815],
                                  [333.231, 102.815],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.496, 115.368],
                                  [125.806, -181.604],
                                  [330.483, 102.026],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.548, 114.771],
                                  [123.893, -181.296],
                                  [327.251, 101.127],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.544, 114.056],
                                  [121.672, -180.925],
                                  [323.495, 100.058],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.564, 113.253],
                                  [119.141, -180.545],
                                  [319.163, 98.76],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.597, 112.315],
                                  [116.201, -180.073],
                                  [314.193, 97.311],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.612, 111.257],
                                  [112.826, -179.522],
                                  [308.51, 95.614],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.637, 110.03],
                                  [109.031, -178.869],
                                  [301.966, 93.634],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.616, 108.645],
                                  [104.668, -178.098],
                                  [294.563, 91.399],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.603, 107.113],
                                  [99.67, -177.263],
                                  [286.06, 88.761],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.577, 105.341],
                                  [94.033, -176.226],
                                  [276.42, 85.683],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.455, 103.324],
                                  [87.587, -175.006],
                                  [265.412, 82.086],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.299, 101.066],
                                  [80.243, -173.575],
                                  [252.809, 77.904],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.011, 98.559],
                                  [71.915, -171.865],
                                  [238.411, 72.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-240.642, 95.701],
                                  [62.43, -169.813],
                                  [221.99, 67.057],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-240.057, 92.5],
                                  [51.697, -167.351],
                                  [203.31, 60.061],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-239.236, 88.988],
                                  [39.682, -164.371],
                                  [182.28, 51.805],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-238.081, 85.191],
                                  [26.505, -160.8],
                                  [159.077, 42.163],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.527, 81.288],
                                  [12.594, -156.642],
                                  [134.26, 31.142],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.575, 77.474],
                                  [-1.432, -151.947],
                                  [109.008, 19.031],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-232.299, 73.946],
                                  [-14.816, -146.977],
                                  [84.572, 6.367],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-229.705, 70.867],
                                  [-26.959, -141.833],
                                  [62.023, -6.288],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.004, 68.255],
                                  [-37.596, -136.778],
                                  [41.943, -18.501],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.246, 66.162],
                                  [-46.753, -131.898],
                                  [24.373, -30.038],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.53, 64.497],
                                  [-54.606, -127.347],
                                  [9.15, -40.802],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-218.885, 63.18],
                                  [-61.242, -123.053],
                                  [-4.076, -50.842],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.302, 62.142],
                                  [-66.904, -119.052],
                                  [-15.584, -60.085],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.833, 61.321],
                                  [-71.775, -115.3],
                                  [-25.637, -68.708],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.45, 60.729],
                                  [-75.936, -111.812],
                                  [-34.458, -76.726],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.17, 60.254],
                                  [-79.569, -108.539],
                                  [-42.252, -84.206],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.018, 59.936],
                                  [-82.723, -105.444],
                                  [-49.165, -91.165],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.953, 59.68],
                                  [-85.486, -102.568],
                                  [-55.345, -97.704],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.972, 59.532],
                                  [-87.895, -99.839],
                                  [-60.901, -103.816],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.074, 59.408],
                                  [-90.042, -97.273],
                                  [-65.879, -109.546],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.274, 59.387],
                                  [-91.914, -94.9],
                                  [-70.375, -114.957],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.566, 59.366],
                                  [-93.598, -92.575],
                                  [-74.452, -120.05],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.924, 59.395],
                                  [-95.076, -90.43],
                                  [-78.146, -124.844],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.363, 59.5],
                                  [-96.43, -88.407],
                                  [-81.531, -129.392],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.842, 59.584],
                                  [-97.602, -86.478],
                                  [-84.599, -133.703],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.414, 59.663],
                                  [-98.683, -84.613],
                                  [-87.445, -137.748],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.034, 59.807],
                                  [-99.614, -82.88],
                                  [-90.034, -141.633],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.715, 59.932],
                                  [-100.484, -81.204],
                                  [-92.395, -145.284],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.476, 60.086],
                                  [-101.234, -79.629],
                                  [-94.633, -148.743],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.304, 60.25],
                                  [-101.929, -78.167],
                                  [-96.632, -151.986],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.154, 60.395],
                                  [-102.553, -76.722],
                                  [-98.514, -155.112],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.035, 60.581],
                                  [-103.124, -75.385],
                                  [-100.249, -158.056],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.028, 60.757],
                                  [-103.628, -74.098],
                                  [-101.814, -160.88],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.032, 60.928],
                                  [-104.064, -72.899],
                                  [-103.313, -163.541],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.09, 61.084],
                                  [-104.457, -71.762],
                                  [-104.69, -166.097],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.206, 61.229],
                                  [-104.846, -70.647],
                                  [-105.924, -168.466],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.315, 61.395],
                                  [-105.17, -69.573],
                                  [-107.141, -170.749],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.502, 61.564],
                                  [-105.492, -68.619],
                                  [-108.215, -172.906],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.728, 61.714],
                                  [-105.771, -67.663],
                                  [-109.231, -174.936],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.972, 61.881],
                                  [-105.989, -66.802],
                                  [-110.191, -176.856],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.297, 62.023],
                                  [-106.247, -65.937],
                                  [-111.095, -178.689],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.622, 62.183],
                                  [-106.443, -65.146],
                                  [-111.879, -180.434],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.029, 62.316],
                                  [-106.638, -64.406],
                                  [-112.65, -182.068],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.415, 62.42],
                                  [-106.771, -63.657],
                                  [-113.342, -183.613],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.821, 62.58],
                                  [-106.903, -63.02],
                                  [-113.999, -185.069],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.332, 62.709],
                                  [-107.076, -62.371],
                                  [-114.58, -186.436],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.823, 62.807],
                                  [-107.187, -61.771],
                                  [-115.167, -187.733],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.357, 62.936],
                                  [-107.277, -61.197],
                                  [-115.676, -188.94],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.872, 63.033],
                                  [-107.386, -60.691],
                                  [-116.15, -190.098],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.493, 63.14],
                                  [-107.473, -60.21],
                                  [-116.586, -191.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.094, 63.233],
                                  [-107.56, -59.755],
                                  [-117.008, -192.145],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.739, 63.314],
                                  [-107.623, -59.302],
                                  [-117.392, -193.053],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.428, 63.401],
                                  [-107.665, -58.913],
                                  [-117.76, -193.912],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.098, 63.475],
                                  [-107.745, -58.547],
                                  [-118.048, -194.681],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.833, 63.576],
                                  [-107.782, -58.203],
                                  [-118.319, -195.402],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.549, 63.62],
                                  [-107.836, -57.88],
                                  [-118.614, -196.073],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.31, 63.691],
                                  [-107.888, -57.619],
                                  [-118.829, -196.696],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.093, 63.747],
                                  [-107.873, -57.356],
                                  [-119.046, -197.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.901, 63.787],
                                  [-107.917, -57.113],
                                  [-119.244, -197.755],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.689, 63.831],
                                  [-107.978, -56.889],
                                  [-119.401, -198.17],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.585, 63.86],
                                  [-107.972, -56.684],
                                  [-119.518, -198.578],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.42, 63.913],
                                  [-107.982, -56.537],
                                  [-119.656, -198.897],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.341, 63.93],
                                  [-108.028, -56.408],
                                  [-119.794, -199.209],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.202, 63.95],
                                  [-108.006, -56.296],
                                  [-119.849, -199.473],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.128, 63.953],
                                  [-108.02, -56.179],
                                  [-119.902, -199.627],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.078, 64.002],
                                  [-108.007, -56.14],
                                  [-119.975, -199.796],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.05, 63.992],
                                  [-108.008, -56.054],
                                  [-120.004, -199.896],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.55, 0.55, 0.55, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.55, 0.55, 0.55, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-108.421, -61.112],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.311, -61.017],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.166, -60.88],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.974, -60.669],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.641, -60.364],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.234, -59.982],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.769, -59.523],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.187, -58.973],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.443, -58.311],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.631, -57.536],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.626, -56.646],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.539, -55.66],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.821, -54.592],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.963, -53.397],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.026, -52.041],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.888, -50.485],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.576, -48.742],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.092, -46.779],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.322, -44.57],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.239, -42.066],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.79, -39.269],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.977, -36.143],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.629, -32.663],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.674, -28.868],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.125, -24.811],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.954, -20.644],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.359, -16.586],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.595, -12.776],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.942, -9.387],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.619, -6.454],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.721, -3.924],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-38.322, -1.718],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.362, 0.154],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.853, 1.813],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.806, 3.304],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.12, 4.638],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.829, 5.866],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.855, 6.963],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.21, 7.997],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.833, 8.98],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.767, 9.897],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.007, 10.804],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.481, 11.647],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.221, 12.496],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.253, 13.324],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.529, 14.133],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.083, 14.95],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.839, 15.751],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.868, 16.555],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.1, 17.384],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.535, 18.218],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.142, 19.039],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.856, 19.885],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.68, 20.703],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.528, 21.545],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.429, 22.379],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.238, 23.169],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.081, 23.954],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.773, 24.699],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-36.461, 25.445],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-38.035, 26.148],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.542, 26.841],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.905, 27.477],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.269, 28.105],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-43.531, 28.692],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.723, 29.265],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.872, 29.805],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.92, 30.302],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.959, 30.776],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.9, 31.238],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.867, 31.677],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.725, 32.078],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.608, 32.469],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.442, 32.834],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.22, 33.167],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.986, 33.488],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.715, 33.788],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.489, 34.088],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56.172, 34.332],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56.885, 34.573],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.521, 34.787],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.163, 34.983],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.812, 35.184],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.424, 35.349],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.057, 35.507],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.674, 35.628],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.271, 35.774],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.837, 35.864],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.425, 35.969],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.003, 36.042],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.594, 36.116],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.169, 36.198],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.831, 36.331],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.44, 36.403],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.146, 36.542],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.866, 36.69],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.595, 36.843],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.454, 37.034],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.305, 37.209],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.193, 37.434],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.222, 37.708],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.277, 37.964],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.403, 38.254],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.673, 38.595],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.03, 38.963],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.483, 39.355],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.106, 39.773],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.883, 40.236],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.81, 40.708],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.943, 41.188],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.161, 41.567],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.413, 41.776],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.358, 41.492],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.143, 39.965],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.129, 35.856],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.144, 28.259],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.167, 18.122],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.808, 6.875],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.27, -4.303],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.1, -14.923],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.665, -24.709],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.991, -33.649],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.133, -41.706],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.951, -49.027],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.387, -55.69],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.626, -61.787],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.154, -67.349],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.231, -72.461],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.916, -77.155],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.271, -81.514],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.327, -85.578],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.131, -89.369],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.639, -92.862],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.958, -96.142],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.049, -99.202],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.958, -102.118],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.703, -104.808],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.268, -107.347],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.721, -109.749],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.002, -112.032],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.159, -114.139],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.188, -116.134],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.124, -118.049],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.935, -119.83],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.654, -121.522],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.275, -123.104],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.828, -124.605],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.281, -126.045],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.675, -127.438],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.954, -128.664],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.177, -129.863],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.346, -131.022],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.431, -132.084],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.475, -133.088],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.439, -134.055],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.363, -134.932],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.209, -135.774],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.022, -136.566],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.766, -137.294],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.495, -138.019],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.131, -138.612],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.764, -139.262],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.333, -139.796],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.873, -140.337],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.349, -140.803],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.813, -141.254],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.227, -141.643],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.606, -141.997],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.94, -142.321],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.25, -142.652],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.506, -142.892],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.753, -143.107],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.947, -143.315],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.136, -143.5],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.278, -143.639],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.426, -143.761],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.506, -143.847],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.548, -143.886],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.545, -143.957],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.349, -143.935],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.006, -143.887],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.522, -143.798],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.828, -143.707],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.988, -143.596],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.942, -143.439],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.68, -143.269],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.163, -143.084],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.392, -142.813],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.352, -142.494],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.986, -142.136],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.262, -141.715],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.135, -141.217],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.56, -140.584],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.501, -139.869],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.815, -139.053],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.485, -138.013],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.401, -136.784],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.437, -135.305],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.465, -133.452],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.312, -131.162],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.879, -128.324],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-3.923, -124.762],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.006, -120.347],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.053, -114.956],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.455, -108.627],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.456, -101.631],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.39, -94.171],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.939, -86.612],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.039, -79.133],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.786, -71.974],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.309, -65.087],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.736, -58.615],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.255, -52.538],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126.914, -46.906],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.803, -41.812],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.914, -37.332],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.167, -33.738],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.447, -31.214],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.723, -29.989],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.188, -29.837],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.126, -30.424],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.791, -31.427],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.358, -32.636],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.844, -33.963],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.365, -35.32],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.89, -36.681],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.479, -38.022],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126.12, -39.313],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.855, -40.557],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.599, -41.785],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.426, -42.949],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-121.306, -44.073],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.233, -45.147],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.203, -46.199],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.256, -47.181],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.312, -48.125],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.48, -49.026],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.665, -49.874],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.882, -50.68],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.173, -51.458],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.479, -52.194],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.852, -52.891],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.192, -53.553],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.618, -54.17],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.112, -54.751],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.586, -55.32],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.092, -55.828],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.653, -56.342],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.255, -56.778],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.864, -57.229],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.491, -57.617],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.143, -57.981],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.856, -58.33],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.817, -58.656],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.926, -59.037],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.026, -59.371],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.057, -59.66],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.145, -59.94],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.245, -60.186],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.273, -60.382],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.312, -60.559],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.384, -60.725],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.385, -60.869],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.417, -60.962],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.415, -61.037],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.429, -61.092],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-108.421, -61.112],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.311, -61.017],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.166, -60.88],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.974, -60.669],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.641, -60.364],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.234, -59.982],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.769, -59.523],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.187, -58.973],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.443, -58.311],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.631, -57.536],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.626, -56.646],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.539, -55.66],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.821, -54.592],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.963, -53.397],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.026, -52.041],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.888, -50.485],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.576, -48.742],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.092, -46.779],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.322, -44.57],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.239, -42.066],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.79, -39.269],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.977, -36.143],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.629, -32.663],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.674, -28.868],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.125, -24.811],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.954, -20.644],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.359, -16.586],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.595, -12.776],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.942, -9.387],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.619, -6.454],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.721, -3.924],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-38.322, -1.718],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.362, 0.154],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.853, 1.813],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.806, 3.304],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.12, 4.638],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.829, 5.866],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.855, 6.963],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.21, 7.997],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.833, 8.98],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.767, 9.897],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.007, 10.804],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.481, 11.647],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.221, 12.496],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.253, 13.324],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.529, 14.133],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.083, 14.95],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.839, 15.751],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.868, 16.555],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.1, 17.384],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.535, 18.218],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.142, 19.039],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.856, 19.885],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.68, 20.703],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.528, 21.545],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.429, 22.379],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.238, 23.169],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.081, 23.954],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.773, 24.699],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-36.461, 25.445],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-38.035, 26.148],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.542, 26.841],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.905, 27.477],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.269, 28.105],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-43.531, 28.692],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.723, 29.265],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.872, 29.805],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.92, 30.302],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.959, 30.776],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.9, 31.238],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.867, 31.677],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.725, 32.078],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.608, 32.469],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.442, 32.834],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.22, 33.167],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.986, 33.488],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.715, 33.788],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.489, 34.088],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56.172, 34.332],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56.885, 34.573],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.521, 34.787],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.163, 34.983],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.812, 35.184],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.424, 35.349],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.057, 35.507],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.674, 35.628],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.271, 35.774],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.837, 35.864],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.425, 35.969],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.003, 36.042],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.594, 36.116],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.169, 36.198],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.831, 36.331],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.44, 36.403],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.146, 36.542],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.866, 36.69],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.595, 36.843],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.454, 37.034],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.305, 37.209],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.193, 37.434],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.222, 37.708],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.277, 37.964],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.403, 38.254],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.673, 38.595],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.03, 38.963],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.483, 39.355],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.106, 39.773],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.883, 40.236],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.81, 40.708],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.943, 41.188],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.161, 41.567],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.413, 41.776],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.358, 41.492],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.143, 39.965],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.129, 35.856],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.144, 28.259],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.167, 18.122],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.808, 6.875],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.27, -4.303],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.1, -14.923],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.665, -24.709],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.991, -33.649],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.133, -41.706],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.951, -49.027],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.387, -55.69],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.626, -61.787],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.154, -67.349],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.231, -72.461],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.916, -77.155],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.271, -81.514],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.327, -85.578],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.131, -89.369],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.639, -92.862],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.958, -96.142],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.049, -99.202],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.958, -102.118],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.703, -104.808],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.268, -107.347],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.721, -109.749],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.002, -112.032],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.159, -114.139],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.188, -116.134],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.124, -118.049],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.935, -119.83],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.654, -121.522],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.275, -123.104],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.828, -124.605],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.281, -126.045],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.675, -127.438],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.954, -128.664],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.177, -129.863],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.346, -131.022],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.431, -132.084],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.475, -133.088],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.439, -134.055],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.363, -134.932],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.209, -135.774],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.022, -136.566],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.766, -137.294],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.495, -138.019],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.131, -138.612],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.764, -139.262],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.333, -139.796],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.873, -140.337],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.349, -140.803],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.813, -141.254],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.227, -141.643],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.606, -141.997],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.94, -142.321],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.25, -142.652],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.506, -142.892],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.753, -143.107],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.947, -143.315],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.136, -143.5],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.278, -143.639],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.426, -143.761],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.506, -143.847],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.548, -143.886],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.545, -143.957],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.349, -143.935],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.006, -143.887],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.522, -143.798],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.828, -143.707],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.988, -143.596],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.942, -143.439],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.68, -143.269],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.163, -143.084],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.392, -142.813],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.352, -142.494],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.986, -142.136],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.262, -141.715],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.135, -141.217],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.56, -140.584],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.501, -139.869],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.815, -139.053],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.485, -138.013],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.401, -136.784],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.437, -135.305],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.465, -133.452],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.312, -131.162],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.879, -128.324],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-3.923, -124.762],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.006, -120.347],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.053, -114.956],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.455, -108.627],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.456, -101.631],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.39, -94.171],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.939, -86.612],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.039, -79.133],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.786, -71.974],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.309, -65.087],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.736, -58.615],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.255, -52.538],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126.914, -46.906],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.803, -41.812],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.914, -37.332],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.167, -33.738],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.447, -31.214],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.723, -29.989],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.188, -29.837],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.126, -30.424],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.791, -31.427],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.358, -32.636],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.844, -33.963],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.365, -35.32],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.89, -36.681],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.479, -38.022],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126.12, -39.313],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.855, -40.557],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.599, -41.785],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.426, -42.949],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-121.306, -44.073],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.233, -45.147],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.203, -46.199],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.256, -47.181],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.312, -48.125],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.48, -49.026],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.665, -49.874],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.882, -50.68],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.173, -51.458],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.479, -52.194],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.852, -52.891],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.192, -53.553],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.618, -54.17],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.112, -54.751],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.586, -55.32],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.092, -55.828],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.653, -56.342],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.255, -56.778],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.864, -57.229],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.491, -57.617],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.143, -57.981],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.856, -58.33],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.817, -58.656],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.926, -59.037],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.026, -59.371],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.057, -59.66],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.145, -59.94],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.245, -60.186],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.273, -60.382],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.312, -60.559],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.384, -60.725],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.385, -60.869],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.417, -60.962],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.415, -61.037],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.429, -61.092],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 11',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 11,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.219, 56.803],
                                  [-79.197, -24.798],
                                  [-81.602, -211.948],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.193, 56.916],
                                  [-79.088, -24.812],
                                  [-81.665, -211.867],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.155, 57.031],
                                  [-78.957, -24.866],
                                  [-81.693, -211.705],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.091, 57.195],
                                  [-78.775, -24.883],
                                  [-81.811, -211.448],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.063, 57.424],
                                  [-78.464, -24.925],
                                  [-81.879, -211.11],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.961, 57.719],
                                  [-78.085, -24.977],
                                  [-82.004, -210.647],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.896, 58.063],
                                  [-77.657, -25.054],
                                  [-82.158, -210.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.775, 58.518],
                                  [-77.13, -25.124],
                                  [-82.355, -209.481],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.628, 59.04],
                                  [-76.446, -25.175],
                                  [-82.593, -208.715],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.489, 59.656],
                                  [-75.725, -25.266],
                                  [-82.829, -207.795],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.312, 60.384],
                                  [-74.833, -25.379],
                                  [-83.09, -206.767],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.128, 61.195],
                                  [-73.751, -25.502],
                                  [-83.424, -205.567],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.861, 62.147],
                                  [-72.555, -25.586],
                                  [-83.767, -204.152],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.622, 63.227],
                                  [-71.126, -25.721],
                                  [-84.164, -202.6],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.273, 64.466],
                                  [-69.539, -25.862],
                                  [-84.583, -200.759],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.954, 65.907],
                                  [-67.639, -25.99],
                                  [-85.05, -198.675],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.517, 67.54],
                                  [-65.486, -26.09],
                                  [-85.566, -196.303],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.054, 69.438],
                                  [-63.005, -26.244],
                                  [-86.169, -193.606],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.523, 71.556],
                                  [-60.1, -26.362],
                                  [-86.794, -190.459],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.932, 74.046],
                                  [-56.709, -26.435],
                                  [-87.497, -186.883],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.21, 76.927],
                                  [-52.736, -26.534],
                                  [-88.233, -182.778],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.395, 80.241],
                                  [-48.181, -26.587],
                                  [-89.043, -178.012],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.492, 84.052],
                                  [-42.821, -26.6],
                                  [-89.91, -172.518],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.423, 88.45],
                                  [-36.611, -26.544],
                                  [-90.853, -166.227],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.241, 93.431],
                                  [-29.55, -26.446],
                                  [-91.891, -159.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.918, 98.906],
                                  [-21.728, -26.287],
                                  [-92.903, -151.319],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.601, 104.704],
                                  [-13.454, -26.109],
                                  [-93.991, -143.161],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.354, 110.517],
                                  [-5.147, -25.845],
                                  [-95.014, -134.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.147, 116.141],
                                  [2.801, -25.606],
                                  [-96.001, -127.083],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.155, 121.352],
                                  [10.155, -25.399],
                                  [-96.93, -119.772],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.326, 126.159],
                                  [16.815, -25.217],
                                  [-97.75, -113.208],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.676, 130.51],
                                  [22.76, -25.035],
                                  [-98.511, -107.24],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.181, 134.432],
                                  [28.068, -24.913],
                                  [-99.244, -101.944],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.839, 138.016],
                                  [32.77, -24.791],
                                  [-99.918, -97.212],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.671, 141.279],
                                  [36.908, -24.69],
                                  [-100.546, -92.946],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.643, 144.286],
                                  [40.619, -24.632],
                                  [-101.124, -89.146],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.695, 147.038],
                                  [43.857, -24.591],
                                  [-101.635, -85.74],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.937, 149.56],
                                  [46.741, -24.588],
                                  [-102.18, -82.684],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.277, 151.892],
                                  [49.267, -24.589],
                                  [-102.673, -79.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.744, 154.102],
                                  [51.511, -24.603],
                                  [-103.149, -77.473],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.337, 156.124],
                                  [53.448, -24.651],
                                  [-103.587, -75.294],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.085, 158.044],
                                  [55.069, -24.673],
                                  [-104.057, -73.296],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.927, 159.845],
                                  [56.476, -24.763],
                                  [-104.479, -71.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.911, 161.547],
                                  [57.618, -24.813],
                                  [-104.92, -70.097],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.042, 163.17],
                                  [58.513, -24.91],
                                  [-105.339, -68.795],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.302, 164.695],
                                  [59.175, -25.015],
                                  [-105.76, -67.722],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.737, 166.144],
                                  [59.603, -25.129],
                                  [-106.163, -66.795],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.265, 167.537],
                                  [59.856, -25.273],
                                  [-106.548, -66.057],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.966, 168.853],
                                  [59.876, -25.425],
                                  [-106.936, -65.507],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.814, 170.134],
                                  [59.73, -25.562],
                                  [-107.305, -65.142],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.775, 171.356],
                                  [59.408, -25.686],
                                  [-107.692, -64.891],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.83, 172.499],
                                  [58.929, -25.85],
                                  [-108.032, -64.811],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.976, 173.618],
                                  [58.372, -25.972],
                                  [-108.324, -64.784],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.147, 174.654],
                                  [57.682, -26.108],
                                  [-108.621, -64.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.367, 175.684],
                                  [56.961, -26.192],
                                  [-108.903, -65.138],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.577, 176.643],
                                  [56.179, -26.267],
                                  [-109.122, -65.383],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.704, 177.527],
                                  [55.431, -26.327],
                                  [-109.324, -65.666],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.828, 178.398],
                                  [54.633, -26.393],
                                  [-109.462, -66.051],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.883, 179.179],
                                  [53.931, -26.393],
                                  [-109.627, -66.365],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.812, 179.985],
                                  [53.192, -26.417],
                                  [-109.696, -66.752],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.696, 180.677],
                                  [52.507, -26.382],
                                  [-109.774, -67.12],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.483, 181.355],
                                  [51.859, -26.351],
                                  [-109.801, -67.466],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.187, 181.985],
                                  [51.296, -26.305],
                                  [-109.845, -67.851],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.785, 182.577],
                                  [50.69, -26.247],
                                  [-109.846, -68.177],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.345, 183.122],
                                  [50.169, -26.201],
                                  [-109.808, -68.568],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.805, 183.657],
                                  [49.649, -26.114],
                                  [-109.798, -68.873],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.207, 184.147],
                                  [49.142, -26.042],
                                  [-109.755, -69.247],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.518, 184.602],
                                  [48.696, -25.971],
                                  [-109.719, -69.565],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.804, 185.014],
                                  [48.245, -25.912],
                                  [-109.657, -69.89],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.973, 185.419],
                                  [47.82, -25.803],
                                  [-109.633, -70.197],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.159, 185.817],
                                  [47.382, -25.736],
                                  [-109.594, -70.515],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.233, 186.174],
                                  [46.989, -25.658],
                                  [-109.562, -70.843],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.297, 186.498],
                                  [46.576, -25.598],
                                  [-109.484, -71.15],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.29, 186.782],
                                  [46.175, -25.522],
                                  [-109.424, -71.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.276, 187.096],
                                  [45.81, -25.466],
                                  [-109.41, -71.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.163, 187.337],
                                  [45.416, -25.402],
                                  [-109.355, -72.038],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.048, 187.609],
                                  [45.055, -25.36],
                                  [-109.32, -72.343],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.905, 187.843],
                                  [44.633, -25.306],
                                  [-109.278, -72.636],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.696, 188.044],
                                  [44.267, -25.276],
                                  [-109.254, -72.913],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.491, 188.209],
                                  [43.869, -25.241],
                                  [-109.224, -73.209],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.229, 188.404],
                                  [43.526, -25.231],
                                  [-109.186, -73.523],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.941, 188.563],
                                  [43.142, -25.216],
                                  [-109.205, -73.791],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.63, 188.719],
                                  [42.752, -25.194],
                                  [-109.187, -74.082],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.263, 188.84],
                                  [42.382, -25.198],
                                  [-109.162, -74.361],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.906, 188.959],
                                  [41.969, -25.199],
                                  [-109.196, -74.628],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.559, 189.046],
                                  [41.574, -25.228],
                                  [-109.225, -74.916],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.13, 189.161],
                                  [41.167, -25.22],
                                  [-109.25, -75.196],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.651, 189.244],
                                  [40.777, -25.274],
                                  [-109.274, -75.495],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.215, 189.292],
                                  [40.369, -25.292],
                                  [-109.292, -75.754],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.731, 189.339],
                                  [39.947, -25.339],
                                  [-109.339, -76.035],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.232, 189.352],
                                  [39.537, -25.415],
                                  [-109.292, -76.275],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.751, 189.33],
                                  [39.201, -25.519],
                                  [-109.152, -76.413],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.288, 189.272],
                                  [38.843, -25.653],
                                  [-108.792, -76.48],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.813, 189.118],
                                  [38.589, -25.877],
                                  [-108.34, -76.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.388, 188.931],
                                  [38.346, -26.13],
                                  [-107.637, -76.305],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.919, 188.678],
                                  [38.145, -26.445],
                                  [-106.746, -76.034],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.468, 188.361],
                                  [38.016, -26.821],
                                  [-105.667, -75.662],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.098, 187.981],
                                  [37.898, -27.289],
                                  [-104.306, -75.127],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.715, 187.475],
                                  [37.853, -27.818],
                                  [-102.723, -74.491],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.319, 186.907],
                                  [37.881, -28.41],
                                  [-100.827, -73.725],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.004, 186.276],
                                  [37.951, -29.127],
                                  [-98.585, -72.733],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.706, 185.489],
                                  [38.127, -29.968],
                                  [-95.997, -71.548],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-218.425, 184.58],
                                  [38.376, -30.904],
                                  [-93.03, -70.17],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.222, 183.579],
                                  [38.701, -31.996],
                                  [-89.591, -68.537],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.036, 182.425],
                                  [39.133, -33.246],
                                  [-85.647, -66.619],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.926, 181.056],
                                  [39.703, -34.654],
                                  [-81.105, -64.414],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.892, 179.474],
                                  [40.382, -36.284],
                                  [-75.87, -61.862],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.964, 177.71],
                                  [41.233, -38.166],
                                  [-69.846, -58.869],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.076, 175.643],
                                  [42.258, -40.334],
                                  [-62.876, -55.372],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-225.353, 173.272],
                                  [43.46, -42.819],
                                  [-54.835, -51.341],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-226.758, 170.507],
                                  [44.934, -45.717],
                                  [-45.531, -46.651],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-228.321, 167.32],
                                  [46.683, -49.059],
                                  [-34.714, -41.179],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-230.098, 163.62],
                                  [48.775, -52.912],
                                  [-22.161, -34.83],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-232.054, 159.379],
                                  [51.213, -57.369],
                                  [-7.714, -27.48],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.184, 154.601],
                                  [54.001, -62.434],
                                  [8.694, -19.1],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.516, 149.319],
                                  [57.141, -68.012],
                                  [26.782, -9.876],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-238.96, 143.75],
                                  [60.506, -73.917],
                                  [45.923, -0.121],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.365, 138.139],
                                  [63.904, -79.896],
                                  [65.241, 9.728],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.647, 132.793],
                                  [67.201, -85.632],
                                  [83.821, 19.171],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.722, 127.831],
                                  [70.326, -90.997],
                                  [101.192, 27.992],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.629, 123.312],
                                  [73.213, -95.928],
                                  [117.067, 36.034],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.277, 119.229],
                                  [75.825, -100.421],
                                  [131.475, 43.328],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.796, 115.615],
                                  [78.189, -104.475],
                                  [144.507, 49.876],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-252.156, 112.31],
                                  [80.369, -108.152],
                                  [156.352, 55.802],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-253.358, 109.346],
                                  [82.362, -111.516],
                                  [167.069, 61.201],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.436, 106.659],
                                  [84.168, -114.595],
                                  [176.908, 66.103],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.42, 104.218],
                                  [85.879, -117.423],
                                  [185.902, 70.57],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-256.312, 101.991],
                                  [87.434, -120.028],
                                  [194.172, 74.666],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-257.111, 99.977],
                                  [88.861, -122.412],
                                  [201.816, 78.422],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-257.849, 98.115],
                                  [90.194, -124.637],
                                  [208.893, 81.9],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-258.528, 96.405],
                                  [91.461, -126.734],
                                  [215.435, 85.162],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-259.114, 94.782],
                                  [92.633, -128.672],
                                  [221.567, 88.146],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-259.704, 93.342],
                                  [93.74, -130.481],
                                  [227.258, 90.947],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-260.203, 91.959],
                                  [94.782, -132.163],
                                  [232.602, 93.531],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-260.674, 90.664],
                                  [95.759, -133.747],
                                  [237.597, 95.931],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-261.148, 89.489],
                                  [96.671, -135.267],
                                  [242.309, 98.24],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-261.531, 88.403],
                                  [97.547, -136.658],
                                  [246.704, 100.365],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-261.917, 87.373],
                                  [98.36, -137.983],
                                  [250.843, 102.368],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-262.245, 86.402],
                                  [99.169, -139.243],
                                  [254.762, 104.248],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-262.608, 85.517],
                                  [99.881, -140.438],
                                  [258.457, 106.038],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-262.912, 84.627],
                                  [100.59, -141.535],
                                  [261.929, 107.674],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-263.189, 83.858],
                                  [101.265, -142.599],
                                  [265.179, 109.251],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-263.436, 83.114],
                                  [101.873, -143.596],
                                  [268.3, 110.704],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-263.689, 82.427],
                                  [102.448, -144.528],
                                  [271.229, 112.1],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-263.914, 81.767],
                                  [103.019, -145.427],
                                  [273.999, 113.402],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.142, 81.132],
                                  [103.556, -146.259],
                                  [276.608, 114.647],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.312, 80.554],
                                  [104.088, -147.057],
                                  [279.058, 115.799],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.518, 80.035],
                                  [104.556, -147.822],
                                  [281.409, 116.923],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.664, 79.509],
                                  [104.989, -148.552],
                                  [283.632, 117.986],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.845, 79.042],
                                  [105.449, -149.217],
                                  [285.695, 118.926],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.968, 78.568],
                                  [105.845, -149.847],
                                  [287.66, 119.839],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.125, 78.154],
                                  [106.237, -150.476],
                                  [289.528, 120.753],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.256, 77.766],
                                  [106.595, -151.038],
                                  [291.299, 121.545],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.358, 77.372],
                                  [106.949, -151.567],
                                  [292.941, 122.308],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.496, 77.067],
                                  [107.267, -152.093],
                                  [294.486, 123.073],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.575, 76.695],
                                  [107.585, -152.553],
                                  [295.965, 123.716],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.689, 76.413],
                                  [107.867, -153.012],
                                  [297.346, 124.36],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.774, 76.126],
                                  [108.146, -153.437],
                                  [298.631, 124.976],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.863, 75.866],
                                  [108.391, -153.827],
                                  [299.849, 125.531],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.926, 75.6],
                                  [108.633, -154.216],
                                  [301.002, 126.088],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.992, 75.362],
                                  [108.871, -154.538],
                                  [302.026, 126.522],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.091, 75.151],
                                  [109.076, -154.891],
                                  [303.048, 127.02],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.132, 74.967],
                                  [109.277, -155.177],
                                  [303.941, 127.428],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.177, 74.778],
                                  [109.445, -155.462],
                                  [304.799, 127.837],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.256, 74.616],
                                  [109.609, -155.713],
                                  [305.593, 128.186],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.276, 74.451],
                                  [109.771, -155.962],
                                  [306.32, 128.503],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.329, 74.314],
                                  [109.93, -156.177],
                                  [306.95, 128.825],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.357, 74.171],
                                  [110.055, -156.358],
                                  [307.578, 129.086],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.386, 74.056],
                                  [110.177, -156.538],
                                  [308.109, 129.316],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.419, 73.968],
                                  [110.265, -156.716],
                                  [308.605, 129.581],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.486, 73.877],
                                  [110.381, -156.859],
                                  [309.037, 129.751],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.461, 73.813],
                                  [110.464, -156.969],
                                  [309.403, 129.926],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.504, 73.745],
                                  [110.513, -157.078],
                                  [309.766, 130.07],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.517, 73.674],
                                  [110.591, -157.184],
                                  [310.033, 130.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.534, 73.63],
                                  [110.635, -157.257],
                                  [310.265, 130.333],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.521, 73.583],
                                  [110.708, -157.329],
                                  [310.465, 130.419],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.568, 73.541],
                                  [110.753, -157.402],
                                  [310.698, 130.503],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.543, 73.528],
                                  [110.684, -157.427],
                                  [310.687, 130.543],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.547, 73.437],
                                  [110.524, -157.398],
                                  [310.357, 130.392],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.514, 73.364],
                                  [110.184, -157.315],
                                  [309.835, 130.174],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.478, 73.248],
                                  [109.754, -157.207],
                                  [309.025, 129.89],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.471, 73.08],
                                  [109.108, -157.074],
                                  [307.926, 129.48],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.402, 72.856],
                                  [108.339, -156.913],
                                  [306.565, 128.915],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.336, 72.576],
                                  [107.38, -156.69],
                                  [304.876, 128.26],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.213, 72.262],
                                  [106.2, -156.432],
                                  [302.827, 127.455],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.13, 71.911],
                                  [104.798, -156.166],
                                  [300.414, 126.502],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.028, 71.485],
                                  [103.17, -155.795],
                                  [297.572, 125.405],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.847, 70.976],
                                  [101.284, -155.345],
                                  [294.268, 124.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.655, 70.408],
                                  [99.138, -154.872],
                                  [290.467, 122.54],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.453, 69.741],
                                  [96.637, -154.303],
                                  [286.106, 120.78],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.187, 68.996],
                                  [93.781, -153.631],
                                  [281.12, 118.731],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.891, 68.13],
                                  [90.57, -152.843],
                                  [275.384, 116.338],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.508, 67.16],
                                  [86.882, -151.924],
                                  [268.901, 113.632],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.075, 66.104],
                                  [82.657, -150.919],
                                  [261.456, 110.462],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-263.566, 64.88],
                                  [77.903, -149.679],
                                  [253.028, 106.766],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-262.893, 63.502],
                                  [72.478, -148.238],
                                  [243.416, 102.45],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-262.087, 61.981],
                                  [66.308, -146.556],
                                  [232.427, 97.447],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-261.056, 60.327],
                                  [59.335, -144.555],
                                  [219.889, 91.529],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-259.823, 58.464],
                                  [51.426, -142.174],
                                  [205.625, 84.548],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-258.243, 56.418],
                                  [42.502, -139.342],
                                  [189.429, 76.277],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-256.274, 54.24],
                                  [32.558, -135.95],
                                  [171.239, 66.56],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-253.813, 51.963],
                                  [21.711, -131.935],
                                  [151.227, 55.269],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.833, 49.735],
                                  [10.34, -127.312],
                                  [129.9, 42.444],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.389, 47.698],
                                  [-1.04, -122.155],
                                  [108.28, 28.442],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.613, 45.958],
                                  [-11.796, -116.762],
                                  [87.436, 13.902],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-239.594, 44.596],
                                  [-21.465, -111.249],
                                  [68.289, -0.542],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-235.575, 43.581],
                                  [-29.85, -105.884],
                                  [51.313, -14.388],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-231.635, 42.938],
                                  [-36.991, -100.756],
                                  [36.509, -27.402],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.865, 42.582],
                                  [-43.06, -96.007],
                                  [23.742, -39.469],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.276, 42.434],
                                  [-48.119, -91.553],
                                  [12.682, -50.673],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.847, 42.45],
                                  [-52.391, -87.436],
                                  [3.088, -60.948],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.626, 42.582],
                                  [-56.021, -83.593],
                                  [-5.259, -70.497],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.571, 42.853],
                                  [-59.083, -80.034],
                                  [-12.559, -79.34],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.678, 43.154],
                                  [-61.728, -76.705],
                                  [-18.997, -87.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.971, 43.549],
                                  [-63.989, -73.573],
                                  [-24.684, -95.195],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.407, 43.944],
                                  [-65.951, -70.672],
                                  [-29.759, -102.344],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.961, 44.397],
                                  [-67.634, -67.927],
                                  [-34.31, -109.011],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.647, 44.823],
                                  [-69.116, -65.351],
                                  [-38.379, -115.245],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.454, 45.311],
                                  [-70.386, -62.979],
                                  [-42.043, -121.117],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.391, 45.763],
                                  [-71.516, -60.656],
                                  [-45.356, -126.64],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.424, 46.227],
                                  [-72.492, -58.525],
                                  [-48.349, -131.828],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.557, 46.738],
                                  [-73.38, -56.514],
                                  [-51.091, -136.737],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.754, 47.203],
                                  [-74.123, -54.598],
                                  [-53.568, -141.383],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.06, 47.634],
                                  [-74.806, -52.755],
                                  [-55.866, -145.743],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.44, 48.111],
                                  [-75.37, -51.037],
                                  [-57.948, -149.915],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.891, 48.542],
                                  [-75.899, -49.383],
                                  [-59.836, -153.838],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.441, 48.989],
                                  [-76.333, -47.829],
                                  [-61.64, -157.547],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.068, 49.423],
                                  [-76.736, -46.397],
                                  [-63.23, -161.021],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.737, 49.822],
                                  [-77.085, -44.971],
                                  [-64.74, -164.364],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.442, 50.244],
                                  [-77.406, -43.662],
                                  [-66.122, -167.513],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.273, 50.65],
                                  [-77.674, -42.398],
                                  [-67.358, -170.527],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.125, 51.029],
                                  [-77.891, -41.221],
                                  [-68.557, -173.362],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.043, 51.387],
                                  [-78.075, -40.111],
                                  [-69.648, -176.087],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.021, 51.713],
                                  [-78.27, -39.022],
                                  [-70.615, -178.612],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.005, 52.058],
                                  [-78.416, -37.97],
                                  [-71.582, -181.043],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.076, 52.395],
                                  [-78.573, -37.043],
                                  [-72.426, -183.336],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.19, 52.694],
                                  [-78.699, -36.105],
                                  [-73.226, -185.49],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.331, 53.009],
                                  [-78.766, -35.272],
                                  [-73.984, -187.53],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.562, 53.292],
                                  [-78.891, -34.426],
                                  [-74.699, -189.475],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.793, 53.579],
                                  [-78.956, -33.654],
                                  [-75.308, -191.323],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.114, 53.832],
                                  [-79.035, -32.939],
                                  [-75.91, -193.058],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.418, 54.052],
                                  [-79.054, -32.208],
                                  [-76.451, -194.696],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.751, 54.317],
                                  [-79.086, -31.593],
                                  [-76.967, -196.237],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.193, 54.546],
                                  [-79.166, -30.959],
                                  [-77.412, -197.681],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.62, 54.739],
                                  [-79.185, -30.378],
                                  [-77.876, -199.056],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.093, 54.957],
                                  [-79.189, -29.822],
                                  [-78.268, -200.333],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.551, 55.138],
                                  [-79.222, -29.334],
                                  [-78.633, -201.559],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.118, 55.315],
                                  [-79.24, -28.868],
                                  [-78.972, -202.641],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.671, 55.482],
                                  [-79.259, -28.425],
                                  [-79.3, -203.717],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.271, 55.627],
                                  [-79.261, -27.986],
                                  [-79.601, -204.678],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.919, 55.778],
                                  [-79.247, -27.613],
                                  [-79.891, -205.587],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.551, 55.906],
                                  [-79.278, -27.259],
                                  [-80.107, -206.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.249, 56.057],
                                  [-79.264, -26.925],
                                  [-80.311, -207.157],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.932, 56.15],
                                  [-79.277, -26.61],
                                  [-80.549, -207.864],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.663, 56.265],
                                  [-79.29, -26.358],
                                  [-80.711, -208.519],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.425, 56.355],
                                  [-79.239, -26.107],
                                  [-80.878, -209.105],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.206, 56.433],
                                  [-79.249, -25.874],
                                  [-81.031, -209.64],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.973, 56.504],
                                  [-79.285, -25.656],
                                  [-81.154, -210.077],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.85, 56.561],
                                  [-79.257, -25.455],
                                  [-81.233, -210.508],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.667, 56.639],
                                  [-79.243, -25.316],
                                  [-81.344, -210.841],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.577, 56.675],
                                  [-79.273, -25.191],
                                  [-81.456, -211.169],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.427, 56.714],
                                  [-79.236, -25.081],
                                  [-81.491, -211.446],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.342, 56.728],
                                  [-79.242, -24.969],
                                  [-81.526, -211.609],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.288, 56.791],
                                  [-79.214, -24.933],
                                  [-81.591, -211.783],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.253, 56.782],
                                  [-79.212, -24.849],
                                  [-81.61, -211.89],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.204, 56.805],
                                  [-79.222, -24.823],
                                  [-81.612, -211.991],
                                ],
                                c: false,
                              },
                            ],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.584, 0.584, 0.584, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.584, 0.584, 0.584, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-79.647, -59.809],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.569, -59.703],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.465, -59.558],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.336, -59.335],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.089, -58.996],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.794, -58.571],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.46, -58.063],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.046, -57.464],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.502, -56.728],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.916, -55.868],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.175, -54.877],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.267, -53.718],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.238, -52.392],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.981, -50.88],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.544, -49.17],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.775, -47.172],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.71, -44.945],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.244, -42.425],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.243, -39.537],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.597, -36.271],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.117, -32.612],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.708, -28.541],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.715, -24.756],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.179, -21.155],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.059, -17.304],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-35.277, -13.358],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.942, -9.549],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.23, -6.046],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.391, -3.039],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.659, -0.543],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.154, 1.499],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.993, 3.197],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.148, 4.547],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.67, 5.69],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.405, 6.674],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.174, 7.513],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.581, 8.255],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.702, 8.89],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.521, 9.477],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.088, 10.031],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.367, 10.535],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.349, 11.05],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.119, 11.509],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.628, 11.996],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.861, 12.474],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.855, 12.949],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.571, 13.452],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.093, 13.951],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.347, 14.471],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.4, 15.029],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.271, 15.602],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.95, 16.169],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.516, 16.793],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.962, 17.396],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.372, 18.04],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.72, 18.687],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.146, 19.3],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.507, 19.927],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.017, 20.531],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.5, 21.145],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.919, 21.735],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-3.277, 22.31],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.502, 22.841],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.742, 23.377],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.889, 23.874],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.986, 24.377],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.051, 24.848],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.019, 25.286],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.99, 25.706],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.865, 26.121],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.769, 26.514],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.574, 26.872],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.413, 27.227],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.2, 27.561],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.939, 27.871],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.672, 28.166],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.373, 28.445],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.119, 28.728],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.781, 28.963],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.47, 29.194],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.094, 29.406],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.724, 29.598],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.362, 29.801],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.963, 29.969],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.588, 30.13],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.202, 30.262],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.79, 30.416],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.35, 30.515],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.938, 30.633],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.515, 30.722],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.115, 30.812],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.7, 30.901],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.404, 31.043],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.059, 31.12],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.831, 31.261],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.639, 31.405],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.472, 31.549],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.464, 31.73],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-32.47, 31.89],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.54, 32.086],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.781, 32.322],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-36.083, 32.53],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.489, 32.755],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.083, 33.017],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.804, 33.274],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.685, 33.534],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.789, 33.781],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.113, 34.022],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.662, 34.206],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.497, 34.318],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.516, 34.225],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.682, 33.844],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.748, 32.904],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.181, 30.927],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.049, 27.224],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.326, 21.143],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.8, 12.801],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.257, 3.071],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.81, -6.925],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.161, -16.574],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.796, -25.539],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.859, -33.746],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.486, -41.137],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.586, -47.849],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.14, -53.951],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.114, -59.523],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.535, -64.597],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.826, -69.257],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.79, -73.514],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.503, -77.474],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.978, -81.159],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.23, -84.582],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.253, -87.744],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.102, -90.695],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.771, -93.46],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.293, -96.086],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.659, -98.494],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.894, -100.787],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.024, -102.941],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.004, -104.984],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.888, -106.879],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.663, -108.672],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.341, -110.374],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.928, -111.973],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.436, -113.488],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.85, -114.901],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.21, -116.24],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.489, -117.529],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.716, -118.77],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.832, -119.864],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.904, -120.932],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.945, -121.975],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.9, -122.922],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.816, -123.813],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.664, -124.673],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.472, -125.453],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.214, -126.202],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.93, -126.905],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.594, -127.561],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.252, -128.212],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.813, -128.744],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.371, -129.318],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.87, -129.792],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.346, -130.271],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.76, -130.681],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.17, -131.084],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.531, -131.429],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.863, -131.743],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.155, -132.029],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.433, -132.322],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.657, -132.538],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.88, -132.733],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.053, -132.919],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.224, -133.085],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.352, -133.21],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.485, -133.319],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.555, -133.397],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.59, -133.432],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.593, -133.494],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.41, -133.453],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.094, -133.377],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.656, -133.258],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.018, -133.12],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.246, -132.944],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.291, -132.71],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.138, -132.444],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.752, -132.157],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.13, -131.762],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.267, -131.293],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.11, -130.77],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.623, -130.162],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.774, -129.44],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.507, -128.559],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.802, -127.559],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.523, -126.425],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.643, -125.01],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.082, -123.364],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.718, -121.416],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.411, -119.025],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.042, -116.142],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.469, -112.633],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.353, -108.353],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.373, -103.178],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.313, -97.043],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.603, -90.05],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.553, -82.538],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.545, -74.754],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.238, -67.096],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.54, -59.747],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.452, -52.967],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.037, -46.739],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.275, -41.263],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.162, -36.637],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.573, -33.017],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.483, -30.54],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.03, -29.108],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.546, -28.568],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.382, -28.586],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.849, -28.995],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.143, -29.622],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.35, -30.361],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.585, -31.178],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.889, -32.02],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.216, -32.882],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.65, -33.728],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.156, -34.564],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.767, -35.384],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.483, -36.161],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.349, -36.923],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.238, -37.656],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.26, -38.377],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.36, -39.055],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.544, -39.715],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.053, -40.467],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.115, -41.856],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.166, -43.188],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.262, -44.452],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.35, -45.636],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.406, -46.742],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.529, -47.806],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.606, -48.81],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.707, -49.747],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.759, -50.629],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.83, -51.462],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.942, -52.239],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.009, -52.981],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.06, -53.65],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.137, -54.332],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.2, -54.904],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.265, -55.481],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.315, -55.995],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.351, -56.458],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.416, -56.907],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.44, -57.29],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.495, -57.685],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.537, -58.032],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.527, -58.322],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.566, -58.609],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.622, -58.869],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.616, -59.071],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.628, -59.252],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.675, -59.425],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.653, -59.572],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.666, -59.664],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.657, -59.733],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.66, -59.788],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-79.647, -59.809],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.569, -59.703],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.465, -59.558],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.336, -59.335],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.089, -58.996],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.794, -58.571],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.46, -58.063],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.046, -57.464],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.502, -56.728],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.916, -55.868],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.175, -54.877],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.267, -53.718],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.238, -52.392],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.981, -50.88],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.544, -49.17],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.775, -47.172],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.71, -44.945],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.244, -42.425],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.243, -39.537],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.597, -36.271],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.117, -32.612],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.708, -28.541],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.715, -24.756],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.179, -21.155],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.059, -17.304],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-35.277, -13.358],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.942, -9.549],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.23, -6.046],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.391, -3.039],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.659, -0.543],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.154, 1.499],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.993, 3.197],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.148, 4.547],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.67, 5.69],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.405, 6.674],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.174, 7.513],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.581, 8.255],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.702, 8.89],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.521, 9.477],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.088, 10.031],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.367, 10.535],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.349, 11.05],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.119, 11.509],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.628, 11.996],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.861, 12.474],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.855, 12.949],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.571, 13.452],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.093, 13.951],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.347, 14.471],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.4, 15.029],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.271, 15.602],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.95, 16.169],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.516, 16.793],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.962, 17.396],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.372, 18.04],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.72, 18.687],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.146, 19.3],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.507, 19.927],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.017, 20.531],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.5, 21.145],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.919, 21.735],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-3.277, 22.31],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.502, 22.841],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.742, 23.377],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.889, 23.874],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.986, 24.377],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.051, 24.848],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.019, 25.286],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.99, 25.706],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.865, 26.121],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.769, 26.514],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.574, 26.872],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.413, 27.227],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.2, 27.561],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.939, 27.871],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.672, 28.166],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.373, 28.445],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.119, 28.728],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.781, 28.963],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.47, 29.194],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.094, 29.406],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.724, 29.598],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.362, 29.801],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.963, 29.969],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.588, 30.13],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.202, 30.262],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.79, 30.416],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.35, 30.515],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.938, 30.633],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.515, 30.722],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.115, 30.812],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.7, 30.901],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.404, 31.043],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.059, 31.12],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.831, 31.261],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.639, 31.405],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.472, 31.549],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.464, 31.73],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-32.47, 31.89],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.54, 32.086],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.781, 32.322],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-36.083, 32.53],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.489, 32.755],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.083, 33.017],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.804, 33.274],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.685, 33.534],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.789, 33.781],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.113, 34.022],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.662, 34.206],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.497, 34.318],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.516, 34.225],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.682, 33.844],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.748, 32.904],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.181, 30.927],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.049, 27.224],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.326, 21.143],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.8, 12.801],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.257, 3.071],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.81, -6.925],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.161, -16.574],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.796, -25.539],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.859, -33.746],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.486, -41.137],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.586, -47.849],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.14, -53.951],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.114, -59.523],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.535, -64.597],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.826, -69.257],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.79, -73.514],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.503, -77.474],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.978, -81.159],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.23, -84.582],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.253, -87.744],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.102, -90.695],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.771, -93.46],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.293, -96.086],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.659, -98.494],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.894, -100.787],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.024, -102.941],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.004, -104.984],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.888, -106.879],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.663, -108.672],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.341, -110.374],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.928, -111.973],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.436, -113.488],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.85, -114.901],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.21, -116.24],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.489, -117.529],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.716, -118.77],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.832, -119.864],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.904, -120.932],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.945, -121.975],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.9, -122.922],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.816, -123.813],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.664, -124.673],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.472, -125.453],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.214, -126.202],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.93, -126.905],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.594, -127.561],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.252, -128.212],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.813, -128.744],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.371, -129.318],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.87, -129.792],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.346, -130.271],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.76, -130.681],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.17, -131.084],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.531, -131.429],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.863, -131.743],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.155, -132.029],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.433, -132.322],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.657, -132.538],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.88, -132.733],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.053, -132.919],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.224, -133.085],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.352, -133.21],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.485, -133.319],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.555, -133.397],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.59, -133.432],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.593, -133.494],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.41, -133.453],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.094, -133.377],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.656, -133.258],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.018, -133.12],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.246, -132.944],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.291, -132.71],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.138, -132.444],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.752, -132.157],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.13, -131.762],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.267, -131.293],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.11, -130.77],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.623, -130.162],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.774, -129.44],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.507, -128.559],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.802, -127.559],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.523, -126.425],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.643, -125.01],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.082, -123.364],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.718, -121.416],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.411, -119.025],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.042, -116.142],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.469, -112.633],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.353, -108.353],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.373, -103.178],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.313, -97.043],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.603, -90.05],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.553, -82.538],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.545, -74.754],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.238, -67.096],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.54, -59.747],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.452, -52.967],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.037, -46.739],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.275, -41.263],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.162, -36.637],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.573, -33.017],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.483, -30.54],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.03, -29.108],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.546, -28.568],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.382, -28.586],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.849, -28.995],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.143, -29.622],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.35, -30.361],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.585, -31.178],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.889, -32.02],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.216, -32.882],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.65, -33.728],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.156, -34.564],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.767, -35.384],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.483, -36.161],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.349, -36.923],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.238, -37.656],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.26, -38.377],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.36, -39.055],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.544, -39.715],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.053, -40.467],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.115, -41.856],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.166, -43.188],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.262, -44.452],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.35, -45.636],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.406, -46.742],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.529, -47.806],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.606, -48.81],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.707, -49.747],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.759, -50.629],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.83, -51.462],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.942, -52.239],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.009, -52.981],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.06, -53.65],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.137, -54.332],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.2, -54.904],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.265, -55.481],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.315, -55.995],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.351, -56.458],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.416, -56.907],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.44, -57.29],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.495, -57.685],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.537, -58.032],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.527, -58.322],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.566, -58.609],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.622, -58.869],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.616, -59.071],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.628, -59.252],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.675, -59.425],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.653, -59.572],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.666, -59.664],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.657, -59.733],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.66, -59.788],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 12',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 12,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.401, 49.602],
                                  [-50.409, 6.391],
                                  [-43.212, -223.996],
                                ],
                                c: false,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.415, 49.603],
                                  [-50.4, 6.398],
                                  [-43.205, -223.945],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.379, 49.719],
                                  [-50.289, 6.372],
                                  [-43.285, -223.852],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.321, 49.837],
                                  [-50.161, 6.298],
                                  [-43.339, -223.667],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.226, 50.008],
                                  [-49.98, 6.251],
                                  [-43.494, -223.379],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.158, 50.243],
                                  [-49.672, 6.168],
                                  [-43.612, -223],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.005, 50.543],
                                  [-49.299, 6.063],
                                  [-43.805, -222.479],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.879, 50.896],
                                  [-48.873, 5.921],
                                  [-44.037, -221.866],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.68, 51.364],
                                  [-48.359, 5.768],
                                  [-44.333, -221.162],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.446, 51.898],
                                  [-47.681, 5.629],
                                  [-44.693, -220.304],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.202, 52.534],
                                  [-46.977, 5.427],
                                  [-45.068, -219.267],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.9, 53.285],
                                  [-46.098, 5.177],
                                  [-45.494, -218.102],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.576, 54.115],
                                  [-45.031, 4.902],
                                  [-46.023, -216.745],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.145, 55.097],
                                  [-43.864, 4.64],
                                  [-46.591, -215.149],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.718, 56.207],
                                  [-42.461, 4.292],
                                  [-47.248, -213.387],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.148, 57.483],
                                  [-40.909, 3.908],
                                  [-47.969, -211.298],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.585, 58.973],
                                  [-39.046, 3.5],
                                  [-48.791, -208.934],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.855, 60.655],
                                  [-36.947, 3.082],
                                  [-49.712, -206.245],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.061, 62.613],
                                  [-34.525, 2.544],
                                  [-50.793, -203.171],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.154, 64.8],
                                  [-31.693, 1.986],
                                  [-51.97, -199.588],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.125, 67.375],
                                  [-28.389, 1.399],
                                  [-53.314, -195.512],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.89, 70.352],
                                  [-24.524, 0.698],
                                  [-54.809, -190.821],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.478, 73.781],
                                  [-20.099, -0.062],
                                  [-56.5, -185.371],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.893, 77.722],
                                  [-14.899, -0.901],
                                  [-58.404, -179.079],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.031, 82.274],
                                  [-8.876, -1.812],
                                  [-60.552, -171.864],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-142.946, 87.437],
                                  [-2.028, -2.814],
                                  [-62.971, -163.684],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.619, 93.11],
                                  [5.545, -3.878],
                                  [-65.509, -154.729],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.238, 99.118],
                                  [13.566, -5.003],
                                  [-68.227, -145.326],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.913, 105.138],
                                  [21.609, -6.047],
                                  [-70.894, -135.846],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.654, 110.969],
                                  [29.319, -7.078],
                                  [-73.469, -126.77],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.668, 116.366],
                                  [36.456, -8.054],
                                  [-75.875, -118.307],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.917, 121.351],
                                  [42.93, -8.954],
                                  [-78.044, -110.688],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.414, 125.853],
                                  [48.724, -9.747],
                                  [-80.028, -103.751],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-127.123, 129.909],
                                  [53.912, -10.506],
                                  [-81.867, -97.571],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-126.046, 133.618],
                                  [58.518, -11.172],
                                  [-83.537, -92.034],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-125.195, 136.994],
                                  [62.587, -11.793],
                                  [-85.065, -87.021],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-124.518, 140.099],
                                  [66.254, -12.383],
                                  [-86.461, -82.531],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.953, 142.935],
                                  [69.468, -12.94],
                                  [-87.715, -78.48],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.613, 145.538],
                                  [72.35, -13.48],
                                  [-88.947, -74.82],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.384, 147.935],
                                  [74.895, -13.976],
                                  [-90.064, -71.504],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.304, 150.211],
                                  [77.177, -14.452],
                                  [-91.122, -68.497],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.357, 152.283],
                                  [79.175, -14.923],
                                  [-92.089, -65.802],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.582, 154.25],
                                  [80.866, -15.329],
                                  [-93.05, -63.309],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.901, 156.096],
                                  [82.375, -15.786],
                                  [-93.93, -61.161],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-124.368, 157.837],
                                  [83.63, -16.168],
                                  [-94.793, -59.171],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-124.987, 159.489],
                                  [84.659, -16.57],
                                  [-95.61, -57.419],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-125.73, 161.038],
                                  [85.473, -16.964],
                                  [-96.398, -55.908],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-126.648, 162.514],
                                  [86.069, -17.35],
                                  [-97.143, -54.544],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-127.649, 163.92],
                                  [86.509, -17.743],
                                  [-97.844, -53.377],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.824, 165.252],
                                  [86.731, -18.129],
                                  [-98.532, -52.405],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.14, 166.544],
                                  [86.803, -18.488],
                                  [-99.176, -51.626],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.568, 167.777],
                                  [86.716, -18.807],
                                  [-99.833, -50.97],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.095, 168.92],
                                  [86.472, -19.171],
                                  [-100.424, -50.483],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.716, 170.046],
                                  [86.161, -19.473],
                                  [-100.947, -50.057],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-136.364, 171.08],
                                  [85.715, -19.784],
                                  [-101.473, -49.804],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.085, 172.109],
                                  [85.241, -20.038],
                                  [-101.97, -49.647],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.8, 173.068],
                                  [84.691, -20.27],
                                  [-102.401, -49.53],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.454, 173.955],
                                  [84.169, -20.487],
                                  [-102.802, -49.457],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.133, 174.831],
                                  [83.581, -20.707],
                                  [-103.138, -49.507],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.757, 175.609],
                                  [83.085, -20.845],
                                  [-103.49, -49.502],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.28, 176.419],
                                  [82.537, -21.014],
                                  [-103.737, -49.58],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.778, 177.112],
                                  [82.021, -21.106],
                                  [-103.988, -49.657],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.21, 177.795],
                                  [81.539, -21.213],
                                  [-104.184, -49.73],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.574, 178.424],
                                  [81.129, -21.292],
                                  [-104.389, -49.859],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.851, 179.023],
                                  [80.661, -21.347],
                                  [-104.546, -49.939],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.106, 179.571],
                                  [80.279, -21.422],
                                  [-104.657, -50.096],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.28, 180.111],
                                  [79.875, -21.437],
                                  [-104.787, -50.185],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.417, 180.602],
                                  [79.485, -21.476],
                                  [-104.877, -50.353],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.479, 181.064],
                                  [79.147, -21.501],
                                  [-104.97, -50.474],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.532, 181.478],
                                  [78.796, -21.534],
                                  [-105.028, -50.614],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.475, 181.886],
                                  [78.468, -21.513],
                                  [-105.114, -50.751],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.454, 182.289],
                                  [78.12, -21.525],
                                  [-105.188, -50.908],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.33, 182.646],
                                  [77.811, -21.527],
                                  [-105.253, -51.086],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.207, 182.976],
                                  [77.476, -21.542],
                                  [-105.268, -51.245],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.025, 183.261],
                                  [77.152, -21.531],
                                  [-105.296, -51.404],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.849, 183.582],
                                  [76.86, -21.534],
                                  [-105.36, -51.587],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.578, 183.82],
                                  [76.535, -21.531],
                                  [-105.377, -51.753],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.317, 184.094],
                                  [76.237, -21.545],
                                  [-105.409, -51.945],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.043, 184.325],
                                  [75.88, -21.536],
                                  [-105.436, -52.139],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.703, 184.53],
                                  [75.57, -21.546],
                                  [-105.463, -52.32],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.376, 184.693],
                                  [75.237, -21.551],
                                  [-105.485, -52.527],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.003, 184.893],
                                  [74.948, -21.575],
                                  [-105.487, -52.761],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.606, 185.051],
                                  [74.617, -21.595],
                                  [-105.547, -52.943],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.202, 185.206],
                                  [74.282, -21.596],
                                  [-105.565, -53.17],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.739, 185.321],
                                  [73.964, -21.618],
                                  [-105.564, -53.385],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.295, 185.433],
                                  [73.601, -21.637],
                                  [-105.622, -53.59],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.871, 185.521],
                                  [73.254, -21.678],
                                  [-105.662, -53.824],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.367, 185.63],
                                  [72.899, -21.677],
                                  [-105.701, -54.064],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.821, 185.713],
                                  [72.558, -21.737],
                                  [-105.737, -54.318],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.321, 185.756],
                                  [72.192, -21.756],
                                  [-105.756, -54.539],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.782, 185.798],
                                  [71.815, -21.798],
                                  [-105.798, -54.79],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.241, 185.8],
                                  [71.436, -21.863],
                                  [-105.752, -54.994],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.739, 185.748],
                                  [71.118, -21.95],
                                  [-105.633, -55.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.289, 185.643],
                                  [70.745, -22.061],
                                  [-105.299, -55.144],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.852, 185.423],
                                  [70.456, -22.243],
                                  [-104.892, -55.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.505, 185.151],
                                  [70.152, -22.449],
                                  [-104.26, -54.887],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.147, 184.788],
                                  [69.856, -22.704],
                                  [-103.451, -54.58],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.84, 184.337],
                                  [69.607, -23.008],
                                  [-102.48, -54.166],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.659, 183.798],
                                  [69.329, -23.384],
                                  [-101.246, -53.583],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.504, 183.108],
                                  [69.086, -23.811],
                                  [-99.809, -52.894],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.388, 182.317],
                                  [68.877, -24.287],
                                  [-98.097, -52.076],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.411, 181.426],
                                  [68.666, -24.863],
                                  [-96.07, -51.026],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.508, 180.337],
                                  [68.501, -25.538],
                                  [-93.729, -49.772],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.694, 179.086],
                                  [68.36, -26.289],
                                  [-91.047, -48.325],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.028, 177.687],
                                  [68.229, -27.165],
                                  [-87.937, -46.611],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.474, 176.078],
                                  [68.135, -28.168],
                                  [-84.373, -44.606],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.093, 174.186],
                                  [68.091, -29.297],
                                  [-80.267, -42.309],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.895, 172.01],
                                  [68.059, -30.603],
                                  [-75.532, -39.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.944, 169.553],
                                  [68.092, -32.112],
                                  [-70.09, -36.53],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.186, 166.689],
                                  [68.166, -33.849],
                                  [-63.791, -32.898],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.769, 163.41],
                                  [68.269, -35.841],
                                  [-56.521, -28.714],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.692, 159.578],
                                  [68.454, -38.163],
                                  [-48.104, -23.84],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-201.025, 155.16],
                                  [68.713, -40.842],
                                  [-38.324, -18.164],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.864, 150.033],
                                  [69.072, -43.929],
                                  [-26.979, -11.587],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.193, 144.149],
                                  [69.51, -47.501],
                                  [-13.917, -3.972],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.02, 137.513],
                                  [70.032, -51.559],
                                  [0.914, 4.705],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.33, 130.176],
                                  [70.664, -56.031],
                                  [17.267, 14.257],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.925, 122.424],
                                  [71.367, -60.764],
                                  [34.578, 24.359],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-230.511, 114.629],
                                  [72.087, -65.558],
                                  [52.043, 34.561],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-235.857, 107.172],
                                  [72.804, -70.158],
                                  [68.846, 44.338],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-240.797, 100.236],
                                  [73.523, -74.463],
                                  [84.544, 53.468],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.313, 93.905],
                                  [74.203, -78.42],
                                  [98.897, 61.799],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.334, 88.187],
                                  [74.815, -82.028],
                                  [111.926, 69.359],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-253, 83.094],
                                  [75.379, -85.284],
                                  [123.707, 76.145],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-256.3, 78.448],
                                  [75.922, -88.24],
                                  [134.411, 82.285],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-259.274, 74.262],
                                  [76.427, -90.943],
                                  [144.101, 87.879],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-261.96, 70.461],
                                  [76.872, -93.42],
                                  [153.002, 92.962],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.421, 66.994],
                                  [77.34, -95.695],
                                  [161.126, 97.598],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.67, 63.822],
                                  [77.746, -97.793],
                                  [168.609, 101.85],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-268.72, 60.946],
                                  [78.125, -99.712],
                                  [175.515, 105.743],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-270.621, 58.279],
                                  [78.479, -101.505],
                                  [181.917, 109.351],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-272.375, 55.82],
                                  [78.843, -103.195],
                                  [187.829, 112.736],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-273.967, 53.506],
                                  [79.168, -104.758],
                                  [193.376, 115.837],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-275.488, 51.412],
                                  [79.491, -106.217],
                                  [198.519, 118.741],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-276.86, 49.426],
                                  [79.799, -107.574],
                                  [203.347, 121.424],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.148, 47.561],
                                  [80.081, -108.853],
                                  [207.87, 123.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-279.388, 45.853],
                                  [80.347, -110.08],
                                  [212.129, 126.32],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-280.493, 44.265],
                                  [80.622, -111.203],
                                  [216.096, 128.523],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-281.564, 42.761],
                                  [80.859, -112.274],
                                  [219.847, 130.603],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-282.526, 41.339],
                                  [81.13, -113.293],
                                  [223.383, 132.561],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-283.49, 40.037],
                                  [81.336, -114.259],
                                  [226.727, 134.421],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.359, 38.755],
                                  [81.564, -115.146],
                                  [229.867, 136.122],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-285.167, 37.607],
                                  [81.789, -116.007],
                                  [232.804, 137.762],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-285.928, 36.515],
                                  [81.974, -116.814],
                                  [235.626, 139.279],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-286.656, 35.494],
                                  [82.144, -117.569],
                                  [238.281, 140.725],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.326, 34.518],
                                  [82.335, -118.296],
                                  [240.783, 142.085],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.984, 33.588],
                                  [82.512, -118.97],
                                  [243.144, 143.373],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-288.559, 32.728],
                                  [82.71, -119.617],
                                  [245.352, 144.575],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-289.139, 31.939],
                                  [82.855, -120.236],
                                  [247.481, 145.742],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-289.645, 31.168],
                                  [82.985, -120.828],
                                  [249.494, 146.849],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.168, 30.458],
                                  [83.162, -121.367],
                                  [251.354, 147.833],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.607, 29.766],
                                  [83.287, -121.878],
                                  [253.135, 148.781],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-291.073, 29.135],
                                  [83.421, -122.388],
                                  [254.826, 149.732],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-291.482, 28.547],
                                  [83.539, -122.844],
                                  [256.426, 150.56],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-291.856, 27.968],
                                  [83.667, -123.273],
                                  [257.911, 151.353],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-292.245, 27.485],
                                  [83.779, -123.701],
                                  [259.304, 152.147],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-292.563, 26.948],
                                  [83.89, -124.074],
                                  [260.645, 152.819],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-292.897, 26.506],
                                  [83.984, -124.447],
                                  [261.895, 153.492],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-293.196, 26.073],
                                  [84.088, -124.791],
                                  [263.054, 154.131],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-293.486, 25.674],
                                  [84.165, -125.109],
                                  [264.16, 154.708],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-293.729, 25.282],
                                  [84.251, -125.424],
                                  [265.201, 155.287],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-293.964, 24.924],
                                  [84.347, -125.686],
                                  [266.126, 155.743],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.226, 24.6],
                                  [84.416, -125.973],
                                  [267.049, 156.264],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.416, 24.31],
                                  [84.494, -126.206],
                                  [267.856, 156.686],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.596, 24.027],
                                  [84.544, -126.437],
                                  [268.634, 157.111],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.805, 23.779],
                                  [84.604, -126.641],
                                  [269.349, 157.474],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.941, 23.527],
                                  [84.663, -126.844],
                                  [270.009, 157.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.105, 23.31],
                                  [84.731, -127.019],
                                  [270.579, 158.143],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.224, 23.101],
                                  [84.772, -127.166],
                                  [271.148, 158.412],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.344, 22.925],
                                  [84.822, -127.312],
                                  [271.626, 158.657],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.455, 22.783],
                                  [84.845, -127.457],
                                  [272.076, 158.929],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.593, 22.639],
                                  [84.903, -127.574],
                                  [272.462, 159.114],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.634, 22.529],
                                  [84.934, -127.663],
                                  [272.794, 159.29],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.728, 22.427],
                                  [84.937, -127.751],
                                  [273.124, 159.441],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.787, 22.322],
                                  [84.976, -127.838],
                                  [273.364, 159.594],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.837, 22.252],
                                  [84.988, -127.898],
                                  [273.576, 159.712],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.862, 22.179],
                                  [85.035, -127.956],
                                  [273.751, 159.805],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.904, 22.114],
                                  [85.018, -127.986],
                                  [273.907, 159.863],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.948, 22.109],
                                  [85.047, -128.016],
                                  [273.963, 159.896],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.956, 22.076],
                                  [85.038, -128.044],
                                  [274.054, 159.956],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.929, 22.097],
                                  [84.978, -128.035],
                                  [273.965, 159.929],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.911, 22.031],
                                  [84.859, -127.996],
                                  [273.684, 159.749],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.841, 22.012],
                                  [84.581, -127.899],
                                  [273.258, 159.481],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.747, 21.952],
                                  [84.253, -127.779],
                                  [272.584, 159.127],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.666, 21.871],
                                  [83.737, -127.624],
                                  [271.663, 158.624],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.502, 21.765],
                                  [83.131, -127.431],
                                  [270.527, 157.939],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.319, 21.62],
                                  [82.383, -127.172],
                                  [269.125, 157.135],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.056, 21.479],
                                  [81.455, -126.87],
                                  [267.417, 156.152],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.805, 21.326],
                                  [80.345, -126.557],
                                  [265.412, 154.992],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.506, 21.13],
                                  [79.063, -126.13],
                                  [263.047, 153.647],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.098, 20.896],
                                  [77.583, -125.61],
                                  [260.294, 152.057],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-293.635, 20.645],
                                  [75.904, -125.053],
                                  [257.137, 150.16],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-293.134, 20.341],
                                  [73.937, -124.4],
                                  [253.514, 148.011],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-292.512, 20.014],
                                  [71.706, -123.621],
                                  [249.372, 145.525],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-291.809, 19.628],
                                  [69.201, -122.715],
                                  [244.613, 142.619],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.968, 19.21],
                                  [66.323, -121.671],
                                  [239.238, 139.331],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.007, 18.783],
                                  [63.025, -120.52],
                                  [233.065, 135.502],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-288.891, 18.283],
                                  [59.327, -119.107],
                                  [226.09, 131.046],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.526, 17.744],
                                  [55.117, -117.479],
                                  [218.14, 125.849],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-285.912, 17.186],
                                  [50.338, -115.59],
                                  [209.068, 119.845],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-283.961, 16.642],
                                  [44.96, -113.349],
                                  [198.726, 112.773],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-281.664, 16.063],
                                  [38.895, -110.704],
                                  [186.992, 104.463],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.867, 15.499],
                                  [32.075, -107.579],
                                  [173.687, 94.667],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-275.504, 15.019],
                                  [24.517, -103.871],
                                  [158.776, 83.215],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-271.474, 14.659],
                                  [16.329, -99.53],
                                  [142.414, 69.983],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.797, 14.526],
                                  [7.825, -94.583],
                                  [125.034, 55.056],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-261.597, 14.684],
                                  [-0.604, -89.123],
                                  [107.473, 38.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-256.074, 15.131],
                                  [-8.469, -83.483],
                                  [90.592, 22.202],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.416, 15.848],
                                  [-15.452, -77.783],
                                  [75.146, 5.747],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-244.896, 16.746],
                                  [-21.425, -72.285],
                                  [61.503, -9.916],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-239.625, 17.824],
                                  [-26.434, -67.08],
                                  [49.633, -24.552],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.679, 19.007],
                                  [-30.639, -62.293],
                                  [39.439, -38.035],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-230.048, 20.225],
                                  [-34.07, -57.832],
                                  [30.623, -50.493],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-225.692, 21.463],
                                  [-36.922, -53.739],
                                  [22.988, -61.868],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.652, 22.69],
                                  [-39.298, -49.935],
                                  [16.373, -72.397],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.872, 23.947],
                                  [-41.259, -46.427],
                                  [10.601, -82.105],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.322, 25.133],
                                  [-42.925, -43.155],
                                  [5.512, -91.098],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.023, 26.335],
                                  [-44.308, -40.093],
                                  [1.033, -99.429],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.929, 27.462],
                                  [-45.49, -37.263],
                                  [-2.961, -107.203],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.995, 28.588],
                                  [-46.473, -34.595],
                                  [-6.537, -114.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.245, 29.63],
                                  [-47.317, -32.096],
                                  [-9.73, -121.181],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.642, 30.684],
                                  [-48.015, -29.806],
                                  [-12.601, -127.518],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.21, 31.658],
                                  [-48.621, -27.563],
                                  [-15.189, -133.473],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.908, 32.605],
                                  [-49.127, -25.517],
                                  [-17.52, -139.054],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.724, 33.562],
                                  [-49.582, -23.585],
                                  [-19.663, -144.32],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.633, 34.445],
                                  [-49.929, -21.748],
                                  [-21.591, -149.296],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.668, 35.262],
                                  [-50.247, -19.986],
                                  [-23.385, -153.967],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.802, 36.102],
                                  [-50.476, -18.34],
                                  [-25.002, -158.419],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.021, 36.866],
                                  [-50.695, -16.763],
                                  [-26.46, -162.607],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.358, 37.631],
                                  [-50.845, -15.282],
                                  [-27.87, -166.558],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.784, 38.358],
                                  [-50.988, -13.927],
                                  [-29.091, -170.255],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.27, 39.031],
                                  [-51.092, -12.566],
                                  [-30.27, -173.807],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.799, 39.708],
                                  [-51.192, -11.328],
                                  [-31.338, -177.151],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.468, 40.36],
                                  [-51.254, -10.128],
                                  [-32.284, -180.347],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.168, 40.964],
                                  [-51.279, -9.012],
                                  [-33.218, -183.347],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.949, 41.538],
                                  [-51.281, -7.966],
                                  [-34.059, -186.232],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.79, 42.059],
                                  [-51.309, -6.938],
                                  [-34.793, -188.905],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.65, 42.596],
                                  [-51.301, -5.941],
                                  [-35.545, -191.477],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.607, 43.112],
                                  [-51.319, -5.072],
                                  [-36.19, -193.897],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.611, 43.571],
                                  [-51.315, -4.183],
                                  [-36.805, -196.165],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.652, 44.042],
                                  [-51.252, -3.405],
                                  [-37.39, -198.317],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.79, 44.475],
                                  [-51.266, -2.605],
                                  [-37.945, -200.366],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.928, 44.897],
                                  [-51.221, -1.878],
                                  [-38.408, -202.311],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.166, 45.278],
                                  [-51.203, -1.212],
                                  [-38.866, -204.139],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.391, 45.62],
                                  [-51.127, -0.519],
                                  [-39.281, -205.862],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.653, 45.997],
                                  [-51.077, 0.052],
                                  [-39.679, -207.482],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.028, 46.331],
                                  [-51.079, 0.651],
                                  [-40.009, -208.997],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.392, 46.624],
                                  [-51.023, 1.193],
                                  [-40.372, -210.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.806, 46.937],
                                  [-50.956, 1.716],
                                  [-40.666, -211.786],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.209, 47.206],
                                  [-50.928, 2.169],
                                  [-40.943, -213.072],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.726, 47.458],
                                  [-50.89, 2.604],
                                  [-41.201, -214.205],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.231, 47.702],
                                  [-50.854, 3.02],
                                  [-41.453, -215.332],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.788, 47.915],
                                  [-50.807, 3.432],
                                  [-41.686, -216.341],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.396, 48.133],
                                  [-50.748, 3.778],
                                  [-41.913, -217.295],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.994, 48.318],
                                  [-50.74, 4.107],
                                  [-42.07, -218.144],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.655, 48.521],
                                  [-50.684, 4.42],
                                  [-42.22, -218.938],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.306, 48.665],
                                  [-50.665, 4.718],
                                  [-42.412, -219.676],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.009, 48.826],
                                  [-50.647, 4.951],
                                  [-42.533, -220.36],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.75, 48.954],
                                  [-50.567, 5.183],
                                  [-42.659, -220.976],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.506, 49.072],
                                  [-50.548, 5.4],
                                  [-42.777, -221.537],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.252, 49.169],
                                  [-50.567, 5.604],
                                  [-42.872, -221.994],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.111, 49.257],
                                  [-50.522, 5.795],
                                  [-42.921, -222.445],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.91, 49.361],
                                  [-50.489, 5.923],
                                  [-43.011, -222.792],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.811, 49.417],
                                  [-50.505, 6.039],
                                  [-43.103, -223.134],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.651, 49.477],
                                  [-50.457, 6.142],
                                  [-43.122, -223.422],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.555, 49.502],
                                  [-50.457, 6.246],
                                  [-43.143, -223.593],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.499, 49.579],
                                  [-50.418, 6.277],
                                  [-43.203, -223.772],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.456, 49.572],
                                  [-50.414, 6.358],
                                  [-43.214, -223.884],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.403, 49.605],
                                  [-50.419, 6.379],
                                  [-43.213, -223.992],
                                ],
                                c: false,
                              },
                            ],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.402, 49.602],
                                  [-50.41, 6.389],
                                  [-43.213, -223.996],
                                ],
                                c: false,
                              },
                            ],
                            t: 270,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.617, 0.617, 0.617, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.617, 0.617, 0.617, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-48.619, -50.631],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.557, -50.535],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.479, -50.412],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.385, -50.21],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.19, -49.885],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.963, -49.493],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.707, -49.02],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.397, -48.476],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.975, -47.779],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.532, -46.986],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.959, -46.06],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.254, -44.956],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.464, -43.71],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-43.485, -42.261],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.37, -40.64],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.981, -38.696],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.363, -36.537],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.421, -34.07],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-35.032, -31.208],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-32.102, -27.933],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.465, -24.226],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.025, -20.049],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.429, -15.357],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.447, -10.273],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-2.856, -4.999],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.941, -1.486],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.643, 1.117],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.958, 3.588],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.724, 5.667],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.659, 7.337],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.619, 8.619],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.456, 9.597],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.121, 10.274],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.545, 10.772],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.644, 11.139],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.511, 11.39],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.062, 11.564],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.371, 11.659],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.416, 11.729],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.235, 11.783],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.799, 11.806],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.077, 11.868],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.171, 11.881],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.021, 11.949],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.616, 12.019],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.989, 12.099],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.097, 12.221],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.032, 12.351],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.712, 12.515],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.201, 12.73],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.523, 12.984],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.649, 13.23],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.672, 13.552],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.571, 13.858],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.425, 14.22],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.194, 14.607],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.019, 14.976],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.761, 15.362],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.65, 15.744],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.477, 16.14],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.379, 16.538],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.316, 16.927],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.37, 17.288],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.386, 17.67],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.493, 18.009],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.624, 18.373],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.774, 18.708],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.009, 19.023],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.226, 19.329],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.531, 19.638],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.79, 19.937],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.135, 20.206],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.433, 20.481],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.781, 20.742],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.166, 20.988],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.552, 21.215],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.96, 21.435],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.322, 21.664],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.755, 21.857],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.166, 22.044],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.625, 22.222],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.074, 22.377],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.513, 22.554],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.987, 22.7],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.43, 22.839],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.875, 22.961],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.348, 23.105],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.843, 23.196],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.3, 23.316],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.768, 23.409],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.199, 23.506],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.643, 23.595],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.933, 23.74],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.267, 23.818],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.463, 23.956],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.597, 24.095],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.689, 24.23],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.586, 24.407],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.435, 24.565],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.198, 24.742],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.76, 24.953],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.206, 25.139],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.517, 25.325],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.59, 25.536],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.476, 25.729],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.867, 25.906],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-3.508, 26.049],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.457, 26.151],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.737, 26.156],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.428, 26.045],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.481, 25.686],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.895, 24.998],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.535, 23.766],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.07, 21.663],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.896, 18.283],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.194, 13.226],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.338, 6.445],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-35.344, -1.586],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.853, -10.031],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.514, -18.307],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.893, -26.053],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.259, -33.153],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.835, -39.539],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.619, -45.321],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.644, -50.554],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.921, -55.311],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.574, -59.628],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.834, -63.579],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.869, -67.169],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.708, -70.486],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.417, -73.573],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.952, -76.426],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.311, -79.05],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.556, -81.495],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.65, -83.777],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.655, -85.939],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.53, -87.915],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.3, -89.791],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.011, -91.557],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.58, -93.216],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.093, -94.76],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.522, -96.22],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.862, -97.596],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.141, -98.891],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.359, -100.116],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.487, -101.254],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.596, -102.336],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.633, -103.373],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.628, -104.366],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.517, -105.244],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.406, -106.109],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.254, -106.945],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.026, -107.701],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.762, -108.41],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.447, -109.093],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.113, -109.722],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.731, -110.326],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.32, -110.888],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.853, -111.409],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.39, -111.923],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.837, -112.345],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.288, -112.799],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.696, -113.178],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.098, -113.561],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.436, -113.888],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.781, -114.214],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.077, -114.491],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.353, -114.742],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.592, -114.97],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.825, -115.202],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54, -115.374],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.181, -115.527],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.318, -115.672],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.459, -115.803],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.564, -115.902],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.671, -115.988],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.726, -116.05],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.75, -116.078],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.757, -116.123],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.588, -116.068],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.302, -115.967],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.915, -115.825],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.34, -115.647],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.644, -115.418],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.788, -115.119],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.754, -114.773],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.514, -114.403],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.058, -113.908],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.376, -113.309],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.426, -112.642],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.172, -111.875],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.61, -110.963],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.663, -109.886],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.324, -108.659],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.463, -107.277],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.06, -105.587],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.031, -103.632],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.271, -101.348],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.666, -98.61],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.081, -95.354],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.6, -91.478],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.442, -86.848],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.386, -81.392],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-41.154, -75.124],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.261, -68.179],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.979, -60.977],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.693, -53.777],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.96, -46.972],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.544, -40.751],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.212, -35.368],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.814, -30.85],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.221, -27.327],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.587, -24.73],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.324, -22.891],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.967, -21.624],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.976, -20.701],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.685, -20.052],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.265, -19.532],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.866, -19.128],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.56, -18.817],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.325, -18.514],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.277, -18.281],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.393, -18.072],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.607, -17.883],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.997, -17.691],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.522, -17.522],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.433, -18.343],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.07, -20.382],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.81, -22.34],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.59, -24.202],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.435, -26.006],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.298, -27.681],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.18, -29.278],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.075, -30.799],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.014, -32.228],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.953, -33.593],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.929, -34.899],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.903, -36.1],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.846, -37.241],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.86, -38.323],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.826, -39.35],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.826, -40.319],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.779, -41.211],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.763, -42.079],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.781, -42.875],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.767, -43.63],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.738, -44.313],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.739, -45.027],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.735, -45.609],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.735, -46.202],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.725, -46.718],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.711, -47.199],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.72, -47.67],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.695, -48.056],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.71, -48.453],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.711, -48.819],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.668, -49.111],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.671, -49.405],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.698, -49.678],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.669, -49.872],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.659, -50.065],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.687, -50.243],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.651, -50.394],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.652, -50.485],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.635, -50.558],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.632, -50.607],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.635, -50.675],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-48.619, -50.631],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.557, -50.535],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.479, -50.412],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.385, -50.21],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.19, -49.885],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.963, -49.493],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.707, -49.02],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.397, -48.476],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.975, -47.779],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.532, -46.986],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.959, -46.06],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.254, -44.956],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.464, -43.71],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-43.485, -42.261],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.37, -40.64],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.981, -38.696],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.363, -36.537],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.421, -34.07],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-35.032, -31.208],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-32.102, -27.933],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.465, -24.226],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.025, -20.049],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.429, -15.357],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.447, -10.273],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-2.856, -4.999],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.941, -1.486],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.643, 1.117],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.958, 3.588],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.724, 5.667],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.659, 7.337],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.619, 8.619],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.456, 9.597],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.121, 10.274],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.545, 10.772],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.644, 11.139],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.511, 11.39],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.062, 11.564],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.371, 11.659],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.416, 11.729],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.235, 11.783],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.799, 11.806],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.077, 11.868],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.171, 11.881],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.021, 11.949],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.616, 12.019],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.989, 12.099],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.097, 12.221],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.032, 12.351],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.712, 12.515],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.201, 12.73],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.523, 12.984],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.649, 13.23],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.672, 13.552],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.571, 13.858],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.425, 14.22],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.194, 14.607],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.019, 14.976],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.761, 15.362],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.65, 15.744],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.477, 16.14],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.379, 16.538],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.316, 16.927],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.37, 17.288],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.386, 17.67],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.493, 18.009],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.624, 18.373],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.774, 18.708],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.009, 19.023],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.226, 19.329],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.531, 19.638],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.79, 19.937],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.135, 20.206],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.433, 20.481],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.781, 20.742],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.166, 20.988],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.552, 21.215],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.96, 21.435],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.322, 21.664],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.755, 21.857],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.166, 22.044],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.625, 22.222],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.074, 22.377],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.513, 22.554],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.987, 22.7],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.43, 22.839],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.875, 22.961],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.348, 23.105],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.843, 23.196],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.3, 23.316],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.768, 23.409],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.199, 23.506],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.643, 23.595],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.933, 23.74],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.267, 23.818],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.463, 23.956],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.597, 24.095],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.689, 24.23],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.586, 24.407],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.435, 24.565],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.198, 24.742],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.76, 24.953],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.206, 25.139],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.517, 25.325],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.59, 25.536],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.476, 25.729],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.867, 25.906],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-3.508, 26.049],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.457, 26.151],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.737, 26.156],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.428, 26.045],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.481, 25.686],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.895, 24.998],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.535, 23.766],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.07, 21.663],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.896, 18.283],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.194, 13.226],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.338, 6.445],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-35.344, -1.586],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.853, -10.031],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.514, -18.307],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.893, -26.053],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.259, -33.153],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.835, -39.539],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.619, -45.321],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.644, -50.554],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.921, -55.311],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.574, -59.628],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.834, -63.579],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.869, -67.169],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.708, -70.486],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.417, -73.573],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.952, -76.426],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.311, -79.05],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.556, -81.495],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.65, -83.777],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.655, -85.939],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.53, -87.915],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.3, -89.791],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.011, -91.557],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.58, -93.216],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.093, -94.76],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.522, -96.22],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.862, -97.596],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.141, -98.891],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.359, -100.116],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.487, -101.254],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.596, -102.336],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.633, -103.373],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.628, -104.366],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.517, -105.244],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.406, -106.109],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.254, -106.945],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.026, -107.701],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.762, -108.41],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.447, -109.093],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.113, -109.722],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.731, -110.326],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.32, -110.888],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.853, -111.409],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.39, -111.923],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.837, -112.345],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.288, -112.799],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.696, -113.178],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.098, -113.561],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.436, -113.888],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.781, -114.214],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.077, -114.491],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.353, -114.742],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.592, -114.97],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.825, -115.202],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54, -115.374],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.181, -115.527],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.318, -115.672],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.459, -115.803],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.564, -115.902],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.671, -115.988],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.726, -116.05],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.75, -116.078],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.757, -116.123],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.588, -116.068],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.302, -115.967],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.915, -115.825],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.34, -115.647],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.644, -115.418],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.788, -115.119],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.754, -114.773],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.514, -114.403],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.058, -113.908],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.376, -113.309],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.426, -112.642],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.172, -111.875],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.61, -110.963],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.663, -109.886],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.324, -108.659],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.463, -107.277],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.06, -105.587],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.031, -103.632],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.271, -101.348],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.666, -98.61],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.081, -95.354],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.6, -91.478],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.442, -86.848],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.386, -81.392],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-41.154, -75.124],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.261, -68.179],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.979, -60.977],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.693, -53.777],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.96, -46.972],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.544, -40.751],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.212, -35.368],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.814, -30.85],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.221, -27.327],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.587, -24.73],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.324, -22.891],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.967, -21.624],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.976, -20.701],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.685, -20.052],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.265, -19.532],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.866, -19.128],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.56, -18.817],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.325, -18.514],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.277, -18.281],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.393, -18.072],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.607, -17.883],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.997, -17.691],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.522, -17.522],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.433, -18.343],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.07, -20.382],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.81, -22.34],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.59, -24.202],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.435, -26.006],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.298, -27.681],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.18, -29.278],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.075, -30.799],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.014, -32.228],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.953, -33.593],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.929, -34.899],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.903, -36.1],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.846, -37.241],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.86, -38.323],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.826, -39.35],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.826, -40.319],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.779, -41.211],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.763, -42.079],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.781, -42.875],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.767, -43.63],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.738, -44.313],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.739, -45.027],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.735, -45.609],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.735, -46.202],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.725, -46.718],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.711, -47.199],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.72, -47.67],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.695, -48.056],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.71, -48.453],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.711, -48.819],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.668, -49.111],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.671, -49.405],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.698, -49.678],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.669, -49.872],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.659, -50.065],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.687, -50.243],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.651, -50.394],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.652, -50.485],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.635, -50.558],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.632, -50.607],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.635, -50.675],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 13',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 13,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.601, 42.402],
                                  [-21.609, 37.591],
                                  [-4.812, -235.996],
                                ],
                                c: false,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.61, 42.403],
                                  [-21.603, 37.596],
                                  [-4.807, -235.941],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.566, 42.522],
                                  [-21.488, 37.556],
                                  [-4.903, -235.837],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.485, 42.643],
                                  [-21.362, 37.464],
                                  [-4.983, -235.63],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.36, 42.819],
                                  [-21.181, 37.389],
                                  [-5.172, -235.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.253, 43.06],
                                  [-20.874, 37.269],
                                  [-5.337, -234.892],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.047, 43.365],
                                  [-20.503, 37.112],
                                  [-5.594, -234.314],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.859, 43.726],
                                  [-20.077, 36.91],
                                  [-5.9, -233.634],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.582, 44.206],
                                  [-19.571, 36.679],
                                  [-6.29, -232.85],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.26, 44.75],
                                  [-18.895, 36.456],
                                  [-6.765, -231.901],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.911, 45.405],
                                  [-18.202, 36.149],
                                  [-7.272, -230.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.482, 46.178],
                                  [-17.33, 35.768],
                                  [-7.856, -229.45],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.017, 47.024],
                                  [-16.271, 35.348],
                                  [-8.571, -227.939],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.419, 48.035],
                                  [-15.126, 34.916],
                                  [-9.355, -226.163],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.803, 49.173],
                                  [-13.74, 34.363],
                                  [-10.261, -224.194],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.009, 50.484],
                                  [-12.215, 33.744],
                                  [-11.274, -221.862],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.199, 52.022],
                                  [-10.378, 33.066],
                                  [-12.437, -219.22],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.174, 53.749],
                                  [-8.323, 32.34],
                                  [-13.751, -216.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.044, 55.766],
                                  [-5.949, 31.429],
                                  [-15.298, -212.768],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.757, 58.018],
                                  [-3.178, 30.442],
                                  [-17.013, -208.752],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.284, 60.675],
                                  [0.053, 29.351],
                                  [-18.985, -204.178],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.53, 63.746],
                                  [3.824, 28.06],
                                  [-21.225, -198.904],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.513, 67.285],
                                  [8.134, 26.605],
                                  [-23.783, -192.77],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.237, 71.353],
                                  [13.189, 24.951],
                                  [-26.711, -185.679],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.57, 76.058],
                                  [19.042, 23.084],
                                  [-30.051, -177.538],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.569, 81.398],
                                  [25.693, 20.99],
                                  [-33.842, -168.297],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.221, 87.266],
                                  [33.037, 18.711],
                                  [-37.897, -158.166],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-124.758, 93.483],
                                  [40.823, 16.288],
                                  [-42.236, -147.511],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.334, 99.707],
                                  [48.623, 13.941],
                                  [-46.541, -136.763],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-117.998, 105.743],
                                  [56.116, 11.645],
                                  [-50.7, -126.454],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-114.992, 111.323],
                                  [63.059, 9.491],
                                  [-54.58, -116.826],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-112.291, 116.483],
                                  [69.371, 7.511],
                                  [-58.093, -108.137],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-109.904, 121.133],
                                  [75.04, 5.749],
                                  [-61.295, -100.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-107.785, 125.32],
                                  [80.137, 4.113],
                                  [-64.235, -93.134],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-105.936, 129.15],
                                  [84.676, 2.663],
                                  [-66.896, -86.774],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-104.367, 132.634],
                                  [88.709, 1.327],
                                  [-69.317, -80.994],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-102.999, 135.833],
                                  [92.367, 0.094],
                                  [-71.525, -75.793],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-101.772, 138.75],
                                  [95.595, -1.055],
                                  [-73.514, -71.074],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-100.803, 141.427],
                                  [98.516, -2.13],
                                  [-75.427, -66.785],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-99.954, 143.886],
                                  [101.125, -3.115],
                                  [-77.159, -62.872],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-99.271, 146.221],
                                  [103.491, -4.045],
                                  [-78.79, -59.294],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.724, 148.338],
                                  [105.602, -4.93],
                                  [-80.278, -56.051],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.36, 150.344],
                                  [107.421, -5.711],
                                  [-81.721, -53.03],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.085, 152.228],
                                  [109.091, -6.526],
                                  [-83.048, -50.366],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-97.958, 154.001],
                                  [110.524, -7.229],
                                  [-84.323, -47.874],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-97.981, 155.674],
                                  [111.758, -7.926],
                                  [-85.527, -45.628],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.116, 157.237],
                                  [112.801, -8.597],
                                  [-86.67, -43.63],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.418, 158.732],
                                  [113.65, -9.243],
                                  [-87.743, -41.778],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.785, 160.139],
                                  [114.366, -9.874],
                                  [-88.747, -40.124],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-99.32, 161.477],
                                  [114.887, -10.479],
                                  [-89.721, -38.668],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-99.979, 162.768],
                                  [115.279, -11.047],
                                  [-90.626, -37.409],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-100.744, 163.999],
                                  [115.537, -11.547],
                                  [-91.539, -36.279],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-101.604, 165.131],
                                  [115.642, -12.097],
                                  [-92.366, -35.308],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-102.555, 166.25],
                                  [115.696, -12.565],
                                  [-93.107, -34.404],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-103.531, 167.268],
                                  [115.614, -13.038],
                                  [-93.848, -33.681],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-104.601, 168.282],
                                  [115.512, -13.451],
                                  [-94.549, -33.067],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-105.67, 169.228],
                                  [115.316, -13.829],
                                  [-95.182, -32.502],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-106.698, 170.106],
                                  [115.139, -14.194],
                                  [-95.774, -31.989],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-107.783, 170.975],
                                  [114.88, -14.56],
                                  [-96.299, -31.619],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.829, 171.738],
                                  [114.705, -14.828],
                                  [-96.834, -31.212],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-109.804, 172.541],
                                  [114.458, -15.136],
                                  [-97.255, -30.902],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-110.779, 173.225],
                                  [114.217, -15.351],
                                  [-97.677, -30.609],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-111.725, 173.9],
                                  [114.002, -15.593],
                                  [-98.04, -30.331],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-112.62, 174.519],
                                  [113.84, -15.796],
                                  [-98.408, -30.13],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-113.453, 175.117],
                                  [113.604, -15.961],
                                  [-98.721, -29.894],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-114.287, 175.66],
                                  [113.449, -16.158],
                                  [-98.982, -29.748],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-115.061, 176.196],
                                  [113.244, -16.275],
                                  [-99.255, -29.554],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-115.826, 176.679],
                                  [113.051, -16.426],
                                  [-99.48, -29.452],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-116.536, 177.141],
                                  [112.898, -16.547],
                                  [-99.706, -29.316],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-117.256, 177.551],
                                  [112.718, -16.673],
                                  [-99.888, -29.211],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-117.878, 177.957],
                                  [112.558, -16.742],
                                  [-100.089, -29.121],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-118.56, 178.358],
                                  [112.367, -16.834],
                                  [-100.28, -29.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.149, 178.708],
                                  [112.205, -16.917],
                                  [-100.445, -29.038],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.756, 179.039],
                                  [112.008, -17.009],
                                  [-100.556, -28.998],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.319, 179.32],
                                  [111.821, -17.064],
                                  [-100.676, -28.977],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.903, 179.644],
                                  [111.659, -17.128],
                                  [-100.82, -28.989],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.4, 179.873],
                                  [111.456, -17.188],
                                  [-100.913, -28.99],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.921, 180.145],
                                  [111.274, -17.259],
                                  [-101.015, -29.025],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-122.45, 180.368],
                                  [111.033, -17.295],
                                  [-101.114, -29.081],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-122.911, 180.573],
                                  [110.827, -17.344],
                                  [-101.193, -29.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.4, 180.729],
                                  [110.606, -17.389],
                                  [-101.27, -29.206],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.854, 180.93],
                                  [110.417, -17.448],
                                  [-101.312, -29.324],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-124.29, 181.083],
                                  [110.182, -17.503],
                                  [-101.415, -29.387],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-124.738, 181.233],
                                  [109.944, -17.527],
                                  [-101.468, -29.516],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-125.125, 181.337],
                                  [109.72, -17.565],
                                  [-101.49, -29.638],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-125.542, 181.438],
                                  [109.447, -17.601],
                                  [-101.572, -29.751],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-125.991, 181.522],
                                  [109.186, -17.651],
                                  [-101.622, -29.902],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-126.363, 181.621],
                                  [108.92, -17.655],
                                  [-101.671, -30.075],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-126.705, 181.702],
                                  [108.665, -17.719],
                                  [-101.719, -30.258],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-127.097, 181.736],
                                  [108.375, -17.736],
                                  [-101.736, -30.417],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-127.46, 181.769],
                                  [108.079, -17.769],
                                  [-101.769, -30.614],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-127.839, 181.755],
                                  [107.763, -17.818],
                                  [-101.721, -30.76],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.28, 181.667],
                                  [107.491, -17.883],
                                  [-101.621, -30.82],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.813, 181.505],
                                  [107.126, -17.964],
                                  [-101.315, -30.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.39, 181.206],
                                  [106.823, -18.097],
                                  [-100.957, -30.663],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.102, 180.834],
                                  [106.472, -18.246],
                                  [-100.402, -30.428],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.842, 180.344],
                                  [106.092, -18.429],
                                  [-99.686, -30.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.672, 179.736],
                                  [105.728, -18.647],
                                  [-98.836, -29.586],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.681, 179.011],
                                  [105.29, -18.918],
                                  [-97.745, -28.935],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.762, 178.107],
                                  [104.841, -19.223],
                                  [-96.473, -28.171],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.942, 177.06],
                                  [104.381, -19.564],
                                  [-94.97, -27.278],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-136.327, 175.869],
                                  [103.866, -19.976],
                                  [-93.188, -26.149],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.856, 174.428],
                                  [103.331, -20.458],
                                  [-91.128, -24.801],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.554, 172.783],
                                  [102.759, -20.994],
                                  [-88.772, -23.263],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.483, 170.924],
                                  [102.123, -21.62],
                                  [-86.038, -21.444],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.635, 168.79],
                                  [101.442, -22.336],
                                  [-82.91, -19.326],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.071, 166.292],
                                  [100.707, -23.141],
                                  [-79.305, -16.911],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.817, 163.431],
                                  [99.874, -24.073],
                                  [-75.143, -14.109],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.971, 160.171],
                                  [98.98, -25.15],
                                  [-70.371, -10.84],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.496, 156.389],
                                  [97.972, -26.389],
                                  [-64.844, -7.042],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.568, 152.059],
                                  [96.824, -27.809],
                                  [-58.462, -2.67],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.223, 146.994],
                                  [95.537, -29.465],
                                  [-51.065, 2.427],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.582, 141.152],
                                  [94.088, -31.375],
                                  [-42.482, 8.347],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.785, 134.375],
                                  [92.46, -33.577],
                                  [-32.531, 15.197],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.839, 126.592],
                                  [90.603, -36.124],
                                  [-21.069, 23.128],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.767, 117.807],
                                  [88.52, -39.019],
                                  [-8.058, 32.158],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.504, 108.092],
                                  [86.265, -42.209],
                                  [6.291, 42.098],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.729, 97.814],
                                  [83.901, -45.585],
                                  [21.484, 52.609],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.98, 87.488],
                                  [81.534, -49.006],
                                  [36.808, 63.228],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-226.861, 77.585],
                                  [79.274, -52.29],
                                  [51.552, 73.401],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-235.108, 68.36],
                                  [77.217, -55.364],
                                  [65.314, 82.897],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.638, 59.926],
                                  [75.348, -58.19],
                                  [77.902, 91.572],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.4, 52.307],
                                  [73.647, -60.768],
                                  [89.332, 99.446],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.548, 45.493],
                                  [72.13, -63.096],
                                  [99.657, 106.518],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-261.093, 39.286],
                                  [70.778, -65.21],
                                  [109.036, 112.913],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.117, 33.677],
                                  [69.562, -67.145],
                                  [117.532, 118.74],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-270.666, 28.575],
                                  [68.429, -68.918],
                                  [125.339, 124.041],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-274.839, 23.909],
                                  [67.457, -70.548],
                                  [132.449, 128.881],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.662, 19.634],
                                  [66.531, -72.05],
                                  [139.013, 133.321],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-282.164, 15.749],
                                  [65.694, -73.427],
                                  [145.058, 137.379],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-285.415, 12.138],
                                  [64.91, -74.712],
                                  [150.672, 141.146],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-288.417, 8.801],
                                  [64.224, -75.925],
                                  [155.847, 144.682],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-291.179, 5.675],
                                  [63.565, -77.046],
                                  [160.708, 147.925],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-293.781, 2.814],
                                  [62.975, -78.094],
                                  [165.209, 150.957],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-296.17, 0.119],
                                  [62.43, -79.069],
                                  [169.432, 153.758],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-298.408, -2.418],
                                  [61.901, -79.988],
                                  [173.401, 156.374],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.542, -4.754],
                                  [61.415, -80.87],
                                  [177.128, 158.885],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.489, -6.932],
                                  [60.99, -81.678],
                                  [180.593, 161.185],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-304.357, -8.997],
                                  [60.555, -82.448],
                                  [183.886, 163.361],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-306.058, -10.95],
                                  [60.198, -83.181],
                                  [186.971, 165.413],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-307.726, -12.745],
                                  [59.813, -83.876],
                                  [189.902, 167.361],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-309.253, -14.492],
                                  [59.479, -84.515],
                                  [192.652, 169.142],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-310.684, -16.089],
                                  [59.179, -85.135],
                                  [195.22, 170.861],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-312.044, -17.592],
                                  [58.869, -85.717],
                                  [197.688, 172.457],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-313.328, -19.009],
                                  [58.565, -86.261],
                                  [200.019, 173.967],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.516, -20.358],
                                  [58.313, -86.785],
                                  [202.205, 175.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-315.679, -21.64],
                                  [58.067, -87.271],
                                  [204.272, 176.741],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-316.729, -22.836],
                                  [57.873, -87.738],
                                  [206.194, 178.006],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-317.746, -23.945],
                                  [57.641, -88.184],
                                  [208.059, 179.228],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-318.675, -25.006],
                                  [57.416, -88.612],
                                  [209.824, 180.389],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-319.598, -26.006],
                                  [57.26, -89.001],
                                  [211.444, 181.427],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-320.408, -26.957],
                                  [57.067, -89.37],
                                  [213.006, 182.422],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-321.237, -27.848],
                                  [56.898, -89.738],
                                  [214.487, 183.418],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-321.972, -28.671],
                                  [56.736, -90.068],
                                  [215.884, 184.292],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-322.664, -29.472],
                                  [56.598, -90.377],
                                  [217.181, 185.122],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-323.349, -30.168],
                                  [56.467, -90.686],
                                  [218.396, 185.954],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-323.948, -30.903],
                                  [56.335, -90.956],
                                  [219.571, 186.663],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-324.54, -31.534],
                                  [56.209, -91.226],
                                  [220.664, 187.373],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.09, -32.142],
                                  [56.107, -91.475],
                                  [221.675, 188.04],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.615, -32.708],
                                  [55.986, -91.704],
                                  [222.647, 188.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-326.072, -33.251],
                                  [55.889, -91.932],
                                  [223.555, 189.254],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-326.505, -33.752],
                                  [55.817, -92.122],
                                  [224.362, 189.737],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-326.957, -34.211],
                                  [55.726, -92.329],
                                  [225.167, 190.285],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-327.322, -34.629],
                                  [55.659, -92.498],
                                  [225.871, 190.728],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-327.663, -35.023],
                                  [55.572, -92.666],
                                  [226.555, 191.172],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.024, -35.376],
                                  [55.509, -92.813],
                                  [227.175, 191.554],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.298, -35.73],
                                  [55.446, -92.96],
                                  [227.757, 191.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.592, -36.043],
                                  [55.407, -93.087],
                                  [228.255, 192.261],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.818, -36.333],
                                  [55.349, -93.194],
                                  [228.753, 192.541],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.045, -36.581],
                                  [55.314, -93.299],
                                  [229.167, 192.803],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.248, -36.787],
                                  [55.26, -93.404],
                                  [229.562, 193.086],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.47, -36.995],
                                  [55.249, -93.489],
                                  [229.893, 193.288],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.587, -37.161],
                                  [55.219, -93.554],
                                  [230.185, 193.466],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.743, -37.303],
                                  [55.169, -93.618],
                                  [230.476, 193.627],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.855, -37.448],
                                  [55.161, -93.68],
                                  [230.684, 193.789],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.944, -37.551],
                                  [55.135, -93.724],
                                  [230.872, 193.908],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-330.015, -37.656],
                                  [55.151, -93.766],
                                  [231.015, 194.01],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-330.081, -37.737],
                                  [55.104, -93.788],
                                  [231.163, 194.069],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-330.147, -37.758],
                                  [55.124, -93.809],
                                  [231.204, 194.11],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-330.171, -37.799],
                                  [55.101, -93.829],
                                  [231.287, 194.171],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-330.134, -37.767],
                                  [55.057, -93.822],
                                  [231.222, 194.134],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-330.088, -37.801],
                                  [54.988, -93.774],
                                  [231, 193.919],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.971, -37.752],
                                  [54.785, -93.665],
                                  [230.69, 193.589],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.803, -37.734],
                                  [54.58, -93.537],
                                  [230.182, 193.146],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.629, -37.699],
                                  [54.222, -93.364],
                                  [229.477, 192.528],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.344, -37.651],
                                  [53.816, -93.144],
                                  [228.617, 191.692],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.013, -37.618],
                                  [53.323, -92.857],
                                  [227.563, 190.703],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.574, -37.533],
                                  [52.699, -92.517],
                                  [226.269, 189.499],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.109, -37.425],
                                  [51.944, -92.167],
                                  [224.761, 188.082],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-327.56, -37.318],
                                  [51.081, -91.697],
                                  [222.973, 186.429],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-326.865, -37.19],
                                  [50.093, -91.12],
                                  [220.888, 184.478],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-326.063, -37.028],
                                  [48.977, -90.495],
                                  [218.51, 182.169],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.184, -36.856],
                                  [47.654, -89.779],
                                  [215.778, 179.541],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-324.114, -36.636],
                                  [46.176, -88.913],
                                  [212.655, 176.514],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-322.902, -36.395],
                                  [44.518, -87.916],
                                  [209.07, 172.976],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-321.488, -36.096],
                                  [42.609, -86.776],
                                  [205.025, 168.973],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-319.867, -35.706],
                                  [40.418, -85.516],
                                  [200.373, 164.336],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-317.997, -35.27],
                                  [37.977, -83.97],
                                  [195.129, 158.952],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-315.775, -34.73],
                                  [35.208, -82.205],
                                  [189.156, 152.683],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-313.163, -34.053],
                                  [32.071, -80.17],
                                  [182.349, 145.464],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-310.082, -33.18],
                                  [28.566, -77.76],
                                  [174.589, 137.001],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-306.488, -32.127],
                                  [24.653, -74.936],
                                  [165.814, 127.098],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.213, -30.822],
                                  [20.271, -71.622],
                                  [155.867, 115.484],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-297.172, -29.174],
                                  [15.457, -67.726],
                                  [144.734, 101.981],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-291.27, -27.152],
                                  [10.297, -63.21],
                                  [132.536, 86.475],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.585, -24.706],
                                  [5.022, -58.114],
                                  [119.612, 69.109],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.328, -21.87],
                                  [-0.122, -52.55],
                                  [106.58, 50.428],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-269.782, -18.78],
                                  [-4.81, -46.875],
                                  [94.063, 31.332],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-262.245, -15.574],
                                  [-8.88, -41.202],
                                  [82.64, 12.62],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.024, -12.409],
                                  [-12.272, -35.781],
                                  [72.575, -5.057],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-248.257, -9.308],
                                  [-15.028, -30.697],
                                  [63.811, -21.472],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.004, -6.331],
                                  [-17.289, -26.058],
                                  [56.309, -36.494],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.223, -3.533],
                                  [-19.041, -21.759],
                                  [49.815, -50.301],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-230.852, -0.891],
                                  [-20.446, -17.848],
                                  [44.184, -62.848],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-225.923, 1.587],
                                  [-21.557, -14.23],
                                  [39.321, -74.412],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.36, 3.965],
                                  [-22.42, -10.905],
                                  [35.08, -85.027],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.107, 6.15],
                                  [-23.118, -7.814],
                                  [31.33, -94.824],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.178, 8.258],
                                  [-23.642, -4.936],
                                  [28.037, -103.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.523, 10.207],
                                  [-24.068, -2.286],
                                  [25.097, -112.291],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.074, 12.082],
                                  [-24.378, 0.206],
                                  [22.46, -120.103],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.866, 13.808],
                                  [-24.614, 2.536],
                                  [20.104, -127.363],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.837, 15.487],
                                  [-24.773, 4.657],
                                  [17.987, -134.169],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.022, 17.038],
                                  [-24.89, 6.74],
                                  [16.08, -140.555],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.374, 18.514],
                                  [-24.96, 8.624],
                                  [14.366, -146.528],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.865, 19.961],
                                  [-25.017, 10.405],
                                  [12.778, -152.147],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.478, 21.3],
                                  [-25.002, 12.097],
                                  [11.354, -157.45],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.236, 22.538],
                                  [-24.989, 13.714],
                                  [10.019, -162.424],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.122, 23.773],
                                  [-24.918, 15.229],
                                  [8.822, -167.15],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.104, 24.899],
                                  [-24.862, 16.673],
                                  [7.751, -171.596],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.226, 26.007],
                                  [-24.76, 18.029],
                                  [6.69, -175.78],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.449, 27.05],
                                  [-24.676, 19.255],
                                  [5.795, -179.691],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.753, 28.018],
                                  [-24.567, 20.503],
                                  [4.907, -183.444],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.105, 28.971],
                                  [-24.476, 21.625],
                                  [4.112, -186.974],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.614, 29.887],
                                  [-24.362, 22.719],
                                  [3.418, -190.343],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.163, 30.731],
                                  [-24.224, 23.734],
                                  [2.71, -193.498],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.807, 31.536],
                                  [-24.072, 24.679],
                                  [2.083, -196.535],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.512, 32.265],
                                  [-23.958, 25.609],
                                  [1.547, -199.347],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.25, 33.006],
                                  [-23.822, 26.517],
                                  [0.976, -202.05],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.094, 33.713],
                                  [-23.725, 27.296],
                                  [0.496, -204.589],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.991, 34.342],
                                  [-23.615, 28.107],
                                  [0.035, -206.964],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.933, 34.979],
                                  [-23.446, 28.801],
                                  [-0.407, -209.219],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.981, 35.572],
                                  [-23.37, 29.529],
                                  [-0.831, -211.364],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.03, 36.136],
                                  [-23.236, 30.185],
                                  [-1.174, -213.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.186, 36.654],
                                  [-23.141, 30.778],
                                  [-1.515, -215.311],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.333, 37.124],
                                  [-22.988, 31.409],
                                  [-1.829, -217.114],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.527, 37.619],
                                  [-22.874, 31.915],
                                  [-2.132, -218.804],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.837, 38.065],
                                  [-22.817, 32.46],
                                  [-2.371, -220.383],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.14, 38.463],
                                  [-22.7, 32.945],
                                  [-2.654, -221.896],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.498, 38.875],
                                  [-22.578, 33.415],
                                  [-2.872, -223.297],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.848, 39.238],
                                  [-22.503, 33.817],
                                  [-3.078, -224.639],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.315, 39.568],
                                  [-22.422, 34.206],
                                  [-3.274, -225.817],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.775, 39.893],
                                  [-22.344, 34.581],
                                  [-3.467, -226.99],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.29, 40.178],
                                  [-22.259, 34.953],
                                  [-3.648, -228.042],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.861, 40.465],
                                  [-22.167, 35.258],
                                  [-3.826, -229.037],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.424, 40.711],
                                  [-22.131, 35.552],
                                  [-3.938, -229.919],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.051, 40.968],
                                  [-22.042, 35.834],
                                  [-4.046, -230.744],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.671, 41.166],
                                  [-22, 36.105],
                                  [-4.204, -231.511],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.347, 41.376],
                                  [-21.959, 36.311],
                                  [-4.295, -232.22],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.069, 41.542],
                                  [-21.856, 36.515],
                                  [-4.39, -232.863],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.802, 41.703],
                                  [-21.817, 36.709],
                                  [-4.48, -233.448],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.527, 41.828],
                                  [-21.823, 36.893],
                                  [-4.557, -233.922],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.37, 41.948],
                                  [-21.767, 37.067],
                                  [-4.583, -234.391],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.152, 42.079],
                                  [-21.72, 37.179],
                                  [-4.657, -234.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.043, 42.157],
                                  [-21.726, 37.281],
                                  [-4.734, -235.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.873, 42.237],
                                  [-21.67, 37.374],
                                  [-4.742, -235.402],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.767, 42.274],
                                  [-21.667, 37.468],
                                  [-4.752, -235.579],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.709, 42.367],
                                  [-21.618, 37.49],
                                  [-4.81, -235.762],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.659, 42.362],
                                  [-21.614, 37.567],
                                  [-4.815, -235.879],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.651, 0.651, 0.651, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.651, 0.651, 0.651, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-17.257, -33.286],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.253, -33.249],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.197, -33.175],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.131, -33.088],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.053, -32.928],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.882, -32.662],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.687, -32.35],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.467, -31.968],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.209, -31.548],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.847, -30.973],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.479, -30.336],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.995, -29.605],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.398, -28.694],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.743, -27.669],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.922, -26.484],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.998, -25.148],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.84, -23.514],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.503, -21.704],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.904, -19.621],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.931, -17.149],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-3.504, -14.288],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.491, -11.034],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.202, -7.297],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.89, -3.021],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.807, 1.691],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.201, 6.707],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.181, 11.591],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.606, 15.861],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.216, 16.67],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.864, 17.186],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.955, 17.557],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.343, 17.726],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.824, 17.738],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.325, 17.536],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.692, 17.251],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.846, 16.876],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.853, 16.431],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.599, 15.943],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.154, 15.416],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.49, 14.894],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.632, 14.378],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.555, 13.858],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.214, 13.404],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.726, 12.91],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.016, 12.497],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.084, 12.101],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.954, 11.725],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.582, 11.405],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.067, 11.102],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.322, 10.846],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.408, 10.651],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.36, 10.511],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.11, 10.363],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.774, 10.312],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.316, 10.245],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.809, 10.247],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.202, 10.288],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.633, 10.32],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.938, 10.389],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.388, 10.475],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.732, 10.577],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.132, 10.702],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.543, 10.816],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.047, 10.923],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.487, 11.067],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.995, 11.177],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.492, 11.336],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.992, 11.466],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.561, 11.596],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.091, 11.727],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.695, 11.869],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.239, 12.013],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.852, 12.13],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.404, 12.262],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77, 12.394],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.614, 12.523],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.215, 12.635],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.824, 12.75],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.383, 12.886],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.995, 12.997],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.59, 13.104],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.208, 13.214],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.814, 13.298],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.403, 13.42],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.022, 13.517],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.602, 13.608],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.172, 13.696],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.771, 13.809],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.383, 13.869],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.945, 13.971],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.518, 14.051],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.04, 14.137],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.571, 14.212],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.904, 14.35],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.272, 14.43],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.474, 14.571],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.583, 14.717],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.631, 14.86],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.451, 15.05],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.191, 15.223],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.802, 15.419],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.17, 15.653],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.39, 15.856],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.405, 16.074],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.134, 16.31],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.606, 16.53],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.763, 16.732],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.518, 16.909],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.87, 17.025],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.739, 17.045],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.022, 16.95],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.713, 16.609],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.763, 15.949],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.2, 14.797],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.203, 12.91],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.183, 10.022],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.192, 5.893],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.167, 0.512],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.251, -5.811],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.542, -12.489],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.43, -19.059],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.432, -25.212],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.898, -30.825],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.145, -35.831],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.261, -40.323],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.338, -44.348],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.437, -47.969],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-2.587, -51.219],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.82, -54.162],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.873, -56.809],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.482, -59.235],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.037, -61.464],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.529, -63.513],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.914, -65.38],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.246, -67.104],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.5, -68.707],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.709, -70.207],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.842, -71.571],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.917, -72.859],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.96, -74.062],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.942, -75.192],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.855, -76.231],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.742, -77.213],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.582, -78.134],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.361, -78.99],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.121, -79.802],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.832, -80.557],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.521, -81.266],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.162, -81.942],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.804, -82.589],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.365, -83.168],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.922, -83.724],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.446, -84.263],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.931, -84.749],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.406, -85.208],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.855, -85.649],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.267, -86.05],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.653, -86.435],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.016, -86.791],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.35, -87.122],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.714, -87.45],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.997, -87.722],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.295, -88.012],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.552, -88.251],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.808, -88.49],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.007, -88.695],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.23, -88.901],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.408, -89.076],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.584, -89.232],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.74, -89.378],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.902, -89.523],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.006, -89.635],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.128, -89.73],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.215, -89.82],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.312, -89.905],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.384, -89.967],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.455, -90.022],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.487, -90.06],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.497, -90.081],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.536, -90.111],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.537, -90.112],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.507, -90.104],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.348, -90.047],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.093, -89.931],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.757, -89.788],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.244, -89.597],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.621, -89.35],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.859, -89.033],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.944, -88.659],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.871, -88.268],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.596, -87.749],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.114, -87.117],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.4, -86.421],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.405, -85.627],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.167, -84.677],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.555, -83.574],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.601, -82.32],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.229, -80.928],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.323, -79.229],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.117, -77.292],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.175, -75.061],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.004, -72.419],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.69, -69.332],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.337, -65.725],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.984, -61.506],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.539, -56.653],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.732, -51.221],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.027, -45.367],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.61, -39.474],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.74, -33.731],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.736, -28.419],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.118, -23.627],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.618, -19.445],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.494, -15.763],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.398, -12.533],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.062, -9.624],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.111, -6.967],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.92, -4.517],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.732, -2.215],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56.679, -0.089],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.806, 1.934],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.185, 3.837],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.808, 5.587],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.641, 7.324],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.773, 8.905],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.017, 10.405],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.095, 7.869],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.323, 5.332],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.642, 2.925],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.08, 0.609],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.584, -1.544],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.172, -3.624],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.799, -5.569],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.489, -7.468],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.197, -9.227],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.93, -10.896],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.677, -12.487],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.47, -13.976],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.274, -15.403],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.122, -16.774],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.973, -18.029],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.798, -19.223],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.703, -20.356],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.561, -21.427],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.464, -22.446],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.324, -23.371],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.224, -24.289],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.161, -25.112],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.071, -25.908],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.969, -26.625],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.904, -27.371],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.843, -27.983],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.784, -28.606],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.723, -29.143],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.662, -29.653],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.629, -30.142],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.561, -30.549],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.54, -30.961],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.508, -31.35],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.436, -31.653],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.41, -31.96],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.415, -32.245],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.367, -32.443],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.338, -32.65],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.352, -32.834],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.303, -32.992],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.296, -33.087],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.272, -33.169],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.265, -33.213],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.263, -33.292],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-17.257, -33.286],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.253, -33.249],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.197, -33.175],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.131, -33.088],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.053, -32.928],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.882, -32.662],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.687, -32.35],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.467, -31.968],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.209, -31.548],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.847, -30.973],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.479, -30.336],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.995, -29.605],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.398, -28.694],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.743, -27.669],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.922, -26.484],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.998, -25.148],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.84, -23.514],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.503, -21.704],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.904, -19.621],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.931, -17.149],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-3.504, -14.288],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.491, -11.034],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.202, -7.297],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.89, -3.021],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.807, 1.691],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.201, 6.707],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.181, 11.591],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.606, 15.861],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.216, 16.67],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.864, 17.186],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.955, 17.557],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.343, 17.726],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.824, 17.738],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.325, 17.536],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.692, 17.251],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.846, 16.876],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.853, 16.431],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.599, 15.943],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.154, 15.416],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.49, 14.894],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.632, 14.378],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.555, 13.858],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.214, 13.404],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.726, 12.91],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.016, 12.497],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.084, 12.101],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.954, 11.725],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.582, 11.405],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.067, 11.102],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.322, 10.846],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.408, 10.651],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.36, 10.511],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.11, 10.363],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.774, 10.312],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.316, 10.245],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.809, 10.247],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.202, 10.288],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.633, 10.32],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.938, 10.389],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.388, 10.475],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.732, 10.577],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.132, 10.702],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.543, 10.816],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.047, 10.923],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.487, 11.067],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.995, 11.177],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.492, 11.336],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.992, 11.466],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.561, 11.596],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.091, 11.727],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.695, 11.869],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.239, 12.013],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.852, 12.13],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.404, 12.262],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77, 12.394],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.614, 12.523],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.215, 12.635],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.824, 12.75],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.383, 12.886],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.995, 12.997],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.59, 13.104],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.208, 13.214],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.814, 13.298],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.403, 13.42],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.022, 13.517],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.602, 13.608],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.172, 13.696],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.771, 13.809],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.383, 13.869],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.945, 13.971],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.518, 14.051],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.04, 14.137],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.571, 14.212],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.904, 14.35],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.272, 14.43],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.474, 14.571],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.583, 14.717],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.631, 14.86],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.451, 15.05],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.191, 15.223],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.802, 15.419],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.17, 15.653],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.39, 15.856],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.405, 16.074],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.134, 16.31],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.606, 16.53],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.763, 16.732],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.518, 16.909],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.87, 17.025],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.739, 17.045],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.022, 16.95],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.713, 16.609],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.763, 15.949],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.2, 14.797],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.203, 12.91],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.183, 10.022],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.192, 5.893],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.167, 0.512],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.251, -5.811],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.542, -12.489],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.43, -19.059],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.432, -25.212],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.898, -30.825],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.145, -35.831],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.261, -40.323],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.338, -44.348],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.437, -47.969],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-2.587, -51.219],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.82, -54.162],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.873, -56.809],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.482, -59.235],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.037, -61.464],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.529, -63.513],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.914, -65.38],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.246, -67.104],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.5, -68.707],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.709, -70.207],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.842, -71.571],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.917, -72.859],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.96, -74.062],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.942, -75.192],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.855, -76.231],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.742, -77.213],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.582, -78.134],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.361, -78.99],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.121, -79.802],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.832, -80.557],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.521, -81.266],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.162, -81.942],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.804, -82.589],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.365, -83.168],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.922, -83.724],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.446, -84.263],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.931, -84.749],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.406, -85.208],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.855, -85.649],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.267, -86.05],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.653, -86.435],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.016, -86.791],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.35, -87.122],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.714, -87.45],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.997, -87.722],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.295, -88.012],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.552, -88.251],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.808, -88.49],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.007, -88.695],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.23, -88.901],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.408, -89.076],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.584, -89.232],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.74, -89.378],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.902, -89.523],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.006, -89.635],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.128, -89.73],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.215, -89.82],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.312, -89.905],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.384, -89.967],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.455, -90.022],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.487, -90.06],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.497, -90.081],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.536, -90.111],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.537, -90.112],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.507, -90.104],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.348, -90.047],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.093, -89.931],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.757, -89.788],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.244, -89.597],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.621, -89.35],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.859, -89.033],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.944, -88.659],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.871, -88.268],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.596, -87.749],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.114, -87.117],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.4, -86.421],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.405, -85.627],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.167, -84.677],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.555, -83.574],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.601, -82.32],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.229, -80.928],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.323, -79.229],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.117, -77.292],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.175, -75.061],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.004, -72.419],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.69, -69.332],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.337, -65.725],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.984, -61.506],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.539, -56.653],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.732, -51.221],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.027, -45.367],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.61, -39.474],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.74, -33.731],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.736, -28.419],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.118, -23.627],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.618, -19.445],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.494, -15.763],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.398, -12.533],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.062, -9.624],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.111, -6.967],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.92, -4.517],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.732, -2.215],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56.679, -0.089],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.806, 1.934],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.185, 3.837],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.808, 5.587],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.641, 7.324],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.773, 8.905],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.017, 10.405],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.095, 7.869],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.323, 5.332],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.642, 2.925],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.08, 0.609],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.584, -1.544],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.172, -3.624],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.799, -5.569],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.489, -7.468],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.197, -9.227],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.93, -10.896],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.677, -12.487],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.47, -13.976],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.274, -15.403],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.122, -16.774],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.973, -18.029],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.798, -19.223],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.703, -20.356],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.561, -21.427],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.464, -22.446],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.324, -23.371],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.224, -24.289],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.161, -25.112],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.071, -25.908],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.969, -26.625],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.904, -27.371],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.843, -27.983],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.784, -28.606],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.723, -29.143],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.662, -29.653],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.629, -30.142],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.561, -30.549],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.54, -30.961],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.508, -31.35],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.436, -31.653],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.41, -31.96],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.415, -32.245],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.367, -32.443],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.338, -32.65],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.352, -32.834],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.303, -32.992],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.296, -33.087],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.272, -33.169],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.265, -33.213],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.263, -33.292],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 14',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 14,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.806, 35.203],
                                  [7.194, 68.793],
                                  [33.59, -247.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.753, 35.325],
                                  [7.314, 68.742],
                                  [33.479, -247.823],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.65, 35.448],
                                  [7.438, 68.632],
                                  [33.376, -247.594],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.494, 35.63],
                                  [7.622, 68.531],
                                  [33.156, -247.247],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.346, 35.876],
                                  [7.931, 68.376],
                                  [32.947, -246.787],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.088, 36.185],
                                  [8.302, 68.172],
                                  [32.628, -246.153],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.838, 36.553],
                                  [8.732, 67.912],
                                  [32.255, -245.406],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.481, 37.044],
                                  [9.233, 67.607],
                                  [31.776, -244.545],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.07, 37.598],
                                  [9.913, 67.305],
                                  [31.191, -243.507],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.615, 38.269],
                                  [10.6, 66.899],
                                  [30.56, -242.243],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.057, 39.063],
                                  [11.471, 66.393],
                                  [29.826, -240.81],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.451, 39.924],
                                  [12.528, 65.836],
                                  [28.933, -239.147],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.684, 40.962],
                                  [13.659, 65.241],
                                  [27.943, -237.196],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.877, 42.126],
                                  [15.035, 64.492],
                                  [26.797, -235.023],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-142.856, 43.47],
                                  [16.544, 63.646],
                                  [25.504, -232.449],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.796, 45.052],
                                  [18.363, 62.709],
                                  [24.01, -229.533],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.473, 46.823],
                                  [20.385, 61.685],
                                  [22.316, -226.217],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.004, 48.896],
                                  [22.723, 60.411],
                                  [20.317, -222.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.331, 51.21],
                                  [25.446, 59.006],
                                  [18.078, -217.951],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.41, 53.947],
                                  [28.618, 57.424],
                                  [15.491, -212.882],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.13, 57.109],
                                  [32.308, 55.554],
                                  [12.52, -207.025],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.501, 60.755],
                                  [36.518, 53.414],
                                  [9.11, -200.208],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-127.524, 64.946],
                                  [41.444, 50.957],
                                  [5.17, -192.319],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-124.04, 69.8],
                                  [47.143, 48.144],
                                  [0.65, -183.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.109, 75.316],
                                  [53.616, 44.967],
                                  [-4.5, -172.943],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-115.725, 81.376],
                                  [60.748, 41.48],
                                  [-10.063, -161.631],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-111.159, 87.798],
                                  [68.318, 37.766],
                                  [-16.018, -149.714],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-106.614, 94.224],
                                  [75.896, 34.121],
                                  [-21.954, -137.69],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-102.178, 100.463],
                                  [83.194, 30.565],
                                  [-27.692, -126.135],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.126, 106.223],
                                  [89.966, 27.235],
                                  [-33.041, -115.328],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-94.446, 111.554],
                                  [96.141, 24.181],
                                  [-37.893, -105.553],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-91.143, 116.349],
                                  [101.712, 21.454],
                                  [-42.309, -96.633],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-88.164, 120.663],
                                  [106.745, 18.946],
                                  [-46.344, -88.632],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-85.508, 124.612],
                                  [111.249, 16.718],
                                  [-49.99, -81.43],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-83.18, 128.199],
                                  [115.28, 14.673],
                                  [-53.298, -74.863],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-81.08, 131.489],
                                  [118.965, 12.803],
                                  [-56.312, -68.93],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-79.146, 134.481],
                                  [122.247, 11.069],
                                  [-59.029, -63.52],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-77.499, 137.228],
                                  [125.248, 9.465],
                                  [-61.614, -58.576],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-75.977, 139.741],
                                  [127.966, 7.999],
                                  [-63.953, -54.04],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.634, 142.131],
                                  [130.467, 6.624],
                                  [-66.149, -49.86],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.425, 144.287],
                                  [132.745, 5.333],
                                  [-68.148, -46.037],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.403, 146.324],
                                  [134.749, 4.187],
                                  [-70.062, -42.451],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-71.461, 148.239],
                                  [136.644, 3.025],
                                  [-71.825, -39.233],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-70.658, 150.036],
                                  [138.324, 2.011],
                                  [-73.5, -36.196],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.997, 151.721],
                                  [139.839, 1.031],
                                  [-75.077, -33.41],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.428, 153.289],
                                  [141.193, 0.095],
                                  [-76.562, -30.876],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.009, 154.792],
                                  [142.382, -0.798],
                                  [-77.95, -28.479],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.629, 156.189],
                                  [143.47, -1.652],
                                  [-79.242, -26.278],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.399, 157.521],
                                  [144.394, -2.463],
                                  [-80.488, -24.272],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.269, 158.798],
                                  [145.218, -3.223],
                                  [-81.637, -22.461],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.228, 160.015],
                                  [145.939, -3.889],
                                  [-82.789, -20.781],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.271, 161.119],
                                  [146.517, -4.61],
                                  [-83.837, -19.246],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.395, 162.218],
                                  [147.067, -5.228],
                                  [-84.779, -17.778],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.535, 163.204],
                                  [147.484, -5.848],
                                  [-85.721, -16.496],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.787, 164.189],
                                  [147.888, -6.406],
                                  [-86.611, -15.333],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.04, 165.108],
                                  [148.183, -6.915],
                                  [-87.435, -14.227],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.274, 165.961],
                                  [148.487, -7.417],
                                  [-88.206, -13.179],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.597, 166.809],
                                  [148.689, -7.919],
                                  [-88.912, -12.297],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.901, 167.542],
                                  [148.966, -8.309],
                                  [-89.622, -11.395],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-70.166, 168.326],
                                  [149.146, -8.748],
                                  [-90.211, -10.604],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-70.461, 168.988],
                                  [149.3, -9.079],
                                  [-90.8, -9.851],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-70.77, 169.645],
                                  [149.47, -9.453],
                                  [-91.328, -9.136],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-71.051, 170.243],
                                  [149.668, -9.775],
                                  [-91.857, -8.521],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-71.299, 170.829],
                                  [149.77, -10.048],
                                  [-92.326, -7.887],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-71.575, 171.356],
                                  [149.945, -10.366],
                                  [-92.738, -7.36],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-71.819, 171.88],
                                  [150.036, -10.582],
                                  [-93.156, -6.807],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.087, 172.345],
                                  [150.135, -10.846],
                                  [-93.518, -6.36],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.324, 172.8],
                                  [150.255, -11.063],
                                  [-93.88, -5.896],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.595, 173.198],
                                  [150.334, -11.283],
                                  [-94.188, -5.477],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.784, 173.592],
                                  [150.424, -11.443],
                                  [-94.506, -5.095],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.063, 173.985],
                                  [150.469, -11.614],
                                  [-94.819, -4.759],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.261, 174.321],
                                  [150.53, -11.78],
                                  [-95.088, -4.47],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.498, 174.646],
                                  [150.545, -11.951],
                                  [-95.298, -4.172],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.711, 174.916],
                                  [150.566, -12.072],
                                  [-95.513, -3.913],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.964, 175.238],
                                  [150.601, -12.198],
                                  [-95.74, -3.701],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.14, 175.45],
                                  [150.585, -12.321],
                                  [-95.91, -3.483],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.357, 175.714],
                                  [150.582, -12.45],
                                  [-96.086, -3.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.607, 175.923],
                                  [150.518, -12.531],
                                  [-96.259, -3.18],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.791, 176.123],
                                  [150.474, -12.619],
                                  [-96.392, -3.042],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-75.019, 176.267],
                                  [150.423, -12.704],
                                  [-96.523, -2.953],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-75.227, 176.464],
                                  [150.389, -12.797],
                                  [-96.608, -2.912],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-75.425, 176.606],
                                  [150.302, -12.888],
                                  [-96.752, -2.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-75.658, 176.747],
                                  [150.214, -12.932],
                                  [-96.842, -2.807],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-75.829, 176.833],
                                  [150.134, -12.984],
                                  [-96.885, -2.796],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-76.044, 176.919],
                                  [149.999, -13.034],
                                  [-96.989, -2.781],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-76.305, 176.994],
                                  [149.871, -13.092],
                                  [-97.048, -2.815],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-76.495, 177.078],
                                  [149.742, -13.096],
                                  [-97.105, -2.89],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-76.669, 177.153],
                                  [149.618, -13.162],
                                  [-97.162, -2.969],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-76.898, 177.173],
                                  [149.447, -13.173],
                                  [-97.173, -3.035],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-77.111, 177.192],
                                  [149.273, -13.192],
                                  [-97.192, -3.151],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-77.361, 177.157],
                                  [149.06, -13.22],
                                  [-97.139, -3.21],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-77.703, 177.024],
                                  [148.87, -13.256],
                                  [-97.058, -3.194],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-78.18, 176.793],
                                  [148.544, -13.301],
                                  [-96.78, -3.112],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-78.739, 176.402],
                                  [148.251, -13.373],
                                  [-96.474, -2.867],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-79.487, 175.913],
                                  [147.875, -13.454],
                                  [-96.005, -2.547],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-80.306, 175.274],
                                  [147.425, -13.553],
                                  [-95.391, -2.099],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-81.261, 174.485],
                                  [146.955, -13.671],
                                  [-94.677, -1.533],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-82.457, 173.545],
                                  [146.358, -13.817],
                                  [-93.746, -0.785],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-83.778, 172.394],
                                  [145.698, -13.981],
                                  [-92.661, 0.08],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-85.268, 171.049],
                                  [144.974, -14.164],
                                  [-91.394, 1.073],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-87.042, 169.511],
                                  [144.133, -14.385],
                                  [-89.891, 2.309],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-89.037, 167.665],
                                  [143.195, -14.644],
                                  [-88.151, 3.778],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-91.298, 165.565],
                                  [142.15, -14.932],
                                  [-86.166, 5.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-93.885, 163.176],
                                  [140.955, -15.267],
                                  [-83.863, 7.391],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.823, 160.436],
                                  [139.62, -15.651],
                                  [-81.232, 9.65],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-100.175, 157.24],
                                  [138.111, -16.082],
                                  [-78.201, 12.214],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-103.982, 153.588],
                                  [136.376, -16.582],
                                  [-74.698, 15.189],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.386, 149.403],
                                  [134.434, -17.158],
                                  [-70.693, 18.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-113.364, 144.561],
                                  [132.198, -17.822],
                                  [-66.052, 22.648],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.13, 139.019],
                                  [129.627, -18.582],
                                  [-60.692, 27.248],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-125.758, 132.532],
                                  [126.662, -19.469],
                                  [-54.469, 32.614],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.432, 125.048],
                                  [123.263, -20.492],
                                  [-47.263, 38.827],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-142.341, 116.366],
                                  [119.361, -21.671],
                                  [-38.917, 46.002],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.518, 106.392],
                                  [114.872, -23.035],
                                  [-29.298, 54.308],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.004, 95.126],
                                  [109.798, -24.586],
                                  [-18.384, 63.755],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.677, 82.665],
                                  [104.227, -26.294],
                                  [-6.348, 74.153],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.075, 69.469],
                                  [98.338, -28.103],
                                  [6.403, 85.148],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.543, 56.218],
                                  [92.418, -29.936],
                                  [19.254, 96.258],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.492, 43.486],
                                  [86.732, -31.696],
                                  [31.621, 106.897],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-228.549, 31.61],
                                  [81.474, -33.344],
                                  [43.143, 116.825],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-239.552, 20.74],
                                  [76.668, -34.86],
                                  [53.689, 125.907],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.475, 10.917],
                                  [72.301, -36.244],
                                  [63.267, 134.154],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-258.488, 2.106],
                                  [68.381, -37.494],
                                  [71.906, 141.565],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.625, -5.915],
                                  [64.841, -38.629],
                                  [79.748, 148.265],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-274.018, -13.18],
                                  [61.636, -39.669],
                                  [86.856, 154.37],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-280.721, -19.796],
                                  [58.678, -40.622],
                                  [93.393, 159.933],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-286.873, -25.859],
                                  [56.04, -41.499],
                                  [99.324, 165.017],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-292.518, -31.422],
                                  [53.572, -42.307],
                                  [104.818, 169.683],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-297.7, -36.486],
                                  [51.326, -43.048],
                                  [109.86, 173.942],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.516, -41.199],
                                  [49.225, -43.741],
                                  [114.555, 177.899],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-306.966, -45.563],
                                  [47.322, -44.394],
                                  [118.869, 181.617],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-311.083, -49.64],
                                  [45.52, -44.999],
                                  [122.929, 185.033],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.941, -53.4],
                                  [43.872, -45.564],
                                  [126.679, 188.22],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-318.51, -56.927],
                                  [42.334, -46.09],
                                  [130.194, 191.168],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-321.854, -60.253],
                                  [40.864, -46.586],
                                  [133.514, 193.929],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.025, -63.325],
                                  [39.504, -47.062],
                                  [136.618, 196.576],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-327.951, -66.198],
                                  [38.263, -47.498],
                                  [139.495, 198.994],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-330.747, -68.923],
                                  [37.047, -47.915],
                                  [142.249, 201.288],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-333.31, -71.501],
                                  [35.959, -48.311],
                                  [144.806, 203.458],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-335.795, -73.879],
                                  [34.885, -48.687],
                                  [147.251, 205.513],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-338.09, -76.172],
                                  [33.897, -49.032],
                                  [149.541, 207.392],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-340.246, -78.299],
                                  [32.984, -49.368],
                                  [151.675, 209.21],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-342.307, -80.288],
                                  [32.095, -49.683],
                                  [153.727, 210.903],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.239, -82.174],
                                  [31.239, -49.977],
                                  [155.676, 212.492],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-346.033, -83.965],
                                  [30.468, -50.261],
                                  [157.489, 214.01],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-347.784, -85.662],
                                  [29.73, -50.524],
                                  [159.21, 215.424],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-349.388, -87.255],
                                  [29.077, -50.777],
                                  [160.794, 216.766],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-350.917, -88.744],
                                  [28.404, -51.019],
                                  [162.348, 218.056],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-352.34, -90.149],
                                  [27.764, -51.251],
                                  [163.82, 219.285],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-353.731, -91.493],
                                  [27.219, -51.462],
                                  [165.155, 220.389],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-354.975, -92.752],
                                  [26.653, -51.662],
                                  [166.46, 221.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.229, -93.951],
                                  [26.13, -51.862],
                                  [167.691, 222.496],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.345, -95.055],
                                  [25.639, -52.041],
                                  [168.849, 223.426],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-358.41, -96.118],
                                  [25.191, -52.209],
                                  [169.924, 224.304],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-359.442, -97.068],
                                  [24.774, -52.376],
                                  [170.925, 225.183],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.369, -98.039],
                                  [24.357, -52.523],
                                  [171.906, 225.938],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-361.265, -98.895],
                                  [23.972, -52.669],
                                  [172.813, 226.694],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-362.109, -99.711],
                                  [23.63, -52.805],
                                  [173.647, 227.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-362.91, -100.475],
                                  [23.277, -52.93],
                                  [174.46, 228.04],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-363.617, -101.199],
                                  [22.965, -53.053],
                                  [175.21, 228.683],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-364.282, -101.87],
                                  [22.696, -53.157],
                                  [175.877, 229.202],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-364.958, -102.491],
                                  [22.417, -53.269],
                                  [176.542, 229.784],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-365.529, -103.061],
                                  [22.179, -53.361],
                                  [177.124, 230.252],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-366.059, -103.589],
                                  [21.931, -53.452],
                                  [177.695, 230.721],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-366.599, -104.066],
                                  [21.724, -53.533],
                                  [178.203, 231.128],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.034, -104.544],
                                  [21.518, -53.612],
                                  [178.69, 231.526],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.481, -104.97],
                                  [21.353, -53.681],
                                  [179.104, 231.882],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.833, -105.356],
                                  [21.177, -53.739],
                                  [179.517, 232.176],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-368.185, -105.69],
                                  [21.044, -53.797],
                                  [179.856, 232.461],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-368.496, -105.973],
                                  [20.9, -53.854],
                                  [180.185, 232.756],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-368.817, -106.258],
                                  [20.808, -53.9],
                                  [180.45, 232.98],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.024, -106.49],
                                  [20.705, -53.935],
                                  [180.694, 233.162],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.252, -106.682],
                                  [20.592, -53.97],
                                  [180.938, 233.335],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.428, -106.875],
                                  [20.53, -54.004],
                                  [181.108, 233.508],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.562, -107.016],
                                  [20.458, -54.028],
                                  [181.267, 233.63],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.687, -107.159],
                                  [20.438, -54.051],
                                  [181.373, 233.742],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.78, -107.26],
                                  [20.355, -54.063],
                                  [181.511, 233.802],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.873, -107.3],
                                  [20.366, -54.075],
                                  [181.533, 233.853],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.915, -107.35],
                                  [20.325, -54.085],
                                  [181.607, 233.915],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.914, -107.35],
                                  [20.325, -54.086],
                                  [181.607, 233.914],
                                ],
                                c: false,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.866, -107.305],
                                  [20.301, -54.081],
                                  [181.572, 233.866],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.785, -107.297],
                                  [20.292, -54.025],
                                  [181.422, 233.608],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.609, -107.159],
                                  [20.181, -53.906],
                                  [181.252, 233.201],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.349, -107.04],
                                  [20.125, -53.776],
                                  [180.946, 232.649],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.057, -106.856],
                                  [19.958, -53.591],
                                  [180.504, 231.887],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-368.621, -106.609],
                                  [19.792, -53.352],
                                  [179.977, 230.866],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-368.103, -106.344],
                                  [19.608, -53.045],
                                  [179.344, 229.647],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.442, -105.966],
                                  [19.353, -52.68],
                                  [178.552, 228.17],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-366.711, -105.522],
                                  [19.026, -52.307],
                                  [177.643, 226.436],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-365.849, -105.022],
                                  [18.669, -51.81],
                                  [176.553, 224.402],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-364.795, -104.428],
                                  [18.271, -51.196],
                                  [175.273, 222.008],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-363.569, -103.732],
                                  [17.834, -50.525],
                                  [173.834, 219.191],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-362.217, -102.947],
                                  [17.284, -49.774],
                                  [172.174, 215.973],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.591, -102.025],
                                  [16.707, -48.852],
                                  [170.274, 212.283],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-358.746, -100.98],
                                  [16.062, -47.797],
                                  [168.096, 207.972],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.621, -99.764],
                                  [15.311, -46.605],
                                  [165.64, 203.096],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-354.185, -98.33],
                                  [14.437, -45.286],
                                  [162.8, 197.475],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-351.385, -96.703],
                                  [13.487, -43.663],
                                  [159.614, 190.963],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-348.109, -94.794],
                                  [12.419, -41.83],
                                  [155.979, 183.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.278, -92.556],
                                  [11.215, -39.729],
                                  [151.841, 174.715],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-339.82, -89.899],
                                  [9.903, -37.244],
                                  [147.111, 164.583],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-334.653, -86.803],
                                  [8.495, -34.353],
                                  [141.786, 152.779],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.598, -83.17],
                                  [6.931, -30.986],
                                  [135.728, 139.011],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-321.549, -78.891],
                                  [5.264, -27.062],
                                  [128.936, 123.093],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-313.424, -73.945],
                                  [3.546, -22.561],
                                  [121.482, 104.932],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-304.378, -68.359],
                                  [1.904, -17.536],
                                  [113.58, 84.746],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.723, -62.289],
                                  [0.411, -12.109],
                                  [105.591, 63.202],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.843, -56.039],
                                  [-0.789, -6.652],
                                  [97.876, 41.364],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-275.16, -49.883],
                                  [-1.704, -1.261],
                                  [90.823, 20.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.016, -44.054],
                                  [-2.337, 3.84],
                                  [84.592, 0.217],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-257.574, -38.595],
                                  [-2.717, 8.575],
                                  [79.116, -18.148],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.87, -33.543],
                                  [-2.951, 12.856],
                                  [74.428, -34.839],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.823, -28.928],
                                  [-2.977, 16.801],
                                  [70.331, -50.095],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.344, -24.685],
                                  [-2.909, 20.354],
                                  [66.746, -63.891],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-230.451, -20.787],
                                  [-2.748, 23.627],
                                  [63.651, -76.548],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-225.045, -17.144],
                                  [-2.518, 26.62],
                                  [60.94, -88.115],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.039, -13.837],
                                  [-2.264, 29.395],
                                  [58.512, -98.746],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.439, -10.715],
                                  [-1.95, 31.964],
                                  [56.381, -108.541],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.192, -7.853],
                                  [-1.648, 34.32],
                                  [54.461, -117.616],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.2, -5.146],
                                  [-1.317, 36.53],
                                  [52.726, -126.016],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.514, -2.664],
                                  [-0.979, 38.591],
                                  [51.165, -133.8],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.04, -0.296],
                                  [-0.633, 40.451],
                                  [49.756, -141.076],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.827, 1.887],
                                  [-0.297, 42.288],
                                  [48.483, -147.894],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.821, 3.943],
                                  [0.031, 43.928],
                                  [47.339, -154.257],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.978, 5.924],
                                  [0.335, 45.483],
                                  [46.258, -160.225],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.288, 7.761],
                                  [0.674, 46.96],
                                  [45.291, -165.848],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.764, 9.454],
                                  [0.981, 48.366],
                                  [44.367, -171.12],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.396, 11.118],
                                  [1.317, 49.687],
                                  [43.543, -176.112],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.139, 12.634],
                                  [1.613, 50.939],
                                  [42.813, -180.808],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.045, 14.111],
                                  [1.932, 52.115],
                                  [42.055, -185.217],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.063, 15.496],
                                  [2.21, 53.161],
                                  [41.443, -189.333],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.184, 16.781],
                                  [2.5, 54.248],
                                  [40.803, -193.277],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.36, 18.028],
                                  [2.749, 55.208],
                                  [40.239, -196.984],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.708, 19.227],
                                  [3.01, 56.152],
                                  [39.757, -200.517],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.107, 20.328],
                                  [3.282, 57.024],
                                  [39.235, -203.818],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.616, 21.379],
                                  [3.56, 57.829],
                                  [38.784, -206.998],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.186, 22.33],
                                  [3.787, 58.626],
                                  [38.41, -209.94],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.805, 23.288],
                                  [4.024, 59.409],
                                  [37.986, -212.764],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.538, 24.198],
                                  [4.211, 60.066],
                                  [37.638, -215.413],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.33, 25.007],
                                  [4.403, 60.768],
                                  [37.299, -217.887],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.174, 25.821],
                                  [4.655, 61.348],
                                  [36.968, -220.237],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.135, 26.581],
                                  [4.798, 61.976],
                                  [36.646, -222.47],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.096, 27.296],
                                  [5.001, 62.536],
                                  [36.395, -224.585],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.172, 27.958],
                                  [5.152, 63.033],
                                  [36.144, -226.576],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.245, 28.565],
                                  [5.364, 63.578],
                                  [35.907, -228.45],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.372, 29.183],
                                  [5.524, 63.999],
                                  [35.673, -230.205],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.62, 29.747],
                                  [5.623, 64.469],
                                  [35.503, -231.842],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.864, 30.256],
                                  [5.784, 64.877],
                                  [35.279, -233.414],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.167, 30.772],
                                  [5.946, 65.277],
                                  [35.117, -234.867],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.466, 31.232],
                                  [6.053, 65.612],
                                  [34.961, -236.26],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.886, 31.645],
                                  [6.163, 65.939],
                                  [34.81, -237.476],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.303, 32.056],
                                  [6.271, 66.259],
                                  [34.66, -238.69],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.778, 32.415],
                                  [6.382, 66.576],
                                  [34.514, -239.781],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.313, 32.776],
                                  [6.496, 66.829],
                                  [34.37, -240.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.844, 33.085],
                                  [6.551, 67.075],
                                  [34.29, -241.724],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.438, 33.4],
                                  [6.662, 67.315],
                                  [34.211, -242.576],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.028, 33.654],
                                  [6.719, 67.55],
                                  [34.076, -243.368],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.678, 33.914],
                                  [6.775, 67.72],
                                  [34.004, -244.099],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.382, 34.121],
                                  [6.892, 67.888],
                                  [33.93, -244.766],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.092, 34.325],
                                  [6.947, 68.051],
                                  [33.858, -245.372],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.797, 34.481],
                                  [6.946, 68.209],
                                  [33.793, -245.86],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.625, 34.634],
                                  [7.008, 68.362],
                                  [33.783, -246.346],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.39, 34.792],
                                  [7.065, 68.451],
                                  [33.718, -246.714],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.273, 34.894],
                                  [7.064, 68.536],
                                  [33.651, -247.079],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.094, 34.996],
                                  [7.125, 68.616],
                                  [33.649, -247.385],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.979, 35.045],
                                  [7.128, 68.695],
                                  [33.646, -247.568],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.918, 35.154],
                                  [7.185, 68.707],
                                  [33.587, -247.754],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.862, 35.151],
                                  [7.188, 68.778],
                                  [33.585, -247.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.802, 35.203],
                                  [7.187, 68.786],
                                  [33.587, -247.995],
                                ],
                                c: false,
                              },
                            ],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.684, 0.684, 0.684, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.684, 0.684, 0.684, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [13.764, -10.088],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.765, -10.054],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.822, -10.004],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.884, -9.954],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.959, -9.84],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.123, -9.641],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.307, -9.413],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.516, -9.131],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.754, -8.841],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.095, -8.398],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.431, -7.93],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.88, -7.402],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.431, -6.718],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.022, -5.964],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.768, -5.087],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.594, -4.095],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.63, -2.854],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.809, -1.495],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.21, 0.081],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.929, 1.984],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.033, 4.212],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.631, 6.76],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.796, 9.714],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.81, 13.173],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.893, 17.037],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.283, 21.22],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.124, 25.349],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.37, 28.988],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.519, 31.704],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.328, 31.645],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.734, 30.273],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.635, 28.997],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.848, 27.784],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.24, 26.516],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.63, 25.283],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.902, 24.041],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.114, 22.8],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.125, 21.56],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.004, 20.331],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.714, 19.144],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.266, 17.988],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.644, 16.858],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.784, 15.828],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.819, 14.765],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.661, 13.814],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.32, 12.894],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.821, 12.003],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.114, 11.176],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.307, 10.377],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.302, 9.636],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.162, 8.962],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.933, 8.36],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.506, 7.744],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.013, 7.249],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.407, 6.733],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.751, 6.299],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.983, 5.914],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.231, 5.526],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.32, 5.184],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.546, 4.883],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.619, 4.596],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.704, 4.368],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.771, 4.127],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.902, 3.899],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.934, 3.727],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.014, 3.523],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.041, 3.395],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.049, 3.239],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.103, 3.105],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.093, 2.985],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.138, 2.887],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.101, 2.807],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.105, 2.711],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.024, 2.642],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.983, 2.588],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.937, 2.545],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.864, 2.486],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.781, 2.441],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.642, 2.433],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.531, 2.415],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.409, 2.395],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.281, 2.393],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.135, 2.363],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.963, 2.394],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.813, 2.407],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.614, 2.416],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.389, 2.441],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.193, 2.496],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.998, 2.498],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.74, 2.559],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.484, 2.609],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.158, 2.673],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.836, 2.73],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.267, 2.862],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.723, 2.953],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.987, 3.111],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.13, 3.274],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.194, 3.446],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.983, 3.687],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.633, 3.939],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.14, 4.204],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.36, 4.526],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.357, 4.855],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.107, 5.204],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.495, 5.599],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.551, 5.998],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.188, 6.41],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.304, 6.832],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.885, 7.226],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.812, 7.562],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.938, 7.83],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.187, 7.915],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.447, 7.752],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.643, 7.191],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.816, 6.034],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.224, 4.085],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.42, 1.171],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.221, -2.691],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.471, -7.245],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.506, -12.053],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.314, -16.759],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.466, -21.122],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.162, -25.037],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.148, -28.465],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.598, -31.48],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.653, -34.122],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.432, -36.443],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.018, -38.486],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.471, -40.293],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.822, -41.88],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.111, -43.305],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.277, -44.587],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.435, -45.74],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.542, -46.774],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.636, -47.711],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.703, -48.566],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.737, -49.348],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.781, -50.054],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.807, -50.705],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.787, -51.309],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.81, -51.86],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.808, -52.367],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.783, -52.838],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.789, -53.27],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.787, -53.666],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.744, -54.04],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.751, -54.385],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.728, -54.71],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.707, -55.009],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.652, -55.284],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.652, -55.546],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.62, -55.783],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.6, -56.015],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.583, -56.221],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.567, -56.416],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.529, -56.599],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.512, -56.767],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.485, -56.924],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.466, -57.073],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.465, -57.207],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.421, -57.336],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.427, -57.452],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.407, -57.567],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.393, -57.665],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.359, -57.754],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.365, -57.838],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.33, -57.918],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.328, -57.992],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.313, -58.05],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.307, -58.11],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.279, -58.161],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.294, -58.214],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.282, -58.249],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.285, -58.28],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.266, -58.315],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.257, -58.337],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.255, -58.373],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.268, -58.389],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.253, -58.396],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.252, -58.396],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.264, -58.387],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.401, -58.353],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.61, -58.249],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.865, -58.151],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.281, -58.006],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.784, -57.818],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.397, -57.582],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.134, -57.293],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.983, -56.999],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.999, -56.608],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.192, -56.124],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.565, -55.603],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.146, -55.018],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.937, -54.298],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.039, -53.488],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.375, -52.563],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.06, -51.545],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-32.176, -50.301],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-35.705, -48.9],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.69, -47.293],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.281, -45.402],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.517, -43.22],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.452, -40.688],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.076, -37.741],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.213, -34.356],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.534, -30.552],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.385, -26.357],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.757, -21.947],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.682, -17.26],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.37, -12.332],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.818, -7.176],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.739, -1.949],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.153, 3.23],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.957, 8.19],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.614, 12.925],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.451, 17.39],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-41.593, 21.588],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.098, 25.537],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.02, 29.214],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.304, 32.686],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.997, 35.939],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.036, 38.938],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-2.401, 41.855],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.707, 41.098],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.011, 37.974],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.181, 35.004],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.176, 32.202],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.085, 29.536],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.87, 26.995],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.581, 24.617],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.198, 22.335],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.767, 20.216],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.261, 18.132],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.728, 16.213],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.157, 14.397],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.57, 12.643],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.923, 11.026],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.256, 9.471],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.532, 7.967],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.798, 6.602],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.088, 5.293],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.282, 4.067],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.523, 2.885],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.708, 1.773],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.932, 0.776],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.107, -0.246],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.239, -1.143],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.397, -2.005],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.562, -2.789],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.682, -3.611],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.793, -4.287],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.902, -4.966],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.005, -5.545],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.105, -6.106],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.173, -6.641],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.277, -7.091],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.326, -7.538],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.384, -7.968],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.481, -8.304],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.532, -8.642],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.542, -8.95],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.604, -9.168],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.649, -9.396],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.646, -9.592],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.704, -9.762],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.715, -9.864],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.748, -9.96],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.756, -10.003],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.761, -10.092],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [13.764, -10.088],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.765, -10.054],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.822, -10.004],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.884, -9.954],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.959, -9.84],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.123, -9.641],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.307, -9.413],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.516, -9.131],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.754, -8.841],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.095, -8.398],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.431, -7.93],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.88, -7.402],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.431, -6.718],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.022, -5.964],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.768, -5.087],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.594, -4.095],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.63, -2.854],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.809, -1.495],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.21, 0.081],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.929, 1.984],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.033, 4.212],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.631, 6.76],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.796, 9.714],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.81, 13.173],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.893, 17.037],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.283, 21.22],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.124, 25.349],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.37, 28.988],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.519, 31.704],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.328, 31.645],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.734, 30.273],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.635, 28.997],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.848, 27.784],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.24, 26.516],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.63, 25.283],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.902, 24.041],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.114, 22.8],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.125, 21.56],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.004, 20.331],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.714, 19.144],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.266, 17.988],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.644, 16.858],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.784, 15.828],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.819, 14.765],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.661, 13.814],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.32, 12.894],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.821, 12.003],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.114, 11.176],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.307, 10.377],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.302, 9.636],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.162, 8.962],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.933, 8.36],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.506, 7.744],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.013, 7.249],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.407, 6.733],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.751, 6.299],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.983, 5.914],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.231, 5.526],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.32, 5.184],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.546, 4.883],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.619, 4.596],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.704, 4.368],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.771, 4.127],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.902, 3.899],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.934, 3.727],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.014, 3.523],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.041, 3.395],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.049, 3.239],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.103, 3.105],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.093, 2.985],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.138, 2.887],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.101, 2.807],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.105, 2.711],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.024, 2.642],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.983, 2.588],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.937, 2.545],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.864, 2.486],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.781, 2.441],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.642, 2.433],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.531, 2.415],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.409, 2.395],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.281, 2.393],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.135, 2.363],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.963, 2.394],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.813, 2.407],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.614, 2.416],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.389, 2.441],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.193, 2.496],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.998, 2.498],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.74, 2.559],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.484, 2.609],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.158, 2.673],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.836, 2.73],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.267, 2.862],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.723, 2.953],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.987, 3.111],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.13, 3.274],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.194, 3.446],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.983, 3.687],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.633, 3.939],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.14, 4.204],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.36, 4.526],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.357, 4.855],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.107, 5.204],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.495, 5.599],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.551, 5.998],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.188, 6.41],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.304, 6.832],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.885, 7.226],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.812, 7.562],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.938, 7.83],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.187, 7.915],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.447, 7.752],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.643, 7.191],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.816, 6.034],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.224, 4.085],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.42, 1.171],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.221, -2.691],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.471, -7.245],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.506, -12.053],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.314, -16.759],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.466, -21.122],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.162, -25.037],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.148, -28.465],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.598, -31.48],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.653, -34.122],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.432, -36.443],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.018, -38.486],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.471, -40.293],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.822, -41.88],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.111, -43.305],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.277, -44.587],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.435, -45.74],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.542, -46.774],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.636, -47.711],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.703, -48.566],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.737, -49.348],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.781, -50.054],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.807, -50.705],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.787, -51.309],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.81, -51.86],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.808, -52.367],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.783, -52.838],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.789, -53.27],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.787, -53.666],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.744, -54.04],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.751, -54.385],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.728, -54.71],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.707, -55.009],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.652, -55.284],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.652, -55.546],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.62, -55.783],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.6, -56.015],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.583, -56.221],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.567, -56.416],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.529, -56.599],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.512, -56.767],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.485, -56.924],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.466, -57.073],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.465, -57.207],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.421, -57.336],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.427, -57.452],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.407, -57.567],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.393, -57.665],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.359, -57.754],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.365, -57.838],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.33, -57.918],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.328, -57.992],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.313, -58.05],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.307, -58.11],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.279, -58.161],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.294, -58.214],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.282, -58.249],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.285, -58.28],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.266, -58.315],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.257, -58.337],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.255, -58.373],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.268, -58.389],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.253, -58.396],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.252, -58.396],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.264, -58.387],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.401, -58.353],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.61, -58.249],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.865, -58.151],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.281, -58.006],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.784, -57.818],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.397, -57.582],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.134, -57.293],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.983, -56.999],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.999, -56.608],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.192, -56.124],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.565, -55.603],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.146, -55.018],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-21.937, -54.298],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.039, -53.488],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.375, -52.563],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-29.06, -51.545],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-32.176, -50.301],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-35.705, -48.9],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.69, -47.293],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.281, -45.402],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.517, -43.22],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.452, -40.688],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.076, -37.741],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.213, -34.356],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.534, -30.552],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.385, -26.357],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.757, -21.947],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.682, -17.26],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.37, -12.332],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.818, -7.176],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.739, -1.949],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.153, 3.23],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.957, 8.19],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.614, 12.925],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.451, 17.39],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-41.593, 21.588],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.098, 25.537],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.02, 29.214],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.304, 32.686],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.997, 35.939],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.036, 38.938],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-2.401, 41.855],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.707, 41.098],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.011, 37.974],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.181, 35.004],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.176, 32.202],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.085, 29.536],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.87, 26.995],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.581, 24.617],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.198, 22.335],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.767, 20.216],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.261, 18.132],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.728, 16.213],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.157, 14.397],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.57, 12.643],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.923, 11.026],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.256, 9.471],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.532, 7.967],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.798, 6.602],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.088, 5.293],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.282, 4.067],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.523, 2.885],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.708, 1.773],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.932, 0.776],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.107, -0.246],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.239, -1.143],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.397, -2.005],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.562, -2.789],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.682, -3.611],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.793, -4.287],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.902, -4.966],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.005, -5.545],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.105, -6.106],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.173, -6.641],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.277, -7.091],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.326, -7.538],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.384, -7.968],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.481, -8.304],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.532, -8.642],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.542, -8.95],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.604, -9.168],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.649, -9.396],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.646, -9.592],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.704, -9.762],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.715, -9.864],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.748, -9.96],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.756, -10.003],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.761, -10.092],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 15',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 15,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'rd',
                  nm: 'Round Corners 1',
                  r: { a: 0, k: 40, ix: 1 },
                  ix: 16,
                  mn: 'ADBE Vector Filter - RC',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group-Blend',
              np: 16,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 360,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'SHAPES BLEND 2 - Blends',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [720, 440, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144, 28],
                                  [36, 100],
                                  [72, -259.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.937, 28.125],
                                  [36.125, 99.938],
                                  [71.875, -259.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.812, 28.25],
                                  [36.25, 99.813],
                                  [71.75, -259.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.625, 28.438],
                                  [36.438, 99.688],
                                  [71.5, -259.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.437, 28.688],
                                  [36.75, 99.5],
                                  [71.25, -258.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.125, 29],
                                  [37.125, 99.25],
                                  [70.875, -258],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-142.812, 29.375],
                                  [37.563, 98.938],
                                  [70.438, -257.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-142.375, 29.875],
                                  [38.063, 98.563],
                                  [69.875, -256.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.875, 30.438],
                                  [38.75, 98.188],
                                  [69.188, -255.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.312, 31.125],
                                  [39.438, 97.688],
                                  [68.438, -253.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.625, 31.938],
                                  [40.313, 97.063],
                                  [67.563, -252.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.875, 32.813],
                                  [41.375, 96.375],
                                  [66.5, -250.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.937, 33.875],
                                  [42.5, 95.625],
                                  [65.313, -248.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.937, 35.063],
                                  [43.875, 94.688],
                                  [63.938, -245.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-136.687, 36.438],
                                  [45.375, 93.625],
                                  [62.375, -243.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.375, 38.063],
                                  [47.188, 92.438],
                                  [60.563, -239.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.75, 39.875],
                                  [49.188, 91.125],
                                  [58.5, -236.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.937, 42],
                                  [51.5, 89.5],
                                  [56.063, -232.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.875, 44.375],
                                  [54.188, 87.688],
                                  [53.313, -227.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-127.5, 47.188],
                                  [57.313, 85.625],
                                  [50.125, -221.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-124.687, 50.438],
                                  [60.938, 83.188],
                                  [46.438, -215.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.437, 54.188],
                                  [65.063, 80.375],
                                  [42.188, -207.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-117.75, 58.5],
                                  [69.875, 77.125],
                                  [37.25, -199],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-113.437, 63.5],
                                  [75.438, 73.375],
                                  [31.563, -189],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.562, 69.188],
                                  [81.75, 69.125],
                                  [25.063, -177.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-103.125, 75.438],
                                  [88.688, 64.438],
                                  [18, -165.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-97.437, 82.063],
                                  [96.063, 59.438],
                                  [10.438, -151.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-91.75, 88.688],
                                  [103.438, 54.5],
                                  [2.875, -138.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-86.187, 95.125],
                                  [110.563, 49.688],
                                  [-4.437, -125.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-81.062, 101.063],
                                  [117.188, 45.188],
                                  [-11.25, -113.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-76.375, 106.563],
                                  [123.25, 41.063],
                                  [-17.437, -102.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.125, 111.5],
                                  [128.75, 37.375],
                                  [-23.062, -93],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.25, 115.938],
                                  [133.75, 34],
                                  [-28.187, -84.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-64.75, 120],
                                  [138.25, 31],
                                  [-32.812, -76],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.625, 123.688],
                                  [142.313, 28.25],
                                  [-37, -68.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.75, 127.063],
                                  [146.063, 25.75],
                                  [-40.812, -61.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-56.062, 130.125],
                                  [149.438, 23.438],
                                  [-44.25, -55.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-53.687, 132.938],
                                  [152.563, 21.313],
                                  [-47.5, -50.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-51.437, 135.5],
                                  [155.438, 19.375],
                                  [-50.437, -45],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-49.375, 137.938],
                                  [158.125, 17.563],
                                  [-53.187, -40.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.437, 140.125],
                                  [160.625, 15.875],
                                  [-55.687, -35.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-45.687, 142.187],
                                  [162.875, 14.375],
                                  [-58.062, -31.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-44, 144.125],
                                  [165.063, 12.875],
                                  [-60.25, -27.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-42.437, 145.937],
                                  [167.063, 11.563],
                                  [-62.312, -24.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-41, 147.625],
                                  [168.938, 10.313],
                                  [-64.25, -20.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.625, 149.187],
                                  [170.688, 9.125],
                                  [-66.062, -17.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-38.375, 150.687],
                                  [172.313, 8],
                                  [-67.75, -14.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-37.125, 152.062],
                                  [173.875, 6.938],
                                  [-69.312, -11.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36, 153.375],
                                  [175.313, 5.938],
                                  [-70.812, -9.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-34.937, 154.625],
                                  [176.688, 5],
                                  [-72.188, -6.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-33.937, 155.812],
                                  [178, 4.188],
                                  [-73.562, -4.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-33, 156.875],
                                  [179.188, 3.313],
                                  [-74.812, -2.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-32.125, 157.937],
                                  [180.375, 2.563],
                                  [-75.938, -0.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-31.25, 158.875],
                                  [181.438, 1.813],
                                  [-77.063, 1.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-30.5, 159.812],
                                  [182.5, 1.125],
                                  [-78.125, 3.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-29.75, 160.687],
                                  [183.438, 0.5],
                                  [-79.125, 5.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-29, 161.5],
                                  [184.375, -0.125],
                                  [-80.063, 7.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-28.375, 162.312],
                                  [185.188, -0.75],
                                  [-80.938, 8.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.75, 163],
                                  [186.063, -1.25],
                                  [-81.813, 10.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.125, 163.75],
                                  [186.813, -1.812],
                                  [-82.563, 11.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.562, 164.375],
                                  [187.5, -2.25],
                                  [-83.313, 12.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.062, 165],
                                  [188.188, -2.75],
                                  [-84, 14],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.562, 165.562],
                                  [188.875, -3.187],
                                  [-84.688, 15.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.062, 166.125],
                                  [189.438, -3.562],
                                  [-85.313, 16.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.625, 166.625],
                                  [190.063, -4],
                                  [-85.875, 17.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.187, 167.125],
                                  [190.563, -4.312],
                                  [-86.438, 18.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.812, 167.562],
                                  [191.063, -4.687],
                                  [-86.938, 19.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.437, 168],
                                  [191.563, -5],
                                  [-87.438, 20],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.125, 168.375],
                                  [192, -5.312],
                                  [-87.875, 20.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.75, 168.75],
                                  [192.438, -5.562],
                                  [-88.313, 21.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.5, 169.125],
                                  [192.813, -5.812],
                                  [-88.75, 22.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.187, 169.437],
                                  [193.188, -6.062],
                                  [-89.125, 22.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.937, 169.75],
                                  [193.5, -6.312],
                                  [-89.438, 23.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.687, 170],
                                  [193.813, -6.5],
                                  [-89.75, 24.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.5, 170.312],
                                  [194.125, -6.687],
                                  [-90.063, 24.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.25, 170.5],
                                  [194.375, -6.875],
                                  [-90.313, 25.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.062, 170.75],
                                  [194.625, -7.062],
                                  [-90.563, 25.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.937, 170.937],
                                  [194.813, -7.187],
                                  [-90.813, 25.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.75, 171.125],
                                  [195, -7.312],
                                  [-91, 26.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.625, 171.25],
                                  [195.188, -7.437],
                                  [-91.188, 26.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.5, 171.437],
                                  [195.375, -7.562],
                                  [-91.313, 26.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.375, 171.562],
                                  [195.5, -7.687],
                                  [-91.5, 27.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.312, 171.687],
                                  [195.625, -7.75],
                                  [-91.625, 27.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.187, 171.75],
                                  [195.75, -7.812],
                                  [-91.688, 27.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.125, 171.812],
                                  [195.813, -7.875],
                                  [-91.813, 27.688],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.125, 171.875],
                                  [195.875, -7.937],
                                  [-91.875, 27.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.062, 171.937],
                                  [195.938, -7.937],
                                  [-91.938, 27.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20, 172],
                                  [196, -8],
                                  [-92, 27.938],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20, 172],
                                  [196, -8],
                                  [-92, 28],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20, 172],
                                  [196, -8],
                                  [-92, 28],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.062, 171.937],
                                  [195.938, -8],
                                  [-91.938, 28.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.25, 171.75],
                                  [195.875, -8],
                                  [-91.875, 28.188],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.625, 171.437],
                                  [195.625, -8],
                                  [-91.625, 28.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.125, 170.937],
                                  [195.375, -8],
                                  [-91.375, 28.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.875, 170.312],
                                  [195, -8],
                                  [-91, 29.188],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.75, 169.5],
                                  [194.5, -8],
                                  [-90.5, 29.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.812, 168.5],
                                  [193.938, -8],
                                  [-89.938, 30.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.187, 167.312],
                                  [193.188, -8],
                                  [-89.188, 31.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.75, 165.875],
                                  [192.313, -8],
                                  [-88.313, 32.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-28.562, 164.187],
                                  [191.313, -8],
                                  [-87.313, 33.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-30.75, 162.25],
                                  [190.125, -8],
                                  [-86.125, 34.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-33.25, 159.937],
                                  [188.75, -8],
                                  [-84.75, 36.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.125, 157.312],
                                  [187.188, -8],
                                  [-83.188, 38.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.437, 154.312],
                                  [185.375, -8],
                                  [-81.375, 40.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-43.25, 150.875],
                                  [183.313, -8],
                                  [-79.313, 42.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.625, 146.875],
                                  [180.938, -8],
                                  [-76.938, 45.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-52.625, 142.312],
                                  [178.188, -8],
                                  [-74.188, 48.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.437, 137.062],
                                  [175.063, -8],
                                  [-71.063, 52.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-65.062, 131],
                                  [171.438, -8],
                                  [-67.438, 56.688],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.75, 124.062],
                                  [167.25, -8],
                                  [-63.25, 61.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-81.625, 115.937],
                                  [162.375, -8],
                                  [-58.375, 67.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-91.937, 106.562],
                                  [156.75, -8],
                                  [-52.75, 73.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-103.937, 95.687],
                                  [150.25, -8],
                                  [-46.25, 81.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-117.687, 83.187],
                                  [142.75, -8],
                                  [-38.75, 90.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.25, 69.062],
                                  [134.25, -8],
                                  [-30.25, 100.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.437, 53.437],
                                  [124.875, -8],
                                  [-20.875, 111],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.625, 36.875],
                                  [114.937, -8],
                                  [-10.938, 122.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-186.937, 20.25],
                                  [104.937, -8],
                                  [-0.938, 134.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.562, 4.25],
                                  [95.312, -8],
                                  [8.687, 145.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221, -10.688],
                                  [86.375, -8],
                                  [17.625, 155.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236, -24.375],
                                  [78.187, -8],
                                  [25.812, 165.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.562, -36.75],
                                  [70.75, -8],
                                  [33.25, 174.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-261.875, -47.875],
                                  [64.062, -8],
                                  [39.937, 181.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-273, -58],
                                  [58, -8],
                                  [46, 189],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-283.125, -67.188],
                                  [52.5, -8],
                                  [51.5, 195.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-292.312, -75.563],
                                  [47.437, -8],
                                  [56.562, 201.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.75, -83.25],
                                  [42.875, -8],
                                  [61.125, 206.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-308.5, -90.313],
                                  [38.625, -8],
                                  [65.375, 211.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-315.625, -96.75],
                                  [34.75, -8],
                                  [69.25, 216.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-322.25, -102.75],
                                  [31.125, -8],
                                  [72.875, 220.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.375, -108.313],
                                  [27.812, -8],
                                  [76.187, 224.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-334.062, -113.5],
                                  [24.687, -8],
                                  [79.312, 227.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-339.375, -118.313],
                                  [21.812, -8],
                                  [82.187, 231.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.312, -122.813],
                                  [19.125, -8],
                                  [84.875, 234.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-348.937, -127.063],
                                  [16.562, -8],
                                  [87.437, 237.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-353.312, -131],
                                  [14.187, -8],
                                  [89.812, 240.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.375, -134.688],
                                  [12, -8],
                                  [92, 242.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-361.25, -138.188],
                                  [9.875, -8],
                                  [94.125, 245.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-364.812, -141.5],
                                  [7.937, -8],
                                  [96.062, 247.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-368.25, -144.563],
                                  [6.062, -8],
                                  [97.937, 249.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.437, -147.5],
                                  [4.312, -8],
                                  [99.687, 251.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-374.437, -150.25],
                                  [2.687, -8],
                                  [101.312, 253.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-377.312, -152.813],
                                  [1.125, -8],
                                  [102.875, 255.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-380, -155.25],
                                  [-0.375, -8],
                                  [104.375, 257.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-382.5, -157.563],
                                  [-1.75, -8],
                                  [105.75, 258.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-384.937, -159.75],
                                  [-3.063, -8],
                                  [107.062, 260.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.187, -161.813],
                                  [-4.25, -8],
                                  [108.25, 261.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-389.312, -163.75],
                                  [-5.438, -8],
                                  [109.437, 263],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-391.312, -165.563],
                                  [-6.563, -8],
                                  [110.562, 264.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-393.25, -167.313],
                                  [-7.563, -8],
                                  [111.562, 265.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-395, -168.938],
                                  [-8.563, -8],
                                  [112.562, 266.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-396.75, -170.5],
                                  [-9.5, -8],
                                  [113.5, 267.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.312, -171.938],
                                  [-10.375, -8],
                                  [114.375, 268.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-399.812, -173.313],
                                  [-11.188, -8],
                                  [115.187, 269.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-401.25, -174.563],
                                  [-11.938, -8],
                                  [115.937, 270.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-402.562, -175.813],
                                  [-12.688, -8],
                                  [116.687, 271.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-403.812, -176.938],
                                  [-13.375, -8],
                                  [117.375, 272.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-405, -178],
                                  [-14, -8],
                                  [118, 273],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-406.125, -179],
                                  [-14.625, -8],
                                  [118.625, 273.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.125, -179.938],
                                  [-15.188, -8],
                                  [119.187, 274.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-408.062, -180.813],
                                  [-15.688, -8],
                                  [119.687, 274.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-409, -181.625],
                                  [-16.188, -8],
                                  [120.187, 275.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-409.812, -182.375],
                                  [-16.625, -8],
                                  [120.625, 276.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-410.562, -183.063],
                                  [-17.063, -8],
                                  [121.062, 276.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-411.312, -183.688],
                                  [-17.438, -8],
                                  [121.437, 277],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-411.937, -184.313],
                                  [-17.813, -8],
                                  [121.812, 277.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-412.562, -184.875],
                                  [-18.125, -8],
                                  [122.125, 277.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-413.062, -185.375],
                                  [-18.438, -8],
                                  [122.437, 278.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-413.562, -185.813],
                                  [-18.688, -8],
                                  [122.687, 278.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-414, -186.188],
                                  [-18.938, -8],
                                  [122.937, 278.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-414.437, -186.563],
                                  [-19.125, -8],
                                  [123.125, 279],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-414.75, -186.875],
                                  [-19.313, -8],
                                  [123.312, 279.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.062, -187.125],
                                  [-19.5, -8],
                                  [123.5, 279.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.312, -187.375],
                                  [-19.625, -8],
                                  [123.625, 279.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.499, -187.563],
                                  [-19.751, -8],
                                  [123.75, 279.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.687, -187.75],
                                  [-19.813, -8],
                                  [123.812, 279.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.812, -187.875],
                                  [-19.938, -8],
                                  [123.937, 279.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.937, -187.938],
                                  [-19.938, -8],
                                  [123.937, 279.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.999, -188],
                                  [-20.001, -8],
                                  [124, 280],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.937, -187.938],
                                  [-20.001, -8],
                                  [124, 279.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.812, -187.875],
                                  [-19.938, -7.937],
                                  [123.937, 279.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.562, -187.625],
                                  [-19.938, -7.812],
                                  [123.937, 279.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-415.187, -187.375],
                                  [-19.813, -7.687],
                                  [123.875, 278.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-414.75, -187],
                                  [-19.75, -7.5],
                                  [123.75, 277.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-414.125, -186.5],
                                  [-19.625, -7.25],
                                  [123.625, 276.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-413.375, -185.938],
                                  [-19.438, -6.937],
                                  [123.5, 274.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-412.437, -185.188],
                                  [-19.25, -6.562],
                                  [123.312, 272.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-411.375, -184.313],
                                  [-19.063, -6.187],
                                  [123.125, 270.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-410.125, -183.313],
                                  [-18.813, -5.687],
                                  [122.875, 268.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-408.625, -182.125],
                                  [-18.5, -5.062],
                                  [122.562, 265.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-406.875, -180.75],
                                  [-18.125, -4.375],
                                  [122.25, 261.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-404.937, -179.188],
                                  [-17.75, -3.625],
                                  [121.875, 258],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-402.625, -177.375],
                                  [-17.25, -2.687],
                                  [121.437, 253.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-400, -175.313],
                                  [-16.688, -1.625],
                                  [120.937, 248.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-397, -172.938],
                                  [-16.063, -0.437],
                                  [120.375, 242.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-393.562, -170.188],
                                  [-15.375, 0.875],
                                  [119.687, 235.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-389.625, -167.063],
                                  [-14.563, 2.5],
                                  [118.937, 227.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-385.062, -163.438],
                                  [-13.625, 4.313],
                                  [118.062, 218.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-379.75, -159.25],
                                  [-12.563, 6.375],
                                  [117.062, 208.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.625, -154.375],
                                  [-11.313, 8.813],
                                  [115.875, 195.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-366.562, -148.75],
                                  [-9.813, 11.625],
                                  [114.562, 181.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-358.375, -142.25],
                                  [-8.125, 14.875],
                                  [113, 165.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-348.937, -134.75],
                                  [-6.188, 18.625],
                                  [111.187, 146.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-338.187, -126.25],
                                  [-4, 22.875],
                                  [109.125, 125.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-326.375, -116.875],
                                  [-1.563, 27.563],
                                  [106.875, 102.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-313.937, -106.937],
                                  [1, 32.563],
                                  [104.5, 77.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-301.375, -96.937],
                                  [3.625, 37.5],
                                  [102.062, 52.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-289.25, -87.312],
                                  [6.125, 42.313],
                                  [99.75, 28.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.937, -78.375],
                                  [8.437, 46.813],
                                  [97.625, 5.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-267.625, -70.187],
                                  [10.562, 50.938],
                                  [95.625, -14.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-258.312, -62.75],
                                  [12.437, 54.625],
                                  [93.875, -33.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.875, -56.062],
                                  [14.187, 58],
                                  [92.25, -49.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.187, -50],
                                  [15.75, 61],
                                  [90.75, -65],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-235.25, -44.5],
                                  [17.187, 63.75],
                                  [89.438, -78.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-228.937, -39.437],
                                  [18.5, 66.25],
                                  [88.25, -91.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.125, -34.875],
                                  [19.687, 68.563],
                                  [87.125, -102.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.812, -30.625],
                                  [20.813, 70.688],
                                  [86.125, -113.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.937, -26.75],
                                  [21.813, 72.625],
                                  [85.188, -123.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.375, -23.125],
                                  [22.75, 74.438],
                                  [84.313, -132.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.187, -19.812],
                                  [23.625, 76.125],
                                  [83.5, -140.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.25, -16.687],
                                  [24.438, 77.625],
                                  [82.75, -148.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.625, -13.812],
                                  [25.188, 79.125],
                                  [82.063, -155.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.25, -11.125],
                                  [25.875, 80.438],
                                  [81.438, -162.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.062, -8.562],
                                  [26.5, 81.688],
                                  [80.813, -168.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.062, -6.187],
                                  [27.125, 82.875],
                                  [80.25, -174.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.25, -4],
                                  [27.688, 84],
                                  [79.688, -180.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.625, -1.875],
                                  [28.25, 85.063],
                                  [79.188, -185.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.125, 0.063],
                                  [28.75, 86.063],
                                  [78.75, -190.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.812, 1.938],
                                  [29.25, 87],
                                  [78.25, -194.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.625, 3.688],
                                  [29.688, 87.813],
                                  [77.875, -199.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.562, 5.313],
                                  [30.125, 88.688],
                                  [77.438, -203.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.562, 6.875],
                                  [30.5, 89.438],
                                  [77.063, -207.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.75, 8.375],
                                  [30.875, 90.188],
                                  [76.75, -210.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167, 9.75],
                                  [31.25, 90.875],
                                  [76.375, -214.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.375, 11.063],
                                  [31.625, 91.5],
                                  [76.063, -217.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.812, 12.25],
                                  [31.938, 92.125],
                                  [75.813, -220.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.312, 13.438],
                                  [32.25, 92.75],
                                  [75.5, -223.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.937, 14.563],
                                  [32.5, 93.25],
                                  [75.25, -226.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.625, 15.563],
                                  [32.75, 93.813],
                                  [75, -228.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.375, 16.563],
                                  [33.063, 94.25],
                                  [74.75, -231.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.25, 17.5],
                                  [33.25, 94.75],
                                  [74.5, -233.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.125, 18.375],
                                  [33.5, 95.188],
                                  [74.313, -235.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.125, 19.188],
                                  [33.688, 95.563],
                                  [74.125, -237.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.125, 19.938],
                                  [33.938, 96],
                                  [73.938, -239.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.187, 20.688],
                                  [34.125, 96.313],
                                  [73.75, -241.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.375, 21.375],
                                  [34.25, 96.688],
                                  [73.625, -243.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.562, 22],
                                  [34.438, 97],
                                  [73.438, -245],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.812, 22.625],
                                  [34.625, 97.313],
                                  [73.313, -246.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.062, 23.188],
                                  [34.75, 97.563],
                                  [73.188, -247.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.812, 24.188],
                                  [35, 98.063],
                                  [72.938, -250.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.25, 24.625],
                                  [35.125, 98.313],
                                  [72.813, -251.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.75, 25.063],
                                  [35.25, 98.5],
                                  [72.688, -252.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.25, 25.438],
                                  [35.313, 98.688],
                                  [72.625, -253.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.812, 25.813],
                                  [35.438, 98.875],
                                  [72.563, -254.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.375, 26.125],
                                  [35.5, 99.063],
                                  [72.438, -255.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146, 26.438],
                                  [35.563, 99.188],
                                  [72.375, -256],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.687, 26.688],
                                  [35.688, 99.313],
                                  [72.313, -256.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.375, 26.938],
                                  [35.75, 99.438],
                                  [72.25, -257.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.062, 27.125],
                                  [35.75, 99.563],
                                  [72.188, -257.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.875, 27.313],
                                  [35.813, 99.688],
                                  [72.188, -258.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.625, 27.5],
                                  [35.875, 99.75],
                                  [72.125, -258.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.5, 27.625],
                                  [35.875, 99.813],
                                  [72.063, -259.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.312, 27.75],
                                  [35.938, 99.875],
                                  [72.063, -259.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.187, 27.813],
                                  [35.938, 99.938],
                                  [72.063, -259.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.125, 27.938],
                                  [36, 99.938],
                                  [72, -259.75],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.062, 27.938],
                                  [36, 100],
                                  [72, -259.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144, 28],
                                  [36, 100],
                                  [72, -260],
                                ],
                                c: false,
                              },
                            ],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.718, 0.718, 0.718, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.718, 0.718, 0.718, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [44.355, 16.454],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.353, 16.485],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.415, 16.517],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.476, 16.54],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.554, 16.618],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.719, 16.767],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.902, 16.93],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.114, 17.135],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.347, 17.314],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.689, 17.642],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.017, 17.961],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.458, 18.307],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.997, 18.794],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.563, 19.312],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.28, 19.912],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.063, 20.584],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.044, 21.459],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.143, 22.397],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.437, 23.458],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.013, 24.805],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.926, 26.363],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.263, 28.141],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.08, 30.222],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.625, 32.691],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.099, 35.468],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.714, 38.497],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.617, 41.476],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.826, 44.043],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.921, 45.827],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.401, 46.389],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.442, 45.398],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.093, 42.427],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.191, 39.796],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.606, 37.304],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.156, 34.987],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.643, 32.775],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.175, 30.641],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.56, 28.566],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.862, 26.563],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.058, 24.64],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.13, 22.781],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.065, 20.987],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.807, 19.319],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.493, 17.628],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.015, 16.083],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.419, 14.576],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.684, 13.124],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.806, 11.729],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.862, 10.386],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.784, 9.097],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.603, 7.889],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.402, 6.762],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.01, 5.612],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.595, 4.595],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.074, 3.558],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.518, 2.602],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.82, 1.715],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.137, 0.813],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.237, -0.024],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.469, -0.794],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.5, -1.57],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.5, -2.25],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.9, -2.732],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.239, -3.145],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.479, -3.493],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.812, -3.903],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.02, -4.187],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.274, -4.534],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.493, -4.814],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.723, -5.1],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.905, -5.318],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.072, -5.539],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.221, -5.758],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.388, -5.984],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.522, -6.145],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.698, -6.31],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.783, -6.472],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.912, -6.638],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.975, -6.743],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.043, -6.848],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.117, -6.954],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.252, -7.068],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.283, -7.176],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.355, -7.229],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.416, -7.28],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.413, -7.331],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.466, -7.39],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.485, -7.383],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.519, -7.441],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.468, -7.446],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.395, -7.464],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.3, -7.477],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.227, -7.509],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.075, -7.546],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.886, -7.586],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.701, -7.654],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.433, -7.73],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.131, -7.824],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.792, -7.948],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.253, -7.624],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.78, -6.957],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.877, -6.196],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.553, -5.363],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.678, -4.436],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [171.176, -3.433],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.961, -2.345],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [159.876, -1.204],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.729, -0.009],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.356, 1.153],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.552, 2.226],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.1, 3.075],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.899, 3.502],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.068, 3.288],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.05, 2.226],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.726, 0.263],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.258, -2.417],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.404, -5.471],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.548, -8.567],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.567, -11.472],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.203, -14.063],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-3.879, -16.312],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.919, -18.26],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.128, -19.926],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.686, -21.348],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.697, -22.578],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.287, -23.629],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.516, -24.532],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.472, -25.32],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-36.096, -25.998],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-38.534, -26.592],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.778, -27.116],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.858, -27.581],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.783, -27.983],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.558, -28.331],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.22, -28.654],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.811, -28.937],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.242, -29.195],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.609, -29.405],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.901, -29.612],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.085, -29.791],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56.231, -29.948],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.318, -30.087],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.306, -30.216],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.285, -30.342],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.159, -30.457],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.989, -30.539],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.762, -30.605],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.53, -30.705],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.225, -30.765],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.902, -30.828],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.52, -30.881],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.117, -30.94],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.66, -30.984],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.2, -31.033],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.682, -31.065],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.139, -31.104],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.586, -31.132],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.959, -31.15],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.339, -31.189],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.684, -31.203],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.008, -31.231],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.295, -31.237],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.59, -31.263],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.831, -31.27],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.078, -31.297],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.288, -31.305],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.479, -31.322],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.635, -31.317],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.805, -31.344],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.939, -31.353],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.063, -31.349],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.151, -31.358],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.227, -31.357],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.294, -31.376],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.361, -31.365],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.408, -31.363],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.496, -31.372],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.623, -31.304],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.74, -31.287],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.984, -31.237],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.269, -31.17],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.606, -31.096],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.012, -30.987],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.456, -30.884],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.012, -30.744],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.674, -30.558],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.424, -30.371],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.289, -30.168],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.26, -29.889],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.417, -29.596],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.685, -29.247],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.127, -28.866],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.846, -28.37],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.761, -27.814],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.872, -27.143],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.31, -26.318],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.03, -25.335],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.046, -24.118],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.231, -22.563],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.327, -20.555],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.845, -17.92],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.914, -14.352],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.321, -9.64],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.215, -3.477],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.803, 3.867],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.31, 11.807],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.034, 19.739],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56.718, 27.372],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.866, 34.484],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-35.582, 41.121],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.955, 47.262],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.968, 52.948],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.577, 58.21],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.797, 63.054],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.505, 67.58],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.294, 71.774],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.658, 75.619],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.292, 78.693],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.156, 74.845],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.774, 71.211],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.239, 67.79],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.516, 64.56],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.69, 61.496],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.719, 58.601],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.658, 55.893],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.486, 53.277],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.253, 50.87],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.928, 48.492],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.562, 46.302],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.145, 44.244],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.705, 42.25],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.19, 40.415],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.646, 38.649],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, 36.942],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.396, 35.405],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.788, 33.909],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.065, 32.525],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.39, 31.188],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.647, 29.916],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.942, 28.793],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.176, 27.636],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.364, 26.616],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.576, 25.635],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.791, 24.745],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.954, 23.821],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.102, 23.056],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.249, 22.289],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.386, 21.622],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.516, 20.977],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.608, 20.377],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.74, 19.865],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.809, 19.366],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.886, 18.881],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.002, 18.497],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.071, 18.114],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.089, 17.773],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.162, 17.519],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.218, 17.256],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.222, 17.032],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.287, 16.84],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.3, 16.719],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.341, 16.606],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.347, 16.557],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.355, 16.454],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [44.355, 16.454],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.353, 16.485],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.415, 16.517],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.476, 16.54],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.554, 16.618],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.719, 16.767],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.902, 16.93],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.114, 17.135],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.347, 17.314],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.689, 17.642],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.017, 17.961],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.458, 18.307],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.997, 18.794],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.563, 19.312],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.28, 19.912],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.063, 20.584],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.044, 21.459],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.143, 22.397],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.437, 23.458],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.013, 24.805],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.926, 26.363],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.263, 28.141],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.08, 30.222],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.625, 32.691],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.099, 35.468],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.714, 38.497],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.617, 41.476],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.826, 44.043],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.921, 45.827],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.401, 46.389],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.442, 45.398],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.093, 42.427],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.191, 39.796],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.606, 37.304],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.156, 34.987],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.643, 32.775],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.175, 30.641],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.56, 28.566],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.862, 26.563],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.058, 24.64],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.13, 22.781],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.065, 20.987],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.807, 19.319],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.493, 17.628],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.015, 16.083],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.419, 14.576],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.684, 13.124],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.806, 11.729],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.862, 10.386],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.784, 9.097],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.603, 7.889],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.402, 6.762],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.01, 5.612],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.595, 4.595],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.074, 3.558],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.518, 2.602],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.82, 1.715],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.137, 0.813],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.237, -0.024],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.469, -0.794],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.5, -1.57],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.5, -2.25],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.9, -2.732],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.239, -3.145],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.479, -3.493],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.812, -3.903],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.02, -4.187],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.274, -4.534],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.493, -4.814],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.723, -5.1],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.905, -5.318],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.072, -5.539],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.221, -5.758],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.388, -5.984],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.522, -6.145],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.698, -6.31],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.783, -6.472],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.912, -6.638],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.975, -6.743],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.043, -6.848],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.117, -6.954],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.252, -7.068],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.283, -7.176],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.355, -7.229],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.416, -7.28],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.413, -7.331],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.466, -7.39],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.485, -7.383],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.519, -7.441],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.468, -7.446],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.395, -7.464],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.3, -7.477],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.227, -7.509],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.075, -7.546],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.886, -7.586],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.701, -7.654],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.433, -7.73],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.131, -7.824],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.792, -7.948],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.253, -7.624],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.78, -6.957],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.877, -6.196],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.553, -5.363],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.678, -4.436],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [171.176, -3.433],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.961, -2.345],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [159.876, -1.204],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.729, -0.009],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.356, 1.153],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.552, 2.226],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.1, 3.075],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.899, 3.502],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.068, 3.288],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.05, 2.226],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.726, 0.263],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.258, -2.417],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.404, -5.471],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.548, -8.567],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.567, -11.472],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.203, -14.063],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-3.879, -16.312],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.919, -18.26],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.128, -19.926],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.686, -21.348],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.697, -22.578],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.287, -23.629],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.516, -24.532],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.472, -25.32],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-36.096, -25.998],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-38.534, -26.592],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.778, -27.116],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.858, -27.581],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.783, -27.983],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.558, -28.331],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.22, -28.654],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.811, -28.937],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.242, -29.195],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.609, -29.405],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.901, -29.612],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.085, -29.791],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56.231, -29.948],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.318, -30.087],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.306, -30.216],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.285, -30.342],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.159, -30.457],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.989, -30.539],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.762, -30.605],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.53, -30.705],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.225, -30.765],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.902, -30.828],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.52, -30.881],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.117, -30.94],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.66, -30.984],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.2, -31.033],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.682, -31.065],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.139, -31.104],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.586, -31.132],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.959, -31.15],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.339, -31.189],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.684, -31.203],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.008, -31.231],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.295, -31.237],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.59, -31.263],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.831, -31.27],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.078, -31.297],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.288, -31.305],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.479, -31.322],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.635, -31.317],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.805, -31.344],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.939, -31.353],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.063, -31.349],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.151, -31.358],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.227, -31.357],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.294, -31.376],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.361, -31.365],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.408, -31.363],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.496, -31.372],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.623, -31.304],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.74, -31.287],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.984, -31.237],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.269, -31.17],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.606, -31.096],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.012, -30.987],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.456, -30.884],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.012, -30.744],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.674, -30.558],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.424, -30.371],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.289, -30.168],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.26, -29.889],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.417, -29.596],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.685, -29.247],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.127, -28.866],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.846, -28.37],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.761, -27.814],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.872, -27.143],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.31, -26.318],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.03, -25.335],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.046, -24.118],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.231, -22.563],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.327, -20.555],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.845, -17.92],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.914, -14.352],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.321, -9.64],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.215, -3.477],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.803, 3.867],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.31, 11.807],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.034, 19.739],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56.718, 27.372],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.866, 34.484],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-35.582, 41.121],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.955, 47.262],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.968, 52.948],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.577, 58.21],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.797, 63.054],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.505, 67.58],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.294, 71.774],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.658, 75.619],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.292, 78.693],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.156, 74.845],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.774, 71.211],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.239, 67.79],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.516, 64.56],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.69, 61.496],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.719, 58.601],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.658, 55.893],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.486, 53.277],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.253, 50.87],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.928, 48.492],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.562, 46.302],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.145, 44.244],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.705, 42.25],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.19, 40.415],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.646, 38.649],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, 36.942],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.396, 35.405],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.788, 33.909],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.065, 32.525],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.39, 31.188],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.647, 29.916],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.942, 28.793],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.176, 27.636],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.364, 26.616],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.576, 25.635],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.791, 24.745],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.954, 23.821],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.102, 23.056],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.249, 22.289],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.386, 21.622],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.516, 20.977],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.608, 20.377],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.74, 19.865],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.809, 19.366],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.886, 18.881],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.002, 18.497],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.071, 18.114],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.089, 17.773],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.162, 17.519],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.218, 17.256],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.222, 17.032],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.287, 16.84],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.3, 16.719],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.341, 16.606],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.347, 16.557],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.355, 16.454],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 1',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.004, 42.395],
                                  [47.996, 104.798],
                                  [91.189, -245.543],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.944, 42.501],
                                  [48.114, 104.743],
                                  [91.052, -245.416],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.823, 42.605],
                                  [48.236, 104.629],
                                  [90.901, -245.17],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.64, 42.761],
                                  [48.418, 104.515],
                                  [90.613, -244.797],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.459, 42.97],
                                  [48.716, 104.345],
                                  [90.315, -244.301],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.155, 43.231],
                                  [49.078, 104.121],
                                  [89.877, -243.62],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.853, 43.544],
                                  [49.497, 103.838],
                                  [89.361, -242.815],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.427, 43.963],
                                  [49.981, 103.499],
                                  [88.709, -241.88],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.941, 44.435],
                                  [50.642, 103.164],
                                  [87.917, -240.763],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.394, 45.013],
                                  [51.306, 102.711],
                                  [87.043, -239.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.724, 45.698],
                                  [52.151, 102.145],
                                  [86.021, -237.841],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-127.994, 46.434],
                                  [53.173, 101.528],
                                  [84.796, -236.04],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-127.079, 47.332],
                                  [54.264, 100.85],
                                  [83.418, -233.924],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-126.103, 48.336],
                                  [55.591, 100.005],
                                  [81.825, -231.554],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-124.879, 49.501],
                                  [57.048, 99.045],
                                  [80.018, -228.753],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.596, 50.881],
                                  [58.799, 97.971],
                                  [77.929, -225.576],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-122.002, 52.423],
                                  [60.744, 96.791],
                                  [75.551, -221.961],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.224, 54.235],
                                  [62.99, 95.324],
                                  [72.759, -217.778],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-118.198, 56.263],
                                  [65.601, 93.688],
                                  [69.604, -212.911],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-115.863, 58.671],
                                  [68.639, 91.828],
                                  [65.957, -207.349],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-113.093, 61.46],
                                  [72.17, 89.629],
                                  [61.74, -200.912],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-109.889, 64.685],
                                  [76.194, 87.092],
                                  [56.888, -193.408],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-106.251, 68.402],
                                  [80.887, 84.16],
                                  [51.265, -184.714],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-101.991, 72.721],
                                  [86.32, 80.775],
                                  [44.794, -174.696],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-97.172, 77.643],
                                  [92.488, 76.936],
                                  [37.426, -163.304],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-91.795, 83.062],
                                  [99.274, 72.699],
                                  [29.409, -150.782],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-86.172, 88.814],
                                  [106.483, 68.172],
                                  [20.854, -137.57],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-80.553, 94.574],
                                  [113.69, 63.699],
                                  [12.295, -124.244],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-75.063, 100.177],
                                  [120.651, 59.321],
                                  [4.029, -111.425],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-70.016, 105.351],
                                  [127.114, 55.214],
                                  [-3.667, -99.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-65.411, 110.149],
                                  [133.018, 51.429],
                                  [-10.661, -88.582],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.249, 114.461],
                                  [138.364, 48.032],
                                  [-17.013, -78.688],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-57.468, 118.341],
                                  [143.214, 44.902],
                                  [-22.789, -69.806],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-54.067, 121.899],
                                  [147.568, 42.098],
                                  [-28.005, -61.815],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-51.048, 125.135],
                                  [151.483, 39.507],
                                  [-32.725, -54.526],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.285, 128.102],
                                  [155.085, 37.131],
                                  [-37.023, -47.94],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-45.717, 130.8],
                                  [158.318, 34.911],
                                  [-40.902, -41.928],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-43.468, 133.285],
                                  [161.291, 32.845],
                                  [-44.551, -36.434],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-41.353, 135.556],
                                  [164.017, 30.938],
                                  [-47.853, -31.392],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.433, 137.723],
                                  [166.548, 29.127],
                                  [-50.94, -26.738],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-37.647, 139.676],
                                  [168.889, 27.416],
                                  [-53.746, -22.475],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.058, 141.525],
                                  [170.977, 25.864],
                                  [-56.401, -18.482],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-34.541, 143.271],
                                  [172.995, 24.289],
                                  [-58.848, -14.871],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-33.158, 144.913],
                                  [174.821, 22.871],
                                  [-61.146, -11.469],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-31.911, 146.451],
                                  [176.514, 21.496],
                                  [-63.297, -8.335],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-30.737, 147.885],
                                  [178.078, 20.16],
                                  [-65.306, -5.463],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-29.698, 149.272],
                                  [179.51, 18.861],
                                  [-67.172, -2.734],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-28.669, 150.553],
                                  [180.875, 17.607],
                                  [-68.899, -0.209],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.773, 151.787],
                                  [182.108, 16.392],
                                  [-70.544, 2.115],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.947, 152.972],
                                  [183.274, 15.223],
                                  [-72.052, 4.245],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.189, 154.107],
                                  [184.374, 14.161],
                                  [-73.541, 6.235],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.496, 155.134],
                                  [185.35, 13.032],
                                  [-74.896, 8.1],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.864, 156.168],
                                  [186.325, 12.016],
                                  [-76.114, 9.896],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.229, 157.091],
                                  [187.183, 11.003],
                                  [-77.318, 11.512],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.713, 158.017],
                                  [188.043, 10.054],
                                  [-78.45, 13.014],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.187, 158.887],
                                  [188.79, 9.173],
                                  [-79.51, 14.46],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.65, 159.701],
                                  [189.547, 8.301],
                                  [-80.501, 15.859],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.227, 160.515],
                                  [190.189, 7.444],
                                  [-81.422, 17.089],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.79, 161.212],
                                  [190.903, 6.721],
                                  [-82.336, 18.332],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.341, 161.967],
                                  [191.506, 5.954],
                                  [-83.121, 19.469],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.942, 162.603],
                                  [192.057, 5.32],
                                  [-83.902, 20.558],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.594, 163.238],
                                  [192.616, 4.64],
                                  [-84.615, 21.603],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.235, 163.812],
                                  [193.183, 4.034],
                                  [-85.324, 22.538],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.866, 164.386],
                                  [193.638, 3.5],
                                  [-85.969, 23.482],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.55, 164.898],
                                  [194.16, 2.917],
                                  [-86.55, 24.318],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.226, 165.409],
                                  [194.566, 2.465],
                                  [-87.127, 25.162],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-18.956, 165.859],
                                  [194.978, 1.963],
                                  [-87.641, 25.895],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-18.68, 166.308],
                                  [195.395, 1.529],
                                  [-88.151, 26.634],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-18.459, 166.697],
                                  [195.755, 1.103],
                                  [-88.599, 27.32],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-18.171, 167.084],
                                  [196.122, 0.745],
                                  [-89.044, 27.953],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-18.002, 167.469],
                                  [196.428, 0.394],
                                  [-89.487, 28.529],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.766, 167.795],
                                  [196.74, 0.05],
                                  [-89.868, 29.05],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.587, 168.119],
                                  [196.994, -0.29],
                                  [-90.185, 29.576],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.405, 168.382],
                                  [197.25, -0.564],
                                  [-90.501, 30.046],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.282, 168.704],
                                  [197.508, -0.833],
                                  [-90.815, 30.458],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.092, 168.906],
                                  [197.71, -1.097],
                                  [-91.068, 30.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.962, 169.167],
                                  [197.912, -1.358],
                                  [-91.319, 31.233],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.892, 169.367],
                                  [198.055, -1.553],
                                  [-91.567, 31.534],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.756, 169.566],
                                  [198.201, -1.746],
                                  [-91.754, 31.836],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.68, 169.705],
                                  [198.348, -1.935],
                                  [-91.941, 32.081],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.602, 169.903],
                                  [198.496, -2.121],
                                  [-92.065, 32.267],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.521, 170.04],
                                  [198.585, -2.305],
                                  [-92.248, 32.517],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.502, 170.176],
                                  [198.675, -2.424],
                                  [-92.37, 32.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.417, 170.252],
                                  [198.767, -2.541],
                                  [-92.43, 32.777],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.394, 170.328],
                                  [198.799, -2.655],
                                  [-92.551, 32.91],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.431, 170.403],
                                  [198.83, -2.768],
                                  [-92.609, 32.983],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.404, 170.477],
                                  [198.864, -2.817],
                                  [-92.667, 32.997],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.376, 170.55],
                                  [198.899, -2.926],
                                  [-92.724, 33.011],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.409, 170.563],
                                  [198.873, -2.972],
                                  [-92.718, 33.028],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.44, 170.576],
                                  [198.848, -3.016],
                                  [-92.712, 32.984],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.532, 170.526],
                                  [198.76, -3.06],
                                  [-92.645, 33.002],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.746, 170.35],
                                  [198.666, -3.101],
                                  [-92.58, 33.081],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.143, 170.05],
                                  [198.384, -3.145],
                                  [-92.332, 33.222],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.661, 169.562],
                                  [198.096, -3.188],
                                  [-92.085, 33.546],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-18.425, 168.948],
                                  [197.679, -3.231],
                                  [-91.716, 33.93],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.312, 168.147],
                                  [197.132, -3.275],
                                  [-91.226, 34.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.383, 167.159],
                                  [196.516, -3.319],
                                  [-90.676, 35.066],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.76, 165.981],
                                  [195.709, -3.365],
                                  [-89.944, 35.876],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.323, 164.556],
                                  [194.77, -3.412],
                                  [-89.091, 36.809],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.13, 162.881],
                                  [193.698, -3.46],
                                  [-88.117, 37.864],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.306, 160.954],
                                  [192.431, -3.51],
                                  [-86.961, 39.161],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-29.791, 158.655],
                                  [190.969, -3.563],
                                  [-85.621, 40.7],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-32.643, 156.042],
                                  [189.307, -3.617],
                                  [-84.101, 42.422],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.928, 153.054],
                                  [187.385, -3.675],
                                  [-82.337, 44.447],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.704, 149.629],
                                  [185.2, -3.736],
                                  [-80.33, 46.775],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-44.034, 145.643],
                                  [182.687, -3.801],
                                  [-78.021, 49.406],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.982, 141.096],
                                  [179.784, -3.872],
                                  [-75.345, 52.461],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-54.73, 135.861],
                                  [176.485, -3.948],
                                  [-72.307, 56],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.278, 129.816],
                                  [172.662, -4.031],
                                  [-68.78, 60.084],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.874, 122.895],
                                  [168.251, -4.123],
                                  [-64.708, 64.773],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-77.642, 114.79],
                                  [163.12, -4.226],
                                  [-59.966, 70.249],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-87.828, 105.437],
                                  [157.202, -4.34],
                                  [-54.495, 76.571],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-99.678, 94.586],
                                  [150.365, -4.468],
                                  [-48.172, 83.863],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-113.255, 82.111],
                                  [142.48, -4.611],
                                  [-40.877, 92.303],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.62, 68.014],
                                  [133.547, -4.77],
                                  [-32.609, 101.894],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.588, 52.42],
                                  [123.7, -4.941],
                                  [-23.489, 112.454],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.545, 35.89],
                                  [113.267, -5.121],
                                  [-13.82, 123.622],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.626, 19.297],
                                  [102.771, -5.3],
                                  [-4.088, 134.913],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.03, 3.327],
                                  [92.673, -5.471],
                                  [5.281, 145.724],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.264, -11.583],
                                  [83.296, -5.629],
                                  [13.983, 155.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-230.08, -25.244],
                                  [74.712, -5.773],
                                  [21.956, 165.058],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.48, -37.595],
                                  [66.919, -5.904],
                                  [29.203, 173.459],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.647, -48.7],
                                  [59.912, -6.021],
                                  [35.72, 181.017],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.643, -58.805],
                                  [53.564, -6.127],
                                  [41.63, 187.85],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-276.652, -67.976],
                                  [47.806, -6.223],
                                  [46.994, 194.08],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-285.737, -76.334],
                                  [42.513, -6.311],
                                  [51.932, 199.768],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.082, -84.007],
                                  [37.741, -6.391],
                                  [56.385, 204.972],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-301.748, -91.055],
                                  [33.3, -6.464],
                                  [60.534, 209.755],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-308.798, -97.48],
                                  [29.251, -6.531],
                                  [64.318, 214.114],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-315.354, -103.469],
                                  [25.467, -6.594],
                                  [67.859, 218.172],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-321.416, -109.021],
                                  [22.008, -6.651],
                                  [71.096, 221.988],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-327.047, -114.197],
                                  [18.748, -6.705],
                                  [74.151, 225.503],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-332.307, -119.001],
                                  [15.749, -6.754],
                                  [76.962, 228.776],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.197, -123.493],
                                  [12.946, -6.8],
                                  [79.59, 231.807],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-341.779, -127.733],
                                  [10.277, -6.843],
                                  [82.097, 234.657],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-346.113, -131.663],
                                  [7.804, -6.884],
                                  [84.421, 237.386],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-350.138, -135.344],
                                  [5.524, -6.921],
                                  [86.562, 239.873],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-353.979, -138.837],
                                  [3.313, -6.957],
                                  [88.643, 242.239],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.51, -142.142],
                                  [1.296, -6.99],
                                  [90.541, 244.484],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.917, -145.199],
                                  [-0.653, -7.021],
                                  [92.377, 246.609],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-364.078, -148.13],
                                  [-2.473, -7.051],
                                  [94.092, 248.552],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.053, -150.875],
                                  [-4.163, -7.078],
                                  [95.685, 250.434],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.904, -153.432],
                                  [-5.787, -7.104],
                                  [97.216, 252.195],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-372.57, -155.865],
                                  [-7.342, -7.129],
                                  [98.688, 253.835],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.051, -158.173],
                                  [-8.771, -7.152],
                                  [100.036, 255.415],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-377.469, -160.356],
                                  [-10.134, -7.174],
                                  [101.323, 256.873],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-379.701, -162.414],
                                  [-11.369, -7.194],
                                  [102.489, 258.271],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-381.81, -164.348],
                                  [-12.6, -7.213],
                                  [103.653, 259.608],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-383.796, -166.157],
                                  [-13.766, -7.232],
                                  [104.758, 260.885],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-385.719, -167.903],
                                  [-14.805, -7.248],
                                  [105.739, 262.04],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.457, -169.524],
                                  [-15.841, -7.265],
                                  [106.722, 263.135],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-389.194, -171.084],
                                  [-16.812, -7.28],
                                  [107.642, 264.229],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-390.746, -172.519],
                                  [-17.718, -7.295],
                                  [108.502, 265.202],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-392.235, -173.891],
                                  [-18.561, -7.308],
                                  [109.3, 266.115],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-393.663, -175.139],
                                  [-19.339, -7.32],
                                  [110.037, 267.028],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-394.967, -176.385],
                                  [-20.115, -7.333],
                                  [110.774, 267.819],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-396.209, -177.509],
                                  [-20.826, -7.344],
                                  [111.45, 268.61],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-397.388, -178.569],
                                  [-21.473, -7.354],
                                  [112.064, 269.34],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.506, -179.567],
                                  [-22.12, -7.364],
                                  [112.679, 270.01],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-399.5, -180.503],
                                  [-22.701, -7.374],
                                  [113.232, 270.679],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-400.432, -181.375],
                                  [-23.22, -7.382],
                                  [113.724, 271.228],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-401.363, -182.186],
                                  [-23.736, -7.39],
                                  [114.216, 271.836],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-402.171, -182.935],
                                  [-24.189, -7.397],
                                  [114.646, 272.323],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-402.916, -183.621],
                                  [-24.641, -7.404],
                                  [115.077, 272.811],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-403.661, -184.245],
                                  [-25.029, -7.41],
                                  [115.445, 273.237],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-404.283, -184.869],
                                  [-25.415, -7.416],
                                  [115.815, 273.663],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-404.904, -185.43],
                                  [-25.739, -7.421],
                                  [116.122, 274.029],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-405.402, -185.929],
                                  [-26.061, -7.426],
                                  [116.43, 274.334],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-405.899, -186.365],
                                  [-26.32, -7.43],
                                  [116.676, 274.638],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-406.334, -186.74],
                                  [-26.579, -7.434],
                                  [116.922, 274.943],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-406.769, -187.114],
                                  [-26.773, -7.438],
                                  [117.106, 275.186],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.08, -187.426],
                                  [-26.968, -7.441],
                                  [117.291, 275.37],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.391, -187.676],
                                  [-27.16, -7.444],
                                  [117.475, 275.552],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.639, -187.925],
                                  [-27.289, -7.446],
                                  [117.599, 275.735],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.827, -188.112],
                                  [-27.419, -7.448],
                                  [117.722, 275.857],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-408.013, -188.298],
                                  [-27.485, -7.449],
                                  [117.784, 275.979],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-408.138, -188.423],
                                  [-27.611, -7.451],
                                  [117.907, 276.04],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-408.261, -188.486],
                                  [-27.613, -7.451],
                                  [117.906, 276.101],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-408.323, -188.548],
                                  [-27.676, -7.452],
                                  [117.968, 276.162],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-408.26, -188.486],
                                  [-27.677, -7.452],
                                  [117.969, 276.099],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-408.132, -188.421],
                                  [-27.616, -7.388],
                                  [117.907, 275.787],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.875, -188.169],
                                  [-27.616, -7.262],
                                  [117.907, 275.289],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.491, -187.914],
                                  [-27.494, -7.135],
                                  [117.846, 274.603],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-407.041, -187.534],
                                  [-27.433, -6.945],
                                  [117.724, 273.668],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-406.401, -187.027],
                                  [-27.31, -6.692],
                                  [117.602, 272.421],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-405.632, -186.456],
                                  [-27.127, -6.376],
                                  [117.48, 270.925],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-404.672, -185.695],
                                  [-26.944, -5.996],
                                  [117.298, 269.117],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-403.581, -184.809],
                                  [-26.76, -5.615],
                                  [117.116, 266.997],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-402.299, -183.794],
                                  [-26.515, -5.108],
                                  [116.872, 264.504],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-400.762, -182.588],
                                  [-26.208, -4.475],
                                  [116.569, 261.575],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.969, -181.193],
                                  [-25.839, -3.779],
                                  [116.268, 258.148],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-396.98, -179.605],
                                  [-25.469, -3.017],
                                  [115.907, 254.223],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-394.61, -177.763],
                                  [-24.974, -2.068],
                                  [115.487, 249.739],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-391.919, -175.666],
                                  [-24.417, -0.991],
                                  [115.008, 244.509],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-388.843, -173.249],
                                  [-23.795, 0.215],
                                  [114.474, 238.596],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-385.318, -170.451],
                                  [-23.108, 1.549],
                                  [113.822, 231.813],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-381.28, -167.266],
                                  [-22.291, 3.198],
                                  [113.116, 223.976],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-376.601, -163.57],
                                  [-21.343, 5.038],
                                  [112.3, 214.901],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.156, -159.294],
                                  [-20.262, 7.136],
                                  [111.377, 204.526],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-364.878, -154.312],
                                  [-18.979, 9.615],
                                  [110.291, 192.483],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.636, -148.553],
                                  [-17.426, 12.479],
                                  [109.114, 178.527],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-349.243, -141.884],
                                  [-15.656, 15.79],
                                  [107.734, 162.357],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-339.571, -134.172],
                                  [-13.593, 19.616],
                                  [106.168, 143.797],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.558, -125.404],
                                  [-11.219, 23.959],
                                  [104.434, 122.797],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-316.458, -115.7],
                                  [-8.52, 28.758],
                                  [102.614, 99.683],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-303.717, -105.373],
                                  [-5.607, 33.885],
                                  [100.777, 75.273],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.857, -94.928],
                                  [-2.549, 38.965],
                                  [98.98, 50.808],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.453, -84.822],
                                  [0.459, 43.928],
                                  [97.389, 27.276],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.89, -75.386],
                                  [3.334, 48.577],
                                  [96.035, 5.466],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-256.361, -66.696],
                                  [6.056, 52.852],
                                  [94.835, -14.448],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-246.86, -58.764],
                                  [8.548, 56.685],
                                  [93.895, -32.363],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-238.266, -51.59],
                                  [10.919, 60.197],
                                  [93.077, -48.59],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-230.45, -45.059],
                                  [13.104, 63.332],
                                  [92.373, -63.142],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.402, -39.103],
                                  [15.156, 66.21],
                                  [91.838, -76.391],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.997, -33.607],
                                  [17.075, 68.832],
                                  [91.408, -88.408],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.111, -28.624],
                                  [18.855, 71.259],
                                  [91.024, -99.379],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.736, -23.971],
                                  [20.556, 73.493],
                                  [90.737, -109.426],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.809, -19.711],
                                  [22.122, 75.534],
                                  [90.494, -118.679],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.207, -15.715],
                                  [23.606, 77.448],
                                  [90.288, -127.198],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.986, -12.044],
                                  [25.013, 79.228],
                                  [90.123, -135.049],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.024, -8.576],
                                  [26.34, 80.821],
                                  [90.002, -142.353],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.377, -5.372],
                                  [27.591, 82.405],
                                  [89.916, -149.17],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.985, -2.367],
                                  [28.762, 83.803],
                                  [89.874, -155.502],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.788, 0.495],
                                  [29.858, 85.133],
                                  [89.811, -161.416],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.781, 3.163],
                                  [30.934, 86.396],
                                  [89.79, -166.967],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.968, 5.632],
                                  [31.934, 87.593],
                                  [89.751, -172.155],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.341, 8.022],
                                  [32.915, 88.723],
                                  [89.753, -177.048],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.842, 10.217],
                                  [33.822, 89.789],
                                  [89.798, -181.638],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.535, 12.341],
                                  [34.714, 90.79],
                                  [89.767, -185.933],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.35, 14.325],
                                  [35.527, 91.668],
                                  [89.838, -189.928],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.296, 16.178],
                                  [36.326, 92.596],
                                  [89.833, -193.747],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.307, 17.958],
                                  [37.055, 93.404],
                                  [89.872, -197.328],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.499, 19.665],
                                  [37.764, 94.204],
                                  [89.951, -200.733],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.762, 21.239],
                                  [38.461, 94.943],
                                  [89.959, -203.904],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.146, 22.741],
                                  [39.14, 95.619],
                                  [90.01, -206.955],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.592, 24.114],
                                  [39.751, 96.289],
                                  [90.107, -209.768],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.107, 25.473],
                                  [40.347, 96.957],
                                  [90.129, -212.466],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.742, 26.762],
                                  [40.869, 97.499],
                                  [90.199, -214.99],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.441, 27.927],
                                  [41.38, 98.103],
                                  [90.255, -217.34],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.204, 29.073],
                                  [41.938, 98.58],
                                  [90.297, -219.571],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.09, 30.157],
                                  [42.366, 99.114],
                                  [90.325, -221.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.977, 31.167],
                                  [42.841, 99.584],
                                  [90.401, -223.688],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-142.985, 32.109],
                                  [43.244, 99.995],
                                  [90.462, -225.57],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.998, 32.984],
                                  [43.693, 100.459],
                                  [90.512, -227.338],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.073, 33.848],
                                  [44.074, 100.805],
                                  [90.551, -228.991],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.266, 34.647],
                                  [44.383, 101.205],
                                  [90.638, -230.529],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.466, 35.377],
                                  [44.742, 101.544],
                                  [90.654, -232.007],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.724, 36.099],
                                  [45.091, 101.879],
                                  [90.718, -233.371],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.985, 36.752],
                                  [45.372, 102.154],
                                  [90.775, -234.679],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.367, 37.34],
                                  [45.643, 102.428],
                                  [90.817, -235.814],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-136.751, 37.921],
                                  [45.9, 102.697],
                                  [90.851, -236.952],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-136.197, 38.436],
                                  [46.152, 102.965],
                                  [90.873, -237.971],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.704, 38.945],
                                  [46.393, 103.17],
                                  [90.889, -238.934],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.21, 39.384],
                                  [46.567, 103.377],
                                  [90.951, -239.783],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.777, 39.817],
                                  [46.792, 103.576],
                                  [91.002, -240.576],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.35, 40.189],
                                  [46.945, 103.778],
                                  [90.991, -241.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.98, 40.55],
                                  [47.097, 103.916],
                                  [91.023, -241.994],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.67, 40.846],
                                  [47.292, 104.054],
                                  [91.052, -242.615],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.363, 41.136],
                                  [47.427, 104.187],
                                  [91.07, -243.18],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.058, 41.365],
                                  [47.49, 104.323],
                                  [91.08, -243.63],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.867, 41.583],
                                  [47.609, 104.454],
                                  [91.142, -244.083],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.624, 41.799],
                                  [47.718, 104.526],
                                  [91.138, -244.421],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.5, 41.951],
                                  [47.759, 104.593],
                                  [91.124, -244.762],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.315, 42.096],
                                  [47.856, 104.66],
                                  [91.164, -245.046],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.19, 42.177],
                                  [47.885, 104.726],
                                  [91.194, -245.211],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.13, 42.313],
                                  [47.966, 104.732],
                                  [91.162, -245.382],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.067, 42.327],
                                  [47.983, 104.797],
                                  [91.178, -245.493],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.005, 42.394],
                                  [47.991, 104.798],
                                  [91.191, -245.606],
                                ],
                                c: false,
                              },
                            ],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.684, 0.684, 0.684, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.684, 0.684, 0.684, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [57.94, 24.143],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.936, 24.174],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.989, 24.219],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.039, 24.257],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.101, 24.356],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.239, 24.531],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.392, 24.732],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.566, 24.979],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.759, 25.217],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.048, 25.605],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.32, 25.996],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.694, 26.432],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.154, 27.014],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.639, 27.648],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.257, 28.388],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.942, 29.202],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.805, 30.227],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.784, 31.346],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.947, 32.62],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.378, 34.168],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.138, 35.973],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.316, 38.004],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.976, 40.343],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.36, 43.069],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.677, 46.064],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.143, 49.252],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.892, 52.286],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.898, 54.753],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.689, 56.282],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.731, 56.508],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.705, 55.318],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.998, 52.322],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.68, 49.591],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.624, 46.948],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.609, 44.457],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.473, 42.048],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.295, 39.721],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.936, 37.44],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.413, 35.238],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [159.738, 33.117],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.882, 31.06],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.856, 29.065],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.591, 27.197],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [171.231, 25.3],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.673, 23.545],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.937, 21.843],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.078, 20.16],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.978, 18.814],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.762, 17.528],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.481, 16.3],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.175, 15.133],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.779, 14.081],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.349, 12.975],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.966, 11.989],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.422, 11.01],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.965, 10.1],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.391, 9.26],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.82, 8.434],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.216, 7.62],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.615, 6.946],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.999, 6.225],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.282, 5.639],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.632, 5.006],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.948, 4.449],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.164, 3.963],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.502, 3.424],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.701, 3.018],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.953, 2.558],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.187, 2.167],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.402, 1.781],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.58, 1.465],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.75, 1.15],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.91, 0.842],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.071, 0.534],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.198, 0.292],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.371, 0.05],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.458, -0.184],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.595, -0.42],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.671, -0.595],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.753, -0.767],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.842, -0.938],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.982, -1.114],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.018, -1.279],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.096, -1.39],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.163, -1.497],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.167, -1.601],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.229, -1.713],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.255, -1.759],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.297, -1.866],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.302, -1.914],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.312, -1.962],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.294, -2.012],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.273, -2.059],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.208, -2.123],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.12, -2.178],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.028, -2.248],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.857, -2.324],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.65, -2.401],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.428, -2.502],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.132, -2.613],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.786, -2.74],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.399, -2.894],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.936, -3.077],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.421, -3.3],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.834, -3.576],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.42, -3.204],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.416, -2.304],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.758, -1.32],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.387, -0.26],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.119, 0.856],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.768, 2.027],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.158, 3.17],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.101, 4.217],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.357, 5.043],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.817, 5.448],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.591, 5.213],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.129, 4.127],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.345, 2.127],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.398, -0.597],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.108, -3.705],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.848, -6.854],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.53, -9.809],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.891, -12.441],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.412, -14.721],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.646, -16.691],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.03, -18.373],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.728, -19.802],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.856, -21.033],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.569, -22.085],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.896, -22.982],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.924, -23.76],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.642, -24.432],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.166, -25.018],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.465, -25.528],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.598, -25.977],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.594, -26.372],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.435, -26.712],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.138, -27.019],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.767, -27.287],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.238, -27.531],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.658, -27.735],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56, -27.934],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.217, -28.101],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.39, -28.244],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.502, -28.371],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.517, -28.487],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.525, -28.605],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.436, -28.714],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.301, -28.792],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.1, -28.85],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.887, -28.941],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.599, -28.991],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.292, -29.046],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.926, -29.09],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.538, -29.142],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.096, -29.179],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.649, -29.221],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.146, -29.248],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.623, -29.284],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.087, -29.31],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.479, -29.326],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.874, -29.363],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.23, -29.374],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.561, -29.397],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.856, -29.399],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.158, -29.421],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.405, -29.425],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.658, -29.449],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.873, -29.454],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.069, -29.468],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.229, -29.461],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.404, -29.486],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.54, -29.493],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.667, -29.488],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.758, -29.495],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.837, -29.493],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.906, -29.511],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.973, -29.5],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.022, -29.497],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.108, -29.507],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.23, -29.439],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.343, -29.423],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.579, -29.374],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.854, -29.309],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.171, -29.234],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.543, -29.117],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.968, -29.015],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.503, -28.877],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.141, -28.695],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.833, -28.501],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.657, -28.299],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.586, -28.026],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.658, -27.722],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.865, -27.379],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.199, -26.986],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.795, -26.482],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.582, -25.925],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.524, -25.241],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.743, -24.395],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.175, -23.376],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.832, -22.106],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.565, -20.471],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.059, -18.326],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.804, -15.472],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.948, -11.586],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.345, -6.453],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.346, 0.164],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.315, 7.91],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.457, 16.173],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.953, 24.366],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.476, 32.206],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.489, 39.481],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.055, 46.251],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.292, 52.479],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.151, 58.226],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-2.61, 63.516],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.337, 68.363],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.796, 72.866],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.739, 77.009],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.34, 80.821],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.938, 77.401],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.264, 74.007],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.34, 70.814],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.253, 67.807],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.969, 64.974],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.561, 62.322],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.008, 59.796],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.347, 57.451],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.563, 55.186],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.705, 53.109],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.745, 51.071],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.729, 49.192],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.65, 47.419],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.527, 45.737],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.328, 44.156],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.079, 42.678],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.755, 41.212],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.395, 39.919],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.045, 38.662],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.578, 37.492],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.141, 36.367],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.626, 35.304],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.133, 34.364],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.574, 33.393],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.958, 32.537],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.352, 31.726],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.739, 30.987],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.069, 30.211],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.372, 29.582],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.662, 28.941],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.934, 28.394],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.189, 27.858],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.398, 27.362],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.637, 26.942],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.805, 26.53],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.975, 26.128],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.168, 25.817],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.313, 25.501],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.4, 25.219],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.531, 25.015],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.638, 24.801],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.686, 24.614],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.789, 24.454],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.831, 24.357],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.894, 24.268],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.918, 24.232],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.935, 24.14],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [57.94, 24.143],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.936, 24.174],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.989, 24.219],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.039, 24.257],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.101, 24.356],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.239, 24.531],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.392, 24.732],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.566, 24.979],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.759, 25.217],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.048, 25.605],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.32, 25.996],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.694, 26.432],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.154, 27.014],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.639, 27.648],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.257, 28.388],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.942, 29.202],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.805, 30.227],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.784, 31.346],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.947, 32.62],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.378, 34.168],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.138, 35.973],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.316, 38.004],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.976, 40.343],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.36, 43.069],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.677, 46.064],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.143, 49.252],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.892, 52.286],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.898, 54.753],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.689, 56.282],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.731, 56.508],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.705, 55.318],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.998, 52.322],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.68, 49.591],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.624, 46.948],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.609, 44.457],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.473, 42.048],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.295, 39.721],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.936, 37.44],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.413, 35.238],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [159.738, 33.117],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.882, 31.06],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.856, 29.065],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.591, 27.197],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [171.231, 25.3],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.673, 23.545],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.937, 21.843],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.078, 20.16],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.978, 18.814],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.762, 17.528],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.481, 16.3],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.175, 15.133],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.779, 14.081],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.349, 12.975],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.966, 11.989],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.422, 11.01],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.965, 10.1],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.391, 9.26],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.82, 8.434],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.216, 7.62],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.615, 6.946],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.999, 6.225],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.282, 5.639],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.632, 5.006],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.948, 4.449],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.164, 3.963],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.502, 3.424],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.701, 3.018],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.953, 2.558],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.187, 2.167],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.402, 1.781],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.58, 1.465],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.75, 1.15],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.91, 0.842],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.071, 0.534],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.198, 0.292],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.371, 0.05],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.458, -0.184],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.595, -0.42],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.671, -0.595],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.753, -0.767],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.842, -0.938],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.982, -1.114],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.018, -1.279],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.096, -1.39],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.163, -1.497],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.167, -1.601],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.229, -1.713],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.255, -1.759],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.297, -1.866],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.302, -1.914],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.312, -1.962],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.294, -2.012],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.273, -2.059],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.208, -2.123],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.12, -2.178],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.028, -2.248],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.857, -2.324],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.65, -2.401],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.428, -2.502],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.132, -2.613],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.786, -2.74],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.399, -2.894],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.936, -3.077],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.421, -3.3],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.834, -3.576],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.42, -3.204],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.416, -2.304],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.758, -1.32],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.387, -0.26],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.119, 0.856],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.768, 2.027],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.158, 3.17],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.101, 4.217],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.357, 5.043],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.817, 5.448],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.591, 5.213],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.129, 4.127],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.345, 2.127],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.398, -0.597],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.108, -3.705],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.848, -6.854],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.53, -9.809],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.891, -12.441],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.412, -14.721],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.646, -16.691],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.03, -18.373],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.728, -19.802],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.856, -21.033],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.569, -22.085],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.896, -22.982],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.924, -23.76],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.642, -24.432],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.166, -25.018],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.465, -25.528],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.598, -25.977],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.594, -26.372],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.435, -26.712],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.138, -27.019],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.767, -27.287],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.238, -27.531],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.658, -27.735],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56, -27.934],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.217, -28.101],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.39, -28.244],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.502, -28.371],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.517, -28.487],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.525, -28.605],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.436, -28.714],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.301, -28.792],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.1, -28.85],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.887, -28.941],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.599, -28.991],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.292, -29.046],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.926, -29.09],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.538, -29.142],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.096, -29.179],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.649, -29.221],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.146, -29.248],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.623, -29.284],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.087, -29.31],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.479, -29.326],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.874, -29.363],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.23, -29.374],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.561, -29.397],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.856, -29.399],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.158, -29.421],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.405, -29.425],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.658, -29.449],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.873, -29.454],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.069, -29.468],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.229, -29.461],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.404, -29.486],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.54, -29.493],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.667, -29.488],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.758, -29.495],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.837, -29.493],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.906, -29.511],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.973, -29.5],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.022, -29.497],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.108, -29.507],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.23, -29.439],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.343, -29.423],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.579, -29.374],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.854, -29.309],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.171, -29.234],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.543, -29.117],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.968, -29.015],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.503, -28.877],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.141, -28.695],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.833, -28.501],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.657, -28.299],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.586, -28.026],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.658, -27.722],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.865, -27.379],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.199, -26.986],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.795, -26.482],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.582, -25.925],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.524, -25.241],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.743, -24.395],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.175, -23.376],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.832, -22.106],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.565, -20.471],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.059, -18.326],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.804, -15.472],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.948, -11.586],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.345, -6.453],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.346, 0.164],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.315, 7.91],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.457, 16.173],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.953, 24.366],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.476, 32.206],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.489, 39.481],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.055, 46.251],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.292, 52.479],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.151, 58.226],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-2.61, 63.516],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.337, 68.363],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.796, 72.866],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.739, 77.009],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.34, 80.821],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.938, 77.401],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.264, 74.007],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.34, 70.814],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.253, 67.807],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.969, 64.974],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.561, 62.322],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.008, 59.796],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.347, 57.451],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.563, 55.186],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.705, 53.109],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.745, 51.071],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.729, 49.192],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.65, 47.419],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.527, 45.737],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.328, 44.156],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.079, 42.678],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.755, 41.212],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.395, 39.919],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.045, 38.662],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.578, 37.492],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.141, 36.367],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.626, 35.304],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.133, 34.364],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.574, 33.393],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.958, 32.537],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.352, 31.726],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.739, 30.987],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.069, 30.211],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.372, 29.582],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.662, 28.941],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.934, 28.394],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.189, 27.858],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.398, 27.362],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.637, 26.942],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.805, 26.53],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.975, 26.128],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.168, 25.817],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.313, 25.501],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.4, 25.219],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.531, 25.015],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.638, 24.801],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.686, 24.614],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.789, 24.454],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.831, 24.357],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.894, 24.268],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.918, 24.232],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.935, 24.14],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 2',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.005, 56.794],
                                  [59.995, 109.598],
                                  [110.384, -231.143],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.946, 56.881],
                                  [60.108, 109.551],
                                  [110.236, -231.015],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.828, 56.965],
                                  [60.226, 109.448],
                                  [110.058, -230.773],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.65, 57.091],
                                  [60.404, 109.344],
                                  [109.735, -230.401],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.475, 57.259],
                                  [60.687, 109.193],
                                  [109.39, -229.908],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.178, 57.47],
                                  [61.038, 108.995],
                                  [108.89, -229.231],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-118.885, 57.723],
                                  [61.441, 108.741],
                                  [108.298, -228.432],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-118.469, 58.064],
                                  [61.91, 108.44],
                                  [107.56, -227.497],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-117.995, 58.447],
                                  [62.547, 108.146],
                                  [106.666, -226.386],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-117.461, 58.918],
                                  [63.189, 107.741],
                                  [105.672, -225.029],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-116.807, 59.478],
                                  [64.007, 107.235],
                                  [104.506, -223.473],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-116.093, 60.079],
                                  [64.992, 106.689],
                                  [103.122, -221.68],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-115.196, 60.815],
                                  [66.051, 106.086],
                                  [101.559, -219.571],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-114.241, 61.639],
                                  [67.333, 105.334],
                                  [99.753, -217.201],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-113.04, 62.597],
                                  [68.751, 104.48],
                                  [97.706, -214.407],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-111.782, 63.737],
                                  [70.445, 103.521],
                                  [95.346, -211.235],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-110.215, 65.011],
                                  [72.338, 102.476],
                                  [92.658, -207.624],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.466, 66.515],
                                  [74.523, 101.171],
                                  [89.517, -203.44],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-106.472, 68.2],
                                  [77.062, 99.714],
                                  [85.963, -198.576],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-104.171, 70.208],
                                  [80.018, 98.059],
                                  [81.862, -193.007],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-101.438, 72.54],
                                  [83.46, 96.104],
                                  [77.122, -186.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.274, 75.244],
                                  [87.39, 93.847],
                                  [71.673, -179.047],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-94.678, 78.367],
                                  [91.969, 91.24],
                                  [65.369, -170.337],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-90.464, 82.006],
                                  [97.278, 88.228],
                                  [58.118, -160.291],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-85.694, 86.164],
                                  [103.308, 84.807],
                                  [49.884, -148.873],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-80.369, 90.75],
                                  [109.95, 81.03],
                                  [40.914, -136.316],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.801, 95.628],
                                  [117.001, 76.988],
                                  [31.368, -123.069],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.242, 100.52],
                                  [124.047, 72.991],
                                  [21.812, -109.716],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-63.815, 105.286],
                                  [130.853, 69.061],
                                  [12.59, -96.876],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.836, 109.691],
                                  [137.161, 65.362],
                                  [4.008, -84.887],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-54.303, 113.782],
                                  [142.916, 61.932],
                                  [-3.796, -74.036],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-50.217, 117.464],
                                  [148.117, 58.842],
                                  [-10.876, -64.17],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-46.517, 120.782],
                                  [152.827, 55.975],
                                  [-17.306, -55.326],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-43.203, 123.831],
                                  [157.044, 53.385],
                                  [-23.116, -47.39],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-40.276, 126.608],
                                  [160.823, 50.971],
                                  [-28.371, -40.167],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-37.611, 129.162],
                                  [164.288, 48.739],
                                  [-33.157, -33.664],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.147, 131.49],
                                  [167.39, 46.633],
                                  [-37.482, -27.744],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-33.01, 133.641],
                                  [170.225, 44.647],
                                  [-41.534, -22.359],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-31.013, 135.613],
                                  [172.813, 42.793],
                                  [-45.204, -17.441],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-29.219, 137.504],
                                  [175.202, 41.008],
                                  [-48.631, -12.921],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.567, 139.214],
                                  [177.398, 39.299],
                                  [-51.748, -8.807],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.12, 140.842],
                                  [179.338, 37.721],
                                  [-54.686, -4.983],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.754, 142.387],
                                  [181.201, 36.098],
                                  [-57.396, -1.547],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.532, 143.85],
                                  [182.87, 34.603],
                                  [-59.936, 1.66],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.455, 145.23],
                                  [184.398, 33.133],
                                  [-62.305, 4.581],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.461, 146.526],
                                  [185.792, 31.678],
                                  [-64.517, 7.23],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.612, 147.789],
                                  [187.047, 30.235],
                                  [-66.566, 9.719],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.782, 148.967],
                                  [188.231, 28.821],
                                  [-68.463, 11.986],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.094, 150.111],
                                  [189.278, 27.422],
                                  [-70.261, 14.041],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-18.483, 151.22],
                                  [190.251, 26.054],
                                  [-71.908, 15.892],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.946, 152.292],
                                  [191.154, 24.772],
                                  [-73.518, 17.59],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.476, 153.274],
                                  [191.935, 23.418],
                                  [-74.985, 19.16],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.07, 154.268],
                                  [192.712, 22.166],
                                  [-76.303, 20.654],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.657, 155.166],
                                  [193.38, 20.915],
                                  [-77.595, 21.974],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.358, 156.071],
                                  [194.049, 19.73],
                                  [-78.803, 23.188],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.043, 156.928],
                                  [194.617, 18.614],
                                  [-79.929, 24.351],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-15.707, 157.734],
                                  [195.203, 17.516],
                                  [-80.981, 25.479],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-15.473, 158.541],
                                  [195.684, 16.443],
                                  [-81.954, 26.455],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-15.216, 159.24],
                                  [196.242, 15.514],
                                  [-82.914, 27.454],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.934, 159.991],
                                  [196.705, 14.556],
                                  [-83.739, 28.365],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.692, 160.633],
                                  [197.124, 13.738],
                                  [-84.557, 29.241],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.49, 161.271],
                                  [197.558, 12.889],
                                  [-85.302, 30.089],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.267, 161.852],
                                  [198.009, 12.124],
                                  [-86.037, 30.84],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.025, 162.431],
                                  [198.359, 11.437],
                                  [-86.707, 31.61],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.828, 162.951],
                                  [198.779, 10.715],
                                  [-87.311, 32.287],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.614, 163.468],
                                  [199.095, 10.13],
                                  [-87.906, 32.979],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.448, 163.928],
                                  [199.419, 9.506],
                                  [-88.438, 33.574],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.268, 164.384],
                                  [199.754, 8.954],
                                  [-88.963, 34.18],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.139, 164.783],
                                  [200.037, 8.418],
                                  [-89.425, 34.741],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.936, 165.179],
                                  [200.333, 7.956],
                                  [-89.88, 35.258],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.847, 165.572],
                                  [200.572, 7.507],
                                  [-90.332, 35.724],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.687, 165.908],
                                  [200.821, 7.071],
                                  [-90.722, 36.143],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.58, 166.241],
                                  [201.015, 6.642],
                                  [-91.046, 36.572],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.465, 166.516],
                                  [201.216, 6.283],
                                  [-91.367, 36.948],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.406, 166.845],
                                  [201.419, 5.934],
                                  [-91.686, 37.273],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.277, 167.06],
                                  [201.572, 5.594],
                                  [-91.942, 37.606],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.204, 167.33],
                                  [201.727, 5.262],
                                  [-92.196, 37.886],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.189, 167.541],
                                  [201.824, 4.997],
                                  [-92.444, 38.111],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.105, 167.751],
                                  [201.928, 4.737],
                                  [-92.633, 38.341],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.079, 167.903],
                                  [202.033, 4.483],
                                  [-92.82, 38.518],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.048, 168.11],
                                  [202.142, 4.235],
                                  [-92.944, 38.639],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.013, 168.258],
                                  [202.195, 3.993],
                                  [-93.123, 38.826],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.036, 168.406],
                                  [202.249, 3.817],
                                  [-93.243, 38.896],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.993, 168.495],
                                  [202.308, 3.645],
                                  [-93.301, 38.969],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.009, 168.583],
                                  [202.308, 3.478],
                                  [-93.419, 39.047],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.085, 168.67],
                                  [202.307, 3.313],
                                  [-93.472, 39.066],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.094, 168.756],
                                  [202.312, 3.215],
                                  [-93.526, 39.031],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.101, 168.84],
                                  [202.319, 3.059],
                                  [-93.577, 38.996],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.167, 168.867],
                                  [202.266, 2.966],
                                  [-93.567, 38.966],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.231, 168.892],
                                  [202.215, 2.877],
                                  [-93.554, 38.877],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.352, 168.854],
                                  [202.101, 2.788],
                                  [-93.482, 38.848],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.593, 168.69],
                                  [201.973, 2.703],
                                  [-93.415, 38.88],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.011, 168.403],
                                  [201.657, 2.614],
                                  [-93.17, 38.973],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.548, 167.928],
                                  [201.327, 2.527],
                                  [-92.927, 39.24],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.325, 167.326],
                                  [200.864, 2.438],
                                  [-92.567, 39.568],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-15.222, 166.536],
                                  [200.265, 2.347],
                                  [-92.09, 40.014],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.298, 165.561],
                                  [199.587, 2.256],
                                  [-91.556, 40.578],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.675, 164.395],
                                  [198.715, 2.16],
                                  [-90.846, 41.317],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.234, 162.983],
                                  [197.702, 2.062],
                                  [-90.019, 42.174],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.031, 161.32],
                                  [196.546, 1.961],
                                  [-89.077, 43.149],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.19, 159.405],
                                  [195.188, 1.855],
                                  [-87.959, 44.358],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.653, 157.121],
                                  [193.622, 1.745],
                                  [-86.664, 45.799],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-28.476, 154.521],
                                  [191.845, 1.629],
                                  [-85.195, 47.416],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-31.723, 151.546],
                                  [189.793, 1.506],
                                  [-83.489, 49.325],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.453, 148.135],
                                  [187.463, 1.376],
                                  [-81.551, 51.525],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.726, 144.166],
                                  [184.787, 1.236],
                                  [-79.32, 54.017],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-44.608, 139.636],
                                  [181.7, 1.084],
                                  [-76.736, 56.915],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-50.275, 134.418],
                                  [178.194, 0.92],
                                  [-73.801, 60.279],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-56.729, 128.392],
                                  [174.135, 0.74],
                                  [-70.394, 64.166],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-64.212, 121.49],
                                  [169.454, 0.54],
                                  [-66.461, 68.634],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.849, 113.41],
                                  [164.017, 0.317],
                                  [-61.881, 73.858],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-82.879, 104.081],
                                  [157.745, 0.068],
                                  [-56.597, 79.894],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-94.546, 93.259],
                                  [150.503, -0.211],
                                  [-50.489, 86.86],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-107.91, 80.813],
                                  [142.155, -0.524],
                                  [-43.442, 94.93],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.034, 66.749],
                                  [132.7, -0.872],
                                  [-35.455, 104.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.735, 51.191],
                                  [122.281, -1.249],
                                  [-26.644, 114.21],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.409, 34.7],
                                  [111.249, -1.644],
                                  [-17.301, 124.901],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.208, 18.145],
                                  [100.153, -2.037],
                                  [-7.894, 135.714],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.341, 2.21],
                                  [89.481, -2.413],
                                  [1.162, 146.07],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.326, -12.665],
                                  [79.572, -2.761],
                                  [9.578, 155.737],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.916, -26.296],
                                  [70.506, -3.079],
                                  [17.29, 164.599],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.116, -38.619],
                                  [62.28, -3.366],
                                  [24.303, 172.654],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-248.104, -49.699],
                                  [54.886, -3.625],
                                  [30.613, 179.901],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-258.939, -59.78],
                                  [48.188, -3.858],
                                  [36.335, 186.457],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-268.804, -68.931],
                                  [42.117, -4.069],
                                  [41.531, 192.435],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.762, -77.269],
                                  [36.542, -4.264],
                                  [46.317, 197.894],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-285.992, -84.925],
                                  [31.512, -4.438],
                                  [50.633, 202.892],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-293.553, -91.956],
                                  [26.837, -4.601],
                                  [54.658, 207.485],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.508, -98.367],
                                  [22.574, -4.748],
                                  [58.33, 211.673],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-306.977, -104.343],
                                  [18.595, -4.886],
                                  [61.766, 215.572],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-312.962, -109.881],
                                  [14.955, -5.012],
                                  [64.91, 219.241],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-318.521, -115.045],
                                  [11.529, -5.13],
                                  [67.877, 222.62],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-323.714, -119.838],
                                  [8.376, -5.239],
                                  [70.608, 225.767],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.543, -124.32],
                                  [5.431, -5.34],
                                  [73.162, 228.683],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-333.07, -128.549],
                                  [2.631, -5.436],
                                  [75.6, 231.426],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.352, -132.47],
                                  [0.037, -5.525],
                                  [77.861, 234.052],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-341.329, -136.143],
                                  [-2.36, -5.607],
                                  [79.943, 236.447],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-345.125, -139.627],
                                  [-4.676, -5.687],
                                  [81.968, 238.725],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-348.616, -142.924],
                                  [-6.794, -5.759],
                                  [83.815, 240.888],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-351.984, -145.975],
                                  [-8.834, -5.829],
                                  [85.603, 242.934],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-355.109, -148.897],
                                  [-10.741, -5.894],
                                  [87.273, 244.806],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-358.052, -151.636],
                                  [-12.513, -5.954],
                                  [88.825, 246.62],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.872, -154.187],
                                  [-14.213, -6.012],
                                  [90.317, 248.318],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-363.509, -156.614],
                                  [-15.838, -6.067],
                                  [91.752, 249.9],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-365.965, -158.918],
                                  [-17.335, -6.118],
                                  [93.067, 251.423],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-368.358, -161.095],
                                  [-18.761, -6.166],
                                  [94.322, 252.83],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-370.567, -163.149],
                                  [-20.056, -6.21],
                                  [95.458, 254.178],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-372.654, -165.078],
                                  [-21.34, -6.253],
                                  [96.594, 255.469],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-374.62, -166.882],
                                  [-22.559, -6.294],
                                  [97.674, 256.7],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-376.525, -168.624],
                                  [-23.646, -6.331],
                                  [98.63, 257.816],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-378.246, -170.241],
                                  [-24.728, -6.367],
                                  [99.59, 258.873],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-379.966, -171.798],
                                  [-25.741, -6.401],
                                  [100.488, 259.929],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-381.504, -173.229],
                                  [-26.688, -6.433],
                                  [101.328, 260.869],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-382.979, -174.597],
                                  [-27.569, -6.463],
                                  [102.108, 261.751],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-384.393, -175.843],
                                  [-28.382, -6.49],
                                  [102.828, 262.632],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-385.685, -177.086],
                                  [-29.191, -6.517],
                                  [103.548, 263.397],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.916, -178.207],
                                  [-29.931, -6.542],
                                  [104.209, 264.161],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-388.084, -179.264],
                                  [-30.608, -6.565],
                                  [104.809, 264.867],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-389.191, -180.259],
                                  [-31.281, -6.587],
                                  [105.411, 265.514],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-390.177, -181.193],
                                  [-31.886, -6.608],
                                  [105.952, 266.161],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-391.101, -182.063],
                                  [-32.429, -6.626],
                                  [106.432, 266.691],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-392.025, -182.873],
                                  [-32.966, -6.644],
                                  [106.913, 267.28],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-392.825, -183.62],
                                  [-33.44, -6.659],
                                  [107.334, 267.751],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-393.564, -184.304],
                                  [-33.91, -6.675],
                                  [107.756, 268.222],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-394.303, -184.927],
                                  [-34.315, -6.689],
                                  [108.115, 268.634],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-394.92, -185.549],
                                  [-34.715, -6.702],
                                  [108.478, 269.047],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-395.536, -186.109],
                                  [-35.054, -6.713],
                                  [108.778, 269.4],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-396.031, -186.606],
                                  [-35.388, -6.725],
                                  [109.08, 269.695],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-396.523, -187.041],
                                  [-35.659, -6.734],
                                  [109.32, 269.99],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-396.954, -187.415],
                                  [-35.928, -6.742],
                                  [109.563, 270.284],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-397.385, -187.788],
                                  [-36.131, -6.749],
                                  [109.742, 270.52],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-397.694, -188.1],
                                  [-36.335, -6.756],
                                  [109.923, 270.698],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.002, -188.35],
                                  [-36.533, -6.763],
                                  [110.103, 270.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.25, -188.597],
                                  [-36.669, -6.767],
                                  [110.225, 271.051],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.436, -188.785],
                                  [-36.803, -6.772],
                                  [110.346, 271.169],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.62, -188.97],
                                  [-36.874, -6.774],
                                  [110.406, 271.287],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.745, -189.095],
                                  [-37.001, -6.778],
                                  [110.527, 271.347],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.867, -189.157],
                                  [-37.006, -6.778],
                                  [110.526, 271.406],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.928, -189.219],
                                  [-37.069, -6.781],
                                  [110.587, 271.464],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.864, -189.157],
                                  [-37.071, -6.78],
                                  [110.588, 271.402],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.733, -189.089],
                                  [-37.011, -6.715],
                                  [110.528, 271.092],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.469, -188.834],
                                  [-37.009, -6.588],
                                  [110.53, 270.595],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-398.077, -188.574],
                                  [-36.889, -6.459],
                                  [110.472, 269.913],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-397.616, -188.187],
                                  [-36.827, -6.266],
                                  [110.356, 268.982],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-396.962, -187.672],
                                  [-36.703, -6.01],
                                  [110.241, 267.741],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-396.174, -187.09],
                                  [-36.519, -5.69],
                                  [110.128, 266.252],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-395.193, -186.315],
                                  [-36.335, -5.305],
                                  [109.956, 264.453],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-394.076, -185.414],
                                  [-36.149, -4.917],
                                  [109.786, 262.343],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-392.763, -184.38],
                                  [-35.9, -4.401],
                                  [109.559, 259.863],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-391.191, -183.153],
                                  [-35.59, -3.76],
                                  [109.274, 256.95],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-389.358, -181.731],
                                  [-35.216, -3.054],
                                  [108.995, 253.541],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.321, -180.112],
                                  [-34.839, -2.279],
                                  [108.662, 249.639],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-384.898, -178.233],
                                  [-34.334, -1.316],
                                  [108.276, 245.18],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-382.146, -176.093],
                                  [-33.766, -0.224],
                                  [107.837, 239.984],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-378.999, -173.625],
                                  [-33.127, 1.002],
                                  [107.352, 234.111],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.394, -170.768],
                                  [-32.416, 2.36],
                                  [106.761, 227.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.263, -167.509],
                                  [-31.567, 4.035],
                                  [106.13, 219.596],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-366.48, -163.728],
                                  [-30.576, 5.906],
                                  [105.407, 210.596],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.916, -159.347],
                                  [-29.438, 8.043],
                                  [104.602, 200.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-354.501, -154.238],
                                  [-28.075, 10.568],
                                  [103.667, 188.384],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-347.102, -148.321],
                                  [-26.412, 13.486],
                                  [102.684, 174.576],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-338.53, -141.455],
                                  [-24.491, 16.863],
                                  [101.556, 158.597],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.658, -133.498],
                                  [-22.221, 20.771],
                                  [100.318, 140.283],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-317.426, -124.427],
                                  [-19.565, 25.213],
                                  [99.011, 119.6],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-305.091, -114.353],
                                  [-16.494, 30.128],
                                  [97.73, 96.884],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-292.111, -103.595],
                                  [-13.111, 35.388],
                                  [96.549, 72.957],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-279.023, -92.668],
                                  [-9.496, 40.614],
                                  [95.512, 49.044],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.414, -82.044],
                                  [-5.859, 45.729],
                                  [94.757, 26.121],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.675, -72.081],
                                  [-2.31, 50.529],
                                  [94.277, 4.944],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-244.001, -62.865],
                                  [1.11, 54.953],
                                  [93.969, -14.323],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.382, -54.42],
                                  [4.311, 58.93],
                                  [93.918, -31.601],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-225.696, -46.747],
                                  [7.38, 62.576],
                                  [93.973, -47.199],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.814, -39.74],
                                  [10.256, 65.843],
                                  [94.12, -61.142],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.714, -33.324],
                                  [12.98, 68.844],
                                  [94.409, -73.798],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.27, -27.392],
                                  [15.556, 71.585],
                                  [94.775, -85.245],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.361, -21.991],
                                  [17.972, 74.121],
                                  [95.161, -95.668],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.969, -16.937],
                                  [20.285, 76.459],
                                  [95.611, -105.185],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-188.034, -12.297],
                                  [22.448, 78.599],
                                  [96.084, -113.93],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.434, -7.937],
                                  [24.505, 80.608],
                                  [96.561, -121.961],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.218, -3.914],
                                  [26.465, 82.474],
                                  [97.054, -129.345],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.268, -0.113],
                                  [28.325, 84.155],
                                  [97.567, -136.201],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.634, 3.411],
                                  [30.092, 85.819],
                                  [98.088, -142.583],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.255, 6.723],
                                  [31.759, 87.297],
                                  [98.631, -148.499],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.078, 9.873],
                                  [33.334, 88.701],
                                  [99.129, -154.016],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.094, 12.824],
                                  [34.87, 90.033],
                                  [99.646, -159.185],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.305, 15.562],
                                  [36.313, 91.298],
                                  [100.125, -164.002],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156.702, 18.205],
                                  [37.715, 92.49],
                                  [100.625, -168.545],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.227, 20.647],
                                  [39.032, 93.616],
                                  [101.144, -172.794],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.948, 23.007],
                                  [40.317, 94.676],
                                  [101.575, -176.764],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.786, 25.214],
                                  [41.505, 95.614],
                                  [102.083, -180.451],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.76, 27.283],
                                  [42.664, 96.59],
                                  [102.501, -183.972],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.8, 29.268],
                                  [43.744, 97.453],
                                  [102.944, -187.266],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.014, 31.17],
                                  [44.785, 98.297],
                                  [103.404, -190.397],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-142.306, 32.933],
                                  [45.8, 99.084],
                                  [103.786, -193.31],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.714, 34.613],
                                  [46.78, 99.805],
                                  [104.189, -196.108],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.184, 36.161],
                                  [47.684, 100.516],
                                  [104.622, -198.682],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.727, 37.68],
                                  [48.559, 101.224],
                                  [104.966, -201.149],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-136.385, 39.123],
                                  [49.349, 101.805],
                                  [105.345, -203.455],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.108, 40.442],
                                  [50.115, 102.445],
                                  [105.696, -205.601],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.897, 41.724],
                                  [50.913, 102.958],
                                  [106.019, -207.634],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.804, 42.945],
                                  [51.577, 103.523],
                                  [106.314, -209.561],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.713, 44.082],
                                  [52.27, 104.023],
                                  [106.643, -211.384],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.739, 45.143],
                                  [52.884, 104.467],
                                  [106.942, -213.094],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.773, 46.135],
                                  [53.527, 104.955],
                                  [107.22, -214.699],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.87, 47.106],
                                  [54.096, 105.331],
                                  [107.476, -216.2],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.075, 48.009],
                                  [54.585, 105.752],
                                  [107.764, -217.597],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-127.295, 48.835],
                                  [55.109, 106.116],
                                  [107.976, -218.935],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-126.568, 49.648],
                                  [55.615, 106.47],
                                  [108.221, -220.17],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-125.848, 50.385],
                                  [56.047, 106.768],
                                  [108.451, -221.355],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-125.242, 51.054],
                                  [56.46, 107.064],
                                  [108.651, -222.381],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-124.642, 51.709],
                                  [56.845, 107.35],
                                  [108.836, -223.412],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-124.101, 52.297],
                                  [57.219, 107.634],
                                  [108.999, -224.331],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.62, 52.872],
                                  [57.573, 107.854],
                                  [109.148, -225.2],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.136, 53.371],
                                  [57.853, 108.081],
                                  [109.329, -225.965],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-122.711, 53.857],
                                  [58.175, 108.289],
                                  [109.487, -226.681],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-122.299, 54.284],
                                  [58.416, 108.504],
                                  [109.585, -227.346],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.937, 54.69],
                                  [58.652, 108.654],
                                  [109.706, -227.962],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.634, 55.028],
                                  [58.915, 108.804],
                                  [109.822, -228.52],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.334, 55.354],
                                  [59.121, 108.943],
                                  [109.916, -229.028],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.039, 55.622],
                                  [59.243, 109.089],
                                  [109.996, -229.432],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.847, 55.869],
                                  [59.417, 109.226],
                                  [110.116, -229.839],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.614, 56.111],
                                  [59.571, 109.306],
                                  [110.168, -230.143],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.492, 56.287],
                                  [59.65, 109.378],
                                  [110.198, -230.451],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.311, 56.45],
                                  [59.781, 109.447],
                                  [110.277, -230.708],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.187, 56.548],
                                  [59.838, 109.516],
                                  [110.334, -230.852],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.13, 56.695],
                                  [59.937, 109.529],
                                  [110.332, -231.009],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.067, 56.722],
                                  [59.971, 109.595],
                                  [110.363, -231.107],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.007, 56.792],
                                  [59.985, 109.597],
                                  [110.389, -231.208],
                                ],
                                c: false,
                              },
                            ],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.651, 0.651, 0.651, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.651, 0.651, 0.651, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [71.467, 32.02],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.511, 32.077],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.551, 32.131],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.598, 32.25],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.709, 32.45],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.835, 32.687],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.97, 32.974],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.125, 33.268],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.364, 33.712],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.581, 34.172],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.89, 34.696],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.272, 35.381],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.68, 36.125],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.203, 36.987],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.793, 37.935],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.54, 39.119],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.405, 40.415],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.442, 41.874],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.735, 43.648],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.35, 45.672],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.38, 47.945],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.896, 50.544],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.134, 53.527],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.318, 56.748],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.658, 60.095],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.28, 63.18],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.105, 65.549],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.6, 66.831],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.183, 66.718],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.161, 65.362],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.046, 62.423],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.344, 59.662],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.835, 56.947],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.293, 54.353],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.56, 51.822],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.725, 49.361],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.648, 46.944],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.122, 44.614],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [171.747, 42.498],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.182, 40.521],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.417, 38.673],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.416, 37.005],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.383, 35.337],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.174, 33.831],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.918, 32.383],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.554, 30.971],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.179, 29.598],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.745, 28.265],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.257, 26.957],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.786, 25.687],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.247, 24.503],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.676, 23.254],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.168, 22.106],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.535, 20.957],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.973, 19.87],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.326, 18.85],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.701, 17.848],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.051, 16.863],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.4, 16.021],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.738, 15.145],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.988, 14.406],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.309, 13.633],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.606, 12.941],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.811, 12.325],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.125, 11.669],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.305, 11.149],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.538, 10.583],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.759, 10.09],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.964, 9.607],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.136, 9.198],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.301, 8.794],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.459, 8.403],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.619, 8.015],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.749, 7.695],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.921, 7.377],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.014, 7.073],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.149, 6.769],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.226, 6.527],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.31, 6.29],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.402, 6.056],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.546, 5.82],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.587, 5.598],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.671, 5.429],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.745, 5.266],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.757, 5.108],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.828, 4.943],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.862, 4.844],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.913, 4.687],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.93, 4.589],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.952, 4.491],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.945, 4.393],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.927, 4.301],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.858, 4.192],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.753, 4.096],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.635, 3.985],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.433, 3.871],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.217, 3.752],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.958, 3.615],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.608, 3.469],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.215, 3.307],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.773, 3.119],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.238, 2.905],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.627, 2.657],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.933, 2.357],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.159, 1.992],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.245, 1.544],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.309, 1.324],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.728, 2.361],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [169.255, 3.438],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.661, 4.574],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.779, 5.683],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.417, 6.694],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.32, 7.487],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.367, 7.864],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.66, 7.6],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.662, 6.48],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.306, 4.436],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.774, 1.655],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.944, -1.519],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.213, -4.734],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.49, -7.748],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.505, -10.428],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.092, -12.745],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.576, -14.74],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.152, -16.434],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.028, -17.868],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.326, -19.1],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.163, -20.142],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.614, -21.03],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-36.766, -21.798],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.576, -22.451],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.186, -23.019],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.59, -23.517],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.797, -23.947],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.86, -24.32],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.766, -24.64],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.551, -24.936],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.236, -25.185],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.76, -25.408],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.23, -25.594],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.618, -25.775],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.896, -25.931],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.113, -26.059],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.263, -26.17],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.316, -26.271],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.359, -26.374],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.305, -26.47],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.201, -26.534],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.04, -26.584],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.857, -26.664],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.595, -26.702],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.314, -26.747],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.972, -26.781],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.607, -26.823],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.186, -26.852],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.759, -26.885],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.276, -26.905],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.77, -26.933],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.251, -26.952],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.659, -26.963],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.069, -26.993],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.439, -26.999],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.783, -27.017],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.09, -27.014],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.401, -27.031],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.659, -27.031],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.921, -27.051],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.144, -27.053],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.347, -27.064],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.515, -27.054],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.695, -27.077],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.837, -27.082],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.968, -27.075],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.063, -27.08],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.146, -27.076],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.218, -27.094],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.286, -27.082],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.336, -27.078],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.419, -27.088],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.533, -27.02],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.637, -27.004],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.859, -26.956],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.116, -26.893],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.412, -26.82],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.758, -26.703],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.151, -26.602],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.649, -26.466],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.244, -26.286],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.881, -26.093],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.643, -25.892],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.495, -25.621],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.482, -25.321],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.581, -24.976],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.797, -24.586],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.24, -24.08],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.841, -23.518],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.562, -22.824],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.505, -21.962],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.595, -20.916],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.821, -19.6],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.988, -17.876],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.782, -15.589],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.635, -12.497],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.732, -8.26],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.04, -2.677],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.113, 4.412],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.455, 12.563],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.236, 21.139],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.503, 29.576],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.86, 37.604],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.725, 45.019],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.157, 51.882],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.248, 58.166],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.97, 63.929],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.724, 69.206],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.838, 74.017],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.451, 78.456],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.492, 82.394],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.919, 79.084],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.999, 76.055],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.775, 73.134],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.3, 70.385],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.647, 67.808],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.783, 65.413],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.786, 63.15],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.633, 61.012],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.358, 59.044],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.948, 57.138],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.447, 55.407],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.839, 53.713],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.158, 52.138],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.397, 50.693],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.581, 49.284],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.677, 48.005],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.714, 46.789],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.662, 45.584],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.563, 44.543],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.459, 43.518],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.233, 42.57],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.019, 41.671],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.72, 40.812],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.427, 40.06],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.062, 39.278],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.63, 38.595],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.196, 37.951],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.744, 37.365],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.231, 36.742],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.678, 36.25],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.101, 35.738],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.499, 35.313],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.872, 34.89],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.192, 34.495],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.529, 34.168],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.79, 33.845],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.047, 33.528],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.312, 33.289],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.528, 33.043],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.678, 32.821],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.864, 32.667],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.019, 32.502],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.107, 32.353],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.245, 32.226],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.315, 32.154],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.397, 32.088],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.438, 32.064],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.464, 31.984],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [71.467, 32.02],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.511, 32.077],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.551, 32.131],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.598, 32.25],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.709, 32.45],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.835, 32.687],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.97, 32.974],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.125, 33.268],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.364, 33.712],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.581, 34.172],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.89, 34.696],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.272, 35.381],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.68, 36.125],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.203, 36.987],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.793, 37.935],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.54, 39.119],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.405, 40.415],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.442, 41.874],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.735, 43.648],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.35, 45.672],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.38, 47.945],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.896, 50.544],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.134, 53.527],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.318, 56.748],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.658, 60.095],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.28, 63.18],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.105, 65.549],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.6, 66.831],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.183, 66.718],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.161, 65.362],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.046, 62.423],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.344, 59.662],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.835, 56.947],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.293, 54.353],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.56, 51.822],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.725, 49.361],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.648, 46.944],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.122, 44.614],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [171.747, 42.498],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.182, 40.521],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.417, 38.673],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.416, 37.005],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.383, 35.337],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.174, 33.831],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.918, 32.383],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.554, 30.971],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.179, 29.598],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.745, 28.265],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.257, 26.957],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.786, 25.687],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.247, 24.503],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.676, 23.254],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.168, 22.106],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.535, 20.957],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.973, 19.87],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.326, 18.85],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.701, 17.848],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.051, 16.863],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.4, 16.021],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.738, 15.145],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.988, 14.406],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.309, 13.633],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.606, 12.941],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.811, 12.325],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.125, 11.669],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.305, 11.149],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.538, 10.583],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.759, 10.09],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.964, 9.607],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.136, 9.198],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.301, 8.794],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.459, 8.403],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.619, 8.015],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.749, 7.695],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.921, 7.377],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.014, 7.073],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.149, 6.769],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.226, 6.527],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.31, 6.29],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.402, 6.056],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.546, 5.82],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.587, 5.598],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.671, 5.429],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.745, 5.266],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.757, 5.108],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.828, 4.943],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.862, 4.844],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.913, 4.687],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.93, 4.589],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.952, 4.491],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.945, 4.393],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.927, 4.301],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.858, 4.192],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.753, 4.096],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.635, 3.985],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.433, 3.871],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.217, 3.752],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.958, 3.615],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.608, 3.469],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.215, 3.307],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.773, 3.119],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.238, 2.905],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.627, 2.657],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.933, 2.357],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.159, 1.992],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.245, 1.544],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.309, 1.324],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.728, 2.361],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [169.255, 3.438],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.661, 4.574],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.779, 5.683],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.417, 6.694],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.32, 7.487],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.367, 7.864],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.66, 7.6],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.662, 6.48],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.306, 4.436],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.774, 1.655],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.944, -1.519],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.213, -4.734],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.49, -7.748],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.505, -10.428],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.092, -12.745],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.576, -14.74],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.152, -16.434],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.028, -17.868],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-26.326, -19.1],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.163, -20.142],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.614, -21.03],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-36.766, -21.798],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.576, -22.451],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.186, -23.019],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.59, -23.517],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.797, -23.947],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.86, -24.32],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.766, -24.64],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.551, -24.936],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.236, -25.185],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.76, -25.408],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.23, -25.594],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.618, -25.775],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.896, -25.931],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.113, -26.059],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.263, -26.17],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.316, -26.271],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.359, -26.374],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.305, -26.47],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.201, -26.534],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.04, -26.584],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.857, -26.664],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.595, -26.702],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.314, -26.747],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.972, -26.781],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.607, -26.823],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.186, -26.852],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.759, -26.885],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.276, -26.905],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.77, -26.933],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.251, -26.952],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.659, -26.963],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.069, -26.993],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.439, -26.999],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.783, -27.017],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.09, -27.014],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.401, -27.031],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.659, -27.031],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.921, -27.051],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.144, -27.053],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.347, -27.064],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.515, -27.054],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.695, -27.077],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.837, -27.082],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.968, -27.075],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.063, -27.08],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.146, -27.076],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.218, -27.094],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.286, -27.082],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.336, -27.078],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.419, -27.088],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.533, -27.02],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.637, -27.004],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.859, -26.956],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.116, -26.893],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.412, -26.82],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.758, -26.703],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.151, -26.602],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.649, -26.466],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.244, -26.286],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.881, -26.093],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.643, -25.892],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.495, -25.621],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.482, -25.321],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.581, -24.976],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.797, -24.586],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.24, -24.08],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.841, -23.518],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.562, -22.824],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.505, -21.962],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.595, -20.916],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.821, -19.6],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.988, -17.876],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.782, -15.589],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.635, -12.497],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.732, -8.26],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.04, -2.677],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.113, 4.412],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.455, 12.563],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.236, 21.139],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.503, 29.576],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.86, 37.604],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.725, 45.019],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.157, 51.882],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.248, 58.166],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.97, 63.929],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.724, 69.206],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.838, 74.017],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.451, 78.456],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.492, 82.394],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.919, 79.084],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.999, 76.055],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.775, 73.134],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.3, 70.385],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.647, 67.808],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.783, 65.413],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.786, 63.15],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.633, 61.012],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.358, 59.044],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.948, 57.138],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.447, 55.407],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.839, 53.713],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.158, 52.138],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.397, 50.693],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.581, 49.284],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.677, 48.005],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.714, 46.789],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.662, 45.584],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.563, 44.543],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.459, 43.518],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.233, 42.57],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.019, 41.671],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.72, 40.812],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.427, 40.06],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.062, 39.278],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.63, 38.595],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.196, 37.951],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.744, 37.365],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.231, 36.742],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.678, 36.25],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.101, 35.738],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.499, 35.313],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.872, 34.89],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.192, 34.495],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.529, 34.168],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.79, 33.845],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.047, 33.528],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.312, 33.289],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.528, 33.043],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.678, 32.821],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.864, 32.667],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.019, 32.502],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.107, 32.353],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.245, 32.226],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.315, 32.154],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.397, 32.088],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.438, 32.064],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.464, 31.984],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 3',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.005, 71.194],
                                  [71.995, 114.398],
                                  [129.579, -216.744],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-107.948, 71.262],
                                  [72.102, 114.358],
                                  [129.42, -216.613],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-107.833, 71.326],
                                  [72.217, 114.267],
                                  [129.218, -216.374],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-107.658, 71.423],
                                  [72.392, 114.174],
                                  [128.859, -216.003],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-107.488, 71.552],
                                  [72.662, 114.042],
                                  [128.469, -215.511],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-107.197, 71.714],
                                  [73.003, 113.871],
                                  [127.91, -214.837],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-106.911, 71.908],
                                  [73.389, 113.647],
                                  [127.244, -214.043],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-106.504, 72.173],
                                  [73.846, 113.384],
                                  [126.422, -213.105],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-106.039, 72.47],
                                  [74.461, 113.131],
                                  [125.43, -211.998],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-105.517, 72.837],
                                  [75.084, 112.776],
                                  [124.318, -210.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-104.875, 73.273],
                                  [75.876, 112.331],
                                  [123.012, -209.089],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-104.176, 73.743],
                                  [76.826, 111.857],
                                  [121.474, -207.301],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-103.295, 74.32],
                                  [77.858, 111.33],
                                  [119.73, -205.194],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-102.357, 74.967],
                                  [79.097, 110.674],
                                  [117.716, -202.82],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-101.175, 75.723],
                                  [80.48, 109.926],
                                  [115.434, -200.028],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-99.937, 76.626],
                                  [82.121, 109.086],
                                  [112.809, -196.857],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.393, 77.638],
                                  [83.967, 108.177],
                                  [109.816, -193.245],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.669, 78.836],
                                  [86.095, 107.036],
                                  [106.333, -189.054],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-94.701, 80.184],
                                  [88.567, 105.763],
                                  [102.385, -184.185],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-92.429, 81.796],
                                  [91.446, 104.317],
                                  [97.836, -178.602],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-89.727, 83.674],
                                  [94.805, 102.61],
                                  [92.578, -172.142],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-86.596, 85.86],
                                  [98.646, 100.639],
                                  [86.538, -164.607],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-83.036, 88.393],
                                  [103.119, 98.363],
                                  [79.558, -155.872],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-78.86, 91.354],
                                  [108.31, 95.73],
                                  [71.531, -145.79],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.131, 94.748],
                                  [114.209, 92.737],
                                  [62.435, -134.334],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.85, 98.502],
                                  [120.714, 89.43],
                                  [52.514, -121.733],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-63.329, 102.504],
                                  [127.613, 85.884],
                                  [41.976, -108.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-57.819, 106.525],
                                  [134.506, 82.375],
                                  [31.422, -95.045],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-52.446, 110.449],
                                  [141.164, 78.907],
                                  [21.243, -82.171],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.523, 114.082],
                                  [147.326, 75.629],
                                  [11.773, -70.165],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-43.051, 117.462],
                                  [152.941, 72.57],
                                  [3.159, -59.304],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.03, 120.509],
                                  [158.007, 69.803],
                                  [-4.654, -49.448],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.399, 123.26],
                                  [162.586, 67.216],
                                  [-11.739, -40.626],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-32.159, 125.794],
                                  [166.677, 64.859],
                                  [-18.146, -32.726],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-29.31, 128.108],
                                  [170.33, 62.641],
                                  [-23.939, -25.55],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.729, 130.242],
                                  [173.669, 60.573],
                                  [-29.216, -19.109],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.354, 132.195],
                                  [176.654, 58.603],
                                  [-33.989, -13.26],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.312, 134.005],
                                  [179.362, 56.719],
                                  [-38.447, -7.961],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.416, 135.672],
                                  [181.827, 54.942],
                                  [-42.488, -3.143],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-18.73, 137.278],
                                  [184.088, 53.208],
                                  [-46.261, 1.268],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.194, 138.739],
                                  [186.154, 51.527],
                                  [-49.691, 5.258],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-15.87, 140.138],
                                  [187.961, 49.949],
                                  [-52.917, 8.94],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.635, 141.474],
                                  [189.687, 48.307],
                                  [-55.896, 12.23],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.553, 142.748],
                                  [191.213, 46.766],
                                  [-58.682, 15.271],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.624, 143.96],
                                  [192.594, 45.23],
                                  [-61.274, 18.01],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.788, 145.109],
                                  [193.837, 43.69],
                                  [-63.694, 20.468],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.105, 146.237],
                                  [194.933, 42.135],
                                  [-65.932, 22.748],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-10.45, 147.302],
                                  [195.956, 40.596],
                                  [-68.007, 24.791],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-9.947, 148.345],
                                  [196.834, 39.047],
                                  [-69.962, 26.609],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-9.527, 149.367],
                                  [197.633, 37.513],
                                  [-71.755, 28.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-9.187, 150.364],
                                  [198.359, 36.045],
                                  [-73.494, 29.654],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.919, 151.29],
                                  [198.962, 34.5],
                                  [-75.08, 30.961],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.716, 152.232],
                                  [199.557, 33.043],
                                  [-76.506, 32.184],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.506, 153.094],
                                  [200.052, 31.586],
                                  [-77.892, 33.236],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.405, 153.967],
                                  [200.544, 30.194],
                                  [-79.184, 34.19],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.282, 154.799],
                                  [200.947, 28.87],
                                  [-80.385, 35.094],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.132, 155.587],
                                  [201.374, 27.569],
                                  [-81.504, 35.974],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.074, 156.377],
                                  [201.703, 26.303],
                                  [-82.538, 36.715],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.984, 157.07],
                                  [202.116, 25.187],
                                  [-83.549, 37.488],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.859, 157.81],
                                  [202.446, 24.056],
                                  [-84.422, 38.189],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.765, 158.448],
                                  [202.741, 23.069],
                                  [-85.284, 38.865],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.701, 159.083],
                                  [203.056, 22.064],
                                  [-86.066, 39.528],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.607, 159.665],
                                  [203.395, 21.152],
                                  [-86.832, 40.107],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.485, 160.242],
                                  [203.646, 20.325],
                                  [-87.534, 40.711],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.401, 160.764],
                                  [203.967, 19.473],
                                  [-88.166, 41.238],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.293, 161.282],
                                  [204.195, 18.764],
                                  [-88.784, 41.784],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.227, 161.746],
                                  [204.435, 18.026],
                                  [-89.339, 42.247],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.141, 162.206],
                                  [204.691, 17.363],
                                  [-89.882, 42.725],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.099, 162.61],
                                  [204.899, 16.723],
                                  [-90.362, 43.165],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.979, 163.011],
                                  [205.126, 16.162],
                                  [-90.832, 43.571],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.968, 163.408],
                                  [205.297, 15.619],
                                  [-91.296, 43.93],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.881, 163.751],
                                  [205.486, 15.095],
                                  [-91.697, 44.251],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.844, 164.09],
                                  [205.622, 14.582],
                                  [-92.032, 44.583],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.796, 164.374],
                                  [205.767, 14.141],
                                  [-92.361, 44.869],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.799, 164.708],
                                  [205.916, 13.715],
                                  [-92.686, 45.108],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.73, 164.934],
                                  [206.021, 13.301],
                                  [-92.949, 45.36],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.715, 165.21],
                                  [206.128, 12.9],
                                  [-93.208, 45.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.753, 165.431],
                                  [206.18, 12.567],
                                  [-93.457, 45.714],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.721, 165.65],
                                  [206.243, 12.24],
                                  [-93.65, 45.871],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.744, 165.813],
                                  [206.307, 11.923],
                                  [-93.838, 45.981],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.76, 166.028],
                                  [206.376, 11.616],
                                  [-93.965, 46.038],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.77, 166.187],
                                  [206.393, 11.316],
                                  [-94.141, 46.162],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.837, 166.344],
                                  [206.41, 11.083],
                                  [-94.259, 46.174],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.835, 166.446],
                                  [206.436, 10.857],
                                  [-94.316, 46.188],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.891, 166.546],
                                  [206.404, 10.639],
                                  [-94.432, 46.211],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.004, 166.645],
                                  [206.372, 10.423],
                                  [-94.481, 46.177],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.05, 166.742],
                                  [206.347, 10.275],
                                  [-94.531, 46.092],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.092, 166.837],
                                  [206.326, 10.071],
                                  [-94.577, 46.009],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.192, 166.877],
                                  [206.246, 9.931],
                                  [-94.562, 45.931],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.288, 166.915],
                                  [206.169, 9.797],
                                  [-94.542, 45.797],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.439, 166.89],
                                  [206.029, 9.663],
                                  [-94.466, 45.721],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.707, 166.738],
                                  [205.865, 9.534],
                                  [-94.398, 45.704],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.146, 166.464],
                                  [205.512, 9.397],
                                  [-94.157, 45.746],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.698, 166.001],
                                  [205.136, 9.264],
                                  [-93.92, 45.954],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-9.486, 165.412],
                                  [204.621, 9.128],
                                  [-93.572, 46.221],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-10.391, 164.635],
                                  [203.964, 8.987],
                                  [-93.111, 46.601],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.47, 163.672],
                                  [203.218, 8.846],
                                  [-92.596, 47.094],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.842, 162.517],
                                  [202.271, 8.697],
                                  [-91.914, 47.754],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.392, 161.12],
                                  [201.173, 8.543],
                                  [-91.119, 48.527],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.173, 159.471],
                                  [199.921, 8.385],
                                  [-90.215, 49.413],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-18.308, 157.567],
                                  [198.457, 8.219],
                                  [-89.144, 50.521],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.742, 155.3],
                                  [196.772, 8.044],
                                  [-87.901, 51.85],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.526, 152.715],
                                  [194.859, 7.86],
                                  [-86.493, 53.348],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.726, 149.753],
                                  [192.656, 7.664],
                                  [-84.859, 55.123],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-30.397, 146.358],
                                  [190.155, 7.456],
                                  [-83.002, 57.175],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-34.599, 142.407],
                                  [187.286, 7.231],
                                  [-80.867, 59.504],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.398, 137.897],
                                  [183.983, 6.986],
                                  [-78.391, 62.221],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-44.967, 132.698],
                                  [180.231, 6.721],
                                  [-75.582, 65.379],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-51.304, 126.694],
                                  [175.89, 6.428],
                                  [-72.318, 69.034],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.65, 119.814],
                                  [170.89, 6.104],
                                  [-68.553, 73.242],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-67.126, 111.761],
                                  [165.088, 5.74],
                                  [-64.167, 78.166],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-76.967, 102.461],
                                  [158.395, 5.333],
                                  [-59.107, 83.863],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-88.412, 91.672],
                                  [150.668, 4.876],
                                  [-53.258, 90.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-101.52, 79.261],
                                  [141.766, 4.362],
                                  [-46.509, 98.071],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-116.35, 65.236],
                                  [131.686, 3.791],
                                  [-38.861, 106.748],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.729, 49.721],
                                  [120.583, 3.17],
                                  [-30.42, 116.311],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.061, 33.275],
                                  [108.833, 2.52],
                                  [-21.469, 126.432],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.519, 16.765],
                                  [97.017, 1.872],
                                  [-12.454, 136.673],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.324, 0.872],
                                  [85.656, 1.252],
                                  [-3.774, 146.485],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.006, -13.964],
                                  [75.107, 0.678],
                                  [4.296, 155.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.322, -27.558],
                                  [65.461, 0.154],
                                  [11.692, 164.047],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.278, -39.847],
                                  [56.713, -0.321],
                                  [18.422, 171.686],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-239.047, -50.899],
                                  [48.85, -0.747],
                                  [24.48, 178.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.686, -60.952],
                                  [41.731, -1.132],
                                  [29.975, 184.783],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-259.375, -70.079],
                                  [35.28, -1.481],
                                  [34.967, 190.458],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-268.176, -78.394],
                                  [29.364, -1.802],
                                  [39.567, 195.643],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-276.264, -86.029],
                                  [24.022, -2.09],
                                  [43.718, 200.39],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-283.695, -93.04],
                                  [19.062, -2.359],
                                  [47.59, 204.754],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.534, -99.434],
                                  [14.539, -2.603],
                                  [51.125, 208.735],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-296.895, -105.394],
                                  [10.324, -2.83],
                                  [54.432, 212.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.781, -110.917],
                                  [6.463, -3.038],
                                  [57.461, 215.932],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-308.252, -116.066],
                                  [2.835, -3.234],
                                  [60.321, 219.147],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-313.361, -120.847],
                                  [-0.506, -3.413],
                                  [62.953, 222.143],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-318.114, -125.316],
                                  [-3.625, -3.581],
                                  [65.415, 224.919],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-322.571, -129.532],
                                  [-6.586, -3.74],
                                  [67.767, 227.531],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-326.788, -133.443],
                                  [-9.33, -3.888],
                                  [69.949, 230.032],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-330.703, -137.107],
                                  [-11.868, -4.023],
                                  [71.959, 232.314],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-334.444, -140.581],
                                  [-14.315, -4.154],
                                  [73.916, 234.486],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.882, -143.867],
                                  [-16.556, -4.274],
                                  [75.699, 236.547],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-341.202, -146.911],
                                  [-18.71, -4.389],
                                  [77.427, 238.499],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.281, -149.823],
                                  [-20.724, -4.497],
                                  [79.04, 240.285],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-347.183, -152.555],
                                  [-22.596, -4.597],
                                  [80.542, 242.016],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-349.962, -155.1],
                                  [-24.391, -4.692],
                                  [81.984, 243.636],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-352.563, -157.519],
                                  [-26.103, -4.784],
                                  [83.374, 245.145],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-354.987, -159.818],
                                  [-27.683, -4.868],
                                  [84.645, 246.6],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.346, -161.988],
                                  [-29.187, -4.948],
                                  [85.86, 247.943],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-359.525, -164.036],
                                  [-30.556, -5.02],
                                  [86.961, 249.231],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-361.584, -165.96],
                                  [-31.908, -5.092],
                                  [88.06, 250.464],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-363.525, -167.759],
                                  [-33.191, -5.161],
                                  [89.107, 251.641],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-365.405, -169.496],
                                  [-34.339, -5.221],
                                  [90.031, 252.707],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.104, -171.108],
                                  [-35.478, -5.282],
                                  [90.962, 253.717],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-368.801, -172.661],
                                  [-36.544, -5.338],
                                  [91.834, 254.727],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-370.321, -174.089],
                                  [-37.541, -5.391],
                                  [92.649, 255.626],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.777, -175.451],
                                  [-38.47, -5.44],
                                  [93.404, 256.47],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.173, -176.696],
                                  [-39.326, -5.485],
                                  [94.103, 257.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-374.45, -177.933],
                                  [-40.176, -5.53],
                                  [94.801, 258.044],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.667, -179.053],
                                  [-40.955, -5.571],
                                  [95.443, 258.775],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-376.82, -180.106],
                                  [-41.668, -5.609],
                                  [96.025, 259.451],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-377.913, -181.098],
                                  [-42.375, -5.646],
                                  [96.61, 260.07],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-378.887, -182.03],
                                  [-43.009, -5.68],
                                  [97.135, 260.69],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-379.802, -182.897],
                                  [-43.583, -5.71],
                                  [97.601, 261.198],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-380.715, -183.704],
                                  [-44.146, -5.74],
                                  [98.069, 261.76],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-381.505, -184.449],
                                  [-44.645, -5.766],
                                  [98.477, 262.212],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-382.236, -185.132],
                                  [-45.138, -5.792],
                                  [98.888, 262.663],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-382.965, -185.753],
                                  [-45.565, -5.815],
                                  [99.236, 263.058],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-383.576, -186.373],
                                  [-45.983, -5.837],
                                  [99.59, 263.453],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-384.185, -186.931],
                                  [-46.34, -5.856],
                                  [99.88, 263.792],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-384.676, -187.427],
                                  [-46.69, -5.874],
                                  [100.174, 264.075],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-385.162, -187.86],
                                  [-46.975, -5.889],
                                  [100.407, 264.357],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-385.588, -188.234],
                                  [-47.258, -5.904],
                                  [100.644, 264.639],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.013, -188.605],
                                  [-47.472, -5.915],
                                  [100.817, 264.865],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.319, -188.917],
                                  [-47.687, -5.926],
                                  [100.993, 265.036],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.624, -189.166],
                                  [-47.892, -5.937],
                                  [101.168, 265.205],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.869, -189.412],
                                  [-48.036, -5.945],
                                  [101.287, 265.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.055, -189.6],
                                  [-48.178, -5.952],
                                  [101.406, 265.488],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.236, -189.783],
                                  [-48.254, -5.956],
                                  [101.464, 265.601],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.36, -189.908],
                                  [-48.383, -5.963],
                                  [101.581, 265.658],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.48, -189.97],
                                  [-48.391, -5.964],
                                  [101.579, 265.714],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.541, -190.033],
                                  [-48.456, -5.967],
                                  [101.639, 265.77],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.476, -189.971],
                                  [-48.458, -5.967],
                                  [101.642, 265.708],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.342, -189.899],
                                  [-48.398, -5.899],
                                  [101.585, 265.4],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-387.072, -189.641],
                                  [-48.393, -5.772],
                                  [101.59, 264.908],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.673, -189.373],
                                  [-48.271, -5.64],
                                  [101.538, 264.231],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-386.201, -188.978],
                                  [-48.203, -5.444],
                                  [101.433, 263.307],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-385.534, -188.452],
                                  [-48.073, -5.184],
                                  [101.331, 262.076],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-384.73, -187.857],
                                  [-47.882, -4.861],
                                  [101.232, 260.599],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-383.731, -187.065],
                                  [-47.691, -4.469],
                                  [101.079, 258.814],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-382.59, -186.146],
                                  [-47.494, -4.073],
                                  [100.93, 256.72],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-381.25, -185.088],
                                  [-47.23, -3.549],
                                  [100.728, 254.26],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-379.649, -183.833],
                                  [-46.906, -2.897],
                                  [100.476, 251.371],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-377.78, -182.38],
                                  [-46.512, -2.181],
                                  [100.235, 247.991],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-375.701, -180.721],
                                  [-46.111, -1.391],
                                  [99.947, 244.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.232, -178.798],
                                  [-45.577, -0.414],
                                  [99.614, 239.705],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-370.427, -176.605],
                                  [-44.976, 0.696],
                                  [99.239, 234.559],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.219, -174.074],
                                  [-44.293, 1.944],
                                  [98.83, 228.744],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-363.546, -171.147],
                                  [-43.528, 3.328],
                                  [98.331, 222.076],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-359.337, -167.8],
                                  [-42.611, 5.032],
                                  [97.811, 214.382],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-354.466, -163.916],
                                  [-41.536, 6.937],
                                  [97.226, 205.486],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-348.803, -159.41],
                                  [-40.29, 9.116],
                                  [96.588, 195.328],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-342.279, -154.15],
                                  [-38.788, 11.69],
                                  [95.864, 183.556],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-334.752, -148.049],
                                  [-36.946, 14.667],
                                  [95.147, 169.945],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-326.041, -140.955],
                                  [-34.791, 18.115],
                                  [94.354, 154.214],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-316.017, -132.717],
                                  [-32.214, 22.108],
                                  [93.544, 136.213],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-304.625, -123.302],
                                  [-29.163, 26.654],
                                  [92.775, 115.924],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-292.125, -112.817],
                                  [-25.59, 31.69],
                                  [92.159, 93.692],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.985, -101.585],
                                  [-21.597, 37.086],
                                  [91.768, 70.338],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.758, -90.134],
                                  [-17.282, 42.462],
                                  [91.624, 47.068],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-253.033, -78.957],
                                  [-12.881, 47.731],
                                  [91.831, 24.837],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.206, -68.437],
                                  [-8.532, 52.681],
                                  [92.338, 4.369],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-230.474, -58.672],
                                  [-4.302, 57.252],
                                  [93.021, -14.186],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.815, -49.697],
                                  [-0.296, 61.37],
                                  [93.942, -30.772],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.114, -41.515],
                                  [3.556, 65.147],
                                  [94.941, -45.696],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.235, -34.023],
                                  [7.195, 68.541],
                                  [95.998, -58.993],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.146, -27.143],
                                  [10.654, 71.661],
                                  [97.159, -71.025],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.723, -20.776],
                                  [13.939, 74.515],
                                  [98.358, -81.879],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.846, -14.959],
                                  [17.036, 77.155],
                                  [99.547, -91.735],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.489, -9.51],
                                  [19.999, 79.591],
                                  [100.759, -100.708],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.593, -4.497],
                                  [22.791, 81.822],
                                  [101.964, -108.934],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.039, 0.22],
                                  [25.448, 83.921],
                                  [103.14, -116.469],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.87, 4.585],
                                  [27.983, 85.869],
                                  [104.3, -123.382],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.969, 8.71],
                                  [30.395, 87.631],
                                  [105.455, -129.788],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.383, 12.542],
                                  [32.692, 89.367],
                                  [106.585, -135.734],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.052, 16.151],
                                  [34.867, 90.92],
                                  [107.713, -141.236],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.927, 19.577],
                                  [36.932, 92.393],
                                  [108.77, -146.359],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.994, 22.799],
                                  [38.933, 93.789],
                                  [109.822, -151.151],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.258, 25.794],
                                  [40.824, 95.115],
                                  [110.815, -155.602],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-143.704, 28.677],
                                  [42.651, 96.364],
                                  [111.806, -159.8],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.278, 31.353],
                                  [44.379, 97.544],
                                  [112.791, -163.715],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.049, 33.936],
                                  [46.058, 98.658],
                                  [113.675, -167.369],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-136.931, 36.354],
                                  [47.62, 99.652],
                                  [114.611, -170.754],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.954, 38.626],
                                  [49.138, 100.671],
                                  [115.441, -173.986],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.042, 40.805],
                                  [50.568, 101.582],
                                  [116.278, -177.003],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-131.296, 42.89],
                                  [51.937, 102.467],
                                  [117.109, -179.868],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.634, 44.83],
                                  [53.267, 103.297],
                                  [117.852, -182.532],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.082, 46.676],
                                  [54.543, 104.059],
                                  [118.597, -185.086],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-126.589, 48.388],
                                  [55.736, 104.807],
                                  [119.354, -187.429],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-125.176, 50.056],
                                  [56.885, 105.55],
                                  [120.008, -189.674],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.87, 51.642],
                                  [57.937, 106.165],
                                  [120.685, -191.772],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-122.629, 53.105],
                                  [58.954, 106.839],
                                  [121.32, -193.723],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.454, 54.513],
                                  [59.986, 107.384],
                                  [121.913, -195.566],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.394, 55.862],
                                  [60.88, 107.977],
                                  [122.464, -197.314],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.335, 57.116],
                                  [61.787, 108.503],
                                  [123.035, -198.967],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-118.389, 58.288],
                                  [62.606, 108.976],
                                  [123.562, -200.513],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-117.455, 59.388],
                                  [63.436, 109.484],
                                  [124.057, -201.963],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-116.581, 60.457],
                                  [64.189, 109.889],
                                  [124.519, -203.32],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-115.807, 61.455],
                                  [64.852, 110.328],
                                  [125, -204.582],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-115.055, 62.371],
                                  [65.537, 110.715],
                                  [125.398, -205.788],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-114.35, 63.267],
                                  [66.194, 111.086],
                                  [125.814, -206.9],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-113.653, 64.081],
                                  [66.772, 111.404],
                                  [126.209, -207.968],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-113.066, 64.825],
                                  [67.322, 111.72],
                                  [126.559, -208.891],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-112.487, 65.549],
                                  [67.83, 112.021],
                                  [126.888, -209.822],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-111.965, 66.204],
                                  [68.322, 112.319],
                                  [127.185, -210.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-111.501, 66.839],
                                  [68.784, 112.552],
                                  [127.46, -211.426],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-111.032, 67.393],
                                  [69.167, 112.796],
                                  [127.753, -212.112],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-110.619, 67.927],
                                  [69.583, 113.012],
                                  [128.012, -212.755],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-110.225, 68.405],
                                  [69.907, 113.238],
                                  [128.214, -213.353],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-109.875, 68.852],
                                  [70.227, 113.4],
                                  [128.42, -213.908],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-109.581, 69.229],
                                  [70.554, 113.559],
                                  [128.618, -214.406],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-109.292, 69.589],
                                  [70.827, 113.704],
                                  [128.783, -214.86],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-109.009, 69.892],
                                  [71.008, 113.859],
                                  [128.928, -215.22],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.819, 70.164],
                                  [71.234, 114],
                                  [129.103, -215.586],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.596, 70.43],
                                  [71.43, 114.089],
                                  [129.208, -215.857],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.479, 70.629],
                                  [71.547, 114.164],
                                  [129.28, -216.134],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.303, 70.809],
                                  [71.71, 114.236],
                                  [129.395, -216.366],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.182, 70.921],
                                  [71.793, 114.307],
                                  [129.479, -216.491],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.128, 71.079],
                                  [71.909, 114.326],
                                  [129.505, -216.634],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.066, 71.118],
                                  [71.959, 114.394],
                                  [129.549, -216.719],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.008, 71.19],
                                  [71.979, 114.397],
                                  [129.587, -216.81],
                                ],
                                c: false,
                              },
                            ],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.617, 0.617, 0.617, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.617, 0.617, 0.617, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [84.928, 40.024],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.963, 40.092],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.993, 40.16],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.026, 40.298],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.112, 40.52],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.21, 40.791],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.31, 41.115],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.428, 41.464],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.617, 41.965],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.782, 42.5],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.027, 43.113],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.335, 43.882],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.669, 44.725],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.101, 45.701],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.599, 46.799],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.235, 48.126],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.993, 49.579],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.908, 51.249],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.072, 53.211],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.549, 55.464],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.444, 57.99],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.829, 60.841],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.94, 64.065],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.014, 67.517],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.258, 71.023],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.78, 74.155],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.444, 76.424],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.656, 77.471],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.781, 77.049],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.169, 75.46],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.221, 72.735],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.161, 70.021],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.225, 67.318],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.223, 64.619],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.854, 61.971],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.977, 59.515],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.634, 57.231],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.989, 55.123],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.084, 53.196],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.007, 51.386],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.798, 49.692],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.401, 48.152],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.986, 46.588],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.465, 45.159],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.879, 43.76],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.258, 42.375],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.619, 40.997],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.953, 39.637],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.255, 38.274],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.592, 36.925],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.886, 35.634],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.166, 34.27],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.522, 32.987],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.778, 31.694],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.111, 30.457],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.367, 29.281],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.657, 28.125],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.931, 26.991],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.215, 26.001],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.494, 24.989],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.696, 24.113],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.972, 23.217],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.23, 22.404],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.404, 21.672],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.686, 20.91],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.843, 20.285],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.053, 19.624],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.255, 19.036],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.443, 18.466],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.602, 17.97],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.755, 17.485],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.904, 17.016],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.054, 16.554],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.18, 16.16],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.345, 15.772],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.436, 15.401],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.566, 15.034],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.643, 14.729],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.727, 14.429],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.82, 14.135],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.96, 13.841],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.005, 13.564],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.09, 13.338],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.168, 13.121],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.185, 12.911],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.258, 12.694],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.297, 12.543],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.352, 12.337],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.375, 12.19],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.402, 12.043],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.398, 11.898],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.379, 11.761],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.31, 11.604],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.196, 11.465],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.066, 11.311],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.846, 11.154],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.577, 10.998],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.255, 10.823],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.871, 10.636],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.407, 10.438],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.873, 10.217],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.26, 9.969],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.535, 9.691],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.728, 9.362],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.808, 8.975],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.73, 8.509],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.491, 7.932],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.096, 7.211],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.428, 6.673],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.541, 7.763],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.339, 8.821],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.602, 9.787],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.078, 10.536],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.623, 10.871],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.363, 10.559],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.726, 9.392],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.674, 7.291],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.442, 4.438],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.949, 1.182],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.628, -2.115],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.398, -5.201],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.99, -7.937],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.953, -10.294],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.728, -12.311],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.576, -14.019],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.663, -15.452],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.15, -16.674],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-32.173, -17.704],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-35.771, -18.571],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.061, -19.317],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.009, -19.948],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.733, -20.487],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.244, -20.957],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.559, -21.362],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.715, -21.706],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.709, -21.999],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.578, -22.268],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.34, -22.49],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.94, -22.687],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.479, -22.849],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.934, -23.007],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.269, -23.137],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.542, -23.241],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.747, -23.332],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.857, -23.413],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.951, -23.498],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.946, -23.577],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.882, -23.622],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.75, -23.648],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.606, -23.713],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.385, -23.737],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.141, -23.769],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.836, -23.79],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.504, -23.82],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.116, -23.84],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.719, -23.861],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.259, -23.87],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.771, -23.885],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.267, -23.891],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.692, -23.89],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.116, -23.909],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.506, -23.908],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.868, -23.919],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.193, -23.91],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.52, -23.923],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.793, -23.918],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.069, -23.934],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.305, -23.931],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.519, -23.938],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.698, -23.925],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.885, -23.946],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.036, -23.948],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.173, -23.94],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.275, -23.943],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.364, -23.937],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.44, -23.953],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.511, -23.94],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.563, -23.935],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.64, -23.945],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.741, -23.877],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.828, -23.86],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.027, -23.811],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.254, -23.747],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.522, -23.679],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.846, -23.572],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.206, -23.477],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.643, -23.339],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.167, -23.159],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.753, -22.983],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.425, -22.782],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.172, -22.515],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.054, -22.227],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.012, -21.886],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.07, -21.502],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.324, -21.004],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.693, -20.448],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.134, -19.751],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.731, -18.88],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.39, -17.812],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.079, -16.451],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.574, -14.639],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.529, -12.196],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.358, -8.847],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.285, -4.23],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.431, 1.826],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.553, 9.394],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.266, 17.943],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.665, 26.826],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.699, 35.48],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.887, 43.662],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.606, 51.174],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.898, 58.085],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.833, 64.378],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.606, 70.115],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.451, 75.326],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.726, 80.045],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.855, 82.871],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.128, 79.987],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.997, 77.197],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.519, 74.663],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.733, 72.223],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.688, 69.939],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.454, 67.804],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.996, 65.842],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.391, 63.99],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.619, 62.259],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.71, 60.681],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.655, 59.145],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.494, 57.771],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.221, 56.419],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.853, 55.177],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.398, 54.035],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.866, 52.946],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.245, 51.938],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.547, 51.006],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.751, 50.073],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.899, 49.275],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.025, 48.489],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.022, 47.784],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.019, 47.099],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.921, 46.449],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.815, 45.889],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.63, 45.299],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.369, 44.791],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.095, 44.317],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.793, 43.887],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.425, 43.418],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.007, 43.065],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.553, 42.684],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.068, 42.382],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.548, 42.076],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.971, 41.786],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.401, 41.549],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.748, 41.313],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.084, 41.082],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.415, 40.915],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.697, 40.74],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.906, 40.578],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.143, 40.475],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.341, 40.358],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.467, 40.248],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.636, 40.154],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.732, 40.106],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.833, 40.064],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.889, 40.054],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.922, 39.984],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [84.928, 40.024],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.963, 40.092],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.993, 40.16],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.026, 40.298],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.112, 40.52],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.21, 40.791],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.31, 41.115],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.428, 41.464],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.617, 41.965],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.782, 42.5],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.027, 43.113],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.335, 43.882],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.669, 44.725],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.101, 45.701],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.599, 46.799],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.235, 48.126],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.993, 49.579],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.908, 51.249],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.072, 53.211],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.549, 55.464],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.444, 57.99],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.829, 60.841],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.94, 64.065],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.014, 67.517],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.258, 71.023],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.78, 74.155],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.444, 76.424],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.656, 77.471],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.781, 77.049],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.169, 75.46],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.221, 72.735],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.161, 70.021],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.225, 67.318],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.223, 64.619],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.854, 61.971],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.977, 59.515],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.634, 57.231],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.989, 55.123],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.084, 53.196],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.007, 51.386],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.798, 49.692],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.401, 48.152],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.986, 46.588],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.465, 45.159],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.879, 43.76],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.258, 42.375],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.619, 40.997],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.953, 39.637],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.255, 38.274],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.592, 36.925],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.886, 35.634],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.166, 34.27],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.522, 32.987],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.778, 31.694],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.111, 30.457],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.367, 29.281],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.657, 28.125],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.931, 26.991],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.215, 26.001],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.494, 24.989],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.696, 24.113],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.972, 23.217],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.23, 22.404],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.404, 21.672],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.686, 20.91],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.843, 20.285],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.053, 19.624],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.255, 19.036],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.443, 18.466],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.602, 17.97],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.755, 17.485],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.904, 17.016],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.054, 16.554],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.18, 16.16],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.345, 15.772],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.436, 15.401],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.566, 15.034],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.643, 14.729],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.727, 14.429],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.82, 14.135],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.96, 13.841],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.005, 13.564],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.09, 13.338],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.168, 13.121],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.185, 12.911],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.258, 12.694],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.297, 12.543],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.352, 12.337],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.375, 12.19],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.402, 12.043],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.398, 11.898],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.379, 11.761],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.31, 11.604],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.196, 11.465],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.066, 11.311],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.846, 11.154],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.577, 10.998],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.255, 10.823],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.871, 10.636],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.407, 10.438],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.873, 10.217],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.26, 9.969],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.535, 9.691],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.728, 9.362],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.808, 8.975],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.73, 8.509],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.491, 7.932],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.096, 7.211],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.428, 6.673],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.541, 7.763],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.339, 8.821],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.602, 9.787],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.078, 10.536],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.623, 10.871],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.363, 10.559],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.726, 9.392],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.674, 7.291],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.442, 4.438],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.949, 1.182],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.628, -2.115],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.398, -5.201],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.99, -7.937],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.953, -10.294],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.728, -12.311],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.576, -14.019],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.663, -15.452],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.15, -16.674],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-32.173, -17.704],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-35.771, -18.571],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.061, -19.317],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.009, -19.948],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.733, -20.487],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.244, -20.957],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.559, -21.362],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.715, -21.706],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.709, -21.999],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.578, -22.268],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.34, -22.49],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.94, -22.687],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.479, -22.849],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.934, -23.007],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.269, -23.137],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.542, -23.241],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.747, -23.332],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.857, -23.413],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.951, -23.498],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.946, -23.577],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.882, -23.622],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.75, -23.648],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.606, -23.713],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.385, -23.737],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.141, -23.769],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.836, -23.79],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.504, -23.82],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.116, -23.84],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.719, -23.861],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.259, -23.87],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.771, -23.885],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.267, -23.891],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.692, -23.89],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.116, -23.909],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.506, -23.908],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.868, -23.919],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.193, -23.91],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.52, -23.923],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.793, -23.918],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.069, -23.934],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.305, -23.931],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.519, -23.938],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.698, -23.925],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.885, -23.946],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.036, -23.948],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.173, -23.94],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.275, -23.943],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.364, -23.937],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.44, -23.953],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.511, -23.94],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.563, -23.935],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.64, -23.945],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.741, -23.877],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.828, -23.86],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.027, -23.811],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.254, -23.747],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.522, -23.679],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.846, -23.572],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.206, -23.477],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.643, -23.339],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.167, -23.159],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.753, -22.983],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.425, -22.782],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.172, -22.515],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.054, -22.227],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.012, -21.886],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.07, -21.502],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.324, -21.004],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.693, -20.448],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.134, -19.751],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.731, -18.88],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.39, -17.812],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.079, -16.451],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.574, -14.639],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.529, -12.196],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.358, -8.847],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.285, -4.23],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.431, 1.826],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.553, 9.394],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.266, 17.943],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.665, 26.826],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.699, 35.48],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.887, 43.662],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.606, 51.174],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-17.898, 58.085],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.833, 64.378],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.606, 70.115],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.451, 75.326],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.726, 80.045],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.855, 82.871],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.128, 79.987],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.997, 77.197],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.519, 74.663],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.733, 72.223],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.688, 69.939],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.454, 67.804],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.996, 65.842],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.391, 63.99],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.619, 62.259],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.71, 60.681],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.655, 59.145],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.494, 57.771],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.221, 56.419],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.853, 55.177],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.398, 54.035],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.866, 52.946],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.245, 51.938],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.547, 51.006],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.751, 50.073],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.899, 49.275],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [72.025, 48.489],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.022, 47.784],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.019, 47.099],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.921, 46.449],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.815, 45.889],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.63, 45.299],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.369, 44.791],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.095, 44.317],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.793, 43.887],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.425, 43.418],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.007, 43.065],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.553, 42.684],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.068, 42.382],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.548, 42.076],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.971, 41.786],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.401, 41.549],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.748, 41.313],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.084, 41.082],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.415, 40.915],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.697, 40.74],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.906, 40.578],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.143, 40.475],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.341, 40.358],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.467, 40.248],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.636, 40.154],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.732, 40.106],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.833, 40.064],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.889, 40.054],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.922, 39.984],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 4',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.006, 85.593],
                                  [83.994, 119.198],
                                  [148.774, -202.344],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-95.95, 85.643],
                                  [84.096, 119.166],
                                  [148.605, -202.211],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-95.836, 85.688],
                                  [84.209, 119.086],
                                  [148.379, -201.974],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-95.665, 85.756],
                                  [84.381, 119.005],
                                  [147.986, -201.602],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-95.499, 85.848],
                                  [84.639, 118.892],
                                  [147.552, -201.111],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-95.213, 85.962],
                                  [84.971, 118.748],
                                  [146.936, -200.439],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-94.932, 86.1],
                                  [85.343, 118.555],
                                  [146.198, -199.648],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-94.532, 86.29],
                                  [85.789, 118.331],
                                  [145.294, -198.705],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-94.075, 86.503],
                                  [86.384, 118.121],
                                  [144.207, -197.599],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-93.562, 86.768],
                                  [86.989, 117.816],
                                  [142.982, -196.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-92.93, 87.085],
                                  [87.759, 117.432],
                                  [141.54, -194.688],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-92.242, 87.425],
                                  [88.677, 117.032],
                                  [139.851, -192.901],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-91.374, 87.847],
                                  [89.684, 116.583],
                                  [137.93, -190.793],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-90.45, 88.322],
                                  [90.885, 116.024],
                                  [135.714, -188.411],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-89.283, 88.879],
                                  [92.236, 115.385],
                                  [133.202, -185.617],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-88.062, 89.549],
                                  [93.827, 114.665],
                                  [130.317, -182.442],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-86.536, 90.302],
                                  [95.631, 113.896],
                                  [127.026, -178.823],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-84.831, 91.2],
                                  [97.707, 112.922],
                                  [123.205, -174.619],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-82.885, 92.213],
                                  [100.117, 111.835],
                                  [118.87, -169.739],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-80.636, 93.433],
                                  [102.924, 110.603],
                                  [113.88, -164.135],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-77.959, 94.862],
                                  [106.206, 109.148],
                                  [108.11, -157.653],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.854, 96.533],
                                  [109.964, 107.468],
                                  [101.484, -150.089],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-71.323, 98.479],
                                  [114.335, 105.529],
                                  [93.833, -141.321],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-67.178, 100.765],
                                  [119.416, 103.283],
                                  [85.034, -131.192],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.482, 103.396],
                                  [125.19, 100.725],
                                  [75.079, -119.689],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-57.236, 106.317],
                                  [131.565, 97.898],
                                  [64.208, -107.031],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-51.753, 109.441],
                                  [138.32, 94.859],
                                  [52.68, -93.674],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-46.284, 112.589],
                                  [145.067, 91.851],
                                  [41.126, -80.23],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-40.955, 115.668],
                                  [151.586, 88.858],
                                  [29.99, -67.308],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.078, 118.525],
                                  [157.611, 86.017],
                                  [19.63, -55.27],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-31.655, 121.19],
                                  [163.094, 83.344],
                                  [10.202, -44.383],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.687, 123.597],
                                  [168.034, 80.918],
                                  [1.656, -34.521],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.113, 125.776],
                                  [172.493, 78.628],
                                  [-6.088, -25.703],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.932, 127.79],
                                  [176.469, 76.522],
                                  [-13.095, -17.82],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-18.148, 129.635],
                                  [180.008, 74.52],
                                  [-19.428, -10.671],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-15.636, 131.344],
                                  [183.234, 72.637],
                                  [-25.199, -4.272],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.334, 132.914],
                                  [186.112, 70.824],
                                  [-30.423, 1.529],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.371, 134.377],
                                  [188.708, 69.066],
                                  [-35.29, 6.764],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-9.559, 135.733],
                                  [191.064, 67.39],
                                  [-39.706, 11.507],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.963, 137.046],
                                  [193.211, 65.733],
                                  [-43.827, 15.834],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.522, 138.25],
                                  [195.162, 64.107],
                                  [-47.575, 19.728],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-5.3, 139.411],
                                  [196.854, 62.559],
                                  [-51.093, 23.297],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-4.175, 140.53],
                                  [198.457, 60.928],
                                  [-54.345, 26.47],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-3.21, 141.606],
                                  [199.861, 59.371],
                                  [-57.382, 29.377],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-2.407, 142.64],
                                  [201.112, 57.803],
                                  [-60.203, 31.967],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.704, 143.631],
                                  [202.223, 56.211],
                                  [-62.837, 34.267],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.163, 144.615],
                                  [203.181, 54.58],
                                  [-65.269, 36.374],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.658, 145.554],
                                  [204.061, 52.952],
                                  [-67.527, 38.228],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.312, 146.485],
                                  [204.792, 51.291],
                                  [-69.648, 39.846],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.058, 147.407],
                                  [205.439, 49.628],
                                  [-71.594, 41.245],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.111, 148.317],
                                  [206.007, 48.012],
                                  [-73.468, 42.461],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.203, 149.174],
                                  [206.453, 46.314],
                                  [-75.181, 43.542],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.228, 150.051],
                                  [206.886, 44.689],
                                  [-76.724, 44.529],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.26, 150.866],
                                  [207.225, 43.06],
                                  [-78.211, 45.346],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.184, 151.694],
                                  [207.559, 41.494],
                                  [-79.595, 46.071],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.135, 152.49],
                                  [207.812, 39.993],
                                  [-80.879, 46.747],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.119, 153.25],
                                  [208.095, 38.518],
                                  [-82.075, 47.404],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.017, 154.012],
                                  [208.286, 37.086],
                                  [-83.175, 47.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.044, 154.688],
                                  [208.564, 35.807],
                                  [-84.246, 48.505],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.063, 155.406],
                                  [208.772, 34.524],
                                  [-85.175, 49.015],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.103, 156.033],
                                  [208.952, 33.388],
                                  [-86.087, 49.509],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.166, 156.656],
                                  [209.156, 32.246],
                                  [-86.913, 50.003],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.192, 157.229],
                                  [209.392, 31.204],
                                  [-87.718, 50.424],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.183, 157.797],
                                  [209.549, 30.25],
                                  [-88.457, 50.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.204, 158.315],
                                  [209.778, 29.282],
                                  [-89.123, 51.262],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.195, 158.828],
                                  [209.923, 28.46],
                                  [-89.77, 51.673],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.222, 159.289],
                                  [210.082, 27.618],
                                  [-90.353, 52.013],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.224, 159.746],
                                  [210.262, 26.855],
                                  [-90.92, 52.37],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.265, 160.152],
                                  [210.399, 26.12],
                                  [-91.423, 52.697],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.223, 160.553],
                                  [210.561, 25.467],
                                  [-91.911, 52.998],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.287, 160.949],
                                  [210.668, 24.839],
                                  [-92.39, 53.256],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.271, 161.295],
                                  [210.797, 24.233],
                                  [-92.808, 53.483],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.3, 161.636],
                                  [210.877, 23.641],
                                  [-93.156, 53.724],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.315, 161.926],
                                  [210.97, 23.124],
                                  [-93.498, 53.924],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.379, 162.26],
                                  [211.066, 22.625],
                                  [-93.832, 54.08],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.368, 162.495],
                                  [211.124, 22.143],
                                  [-94.105, 54.254],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.407, 162.775],
                                  [211.186, 21.676],
                                  [-94.371, 54.382],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.497, 163.003],
                                  [211.193, 21.28],
                                  [-94.624, 54.463],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.515, 163.228],
                                  [211.216, 20.89],
                                  [-94.822, 54.551],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.586, 163.401],
                                  [211.239, 20.512],
                                  [-95.014, 54.596],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.648, 163.621],
                                  [211.27, 20.147],
                                  [-95.145, 54.591],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.702, 163.789],
                                  [211.252, 19.793],
                                  [-95.318, 54.653],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.811, 163.954],
                                  [211.233, 19.505],
                                  [-95.437, 54.608],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.849, 164.068],
                                  [211.227, 19.227],
                                  [-95.495, 54.567],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.944, 164.18],
                                  [211.164, 18.959],
                                  [-95.609, 54.535],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.094, 164.289],
                                  [211.1, 18.692],
                                  [-95.655, 54.449],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.176, 164.396],
                                  [211.047, 18.497],
                                  [-95.702, 54.316],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.252, 164.501],
                                  [210.998, 18.247],
                                  [-95.743, 54.184],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.386, 164.554],
                                  [210.892, 18.06],
                                  [-95.723, 54.06],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.514, 164.605],
                                  [210.789, 17.881],
                                  [-95.697, 53.881],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.693, 164.592],
                                  [210.621, 17.702],
                                  [-95.616, 53.758],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.987, 164.452],
                                  [210.42, 17.529],
                                  [-95.549, 53.691],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-2.445, 164.192],
                                  [210.028, 17.344],
                                  [-95.314, 53.681],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-3.011, 163.742],
                                  [209.603, 17.165],
                                  [-95.085, 53.827],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-3.806, 163.166],
                                  [209.032, 16.979],
                                  [-94.751, 54.03],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-4.716, 162.4],
                                  [208.31, 16.787],
                                  [-94.31, 54.339],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-5.793, 161.452],
                                  [207.486, 16.594],
                                  [-93.818, 54.755],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.155, 160.309],
                                  [206.455, 16.388],
                                  [-93.17, 55.327],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.69, 158.926],
                                  [205.261, 16.175],
                                  [-92.414, 56.007],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-10.449, 157.292],
                                  [203.899, 15.956],
                                  [-91.556, 56.794],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.551, 155.401],
                                  [202.312, 15.723],
                                  [-90.541, 57.788],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.947, 153.151],
                                  [200.489, 15.477],
                                  [-89.36, 58.992],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.68, 150.581],
                                  [198.418, 15.219],
                                  [-88.027, 60.353],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.821, 147.636],
                                  [196.038, 14.941],
                                  [-86.478, 61.974],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.419, 144.257],
                                  [193.337, 14.645],
                                  [-84.718, 63.855],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-28.533, 140.327],
                                  [190.242, 14.325],
                                  [-82.697, 65.997],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-33.231, 135.838],
                                  [186.685, 13.974],
                                  [-80.351, 68.501],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-38.678, 130.661],
                                  [182.644, 13.593],
                                  [-77.692, 71.42],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-44.875, 124.682],
                                  [177.971, 13.171],
                                  [-74.599, 74.804],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-52.054, 117.826],
                                  [172.594, 12.702],
                                  [-71.033, 78.706],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.336, 109.805],
                                  [166.359, 12.175],
                                  [-66.88, 83.279],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.949, 100.539],
                                  [159.166, 11.584],
                                  [-62.088, 88.575],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-81.126, 89.788],
                                  [150.863, 10.919],
                                  [-56.547, 94.699],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-93.925, 77.417],
                                  [141.304, 10.169],
                                  [-50.154, 101.803],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.404, 63.436],
                                  [130.48, 9.334],
                                  [-42.909, 109.891],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-124.395, 47.971],
                                  [118.563, 8.427],
                                  [-34.912, 118.811],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.317, 31.579],
                                  [105.957, 7.476],
                                  [-26.43, 128.255],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.365, 15.122],
                                  [93.283, 6.526],
                                  [-17.884, 137.815],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.775, -0.722],
                                  [81.099, 5.617],
                                  [-9.654, 146.979],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.092, -15.511],
                                  [69.785, 4.776],
                                  [-1.999, 155.538],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.077, -29.062],
                                  [59.446, 4.008],
                                  [5.019, 163.391],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.739, -41.312],
                                  [50.074, 3.311],
                                  [11.408, 170.533],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-228.242, -52.331],
                                  [41.65, 2.686],
                                  [17.163, 176.964],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-238.643, -62.35],
                                  [34.025, 2.121],
                                  [22.385, 182.786],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-248.117, -71.449],
                                  [27.117, 1.608],
                                  [27.13, 188.098],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-256.727, -79.737],
                                  [20.791, 1.138],
                                  [31.506, 192.953],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.642, -87.348],
                                  [15.074, 0.714],
                                  [35.455, 197.4],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-271.913, -94.336],
                                  [9.771, 0.321],
                                  [39.143, 201.49],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.609, -100.709],
                                  [4.935, -0.038],
                                  [42.511, 205.223],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.838, -106.652],
                                  [0.432, -0.372],
                                  [45.662, 208.701],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.603, -112.157],
                                  [-3.696, -0.677],
                                  [48.551, 211.974],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.964, -117.288],
                                  [-7.569, -0.964],
                                  [51.279, 214.992],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.97, -122.055],
                                  [-11.137, -1.228],
                                  [53.79, 217.805],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-305.627, -126.509],
                                  [-14.468, -1.475],
                                  [56.141, 220.412],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-309.998, -130.709],
                                  [-17.625, -1.709],
                                  [58.387, 222.866],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.134, -134.608],
                                  [-20.55, -1.925],
                                  [60.473, 225.217],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-317.972, -138.262],
                                  [-23.261, -2.125],
                                  [62.392, 227.363],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-321.643, -141.724],
                                  [-25.867, -2.318],
                                  [64.265, 229.405],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.016, -144.998],
                                  [-28.258, -2.494],
                                  [65.97, 231.345],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.274, -148.034],
                                  [-30.55, -2.664],
                                  [67.623, 233.181],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-331.296, -150.934],
                                  [-32.695, -2.822],
                                  [69.167, 234.862],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-334.145, -153.658],
                                  [-34.69, -2.969],
                                  [70.606, 236.492],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-336.873, -156.194],
                                  [-36.602, -3.11],
                                  [71.986, 238.018],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-339.428, -158.606],
                                  [-38.419, -3.244],
                                  [73.32, 239.44],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-341.81, -160.898],
                                  [-40.102, -3.368],
                                  [74.538, 240.811],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.128, -163.06],
                                  [-41.703, -3.486],
                                  [75.703, 242.077],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-346.268, -165.102],
                                  [-43.163, -3.592],
                                  [76.758, 243.292],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-348.291, -167.02],
                                  [-44.598, -3.698],
                                  [77.812, 244.454],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-350.199, -168.813],
                                  [-45.961, -3.799],
                                  [78.818, 245.564],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-352.048, -170.544],
                                  [-47.184, -3.888],
                                  [79.703, 246.57],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-353.718, -172.149],
                                  [-48.392, -3.977],
                                  [80.598, 247.524],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-355.387, -173.698],
                                  [-49.524, -4.061],
                                  [81.435, 248.477],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.882, -175.122],
                                  [-50.583, -4.138],
                                  [82.218, 249.326],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-358.314, -176.478],
                                  [-51.571, -4.211],
                                  [82.943, 250.122],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-359.687, -177.72],
                                  [-52.482, -4.277],
                                  [83.615, 250.918],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-360.944, -178.952],
                                  [-53.383, -4.344],
                                  [84.286, 251.609],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-362.142, -180.069],
                                  [-54.208, -4.404],
                                  [84.904, 252.3],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-363.275, -181.119],
                                  [-54.966, -4.46],
                                  [85.463, 252.939],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-364.351, -182.106],
                                  [-55.715, -4.515],
                                  [86.027, 253.524],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-365.31, -183.036],
                                  [-56.387, -4.565],
                                  [86.532, 254.109],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-366.211, -183.899],
                                  [-56.998, -4.609],
                                  [86.979, 254.59],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.11, -184.704],
                                  [-57.594, -4.653],
                                  [87.43, 255.122],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.888, -185.447],
                                  [-58.125, -4.691],
                                  [87.822, 255.549],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-368.607, -186.128],
                                  [-58.646, -4.73],
                                  [88.219, 255.976],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.324, -186.746],
                                  [-59.1, -4.763],
                                  [88.552, 256.35],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.926, -187.365],
                                  [-59.541, -4.796],
                                  [88.895, 256.723],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-370.527, -187.921],
                                  [-59.92, -4.823],
                                  [89.174, 257.044],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.011, -188.414],
                                  [-60.29, -4.851],
                                  [89.457, 257.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.489, -188.845],
                                  [-60.593, -4.873],
                                  [89.682, 257.579],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.909, -189.219],
                                  [-60.893, -4.895],
                                  [89.911, 257.846],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-372.327, -189.588],
                                  [-61.121, -4.911],
                                  [90.076, 258.06],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-372.629, -189.9],
                                  [-61.35, -4.928],
                                  [90.246, 258.221],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-372.93, -190.149],
                                  [-61.565, -4.944],
                                  [90.414, 258.382],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.172, -190.394],
                                  [-61.719, -4.955],
                                  [90.53, 258.542],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.356, -190.581],
                                  [-61.868, -4.966],
                                  [90.644, 258.649],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.533, -190.763],
                                  [-61.952, -4.972],
                                  [90.701, 258.756],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.657, -190.887],
                                  [-62.083, -4.983],
                                  [90.813, 258.81],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.774, -190.95],
                                  [-62.095, -4.983],
                                  [90.81, 258.864],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.833, -191.012],
                                  [-62.161, -4.988],
                                  [90.869, 258.917],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.768, -190.95],
                                  [-62.164, -4.988],
                                  [90.873, 258.855],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.632, -190.874],
                                  [-62.102, -4.918],
                                  [90.821, 258.551],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-373.357, -190.611],
                                  [-62.091, -4.79],
                                  [90.832, 258.064],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-372.952, -190.334],
                                  [-61.964, -4.655],
                                  [90.791, 257.396],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-372.472, -189.929],
                                  [-61.886, -4.455],
                                  [90.7, 256.482],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-371.796, -189.389],
                                  [-61.743, -4.192],
                                  [90.618, 255.265],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-370.978, -188.778],
                                  [-61.538, -3.864],
                                  [90.541, 253.805],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-369.965, -187.966],
                                  [-61.329, -3.466],
                                  [90.417, 252.041],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-368.805, -187.024],
                                  [-61.109, -3.06],
                                  [90.301, 249.971],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-367.444, -185.937],
                                  [-60.817, -2.526],
                                  [90.139, 247.541],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-365.82, -184.648],
                                  [-60.462, -1.864],
                                  [89.936, 244.688],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-363.923, -183.156],
                                  [-60.031, -1.136],
                                  [89.751, 241.349],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-361.812, -181.45],
                                  [-59.585, -0.33],
                                  [89.53, 237.533],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-359.309, -179.472],
                                  [-58.996, 0.663],
                                  [89.276, 233.17],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.463, -177.215],
                                  [-58.333, 1.792],
                                  [88.993, 228.095],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-353.21, -174.609],
                                  [-57.573, 3.065],
                                  [88.695, 222.362],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-349.487, -171.596],
                                  [-56.714, 4.477],
                                  [88.328, 215.789],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-345.221, -168.143],
                                  [-55.684, 6.212],
                                  [87.964, 208.209],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-340.287, -164.138],
                                  [-54.47, 8.153],
                                  [87.569, 199.455],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-334.557, -159.483],
                                  [-53.055, 10.378],
                                  [87.162, 189.465],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-327.957, -154.048],
                                  [-51.341, 13.004],
                                  [86.722, 177.9],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-320.348, -147.731],
                                  [-49.233, 16.044],
                                  [86.355, 164.543],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-311.55, -140.375],
                                  [-46.742, 19.567],
                                  [85.998, 149.127],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-301.438, -131.817],
                                  [-43.74, 23.651],
                                  [85.73, 131.518],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-289.964, -122.015],
                                  [-40.155, 28.305],
                                  [85.633, 111.713],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.389, -111.072],
                                  [-35.928, 33.466],
                                  [85.827, 90.064],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.189, -99.32],
                                  [-31.163, 39.002],
                                  [86.378, 67.386],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.929, -87.301],
                                  [-25.987, 44.528],
                                  [87.278, 44.858],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-238.197, -75.534],
                                  [-20.667, 49.951],
                                  [88.587, 23.413],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-226.387, -64.427],
                                  [-15.378, 55.048],
                                  [90.205, 3.736],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.695, -54.091],
                                  [-10.216, 59.764],
                                  [91.985, -14.037],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.089, -44.571],
                                  [-5.297, 64.019],
                                  [93.968, -29.873],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.456, -35.868],
                                  [-0.571, 67.921],
                                  [95.985, -44.074],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.659, -27.887],
                                  [3.909, 71.437],
                                  [98.014, -56.686],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.653, -20.542],
                                  [8.169, 74.67],
                                  [100.096, -68.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-176.316, -13.741],
                                  [12.219, 77.63],
                                  [102.168, -78.299],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.532, -7.512],
                                  [16.044, 80.368],
                                  [104.193, -87.569],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.266, -1.673],
                                  [19.697, 82.896],
                                  [106.19, -95.983],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.461, 3.705],
                                  [23.151, 85.212],
                                  [108.148, -103.681],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-156, 8.769],
                                  [26.436, 87.394],
                                  [110.034, -110.712],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.921, 13.466],
                                  [29.569, 89.416],
                                  [111.872, -117.151],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.112, 17.904],
                                  [32.551, 91.253],
                                  [113.674, -123.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.612, 22.033],
                                  [35.395, 93.056],
                                  [115.416, -128.616],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.363, 25.925],
                                  [38.089, 94.676],
                                  [117.129, -133.705],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-138.323, 29.615],
                                  [40.653, 96.212],
                                  [118.743, -138.439],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.472, 33.095],
                                  [43.128, 97.665],
                                  [120.327, -142.857],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.817, 36.334],
                                  [45.471, 99.048],
                                  [121.827, -146.949],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.34, 39.444],
                                  [47.726, 100.347],
                                  [123.301, -150.809],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-127.988, 42.341],
                                  [49.868, 101.577],
                                  [124.745, -154.396],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-125.833, 45.135],
                                  [51.94, 102.737],
                                  [126.073, -157.742],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.782, 47.75],
                                  [53.876, 103.782],
                                  [127.427, -160.835],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.874, 50.213],
                                  [55.751, 104.839],
                                  [128.659, -163.786],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.029, 52.573],
                                  [57.528, 105.794],
                                  [129.879, -166.534],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-118.341, 54.828],
                                  [59.222, 106.715],
                                  [131.069, -169.144],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-116.742, 56.933],
                                  [60.862, 107.582],
                                  [132.161, -171.568],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-115.247, 58.933],
                                  [62.43, 108.382],
                                  [133.236, -173.887],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-113.808, 60.797],
                                  [63.908, 109.162],
                                  [134.305, -176.008],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-112.452, 62.604],
                                  [65.326, 109.936],
                                  [135.259, -178.041],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-111.195, 64.321],
                                  [66.635, 110.582],
                                  [136.221, -179.94],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-110.003, 65.918],
                                  [67.897, 111.285],
                                  [137.129, -181.705],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.877, 67.442],
                                  [69.158, 111.859],
                                  [137.981, -183.367],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-107.86, 68.908],
                                  [70.277, 112.475],
                                  [138.776, -184.944],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-106.844, 70.27],
                                  [71.392, 113.024],
                                  [139.577, -186.435],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-105.935, 71.543],
                                  [72.409, 113.523],
                                  [140.321, -187.825],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-105.041, 72.743],
                                  [73.422, 114.049],
                                  [141.023, -189.13],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-104.206, 73.902],
                                  [74.352, 114.478],
                                  [141.683, -190.349],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-103.461, 74.988],
                                  [75.184, 114.934],
                                  [142.346, -191.484],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-102.743, 75.985],
                                  [76.024, 115.34],
                                  [142.921, -192.564],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-102.067, 76.957],
                                  [76.828, 115.726],
                                  [143.499, -193.56],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-101.402, 77.841],
                                  [77.547, 116.062],
                                  [144.051, -194.518],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-100.84, 78.653],
                                  [78.229, 116.395],
                                  [144.543, -195.345],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-100.287, 79.44],
                                  [78.856, 116.709],
                                  [145.008, -196.181],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-99.789, 80.156],
                                  [79.461, 117.019],
                                  [145.43, -196.915],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-99.347, 80.847],
                                  [80.028, 117.264],
                                  [145.825, -197.612],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.897, 81.45],
                                  [80.51, 117.523],
                                  [146.223, -198.224],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.501, 82.028],
                                  [81.016, 117.745],
                                  [146.578, -198.798],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.129, 82.553],
                                  [81.421, 117.982],
                                  [146.877, -199.334],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-97.794, 83.036],
                                  [81.819, 118.153],
                                  [147.162, -199.831],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-97.512, 83.449],
                                  [82.209, 118.321],
                                  [147.438, -200.273],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-97.237, 83.838],
                                  [82.546, 118.471],
                                  [147.67, -200.677],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.969, 84.174],
                                  [82.782, 118.634],
                                  [147.877, -200.996],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.782, 84.469],
                                  [83.059, 118.778],
                                  [148.103, -201.322],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.573, 84.757],
                                  [83.296, 118.875],
                                  [148.258, -201.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.461, 84.976],
                                  [83.449, 118.952],
                                  [148.37, -201.811],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.292, 85.172],
                                  [83.642, 119.027],
                                  [148.519, -202.02],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.175, 85.298],
                                  [83.751, 119.099],
                                  [148.627, -202.127],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.125, 85.464],
                                  [83.883, 119.124],
                                  [148.679, -202.257],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.065, 85.515],
                                  [83.948, 119.192],
                                  [148.737, -202.331],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.584, 0.584, 0.584, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.584, 0.584, 0.584, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [98.302, 48.181],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.329, 48.259],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.349, 48.34],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.369, 48.496],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.43, 48.744],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.501, 49.051],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.566, 49.416],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.648, 49.822],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.791, 50.375],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.906, 50.971],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.092, 51.659],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.331, 52.507],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.593, 53.453],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.936, 54.557],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.349, 55.776],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.88, 57.238],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.533, 58.867],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.337, 60.708],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.377, 62.878],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.728, 65.351],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.499, 68.114],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.77, 71.219],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.774, 74.686],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.762, 78.365],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.942, 82.037],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.392, 85.203],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.923, 87.38],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.859, 88.196],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.512, 87.478],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.232, 85.629],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.786, 83.087],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.146, 80.301],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.495, 77.461],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.982, 74.787],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.874, 72.327],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.291, 70.075],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.32, 68.01],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.096, 66.114],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.678, 64.391],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.134, 62.77],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.493, 61.243],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.688, 59.841],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.925, 58.402],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.054, 57.061],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.174, 55.733],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.265, 54.393],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.359, 53.032],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.448, 51.671],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.54, 50.279],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.667, 48.879],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.774, 47.511],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.881, 46.063],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.078, 44.674],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.197, 43.268],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.399, 41.911],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.543, 40.608],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.729, 39.326],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.908, 38.068],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.111, 36.954],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.313, 35.829],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.452, 34.836],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.665, 33.835],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.87, 32.92],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.998, 32.088],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.233, 31.234],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.353, 30.518],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.526, 29.775],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.697, 29.105],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.855, 28.457],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.992, 27.885],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.12, 27.328],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.249, 26.791],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.381, 26.263],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.495, 25.802],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.646, 25.351],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.73, 24.92],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.852, 24.495],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.924, 24.13],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.005, 23.773],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.095, 23.422],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.232, 23.074],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.279, 22.747],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.364, 22.466],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.445, 22.198],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.465, 21.939],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.543, 21.671],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.587, 21.47],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.648, 21.217],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.679, 21.02],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.715, 20.826],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.718, 20.634],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.69, 20.452],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.602, 20.251],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.462, 20.07],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.296, 19.874],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.045, 19.674],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.736, 19.475],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.369, 19.258],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.899, 19.034],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.34, 18.797],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.735, 18.535],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.993, 18.254],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.148, 17.94],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.183, 17.581],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.091, 17.167],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.814, 16.678],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.35, 16.084],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.7, 15.358],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.744, 14.446],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.495, 13.27],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.003, 12.759],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [153.803, 13.667],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.776, 14.349],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.743, 14.618],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.793, 14.246],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.381, 13.014],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.507, 10.834],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.427, 7.888],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.133, 4.528],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.09, 1.131],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.24, -2.042],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.31, -4.844],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.069, -7.245],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.209, -9.284],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.371, -10.997],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.739, -12.422],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.469, -13.624],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.711, -14.627],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-38.504, -15.46],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-41.975, -16.171],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.085, -16.759],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.966, -17.258],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.619, -17.688],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.058, -18.047],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.345, -18.351],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.46, -18.606],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.419, -18.828],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.29, -19.014],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.999, -19.177],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.626, -19.302],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.154, -19.418],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.569, -19.514],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.93, -19.591],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.215, -19.655],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.387, -19.702],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.537, -19.755],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.589, -19.805],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.586, -19.825],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.521, -19.832],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.434, -19.878],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.256, -19.875],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.055, -19.885],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.791, -19.885],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.498, -19.894],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.146, -19.896],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.784, -19.9],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.363, -19.896],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.914, -19.901],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.44, -19.893],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.893, -19.878],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.343, -19.884],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.756, -19.871],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.14, -19.872],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.485, -19.852],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.829, -19.856],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.121, -19.842],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.413, -19.851],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.664, -19.841],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.89, -19.842],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.082, -19.823],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.279, -19.841],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.44, -19.837],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.584, -19.826],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.694, -19.826],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.79, -19.817],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.87, -19.83],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.944, -19.816],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.999, -19.809],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.069, -19.821],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.157, -19.754],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.228, -19.738],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.402, -19.693],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.598, -19.635],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.828, -19.574],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.11, -19.475],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.405, -19.379],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.778, -19.251],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.23, -19.084],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.715, -18.916],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.281, -18.726],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.91, -18.48],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.631, -18.201],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.414, -17.875],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.275, -17.511],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.272, -17.026],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.337, -16.482],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.423, -15.792],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.592, -14.928],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.73, -13.856],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.75, -12.449],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.443, -10.558],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.393, -7.943],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.053, -4.32],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.681, 0.704],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.594, 7.251],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.734, 15.3],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.804, 24.232],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.82, 33.387],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.594, 42.226],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.603, 50.508],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.152, 58.065],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.276, 64.968],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.051, 71.199],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.551, 76.839],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.575, 81.925],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.429, 82.372],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.612, 79.866],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.306, 77.527],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.601, 75.267],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.55, 73.235],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.18, 71.297],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.546, 69.491],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.705, 67.825],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.633, 66.309],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.397, 64.884],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.99, 63.553],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.427, 62.374],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.707, 61.219],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.864, 60.209],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.905, 59.205],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.831, 58.304],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.658, 57.485],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.396, 56.693],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.034, 55.991],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.583, 55.345],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.026, 54.674],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.403, 54.128],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.739, 53.602],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.945, 53.129],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.136, 52.663],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.223, 52.226],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.289, 51.86],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.27, 51.466],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.168, 51.133],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.04, 50.831],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.875, 50.559],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.64, 50.246],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.345, 50.033],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.005, 49.784],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.627, 49.607],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.205, 49.417],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.722, 49.236],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.235, 49.096],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.661, 48.948],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.071, 48.799],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.462, 48.704],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.805, 48.595],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.067, 48.492],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.352, 48.437],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.589, 48.369],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.749, 48.297],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.947, 48.237],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.067, 48.213],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.184, 48.194],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.254, 48.197],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [98.302, 48.181],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.329, 48.259],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.349, 48.34],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.369, 48.496],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.43, 48.744],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.501, 49.051],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.566, 49.416],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.648, 49.822],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.791, 50.375],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.906, 50.971],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.092, 51.659],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.331, 52.507],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.593, 53.453],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.936, 54.557],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.349, 55.776],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.88, 57.238],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.533, 58.867],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.337, 60.708],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.377, 62.878],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.728, 65.351],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.499, 68.114],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.77, 71.219],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.774, 74.686],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.762, 78.365],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.942, 82.037],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.392, 85.203],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.923, 87.38],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.859, 88.196],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.512, 87.478],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.232, 85.629],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.786, 83.087],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.146, 80.301],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.495, 77.461],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.982, 74.787],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.874, 72.327],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.291, 70.075],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.32, 68.01],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.096, 66.114],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.678, 64.391],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.134, 62.77],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.493, 61.243],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.688, 59.841],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.925, 58.402],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.054, 57.061],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.174, 55.733],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.265, 54.393],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.359, 53.032],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.448, 51.671],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.54, 50.279],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.667, 48.879],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.774, 47.511],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.881, 46.063],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.078, 44.674],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.197, 43.268],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.399, 41.911],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.543, 40.608],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.729, 39.326],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.908, 38.068],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.111, 36.954],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.313, 35.829],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.452, 34.836],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.665, 33.835],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.87, 32.92],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.998, 32.088],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.233, 31.234],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.353, 30.518],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.526, 29.775],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.697, 29.105],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.855, 28.457],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.992, 27.885],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.12, 27.328],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.249, 26.791],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.381, 26.263],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.495, 25.802],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.646, 25.351],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.73, 24.92],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.852, 24.495],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.924, 24.13],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.005, 23.773],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.095, 23.422],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.232, 23.074],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.279, 22.747],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.364, 22.466],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.445, 22.198],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.465, 21.939],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.543, 21.671],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.587, 21.47],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.648, 21.217],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.679, 21.02],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.715, 20.826],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.718, 20.634],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.69, 20.452],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.602, 20.251],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.462, 20.07],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.296, 19.874],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.045, 19.674],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.736, 19.475],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.369, 19.258],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.899, 19.034],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.34, 18.797],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.735, 18.535],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.993, 18.254],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.148, 17.94],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.183, 17.581],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.091, 17.167],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.814, 16.678],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.35, 16.084],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.7, 15.358],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.744, 14.446],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.495, 13.27],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.003, 12.759],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [153.803, 13.667],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.776, 14.349],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.743, 14.618],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.793, 14.246],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.381, 13.014],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.507, 10.834],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.427, 7.888],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.133, 4.528],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.09, 1.131],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.24, -2.042],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.31, -4.844],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.069, -7.245],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.209, -9.284],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.371, -10.997],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.739, -12.422],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.469, -13.624],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.711, -14.627],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-38.504, -15.46],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-41.975, -16.171],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.085, -16.759],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.966, -17.258],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.619, -17.688],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.058, -18.047],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-55.345, -18.351],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.46, -18.606],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-59.419, -18.828],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.29, -19.014],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.999, -19.177],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.626, -19.302],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.154, -19.418],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.569, -19.514],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.93, -19.591],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.215, -19.655],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.387, -19.702],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.537, -19.755],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.589, -19.805],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.586, -19.825],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.521, -19.832],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.434, -19.878],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.256, -19.875],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.055, -19.885],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.791, -19.885],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.498, -19.894],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.146, -19.896],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.784, -19.9],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.363, -19.896],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.914, -19.901],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.44, -19.893],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.893, -19.878],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.343, -19.884],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.756, -19.871],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.14, -19.872],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.485, -19.852],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.829, -19.856],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.121, -19.842],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.413, -19.851],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.664, -19.841],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.89, -19.842],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.082, -19.823],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.279, -19.841],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.44, -19.837],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.584, -19.826],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.694, -19.826],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.79, -19.817],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.87, -19.83],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.944, -19.816],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.999, -19.809],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.069, -19.821],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.157, -19.754],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.228, -19.738],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.402, -19.693],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.598, -19.635],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.828, -19.574],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.11, -19.475],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.405, -19.379],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.778, -19.251],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.23, -19.084],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.715, -18.916],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.281, -18.726],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.91, -18.48],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.631, -18.201],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.414, -17.875],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.275, -17.511],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.272, -17.026],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.337, -16.482],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.423, -15.792],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.592, -14.928],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.73, -13.856],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.75, -12.449],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.443, -10.558],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.393, -7.943],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.053, -4.32],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.681, 0.704],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.594, 7.251],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.734, 15.3],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-69.804, 24.232],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.82, 33.387],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.594, 42.226],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.603, 50.508],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.152, 58.065],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-11.276, 64.968],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.051, 71.199],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.551, 76.839],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.575, 81.925],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.429, 82.372],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.612, 79.866],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.306, 77.527],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.601, 75.267],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.55, 73.235],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.18, 71.297],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.546, 69.491],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.705, 67.825],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.633, 66.309],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.397, 64.884],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.99, 63.553],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.427, 62.374],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.707, 61.219],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.864, 60.209],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.905, 59.205],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.831, 58.304],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.658, 57.485],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [75.396, 56.693],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.034, 55.991],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.583, 55.345],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.026, 54.674],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.403, 54.128],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.739, 53.602],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.945, 53.129],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.136, 52.663],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.223, 52.226],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.289, 51.86],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.27, 51.466],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.168, 51.133],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.04, 50.831],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.875, 50.559],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.64, 50.246],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.345, 50.033],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.005, 49.784],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.627, 49.607],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.205, 49.417],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.722, 49.236],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.235, 49.096],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.661, 48.948],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.071, 48.799],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.462, 48.704],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.805, 48.595],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.067, 48.492],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.352, 48.437],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.589, 48.369],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.749, 48.297],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.947, 48.237],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.067, 48.213],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.184, 48.194],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.254, 48.197],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 5',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 5,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-84.006, 99.993],
                                  [95.994, 123.998],
                                  [167.969, -187.945],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-83.951, 100.025],
                                  [96.09, 123.974],
                                  [167.791, -187.808],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-83.839, 100.051],
                                  [96.203, 123.906],
                                  [167.541, -187.574],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-83.67, 100.092],
                                  [96.372, 123.836],
                                  [167.116, -187.2],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-83.507, 100.147],
                                  [96.618, 123.743],
                                  [166.64, -186.708],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-83.224, 100.215],
                                  [96.943, 123.626],
                                  [165.968, -186.036],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-82.948, 100.298],
                                  [97.302, 123.465],
                                  [165.161, -185.246],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-82.553, 100.415],
                                  [97.739, 123.281],
                                  [164.178, -184.297],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-82.102, 100.546],
                                  [98.316, 123.114],
                                  [162.998, -183.19],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-81.595, 100.712],
                                  [98.906, 122.86],
                                  [161.662, -181.841],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-80.971, 100.912],
                                  [99.656, 122.54],
                                  [160.088, -180.271],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-80.292, 101.126],
                                  [100.544, 122.215],
                                  [158.254, -178.482],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-79.433, 101.396],
                                  [101.53, 121.844],
                                  [156.161, -176.369],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-78.519, 101.702],
                                  [102.695, 121.384],
                                  [153.746, -173.975],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-77.364, 102.064],
                                  [104.019, 120.856],
                                  [151.01, -171.174],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-76.155, 102.505],
                                  [105.564, 120.258],
                                  [147.87, -167.989],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.643, 103.004],
                                  [107.33, 119.632],
                                  [144.287, -164.357],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.953, 103.606],
                                  [109.358, 118.828],
                                  [140.135, -160.135],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-71.023, 104.289],
                                  [111.711, 117.931],
                                  [135.419, -155.237],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.792, 105.121],
                                  [114.451, 116.916],
                                  [129.993, -149.605],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-66.133, 106.105],
                                  [117.662, 115.718],
                                  [123.717, -143.093],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-63.049, 107.264],
                                  [121.342, 114.333],
                                  [116.511, -135.493],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.54, 108.626],
                                  [125.619, 112.738],
                                  [108.194, -126.682],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-55.418, 110.238],
                                  [130.596, 110.886],
                                  [98.627, -116.496],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-50.747, 112.107],
                                  [136.253, 108.773],
                                  [87.816, -104.935],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-45.528, 114.195],
                                  [142.504, 106.435],
                                  [75.997, -92.209],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-40.074, 116.44],
                                  [149.123, 103.914],
                                  [63.478, -78.78],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-34.636, 118.712],
                                  [155.732, 101.42],
                                  [50.926, -65.27],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-29.34, 120.943],
                                  [162.12, 98.916],
                                  [38.83, -52.286],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.499, 123.02],
                                  [168.016, 96.527],
                                  [27.579, -40.201],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.114, 124.965],
                                  [173.377, 94.257],
                                  [17.335, -29.271],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.187, 126.728],
                                  [178.201, 92.187],
                                  [8.053, -19.387],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.655, 128.33],
                                  [182.55, 90.212],
                                  [-0.352, -10.554],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-9.522, 129.818],
                                  [186.421, 88.376],
                                  [-7.96, -2.669],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.787, 131.19],
                                  [189.858, 86.61],
                                  [-14.836, 4.473],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-4.328, 132.467],
                                  [192.982, 84.934],
                                  [-21.103, 10.852],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-2.084, 133.649],
                                  [195.769, 83.301],
                                  [-26.782, 16.627],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.182, 134.758],
                                  [198.266, 81.693],
                                  [-32.061, 21.823],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [1.565, 135.794],
                                  [200.526, 80.144],
                                  [-36.856, 26.516],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [3.09, 136.808],
                                  [202.575, 78.59],
                                  [-41.328, 30.787],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [4.456, 137.747],
                                  [204.428, 77.048],
                                  [-45.399, 34.612],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [5.596, 138.662],
                                  [206.021, 75.559],
                                  [-49.213, 38.097],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.634, 139.554],
                                  [207.521, 73.97],
                                  [-52.743, 41.186],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.505, 140.423],
                                  [208.82, 72.43],
                                  [-56.035, 43.991],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.208, 141.269],
                                  [209.961, 70.866],
                                  [-59.09, 46.467],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.804, 142.091],
                                  [210.961, 69.257],
                                  [-61.943, 48.645],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.23, 142.919],
                                  [211.801, 67.589],
                                  [-64.576, 50.617],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.612, 143.721],
                                  [212.563, 65.911],
                                  [-67.024, 52.321],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.829, 144.528],
                                  [213.169, 64.178],
                                  [-69.317, 53.779],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.946, 145.337],
                                  [213.685, 62.429],
                                  [-71.424, 55.012],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.973, 146.146],
                                  [214.119, 60.707],
                                  [-73.441, 56.046],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.918, 146.92],
                                  [214.431, 58.897],
                                  [-75.289, 56.941],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.793, 147.72],
                                  [214.725, 57.144],
                                  [-76.956, 57.731],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.674, 148.473],
                                  [214.93, 55.383],
                                  [-78.555, 58.353],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.448, 149.243],
                                  [215.124, 53.682],
                                  [-80.039, 58.886],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.251, 149.989],
                                  [215.248, 52.041],
                                  [-81.414, 59.367],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.091, 150.707],
                                  [215.404, 50.426],
                                  [-82.695, 59.835],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.851, 151.429],
                                  [215.472, 48.859],
                                  [-83.872, 60.188],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.658, 152.078],
                                  [215.631, 47.445],
                                  [-85.011, 60.578],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.513, 152.762],
                                  [215.731, 46.038],
                                  [-86.003, 60.922],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.353, 153.367],
                                  [215.808, 44.777],
                                  [-86.974, 61.256],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.177, 153.967],
                                  [215.912, 43.521],
                                  [-87.852, 61.602],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.044, 154.524],
                                  [216.052, 42.368],
                                  [-88.702, 61.883],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.953, 155.074],
                                  [216.125, 41.306],
                                  [-89.485, 62.196],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.836, 155.579],
                                  [216.268, 40.24],
                                  [-90.192, 62.459],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.755, 156.078],
                                  [216.338, 39.32],
                                  [-90.874, 62.748],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.644, 156.531],
                                  [216.424, 38.39],
                                  [-91.492, 62.978],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.562, 156.978],
                                  [216.534, 37.538],
                                  [-92.087, 63.226],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.444, 157.379],
                                  [216.604, 36.72],
                                  [-92.62, 63.45],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.414, 157.774],
                                  [216.705, 35.986],
                                  [-93.13, 63.656],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.282, 158.164],
                                  [216.752, 35.282],
                                  [-93.631, 63.821],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.233, 158.507],
                                  [216.826, 34.606],
                                  [-94.069, 63.962],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.141, 158.845],
                                  [216.854, 33.944],
                                  [-94.435, 64.12],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.068, 159.137],
                                  [216.896, 33.358],
                                  [-94.792, 64.24],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.948, 159.467],
                                  [216.942, 32.793],
                                  [-95.14, 64.32],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.906, 159.707],
                                  [216.958, 32.249],
                                  [-95.425, 64.422],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.815, 159.986],
                                  [216.976, 31.724],
                                  [-95.702, 64.48],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.675, 160.218],
                                  [216.941, 31.269],
                                  [-95.961, 64.495],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.611, 160.447],
                                  [216.926, 30.821],
                                  [-96.168, 64.517],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.495, 160.628],
                                  [216.911, 30.389],
                                  [-96.367, 64.502],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.389, 160.85],
                                  [216.905, 29.97],
                                  [-96.504, 64.439],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.294, 161.025],
                                  [216.854, 29.566],
                                  [-96.676, 64.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.146, 161.196],
                                  [216.801, 29.227],
                                  [-96.797, 64.345],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.069, 161.32],
                                  [216.764, 28.901],
                                  [-96.857, 64.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [5.937, 161.442],
                                  [216.672, 28.585],
                                  [-96.971, 64.166],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [5.752, 161.56],
                                  [216.576, 28.272],
                                  [-97.014, 64.031],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [5.635, 161.676],
                                  [216.496, 28.031],
                                  [-97.059, 63.852],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [5.526, 161.789],
                                  [216.421, 27.737],
                                  [-97.096, 63.674],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [5.361, 161.855],
                                  [216.289, 27.506],
                                  [-97.072, 63.506],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [5.203, 161.919],
                                  [216.162, 27.284],
                                  [-97.041, 63.284],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [4.996, 161.918],
                                  [215.969, 27.061],
                                  [-96.956, 63.115],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [4.678, 161.789],
                                  [215.728, 26.846],
                                  [-96.89, 62.999],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [4.204, 161.542],
                                  [215.296, 26.613],
                                  [-96.663, 62.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [3.627, 161.105],
                                  [214.818, 26.388],
                                  [-96.445, 63.018],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [2.829, 160.541],
                                  [214.185, 26.152],
                                  [-96.128, 63.153],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [1.919, 159.788],
                                  [213.391, 25.907],
                                  [-95.711, 63.386],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.85, 158.854],
                                  [212.48, 25.66],
                                  [-95.249, 63.719],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.495, 157.722],
                                  [211.355, 25.395],
                                  [-94.64, 64.196],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-2.009, 156.355],
                                  [210.052, 25.119],
                                  [-93.931, 64.773],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-3.736, 154.737],
                                  [208.563, 24.833],
                                  [-93.129, 65.449],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-5.796, 152.858],
                                  [206.836, 24.529],
                                  [-92.18, 66.317],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.141, 150.628],
                                  [204.853, 24.206],
                                  [-91.074, 67.377],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-10.811, 148.075],
                                  [202.6, 23.865],
                                  [-89.829, 68.584],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.877, 145.145],
                                  [200.015, 23.498],
                                  [-88.381, 70.029],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.386, 141.785],
                                  [197.081, 23.104],
                                  [-86.737, 71.715],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.392, 137.877],
                                  [193.722, 22.675],
                                  [-84.851, 73.64],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.967, 133.413],
                                  [189.868, 22.205],
                                  [-82.66, 75.899],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-31.267, 128.26],
                                  [185.487, 21.692],
                                  [-80.178, 78.54],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-37.293, 122.309],
                                  [180.425, 21.122],
                                  [-77.289, 81.608],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-44.271, 115.481],
                                  [174.603, 20.487],
                                  [-73.96, 85.152],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-52.32, 107.497],
                                  [167.859, 19.771],
                                  [-70.083, 89.314],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.66, 98.268],
                                  [160.077, 18.966],
                                  [-65.607, 94.14],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.516, 87.562],
                                  [151.095, 18.059],
                                  [-60.433, 99.727],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-84.947, 75.237],
                                  [140.758, 17.034],
                                  [-54.463, 106.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-99.007, 61.308],
                                  [129.054, 15.891],
                                  [-47.697, 113.609],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-114.534, 45.901],
                                  [116.173, 14.647],
                                  [-40.228, 121.768],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.966, 29.571],
                                  [102.554, 13.342],
                                  [-32.303, 130.413],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.523, 13.176],
                                  [88.861, 12.038],
                                  [-24.314, 139.168],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.462, -2.611],
                                  [75.701, 10.789],
                                  [-16.62, 147.564],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.342, -17.344],
                                  [63.479, 9.633],
                                  [-9.459, 155.411],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.93, -30.845],
                                  [52.315, 8.577],
                                  [-2.893, 162.612],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.238, -43.05],
                                  [42.2, 7.619],
                                  [3.09, 169.165],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.422, -54.029],
                                  [33.106, 6.76],
                                  [8.482, 175.069],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-225.536, -64.01],
                                  [24.878, 5.982],
                                  [13.376, 180.415],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.751, -73.077],
                                  [17.426, 5.277],
                                  [17.826, 185.296],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.129, -81.332],
                                  [10.609, 4.63],
                                  [21.932, 189.759],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.834, -88.916],
                                  [4.442, 4.047],
                                  [25.639, 193.849],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-257.912, -95.875],
                                  [-1.271, 3.505],
                                  [29.104, 197.612],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.433, -102.226],
                                  [-6.484, 3.012],
                                  [32.27, 201.048],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-270.499, -108.147],
                                  [-11.331, 2.552],
                                  [35.233, 204.251],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-276.118, -113.631],
                                  [-15.779, 2.131],
                                  [37.952, 207.267],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-281.343, -118.741],
                                  [-19.948, 1.736],
                                  [40.521, 210.048],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-286.222, -123.492],
                                  [-23.79, 1.372],
                                  [42.885, 212.641],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.762, -127.93],
                                  [-27.376, 1.033],
                                  [45.099, 215.047],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-295.026, -132.111],
                                  [-30.769, 0.71],
                                  [47.218, 217.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-299.062, -135.996],
                                  [-33.914, 0.411],
                                  [49.186, 219.482],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.805, -139.638],
                                  [-36.834, 0.137],
                                  [50.996, 221.464],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-306.389, -143.086],
                                  [-39.632, -0.13],
                                  [52.765, 223.351],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-309.681, -146.347],
                                  [-42.206, -0.372],
                                  [54.375, 225.143],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-312.862, -149.372],
                                  [-44.665, -0.606],
                                  [55.936, 226.841],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-315.812, -152.259],
                                  [-46.97, -0.825],
                                  [57.394, 228.396],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-318.596, -154.973],
                                  [-49.114, -1.027],
                                  [58.756, 229.904],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-321.26, -157.5],
                                  [-51.167, -1.222],
                                  [60.061, 231.317],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-323.757, -159.902],
                                  [-53.114, -1.408],
                                  [61.325, 232.634],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-326.087, -162.187],
                                  [-54.922, -1.578],
                                  [62.477, 233.903],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.352, -164.339],
                                  [-56.64, -1.741],
                                  [63.58, 235.076],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-330.443, -166.374],
                                  [-58.212, -1.888],
                                  [64.579, 236.202],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-332.421, -168.285],
                                  [-59.748, -2.034],
                                  [65.576, 237.279],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-334.287, -170.071],
                                  [-61.208, -2.173],
                                  [66.532, 238.308],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-336.096, -171.794],
                                  [-62.523, -2.296],
                                  [67.369, 239.241],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.731, -173.393],
                                  [-63.817, -2.419],
                                  [68.219, 240.126],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-339.363, -174.937],
                                  [-65.029, -2.535],
                                  [69.012, 241.01],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-340.828, -176.356],
                                  [-66.164, -2.642],
                                  [69.757, 241.798],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-342.228, -177.705],
                                  [-67.225, -2.742],
                                  [70.444, 242.538],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-343.571, -178.945],
                                  [-68.202, -2.834],
                                  [71.083, 243.277],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-344.802, -180.169],
                                  [-69.167, -2.925],
                                  [71.719, 243.919],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-345.976, -181.284],
                                  [-70.048, -3.01],
                                  [72.307, 244.561],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-347.085, -182.329],
                                  [-70.862, -3.086],
                                  [72.838, 245.154],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-348.137, -183.312],
                                  [-71.663, -3.162],
                                  [73.375, 245.698],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-349.077, -184.239],
                                  [-72.381, -3.231],
                                  [73.855, 246.242],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-349.96, -185.097],
                                  [-73.038, -3.292],
                                  [74.279, 246.689],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-350.841, -185.9],
                                  [-73.675, -3.353],
                                  [74.708, 247.183],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-351.603, -186.641],
                                  [-74.244, -3.406],
                                  [75.081, 247.581],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-352.307, -187.319],
                                  [-74.801, -3.459],
                                  [75.46, 247.978],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-353.01, -187.935],
                                  [-75.289, -3.505],
                                  [75.774, 248.326],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-353.6, -188.551],
                                  [-75.757, -3.551],
                                  [76.102, 248.673],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-354.189, -189.104],
                                  [-76.164, -3.589],
                                  [76.367, 248.971],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-354.665, -189.596],
                                  [-76.558, -3.627],
                                  [76.637, 249.221],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-355.133, -190.024],
                                  [-76.884, -3.657],
                                  [76.85, 249.47],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-355.543, -190.398],
                                  [-77.205, -3.687],
                                  [77.07, 249.718],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-355.952, -190.765],
                                  [-77.45, -3.71],
                                  [77.225, 249.917],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.249, -191.076],
                                  [-77.697, -3.733],
                                  [77.387, 250.068],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.544, -191.326],
                                  [-77.924, -3.756],
                                  [77.547, 250.218],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.783, -191.568],
                                  [-78.09, -3.771],
                                  [77.658, 250.367],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.964, -191.755],
                                  [-78.25, -3.786],
                                  [77.768, 250.467],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.137, -191.934],
                                  [-78.342, -3.794],
                                  [77.822, 250.566],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.26, -192.059],
                                  [-78.476, -3.809],
                                  [77.929, 250.617],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.374, -192.121],
                                  [-78.494, -3.809],
                                  [77.925, 250.667],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.431, -192.183],
                                  [-78.561, -3.817],
                                  [77.982, 250.716],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.366, -192.122],
                                  [-78.564, -3.816],
                                  [77.988, 250.655],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-357.229, -192.04],
                                  [-78.499, -3.744],
                                  [77.943, 250.355],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.95, -191.772],
                                  [-78.477, -3.614],
                                  [77.963, 249.877],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.542, -191.484],
                                  [-78.342, -3.476],
                                  [77.936, 249.22],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-356.057, -191.066],
                                  [-78.246, -3.273],
                                  [77.868, 248.321],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-355.375, -190.51],
                                  [-78.082, -3.005],
                                  [77.813, 247.124],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-354.549, -189.879],
                                  [-77.852, -2.673],
                                  [77.769, 245.689],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-353.528, -189.041],
                                  [-77.614, -2.267],
                                  [77.686, 243.954],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-352.357, -188.071],
                                  [-77.356, -1.851],
                                  [77.618, 241.917],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-350.982, -186.949],
                                  [-77.018, -1.307],
                                  [77.514, 239.53],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-349.346, -185.619],
                                  [-76.612, -0.633],
                                  [77.379, 236.727],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-347.434, -184.08],
                                  [-76.119, 0.108],
                                  [77.274, 233.445],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-345.304, -182.316],
                                  [-75.599, 0.931],
                                  [77.148, 229.698],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-342.782, -180.272],
                                  [-74.924, 1.941],
                                  [77.005, 225.414],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-339.915, -177.938],
                                  [-74.163, 3.091],
                                  [76.852, 220.434],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-336.638, -175.242],
                                  [-73.282, 4.39],
                                  [76.706, 214.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-332.891, -172.126],
                                  [-72.281, 5.833],
                                  [76.52, 208.366],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.596, -168.548],
                                  [-71.079, 7.601],
                                  [76.368, 200.941],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-323.635, -164.399],
                                  [-69.661, 9.581],
                                  [76.229, 192.372],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-317.878, -159.569],
                                  [-67.998, 11.855],
                                  [76.128, 182.602],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-311.254, -153.928],
                                  [-65.982, 14.537],
                                  [76.059, 171.302],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-303.62, -147.362],
                                  [-63.502, 17.644],
                                  [76.145, 158.269],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.805, -139.704],
                                  [-60.552, 21.245],
                                  [76.342, 143.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.689, -130.783],
                                  [-56.982, 25.423],
                                  [76.753, 126.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-273.231, -120.545],
                                  [-52.7, 30.189],
                                  [77.481, 106.908],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-260.694, -109.094],
                                  [-47.641, 35.478],
                                  [78.654, 85.953],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.557, -96.773],
                                  [-41.916, 41.154],
                                  [80.319, 64.067],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.392, -84.143],
                                  [-35.694, 46.831],
                                  [82.431, 42.394],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.782, -71.748],
                                  [-29.281, 52.406],
                                  [84.997, 21.838],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.112, -60.024],
                                  [-22.897, 57.649],
                                  [87.863, 3.041],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.574, -49.094],
                                  [-16.666, 62.505],
                                  [90.855, -13.874],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.126, -39.015],
                                  [-10.718, 66.89],
                                  [93.996, -28.898],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-181.658, -29.781],
                                  [-5.019, 70.911],
                                  [97.111, -42.326],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-174.03, -21.308],
                                  [0.385, 74.543],
                                  [100.175, -54.212],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.186, -13.496],
                                  [5.517, 77.881],
                                  [103.23, -64.902],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.008, -6.265],
                                  [10.392, 80.941],
                                  [106.218, -74.495],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.382, 0.37],
                                  [14.994, 83.769],
                                  [109.109, -83.16],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.267, 6.59],
                                  [19.379, 86.38],
                                  [111.917, -91.001],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.608, 12.325],
                                  [23.53, 88.775],
                                  [114.646, -98.16],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.293, 17.725],
                                  [27.47, 91.032],
                                  [117.257, -104.682],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.351, 22.742],
                                  [31.226, 93.12],
                                  [119.781, -110.642],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.677, 27.481],
                                  [34.797, 95.026],
                                  [122.236, -116.142],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.303, 31.894],
                                  [38.202, 96.888],
                                  [124.592, -121.22],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-127.175, 36.057],
                                  [41.429, 98.57],
                                  [126.889, -125.899],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-124.254, 39.997],
                                  [44.502, 100.162],
                                  [129.057, -130.247],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.517, 43.72],
                                  [47.456, 101.665],
                                  [131.167, -134.298],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-118.974, 47.19],
                                  [50.257, 103.098],
                                  [133.168, -138.037],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-116.602, 50.512],
                                  [52.943, 104.441],
                                  [135.118, -141.566],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-114.35, 53.616],
                                  [55.499, 105.714],
                                  [137.011, -144.834],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-112.294, 56.608],
                                  [57.966, 106.917],
                                  [138.774, -147.881],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-110.332, 59.406],
                                  [60.275, 108.006],
                                  [140.535, -150.689],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.515, 62.047],
                                  [62.504, 109.095],
                                  [142.159, -153.369],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-106.758, 64.574],
                                  [64.626, 110.09],
                                  [143.75, -155.858],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-105.147, 66.988],
                                  [66.642, 111.041],
                                  [145.286, -158.22],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-103.629, 69.244],
                                  [68.589, 111.941],
                                  [146.717, -160.415],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-102.207, 71.386],
                                  [70.444, 112.774],
                                  [148.109, -162.509],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-100.836, 73.39],
                                  [72.202, 113.581],
                                  [149.479, -164.418],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-99.552, 75.324],
                                  [73.883, 114.383],
                                  [150.72, -166.248],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.358, 77.162],
                                  [75.444, 115.054],
                                  [151.956, -167.957],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-97.228, 78.882],
                                  [76.946, 115.783],
                                  [153.124, -169.545],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.162, 80.511],
                                  [78.43, 116.382],
                                  [154.222, -171.036],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-95.201, 82.085],
                                  [79.767, 117.018],
                                  [155.251, -172.451],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-94.238, 83.545],
                                  [81.084, 117.587],
                                  [156.272, -173.789],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-93.376, 84.91],
                                  [82.296, 118.109],
                                  [157.222, -175.031],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-92.531, 86.201],
                                  [83.485, 118.649],
                                  [158.12, -176.197],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-91.744, 87.441],
                                  [84.587, 119.1],
                                  [158.966, -177.287],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-91.036, 88.606],
                                  [85.581, 119.569],
                                  [159.803, -178.303],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-90.361, 89.678],
                                  [86.572, 119.991],
                                  [160.545, -179.265],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-89.721, 90.719],
                                  [87.517, 120.389],
                                  [161.276, -180.152],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-89.093, 91.666],
                                  [88.373, 120.741],
                                  [161.975, -181.006],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-88.562, 92.54],
                                  [89.182, 121.09],
                                  [162.601, -181.743],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-88.041, 93.383],
                                  [89.923, 121.414],
                                  [163.195, -182.489],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-87.573, 94.154],
                                  [90.638, 121.735],
                                  [163.735, -183.14],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-87.158, 94.895],
                                  [91.305, 121.99],
                                  [164.243, -183.758],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-86.731, 95.542],
                                  [91.882, 122.261],
                                  [164.74, -184.301],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-86.356, 96.16],
                                  [92.474, 122.489],
                                  [165.185, -184.811],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-86.01, 96.727],
                                  [92.955, 122.734],
                                  [165.576, -185.288],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-85.693, 97.243],
                                  [93.43, 122.913],
                                  [165.935, -185.732],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-85.427, 97.688],
                                  [93.879, 123.09],
                                  [166.283, -186.122],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-85.169, 98.104],
                                  [94.278, 123.242],
                                  [166.579, -186.478],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-84.918, 98.469],
                                  [94.567, 123.413],
                                  [166.843, -186.759],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-84.737, 98.785],
                                  [94.892, 123.56],
                                  [167.117, -187.048],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-84.542, 99.092],
                                  [95.168, 123.663],
                                  [167.318, -187.261],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-84.438, 99.33],
                                  [95.355, 123.742],
                                  [167.468, -187.483],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-84.277, 99.538],
                                  [95.577, 123.818],
                                  [167.649, -187.67],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-84.165, 99.677],
                                  [95.71, 123.892],
                                  [167.778, -187.76],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-84.121, 99.85],
                                  [95.858, 123.922],
                                  [167.856, -187.879],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-84.063, 99.912],
                                  [95.937, 123.992],
                                  [167.925, -187.942],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.55, 0.55, 0.55, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.55, 0.55, 0.55, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [111.566, 56.506],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.586, 56.594],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.597, 56.69],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.604, 56.866],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.642, 57.133],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.689, 57.47],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.722, 57.867],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.771, 58.32],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.872, 58.918],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.941, 59.569],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.069, 60.338],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.24, 61.275],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.435, 62.313],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.697, 63.517],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.028, 64.867],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.459, 66.468],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.017, 68.243],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.714, 70.276],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.642, 72.635],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.877, 75.319],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.537, 78.319],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.711, 81.677],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.629, 85.392],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.562, 89.303],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.708, 93.131],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.128, 96.352],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.547, 98.417],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.204, 98.99],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.379, 98.007],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.424, 95.95],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.164, 93.297],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.683, 90.536],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.205, 87.819],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.921, 85.331],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.087, 83.091],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.848, 81.081],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.286, 79.262],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.516, 77.599],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.606, 76.095],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.598, 74.675],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.532, 73.328],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.364, 72.087],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.226, 70.783],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.041, 69.555],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.847, 68.316],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.649, 67.044],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.483, 65.727],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.331, 64.393],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.182, 63],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.081, 61.581],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.98, 60.17],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.895, 58.671],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.912, 57.212],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.872, 55.728],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.921, 54.285],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.932, 52.888],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.996, 51.512],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.062, 50.161],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.164, 48.951],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.273, 47.738],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.331, 46.653],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.468, 45.571],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.604, 44.574],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.671, 43.661],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.847, 42.736],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.918, 41.945],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.043, 41.135],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.171, 40.398],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.291, 39.686],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.394, 39.05],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.491, 38.433],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.591, 37.838],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.698, 37.255],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.789, 36.737],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.914, 36.231],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.98, 35.749],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.081, 35.275],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.138, 34.859],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.205, 34.45],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.283, 34.05],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.405, 33.655],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.445, 33.282],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.52, 32.954],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.598, 32.639],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.619, 32.335],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.696, 32.021],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.742, 31.773],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.805, 31.476],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.842, 31.234],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.885, 30.994],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.892, 30.757],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.855, 30.532],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.755, 30.286],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.592, 30.063],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.391, 29.824],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.083, 29.582],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.701, 29.343],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.263, 29.084],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.718, 28.815],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.063, 28.536],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.318, 28.235],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.443, 27.913],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.444, 27.559],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.282, 27.165],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.985, 26.717],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.458, 26.199],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.721, 25.583],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.756, 24.844],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.439, 23.935],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.787, 22.78],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.648, 21.294],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.951, 19.341],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.566, 19.156],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.827, 19.337],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.074, 18.874],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.756, 17.549],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.878, 15.265],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.779, 12.197],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.507, 8.705],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.578, 5.182],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.966, 1.903],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.387, -0.978],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.535, -3.426],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.14, -5.483],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.706, -7.192],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.416, -8.591],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.467, -9.758],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.975, -10.708],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.035, -11.492],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.722, -12.138],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.061, -12.668],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.139, -13.101],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.971, -13.464],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.598, -13.765],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.039, -14.002],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.309, -14.197],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.419, -14.36],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.424, -14.487],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.266, -14.595],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.013, -14.667],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.657, -14.73],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.188, -14.78],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.655, -14.812],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.02, -14.822],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.295, -14.829],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.538, -14.845],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.682, -14.86],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.751, -14.84],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.752, -14.804],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.731, -14.817],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.63, -14.786],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.502, -14.772],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.305, -14.748],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.061, -14.726],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.756, -14.7],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.44, -14.676],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.063, -14.648],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.655, -14.632],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.226, -14.606],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.727, -14.578],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.219, -14.572],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.666, -14.543],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.078, -14.527],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.451, -14.491],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.819, -14.482],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.135, -14.456],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.449, -14.455],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.72, -14.434],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.963, -14.427],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.172, -14.398],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.382, -14.411],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.557, -14.4],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.711, -14.384],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.832, -14.379],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.936, -14.365],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.024, -14.377],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.102, -14.36],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.146, -14.378],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.175, -14.363],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.16, -14.35],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.221, -14.365],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.291, -14.3],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.341, -14.288],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.484, -14.249],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.639, -14.201],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.822, -14.154],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.029, -14.056],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.252, -13.97],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.545, -13.861],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.896, -13.713],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.246, -13.556],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.688, -13.397],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.124, -13.161],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.668, -12.92],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.215, -12.616],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.801, -12.276],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.482, -11.827],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.166, -11.316],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.801, -10.649],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.423, -9.805],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.891, -8.737],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.102, -7.303],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.806, -5.326],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.586, -2.542],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.892, 1.379],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.091, 6.83],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.69, 13.876],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.82, 22.391],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.227, 31.671],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.823, 41.054],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.315, 50.009],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.097, 58.326],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.434, 65.849],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.361, 72.655],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.075, 78.745],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.468, 82.929],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.286, 80.807],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.424, 78.735],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.998, 76.803],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.095, 75.015],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.793, 73.301],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.142, 71.794],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.168, 70.363],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.916, 69.059],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.446, 67.869],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.735, 66.809],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.845, 65.818],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.771, 64.92],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.532, 64.14],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.121, 63.377],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.574, 62.737],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.903, 62.103],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.103, 61.531],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.188, 61.048],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.17, 60.578],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.05, 60.165],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.827, 59.802],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.487, 59.414],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.07, 59.135],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.6, 58.856],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.996, 58.617],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.364, 58.373],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.621, 58.152],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.841, 57.985],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.973, 57.788],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.015, 57.63],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.019, 57.5],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.979, 57.387],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.864, 57.23],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.681, 57.158],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.443, 57.041],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.161, 56.988],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.829, 56.915],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.429, 56.843],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.017, 56.799],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.514, 56.742],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.99, 56.679],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.435, 56.656],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.831, 56.619],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.141, 56.577],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.468, 56.573],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.741, 56.553],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.931, 56.517],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.156, 56.49],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.297, 56.49],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.428, 56.493],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.511, 56.509],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [111.566, 56.506],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.586, 56.594],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.597, 56.69],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.604, 56.866],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.642, 57.133],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.689, 57.47],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.722, 57.867],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.771, 58.32],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.872, 58.918],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.941, 59.569],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.069, 60.338],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.24, 61.275],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.435, 62.313],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.697, 63.517],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.028, 64.867],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.459, 66.468],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.017, 68.243],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.714, 70.276],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.642, 72.635],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.877, 75.319],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.537, 78.319],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.711, 81.677],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.629, 85.392],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.562, 89.303],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.708, 93.131],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.128, 96.352],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.547, 98.417],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.204, 98.99],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.379, 98.007],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.424, 95.95],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.164, 93.297],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.683, 90.536],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.205, 87.819],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.921, 85.331],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.087, 83.091],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.848, 81.081],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.286, 79.262],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.516, 77.599],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.606, 76.095],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.598, 74.675],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.532, 73.328],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.364, 72.087],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.226, 70.783],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.041, 69.555],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.847, 68.316],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.649, 67.044],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.483, 65.727],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.331, 64.393],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.182, 63],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.081, 61.581],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.98, 60.17],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.895, 58.671],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.912, 57.212],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.872, 55.728],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.921, 54.285],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.932, 52.888],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.996, 51.512],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.062, 50.161],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.164, 48.951],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.273, 47.738],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.331, 46.653],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.468, 45.571],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.604, 44.574],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.671, 43.661],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.847, 42.736],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.918, 41.945],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.043, 41.135],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.171, 40.398],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.291, 39.686],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.394, 39.05],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.491, 38.433],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.591, 37.838],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.698, 37.255],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.789, 36.737],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.914, 36.231],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.98, 35.749],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.081, 35.275],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.138, 34.859],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.205, 34.45],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.283, 34.05],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.405, 33.655],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.445, 33.282],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.52, 32.954],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.598, 32.639],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.619, 32.335],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.696, 32.021],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.742, 31.773],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.805, 31.476],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.842, 31.234],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.885, 30.994],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.892, 30.757],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.855, 30.532],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.755, 30.286],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.592, 30.063],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.391, 29.824],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.083, 29.582],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.701, 29.343],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.263, 29.084],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.718, 28.815],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.063, 28.536],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.318, 28.235],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.443, 27.913],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.444, 27.559],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.282, 27.165],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.985, 26.717],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.458, 26.199],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.721, 25.583],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.756, 24.844],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.439, 23.935],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.787, 22.78],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.648, 21.294],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.951, 19.341],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.566, 19.156],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.827, 19.337],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.074, 18.874],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.756, 17.549],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.878, 15.265],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.779, 12.197],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.507, 8.705],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.578, 5.182],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.966, 1.903],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.387, -0.978],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.535, -3.426],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-16.14, -5.483],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.706, -7.192],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.416, -8.591],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.467, -9.758],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.975, -10.708],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.035, -11.492],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.722, -12.138],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.061, -12.668],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-52.139, -13.101],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.971, -13.464],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.598, -13.765],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.039, -14.002],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.309, -14.197],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.419, -14.36],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.424, -14.487],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.266, -14.595],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-70.013, -14.667],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.657, -14.73],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.188, -14.78],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.655, -14.812],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.02, -14.822],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.295, -14.829],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.538, -14.845],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.682, -14.86],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.751, -14.84],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.752, -14.804],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.731, -14.817],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.63, -14.786],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.502, -14.772],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.305, -14.748],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.061, -14.726],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.756, -14.7],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.44, -14.676],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.063, -14.648],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.655, -14.632],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.226, -14.606],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.727, -14.578],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.219, -14.572],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.666, -14.543],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.078, -14.527],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.451, -14.491],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.819, -14.482],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.135, -14.456],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.449, -14.455],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.72, -14.434],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.963, -14.427],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.172, -14.398],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.382, -14.411],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.557, -14.4],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.711, -14.384],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.832, -14.379],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.936, -14.365],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.024, -14.377],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.102, -14.36],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.146, -14.378],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.175, -14.363],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.16, -14.35],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.221, -14.365],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.291, -14.3],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.341, -14.288],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.484, -14.249],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.639, -14.201],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.822, -14.154],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.029, -14.056],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.252, -13.97],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.545, -13.861],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.896, -13.713],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.246, -13.556],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.688, -13.397],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.124, -13.161],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.668, -12.92],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.215, -12.616],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.801, -12.276],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.482, -11.827],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.166, -11.316],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.801, -10.649],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.423, -9.805],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.891, -8.737],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.102, -7.303],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.806, -5.326],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.586, -2.542],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.892, 1.379],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.091, 6.83],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.69, 13.876],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.82, 22.391],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.227, 31.671],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.823, 41.054],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-39.315, 50.009],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.097, 58.326],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.434, 65.849],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.361, 72.655],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.075, 78.745],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.468, 82.929],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.286, 80.807],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.424, 78.735],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.998, 76.803],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.095, 75.015],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.793, 73.301],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.142, 71.794],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.168, 70.363],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [55.916, 69.059],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.446, 67.869],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.735, 66.809],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.845, 65.818],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.771, 64.92],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.532, 64.14],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.121, 63.377],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.574, 62.737],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.903, 62.103],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.103, 61.531],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.188, 61.048],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.17, 60.578],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.05, 60.165],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.827, 59.802],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.487, 59.414],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.07, 59.135],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [93.6, 58.856],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.996, 58.617],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.364, 58.373],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.621, 58.152],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.841, 57.985],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.973, 57.788],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.015, 57.63],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.019, 57.5],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.979, 57.387],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.864, 57.23],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.681, 57.158],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.443, 57.041],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.161, 56.988],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.829, 56.915],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.429, 56.843],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.017, 56.799],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.514, 56.742],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.99, 56.679],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.435, 56.656],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.831, 56.619],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.141, 56.577],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.468, 56.573],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.741, 56.553],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.931, 56.517],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.156, 56.49],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.297, 56.49],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.428, 56.493],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.511, 56.509],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 6',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 6,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.006, 114.392],
                                  [107.994, 128.797],
                                  [187.165, -173.545],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-71.952, 114.408],
                                  [108.085, 128.782],
                                  [186.977, -173.405],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-71.841, 114.416],
                                  [108.197, 128.726],
                                  [186.705, -173.172],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-71.673, 114.43],
                                  [108.364, 128.668],
                                  [186.248, -172.796],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-71.512, 114.449],
                                  [108.601, 128.595],
                                  [185.732, -172.302],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-71.232, 114.473],
                                  [108.918, 128.507],
                                  [185.005, -171.628],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-70.958, 114.502],
                                  [109.267, 128.378],
                                  [184.132, -170.838],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-70.567, 114.549],
                                  [109.696, 128.233],
                                  [183.073, -169.881],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-70.12, 114.6],
                                  [110.256, 128.11],
                                  [181.803, -168.77],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.618, 114.669],
                                  [110.833, 127.909],
                                  [180.361, -167.418],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.999, 114.755],
                                  [111.566, 127.653],
                                  [178.658, -165.838],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.325, 114.847],
                                  [112.428, 127.405],
                                  [176.682, -164.043],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-67.472, 114.968],
                                  [113.395, 127.114],
                                  [174.421, -161.921],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-66.566, 115.108],
                                  [114.528, 126.755],
                                  [171.813, -159.51],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-65.418, 115.279],
                                  [115.828, 126.34],
                                  [168.858, -156.698],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-64.218, 115.495],
                                  [117.332, 125.866],
                                  [165.47, -153.498],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.715, 115.744],
                                  [119.063, 125.385],
                                  [161.599, -149.849],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.035, 116.053],
                                  [121.048, 124.754],
                                  [157.123, -145.601],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.116, 116.411],
                                  [123.349, 124.05],
                                  [152.031, -140.68],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-56.896, 116.86],
                                  [126.029, 123.256],
                                  [146.176, -135.013],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-54.251, 117.401],
                                  [129.173, 122.319],
                                  [139.399, -128.462],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-51.18, 118.053],
                                  [132.783, 121.236],
                                  [131.619, -120.817],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.686, 118.833],
                                  [136.971, 119.991],
                                  [122.641, -111.955],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-43.58, 119.774],
                                  [141.851, 118.54],
                                  [112.311, -101.702],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-38.926, 120.883],
                                  [147.397, 116.879],
                                  [100.647, -90.072],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-33.726, 122.137],
                                  [153.533, 115.041],
                                  [87.881, -77.267],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-28.291, 123.502],
                                  [160.023, 113.05],
                                  [74.374, -63.752],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.875, 124.895],
                                  [166.502, 111.083],
                                  [60.821, -50.163],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.601, 126.275],
                                  [172.767, 109.082],
                                  [47.764, -37.102],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.784, 127.568],
                                  [178.543, 107.16],
                                  [35.62, -24.956],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.426, 128.788],
                                  [183.791, 105.308],
                                  [24.559, -13.966],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-4.526, 129.902],
                                  [188.509, 103.612],
                                  [14.539, -4.042],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.025, 130.923],
                                  [192.758, 101.972],
                                  [5.471, 4.823],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [2.076, 131.88],
                                  [196.537, 100.425],
                                  [-2.741, 12.73],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [4.775, 132.771],
                                  [199.883, 98.916],
                                  [-10.163, 19.886],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.197, 133.611],
                                  [202.919, 97.467],
                                  [-16.93, 26.267],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.401, 134.398],
                                  [205.627, 96.038],
                                  [-23.065, 32.04],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.26, 135.148],
                                  [208.038, 94.604],
                                  [-28.76, 37.221],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [12.959, 135.858],
                                  [210.219, 93.208],
                                  [-33.936, 41.891],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.434, 136.564],
                                  [212.186, 91.785],
                                  [-38.764, 46.132],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [15.745, 137.229],
                                  [213.957, 90.356],
                                  [-43.161, 49.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [16.827, 137.89],
                                  [215.469, 88.958],
                                  [-47.274, 53.352],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [17.801, 138.546],
                                  [216.885, 87.444],
                                  [-51.087, 56.39],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [18.604, 139.198],
                                  [218.1, 85.957],
                                  [-54.64, 59.128],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [19.233, 139.845],
                                  [219.152, 84.432],
                                  [-57.934, 61.526],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [19.749, 140.488],
                                  [220.062, 82.847],
                                  [-61.012, 63.622],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [20.089, 141.147],
                                  [220.809, 81.181],
                                  [-63.852, 65.499],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [20.379, 141.8],
                                  [221.475, 79.496],
                                  [-66.497, 67.095],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [20.497, 142.468],
                                  [221.981, 77.735],
                                  [-68.969, 68.436],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [20.51, 143.15],
                                  [222.393, 75.946],
                                  [-71.244, 69.548],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [20.426, 143.845],
                                  [222.718, 74.162],
                                  [-73.411, 70.445],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [20.257, 144.522],
                                  [222.921, 72.287],
                                  [-75.404, 71.2],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [20.014, 145.229],
                                  [223.1, 70.452],
                                  [-77.205, 71.838],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [19.775, 145.906],
                                  [223.196, 68.606],
                                  [-78.923, 72.308],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [19.428, 146.602],
                                  [223.275, 66.812],
                                  [-80.517, 72.692],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [19.112, 147.285],
                                  [223.29, 65.072],
                                  [-81.992, 73.018],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [18.835, 147.947],
                                  [223.341, 63.356],
                                  [-83.368, 73.334],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [18.481, 148.614],
                                  [223.306, 61.692],
                                  [-84.631, 73.542],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [18.178, 149.222],
                                  [223.363, 60.178],
                                  [-85.847, 73.787],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [17.927, 149.859],
                                  [223.371, 58.68],
                                  [-86.911, 73.995],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [17.667, 150.43],
                                  [223.36, 57.322],
                                  [-87.951, 74.196],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [17.396, 150.997],
                                  [223.377, 55.979],
                                  [-88.889, 74.419],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [17.173, 151.525],
                                  [223.434, 54.742],
                                  [-89.792, 74.583],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [16.995, 152.047],
                                  [223.434, 53.595],
                                  [-90.628, 74.78],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [16.797, 152.53],
                                  [223.502, 52.452],
                                  [-91.384, 74.939],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [16.638, 153.006],
                                  [223.507, 51.455],
                                  [-92.108, 75.123],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [16.453, 153.441],
                                  [223.527, 50.454],
                                  [-92.768, 75.259],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [16.301, 153.871],
                                  [223.574, 49.531],
                                  [-93.398, 75.414],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [16.118, 154.259],
                                  [223.585, 48.646],
                                  [-93.966, 75.548],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [16.024, 154.64],
                                  [223.632, 47.846],
                                  [-94.506, 75.671],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [15.832, 155.017],
                                  [223.624, 47.08],
                                  [-95.032, 75.755],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [15.726, 155.352],
                                  [223.65, 46.345],
                                  [-95.497, 75.822],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [15.579, 155.681],
                                  [223.631, 45.625],
                                  [-95.886, 75.906],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [15.453, 155.969],
                                  [223.628, 44.981],
                                  [-96.262, 75.956],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [15.283, 156.289],
                                  [223.628, 44.361],
                                  [-96.627, 75.969],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [15.193, 156.53],
                                  [223.605, 43.765],
                                  [-96.93, 76.006],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [15.056, 156.804],
                                  [223.583, 43.19],
                                  [-97.222, 76.004],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.873, 157.036],
                                  [223.51, 42.686],
                                  [-97.49, 75.959],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.765, 157.264],
                                  [223.462, 42.187],
                                  [-97.708, 75.922],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.609, 157.45],
                                  [223.411, 41.706],
                                  [-97.916, 75.853],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.464, 157.67],
                                  [223.371, 41.242],
                                  [-98.063, 75.738],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.332, 157.849],
                                  [223.289, 40.793],
                                  [-98.236, 75.69],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.147, 158.023],
                                  [223.205, 40.41],
                                  [-98.361, 75.544],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.035, 158.156],
                                  [223.14, 40.04],
                                  [-98.425, 75.4],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.87, 158.285],
                                  [223.02, 39.683],
                                  [-98.541, 75.269],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.652, 158.411],
                                  [222.897, 39.327],
                                  [-98.583, 75.088],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.505, 158.534],
                                  [222.791, 39.045],
                                  [-98.628, 74.869],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.365, 158.654],
                                  [222.692, 38.711],
                                  [-98.661, 74.649],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.171, 158.732],
                                  [222.537, 38.44],
                                  [-98.634, 74.44],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [12.984, 158.806],
                                  [222.387, 38.178],
                                  [-98.597, 74.178],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [12.753, 158.816],
                                  [222.169, 37.914],
                                  [-98.51, 73.965],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [12.415, 158.698],
                                  [221.889, 37.66],
                                  [-98.446, 73.802],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.928, 158.464],
                                  [221.415, 37.381],
                                  [-98.23, 73.688],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.345, 158.039],
                                  [220.88, 37.11],
                                  [-98.026, 73.703],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.55, 157.488],
                                  [220.181, 36.825],
                                  [-97.731, 73.767],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.645, 156.746],
                                  [219.307, 36.527],
                                  [-97.343, 73.921],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.59, 155.826],
                                  [218.3, 36.225],
                                  [-96.916, 74.165],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.271, 154.706],
                                  [217.069, 35.898],
                                  [-96.355, 74.539],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [5.788, 153.355],
                                  [215.642, 35.556],
                                  [-95.702, 75.003],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [4.103, 151.753],
                                  [214.01, 35.2],
                                  [-94.966, 75.557],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [2.098, 149.887],
                                  [212.122, 34.819],
                                  [-94.095, 76.283],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.184, 147.677],
                                  [209.956, 34.413],
                                  [-93.079, 77.183],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-2.775, 145.142],
                                  [207.493, 33.982],
                                  [-91.938, 78.214],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-5.749, 142.23],
                                  [204.67, 33.514],
                                  [-90.609, 79.46],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-9.147, 138.89],
                                  [201.467, 33.011],
                                  [-89.102, 80.921],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.022, 135.006],
                                  [197.801, 32.462],
                                  [-87.376, 82.599],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.449, 130.569],
                                  [193.6, 31.857],
                                  [-85.367, 84.575],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.572, 125.443],
                                  [188.824, 31.195],
                                  [-83.096, 86.893],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-28.392, 119.523],
                                  [183.305, 30.456],
                                  [-80.446, 89.595],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.131, 112.727],
                                  [176.963, 29.63],
                                  [-77.398, 92.724],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-42.901, 104.784],
                                  [169.622, 28.697],
                                  [-73.846, 96.406],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-51.915, 95.599],
                                  [161.148, 27.646],
                                  [-69.746, 100.683],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.39, 84.943],
                                  [151.367, 26.458],
                                  [-65.003, 105.641],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.382, 72.671],
                                  [140.116, 25.112],
                                  [-59.533, 111.408],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-87.943, 58.803],
                                  [127.376, 23.61],
                                  [-53.334, 117.986],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-102.92, 43.464],
                                  [113.358, 21.973],
                                  [-46.488, 125.251],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-118.771, 27.206],
                                  [98.543, 20.254],
                                  [-39.222, 132.955],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.744, 10.883],
                                  [83.648, 18.535],
                                  [-31.893, 140.763],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.121, -4.837],
                                  [69.336, 16.887],
                                  [-24.835, 148.254],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.482, -19.507],
                                  [56.039, 15.362],
                                  [-18.259, 155.26],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.595, -32.949],
                                  [43.9, 13.968],
                                  [-12.23, 161.693],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.48, -45.101],
                                  [32.904, 12.705],
                                  [-6.73, 167.55],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.282, -56.035],
                                  [23.018, 11.57],
                                  [-1.769, 172.83],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.052, -65.97],
                                  [14.073, 10.543],
                                  [2.734, 177.614],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-218.956, -74.999],
                                  [5.974, 9.612],
                                  [6.83, 181.985],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.055, -83.217],
                                  [-1.427, 8.757],
                                  [10.614, 185.983],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.506, -90.769],
                                  [-8.129, 7.987],
                                  [14.032, 189.649],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.351, -97.696],
                                  [-14.332, 7.272],
                                  [17.23, 193.025],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.661, -104.02],
                                  [-19.994, 6.62],
                                  [20.155, 196.108],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-253.531, -109.916],
                                  [-25.252, 6.011],
                                  [22.891, 198.984],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-258.97, -115.376],
                                  [-30.084, 5.456],
                                  [25.405, 201.693],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-264.03, -120.462],
                                  [-34.606, 4.933],
                                  [27.783, 204.194],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-268.755, -125.194],
                                  [-38.776, 4.452],
                                  [29.97, 206.526],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-273.152, -129.613],
                                  [-42.668, 4.003],
                                  [32.019, 208.691],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.286, -133.773],
                                  [-46.345, 3.576],
                                  [33.983, 210.73],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-281.197, -137.641],
                                  [-49.753, 3.181],
                                  [35.808, 212.685],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.824, -141.269],
                                  [-52.925, 2.817],
                                  [37.485, 214.471],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-288.302, -144.701],
                                  [-55.954, 2.465],
                                  [39.128, 216.172],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-291.494, -147.945],
                                  [-58.747, 2.144],
                                  [40.622, 217.789],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.579, -150.96],
                                  [-61.409, 1.834],
                                  [42.073, 219.321],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-297.442, -153.83],
                                  [-63.907, 1.545],
                                  [43.427, 220.725],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.144, -156.533],
                                  [-66.231, 1.277],
                                  [44.693, 222.087],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.729, -159.049],
                                  [-68.455, 1.019],
                                  [45.906, 223.363],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-305.154, -161.44],
                                  [-70.558, 0.773],
                                  [47.085, 224.553],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-307.419, -163.717],
                                  [-72.518, 0.547],
                                  [48.156, 225.701],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-309.618, -165.859],
                                  [-74.378, 0.332],
                                  [49.184, 226.763],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-311.648, -167.886],
                                  [-76.085, 0.137],
                                  [50.114, 227.781],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-313.57, -169.788],
                                  [-77.744, -0.058],
                                  [51.042, 228.756],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-315.384, -171.565],
                                  [-79.323, -0.241],
                                  [51.936, 229.688],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-317.143, -173.281],
                                  [-80.75, -0.405],
                                  [52.713, 230.533],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-318.731, -174.871],
                                  [-82.148, -0.568],
                                  [53.508, 231.335],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-320.317, -176.41],
                                  [-83.457, -0.721],
                                  [54.248, 232.136],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-321.743, -177.823],
                                  [-84.686, -0.863],
                                  [54.944, 232.851],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-323.104, -179.164],
                                  [-85.835, -0.995],
                                  [55.585, 233.522],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-324.41, -180.4],
                                  [-86.893, -1.117],
                                  [56.182, 234.192],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.608, -181.617],
                                  [-87.935, -1.239],
                                  [56.777, 234.775],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-326.751, -182.729],
                                  [-88.886, -1.351],
                                  [57.327, 235.357],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-327.829, -183.768],
                                  [-89.768, -1.452],
                                  [57.822, 235.896],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-328.852, -184.746],
                                  [-90.632, -1.553],
                                  [58.326, 236.39],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.768, -185.67],
                                  [-91.406, -1.644],
                                  [58.775, 236.884],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-330.628, -186.523],
                                  [-92.12, -1.725],
                                  [59.17, 237.29],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-331.486, -187.323],
                                  [-92.807, -1.806],
                                  [59.573, 237.739],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-332.227, -188.061],
                                  [-93.424, -1.877],
                                  [59.921, 238.101],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-332.912, -188.736],
                                  [-94.023, -1.948],
                                  [60.277, 238.461],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-333.596, -189.349],
                                  [-94.552, -2.008],
                                  [60.569, 238.778],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-334.172, -189.962],
                                  [-95.054, -2.069],
                                  [60.879, 239.093],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-334.745, -190.513],
                                  [-95.496, -2.119],
                                  [61.125, 239.365],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-335.211, -191.002],
                                  [-95.92, -2.17],
                                  [61.379, 239.592],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-335.666, -191.428],
                                  [-96.274, -2.21],
                                  [61.578, 239.819],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-336.065, -191.801],
                                  [-96.62, -2.25],
                                  [61.786, 240.044],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-336.462, -192.165],
                                  [-96.887, -2.281],
                                  [61.929, 240.226],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-336.752, -192.477],
                                  [-97.155, -2.311],
                                  [62.081, 240.363],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.04, -192.726],
                                  [-97.397, -2.341],
                                  [62.231, 240.499],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.273, -192.965],
                                  [-97.577, -2.361],
                                  [62.337, 240.635],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.451, -193.152],
                                  [-97.75, -2.381],
                                  [62.44, 240.726],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.618, -193.329],
                                  [-97.853, -2.392],
                                  [62.49, 240.816],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.74, -193.454],
                                  [-97.991, -2.412],
                                  [62.591, 240.863],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.85, -193.516],
                                  [-98.016, -2.412],
                                  [62.585, 240.908],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.905, -193.578],
                                  [-98.085, -2.422],
                                  [62.64, 240.953],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.84, -193.517],
                                  [-98.088, -2.421],
                                  [62.65, 240.894],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.704, -193.428],
                                  [-98.017, -2.346],
                                  [62.614, 240.6],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.423, -193.154],
                                  [-97.98, -2.216],
                                  [62.646, 240.133],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-337.015, -192.853],
                                  [-97.83, -2.074],
                                  [62.64, 239.492],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-336.529, -192.419],
                                  [-97.709, -1.867],
                                  [62.603, 238.613],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-335.847, -191.843],
                                  [-97.513, -1.595],
                                  [62.586, 237.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-335.02, -191.188],
                                  [-97.245, -1.257],
                                  [62.588, 236.042],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-334, -190.318],
                                  [-96.961, -0.844],
                                  [62.562, 234.347],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-332.827, -189.315],
                                  [-96.646, -0.417],
                                  [62.559, 232.356],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-331.452, -188.15],
                                  [-96.239, 0.14],
                                  [62.534, 230.025],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-329.817, -186.77],
                                  [-95.756, 0.826],
                                  [62.494, 227.289],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-327.907, -185.174],
                                  [-95.171, 1.58],
                                  [62.499, 224.085],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-325.776, -183.341],
                                  [-94.543, 2.423],
                                  [62.501, 220.43],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-323.258, -181.217],
                                  [-93.741, 3.451],
                                  [62.509, 216.251],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-320.396, -178.791],
                                  [-92.834, 4.623],
                                  [62.53, 211.397],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-317.124, -175.987],
                                  [-91.78, 5.951],
                                  [62.589, 205.921],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-313.387, -172.749],
                                  [-90.574, 7.427],
                                  [62.643, 199.644],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-309.105, -169.022],
                                  [-89.13, 9.23],
                                  [62.772, 192.418],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-304.163, -164.704],
                                  [-87.424, 11.251],
                                  [62.968, 184.09],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-298.434, -159.669],
                                  [-85.419, 13.577],
                                  [63.264, 174.601],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-291.85, -153.789],
                                  [-82.989, 16.318],
                                  [63.673, 163.638],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.267, -146.936],
                                  [-80.01, 19.494],
                                  [64.332, 151.011],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-275.526, -138.932],
                                  [-76.452, 23.177],
                                  [65.224, 136.483],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.512, -129.599],
                                  [-72.144, 27.452],
                                  [66.475, 119.95],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.192, -118.873],
                                  [-66.974, 32.333],
                                  [68.206, 101.44],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.832, -106.859],
                                  [-60.873, 37.751],
                                  [70.549, 81.308],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-228.909, -93.917],
                                  [-53.972, 43.568],
                                  [73.526, 60.346],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.995, -80.628],
                                  [-46.494, 49.394],
                                  [77.039, 39.653],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.657, -67.566],
                                  [-38.793, 55.118],
                                  [81.034, 20.099],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.269, -55.196],
                                  [-31.14, 60.499],
                                  [85.294, 2.279],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.018, -43.652],
                                  [-23.69, 65.489],
                                  [89.625, -13.696],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.846, -33],
                                  [-16.586, 69.999],
                                  [94.027, -27.842],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.65, -23.229],
                                  [-9.807, 74.13],
                                  [98.323, -40.444],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.288, -14.261],
                                  [-3.388, 77.869],
                                  [102.49, -51.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.695, -5.984],
                                  [2.689, 81.305],
                                  [106.572, -61.532],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.754, 1.672],
                                  [8.452, 84.456],
                                  [110.517, -70.456],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.36, 8.706],
                                  [13.884, 87.366],
                                  [114.309, -78.498],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.462, 15.299],
                                  [19.043, 90.052],
                                  [117.953, -85.751],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.009, 21.378],
                                  [23.928, 92.517],
                                  [121.471, -92.361],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-125.893, 27.103],
                                  [28.554, 94.842],
                                  [124.82, -98.367],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-122.139, 32.428],
                                  [32.957, 96.989],
                                  [128.039, -103.846],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-118.646, 37.454],
                                  [37.137, 98.955],
                                  [131.151, -108.893],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-115.442, 42.136],
                                  [41.119, 100.869],
                                  [134.122, -113.539],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-112.473, 46.555],
                                  [44.89, 102.604],
                                  [137.002, -117.811],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-109.709, 50.73],
                                  [48.481, 104.246],
                                  [139.72, -121.779],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-107.12, 54.682],
                                  [51.922, 105.793],
                                  [142.351, -125.467],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-104.72, 58.368],
                                  [55.186, 107.268],
                                  [144.846, -128.86],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-102.482, 61.889],
                                  [58.306, 108.649],
                                  [147.264, -132.066],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-100.357, 65.185],
                                  [61.278, 109.959],
                                  [149.597, -135.023],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.426, 68.359],
                                  [64.138, 111.198],
                                  [151.784, -137.779],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.576, 71.327],
                                  [66.819, 112.327],
                                  [153.941, -140.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-94.872, 74.131],
                                  [69.401, 113.442],
                                  [155.945, -142.73],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-93.225, 76.813],
                                  [71.864, 114.471],
                                  [157.895, -144.97],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-91.709, 79.371],
                                  [74.199, 115.446],
                                  [159.766, -147.096],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-90.291, 81.766],
                                  [76.448, 116.375],
                                  [161.523, -149.07],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-88.959, 84.038],
                                  [78.586, 117.236],
                                  [163.219, -150.95],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-87.672, 86.17],
                                  [80.618, 118.066],
                                  [164.877, -152.656],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-86.475, 88.218],
                                  [82.558, 118.89],
                                  [166.393, -154.293],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-85.358, 90.166],
                                  [84.365, 119.584],
                                  [167.89, -155.821],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-84.303, 91.998],
                                  [86.101, 120.334],
                                  [169.306, -157.242],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-83.31, 93.722],
                                  [87.802, 120.954],
                                  [170.64, -158.571],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-82.415, 95.393],
                                  [89.352, 121.606],
                                  [171.891, -159.832],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-81.516, 96.941],
                                  [90.866, 122.192],
                                  [173.12, -161.026],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-80.712, 98.389],
                                  [92.265, 122.733],
                                  [174.264, -162.129],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-79.926, 99.762],
                                  [93.625, 123.284],
                                  [175.348, -163.165],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-79.195, 101.074],
                                  [94.893, 123.754],
                                  [176.371, -164.135],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-78.532, 102.311],
                                  [96.045, 124.233],
                                  [177.37, -165.038],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-77.907, 103.45],
                                  [97.181, 124.669],
                                  [178.27, -165.889],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-77.311, 104.551],
                                  [98.261, 125.077],
                                  [179.145, -166.674],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-76.727, 105.555],
                                  [99.249, 125.443],
                                  [179.983, -167.43],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-76.233, 106.484],
                                  [100.18, 125.804],
                                  [180.735, -168.084],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-75.75, 107.378],
                                  [101.031, 126.137],
                                  [181.449, -168.747],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-75.317, 108.198],
                                  [101.851, 126.466],
                                  [182.1, -169.319],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.933, 108.983],
                                  [102.614, 126.729],
                                  [182.715, -169.864],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.535, 109.67],
                                  [103.282, 127.012],
                                  [183.304, -170.343],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.184, 110.323],
                                  [103.957, 127.243],
                                  [183.832, -170.794],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.868, 110.927],
                                  [104.512, 127.495],
                                  [184.309, -171.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.574, 111.473],
                                  [105.06, 127.681],
                                  [184.737, -171.61],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.326, 111.946],
                                  [105.564, 127.864],
                                  [185.154, -171.951],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.087, 112.385],
                                  [106.023, 128.019],
                                  [185.508, -172.263],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.856, 112.777],
                                  [106.363, 128.196],
                                  [185.826, -172.509],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.683, 113.11],
                                  [106.733, 128.345],
                                  [186.144, -172.764],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.505, 113.435],
                                  [107.047, 128.453],
                                  [186.389, -172.952],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.41, 113.689],
                                  [107.266, 128.534],
                                  [186.573, -173.148],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.259, 113.909],
                                  [107.516, 128.611],
                                  [186.784, -173.316],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.153, 114.059],
                                  [107.672, 128.686],
                                  [186.933, -173.391],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.116, 114.239],
                                  [107.835, 128.721],
                                  [187.035, -173.499],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.06, 114.311],
                                  [107.928, 128.791],
                                  [187.114, -173.552],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.517, 0.517, 0.517, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.517, 0.517, 0.517, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [124.706, 64.977],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.718, 65.073],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.721, 65.18],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.717, 65.372],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.734, 65.656],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.759, 66.02],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.762, 66.447],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.78, 66.952],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.839, 67.601],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.863, 68.316],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.939, 69.152],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.05, 70.155],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.183, 71.283],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.365, 72.602],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.622, 74.065],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.96, 75.794],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.429, 77.723],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.029, 79.931],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.854, 82.472],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.984, 85.366],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.548, 88.604],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.643, 92.214],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.5, 96.182],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.408, 100.324],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.556, 104.302],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.981, 107.567],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.316, 109.526],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.705, 109.867],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.383, 108.633],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.738, 106.415],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.676, 103.724],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.36, 101.045],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.086, 98.511],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.052, 96.25],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.54, 94.272],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.672, 92.529],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.556, 90.981],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.272, 89.571],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.897, 88.306],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.463, 87.106],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.011, 85.961],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.468, 84.892],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.995, 83.748],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.483, 82.65],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.988, 81.525],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.514, 80.347],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.074, 79.102],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.662, 77.825],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.268, 76.466],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.941, 75.064],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.63, 73.646],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.343, 72.136],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.163, 70.646],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.945, 69.123],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.821, 67.634],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.675, 66.181],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.592, 64.747],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.517, 63.338],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.493, 62.065],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.482, 60.797],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.436, 59.649],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.469, 58.513],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.514, 57.461],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.501, 56.491],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.599, 55.516],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.606, 54.672],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.666, 53.816],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.737, 53.029],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.803, 52.27],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.861, 51.587],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.912, 50.924],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.973, 50.286],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.043, 49.661],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.103, 49.098],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.195, 48.549],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.237, 48.027],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.312, 47.514],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.35, 47.056],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.398, 46.605],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.46, 46.164],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.564, 45.729],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.594, 45.319],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.659, 44.949],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.727, 44.594],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.738, 44.251],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.805, 43.898],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.843, 43.609],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.899, 43.273],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.932, 42.991],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.975, 42.71],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.983, 42.432],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.93, 42.169],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.81, 41.879],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.613, 41.616],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.369, 41.336],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.004, 41.05],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.55, 40.769],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.012, 40.466],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.347, 40.155],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.564, 39.83],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.683, 39.483],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.641, 39.115],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.428, 38.718],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.055, 38.278],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.49, 37.789],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.668, 37.233],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.598, 36.582],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.25, 35.819],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.495, 34.898],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.341, 33.753],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.634, 32.308],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.325, 30.437],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.245, 27.966],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.044, 25.349],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.326, 24.762],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.908, 23.309],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.846, 20.884],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.5, 17.653],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.039, 13.987],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.015, 10.298],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.458, 6.885],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.932, 3.909],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.549, 1.407],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.748, -0.662],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.814, -2.345],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.991, -3.702],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.439, -4.799],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.324, -5.671],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.716, -6.366],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.716, -6.92],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.354, -7.357],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.698, -7.689],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.794, -7.96],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.648, -8.159],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.331, -8.311],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.8, -8.408],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.128, -8.493],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.315, -8.531],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.341, -8.557],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.26, -8.553],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.069, -8.542],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.763, -8.527],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.359, -8.48],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.871, -8.432],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.295, -8.388],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.648, -8.336],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.902, -8.292],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.096, -8.228],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.21, -8.143],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.283, -8.108],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.277, -8.03],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.241, -7.978],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.126, -7.91],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.962, -7.85],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.736, -7.792],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.496, -7.737],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.192, -7.684],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.847, -7.639],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.465, -7.577],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.017, -7.52],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.556, -7.487],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.056, -7.441],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.519, -7.409],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.943, -7.359],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.352, -7.337],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.701, -7.291],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.043, -7.275],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.342, -7.238],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.609, -7.218],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.843, -7.176],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.07, -7.181],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.265, -7.16],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.434, -7.137],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.57, -7.127],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.688, -7.107],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.784, -7.115],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.87, -7.096],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.916, -7.114],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.95, -7.096],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.954, -7.093],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.934, -7.081],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.98, -7.1],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.027, -7.038],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.047, -7.033],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.148, -7.005],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.253, -6.975],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.35, -6.932],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.475, -6.849],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.615, -6.79],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.779, -6.696],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.989, -6.574],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.179, -6.45],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.428, -6.321],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.643, -6.125],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.917, -5.921],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.165, -5.664],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.406, -5.376],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.666, -4.979],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.848, -4.519],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.9, -3.894],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.823, -3.093],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.44, -2.041],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.65, -0.6],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.142, 1.463],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.525, 4.428],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.267, 8.668],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.881, 14.56],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.08, 22.096],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.147, 31.042],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.831, 40.617],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.939, 50.152],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.065, 59.137],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.542, 67.38],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.597, 74.751],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.727, 81.254],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.574, 79.718],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.486, 78.114],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.635, 76.558],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.13, 75.076],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.078, 73.72],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.551, 72.499],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.628, 71.338],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.346, 70.376],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.738, 69.467],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.843, 68.666],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.715, 67.962],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.335, 67.371],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.76, 66.831],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.994, 66.367],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.049, 66],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.919, 65.641],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.639, 65.378],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.229, 65.12],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.675, 64.894],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.998, 64.737],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.2, 64.589],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.294, 64.485],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.273, 64.424],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.129, 64.31],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.901, 64.29],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.603, 64.262],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.17, 64.261],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.696, 64.243],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.103, 64.239],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.461, 64.272],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.727, 64.273],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.898, 64.289],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.02, 64.331],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.09, 64.376],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.083, 64.374],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117, 64.442],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.852, 64.455],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.656, 64.525],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.401, 64.568],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.078, 64.602],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.731, 64.653],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.291, 64.686],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.825, 64.708],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.317, 64.756],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.76, 64.788],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.114, 64.807],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.478, 64.853],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.782, 64.88],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.999, 64.883],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.246, 64.888],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.406, 64.912],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.55, 64.937],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.644, 64.966],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [124.706, 64.977],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.718, 65.073],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.721, 65.18],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.717, 65.372],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.734, 65.656],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.759, 66.02],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.762, 66.447],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.78, 66.952],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.839, 67.601],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.863, 68.316],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.939, 69.152],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.05, 70.155],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.183, 71.283],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.365, 72.602],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.622, 74.065],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.96, 75.794],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.429, 77.723],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.029, 79.931],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.854, 82.472],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.984, 85.366],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.548, 88.604],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.643, 92.214],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.5, 96.182],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.408, 100.324],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.556, 104.302],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.981, 107.567],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.316, 109.526],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.705, 109.867],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.383, 108.633],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.738, 106.415],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.676, 103.724],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.36, 101.045],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.086, 98.511],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.052, 96.25],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.54, 94.272],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.672, 92.529],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.556, 90.981],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.272, 89.571],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.897, 88.306],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.463, 87.106],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.011, 85.961],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.468, 84.892],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.995, 83.748],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.483, 82.65],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.988, 81.525],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.514, 80.347],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.074, 79.102],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.662, 77.825],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.268, 76.466],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.941, 75.064],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.63, 73.646],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.343, 72.136],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.163, 70.646],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.945, 69.123],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.821, 67.634],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.675, 66.181],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.592, 64.747],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.517, 63.338],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.493, 62.065],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.482, 60.797],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.436, 59.649],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.469, 58.513],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.514, 57.461],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.501, 56.491],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.599, 55.516],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.606, 54.672],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.666, 53.816],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.737, 53.029],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.803, 52.27],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.861, 51.587],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.912, 50.924],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.973, 50.286],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.043, 49.661],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.103, 49.098],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.195, 48.549],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.237, 48.027],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.312, 47.514],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.35, 47.056],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.398, 46.605],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.46, 46.164],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.564, 45.729],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.594, 45.319],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.659, 44.949],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.727, 44.594],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.738, 44.251],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.805, 43.898],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.843, 43.609],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.899, 43.273],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.932, 42.991],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.975, 42.71],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.983, 42.432],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.93, 42.169],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.81, 41.879],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.613, 41.616],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.369, 41.336],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.004, 41.05],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.55, 40.769],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.012, 40.466],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.347, 40.155],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.564, 39.83],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.683, 39.483],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.641, 39.115],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.428, 38.718],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.055, 38.278],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.49, 37.789],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.668, 37.233],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.598, 36.582],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.25, 35.819],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.495, 34.898],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.341, 33.753],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.634, 32.308],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.325, 30.437],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.245, 27.966],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.044, 25.349],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.326, 24.762],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.908, 23.309],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.846, 20.884],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.5, 17.653],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.039, 13.987],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.015, 10.298],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.458, 6.885],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.932, 3.909],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.549, 1.407],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.748, -0.662],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.814, -2.345],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-31.991, -3.702],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.439, -4.799],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.324, -5.671],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-46.716, -6.366],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.716, -6.92],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.354, -7.357],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.698, -7.689],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-60.794, -7.96],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.648, -8.159],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.331, -8.311],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.8, -8.408],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.128, -8.493],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-73.315, -8.531],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.341, -8.557],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.26, -8.553],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.069, -8.542],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.763, -8.527],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.359, -8.48],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.871, -8.432],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.295, -8.388],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.648, -8.336],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.902, -8.292],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.096, -8.228],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.21, -8.143],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.283, -8.108],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-92.277, -8.03],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.241, -7.978],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.126, -7.91],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.962, -7.85],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.736, -7.792],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.496, -7.737],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.192, -7.684],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.847, -7.639],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.465, -7.577],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.017, -7.52],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.556, -7.487],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.056, -7.441],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.519, -7.409],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.943, -7.359],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.352, -7.337],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.701, -7.291],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.043, -7.275],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.342, -7.238],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.609, -7.218],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.843, -7.176],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.07, -7.181],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.265, -7.16],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.434, -7.137],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.57, -7.127],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.688, -7.107],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.784, -7.115],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.87, -7.096],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.916, -7.114],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.95, -7.096],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.954, -7.093],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.934, -7.081],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.98, -7.1],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.027, -7.038],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.047, -7.033],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.148, -7.005],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.253, -6.975],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.35, -6.932],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.475, -6.849],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.615, -6.79],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.779, -6.696],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.989, -6.574],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.179, -6.45],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.428, -6.321],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.643, -6.125],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.917, -5.921],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.165, -5.664],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.406, -5.376],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.666, -4.979],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.848, -4.519],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.9, -3.894],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.823, -3.093],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.44, -2.041],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.65, -0.6],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.142, 1.463],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.525, 4.428],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.267, 8.668],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.881, 14.56],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-82.08, 22.096],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.147, 31.042],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-58.831, 40.617],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-45.939, 50.152],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.065, 59.137],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-20.542, 67.38],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.597, 74.751],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.727, 81.254],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.574, 79.718],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.486, 78.114],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.635, 76.558],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.13, 75.076],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.078, 73.72],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.551, 72.499],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.628, 71.338],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [54.346, 70.376],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.738, 69.467],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [62.843, 68.666],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.715, 67.962],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.335, 67.371],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.76, 66.831],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [76.994, 66.367],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.049, 66],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.919, 65.641],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.639, 65.378],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.229, 65.12],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.675, 64.894],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.998, 64.737],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.2, 64.589],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.294, 64.485],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.273, 64.424],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.129, 64.31],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.901, 64.29],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.603, 64.262],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.17, 64.261],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.696, 64.243],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.103, 64.239],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.461, 64.272],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.727, 64.273],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.898, 64.289],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.02, 64.331],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.09, 64.376],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.083, 64.374],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117, 64.442],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.852, 64.455],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.656, 64.525],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.401, 64.568],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.078, 64.602],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.731, 64.653],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.291, 64.686],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.825, 64.708],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.317, 64.756],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.76, 64.788],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.114, 64.807],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.478, 64.853],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.782, 64.88],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.999, 64.883],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.246, 64.888],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.406, 64.912],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.55, 64.937],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.644, 64.966],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 7',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 7,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.006, 128.792],
                                  [119.994, 133.597],
                                  [206.361, -159.145],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.952, 128.79],
                                  [120.081, 133.59],
                                  [206.164, -159.001],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.842, 128.781],
                                  [120.191, 133.547],
                                  [205.87, -158.769],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.675, 128.769],
                                  [120.358, 133.501],
                                  [205.383, -158.389],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.515, 128.754],
                                  [120.586, 133.448],
                                  [204.827, -157.892],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.236, 128.735],
                                  [120.898, 133.388],
                                  [204.049, -157.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.964, 128.713],
                                  [121.237, 133.292],
                                  [203.111, -156.423],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.574, 128.691],
                                  [121.66, 133.189],
                                  [201.978, -155.456],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.129, 128.665],
                                  [122.205, 133.111],
                                  [200.622, -154.339],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-57.629, 128.639],
                                  [122.772, 132.962],
                                  [199.077, -152.981],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-57.012, 128.615],
                                  [123.49, 132.772],
                                  [197.249, -151.388],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-56.342, 128.586],
                                  [124.328, 132.602],
                                  [195.136, -149.584],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-55.492, 128.562],
                                  [125.279, 132.393],
                                  [192.712, -147.449],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-54.589, 128.54],
                                  [126.384, 132.136],
                                  [189.915, -145.018],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-53.446, 128.524],
                                  [127.663, 131.835],
                                  [186.746, -142.19],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-52.25, 128.519],
                                  [129.129, 131.489],
                                  [183.115, -138.97],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-50.751, 128.522],
                                  [130.832, 131.154],
                                  [178.963, -135.297],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-49.077, 128.543],
                                  [132.778, 130.7],
                                  [174.168, -131.018],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.163, 128.58],
                                  [135.032, 130.192],
                                  [168.707, -126.067],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-44.949, 128.649],
                                  [137.656, 129.623],
                                  [162.428, -120.357],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-42.31, 128.753],
                                  [140.74, 128.953],
                                  [155.158, -113.761],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.247, 128.901],
                                  [144.285, 128.177],
                                  [146.809, -106.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.76, 129.102],
                                  [148.392, 127.287],
                                  [137.175, -97.139],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-31.663, 129.374],
                                  [153.18, 126.245],
                                  [126.086, -86.81],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.018, 129.723],
                                  [158.623, 125.045],
                                  [113.572, -75.1],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.827, 130.144],
                                  [164.65, 123.717],
                                  [99.862, -62.204],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.404, 130.627],
                                  [171.019, 122.267],
                                  [85.366, -48.591],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-10.998, 131.138],
                                  [177.376, 120.84],
                                  [70.813, -34.909],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-5.737, 131.663],
                                  [183.527, 119.356],
                                  [56.794, -21.757],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.933, 132.168],
                                  [189.193, 117.916],
                                  [43.755, -9.533],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [3.411, 132.659],
                                  [194.337, 116.499],
                                  [31.875, 1.532],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.295, 133.12],
                                  [198.959, 115.195],
                                  [21.115, 11.514],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.779, 133.554],
                                  [203.12, 113.907],
                                  [11.382, 20.432],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.862, 133.976],
                                  [206.817, 112.669],
                                  [2.563, 28.38],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [16.542, 134.381],
                                  [210.085, 111.439],
                                  [-5.407, 35.571],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [18.943, 134.778],
                                  [213.045, 110.241],
                                  [-12.676, 41.976],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [21.123, 135.164],
                                  [215.689, 109.039],
                                  [-19.272, 47.772],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [22.957, 135.546],
                                  [218.03, 107.804],
                                  [-25.385, 52.964],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.63, 135.923],
                                  [220.147, 106.588],
                                  [-30.945, 57.638],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [26.075, 136.313],
                                  [222.048, 105.326],
                                  [-36.133, 61.88],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [27.354, 136.697],
                                  [223.756, 104.04],
                                  [-40.859, 65.659],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [28.4, 137.094],
                                  [225.206, 102.765],
                                  [-45.277, 69.073],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [29.336, 137.504],
                                  [226.557, 101.361],
                                  [-49.377, 72.093],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [30.096, 137.929],
                                  [227.709, 99.963],
                                  [-53.195, 74.803],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [30.679, 138.366],
                                  [228.695, 98.517],
                                  [-56.734, 77.161],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [31.144, 138.818],
                                  [229.539, 96.997],
                                  [-60.042, 79.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [31.43, 139.296],
                                  [230.216, 95.377],
                                  [-63.096, 81.041],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [31.66, 139.786],
                                  [230.814, 93.731],
                                  [-65.945, 82.576],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [31.713, 140.303],
                                  [231.246, 91.989],
                                  [-68.602, 83.847],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [31.656, 140.844],
                                  [231.581, 90.208],
                                  [-71.054, 84.886],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [31.498, 141.408],
                                  [231.826, 88.414],
                                  [-73.381, 85.696],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [31.25, 141.973],
                                  [231.948, 86.525],
                                  [-75.526, 86.362],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [30.925, 142.569],
                                  [232.041, 84.659],
                                  [-77.47, 86.897],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [30.601, 143.155],
                                  [232.057, 82.777],
                                  [-79.317, 87.266],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [30.168, 143.761],
                                  [232.046, 80.941],
                                  [-81.032, 87.549],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [29.765, 144.362],
                                  [231.979, 79.15],
                                  [-82.617, 87.765],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [29.402, 144.953],
                                  [231.948, 77.379],
                                  [-84.098, 87.973],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [28.963, 145.549],
                                  [231.834, 75.661],
                                  [-85.457, 88.079],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [28.577, 146.102],
                                  [231.809, 74.088],
                                  [-86.761, 88.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [28.247, 146.677],
                                  [231.745, 72.536],
                                  [-87.907, 88.324],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [27.91, 147.201],
                                  [231.664, 71.118],
                                  [-89.025, 88.425],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [27.565, 147.72],
                                  [231.611, 69.721],
                                  [-90.033, 88.557],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [27.271, 148.208],
                                  [231.6, 68.431],
                                  [-90.998, 88.633],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [27.025, 148.69],
                                  [231.542, 67.226],
                                  [-91.896, 88.739],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [26.763, 149.139],
                                  [231.548, 66.034],
                                  [-92.709, 88.818],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [26.542, 149.581],
                                  [231.499, 64.983],
                                  [-93.483, 88.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [26.298, 149.989],
                                  [231.464, 63.936],
                                  [-94.193, 88.984],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [26.09, 150.39],
                                  [231.46, 62.963],
                                  [-94.867, 89.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.852, 150.757],
                                  [231.42, 62.031],
                                  [-95.477, 89.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.707, 151.117],
                                  [231.422, 61.184],
                                  [-96.052, 89.184],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.466, 151.471],
                                  [231.368, 60.373],
                                  [-96.611, 89.202],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.312, 151.79],
                                  [231.352, 59.596],
                                  [-97.108, 89.21],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.12, 152.103],
                                  [231.293, 58.833],
                                  [-97.526, 89.234],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.95, 152.381],
                                  [231.252, 58.145],
                                  [-97.928, 89.226],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.739, 152.684],
                                  [231.212, 57.485],
                                  [-98.315, 89.184],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.609, 152.921],
                                  [231.157, 56.849],
                                  [-98.64, 89.169],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.434, 153.183],
                                  [231.101, 56.237],
                                  [-98.951, 89.116],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.213, 153.41],
                                  [230.995, 55.694],
                                  [-99.231, 89.023],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.07, 153.633],
                                  [230.918, 55.155],
                                  [-99.466, 88.935],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.879, 153.819],
                                  [230.837, 54.636],
                                  [-99.686, 88.821],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.701, 154.033],
                                  [230.767, 54.135],
                                  [-99.846, 88.663],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.536, 154.211],
                                  [230.659, 53.651],
                                  [-100.023, 88.57],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.321, 154.386],
                                  [230.547, 53.231],
                                  [-100.155, 88.384],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.179, 154.524],
                                  [230.458, 52.825],
                                  [-100.225, 88.198],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [22.985, 154.658],
                                  [230.316, 52.435],
                                  [-100.345, 88.027],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [22.739, 154.789],
                                  [230.166, 52.043],
                                  [-100.388, 87.808],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [22.565, 154.916],
                                  [230.039, 51.726],
                                  [-100.433, 87.553],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [22.4, 155.04],
                                  [229.92, 51.359],
                                  [-100.465, 87.297],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [22.18, 155.128],
                                  [229.744, 51.052],
                                  [-100.436, 87.052],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [21.969, 155.212],
                                  [229.575, 50.756],
                                  [-100.394, 86.756],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [21.717, 155.231],
                                  [229.335, 50.457],
                                  [-100.305, 86.505],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [21.362, 155.123],
                                  [229.014, 50.167],
                                  [-100.246, 86.297],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [20.869, 154.9],
                                  [228.498, 49.844],
                                  [-100.044, 86.133],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [20.286, 154.488],
                                  [227.903, 49.531],
                                  [-99.857, 86.081],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [19.5, 153.948],
                                  [227.132, 49.199],
                                  [-99.588, 86.073],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [18.609, 153.217],
                                  [226.172, 48.847],
                                  [-99.237, 86.144],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [17.577, 152.311],
                                  [225.057, 48.491],
                                  [-98.851, 86.292],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [16.293, 151.201],
                                  [223.707, 48.1],
                                  [-98.348, 86.554],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.853, 149.867],
                                  [222.141, 47.69],
                                  [-97.76, 86.896],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.222, 148.281],
                                  [220.347, 47.261],
                                  [-97.102, 87.316],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.288, 146.428],
                                  [218.276, 46.798],
                                  [-96.325, 87.885],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.085, 144.24],
                                  [215.901, 46.302],
                                  [-95.413, 88.604],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.592, 141.724],
                                  [213.196, 45.772],
                                  [-94.395, 89.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [3.731, 138.83],
                                  [210.1, 45.196],
                                  [-93.207, 90.458],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.467, 135.511],
                                  [206.585, 44.573],
                                  [-91.862, 91.664],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-3.251, 131.655],
                                  [202.564, 43.889],
                                  [-90.324, 93.058],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.498, 127.247],
                                  [197.961, 43.132],
                                  [-88.529, 94.71],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.409, 122.15],
                                  [192.723, 42.301],
                                  [-86.505, 96.657],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.985, 116.266],
                                  [186.673, 41.37],
                                  [-84.139, 98.935],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.437, 109.505],
                                  [179.724, 40.327],
                                  [-81.42, 101.582],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-31.877, 101.608],
                                  [171.685, 39.144],
                                  [-78.25, 104.706],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-40.503, 92.473],
                                  [162.402, 37.809],
                                  [-74.591, 108.344],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-50.527, 81.875],
                                  [151.686, 36.296],
                                  [-70.358, 112.57],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.999, 69.664],
                                  [139.362, 34.58],
                                  [-65.476, 117.494],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.971, 55.865],
                                  [125.407, 32.66],
                                  [-59.944, 123.118],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-89.297, 40.604],
                                  [110.056, 30.566],
                                  [-53.831, 129.337],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-104.459, 24.43],
                                  [93.837, 28.364],
                                  [-47.342, 135.938],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.743, 8.191],
                                  [77.529, 26.161],
                                  [-40.79, 142.634],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.455, -7.452],
                                  [61.86, 24.049],
                                  [-34.481, 149.065],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.199, -22.048],
                                  [47.299, 22.093],
                                  [-28.597, 155.082],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.749, -35.423],
                                  [34.01, 20.305],
                                  [-23.203, 160.612],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.132, -47.513],
                                  [21.976, 18.683],
                                  [-18.274, 165.652],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.478, -58.394],
                                  [11.153, 17.227],
                                  [-13.825, 170.197],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.838, -68.276],
                                  [1.362, 15.908],
                                  [-9.786, 174.319],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.369, -77.262],
                                  [-7.503, 14.714],
                                  [-6.108, 178.088],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.135, -85.437],
                                  [-15.594, 13.616],
                                  [-2.708, 181.538],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.282, -92.951],
                                  [-22.931, 12.627],
                                  [0.365, 184.704],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-221.846, -99.841],
                                  [-29.714, 11.708],
                                  [3.245, 187.622],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.901, -106.133],
                                  [-35.91, 10.87],
                                  [5.881, 190.289],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-233.534, -112.002],
                                  [-41.657, 10.088],
                                  [8.346, 192.778],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-238.756, -117.434],
                                  [-46.945, 9.374],
                                  [10.616, 195.124],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.618, -122.491],
                                  [-51.888, 8.702],
                                  [12.763, 197.291],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-248.155, -127.201],
                                  [-56.45, 8.084],
                                  [14.738, 199.314],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-252.379, -131.598],
                                  [-60.707, 7.507],
                                  [16.589, 201.193],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-256.354, -135.733],
                                  [-64.722, 6.958],
                                  [18.367, 202.964],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-260.115, -139.582],
                                  [-68.446, 6.45],
                                  [20.02, 204.663],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-263.6, -143.195],
                                  [-71.918, 5.982],
                                  [21.537, 206.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.946, -146.608],
                                  [-75.225, 5.529],
                                  [23.028, 207.696],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-270.017, -149.833],
                                  [-78.281, 5.116],
                                  [24.382, 209.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-272.985, -152.835],
                                  [-81.186, 4.717],
                                  [25.698, 210.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-275.739, -155.687],
                                  [-83.915, 4.344],
                                  [26.926, 211.662],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.342, -158.377],
                                  [-86.457, 3.999],
                                  [28.078, 212.85],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-280.829, -160.88],
                                  [-88.886, 3.668],
                                  [29.179, 213.963],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-283.165, -163.259],
                                  [-91.177, 3.35],
                                  [30.254, 215.003],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-285.349, -165.526],
                                  [-93.32, 3.059],
                                  [31.227, 216.005],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.468, -167.655],
                                  [-95.351, 2.782],
                                  [32.162, 216.933],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-289.422, -169.672],
                                  [-97.221, 2.531],
                                  [33.008, 217.823],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-291.273, -171.565],
                                  [-99.029, 2.28],
                                  [33.853, 218.676],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-293.023, -173.333],
                                  [-100.751, 2.044],
                                  [34.671, 219.491],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.72, -175.039],
                                  [-102.312, 1.833],
                                  [35.375, 220.231],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-296.251, -176.62],
                                  [-103.837, 1.623],
                                  [36.102, 220.934],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-297.781, -178.152],
                                  [-105.264, 1.426],
                                  [36.777, 221.635],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-299.158, -179.559],
                                  [-106.605, 1.242],
                                  [37.413, 222.262],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.469, -180.891],
                                  [-107.861, 1.071],
                                  [37.998, 222.85],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-301.729, -182.124],
                                  [-109.019, 0.914],
                                  [38.543, 223.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.885, -183.331],
                                  [-110.153, 0.757],
                                  [39.086, 223.949],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-303.99, -184.439],
                                  [-111.19, 0.613],
                                  [39.591, 224.46],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-305.029, -185.473],
                                  [-112.154, 0.482],
                                  [40.043, 224.933],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-306.016, -186.444],
                                  [-113.094, 0.352],
                                  [40.506, 225.367],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-306.9, -187.365],
                                  [-113.937, 0.234],
                                  [40.917, 225.801],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-307.732, -188.211],
                                  [-114.72, 0.13],
                                  [41.276, 226.159],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-308.561, -189.008],
                                  [-115.468, 0.026],
                                  [41.645, 226.552],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-309.275, -189.742],
                                  [-116.142, -0.066],
                                  [41.963, 226.871],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-309.937, -190.415],
                                  [-116.794, -0.157],
                                  [42.292, 227.188],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-310.597, -191.024],
                                  [-117.373, -0.235],
                                  [42.556, 227.466],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-311.154, -191.634],
                                  [-117.917, -0.313],
                                  [42.844, 227.744],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-311.708, -192.182],
                                  [-118.401, -0.378],
                                  [43.067, 227.983],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-312.161, -192.668],
                                  [-118.861, -0.443],
                                  [43.3, 228.183],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-312.598, -193.091],
                                  [-119.249, -0.496],
                                  [43.482, 228.383],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-312.983, -193.464],
                                  [-119.627, -0.548],
                                  [43.675, 228.581],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-313.366, -193.825],
                                  [-119.92, -0.587],
                                  [43.803, 228.741],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-313.647, -194.136],
                                  [-120.214, -0.626],
                                  [43.943, 228.862],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-313.926, -194.385],
                                  [-120.474, -0.665],
                                  [44.08, 228.982],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.152, -194.621],
                                  [-120.673, -0.691],
                                  [44.178, 229.101],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.326, -194.808],
                                  [-120.861, -0.717],
                                  [44.275, 229.182],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.485, -194.982],
                                  [-120.977, -0.73],
                                  [44.32, 229.262],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.605, -195.107],
                                  [-121.12, -0.756],
                                  [44.413, 229.303],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.71, -195.169],
                                  [-121.152, -0.756],
                                  [44.404, 229.343],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.763, -195.231],
                                  [-121.224, -0.769],
                                  [44.457, 229.382],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.764, -195.231],
                                  [-121.236, -0.769],
                                  [44.458, 229.382],
                                ],
                                c: false,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.699, -195.17],
                                  [-121.226, -0.768],
                                  [44.471, 229.325],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.565, -195.073],
                                  [-121.147, -0.689],
                                  [44.447, 229.039],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-314.285, -194.791],
                                  [-121.089, -0.559],
                                  [44.497, 228.587],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-313.882, -194.474],
                                  [-120.917, -0.413],
                                  [44.519, 227.967],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-313.401, -194.021],
                                  [-120.759, -0.201],
                                  [44.524, 227.115],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-312.728, -193.421],
                                  [-120.517, 0.076],
                                  [44.559, 225.981],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-311.909, -192.737],
                                  [-120.195, 0.419],
                                  [44.621, 224.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-310.902, -191.829],
                                  [-119.846, 0.841],
                                  [44.672, 222.982],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-309.74, -190.786],
                                  [-119.449, 1.28],
                                  [44.758, 221.053],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-308.38, -189.569],
                                  [-118.945, 1.849],
                                  [44.839, 218.796],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-306.767, -188.129],
                                  [-118.353, 2.548],
                                  [44.925, 216.149],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-304.88, -186.464],
                                  [-117.638, 3.317],
                                  [45.075, 213.047],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-302.774, -184.547],
                                  [-116.856, 4.181],
                                  [45.249, 209.514],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-300.29, -182.329],
                                  [-115.877, 5.228],
                                  [45.456, 205.471],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-297.466, -179.792],
                                  [-114.768, 6.423],
                                  [45.705, 200.782],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-294.239, -176.86],
                                  [-113.473, 7.781],
                                  [46.033, 195.495],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-290.558, -173.479],
                                  [-111.986, 9.292],
                                  [46.4, 189.434],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-286.34, -169.576],
                                  [-110.211, 11.133],
                                  [46.893, 182.464],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-281.479, -165.059],
                                  [-108.117, 13.196],
                                  [47.519, 174.441],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-275.85, -159.785],
                                  [-105.654, 15.577],
                                  [48.322, 165.307],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-269.389, -153.628],
                                  [-102.677, 18.38],
                                  [49.334, 154.766],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-261.954, -146.444],
                                  [-99.043, 21.628],
                                  [50.713, 142.643],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-253.4, -138.046],
                                  [-94.7, 25.394],
                                  [52.465, 128.717],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.62, -128.247],
                                  [-89.452, 29.768],
                                  [54.742, 112.902],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-232.592, -116.976],
                                  [-83.169, 34.765],
                                  [57.683, 95.236],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.577, -104.341],
                                  [-75.785, 40.313],
                                  [61.416, 76.075],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.047, -90.723],
                                  [-67.459, 46.268],
                                  [65.927, 56.183],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.567, -76.726],
                                  [-58.485, 52.24],
                                  [71.053, 36.609],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-183.679, -62.957],
                                  [-49.277, 58.107],
                                  [76.666, 18.182],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.739, -49.912],
                                  [-40.163, 63.619],
                                  [82.483, 1.445],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.924, -37.733],
                                  [-31.33, 68.734],
                                  [88.286, -13.503],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.163, -26.496],
                                  [-22.931, 73.359],
                                  [94.06, -26.701],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.36, -16.182],
                                  [-14.957, 77.591],
                                  [99.626, -38.42],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.373, -6.719],
                                  [-7.427, 81.429],
                                  [104.968, -48.726],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.126, 2.018],
                                  [-0.323, 84.953],
                                  [110.132, -57.941],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-127.51, 10.093],
                                  [6.394, 88.186],
                                  [115.078, -66.17],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-122.426, 17.517],
                                  [12.711, 91.167],
                                  [119.804, -73.569],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-117.815, 24.47],
                                  [18.69, 93.92],
                                  [124.309, -80.222],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-113.632, 30.882],
                                  [24.346, 96.445],
                                  [128.637, -86.274],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-109.776, 36.918],
                                  [29.688, 98.829],
                                  [132.736, -91.759],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-106.263, 42.536],
                                  [34.762, 101.026],
                                  [136.657, -96.754],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-103, 47.834],
                                  [39.571, 103.045],
                                  [140.43, -101.348],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-100.011, 52.77],
                                  [44.147, 105.002],
                                  [144.017, -105.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-97.243, 57.43],
                                  [48.475, 106.784],
                                  [147.479, -109.432],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-94.674, 61.824],
                                  [52.594, 108.466],
                                  [150.742, -113.025],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-92.269, 65.99],
                                  [56.529, 110.05],
                                  [153.887, -116.359],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-90.046, 69.876],
                                  [60.26, 111.561],
                                  [156.869, -119.412],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-87.971, 73.579],
                                  [63.816, 112.974],
                                  [159.745, -122.304],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-86.002, 77.053],
                                  [67.206, 114.314],
                                  [162.508, -124.959],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-84.222, 80.395],
                                  [70.46, 115.583],
                                  [165.108, -127.433],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-82.51, 83.517],
                                  [73.511, 116.745],
                                  [167.65, -129.702],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-80.942, 86.471],
                                  [76.444, 117.881],
                                  [170.021, -131.868],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-79.424, 89.292],
                                  [79.245, 118.938],
                                  [172.319, -133.868],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-78.025, 91.981],
                                  [81.894, 119.933],
                                  [174.512, -135.767],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-76.724, 94.502],
                                  [84.441, 120.885],
                                  [176.582, -137.532],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-75.5, 96.891],
                                  [86.857, 121.768],
                                  [178.57, -139.206],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.314, 99.139],
                                  [89.158, 122.617],
                                  [180.502, -140.721],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.219, 101.29],
                                  [91.351, 123.46],
                                  [182.28, -142.174],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.193, 103.335],
                                  [93.399, 124.171],
                                  [184.026, -143.532],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-71.227, 105.268],
                                  [95.363, 124.938],
                                  [185.678, -144.795],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-70.319, 107.075],
                                  [97.276, 125.575],
                                  [187.235, -145.971],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.502, 108.834],
                                  [99.033, 126.241],
                                  [188.695, -147.088],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.678, 110.46],
                                  [100.737, 126.838],
                                  [190.121, -148.147],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-67.942, 111.982],
                                  [102.317, 127.396],
                                  [191.45, -149.119],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-67.224, 113.427],
                                  [103.843, 127.955],
                                  [192.708, -150.033],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-66.558, 114.803],
                                  [105.272, 128.44],
                                  [193.897, -150.89],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-65.948, 116.103],
                                  [106.576, 128.927],
                                  [195.049, -151.689],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-65.382, 117.3],
                                  [107.851, 129.375],
                                  [196.097, -152.436],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-64.836, 118.456],
                                  [109.062, 129.789],
                                  [197.107, -153.125],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-64.304, 119.509],
                                  [110.175, 130.166],
                                  [198.076, -153.792],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-63.853, 120.487],
                                  [111.224, 130.538],
                                  [198.944, -154.367],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-63.414, 121.425],
                                  [112.18, 130.877],
                                  [199.771, -154.953],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-63.021, 122.288],
                                  [113.1, 131.212],
                                  [200.526, -155.453],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.673, 123.112],
                                  [113.955, 131.481],
                                  [201.239, -155.93],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.308, 123.834],
                                  [114.711, 131.775],
                                  [201.915, -156.35],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.986, 124.516],
                                  [115.465, 132.008],
                                  [202.52, -156.746],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.703, 125.155],
                                  [116.09, 132.265],
                                  [203.078, -157.118],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.435, 125.725],
                                  [116.708, 132.457],
                                  [203.57, -157.466],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.209, 126.222],
                                  [117.265, 132.646],
                                  [204.049, -157.762],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.992, 126.681],
                                  [117.781, 132.802],
                                  [204.458, -158.033],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.783, 127.097],
                                  [118.169, 132.983],
                                  [204.825, -158.247],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.621, 127.446],
                                  [118.583, 133.133],
                                  [205.185, -158.47],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.462, 127.786],
                                  [118.932, 133.246],
                                  [205.47, -158.635],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.377, 128.054],
                                  [119.182, 133.328],
                                  [205.686, -158.808],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.238, 128.284],
                                  [119.459, 133.406],
                                  [205.924, -158.957],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.138, 128.444],
                                  [119.637, 133.481],
                                  [206.092, -159.018],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.109, 128.629],
                                  [119.812, 133.521],
                                  [206.216, -159.117],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.056, 128.71],
                                  [119.919, 133.591],
                                  [206.305, -159.161],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.483, 0.483, 0.483, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.483, 0.483, 0.483, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [137.699, 73.584],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.705, 73.688],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.701, 73.808],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.685, 74.018],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.682, 74.322],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.686, 74.716],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.66, 75.176],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.651, 75.721],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.674, 76.407],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.661, 77.168],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.687, 78.077],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.738, 79.159],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.815, 80.37],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.927, 81.778],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.113, 83.367],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.367, 85.208],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.755, 87.293],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.268, 89.665],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140, 92.389],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.038, 95.493],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.521, 98.968],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.558, 102.826],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.38, 107.049],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [151.294, 111.415],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.487, 115.557],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.96, 118.866],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.237, 120.706],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.367, 120.827],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.527, 119.356],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.175, 117.024],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.311, 114.357],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.198, 111.837],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.148, 109.533],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.42, 107.554],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.268, 105.87],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.828, 104.429],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.191, 103.172],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.435, 102.038],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.636, 101.03],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.797, 100.067],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.972, 99.14],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.097, 98.267],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.29, 97.303],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.48, 96.36],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.694, 95.375],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.944, 94.32],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.24, 93.179],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.576, 91.993],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.938, 90.705],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.367, 89.36],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.832, 87.977],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.327, 86.499],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.935, 85.021],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.522, 83.506],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.206, 82.014],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.885, 80.549],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.635, 79.099],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.402, 77.674],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.234, 76.375],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.086, 75.088],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.917, 73.911],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.832, 72.753],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.768, 71.677],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.657, 70.677],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.654, 69.68],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.576, 68.807],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.55, 67.929],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.544, 67.115],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.54, 66.331],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.537, 65.62],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.528, 64.93],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.535, 64.268],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.554, 63.617],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.571, 63.025],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.617, 62.448],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.624, 61.899],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.661, 61.361],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.67, 60.874],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.691, 60.393],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.727, 59.922],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.803, 59.457],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.817, 59.02],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.861, 58.618],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.916, 58.232],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.915, 57.86],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.969, 57.474],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.999, 57.151],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.047, 56.784],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.077, 56.468],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.115, 56.153],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.111, 55.84],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.029, 55.542],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.872, 55.215],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.628, 54.915],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.323, 54.595],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.884, 54.267],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.336, 53.943],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.688, 53.595],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.896, 53.235],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.949, 52.863],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.872, 52.467],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.623, 52.047],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.18, 51.595],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.526, 51.101],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.633, 50.561],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.459, 49.95],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.974, 49.25],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.153, 48.446],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.854, 47.496],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.093, 46.339],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.702, 44.91],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.621, 43.101],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.697, 40.758],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.686, 37.687],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.377, 33.651],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.946, 30.773],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.426, 28.158],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.573, 24.708],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.629, 20.803],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.237, 16.886],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.47, 13.283],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-2.96, 10.179],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.471, 7.61],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.44, 5.537],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.196, 3.891],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-36.976, 2.616],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.989, 1.617],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.388, 0.866],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.242, 0.308],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.685, -0.11],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.737, -0.404],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.483, -0.6],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.934, -0.726],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.146, -0.795],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.161, -0.815],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.943, -0.786],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.559, -0.742],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.046, -0.677],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.33, -0.581],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.507, -0.476],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.562, -0.367],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.473, -0.246],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.306, -0.121],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.014, 0.021],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.63, 0.153],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.182, 0.27],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.608, 0.392],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.971, 0.524],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.249, 0.677],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.467, 0.771],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.607, 0.91],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.701, 1.018],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.605, 1.242],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.74, 1.255],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.689, 1.41],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.617, 1.559],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.45, 1.711],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.247, 1.819],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.996, 1.961],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.677, 2.07],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.323, 2.154],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.919, 2.258],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.461, 2.347],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.977, 2.439],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.455, 2.496],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.883, 2.572],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.284, 2.614],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.643, 2.685],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.954, 2.733],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.242, 2.8],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.5, 2.803],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.735, 2.84],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.936, 2.877],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.098, 2.899],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.241, 2.931],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.349, 2.927],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.45, 2.955],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.496, 2.935],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.54, 2.959],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.547, 2.965],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.526, 2.981],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.534, 2.936],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.533, 2.979],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.477, 2.956],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.475, 2.939],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.447, 2.908],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.386, 2.879],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.342, 2.872],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.262, 2.868],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.188, 2.852],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.118, 2.855],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.03, 2.919],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.006, 3.014],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.932, 3.137],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.848, 3.284],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.693, 3.471],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.447, 3.703],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.145, 4.019],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.667, 4.405],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.956, 4.962],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.957, 5.71],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.49, 6.723],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.416, 8.178],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.414, 10.333],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.1, 13.509],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.022, 18.096],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.855, 24.446],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.505, 32.464],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.391, 41.791],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.229, 51.574],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.699, 61.154],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.286, 70.045],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.388, 77.015],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-3.091, 76.407],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.021, 75.452],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.11, 74.386],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.312, 73.321],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.784, 72.33],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.628, 71.422],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.925, 70.648],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.746, 70.001],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.169, 69.408],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.228, 68.988],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.948, 68.624],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.375, 68.34],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.554, 68.132],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.467, 68.032],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.175, 67.946],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.684, 67.921],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.998, 67.987],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.117, 68.036],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.075, 68.157],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.895, 68.281],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.556, 68.411],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.085, 68.597],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.482, 68.769],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.767, 68.97],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.924, 69.202],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.952, 69.367],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.889, 69.61],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.741, 69.835],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.457, 70.076],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.12, 70.283],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.658, 70.498],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.136, 70.729],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.519, 70.928],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.802, 71.119],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.026, 71.332],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.192, 71.533],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.279, 71.683],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.282, 71.889],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.213, 72.031],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.091, 72.222],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.904, 72.378],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.647, 72.516],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.357, 72.66],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.971, 72.78],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.556, 72.885],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.088, 73.003],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.573, 73.102],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.964, 73.18],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.361, 73.274],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.691, 73.348],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.931, 73.387],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.198, 73.424],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.374, 73.471],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.529, 73.517],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.633, 73.56],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [137.699, 73.584],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.705, 73.688],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.701, 73.808],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.685, 74.018],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.682, 74.322],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.686, 74.716],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.66, 75.176],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.651, 75.721],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.674, 76.407],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.661, 77.168],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.687, 78.077],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.738, 79.159],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.815, 80.37],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.927, 81.778],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.113, 83.367],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.367, 85.208],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.755, 87.293],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.268, 89.665],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140, 92.389],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.038, 95.493],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.521, 98.968],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.558, 102.826],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.38, 107.049],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [151.294, 111.415],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.487, 115.557],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.96, 118.866],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.237, 120.706],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.367, 120.827],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.527, 119.356],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.175, 117.024],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.311, 114.357],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.198, 111.837],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.148, 109.533],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.42, 107.554],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.268, 105.87],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.828, 104.429],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.191, 103.172],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.435, 102.038],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.636, 101.03],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.797, 100.067],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.972, 99.14],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.097, 98.267],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.29, 97.303],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.48, 96.36],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.694, 95.375],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.944, 94.32],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.24, 93.179],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.576, 91.993],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.938, 90.705],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.367, 89.36],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.832, 87.977],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.327, 86.499],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.935, 85.021],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.522, 83.506],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.206, 82.014],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.885, 80.549],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.635, 79.099],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.402, 77.674],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.234, 76.375],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.086, 75.088],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.917, 73.911],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.832, 72.753],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.768, 71.677],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.657, 70.677],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.654, 69.68],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.576, 68.807],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.55, 67.929],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.544, 67.115],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.54, 66.331],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.537, 65.62],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.528, 64.93],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.535, 64.268],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.554, 63.617],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.571, 63.025],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.617, 62.448],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.624, 61.899],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.661, 61.361],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.67, 60.874],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.691, 60.393],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.727, 59.922],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.803, 59.457],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.817, 59.02],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.861, 58.618],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.916, 58.232],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.915, 57.86],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.969, 57.474],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.999, 57.151],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.047, 56.784],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.077, 56.468],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.115, 56.153],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.111, 55.84],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.029, 55.542],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.872, 55.215],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.628, 54.915],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.323, 54.595],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.884, 54.267],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.336, 53.943],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.688, 53.595],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.896, 53.235],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.949, 52.863],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.872, 52.467],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.623, 52.047],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.18, 51.595],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.526, 51.101],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.633, 50.561],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.459, 49.95],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.974, 49.25],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.153, 48.446],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.854, 47.496],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.093, 46.339],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.702, 44.91],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.621, 43.101],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.697, 40.758],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.686, 37.687],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.377, 33.651],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.946, 30.773],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.426, 28.158],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.573, 24.708],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.629, 20.803],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.237, 16.886],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.47, 13.283],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-2.96, 10.179],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.471, 7.61],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.44, 5.537],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.196, 3.891],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-36.976, 2.616],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-42.989, 1.617],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.388, 0.866],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.242, 0.308],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.685, -0.11],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.737, -0.404],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.483, -0.6],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.934, -0.726],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.146, -0.795],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.161, -0.815],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.943, -0.786],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.559, -0.742],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.046, -0.677],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.33, -0.581],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.507, -0.476],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.562, -0.367],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.473, -0.246],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-93.306, -0.121],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.014, 0.021],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-96.63, 0.153],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.182, 0.27],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.608, 0.392],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.971, 0.524],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.249, 0.677],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.467, 0.771],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.607, 0.91],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.701, 1.018],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-106.605, 1.242],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.74, 1.255],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.689, 1.41],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.617, 1.559],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.45, 1.711],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.247, 1.819],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.996, 1.961],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.677, 2.07],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.323, 2.154],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.919, 2.258],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.461, 2.347],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.977, 2.439],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.455, 2.496],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.883, 2.572],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.284, 2.614],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.643, 2.685],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.954, 2.733],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.242, 2.8],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.5, 2.803],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.735, 2.84],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.936, 2.877],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.098, 2.899],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.241, 2.931],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.349, 2.927],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.45, 2.955],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.496, 2.935],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.54, 2.959],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.547, 2.965],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.526, 2.981],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.534, 2.936],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.533, 2.979],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.477, 2.956],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.475, 2.939],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.447, 2.908],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.386, 2.879],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.342, 2.872],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.262, 2.868],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.188, 2.852],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.118, 2.855],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.03, 2.919],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-118.006, 3.014],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.932, 3.137],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.848, 3.284],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.693, 3.471],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.447, 3.703],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.145, 4.019],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.667, 4.405],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-115.956, 4.962],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.957, 5.71],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.49, 6.723],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-111.416, 8.178],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.414, 10.333],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-104.1, 13.509],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-98.022, 18.096],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.855, 24.446],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.505, 32.464],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.391, 41.791],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.229, 51.574],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.699, 61.154],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-27.286, 70.045],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.388, 77.015],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-3.091, 76.407],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.021, 75.452],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.11, 74.386],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.312, 73.321],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.784, 72.33],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.628, 71.422],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.925, 70.648],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.746, 70.001],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [56.169, 69.408],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.228, 68.988],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.948, 68.624],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [70.375, 68.34],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.554, 68.132],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.467, 68.032],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.175, 67.946],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.684, 67.921],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.998, 67.987],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.117, 68.036],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.075, 68.157],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.895, 68.281],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.556, 68.411],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.085, 68.597],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.482, 68.769],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.767, 68.97],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [109.924, 69.202],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.952, 69.367],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.889, 69.61],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.741, 69.835],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.457, 70.076],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.12, 70.283],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.658, 70.498],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.136, 70.729],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.519, 70.928],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.802, 71.119],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.026, 71.332],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.192, 71.533],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.279, 71.683],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.282, 71.889],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.213, 72.031],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.091, 72.222],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.904, 72.378],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.647, 72.516],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.357, 72.66],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.971, 72.78],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.556, 72.885],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.088, 73.003],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.573, 73.102],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.964, 73.18],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.361, 73.274],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.691, 73.348],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.931, 73.387],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.198, 73.424],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.374, 73.471],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.529, 73.517],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.633, 73.56],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 8',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 8,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.006, 143.192],
                                  [131.994, 138.397],
                                  [225.556, -144.745],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.952, 143.174],
                                  [132.077, 138.398],
                                  [225.351, -144.597],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.842, 143.148],
                                  [132.187, 138.367],
                                  [225.036, -144.364],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.675, 143.111],
                                  [132.354, 138.334],
                                  [224.52, -143.981],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.515, 143.062],
                                  [132.573, 138.302],
                                  [223.927, -143.48],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.236, 143.002],
                                  [132.881, 138.272],
                                  [223.099, -142.8],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-46.964, 142.929],
                                  [133.212, 138.209],
                                  [222.098, -142.002],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-46.574, 142.841],
                                  [133.631, 138.147],
                                  [220.895, -141.022],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-46.129, 142.74],
                                  [134.164, 138.115],
                                  [219.456, -139.897],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-45.629, 142.623],
                                  [134.722, 138.021],
                                  [217.81, -138.531],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-45.013, 142.489],
                                  [135.427, 137.897],
                                  [215.861, -136.921],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-44.342, 142.344],
                                  [136.245, 137.806],
                                  [213.614, -135.105],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-43.492, 142.179],
                                  [137.183, 137.681],
                                  [211.032, -132.954],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-42.589, 141.998],
                                  [138.263, 137.528],
                                  [208.052, -130.497],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-41.446, 141.798],
                                  [139.525, 137.343],
                                  [204.675, -127.648],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-40.25, 141.577],
                                  [140.958, 137.126],
                                  [200.805, -124.404],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-38.752, 141.338],
                                  [142.635, 136.942],
                                  [196.379, -120.702],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-37.078, 141.076],
                                  [144.548, 136.666],
                                  [191.271, -116.386],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.165, 140.796],
                                  [146.76, 136.358],
                                  [185.447, -111.397],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-32.951, 140.489],
                                  [149.334, 136.018],
                                  [178.751, -105.638],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-30.312, 140.159],
                                  [152.363, 135.618],
                                  [170.993, -98.988],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.25, 139.807],
                                  [155.849, 135.154],
                                  [162.081, -91.228],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.763, 139.433],
                                  [159.88, 134.627],
                                  [151.797, -82.234],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.666, 139.038],
                                  [164.585, 134.001],
                                  [139.953, -71.819],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-15.023, 138.628],
                                  [169.931, 133.271],
                                  [126.592, -60.018],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-9.833, 138.215],
                                  [175.857, 132.463],
                                  [111.94, -47.02],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-4.41, 137.814],
                                  [182.113, 131.566],
                                  [96.455, -33.295],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.994, 137.442],
                                  [188.356, 130.692],
                                  [80.902, -19.507],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.254, 137.109],
                                  [194.402, 129.739],
                                  [65.921, -6.249],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.055, 136.822],
                                  [199.966, 128.797],
                                  [51.985, 6.069],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [15.398, 136.58],
                                  [205.017, 127.832],
                                  [39.283, 17.227],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [19.279, 136.382],
                                  [209.554, 126.938],
                                  [27.781, 27.285],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [22.76, 136.225],
                                  [213.637, 126.022],
                                  [17.381, 36.273],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.84, 136.105],
                                  [217.265, 125.113],
                                  [7.953, 44.284],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [28.516, 136.019],
                                  [220.466, 124.182],
                                  [-0.568, 51.532],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [30.912, 135.966],
                                  [223.365, 123.258],
                                  [-8.341, 57.986],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [33.088, 135.945],
                                  [225.958, 122.308],
                                  [-15.4, 63.828],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [34.916, 135.952],
                                  [228.244, 121.299],
                                  [-21.934, 69.058],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [36.581, 135.989],
                                  [230.314, 120.291],
                                  [-27.883, 73.764],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [38.019, 136.056],
                                  [232.167, 119.218],
                                  [-33.433, 78.037],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [39.288, 136.15],
                                  [233.83, 118.108],
                                  [-38.493, 81.841],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [40.323, 136.274],
                                  [235.237, 116.99],
                                  [-43.22, 85.269],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [41.247, 136.429],
                                  [236.545, 115.733],
                                  [-47.611, 88.31],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [41.993, 136.615],
                                  [237.656, 114.462],
                                  [-51.7, 91.029],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [42.559, 136.832],
                                  [238.599, 113.137],
                                  [-55.488, 93.389],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [43.006, 137.08],
                                  [239.403, 111.725],
                                  [-59.033, 95.447],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [43.269, 137.364],
                                  [240.037, 110.197],
                                  [-62.307, 97.267],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [43.475, 137.678],
                                  [240.594, 108.639],
                                  [-65.366, 98.788],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [43.5, 138.027],
                                  [240.982, 106.968],
                                  [-68.218, 100.042],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [43.411, 138.411],
                                  [241.271, 105.248],
                                  [-70.854, 101.061],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [43.218, 138.828],
                                  [241.466, 103.498],
                                  [-73.348, 101.839],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [42.931, 139.263],
                                  [241.54, 101.653],
                                  [-75.656, 102.471],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [42.563, 139.732],
                                  [241.578, 99.813],
                                  [-77.753, 102.961],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [42.194, 140.208],
                                  [241.544, 97.953],
                                  [-79.74, 103.283],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [41.713, 140.706],
                                  [241.474, 96.131],
                                  [-81.585, 103.52],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [41.261, 141.209],
                                  [241.356, 94.342],
                                  [-83.292, 103.68],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [40.848, 141.71],
                                  [241.272, 92.569],
                                  [-84.889, 103.83],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [40.358, 142.218],
                                  [241.104, 90.846],
                                  [-86.355, 103.881],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [39.922, 142.699],
                                  [241.022, 89.261],
                                  [-87.757, 103.957],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [39.542, 143.194],
                                  [240.91, 87.701],
                                  [-88.998, 104.007],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [39.155, 143.656],
                                  [240.782, 86.264],
                                  [-90.204, 104.048],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [38.762, 144.112],
                                  [240.677, 84.853],
                                  [-91.293, 104.124],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [38.422, 144.545],
                                  [240.618, 83.547],
                                  [-92.33, 104.147],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [38.131, 144.973],
                                  [240.519, 82.319],
                                  [-93.299, 104.194],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [37.825, 145.375],
                                  [240.478, 81.11],
                                  [-94.18, 104.224],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [37.562, 145.77],
                                  [240.391, 80.036],
                                  [-95.014, 104.271],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [37.277, 146.139],
                                  [240.316, 78.971],
                                  [-95.783, 104.29],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [37.03, 146.501],
                                  [240.272, 77.974],
                                  [-96.508, 104.318],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [36.754, 146.835],
                                  [240.194, 77.021],
                                  [-97.169, 104.331],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [36.572, 147.163],
                                  [240.163, 76.15],
                                  [-97.788, 104.346],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [36.295, 147.485],
                                  [240.073, 75.317],
                                  [-98.386, 104.318],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [36.108, 147.779],
                                  [240.026, 74.519],
                                  [-98.922, 104.286],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [35.883, 148.067],
                                  [239.937, 73.733],
                                  [-99.375, 104.268],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [35.681, 148.327],
                                  [239.867, 73.02],
                                  [-99.809, 104.219],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [35.439, 148.605],
                                  [239.795, 72.335],
                                  [-100.225, 104.139],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [35.279, 148.831],
                                  [239.716, 71.677],
                                  [-100.578, 104.085],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [35.075, 149.075],
                                  [239.633, 71.043],
                                  [-100.913, 103.996],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [34.827, 149.29],
                                  [239.501, 70.476],
                                  [-101.21, 103.868],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [34.657, 149.501],
                                  [239.403, 69.911],
                                  [-101.466, 103.743],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [34.44, 149.683],
                                  [239.297, 69.367],
                                  [-101.703, 103.596],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [34.237, 149.885],
                                  [239.203, 68.842],
                                  [-101.88, 103.407],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [34.048, 150.058],
                                  [239.076, 68.335],
                                  [-102.063, 103.28],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [33.809, 150.227],
                                  [238.941, 67.889],
                                  [-102.205, 103.064],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [33.644, 150.367],
                                  [238.834, 67.459],
                                  [-102.286, 102.846],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [33.429, 150.503],
                                  [238.674, 67.044],
                                  [-102.412, 102.644],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [33.161, 150.635],
                                  [238.504, 66.626],
                                  [-102.458, 102.394],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [32.966, 150.763],
                                  [238.36, 66.284],
                                  [-102.506, 102.115],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [32.78, 150.888],
                                  [238.224, 65.892],
                                  [-102.538, 101.83],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [32.541, 150.984],
                                  [238.033, 65.558],
                                  [-102.508, 101.558],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [32.311, 151.076],
                                  [237.849, 65.235],
                                  [-102.462, 101.235],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [32.044, 151.102],
                                  [237.59, 64.906],
                                  [-102.373, 100.95],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [31.678, 151],
                                  [237.23, 64.588],
                                  [-102.322, 100.703],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [31.185, 150.789],
                                  [236.671, 64.226],
                                  [-102.138, 100.493],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [30.61, 150.386],
                                  [236.012, 63.875],
                                  [-101.972, 100.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [29.844, 149.856],
                                  [235.164, 63.498],
                                  [-101.735, 100.294],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [28.975, 149.136],
                                  [234.11, 63.096],
                                  [-101.427, 100.278],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [27.977, 148.244],
                                  [232.877, 62.685],
                                  [-101.091, 100.327],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [26.742, 147.143],
                                  [231.395, 62.231],
                                  [-100.655, 100.469],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.357, 145.826],
                                  [229.672, 61.751],
                                  [-100.146, 100.677],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.796, 144.255],
                                  [227.694, 61.246],
                                  [-99.58, 100.951],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [21.95, 142.416],
                                  [225.415, 60.697],
                                  [-98.912, 101.346],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [19.846, 140.251],
                                  [222.802, 60.104],
                                  [-98.124, 101.864],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [17.472, 137.753],
                                  [219.821, 59.469],
                                  [-97.25, 102.476],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.749, 134.879],
                                  [216.41, 58.773],
                                  [-96.227, 103.24],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.646, 131.582],
                                  [212.537, 58.017],
                                  [-95.071, 104.157],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.119, 127.755],
                                  [208.105, 57.184],
                                  [-93.754, 105.227],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [4.086, 123.38],
                                  [203.036, 56.257],
                                  [-92.211, 106.507],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.572, 118.316],
                                  [197.264, 55.236],
                                  [-90.476, 108.028],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-5.857, 112.471],
                                  [190.598, 54.089],
                                  [-88.441, 109.819],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.97, 105.748],
                                  [182.943, 52.797],
                                  [-86.109, 111.909],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.018, 97.904],
                                  [174.091, 51.329],
                                  [-83.387, 114.387],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.188, 88.825],
                                  [163.865, 49.668],
                                  [-80.246, 117.284],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.678, 78.294],
                                  [152.059, 47.782],
                                  [-76.609, 120.659],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.538, 66.152],
                                  [138.483, 45.637],
                                  [-72.416, 124.601],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.815, 52.433],
                                  [123.108, 43.234],
                                  [-67.666, 129.113],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.374, 37.262],
                                  [106.197, 40.61],
                                  [-62.413, 134.112],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-87.726, 21.185],
                                  [88.334, 37.847],
                                  [-56.835, 139.426],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-102.196, 5.042],
                                  [70.372, 35.082],
                                  [-51.197, 144.824],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-116.123, -10.512],
                                  [53.113, 32.429],
                                  [-45.769, 150.013],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.139, -25.023],
                                  [37.069, 29.971],
                                  [-40.699, 154.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-141.024, -38.319],
                                  [22.43, 27.724],
                                  [-36.051, 159.348],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.811, -50.337],
                                  [9.176, 25.686],
                                  [-31.796, 163.428],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.618, -61.158],
                                  [-2.748, 23.855],
                                  [-27.951, 167.113],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.489, -70.979],
                                  [-13.536, 22.197],
                                  [-24.46, 170.458],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-178.578, -79.915],
                                  [-23.303, 20.695],
                                  [-21.278, 173.52],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.946, -88.039],
                                  [-32.21, 19.314],
                                  [-18.332, 176.326],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-192.729, -95.51],
                                  [-40.295, 18.07],
                                  [-15.668, 178.904],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.957, -102.358],
                                  [-47.765, 16.913],
                                  [-13.166, 181.281],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.708, -108.614],
                                  [-54.591, 15.859],
                                  [-10.873, 183.458],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.057, -114.45],
                                  [-60.917, 14.875],
                                  [-8.731, 185.491],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.019, -119.85],
                                  [-66.746, 13.976],
                                  [-6.752, 187.41],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.641, -124.875],
                                  [-72.189, 13.13],
                                  [-4.879, 189.183],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-223.951, -129.559],
                                  [-77.215, 12.352],
                                  [-3.158, 190.841],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-227.967, -133.931],
                                  [-81.906, 11.625],
                                  [-1.544, 192.382],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-231.749, -138.038],
                                  [-86.324, 10.933],
                                  [0.011, 193.836],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-235.328, -141.865],
                                  [-90.424, 10.293],
                                  [1.458, 195.231],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-238.641, -145.459],
                                  [-94.254, 9.704],
                                  [2.782, 196.508],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.829, -148.85],
                                  [-97.892, 9.132],
                                  [4.092, 197.727],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-244.75, -152.055],
                                  [-101.262, 8.611],
                                  [5.277, 198.886],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.576, -155.042],
                                  [-104.456, 8.108],
                                  [6.431, 199.986],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.198, -157.872],
                                  [-107.462, 7.639],
                                  [7.506, 200.997],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-252.679, -160.548],
                                  [-110.263, 7.204],
                                  [8.52, 201.977],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.048, -163.036],
                                  [-112.938, 6.786],
                                  [9.486, 202.897],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-257.274, -165.4],
                                  [-115.456, 6.385],
                                  [10.436, 203.757],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-259.359, -167.656],
                                  [-117.817, 6.018],
                                  [11.291, 204.586],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-261.379, -169.771],
                                  [-120.054, 5.668],
                                  [12.114, 205.355],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-263.24, -171.777],
                                  [-122.119, 5.351],
                                  [12.858, 206.093],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-265.005, -173.659],
                                  [-124.106, 5.035],
                                  [13.601, 206.8],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-266.674, -175.416],
                                  [-126, 4.736],
                                  [14.327, 207.476],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-268.295, -177.111],
                                  [-127.723, 4.47],
                                  [14.942, 208.091],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-269.756, -178.682],
                                  [-129.4, 4.204],
                                  [15.587, 208.674],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-271.215, -180.206],
                                  [-130.969, 3.956],
                                  [16.183, 209.257],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-272.531, -181.606],
                                  [-132.446, 3.724],
                                  [16.747, 209.778],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-273.782, -182.927],
                                  [-133.831, 3.508],
                                  [17.262, 210.268],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-274.984, -184.156],
                                  [-135.108, 3.31],
                                  [17.745, 210.756],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-276.089, -185.353],
                                  [-136.355, 3.111],
                                  [18.225, 211.183],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.146, -186.457],
                                  [-137.494, 2.93],
                                  [18.673, 211.609],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-278.136, -187.483],
                                  [-138.557, 2.764],
                                  [19.072, 212.003],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-279.078, -188.447],
                                  [-139.591, 2.599],
                                  [19.485, 212.365],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-279.923, -189.364],
                                  [-140.517, 2.451],
                                  [19.849, 212.727],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-280.719, -190.203],
                                  [-141.383, 2.319],
                                  [20.165, 213.026],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-281.513, -190.996],
                                  [-142.204, 2.187],
                                  [20.494, 213.354],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-282.194, -191.727],
                                  [-142.949, 2.071],
                                  [20.775, 213.62],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-282.826, -192.395],
                                  [-143.664, 1.956],
                                  [21.069, 213.885],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-283.456, -193.002],
                                  [-144.304, 1.857],
                                  [21.299, 214.118],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-283.989, -193.608],
                                  [-144.898, 1.759],
                                  [21.559, 214.35],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.52, -194.152],
                                  [-145.433, 1.676],
                                  [21.754, 214.55],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.955, -194.634],
                                  [-145.938, 1.594],
                                  [21.963, 214.718],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-285.372, -195.053],
                                  [-146.367, 1.528],
                                  [22.124, 214.885],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-285.739, -195.426],
                                  [-146.783, 1.462],
                                  [22.298, 215.051],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-286.104, -195.784],
                                  [-147.107, 1.413],
                                  [22.408, 215.184],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-286.373, -196.095],
                                  [-147.434, 1.363],
                                  [22.532, 215.286],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-286.64, -196.344],
                                  [-147.715, 1.314],
                                  [22.654, 215.387],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-286.858, -196.577],
                                  [-147.936, 1.281],
                                  [22.743, 215.487],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.026, -196.763],
                                  [-148.144, 1.248],
                                  [22.83, 215.554],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.177, -196.934],
                                  [-148.276, 1.232],
                                  [22.869, 215.621],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.294, -197.058],
                                  [-148.424, 1.199],
                                  [22.953, 215.656],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.393, -197.12],
                                  [-148.466, 1.199],
                                  [22.941, 215.689],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.443, -197.183],
                                  [-148.541, 1.183],
                                  [22.991, 215.721],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.381, -197.121],
                                  [-148.541, 1.184],
                                  [23.01, 215.667],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-287.252, -197.015],
                                  [-148.45, 1.266],
                                  [23.003, 215.393],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-286.976, -196.723],
                                  [-148.364, 1.397],
                                  [23.077, 214.96],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-286.583, -196.386],
                                  [-148.161, 1.548],
                                  [23.136, 214.367],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-286.116, -195.912],
                                  [-147.952, 1.764],
                                  [23.195, 213.551],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-285.462, -195.283],
                                  [-147.647, 2.046],
                                  [23.297, 212.463],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-284.663, -194.562],
                                  [-147.25, 2.394],
                                  [23.441, 211.165],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-283.685, -193.609],
                                  [-146.811, 2.825],
                                  [23.592, 209.592],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-282.552, -192.517],
                                  [-146.302, 3.277],
                                  [23.794, 207.742],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-281.229, -191.239],
                                  [-145.666, 3.86],
                                  [24.015, 205.583],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-279.662, -189.726],
                                  [-144.923, 4.573],
                                  [24.266, 203.05],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-277.828, -187.979],
                                  [-144.031, 5.357],
                                  [24.606, 200.08],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-275.78, -185.964],
                                  [-143.043, 6.243],
                                  [25.003, 196.702],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-273.368, -183.632],
                                  [-141.822, 7.31],
                                  [25.467, 192.836],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-270.628, -180.965],
                                  [-140.441, 8.529],
                                  [26.013, 188.358],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-267.497, -177.881],
                                  [-138.823, 9.92],
                                  [26.686, 183.311],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-263.93, -174.33],
                                  [-136.961, 11.468],
                                  [27.454, 177.525],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-259.844, -170.221],
                                  [-134.748, 13.348],
                                  [28.412, 170.879],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.141, -165.471],
                                  [-132.143, 15.455],
                                  [29.582, 163.239],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.703, -159.92],
                                  [-129.081, 17.893],
                                  [31.023, 154.548],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.471, -153.442],
                                  [-125.395, 20.759],
                                  [32.789, 144.529],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.305, -145.879],
                                  [-120.921, 24.08],
                                  [35.058, 133.024],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-228.079, -137.031],
                                  [-115.582, 27.931],
                                  [37.864, 119.83],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-218.697, -126.708],
                                  [-109.156, 32.405],
                                  [41.384, 104.877],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.146, -114.829],
                                  [-101.497, 37.517],
                                  [45.774, 88.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.68, -101.51],
                                  [-92.549, 43.193],
                                  [51.149, 70.191],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.76, -87.157],
                                  [-82.515, 49.282],
                                  [57.444, 51.536],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.929, -72.402],
                                  [-71.773, 55.394],
                                  [64.418, 33.236],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.696, -57.886],
                                  [-60.814, 61.396],
                                  [71.859, 16.072],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.394, -44.136],
                                  [-50.025, 67.03],
                                  [79.411, 0.534],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-142.185, -31.305],
                                  [-39.628, 72.259],
                                  [86.833, -13.293],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-133.986, -19.473],
                                  [-29.783, 76.988],
                                  [94.096, -25.469],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-126.71, -8.612],
                                  [-20.489, 81.31],
                                  [101.026, -36.246],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.219, 1.345],
                                  [-11.745, 85.235],
                                  [107.617, -45.694],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-114.425, 10.537],
                                  [-3.53, 88.836],
                                  [113.922, -54.119],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-109.228, 19.02],
                                  [4.212, 92.14],
                                  [119.914, -61.627],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-104.538, 26.824],
                                  [11.472, 95.182],
                                  [125.609, -68.364],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-100.292, 34.125],
                                  [18.318, 97.991],
                                  [131, -74.401],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.449, 40.854],
                                  [24.784, 100.567],
                                  [136.155, -79.886],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-92.914, 47.186],
                                  [30.875, 103],
                                  [141.017, -84.845],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-89.701, 53.082],
                                  [36.646, 105.237],
                                  [145.648, -89.355],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-86.721, 58.635],
                                  [42.105, 107.3],
                                  [150.086, -93.496],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-83.993, 63.808],
                                  [47.29, 109.292],
                                  [154.288, -97.284],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-81.47, 68.693],
                                  [52.188, 111.112],
                                  [158.328, -100.755],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-79.137, 73.289],
                                  [56.844, 112.828],
                                  [162.133, -103.979],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-76.953, 77.652],
                                  [61.279, 114.441],
                                  [165.785, -106.965],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-74.941, 81.721],
                                  [65.483, 115.981],
                                  [169.245, -109.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.062, 85.591],
                                  [69.478, 117.417],
                                  [172.569, -112.273],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-71.279, 89.226],
                                  [73.286, 118.781],
                                  [175.751, -114.636],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.677, 92.72],
                                  [76.934, 120.073],
                                  [178.753, -116.838],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.128, 95.981],
                                  [80.354, 121.262],
                                  [181.667, -118.853],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-66.719, 99.069],
                                  [83.634, 122.413],
                                  [184.394, -120.777],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-65.354, 102.016],
                                  [86.771, 123.492],
                                  [187.025, -122.548],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-64.091, 104.822],
                                  [89.73, 124.501],
                                  [189.527, -124.232],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.927, 107.455],
                                  [92.571, 125.472],
                                  [191.896, -125.797],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.828, 109.947],
                                  [95.259, 126.373],
                                  [194.163, -127.277],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.759, 112.298],
                                  [97.825, 127.235],
                                  [196.358, -128.61],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.782, 114.539],
                                  [100.265, 128.091],
                                  [198.384, -129.89],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.862, 116.67],
                                  [102.547, 128.815],
                                  [200.366, -131.088],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-57.998, 118.693],
                                  [104.733, 129.596],
                                  [202.242, -132.203],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-57.188, 120.573],
                                  [106.851, 130.246],
                                  [204.009, -133.236],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-56.461, 122.408],
                                  [108.809, 130.921],
                                  [205.667, -134.218],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-55.723, 124.102],
                                  [110.697, 131.527],
                                  [207.278, -135.15],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-55.065, 125.687],
                                  [112.454, 132.098],
                                  [208.779, -136.001],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-54.425, 127.197],
                                  [114.138, 132.661],
                                  [210.201, -136.801],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-53.833, 128.627],
                                  [115.722, 133.16],
                                  [211.545, -137.553],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-53.285, 129.983],
                                  [117.173, 133.651],
                                  [212.84, -138.255],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-52.785, 131.23],
                                  [118.581, 134.107],
                                  [214.026, -138.906],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-52.297, 132.433],
                                  [119.918, 134.526],
                                  [215.162, -139.507],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-51.822, 133.527],
                                  [121.153, 134.911],
                                  [216.252, -140.09],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-51.421, 134.548],
                                  [122.315, 135.291],
                                  [217.23, -140.594],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-51.032, 135.523],
                                  [123.371, 135.635],
                                  [218.161, -141.109],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-50.685, 136.425],
                                  [124.387, 135.975],
                                  [219.012, -141.542],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-50.378, 137.282],
                                  [125.33, 136.248],
                                  [219.817, -141.956],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-50.05, 138.034],
                                  [126.169, 136.55],
                                  [220.573, -142.321],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-49.762, 138.741],
                                  [126.998, 136.782],
                                  [221.248, -142.667],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-49.516, 139.408],
                                  [127.69, 137.044],
                                  [221.882, -142.993],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-49.277, 139.999],
                                  [128.374, 137.24],
                                  [222.432, -143.3],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-49.076, 140.518],
                                  [128.981, 137.433],
                                  [222.97, -143.554],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.884, 140.994],
                                  [129.552, 137.589],
                                  [223.428, -143.788],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.7, 141.431],
                                  [129.986, 137.775],
                                  [223.842, -143.972],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.55, 141.792],
                                  [130.442, 137.925],
                                  [224.239, -144.166],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.412, 142.144],
                                  [130.824, 138.042],
                                  [224.562, -144.31],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.34, 142.425],
                                  [131.103, 138.123],
                                  [224.807, -144.463],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.213, 142.664],
                                  [131.405, 138.202],
                                  [225.07, -144.595],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.122, 142.832],
                                  [131.604, 138.277],
                                  [225.255, -144.643],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.1, 143.021],
                                  [131.792, 138.321],
                                  [225.4, -144.734],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.052, 143.11],
                                  [131.91, 138.391],
                                  [225.497, -144.769],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.45, 0.45, 0.45, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.45, 0.45, 0.45, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [150.524, 82.319],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.524, 82.428],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.515, 82.559],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.49, 82.782],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.47, 83.099],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.457, 83.515],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.405, 84.007],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.37, 84.597],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.358, 85.328],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.308, 86.144],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.292, 87.109],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.294, 88.251],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.316, 89.551],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.363, 91.057],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.489, 92.749],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.663, 94.717],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.98, 96.937],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [151.416, 99.473],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.066, 102.382],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [153.025, 105.694],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.445, 109.405],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.445, 113.511],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [159.26, 117.992],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.221, 122.604],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.504, 126.909],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.066, 130.24],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.703, 131.58],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.956, 130.742],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.808, 129.762],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.753, 127.792],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.098, 125.217],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.193, 122.903],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.436, 120.9],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.052, 119.238],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.301, 117.882],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.345, 116.773],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.247, 115.836],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.061, 114.996],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.869, 114.264],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.657, 113.557],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.511, 112.872],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.32, 112.215],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.218, 111.455],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.118, 110.693],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.078, 109.879],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.058, 108.979],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.096, 107.976],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.195, 106.919],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.323, 105.744],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.519, 104.5],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.75, 103.199],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.015, 101.802],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.398, 100.387],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.779, 98.928],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.257, 97.485],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.748, 96.057],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.314, 94.639],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.905, 93.242],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.573, 91.963],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.27, 90.698],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.96, 89.532],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.735, 88.391],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.544, 87.325],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.318, 86.33],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.198, 85.342],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.02, 84.47],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.895, 83.598],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.798, 82.783],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.706, 81.998],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.626, 81.284],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.542, 80.589],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.48, 79.924],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.435, 79.267],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.393, 78.666],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.38, 78.079],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.34, 77.522],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.327, 76.975],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.294, 76.475],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.276, 75.978],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.276, 75.492],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.314, 75.012],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.301, 74.56],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.317, 74.14],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.349, 73.733],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.327, 73.343],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.361, 72.935],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.375, 72.589],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.408, 72.201],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.427, 71.859],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.457, 71.518],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.441, 71.177],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.326, 70.853],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.128, 70.493],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.82, 70.159],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.43, 69.804],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.89, 69.437],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.221, 69.071],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.434, 68.676],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.481, 68.265],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.341, 67.84],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.044, 67.386],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.542, 66.905],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.79, 66.391],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.792, 65.83],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.527, 65.218],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.905, 64.537],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.914, 63.766],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [155.523, 62.895],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [151.576, 61.882],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.063, 60.68],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.828, 59.231],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.787, 57.446],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.815, 55.189],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.675, 52.304],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.229, 48.582],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.426, 43.81],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.207, 37.937],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.771, 34.107],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.96, 29.853],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.813, 25.585],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.482, 21.684],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.344, 18.357],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.058, 15.663],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.088, 13.558],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-36.806, 11.945],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.457, 10.762],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.27, 9.892],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.417, 9.299],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.978, 8.906],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.085, 8.66],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.766, 8.543],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.104, 8.53],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.121, 8.575],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.868, 8.673],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.395, 8.812],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.66, 8.995],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.737, 9.184],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.735, 9.422],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.62, 9.758],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.311, 10.09],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.828, 10.387],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.163, 10.647],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.365, 10.908],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.411, 11.159],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.334, 11.375],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.144, 11.574],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.822, 11.741],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.394, 11.938],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.886, 12.124],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.282, 12.235],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.583, 12.408],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.826, 12.527],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.98, 12.654],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.064, 12.768],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.059, 12.875],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.023, 12.98],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.909, 13.065],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.74, 13.141],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.529, 13.23],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.24, 13.304],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.916, 13.363],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.541, 13.43],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.116, 13.484],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.649, 13.555],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.15, 13.59],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.6, 13.634],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.026, 13.654],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.405, 13.695],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.738, 13.722],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.039, 13.771],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.31, 13.768],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.558, 13.794],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.762, 13.82],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.934, 13.835],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.084, 13.858],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.201, 13.852],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.299, 13.869],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.353, 13.854],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.396, 13.872],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.404, 13.878],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.376, 13.892],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.373, 13.852],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.336, 13.889],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.245, 13.868],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.18, 13.856],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.065, 13.841],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.911, 13.816],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.747, 13.823],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.54, 13.81],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.298, 13.808],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.026, 13.838],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.683, 13.842],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.293, 13.893],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.822, 13.964],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.31, 14.073],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.672, 14.269],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.92, 14.539],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.077, 14.962],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.043, 15.546],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.304, 16.09],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.084, 16.791],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.174, 17.816],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-121.439, 19.343],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.55, 21.664],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.155, 25.148],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.909, 30.186],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.663, 37.075],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.529, 45.595],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.981, 55.286],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.678, 65.217],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-36.762, 70.32],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.561, 71.015],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.747, 70.919],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.739, 70.444],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.103, 69.809],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.487, 69.181],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.006, 68.625],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.804, 68.179],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.98, 67.844],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.6, 67.654],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.745, 67.57],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.481, 67.55],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.835, 67.696],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.851, 67.871],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.556, 68.119],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.002, 68.419],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.17, 68.813],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.119, 69.199],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.861, 69.633],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.396, 70.134],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.731, 70.596],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.891, 71.108],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.909, 71.616],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.753, 72.102],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.458, 72.632],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.02, 73.129],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.466, 73.639],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.773, 74.168],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.947, 74.613],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.024, 75.118],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.004, 75.594],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.846, 76.073],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.624, 76.506],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.273, 76.936],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.852, 77.364],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.333, 77.757],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.71, 78.121],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.021, 78.502],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.266, 78.858],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.432, 79.159],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.509, 79.5],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.506, 79.768],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.446, 80.077],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.317, 80.343],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.115, 80.584],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.873, 80.817],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.533, 81.022],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.161, 81.207],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.727, 81.392],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.246, 81.556],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.668, 81.691],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.093, 81.832],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.446, 81.95],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.705, 82.024],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.989, 82.092],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.178, 82.162],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.342, 82.227],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.454, 82.283],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [150.524, 82.319],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.524, 82.428],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.515, 82.559],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.49, 82.782],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.47, 83.099],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.457, 83.515],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.405, 84.007],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.37, 84.597],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.358, 85.328],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.308, 86.144],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.292, 87.109],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.294, 88.251],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.316, 89.551],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.363, 91.057],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.489, 92.749],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.663, 94.717],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.98, 96.937],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [151.416, 99.473],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.066, 102.382],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [153.025, 105.694],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.445, 109.405],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.445, 113.511],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [159.26, 117.992],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.221, 122.604],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.504, 126.909],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.066, 130.24],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.703, 131.58],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.956, 130.742],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.808, 129.762],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.753, 127.792],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.098, 125.217],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.193, 122.903],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.436, 120.9],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.052, 119.238],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.301, 117.882],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.345, 116.773],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.247, 115.836],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.061, 114.996],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.869, 114.264],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.657, 113.557],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.511, 112.872],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.32, 112.215],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.218, 111.455],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.118, 110.693],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.078, 109.879],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.058, 108.979],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.096, 107.976],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.195, 106.919],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.323, 105.744],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.519, 104.5],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.75, 103.199],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.015, 101.802],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.398, 100.387],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.779, 98.928],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.257, 97.485],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.748, 96.057],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.314, 94.639],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.905, 93.242],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.573, 91.963],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.27, 90.698],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.96, 89.532],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.735, 88.391],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.544, 87.325],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.318, 86.33],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.198, 85.342],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.02, 84.47],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.895, 83.598],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.798, 82.783],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.706, 81.998],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.626, 81.284],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.542, 80.589],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.48, 79.924],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.435, 79.267],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.393, 78.666],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.38, 78.079],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.34, 77.522],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.327, 76.975],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.294, 76.475],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.276, 75.978],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.276, 75.492],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.314, 75.012],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.301, 74.56],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.317, 74.14],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.349, 73.733],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.327, 73.343],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.361, 72.935],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.375, 72.589],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.408, 72.201],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.427, 71.859],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.457, 71.518],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.441, 71.177],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.326, 70.853],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.128, 70.493],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.82, 70.159],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.43, 69.804],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.89, 69.437],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.221, 69.071],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.434, 68.676],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.481, 68.265],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.341, 67.84],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.044, 67.386],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.542, 66.905],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.79, 66.391],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.792, 65.83],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.527, 65.218],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.905, 64.537],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.914, 63.766],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [155.523, 62.895],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [151.576, 61.882],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.063, 60.68],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.828, 59.231],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.787, 57.446],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.815, 55.189],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.675, 52.304],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.229, 48.582],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.426, 43.81],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.207, 37.937],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [64.771, 34.107],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.96, 29.853],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.813, 25.585],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.482, 21.684],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-6.344, 18.357],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.058, 15.663],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-28.088, 13.558],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-36.806, 11.945],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.457, 10.762],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.27, 9.892],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.417, 9.299],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-62.978, 8.906],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.085, 8.66],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-72.766, 8.543],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.104, 8.53],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-81.121, 8.575],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.868, 8.673],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.395, 8.812],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.66, 8.995],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.737, 9.184],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-97.735, 9.422],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.62, 9.758],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-103.311, 10.09],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.828, 10.387],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-108.163, 10.647],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.365, 10.908],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.411, 11.159],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-114.334, 11.375],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.144, 11.574],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-117.822, 11.741],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-119.394, 11.938],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.886, 12.124],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.282, 12.235],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.583, 12.408],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.826, 12.527],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.98, 12.654],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-127.064, 12.768],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.059, 12.875],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.023, 12.98],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.909, 13.065],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.74, 13.141],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.529, 13.23],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.24, 13.304],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.916, 13.363],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.541, 13.43],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.116, 13.484],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.649, 13.555],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.15, 13.59],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.6, 13.634],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.026, 13.654],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.405, 13.695],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.738, 13.722],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.039, 13.771],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.31, 13.768],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.558, 13.794],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.762, 13.82],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.934, 13.835],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.084, 13.858],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.201, 13.852],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.299, 13.869],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.353, 13.854],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.396, 13.872],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.404, 13.878],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.376, 13.892],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.373, 13.852],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.336, 13.889],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.245, 13.868],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.18, 13.856],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.065, 13.841],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.911, 13.816],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.747, 13.823],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.54, 13.81],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.298, 13.808],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.026, 13.838],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.683, 13.842],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.293, 13.893],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.822, 13.964],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-135.31, 14.073],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.672, 14.269],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.92, 14.539],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.077, 14.962],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-132.043, 15.546],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-130.304, 16.09],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.084, 16.791],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.174, 17.816],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-121.439, 19.343],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.55, 21.664],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.155, 25.148],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.909, 30.186],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-91.663, 37.075],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-79.529, 45.595],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.981, 55.286],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-51.678, 65.217],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-36.762, 70.32],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-22.561, 71.015],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-9.747, 70.919],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.739, 70.444],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.103, 69.809],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.487, 69.181],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.006, 68.625],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.804, 68.179],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.98, 67.844],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.6, 67.654],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.745, 67.57],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [63.481, 67.55],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.835, 67.696],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [73.851, 67.871],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [78.556, 68.119],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [83.002, 68.419],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.17, 68.813],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.119, 69.199],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.861, 69.633],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [98.396, 70.134],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.731, 70.596],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.891, 71.108],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.909, 71.616],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.753, 72.102],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.458, 72.632],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.02, 73.129],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.466, 73.639],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.773, 74.168],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.947, 74.613],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.024, 75.118],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.004, 75.594],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.846, 76.073],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.624, 76.506],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.273, 76.936],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.852, 77.364],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.333, 77.757],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.71, 78.121],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.021, 78.502],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.266, 78.858],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.432, 79.159],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.509, 79.5],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.506, 79.768],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.446, 80.077],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.317, 80.343],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.115, 80.584],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.873, 80.817],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.533, 81.022],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.161, 81.207],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.727, 81.392],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.246, 81.556],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.668, 81.691],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.093, 81.832],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.446, 81.95],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.705, 82.024],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.989, 82.092],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.178, 82.162],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.342, 82.227],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [150.454, 82.283],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 9',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 9,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.006, 157.592],
                                  [143.994, 143.197],
                                  [244.752, -130.345],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.952, 157.558],
                                  [144.073, 143.207],
                                  [244.539, -130.192],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.841, 157.516],
                                  [144.184, 143.189],
                                  [244.205, -129.959],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.673, 157.455],
                                  [144.352, 143.168],
                                  [243.66, -129.571],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.512, 157.374],
                                  [144.563, 143.157],
                                  [243.032, -129.064],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.232, 157.273],
                                  [144.868, 143.157],
                                  [242.155, -128.378],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-34.958, 157.152],
                                  [145.192, 143.128],
                                  [241.094, -127.575],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-34.567, 156.999],
                                  [145.609, 143.108],
                                  [239.823, -126.581],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-34.12, 156.825],
                                  [146.131, 143.123],
                                  [238.303, -125.445],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-33.618, 156.619],
                                  [146.683, 143.084],
                                  [236.56, -124.068],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-32.999, 156.38],
                                  [147.378, 143.028],
                                  [234.495, -122.438],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-32.325, 156.121],
                                  [148.178, 143.017],
                                  [232.119, -120.606],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-31.473, 155.818],
                                  [149.106, 142.977],
                                  [229.383, -118.434],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-30.567, 155.482],
                                  [150.165, 142.93],
                                  [226.224, -115.949],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-29.42, 155.103],
                                  [151.414, 142.864],
                                  [222.644, -113.075],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-28.22, 154.668],
                                  [152.817, 142.778],
                                  [218.542, -109.8],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.717, 154.192],
                                  [154.473, 142.746],
                                  [213.846, -106.064],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.038, 153.651],
                                  [156.358, 142.653],
                                  [208.431, -101.705],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.12, 153.058],
                                  [158.533, 142.548],
                                  [202.251, -96.672],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.901, 152.38],
                                  [161.062, 142.441],
                                  [195.144, -90.856],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-18.256, 151.621],
                                  [164.043, 142.316],
                                  [186.904, -84.144],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-15.187, 150.772],
                                  [167.476, 142.17],
                                  [177.435, -76.314],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.694, 149.826],
                                  [171.438, 142.01],
                                  [166.505, -67.241],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.591, 148.766],
                                  [176.066, 141.808],
                                  [153.911, -56.728],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-2.939, 147.598],
                                  [181.322, 141.558],
                                  [139.708, -44.826],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [2.258, 146.352],
                                  [187.155, 141.28],
                                  [124.115, -31.712],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.689, 145.066],
                                  [193.305, 140.947],
                                  [107.643, -17.864],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.102, 143.808],
                                  [199.443, 140.639],
                                  [91.089, -3.955],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [18.372, 142.612],
                                  [205.392, 140.233],
                                  [75.144, 9.424],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.183, 141.53],
                                  [210.864, 139.805],
                                  [60.31, 21.851],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [27.536, 140.55],
                                  [215.831, 139.308],
                                  [46.785, 33.12],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [31.428, 139.689],
                                  [220.293, 138.842],
                                  [34.539, 43.272],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [34.92, 138.936],
                                  [224.311, 138.317],
                                  [23.469, 52.352],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [38.012, 138.269],
                                  [227.881, 137.758],
                                  [13.43, 60.445],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [40.7, 137.686],
                                  [231.03, 137.148],
                                  [4.357, 67.773],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [43.109, 137.178],
                                  [233.88, 136.522],
                                  [-3.924, 74.299],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [45.298, 136.742],
                                  [236.438, 135.849],
                                  [-11.449, 80.214],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [47.139, 136.368],
                                  [238.685, 135.093],
                                  [-18.407, 85.509],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [48.819, 136.057],
                                  [240.725, 134.322],
                                  [-24.747, 90.276],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [50.271, 135.792],
                                  [242.547, 133.47],
                                  [-30.663, 94.611],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [51.555, 135.588],
                                  [244.184, 132.569],
                                  [-36.061, 98.474],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [52.605, 135.43],
                                  [245.571, 131.644],
                                  [-41.1, 101.952],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [53.544, 135.319],
                                  [246.857, 130.571],
                                  [-45.788, 105.052],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [54.305, 135.255],
                                  [247.95, 129.468],
                                  [-50.152, 107.821],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [54.886, 135.24],
                                  [248.876, 128.306],
                                  [-54.195, 110.228],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [55.348, 135.271],
                                  [249.666, 127.049],
                                  [-57.983, 112.336],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [55.625, 135.347],
                                  [250.287, 125.663],
                                  [-61.483, 114.2],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [55.843, 135.47],
                                  [250.832, 124.245],
                                  [-64.761, 115.76],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [55.879, 135.637],
                                  [251.209, 122.701],
                                  [-67.814, 117.051],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [55.801, 135.847],
                                  [251.484, 121.101],
                                  [-70.644, 118.11],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [55.615, 136.099],
                                  [251.663, 119.455],
                                  [-73.314, 118.915],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [55.333, 136.387],
                                  [251.724, 117.715],
                                  [-75.794, 119.575],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [54.967, 136.709],
                                  [251.742, 115.964],
                                  [-78.054, 120.081],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [54.597, 137.056],
                                  [251.695, 114.189],
                                  [-80.192, 120.419],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [54.113, 137.425],
                                  [251.6, 112.443],
                                  [-82.179, 120.672],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [53.653, 137.81],
                                  [251.463, 110.72],
                                  [-84.019, 120.836],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [53.231, 138.201],
                                  [251.359, 109.002],
                                  [-85.744, 120.987],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [52.73, 138.601],
                                  [251.17, 107.333],
                                  [-87.33, 121.039],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [52.28, 138.991],
                                  [251.059, 105.79],
                                  [-88.843, 121.104],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [51.885, 139.388],
                                  [250.926, 104.275],
                                  [-90.189, 121.147],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [51.483, 139.769],
                                  [250.777, 102.868],
                                  [-91.496, 121.174],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [51.073, 140.145],
                                  [250.646, 101.49],
                                  [-92.678, 121.24],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [50.715, 140.507],
                                  [250.559, 100.211],
                                  [-93.798, 121.251],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [50.407, 140.864],
                                  [250.442, 99.002],
                                  [-94.851, 121.278],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [50.082, 141.204],
                                  [250.373, 97.814],
                                  [-95.81, 121.295],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [49.801, 141.537],
                                  [250.267, 96.754],
                                  [-96.714, 121.32],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [49.498, 141.853],
                                  [250.169, 95.707],
                                  [-97.552, 121.327],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [49.232, 142.162],
                                  [250.102, 94.718],
                                  [-98.338, 121.333],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [48.938, 142.452],
                                  [250.001, 93.774],
                                  [-99.06, 121.325],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [48.738, 142.736],
                                  [249.951, 92.908],
                                  [-99.731, 121.324],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [48.444, 143.014],
                                  [249.838, 92.08],
                                  [-100.377, 121.276],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [48.239, 143.272],
                                  [249.773, 91.287],
                                  [-100.961, 121.227],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [47.997, 143.525],
                                  [249.666, 90.503],
                                  [-101.457, 121.189],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [47.778, 143.757],
                                  [249.578, 89.787],
                                  [-101.931, 121.122],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [47.519, 143.999],
                                  [249.484, 89.101],
                                  [-102.381, 121.022],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [47.343, 144.206],
                                  [249.393, 88.441],
                                  [-102.769, 120.95],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [47.124, 144.422],
                                  [249.292, 87.807],
                                  [-103.134, 120.843],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [46.86, 144.619],
                                  [249.144, 87.234],
                                  [-103.454, 120.697],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [46.675, 144.811],
                                  [249.033, 86.661],
                                  [-103.736, 120.551],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [46.443, 144.982],
                                  [248.912, 86.108],
                                  [-103.996, 120.388],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [46.226, 145.164],
                                  [248.802, 85.575],
                                  [-104.194, 120.182],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [46.022, 145.326],
                                  [248.664, 85.061],
                                  [-104.387, 120.035],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [45.769, 145.484],
                                  [248.513, 84.604],
                                  [-104.543, 119.804],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [45.591, 145.621],
                                  [248.396, 84.163],
                                  [-104.638, 119.567],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [45.362, 145.754],
                                  [248.225, 83.738],
                                  [-104.773, 119.346],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [45.081, 145.883],
                                  [248.04, 83.306],
                                  [-104.826, 119.079],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [44.873, 146.008],
                                  [247.886, 82.95],
                                  [-104.88, 118.785],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [44.675, 146.13],
                                  [247.74, 82.546],
                                  [-104.913, 118.483],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [44.424, 146.23],
                                  [247.539, 82.194],
                                  [-104.885, 118.194],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [44.182, 146.327],
                                  [247.346, 81.855],
                                  [-104.836, 117.855],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [43.908, 146.358],
                                  [247.074, 81.505],
                                  [-104.749, 117.546],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [43.539, 146.261],
                                  [246.676, 81.167],
                                  [-104.708, 117.266],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [43.056, 146.058],
                                  [246.076, 80.774],
                                  [-104.546, 117.016],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [42.498, 145.664],
                                  [245.35, 80.391],
                                  [-104.407, 116.833],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [41.762, 145.142],
                                  [244.42, 79.975],
                                  [-104.209, 116.681],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [40.929, 144.43],
                                  [243.264, 79.526],
                                  [-103.952, 116.577],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [39.978, 143.55],
                                  [241.9, 79.064],
                                  [-103.675, 116.522],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [38.806, 142.457],
                                  [240.271, 78.548],
                                  [-103.319, 116.536],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [37.494, 141.156],
                                  [238.374, 77.997],
                                  [-102.902, 116.6],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [36.021, 139.602],
                                  [236.189, 77.413],
                                  [-102.444, 116.714],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [34.284, 137.774],
                                  [233.675, 76.774],
                                  [-101.904, 116.917],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [32.301, 135.632],
                                  [230.79, 76.08],
                                  [-101.261, 117.211],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [30.073, 133.155],
                                  [227.493, 75.331],
                                  [-100.556, 117.576],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [27.515, 130.3],
                                  [223.722, 74.505],
                                  [-99.726, 118.052],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.608, 127.027],
                                  [219.436, 73.604],
                                  [-98.792, 118.641],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [21.307, 123.231],
                                  [214.532, 72.605],
                                  [-97.732, 119.343],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [17.53, 118.892],
                                  [208.927, 71.489],
                                  [-96.484, 120.199],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.172, 113.863],
                                  [202.538, 70.256],
                                  [-95.087, 121.231],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.231, 108.062],
                                  [195.157, 68.863],
                                  [-93.439, 122.461],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [2.519, 101.383],
                                  [186.684, 67.29],
                                  [-91.558, 123.911],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-4.068, 93.598],
                                  [176.889, 65.497],
                                  [-89.36, 125.644],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.699, 84.582],
                                  [165.567, 63.463],
                                  [-86.823, 127.683],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.563, 74.126],
                                  [152.492, 61.147],
                                  [-83.883, 130.071],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-30.703, 62.064],
                                  [137.459, 58.509],
                                  [-80.496, 132.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-42.164, 48.436],
                                  [120.43, 55.549],
                                  [-76.659, 136.096],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-54.823, 33.368],
                                  [101.701, 52.311],
                                  [-72.413, 139.676],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.222, 17.402],
                                  [81.921, 48.9],
                                  [-67.9, 143.492],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-81.738, 1.37],
                                  [62.027, 45.483],
                                  [-63.331, 147.377],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-94.742, -14.081],
                                  [42.911, 42.203],
                                  [-58.934, 151.119],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-106.901, -28.493],
                                  [25.133, 39.164],
                                  [-54.818, 154.633],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-118.002, -41.698],
                                  [8.914, 36.384],
                                  [-51.047, 157.871],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.086, -53.635],
                                  [-5.769, 33.861],
                                  [-47.583, 160.832],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.255, -64.385],
                                  [-18.983, 31.596],
                                  [-44.448, 163.51],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.549, -74.137],
                                  [-30.94, 29.544],
                                  [-41.602, 165.947],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.113, -83.015],
                                  [-41.767, 27.684],
                                  [-39.005, 168.181],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.008, -91.082],
                                  [-51.632, 25.974],
                                  [-36.594, 170.233],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.36, -98.503],
                                  [-60.598, 24.434],
                                  [-34.414, 172.121],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.188, -105.301],
                                  [-68.877, 23.001],
                                  [-32.359, 173.866],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.575, -111.516],
                                  [-76.446, 21.696],
                                  [-30.473, 175.467],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.584, -117.315],
                                  [-83.455, 20.476],
                                  [-28.713, 176.965],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.235, -122.677],
                                  [-89.923, 19.363],
                                  [-27.081, 178.38],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.57, -127.666],
                                  [-95.955, 18.314],
                                  [-25.534, 179.691],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-195.609, -132.321],
                                  [-101.532, 17.349],
                                  [-24.115, 180.919],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.373, -136.664],
                                  [-106.736, 16.448],
                                  [-22.782, 182.062],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.924, -140.737],
                                  [-111.631, 15.591],
                                  [-21.494, 183.141],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.283, -144.54],
                                  [-116.176, 14.796],
                                  [-20.292, 184.179],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.388, -148.113],
                                  [-120.432, 14.065],
                                  [-19.198, 185.131],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.384, -151.479],
                                  [-124.463, 13.356],
                                  [-18.107, 186.041],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.126, -154.659],
                                  [-128.206, 12.71],
                                  [-17.124, 186.907],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.78, -157.629],
                                  [-131.745, 12.086],
                                  [-16.164, 187.73],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-220.242, -160.435],
                                  [-135.08, 11.503],
                                  [-15.27, 188.487],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-222.574, -163.094],
                                  [-138.19, 10.963],
                                  [-14.423, 189.222],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.798, -165.566],
                                  [-141.159, 10.444],
                                  [-13.62, 189.914],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-226.892, -167.913],
                                  [-143.945, 9.946],
                                  [-12.82, 190.56],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-228.856, -170.156],
                                  [-146.568, 9.49],
                                  [-12.108, 191.185],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-230.754, -172.255],
                                  [-149.05, 9.055],
                                  [-11.419, 191.764],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-232.502, -174.249],
                                  [-151.349, 8.662],
                                  [-10.798, 192.321],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-234.162, -176.118],
                                  [-153.55, 8.269],
                                  [-10.179, 192.855],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-235.733, -177.863],
                                  [-155.65, 7.898],
                                  [-9.564, 193.366],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-237.259, -179.545],
                                  [-157.567, 7.567],
                                  [-9.056, 193.832],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-238.635, -181.103],
                                  [-159.427, 7.237],
                                  [-8.51, 194.274],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-240.007, -182.619],
                                  [-161.166, 6.928],
                                  [-8.01, 194.716],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.249, -184.01],
                                  [-162.806, 6.639],
                                  [-7.534, 195.111],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-242.425, -185.319],
                                  [-164.345, 6.372],
                                  [-7.102, 195.484],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-243.556, -186.543],
                                  [-165.765, 6.125],
                                  [-6.696, 195.855],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-244.598, -187.728],
                                  [-167.147, 5.878],
                                  [-6.292, 196.18],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-245.595, -188.828],
                                  [-168.41, 5.652],
                                  [-5.912, 196.504],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-246.526, -189.846],
                                  [-169.593, 5.446],
                                  [-5.578, 196.804],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.411, -190.801],
                                  [-170.738, 5.241],
                                  [-5.226, 197.081],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-248.209, -191.714],
                                  [-171.764, 5.056],
                                  [-4.918, 197.356],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-248.961, -192.545],
                                  [-172.731, 4.892],
                                  [-4.656, 197.585],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.709, -193.334],
                                  [-173.64, 4.728],
                                  [-4.376, 197.835],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.35, -194.061],
                                  [-174.469, 4.584],
                                  [-4.14, 198.039],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.945, -194.725],
                                  [-175.262, 4.441],
                                  [-3.887, 198.242],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.538, -195.327],
                                  [-175.975, 4.318],
                                  [-3.699, 198.42],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-252.042, -195.929],
                                  [-176.63, 4.195],
                                  [-3.473, 198.598],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-252.543, -196.469],
                                  [-177.226, 4.093],
                                  [-3.312, 198.751],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-252.957, -196.946],
                                  [-177.785, 3.99],
                                  [-3.133, 198.88],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-253.348, -197.362],
                                  [-178.264, 3.908],
                                  [-2.999, 199.008],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-253.693, -197.735],
                                  [-178.726, 3.827],
                                  [-2.847, 199.135],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.036, -198.088],
                                  [-179.088, 3.765],
                                  [-2.76, 199.238],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.29, -198.399],
                                  [-179.453, 3.703],
                                  [-2.655, 199.316],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.542, -198.648],
                                  [-179.761, 3.642],
                                  [-2.552, 199.394],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.749, -198.877],
                                  [-180.009, 3.601],
                                  [-2.474, 199.47],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.911, -199.063],
                                  [-180.24, 3.56],
                                  [-2.397, 199.522],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.05, -199.23],
                                  [-180.391, 3.54],
                                  [-2.366, 199.573],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.163, -199.354],
                                  [-180.547, 3.499],
                                  [-2.294, 199.6],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.255, -199.416],
                                  [-180.6, 3.499],
                                  [-2.309, 199.626],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.301, -199.479],
                                  [-180.679, 3.479],
                                  [-2.263, 199.651],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.302, -199.478],
                                  [-180.698, 3.478],
                                  [-2.262, 199.651],
                                ],
                                c: false,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.242, -199.417],
                                  [-180.676, 3.48],
                                  [-2.237, 199.6],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-255.121, -199.299],
                                  [-180.569, 3.566],
                                  [-2.223, 199.339],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.856, -198.996],
                                  [-180.445, 3.698],
                                  [-2.118, 198.931],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.481, -198.636],
                                  [-180.199, 3.853],
                                  [-2.011, 198.374],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-254.037, -198.134],
                                  [-179.923, 4.074],
                                  [-1.882, 197.603],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-253.415, -197.47],
                                  [-179.534, 4.361],
                                  [-1.692, 196.576],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-252.653, -196.707],
                                  [-179.036, 4.715],
                                  [-1.443, 195.352],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-251.724, -195.699],
                                  [-178.476, 5.155],
                                  [-1.162, 193.867],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-250.644, -194.55],
                                  [-177.819, 5.621],
                                  [-0.809, 192.12],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-249.385, -193.197],
                                  [-177.006, 6.218],
                                  [-0.409, 190.085],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-247.898, -191.599],
                                  [-176.063, 6.947],
                                  [0.054, 187.699],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-246.154, -189.754],
                                  [-174.935, 7.745],
                                  [0.639, 184.897],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-244.206, -187.62],
                                  [-173.672, 8.656],
                                  [1.321, 181.717],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-241.917, -185.154],
                                  [-172.134, 9.743],
                                  [2.115, 178.075],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-239.316, -182.333],
                                  [-170.393, 10.987],
                                  [3.039, 173.862],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-236.346, -179.07],
                                  [-168.352, 12.411],
                                  [4.15, 169.119],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-232.968, -175.319],
                                  [-166, 13.998],
                                  [5.425, 163.679],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-229.1, -170.969],
                                  [-163.22, 15.918],
                                  [6.967, 157.436],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-224.654, -165.949],
                                  [-159.955, 18.07],
                                  [8.82, 150.272],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-219.52, -160.076],
                                  [-156.124, 20.566],
                                  [11.054, 142.128],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.648, -153.229],
                                  [-151.535, 23.496],
                                  [13.751, 132.749],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.902, -145.23],
                                  [-146.001, 26.892],
                                  [17.112, 121.997],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.179, -135.874],
                                  [-139.417, 30.827],
                                  [21.199, 109.686],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-190.394, -124.961],
                                  [-131.532, 35.4],
                                  [26.215, 95.764],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.544, -112.404],
                                  [-122.191, 40.625],
                                  [32.327, 80.289],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.871, -98.334],
                                  [-111.356, 46.424],
                                  [39.63, 63.59],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.813, -83.183],
                                  [-99.29, 52.641],
                                  [47.992, 46.359],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-147.884, -67.618],
                                  [-86.475, 58.883],
                                  [57.078, 29.504],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.545, -52.314],
                                  [-73.488, 65.009],
                                  [66.578, 13.754],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.095, -37.832],
                                  [-60.788, 70.752],
                                  [76.057, -0.461],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.686, -24.331],
                                  [-48.63, 76.084],
                                  [85.256, -13.065],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-112.221, -11.896],
                                  [-37.174, 80.904],
                                  [94.135, -24.139],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-105.622, -0.488],
                                  [-26.426, 85.301],
                                  [102.529, -33.912],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-99.757, 9.958],
                                  [-16.358, 89.3],
                                  [110.446, -42.456],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-94.532, 19.598],
                                  [-6.941, 92.966],
                                  [117.953, -50.054],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-89.858, 28.479],
                                  [1.9, 96.329],
                                  [125.037, -56.814],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-85.656, 36.648],
                                  [10.164, 99.421],
                                  [131.737, -62.869],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-81.857, 44.282],
                                  [17.927, 102.274],
                                  [138.04, -68.278],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-78.428, 51.313],
                                  [25.244, 104.889],
                                  [144.039, -73.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-75.283, 57.923],
                                  [32.115, 107.362],
                                  [149.676, -77.615],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-72.429, 64.079],
                                  [38.61, 109.629],
                                  [155.023, -81.639],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.787, 69.869],
                                  [44.74, 111.726],
                                  [160.13, -85.33],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-67.372, 75.263],
                                  [50.552, 113.743],
                                  [164.947, -88.693],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-65.14, 80.353],
                                  [56.032, 115.593],
                                  [169.561, -91.771],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-63.084, 85.134],
                                  [61.235, 117.335],
                                  [173.9, -94.633],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.161, 89.676],
                                  [66.178, 118.968],
                                  [178.052, -97.279],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.396, 93.911],
                                  [70.858, 120.529],
                                  [181.981, -99.679],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-57.747, 97.93],
                                  [75.294, 121.981],
                                  [185.743, -101.969],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-56.179, 101.71],
                                  [79.522, 123.362],
                                  [189.332, -104.049],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-54.783, 105.34],
                                  [83.562, 124.671],
                                  [192.724, -105.991],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-53.424, 108.724],
                                  [87.349, 125.88],
                                  [195.997, -107.762],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-52.199, 111.931],
                                  [90.975, 127.039],
                                  [199.066, -109.454],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-51.009, 114.989],
                                  [94.443, 128.135],
                                  [202.018, -111.008],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-49.904, 117.897],
                                  [97.708, 129.153],
                                  [204.815, -112.486],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.896, 120.628],
                                  [100.838, 130.136],
                                  [207.471, -113.864],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.941, 123.209],
                                  [103.793, 131.05],
                                  [210.003, -115.16],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.006, 125.65],
                                  [106.618, 131.921],
                                  [212.445, -116.322],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-46.163, 127.968],
                                  [109.299, 132.785],
                                  [214.707, -117.439],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-45.363, 130.173],
                                  [111.811, 133.519],
                                  [216.912, -118.486],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-44.615, 132.274],
                                  [114.213, 134.309],
                                  [218.998, -119.464],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-43.916, 134.215],
                                  [116.53, 134.968],
                                  [220.963, -120.363],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-43.291, 136.116],
                                  [118.683, 135.647],
                                  [222.807, -121.22],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-42.65, 137.868],
                                  [120.749, 136.259],
                                  [224.591, -122.036],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-42.081, 139.507],
                                  [122.675, 136.839],
                                  [226.252, -122.773],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-41.529, 141.071],
                                  [124.513, 137.404],
                                  [227.828, -123.468],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-41.02, 142.548],
                                  [126.246, 137.912],
                                  [229.316, -124.123],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-40.542, 143.95],
                                  [127.837, 138.404],
                                  [230.743, -124.736],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-40.116, 145.24],
                                  [129.374, 138.866],
                                  [232.058, -125.298],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.692, 146.481],
                                  [130.831, 139.287],
                                  [233.311, -125.818],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.283, 147.611],
                                  [132.181, 139.678],
                                  [234.513, -126.324],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-38.937, 148.667],
                                  [133.451, 140.065],
                                  [235.591, -126.763],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-38.604, 149.674],
                                  [134.603, 140.41],
                                  [236.619, -127.213],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-38.308, 150.608],
                                  [135.711, 140.752],
                                  [237.559, -127.585],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-38.048, 151.493],
                                  [136.737, 141.028],
                                  [238.449, -127.941],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-37.761, 152.269],
                                  [137.656, 141.337],
                                  [239.277, -128.257],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-37.51, 152.996],
                                  [138.556, 141.568],
                                  [240.018, -128.557],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-37.306, 153.689],
                                  [139.311, 141.832],
                                  [240.72, -128.842],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-37.099, 154.297],
                                  [140.059, 142.031],
                                  [241.324, -129.111],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.927, 154.833],
                                  [140.714, 142.227],
                                  [241.915, -129.327],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.762, 155.322],
                                  [141.335, 142.382],
                                  [242.42, -129.526],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.606, 155.777],
                                  [141.813, 142.571],
                                  [242.875, -129.684],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.471, 156.148],
                                  [142.308, 142.72],
                                  [243.307, -129.852],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.355, 156.51],
                                  [142.722, 142.841],
                                  [243.664, -129.977],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.298, 156.801],
                                  [143.029, 142.921],
                                  [243.935, -130.111],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.184, 157.047],
                                  [143.354, 142.999],
                                  [244.221, -130.228],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.103, 157.222],
                                  [143.572, 143.074],
                                  [244.421, -130.266],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.091, 157.414],
                                  [143.772, 143.121],
                                  [244.585, -130.349],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.047, 157.511],
                                  [143.903, 143.191],
                                  [244.689, -130.377],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.01, 157.588],
                                  [143.952, 143.196],
                                  [244.784, -130.412],
                                ],
                                c: false,
                              },
                            ],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.416, 0.416, 0.416, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.416, 0.416, 0.416, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [163.163, 91.157],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.157, 91.273],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.142, 91.415],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.109, 91.654],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.072, 91.988],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.043, 92.431],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.97, 92.944],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.916, 93.567],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.876, 94.332],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.792, 95.201],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.735, 96.231],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.692, 97.435],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.671, 98.806],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.657, 100.41],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.731, 102.201],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.833, 104.288],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.088, 106.645],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.456, 109.345],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.036, 112.435],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.93, 115.96],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.306, 119.901],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.292, 124.277],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [171.132, 129.01],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.18, 133.871],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.604, 138.341],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.743, 141.291],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.698, 141.005],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.612, 140.687],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.183, 140.248],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.469, 138.712],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.035, 136.296],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.392, 134.262],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.956, 132.601],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.974, 131.302],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.699, 130.314],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.268, 129.558],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.754, 128.962],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.189, 128.44],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.662, 128.007],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.139, 127.58],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.684, 127.155],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.234, 126.741],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.856, 126.211],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.521, 125.661],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.232, 125.047],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.982, 124.338],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.797, 123.513],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.666, 122.628],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.565, 121.609],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.537, 120.516],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.558, 119.348],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.612, 118.085],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.78, 116.787],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.955, 115.442],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.222, 114.105],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.515, 112.769],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.888, 111.437],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.291, 110.122],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.781, 108.912],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.31, 107.719],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.844, 106.608],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.465, 105.527],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.131, 104.514],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.775, 103.561],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.522, 102.62],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.231, 101.783],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.991, 100.951],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.788, 100.166],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.593, 99.41],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.422, 98.721],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.247, 98.048],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.103, 97.405],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.978, 96.768],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.866, 96.18],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.779, 95.606],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.679, 95.061],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.604, 94.527],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.518, 94.032],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.451, 93.538],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.402, 93.054],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.39, 92.576],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.341, 92.126],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.318, 91.702],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.318, 91.291],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.267, 90.896],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.27, 90.48],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.261, 90.125],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.27, 89.727],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.272, 89.372],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.287, 89.016],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.25, 88.658],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.09, 88.316],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.837, 87.931],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.452, 87.57],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.965, 87.183],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.304, 86.778],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.481, 86.372],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.517, 85.928],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.361, 85.464],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.983, 84.981],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [169.412, 84.462],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.604, 83.907],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.494, 83.315],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.09, 82.668],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.344, 81.967],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.177, 81.19],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [153.585, 80.316],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.502, 79.339],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.759, 78.223],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.331, 76.927],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.087, 75.392],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.881, 73.561],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.607, 71.319],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.081, 68.532],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.217, 65.036],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.108, 60.637],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.878, 55.241],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.696, 49.003],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.778, 42.346],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.62, 37.363],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.157, 32.842],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.561, 29.003],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.953, 25.94],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.508, 23.603],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.607, 21.877],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56.546, 20.666],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.564, 19.825],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.856, 19.301],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.482, 19.014],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.605, 18.878],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.254, 18.88],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.644, 19.026],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.641, 19.232],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.218, 19.442],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.449, 19.647],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.325, 19.832],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.918, 20.002],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.273, 20.158],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.371, 20.292],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126.253, 20.431],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.962, 20.541],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.466, 20.648],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.837, 20.746],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.035, 20.841],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.112, 20.917],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.069, 20.978],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-141.886, 21.028],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.587, 21.096],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.196, 21.17],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.712, 21.181],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.127, 21.241],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-149.468, 21.271],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.714, 21.317],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.893, 21.348],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-152.982, 21.371],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.033, 21.394],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.991, 21.412],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.892, 21.431],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.743, 21.462],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.518, 21.476],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.256, 21.487],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.935, 21.505],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.566, 21.511],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.147, 21.533],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.691, 21.537],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.18, 21.541],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.644, 21.533],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.056, 21.542],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.419, 21.547],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.748, 21.569],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.043, 21.552],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.316, 21.558],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.535, 21.566],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.724, 21.568],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.889, 21.577],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.019, 21.567],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.182, 21.56],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.238, 21.575],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.202, 21.586],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.18, 21.557],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.1, 21.592],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.958, 21.583],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.815, 21.583],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.602, 21.58],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.333, 21.571],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.018, 21.608],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.637, 21.617],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.192, 21.642],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.686, 21.702],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.046, 21.76],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.329, 21.852],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.461, 21.986],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.486, 22.169],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.309, 22.444],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.929, 22.792],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.316, 23.322],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-152.355, 24.022],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-149.963, 25.046],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.034, 26.471],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.318, 28.456],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.596, 31.23],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.859, 34.508],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.945, 38.649],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.136, 44.494],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.464, 52.243],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.588, 58.292],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.227, 62.352],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.032, 64.634],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-32.886, 65.6],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.122, 65.662],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.819, 65.326],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.13, 64.895],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.925, 64.492],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.711, 64.228],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.609, 64.119],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.753, 64.182],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.258, 64.395],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.185, 64.769],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.617, 65.258],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.627, 65.81],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.236, 66.523],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.496, 67.256],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.43, 68.051],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.094, 68.874],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.47, 69.773],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.616, 70.638],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.547, 71.535],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.263, 72.479],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.772, 73.361],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.094, 74.268],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.272, 75.162],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.267, 76.004],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.112, 76.88],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.805, 77.705],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.381, 78.525],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.809, 79.35],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.101, 80.072],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.291, 80.837],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.374, 81.559],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.32, 82.27],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.193, 82.92],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.932, 83.561],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.591, 84.181],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.153, 84.76],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.607, 85.292],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.987, 85.836],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [151.298, 86.34],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.529, 86.786],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [153.666, 87.256],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.717, 87.643],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [155.708, 88.065],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.625, 88.434],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.47, 88.771],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.266, 89.091],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.963, 89.379],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [159.625, 89.644],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.217, 89.893],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.764, 90.121],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.212, 90.312],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.659, 90.498],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.029, 90.659],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.304, 90.768],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.6, 90.865],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.801, 90.957],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.971, 91.041],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.09, 91.11],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [163.163, 91.157],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.157, 91.273],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.142, 91.415],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.109, 91.654],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.072, 91.988],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.043, 92.431],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.97, 92.944],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.916, 93.567],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.876, 94.332],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.792, 95.201],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.735, 96.231],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.692, 97.435],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.671, 98.806],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.657, 100.41],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.731, 102.201],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.833, 104.288],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.088, 106.645],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.456, 109.345],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.036, 112.435],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.93, 115.96],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.306, 119.901],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.292, 124.277],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [171.132, 129.01],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.18, 133.871],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.604, 138.341],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.743, 141.291],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.698, 141.005],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.612, 140.687],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.183, 140.248],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.469, 138.712],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.035, 136.296],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.392, 134.262],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.956, 132.601],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.974, 131.302],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.699, 130.314],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.268, 129.558],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.754, 128.962],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.189, 128.44],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.662, 128.007],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.139, 127.58],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.684, 127.155],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.234, 126.741],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.856, 126.211],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.521, 125.661],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.232, 125.047],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.982, 124.338],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.797, 123.513],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.666, 122.628],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.565, 121.609],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.537, 120.516],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.558, 119.348],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.612, 118.085],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.78, 116.787],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.955, 115.442],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.222, 114.105],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.515, 112.769],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.888, 111.437],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.291, 110.122],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.781, 108.912],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.31, 107.719],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.844, 106.608],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.465, 105.527],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.131, 104.514],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.775, 103.561],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.522, 102.62],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.231, 101.783],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.991, 100.951],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.788, 100.166],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.593, 99.41],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.422, 98.721],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.247, 98.048],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.103, 97.405],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.978, 96.768],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.866, 96.18],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.779, 95.606],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.679, 95.061],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.604, 94.527],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.518, 94.032],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.451, 93.538],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.402, 93.054],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.39, 92.576],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.341, 92.126],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.318, 91.702],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.318, 91.291],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.267, 90.896],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.27, 90.48],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.261, 90.125],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.27, 89.727],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.272, 89.372],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.287, 89.016],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.25, 88.658],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.09, 88.316],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.837, 87.931],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.452, 87.57],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.965, 87.183],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.304, 86.778],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.481, 86.372],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.517, 85.928],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.361, 85.464],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.983, 84.981],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [169.412, 84.462],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.604, 83.907],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.494, 83.315],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.09, 82.668],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.344, 81.967],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.177, 81.19],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [153.585, 80.316],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.502, 79.339],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.759, 78.223],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.331, 76.927],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.087, 75.392],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.881, 73.561],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [117.607, 71.319],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.081, 68.532],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.217, 65.036],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [85.108, 60.637],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.878, 55.241],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [57.696, 49.003],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.778, 42.346],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.62, 37.363],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.157, 32.842],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-12.561, 29.003],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.953, 25.94],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-37.508, 23.603],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.607, 21.877],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-56.546, 20.666],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.564, 19.825],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-71.856, 19.301],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-78.482, 19.014],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-84.605, 18.878],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-90.254, 18.88],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.644, 19.026],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-100.641, 19.232],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-105.218, 19.442],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.449, 19.647],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.325, 19.832],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.918, 20.002],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.273, 20.158],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.371, 20.292],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-126.253, 20.431],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.962, 20.541],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.466, 20.648],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.837, 20.746],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-136.035, 20.841],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.112, 20.917],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.069, 20.978],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-141.886, 21.028],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.587, 21.096],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-145.196, 21.17],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.712, 21.181],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.127, 21.241],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-149.468, 21.271],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.714, 21.317],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.893, 21.348],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-152.982, 21.371],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.033, 21.394],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.991, 21.412],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.892, 21.431],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.743, 21.462],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.518, 21.476],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.256, 21.487],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.935, 21.505],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.566, 21.511],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.147, 21.533],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.691, 21.537],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.18, 21.541],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.644, 21.533],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.056, 21.542],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.419, 21.547],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.748, 21.569],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.043, 21.552],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.316, 21.558],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.535, 21.566],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.724, 21.568],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.889, 21.577],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.019, 21.567],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.182, 21.56],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.238, 21.575],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.202, 21.586],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.18, 21.557],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.1, 21.592],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.958, 21.583],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.815, 21.583],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.602, 21.58],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.333, 21.571],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.018, 21.608],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.637, 21.617],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.192, 21.642],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.686, 21.702],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.046, 21.76],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.329, 21.852],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.461, 21.986],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.486, 22.169],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.309, 22.444],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.929, 22.792],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.316, 23.322],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-152.355, 24.022],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-149.963, 25.046],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.034, 26.471],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-143.318, 28.456],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.596, 31.23],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-131.859, 34.508],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.945, 38.649],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.136, 44.494],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.464, 52.243],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-83.588, 58.292],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-66.227, 62.352],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-49.032, 64.634],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-32.886, 65.6],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.122, 65.662],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-4.819, 65.326],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.13, 64.895],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.925, 64.492],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.711, 64.228],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.609, 64.119],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.753, 64.182],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.258, 64.395],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [59.185, 64.769],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.617, 65.258],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.627, 65.81],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.236, 66.523],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [82.496, 67.256],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [87.43, 68.051],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.094, 68.874],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.47, 69.773],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [100.616, 70.638],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.547, 71.535],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [108.263, 72.479],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [111.772, 73.361],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.094, 74.268],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.272, 75.162],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.267, 76.004],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.112, 76.88],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.805, 77.705],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.381, 78.525],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.809, 79.35],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [134.101, 80.072],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.291, 80.837],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.374, 81.559],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.32, 82.27],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.193, 82.92],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.932, 83.561],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.591, 84.181],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.153, 84.76],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.607, 85.292],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.987, 85.836],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [151.298, 86.34],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.529, 86.786],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [153.666, 87.256],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.717, 87.643],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [155.708, 88.065],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.625, 88.434],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.47, 88.771],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.266, 89.091],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.963, 89.379],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [159.625, 89.644],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.217, 89.893],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.764, 90.121],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.212, 90.312],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.659, 90.498],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.029, 90.659],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.304, 90.768],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.6, 90.865],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.801, 90.957],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.971, 91.041],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.09, 91.11],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 10',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 10,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.006, 171.993],
                                  [155.994, 147.998],
                                  [263.949, -115.945],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.951, 171.942],
                                  [156.07, 148.015],
                                  [263.728, -115.787],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.839, 171.885],
                                  [156.182, 148.01],
                                  [263.374, -115.553],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.67, 171.8],
                                  [156.351, 148.003],
                                  [262.803, -115.158],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.507, 171.688],
                                  [156.556, 148.014],
                                  [262.14, -114.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.224, 171.549],
                                  [156.859, 148.043],
                                  [261.218, -113.952],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.948, 171.381],
                                  [157.177, 148.049],
                                  [260.098, -113.142],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.553, 171.165],
                                  [157.593, 148.072],
                                  [258.761, -112.131],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.102, 170.921],
                                  [158.107, 148.134],
                                  [257.164, -110.982],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.596, 170.628],
                                  [158.655, 148.151],
                                  [255.329, -109.591],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.972, 170.287],
                                  [159.343, 148.164],
                                  [253.15, -107.939],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.292, 169.917],
                                  [160.127, 148.236],
                                  [250.649, -106.087],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.434, 169.479],
                                  [161.049, 148.281],
                                  [247.763, -103.891],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-18.521, 168.992],
                                  [162.09, 148.342],
                                  [244.431, -101.372],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.366, 168.437],
                                  [163.329, 148.397],
                                  [240.653, -98.468],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.158, 167.794],
                                  [164.707, 148.445],
                                  [236.325, -95.159],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.647, 167.084],
                                  [166.347, 148.568],
                                  [231.365, -91.382],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.958, 166.268],
                                  [168.208, 148.659],
                                  [225.65, -86.973],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.029, 165.367],
                                  [170.351, 148.761],
                                  [219.119, -81.89],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.799, 164.323],
                                  [172.84, 148.891],
                                  [211.608, -76.01],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.142, 163.138],
                                  [175.778, 149.046],
                                  [202.893, -69.229],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-3.06, 161.796],
                                  [179.165, 149.223],
                                  [192.872, -61.319],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.447, 160.281],
                                  [183.065, 149.438],
                                  [181.302, -52.157],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [4.565, 158.558],
                                  [187.622, 149.667],
                                  [167.962, -41.537],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.232, 156.634],
                                  [192.796, 149.904],
                                  [152.919, -29.522],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.447, 154.554],
                                  [198.544, 150.167],
                                  [136.389, -16.282],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [19.896, 152.381],
                                  [204.596, 150.412],
                                  [118.93, -2.297],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.328, 150.235],
                                  [210.637, 150.683],
                                  [101.375, 11.747],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [30.618, 148.174],
                                  [216.498, 150.837],
                                  [84.464, 25.264],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [35.451, 146.292],
                                  [221.888, 150.939],
                                  [68.731, 37.816],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [39.826, 144.57],
                                  [226.782, 150.929],
                                  [54.381, 49.213],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [43.742, 143.042],
                                  [231.18, 150.909],
                                  [41.389, 59.478],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [47.261, 141.687],
                                  [235.143, 150.794],
                                  [29.648, 68.669],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [50.38, 140.467],
                                  [238.669, 150.607],
                                  [18.996, 76.867],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [53.097, 139.382],
                                  [241.778, 150.341],
                                  [9.367, 84.298],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [55.536, 138.412],
                                  [244.594, 150.036],
                                  [0.576, 90.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [57.757, 137.555],
                                  [247.133, 149.667],
                                  [-7.417, 96.935],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [59.633, 136.794],
                                  [249.357, 149.191],
                                  [-14.802, 102.323],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [61.348, 136.127],
                                  [251.384, 148.687],
                                  [-21.536, 107.182],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [62.838, 135.522],
                                  [253.194, 148.088],
                                  [-27.823, 111.612],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [64.162, 135.01],
                                  [254.826, 147.431],
                                  [-33.561, 115.568],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [65.254, 134.56],
                                  [256.212, 146.735],
                                  [-38.917, 119.133],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [66.237, 134.173],
                                  [257.501, 145.887],
                                  [-43.906, 122.333],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [67.045, 133.849],
                                  [258.602, 144.994],
                                  [-48.551, 125.196],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [67.674, 133.588],
                                  [259.537, 144.042],
                                  [-52.854, 127.695],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [68.185, 133.39],
                                  [260.342, 142.989],
                                  [-56.891, 129.903],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [68.514, 133.244],
                                  [260.978, 141.797],
                                  [-60.624, 131.865],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [68.785, 133.16],
                                  [261.545, 140.576],
                                  [-64.127, 133.52],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [68.876, 133.128],
                                  [261.944, 139.217],
                                  [-67.389, 134.908],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [68.852, 133.146],
                                  [262.242, 137.8],
                                  [-70.422, 136.068],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [68.72, 133.214],
                                  [262.443, 136.324],
                                  [-73.277, 136.966],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [68.491, 133.335],
                                  [262.53, 134.758],
                                  [-75.94, 137.723],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [68.177, 133.489],
                                  [262.567, 133.164],
                                  [-78.375, 138.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [67.856, 133.686],
                                  [262.546, 131.545],
                                  [-80.676, 138.737],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [67.417, 133.905],
                                  [262.465, 129.947],
                                  [-82.817, 139.076],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [66.999, 134.149],
                                  [262.348, 128.357],
                                  [-84.802, 139.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [66.614, 134.409],
                                  [262.261, 126.763],
                                  [-86.669, 139.528],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [66.147, 134.678],
                                  [262.085, 125.214],
                                  [-88.388, 139.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [65.727, 134.957],
                                  [261.98, 123.776],
                                  [-90.024, 139.762],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [65.359, 135.234],
                                  [261.859, 122.366],
                                  [-91.49, 139.855],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [64.979, 135.514],
                                  [261.719, 121.045],
                                  [-92.912, 139.923],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [64.589, 135.79],
                                  [261.59, 119.755],
                                  [-94.198, 140.03],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [64.248, 136.062],
                                  [261.503, 118.555],
                                  [-95.415, 140.079],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [63.954, 136.329],
                                  [261.392, 117.413],
                                  [-96.563, 140.131],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [63.641, 136.59],
                                  [261.319, 116.294],
                                  [-97.613, 140.179],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [63.371, 136.844],
                                  [261.217, 115.29],
                                  [-98.598, 140.224],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [63.077, 137.091],
                                  [261.117, 114.302],
                                  [-99.518, 140.257],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [62.818, 137.331],
                                  [261.046, 113.362],
                                  [-100.376, 140.279],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [62.53, 137.563],
                                  [260.941, 112.464],
                                  [-101.169, 140.284],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [62.336, 137.788],
                                  [260.89, 111.638],
                                  [-101.903, 140.299],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [62.045, 138.008],
                                  [260.772, 110.848],
                                  [-102.606, 140.261],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [61.844, 138.218],
                                  [260.704, 110.093],
                                  [-103.247, 140.227],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [61.604, 138.422],
                                  [260.594, 109.34],
                                  [-103.796, 140.197],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [61.388, 138.616],
                                  [260.503, 108.651],
                                  [-104.317, 140.137],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [61.13, 138.81],
                                  [260.401, 107.99],
                                  [-104.81, 140.044],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [60.955, 138.988],
                                  [260.31, 107.355],
                                  [-105.241, 139.978],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [60.736, 139.167],
                                  [260.205, 106.745],
                                  [-105.644, 139.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [60.471, 139.335],
                                  [260.053, 106.191],
                                  [-105.992, 139.734],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [60.286, 139.499],
                                  [259.941, 105.631],
                                  [-106.307, 139.588],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [60.053, 139.651],
                                  [259.814, 105.092],
                                  [-106.595, 139.428],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [59.835, 139.806],
                                  [259.699, 104.572],
                                  [-106.822, 139.226],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [59.63, 139.949],
                                  [259.56, 104.07],
                                  [-107.029, 139.077],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [59.376, 140.089],
                                  [259.404, 103.62],
                                  [-107.203, 138.849],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [59.196, 140.217],
                                  [259.285, 103.187],
                                  [-107.316, 138.61],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [58.965, 140.341],
                                  [259.112, 102.769],
                                  [-107.466, 138.387],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [58.683, 140.461],
                                  [258.921, 102.339],
                                  [-107.527, 138.116],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [58.473, 140.578],
                                  [258.766, 101.985],
                                  [-107.59, 137.825],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [58.273, 140.691],
                                  [258.618, 101.582],
                                  [-107.627, 137.519],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [58.019, 140.792],
                                  [258.416, 101.227],
                                  [-107.604, 137.227],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [57.775, 140.89],
                                  [258.22, 100.885],
                                  [-107.555, 136.885],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [57.503, 140.922],
                                  [257.942, 100.527],
                                  [-107.472, 136.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [57.141, 140.826],
                                  [257.509, 100.181],
                                  [-107.444, 136.261],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [56.679, 140.628],
                                  [256.869, 99.765],
                                  [-107.311, 135.98],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [56.152, 140.24],
                                  [256.076, 99.36],
                                  [-107.204, 135.737],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [55.461, 139.724],
                                  [255.058, 98.913],
                                  [-107.053, 135.514],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [54.677, 139.018],
                                  [253.791, 98.422],
                                  [-106.856, 135.322],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [53.789, 138.148],
                                  [252.284, 97.914],
                                  [-106.65, 135.16],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [52.699, 137.061],
                                  [250.493, 97.338],
                                  [-106.387, 135.038],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [51.479, 135.775],
                                  [248.401, 96.718],
                                  [-106.077, 134.948],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [50.116, 134.236],
                                  [245.983, 96.055],
                                  [-105.746, 134.889],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [48.513, 132.418],
                                  [243.203, 95.323],
                                  [-105.357, 134.882],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [46.678, 130.302],
                                  [240.011, 94.521],
                                  [-104.882, 134.927],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [44.626, 127.844],
                                  [236.354, 93.651],
                                  [-104.375, 135.016],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [42.268, 125.009],
                                  [232.172, 92.685],
                                  [-103.77, 135.168],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [39.593, 121.76],
                                  [227.414, 91.625],
                                  [-103.094, 135.387],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [36.563, 117.999],
                                  [221.967, 90.445],
                                  [-102.334, 135.671],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [33.089, 113.698],
                                  [215.744, 89.118],
                                  [-101.428, 136.045],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [29.085, 108.708],
                                  [208.643, 87.646],
                                  [-100.426, 136.519],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.551, 102.954],
                                  [200.439, 85.977],
                                  [-99.228, 137.107],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [19.311, 96.323],
                                  [191.019, 84.087],
                                  [-97.873, 137.82],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.266, 88.605],
                                  [180.133, 81.924],
                                  [-96.285, 138.695],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.267, 79.66],
                                  [167.542, 79.464],
                                  [-94.452, 139.745],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.862, 69.29],
                                  [152.995, 76.657],
                                  [-92.324, 140.994],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.16, 57.318],
                                  [136.27, 73.452],
                                  [-89.875, 142.479],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.666, 43.794],
                                  [117.319, 69.851],
                                  [-87.103, 144.205],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-33.271, 28.845],
                                  [96.477, 65.906],
                                  [-84.03, 146.14],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-45.556, 13.006],
                                  [74.467, 61.746],
                                  [-80.76, 148.217],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-57.952, -2.899],
                                  [52.325, 57.575],
                                  [-77.437, 150.344],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.876, -18.232],
                                  [31.047, 53.57],
                                  [-74.245, 152.406],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-81.029, -32.53],
                                  [11.246, 49.858],
                                  [-71.244, 154.351],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-91.21, -45.632],
                                  [-6.815, 46.461],
                                  [-68.499, 156.154],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-100.467, -57.474],
                                  [-23.166, 43.379],
                                  [-65.961, 157.809],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-108.884, -68.144],
                                  [-37.889, 40.61],
                                  [-63.659, 159.315],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-116.497, -77.816],
                                  [-51.213, 38.102],
                                  [-61.57, 160.692],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.441, -86.627],
                                  [-63.28, 35.828],
                                  [-59.66, 161.961],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.778, -94.628],
                                  [-74.268, 33.737],
                                  [-57.88, 163.131],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.618, -101.993],
                                  [-84.268, 31.853],
                                  [-56.269, 164.214],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.972, -108.734],
                                  [-93.495, 30.101],
                                  [-54.741, 165.219],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-145.927, -114.901],
                                  [-101.939, 28.504],
                                  [-53.335, 166.146],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-150.532, -120.658],
                                  [-109.75, 27.012],
                                  [-52.027, 167.016],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.812, -125.977],
                                  [-116.97, 25.649],
                                  [-50.804, 167.843],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.804, -130.923],
                                  [-123.697, 24.364],
                                  [-49.644, 168.611],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.518, -135.545],
                                  [-129.922, 23.183],
                                  [-48.583, 169.334],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.981, -139.855],
                                  [-135.732, 22.081],
                                  [-47.585, 170.01],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.255, -143.891],
                                  [-141.191, 21.03],
                                  [-46.612, 170.65],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.351, -147.665],
                                  [-146.262, 20.057],
                                  [-45.703, 171.268],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-175.206, -151.214],
                                  [-151.02, 19.162],
                                  [-44.882, 171.837],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-177.972, -154.552],
                                  [-155.517, 18.293],
                                  [-44.051, 172.383],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-180.497, -157.703],
                                  [-159.702, 17.501],
                                  [-43.309, 172.904],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-182.944, -160.654],
                                  [-163.65, 16.736],
                                  [-42.581, 173.4],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-185.212, -163.432],
                                  [-167.375, 16.022],
                                  [-41.905, 173.859],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-187.365, -166.072],
                                  [-170.852, 15.359],
                                  [-41.256, 174.305],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.414, -168.525],
                                  [-174.169, 14.723],
                                  [-40.647, 174.726],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-191.347, -170.853],
                                  [-177.276, 14.113],
                                  [-40.028, 175.122],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.164, -173.082],
                                  [-180.21, 13.553],
                                  [-39.486, 175.504],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-194.915, -175.161],
                                  [-182.984, 13.02],
                                  [-38.96, 175.86],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-196.525, -177.141],
                                  [-185.561, 12.537],
                                  [-38.486, 176.203],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-198.057, -178.996],
                                  [-188.017, 12.055],
                                  [-38.014, 176.532],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-199.509, -180.726],
                                  [-190.363, 11.599],
                                  [-37.533, 176.848],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.921, -182.395],
                                  [-192.511, 11.193],
                                  [-37.154, 177.137],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.191, -183.938],
                                  [-194.589, 10.788],
                                  [-36.729, 177.412],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-203.458, -185.445],
                                  [-196.532, 10.409],
                                  [-36.343, 177.686],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-204.609, -186.826],
                                  [-198.366, 10.055],
                                  [-35.973, 177.933],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.693, -188.121],
                                  [-200.09, 9.726],
                                  [-35.643, 178.166],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-206.737, -189.341],
                                  [-201.681, 9.422],
                                  [-35.328, 178.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.702, -190.511],
                                  [-203.225, 9.119],
                                  [-35.016, 178.602],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-208.626, -191.606],
                                  [-204.635, 8.842],
                                  [-34.72, 178.805],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.484, -192.614],
                                  [-205.961, 8.589],
                                  [-34.463, 178.994],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.301, -193.56],
                                  [-207.241, 8.337],
                                  [-34.185, 179.169],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.039, -194.469],
                                  [-208.387, 8.11],
                                  [-33.946, 179.342],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-211.736, -195.29],
                                  [-209.473, 7.908],
                                  [-33.748, 179.487],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.43, -196.074],
                                  [-210.489, 7.707],
                                  [-33.527, 179.645],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.021, -196.796],
                                  [-211.42, 7.53],
                                  [-33.347, 179.774],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.571, -197.455],
                                  [-212.304, 7.354],
                                  [-33.145, 179.903],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.118, -198.053],
                                  [-213.105, 7.203],
                                  [-33.007, 180.016],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.586, -198.65],
                                  [-213.834, 7.052],
                                  [-32.822, 180.129],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.051, -199.185],
                                  [-214.504, 6.926],
                                  [-32.702, 180.227],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.437, -199.658],
                                  [-215.127, 6.8],
                                  [-32.56, 180.31],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.796, -200.069],
                                  [-215.666, 6.7],
                                  [-32.458, 180.391],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.114, -200.442],
                                  [-216.183, 6.599],
                                  [-32.333, 180.472],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.429, -200.79],
                                  [-216.592, 6.523],
                                  [-32.274, 180.537],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.666, -201.101],
                                  [-217.003, 6.448],
                                  [-32.191, 180.588],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.9, -201.35],
                                  [-217.343, 6.372],
                                  [-32.111, 180.638],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.093, -201.574],
                                  [-217.623, 6.322],
                                  [-32.047, 180.686],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.246, -201.76],
                                  [-217.881, 6.272],
                                  [-31.984, 180.72],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.371, -201.923],
                                  [-218.055, 6.246],
                                  [-31.962, 180.753],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.48, -202.047],
                                  [-218.221, 6.196],
                                  [-31.904, 180.77],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.562, -202.109],
                                  [-218.288, 6.196],
                                  [-31.923, 180.787],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.604, -202.171],
                                  [-218.371, 6.171],
                                  [-31.882, 180.802],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.605, -202.171],
                                  [-218.395, 6.171],
                                  [-31.881, 180.803],
                                ],
                                c: false,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.549, -202.11],
                                  [-218.364, 6.172],
                                  [-31.847, 180.756],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.441, -201.978],
                                  [-218.235, 6.264],
                                  [-31.806, 180.513],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-217.191, -201.661],
                                  [-218.063, 6.396],
                                  [-31.662, 180.136],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.846, -201.273],
                                  [-217.76, 6.555],
                                  [-31.492, 179.624],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-216.439, -200.739],
                                  [-217.395, 6.781],
                                  [-31.273, 178.912],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.866, -200.033],
                                  [-216.896, 7.074],
                                  [-30.972, 177.96],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-215.161, -199.219],
                                  [-216.266, 7.433],
                                  [-30.589, 176.83],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-214.306, -198.147],
                                  [-215.548, 7.884],
                                  [-30.143, 175.458],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-213.309, -196.928],
                                  [-214.695, 8.365],
                                  [-29.597, 173.84],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-212.149, -195.487],
                                  [-213.652, 8.976],
                                  [-28.968, 171.963],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-210.782, -193.786],
                                  [-212.447, 9.72],
                                  [-28.235, 169.762],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-209.176, -191.825],
                                  [-211.013, 10.534],
                                  [-27.34, 167.172],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-207.383, -189.552],
                                  [-209.393, 11.469],
                                  [-26.297, 164.241],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-205.279, -186.928],
                                  [-207.444, 12.577],
                                  [-25.087, 160.88],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-202.89, -183.924],
                                  [-205.236, 13.846],
                                  [-23.688, 156.999],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-200.163, -180.451],
                                  [-202.651, 15.305],
                                  [-22.026, 152.634],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-197.069, -176.466],
                                  [-199.671, 16.932],
                                  [-20.117, 147.623],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-193.524, -171.835],
                                  [-196.165, 18.891],
                                  [-17.848, 141.881],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-189.458, -166.5],
                                  [-192.061, 21.088],
                                  [-15.149, 135.301],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-184.77, -160.255],
                                  [-187.259, 23.644],
                                  [-11.937, 127.828],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-179.42, -152.983],
                                  [-181.536, 26.638],
                                  [-8.099, 119.23],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.279, -144.489],
                                  [-174.681, 30.107],
                                  [-3.41, 109.387],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.271, -134.556],
                                  [-166.557, 34.124],
                                  [2.222, 98.136],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.323, -122.981],
                                  [-156.887, 38.793],
                                  [9.026, 85.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.443, -109.673],
                                  [-145.509, 44.127],
                                  [17.176, 71.357],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-139.853, -94.778],
                                  [-132.415, 50.041],
                                  [26.732, 56.199],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-129.955, -78.764],
                                  [-117.947, 56.376],
                                  [37.48, 40.6],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.221, -62.334],
                                  [-102.713, 62.736],
                                  [48.97, 25.382],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-111.048, -46.201],
                                  [-87.393, 68.973],
                                  [60.784, 11.212],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-102.699, -30.961],
                                  [-72.521, 74.809],
                                  [72.402, -1.546],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-95.307, -16.774],
                                  [-58.384, 80.228],
                                  [83.547, -12.818],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-88.764, -3.731],
                                  [-45.14, 85.123],
                                  [94.177, -22.707],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-83.008, 8.224],
                                  [-32.793, 89.581],
                                  [104.14, -31.41],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-77.916, 19.152],
                                  [-21.281, 93.64],
                                  [113.467, -38.999],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.388, 29.23],
                                  [-10.566, 97.356],
                                  [122.237, -45.732],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.35, 38.494],
                                  [-0.548, 100.764],
                                  [130.461, -51.717],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-65.735, 47.012],
                                  [8.784, 103.893],
                                  [138.201, -57.071],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.472, 54.963],
                                  [17.515, 106.778],
                                  [145.442, -61.838],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.537, 62.277],
                                  [25.726, 109.421],
                                  [152.305, -66.165],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-56.853, 69.146],
                                  [33.412, 111.922],
                                  [158.727, -70.059],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-54.423, 75.543],
                                  [40.658, 114.208],
                                  [164.798, -73.595],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-52.179, 81.552],
                                  [47.48, 116.329],
                                  [170.573, -76.837],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-50.13, 87.146],
                                  [53.936, 118.361],
                                  [176.003, -79.781],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.238, 92.423],
                                  [60.011, 120.231],
                                  [181.188, -82.472],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-46.504, 97.368],
                                  [65.77, 121.989],
                                  [186.056, -84.979],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-44.882, 102.071],
                                  [71.228, 123.635],
                                  [190.698, -87.294],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-43.401, 106.455],
                                  [76.388, 125.208],
                                  [195.086, -89.382],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-42.016, 110.604],
                                  [81.268, 126.67],
                                  [199.274, -91.386],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-40.695, 114.511],
                                  [85.916, 128.059],
                                  [203.259, -93.193],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.536, 118.26],
                                  [90.349, 129.377],
                                  [207.027, -94.885],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-38.392, 121.751],
                                  [94.5, 130.602],
                                  [210.647, -96.423],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-37.377, 125.06],
                                  [98.468, 131.762],
                                  [214.044, -97.896],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.385, 128.213],
                                  [102.265, 132.869],
                                  [217.303, -99.243],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.459, 131.208],
                                  [105.831, 133.889],
                                  [220.38, -100.528],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-34.629, 134.023],
                                  [109.245, 134.879],
                                  [223.308, -101.729],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-33.835, 136.68],
                                  [112.462, 135.8],
                                  [226.091, -102.853],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-33.052, 139.197],
                                  [115.539, 136.675],
                                  [228.767, -103.854],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-32.359, 141.58],
                                  [118.456, 137.544],
                                  [231.251, -104.82],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-31.695, 143.845],
                                  [121.19, 138.281],
                                  [233.666, -105.727],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-31.076, 146.012],
                                  [123.802, 139.076],
                                  [235.949, -106.578],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-30.501, 148.004],
                                  [126.312, 139.74],
                                  [238.099, -107.353],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-29.991, 149.96],
                                  [128.654, 140.42],
                                  [240.116, -108.094],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-29.459, 151.759],
                                  [130.892, 141.033],
                                  [242.061, -108.802],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-28.988, 153.443],
                                  [132.982, 141.62],
                                  [243.872, -109.434],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-28.534, 155.051],
                                  [134.966, 142.182],
                                  [245.588, -110.034],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-28.117, 156.566],
                                  [136.842, 142.697],
                                  [247.21, -110.599],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.718, 158.005],
                                  [138.568, 143.188],
                                  [248.76, -111.132],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.374, 159.33],
                                  [140.228, 143.653],
                                  [250.193, -111.612],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.023, 160.603],
                                  [141.799, 144.073],
                                  [251.553, -112.059],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.685, 161.76],
                                  [143.261, 144.467],
                                  [252.859, -112.494],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.402, 162.845],
                                  [144.634, 144.858],
                                  [254.029, -112.875],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.13, 163.877],
                                  [145.877, 145.204],
                                  [255.145, -113.266],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.89, 164.837],
                                  [147.071, 145.546],
                                  [256.166, -113.582],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.682, 165.745],
                                  [148.177, 145.822],
                                  [257.134, -113.887],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.442, 166.54],
                                  [149.171, 146.136],
                                  [258.028, -114.158],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.232, 167.283],
                                  [150.139, 146.363],
                                  [258.828, -114.417],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.073, 167.996],
                                  [150.954, 146.629],
                                  [259.594, -114.665],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.903, 168.617],
                                  [151.763, 146.83],
                                  [260.246, -114.9],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.761, 169.166],
                                  [152.461, 147.027],
                                  [260.886, -115.081],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.627, 169.666],
                                  [153.132, 147.18],
                                  [261.432, -115.249],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.501, 170.135],
                                  [153.65, 147.371],
                                  [261.926, -115.384],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.383, 170.514],
                                  [154.183, 147.518],
                                  [262.388, -115.527],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.292, 170.884],
                                  [154.626, 147.642],
                                  [262.776, -115.636],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.25, 171.184],
                                  [154.959, 147.721],
                                  [263.072, -115.754],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.152, 171.434],
                                  [155.307, 147.798],
                                  [263.378, -115.857],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.082, 171.615],
                                  [155.544, 147.872],
                                  [263.591, -115.885],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.079, 171.809],
                                  [155.754, 147.922],
                                  [263.773, -115.962],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.042, 171.912],
                                  [155.896, 147.992],
                                  [263.883, -115.983],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.01, 171.988],
                                  [155.949, 147.996],
                                  [263.984, -116.012],
                                ],
                                c: false,
                              },
                            ],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.383, 0.383, 0.383, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.383, 0.383, 0.383, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [175.598, 100.068],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.588, 100.188],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.569, 100.342],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.527, 100.596],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.477, 100.945],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.433, 101.414],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.341, 101.955],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.266, 102.617],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.203, 103.416],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.095, 104.321],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.002, 105.413],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.918, 106.681],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.86, 108.119],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.793, 109.816],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.82, 111.713],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.861, 113.909],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.063, 116.403],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.373, 119.268],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.895, 122.542],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.739, 126.273],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.088, 130.453],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.085, 135.098],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.983, 140.112],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.165, 145.221],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.784, 149.848],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.316, 150.244],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.8, 150.463],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.36, 150.673],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.664, 150.811],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [220.319, 149.781],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.146, 147.604],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [220.793, 145.905],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [219.741, 144.639],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [218.221, 143.743],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.482, 143.153],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.64, 142.779],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [212.773, 142.549],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.886, 142.366],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.076, 142.253],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.287, 142.129],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.589, 141.991],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.915, 141.844],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.319, 141.574],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.777, 141.267],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.289, 140.889],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.848, 140.411],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.469, 139.805],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.146, 139.137],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.853, 138.326],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.637, 137.438],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.466, 136.46],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.324, 135.39],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.29, 134.271],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.273, 133.101],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.341, 131.933],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.446, 130.752],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.629, 129.567],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.851, 128.394],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.166, 127.31],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.53, 126.243],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.906, 125.241],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.369, 124.269],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.888, 123.356],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.395, 122.49],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.001, 121.639],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.589, 120.877],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.225, 120.124],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.905, 119.405],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.598, 118.713],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.328, 118.081],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.053, 117.463],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.816, 116.873],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.603, 116.284],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.41, 115.736],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.24, 115.201],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.07, 114.694],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.923, 114.196],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.776, 113.731],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.649, 113.261],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.542, 112.802],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.471, 112.346],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.377, 111.918],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.306, 111.509],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.265, 111.112],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.176, 110.73],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.141, 110.323],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.1, 109.974],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.078, 109.583],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.054, 109.23],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.046, 108.874],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.98, 108.511],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.765, 108.164],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.44, 107.763],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.96, 107.383],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.351, 106.969],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.546, 106.53],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.544, 106.085],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.369, 105.591],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.966, 105.069],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [169.297, 104.522],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.394, 103.926],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.212, 103.284],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.665, 102.597],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [159.767, 101.842],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.459, 101.02],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.648, 100.112],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.326, 99.093],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.402, 97.963],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.705, 96.681],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.194, 95.214],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.724, 93.51],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.137, 91.521],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.317, 89.161],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.129, 86.315],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.559, 82.858],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.878, 78.618],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.44, 73.459],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.601, 67.387],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.556, 60.601],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.329, 53.553],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.038, 46.859],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.654, 41.438],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.183, 37.097],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.665, 33.748],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.536, 31.235],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.137, 29.414],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.712, 28.089],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.489, 27.195],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.527, 26.585],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.982, 26.173],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.857, 25.892],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.031, 25.662],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.657, 25.456],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.827, 25.278],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.6, 25.13],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.984, 24.986],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.071, 24.843],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.866, 24.722],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.394, 24.588],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.676, 24.466],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.755, 24.35],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.621, 24.229],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-166.322, 24.126],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.822, 24.03],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.209, 23.912],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.44, 23.811],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.525, 23.705],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-177.475, 23.614],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.321, 23.538],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.056, 23.429],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.679, 23.359],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.214, 23.271],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.646, 23.204],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.004, 23.13],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.255, 23.06],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.46, 22.989],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-190.559, 22.922],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-191.595, 22.865],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.57, 22.82],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-193.462, 22.761],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.312, 22.712],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.089, 22.669],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.819, 22.62],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.489, 22.586],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.113, 22.548],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.673, 22.505],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.205, 22.463],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.678, 22.433],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.096, 22.409],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.479, 22.397],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.814, 22.363],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.134, 22.343],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.382, 22.329],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.599, 22.317],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.791, 22.31],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.942, 22.294],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.124, 22.276],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.097, 22.282],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.97, 22.328],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.762, 22.349],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.52, 22.388],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.184, 22.434],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.766, 22.486],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.274, 22.592],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.675, 22.687],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.97, 22.816],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.166, 22.997],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.173, 23.19],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.024, 23.458],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-193.669, 23.779],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.125, 24.185],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-190.266, 24.722],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.092, 25.36],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.523, 26.231],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.413, 27.317],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.659, 28.754],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.06, 30.629],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.28, 33.07],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.034, 36.218],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.811, 40.22],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.04, 45.042],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.301, 50.317],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.731, 55.339],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.186, 59.159],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.085, 61.447],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.721, 62.27],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.82, 62.157],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.622, 61.502],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.153, 60.741],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.746, 60.145],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.312, 59.776],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.717, 59.719],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.121, 59.941],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.675, 60.434],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.527, 61.146],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.743, 62.067],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.427, 63.12],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.662, 64.257],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.473, 65.546],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.924, 66.849],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.034, 68.195],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.86, 69.553],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.39, 70.968],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.682, 72.317],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.757, 73.679],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.602, 75.074],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.24, 76.381],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.683, 77.683],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.981, 78.963],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.081, 80.17],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.034, 81.383],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.828, 82.525],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.504, 83.641],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.023, 84.757],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.404, 85.752],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.68, 86.773],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.84, 87.735],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [151.863, 88.672],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [153.808, 89.533],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [155.616, 90.376],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.336, 91.179],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.959, 91.937],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.475, 92.627],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.909, 93.325],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.269, 93.968],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.551, 94.552],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.732, 95.15],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.823, 95.656],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.851, 96.189],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.801, 96.661],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [169.682, 97.092],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.507, 97.493],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [171.233, 97.856],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [171.923, 98.191],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.536, 98.497],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.104, 98.783],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.573, 99.024],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.038, 99.25],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.423, 99.449],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.709, 99.589],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.016, 99.713],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.225, 99.827],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.4, 99.928],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.524, 100.01],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [175.598, 100.068],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.588, 100.188],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.569, 100.342],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.527, 100.596],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.477, 100.945],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.433, 101.414],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.341, 101.955],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.266, 102.617],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.203, 103.416],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.095, 104.321],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.002, 105.413],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.918, 106.681],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.86, 108.119],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.793, 109.816],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.82, 111.713],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.861, 113.909],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.063, 116.403],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.373, 119.268],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.895, 122.542],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.739, 126.273],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.088, 130.453],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.085, 135.098],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.983, 140.112],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.165, 145.221],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.784, 149.848],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.316, 150.244],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.8, 150.463],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.36, 150.673],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.664, 150.811],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [220.319, 149.781],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.146, 147.604],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [220.793, 145.905],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [219.741, 144.639],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [218.221, 143.743],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.482, 143.153],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.64, 142.779],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [212.773, 142.549],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.886, 142.366],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.076, 142.253],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.287, 142.129],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.589, 141.991],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.915, 141.844],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.319, 141.574],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.777, 141.267],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.289, 140.889],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.848, 140.411],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.469, 139.805],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.146, 139.137],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.853, 138.326],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.637, 137.438],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.466, 136.46],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.324, 135.39],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.29, 134.271],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.273, 133.101],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.341, 131.933],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.446, 130.752],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.629, 129.567],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.851, 128.394],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.166, 127.31],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.53, 126.243],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.906, 125.241],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.369, 124.269],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.888, 123.356],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.395, 122.49],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.001, 121.639],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.589, 120.877],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.225, 120.124],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.905, 119.405],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.598, 118.713],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.328, 118.081],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.053, 117.463],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.816, 116.873],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.603, 116.284],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.41, 115.736],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.24, 115.201],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.07, 114.694],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.923, 114.196],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.776, 113.731],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.649, 113.261],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.542, 112.802],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.471, 112.346],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.377, 111.918],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.306, 111.509],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.265, 111.112],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.176, 110.73],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.141, 110.323],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.1, 109.974],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.078, 109.583],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.054, 109.23],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.046, 108.874],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.98, 108.511],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.765, 108.164],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.44, 107.763],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.96, 107.383],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.351, 106.969],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.546, 106.53],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.544, 106.085],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.369, 105.591],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.966, 105.069],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [169.297, 104.522],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.394, 103.926],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.212, 103.284],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.665, 102.597],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [159.767, 101.842],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.459, 101.02],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.648, 100.112],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.326, 99.093],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.402, 97.963],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.705, 96.681],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.194, 95.214],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.724, 93.51],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [115.137, 91.521],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [105.317, 89.161],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.129, 86.315],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.559, 82.858],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.878, 78.618],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.44, 73.459],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.601, 67.387],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.556, 60.601],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.329, 53.553],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-7.038, 46.859],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-24.654, 41.438],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-40.183, 37.097],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.665, 33.748],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-65.536, 31.235],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-76.137, 29.414],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-85.712, 28.089],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-94.489, 27.195],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-102.527, 26.585],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-109.982, 26.173],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.857, 25.892],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-123.031, 25.662],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-128.657, 25.456],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.827, 25.278],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.6, 25.13],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-142.984, 24.986],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-147.071, 24.843],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-150.866, 24.722],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.394, 24.588],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-157.676, 24.466],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.755, 24.35],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-163.621, 24.229],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-166.322, 24.126],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.822, 24.03],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-171.209, 23.912],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.44, 23.811],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-175.525, 23.705],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-177.475, 23.614],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.321, 23.538],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-181.056, 23.429],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.679, 23.359],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.214, 23.271],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.646, 23.204],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-187.004, 23.13],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.255, 23.06],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.46, 22.989],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-190.559, 22.922],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-191.595, 22.865],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.57, 22.82],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-193.462, 22.761],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.312, 22.712],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.089, 22.669],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.819, 22.62],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.489, 22.586],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.113, 22.548],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.673, 22.505],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.205, 22.463],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.678, 22.433],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.096, 22.409],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.479, 22.397],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.814, 22.363],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.134, 22.343],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.382, 22.329],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.599, 22.317],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.791, 22.31],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.942, 22.294],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.124, 22.276],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-201.097, 22.282],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.97, 22.328],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.762, 22.349],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.52, 22.388],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-200.184, 22.434],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.766, 22.486],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.274, 22.592],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.675, 22.687],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.97, 22.816],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.166, 22.997],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-196.173, 23.19],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.024, 23.458],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-193.669, 23.779],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.125, 24.185],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-190.266, 24.722],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.092, 25.36],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.523, 26.231],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.413, 27.317],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.659, 28.754],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-174.06, 30.629],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.28, 33.07],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-161.034, 36.218],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-151.811, 40.22],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-140.04, 45.042],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.301, 50.317],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-107.731, 55.339],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.186, 59.159],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-68.085, 61.447],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-48.721, 62.27],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.82, 62.157],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-14.622, 61.502],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.153, 60.741],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.746, 60.145],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.312, 59.776],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.717, 59.719],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.121, 59.941],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.675, 60.434],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [60.527, 61.146],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [67.743, 62.067],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.427, 63.12],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.662, 64.257],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [86.473, 65.546],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.924, 66.849],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [97.034, 68.195],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.86, 69.553],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.39, 70.968],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [110.682, 72.317],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.757, 73.679],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.602, 75.074],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [122.24, 76.381],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.683, 77.683],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.981, 78.963],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.081, 80.17],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.034, 81.383],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.828, 82.525],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.504, 83.641],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.023, 84.757],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [145.404, 85.752],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.68, 86.773],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.84, 87.735],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [151.863, 88.672],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [153.808, 89.533],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [155.616, 90.376],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.336, 91.179],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [158.959, 91.937],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.475, 92.627],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.909, 93.325],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.269, 93.968],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.551, 94.552],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.732, 95.15],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.823, 95.656],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.851, 96.189],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.801, 96.661],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [169.682, 97.092],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.507, 97.493],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [171.233, 97.856],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [171.923, 98.191],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.536, 98.497],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.104, 98.783],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.573, 99.024],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.038, 99.25],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.423, 99.449],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.709, 99.589],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.016, 99.713],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.225, 99.827],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.4, 99.928],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.524, 100.01],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 11',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 11,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.006, 186.393],
                                  [167.994, 152.798],
                                  [283.145, -101.544],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.95, 186.327],
                                  [168.067, 152.824],
                                  [282.918, -101.382],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.836, 186.255],
                                  [168.18, 152.832],
                                  [282.545, -101.145],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.665, 186.148],
                                  [168.352, 152.838],
                                  [281.949, -100.744],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.499, 186.006],
                                  [168.551, 152.871],
                                  [281.252, -100.224],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.213, 185.829],
                                  [168.854, 152.931],
                                  [280.286, -99.522],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-10.932, 185.617],
                                  [169.168, 152.972],
                                  [279.111, -98.702],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-10.532, 185.34],
                                  [169.585, 153.039],
                                  [277.711, -97.672],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-10.075, 185.027],
                                  [170.092, 153.15],
                                  [276.04, -96.508],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-9.562, 184.651],
                                  [170.639, 153.224],
                                  [274.114, -95.101],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.931, 184.209],
                                  [171.321, 153.307],
                                  [271.826, -93.423],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.243, 183.732],
                                  [172.093, 153.461],
                                  [269.204, -91.548],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.375, 183.162],
                                  [173.012, 153.595],
                                  [266.174, -89.324],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.451, 182.528],
                                  [174.037, 153.765],
                                  [262.673, -86.768],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-5.285, 181.801],
                                  [175.272, 153.942],
                                  [258.703, -83.829],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-4.065, 180.954],
                                  [176.628, 154.126],
                                  [254.154, -80.479],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-2.54, 180.015],
                                  [178.256, 154.406],
                                  [248.937, -76.657],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.836, 178.928],
                                  [180.097, 154.686],
                                  [242.927, -72.192],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [1.108, 177.723],
                                  [182.214, 154.998],
                                  [236.052, -67.052],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [3.356, 176.316],
                                  [184.669, 155.369],
                                  [228.143, -61.1],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.03, 174.71],
                                  [187.57, 155.809],
                                  [218.958, -54.241],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.132, 172.879],
                                  [190.917, 156.314],
                                  [208.392, -46.243],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [12.66, 170.798],
                                  [194.761, 156.911],
                                  [196.188, -36.983],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [16.802, 168.415],
                                  [199.255, 157.578],
                                  [182.106, -26.246],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [21.493, 165.736],
                                  [204.354, 158.312],
                                  [166.227, -14.107],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [26.734, 162.822],
                                  [210.024, 159.126],
                                  [148.76, -0.728],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [32.21, 159.761],
                                  [215.987, 159.96],
                                  [130.317, 13.408],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [37.672, 156.724],
                                  [221.94, 160.824],
                                  [111.76, 27.601],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [42.993, 153.794],
                                  [227.722, 161.554],
                                  [93.883, 41.27],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [47.86, 151.109],
                                  [233.038, 162.202],
                                  [77.25, 53.965],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [52.271, 148.64],
                                  [237.87, 162.695],
                                  [62.073, 65.508],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [56.225, 146.44],
                                  [242.215, 163.14],
                                  [48.332, 75.905],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [59.784, 144.478],
                                  [246.136, 163.457],
                                  [35.918, 85.227],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [62.946, 142.702],
                                  [249.629, 163.662],
                                  [24.651, 93.552],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [65.709, 141.108],
                                  [252.713, 163.764],
                                  [14.464, 101.11],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [68.197, 139.669],
                                  [255.51, 163.805],
                                  [5.162, 107.854],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [70.47, 138.385],
                                  [258.044, 163.765],
                                  [-3.303, 113.995],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [72.4, 137.228],
                                  [260.263, 163.599],
                                  [-11.118, 119.506],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [74.174, 136.198],
                                  [262.295, 163.393],
                                  [-18.249, 124.489],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [75.726, 135.244],
                                  [264.114, 163.08],
                                  [-24.909, 129.047],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [77.116, 134.416],
                                  [265.761, 162.701],
                                  [-30.993, 133.133],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [78.279, 133.665],
                                  [267.17, 162.274],
                                  [-36.67, 136.825],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [79.336, 132.991],
                                  [268.485, 161.692],
                                  [-41.964, 140.167],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [80.222, 132.394],
                                  [269.62, 161.054],
                                  [-46.895, 143.169],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [80.935, 131.875],
                                  [270.592, 160.36],
                                  [-51.464, 145.81],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [81.534, 131.434],
                                  [271.443, 159.564],
                                  [-55.756, 148.169],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [81.955, 131.05],
                                  [272.128, 158.622],
                                  [-59.728, 150.286],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [82.323, 130.744],
                                  [272.751, 157.658],
                                  [-63.465, 152.097],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [82.514, 130.495],
                                  [273.21, 156.549],
                                  [-66.944, 153.645],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [82.593, 130.303],
                                  [273.568, 155.382],
                                  [-70.188, 154.977],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [82.567, 130.165],
                                  [273.833, 154.146],
                                  [-73.239, 156.039],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [82.444, 130.098],
                                  [273.988, 152.828],
                                  [-76.095, 156.966],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [82.236, 130.062],
                                  [274.087, 151.469],
                                  [-78.717, 157.716],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [82.018, 130.086],
                                  [274.136, 150.083],
                                  [-81.192, 158.303],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [81.679, 130.132],
                                  [274.113, 148.711],
                                  [-83.5, 158.807],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [81.358, 130.21],
                                  [274.06, 147.332],
                                  [-85.645, 159.19],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [81.064, 130.315],
                                  [274.032, 145.94],
                                  [-87.668, 159.548],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [80.682, 130.428],
                                  [273.91, 144.584],
                                  [-89.533, 159.803],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [80.342, 130.572],
                                  [273.85, 143.323],
                                  [-91.308, 160.04],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [80.047, 130.705],
                                  [273.778, 142.088],
                                  [-92.908, 160.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [79.735, 130.862],
                                  [273.682, 140.919],
                                  [-94.459, 160.422],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [79.407, 131.015],
                                  [273.588, 139.78],
                                  [-95.865, 160.632],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [79.123, 131.176],
                                  [273.532, 138.72],
                                  [-97.192, 160.776],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [78.882, 131.332],
                                  [273.46, 137.701],
                                  [-98.45, 160.906],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [78.619, 131.493],
                                  [273.41, 136.706],
                                  [-99.605, 161.039],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [78.393, 131.649],
                                  [273.34, 135.81],
                                  [-100.685, 161.151],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [78.141, 131.808],
                                  [273.262, 134.931],
                                  [-101.699, 161.257],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [77.92, 131.962],
                                  [273.212, 134.086],
                                  [-102.641, 161.338],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [77.668, 132.117],
                                  [273.125, 133.278],
                                  [-103.519, 161.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [77.507, 132.268],
                                  [273.096, 132.535],
                                  [-104.326, 161.47],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [77.247, 132.413],
                                  [272.991, 131.824],
                                  [-105.097, 161.48],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [77.074, 132.559],
                                  [272.941, 131.146],
                                  [-105.806, 161.496],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [76.861, 132.701],
                                  [272.848, 130.462],
                                  [-106.419, 161.509],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [76.669, 132.843],
                                  [272.771, 129.834],
                                  [-106.997, 161.489],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [76.435, 132.975],
                                  [272.677, 129.231],
                                  [-107.541, 161.433],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [76.282, 133.113],
                                  [272.603, 128.653],
                                  [-108.024, 161.404],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [76.083, 133.241],
                                  [272.51, 128.098],
                                  [-108.474, 161.335],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [75.838, 133.37],
                                  [272.367, 127.592],
                                  [-108.857, 161.226],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [75.671, 133.494],
                                  [272.27, 127.074],
                                  [-109.213, 161.105],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [75.455, 133.619],
                                  [272.152, 126.574],
                                  [-109.536, 160.975],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [75.253, 133.735],
                                  [272.044, 126.093],
                                  [-109.798, 160.8],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [75.064, 133.851],
                                  [271.917, 125.628],
                                  [-110.024, 160.673],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [74.823, 133.963],
                                  [271.767, 125.209],
                                  [-110.223, 160.47],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [74.657, 134.076],
                                  [271.659, 124.806],
                                  [-110.36, 160.25],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [74.439, 134.184],
                                  [271.497, 124.416],
                                  [-110.528, 160.045],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [74.169, 134.288],
                                  [271.31, 124.008],
                                  [-110.603, 159.791],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [73.971, 134.39],
                                  [271.164, 123.676],
                                  [-110.679, 159.521],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [73.781, 134.487],
                                  [271.025, 123.294],
                                  [-110.724, 159.232],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [73.539, 134.585],
                                  [270.831, 122.954],
                                  [-110.708, 158.954],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [73.304, 134.678],
                                  [270.643, 122.625],
                                  [-110.661, 158.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [73.047, 134.707],
                                  [270.367, 122.276],
                                  [-110.585, 158.306],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [72.705, 134.607],
                                  [269.903, 121.937],
                                  [-110.576, 157.995],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [72.278, 134.411],
                                  [269.227, 121.511],
                                  [-110.476, 157.693],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [71.797, 134.025],
                                  [268.365, 121.096],
                                  [-110.409, 157.397],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [71.168, 133.512],
                                  [267.256, 120.627],
                                  [-110.313, 157.11],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [70.451, 132.808],
                                  [265.871, 120.103],
                                  [-110.189, 156.83],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [69.646, 131.946],
                                  [264.207, 119.557],
                                  [-110.065, 156.559],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [68.661, 130.862],
                                  [262.237, 118.925],
                                  [-109.912, 156.295],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [67.555, 129.589],
                                  [259.927, 118.238],
                                  [-109.728, 156.04],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [66.329, 128.064],
                                  [257.248, 117.497],
                                  [-109.545, 155.795],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [64.889, 126.255],
                                  [254.169, 116.67],
                                  [-109.33, 155.557],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [63.234, 124.163],
                                  [250.628, 115.757],
                                  [-109.053, 155.328],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [61.394, 121.725],
                                  [246.563, 114.758],
                                  [-108.774, 155.109],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [59.275, 118.91],
                                  [241.913, 113.643],
                                  [-108.431, 154.899],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [56.877, 115.686],
                                  [236.615, 112.41],
                                  [-108.055, 154.7],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [54.167, 111.961],
                                  [230.546, 111.029],
                                  [-107.645, 154.513],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [51.051, 107.702],
                                  [223.614, 109.47],
                                  [-107.137, 154.338],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [47.465, 102.753],
                                  [215.696, 107.732],
                                  [-106.593, 154.177],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [43.408, 97.052],
                                  [206.542, 105.754],
                                  [-105.918, 154.03],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [38.723, 90.474],
                                  [196.031, 103.504],
                                  [-105.173, 153.9],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [33.314, 82.831],
                                  [183.885, 100.922],
                                  [-104.294, 153.789],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [27.053, 73.966],
                                  [169.826, 97.977],
                                  [-103.279, 153.7],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [19.783, 63.692],
                                  [153.577, 94.608],
                                  [-102.094, 153.636],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.469, 51.822],
                                  [134.893, 90.754],
                                  [-100.735, 153.601],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [2.078, 38.417],
                                  [113.717, 86.417],
                                  [-99.201, 153.598],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.297, 23.603],
                                  [90.424, 81.659],
                                  [-97.491, 153.63],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.281, 7.91],
                                  [65.827, 76.636],
                                  [-95.667, 153.694],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-30.372, -7.848],
                                  [41.075, 71.597],
                                  [-93.795, 153.786],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-41.032, -23.046],
                                  [17.284, 66.756],
                                  [-92.006, 153.898],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-51.009, -37.215],
                                  [-4.867, 62.267],
                                  [-90.304, 154.024],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.111, -50.197],
                                  [-25.072, 58.159],
                                  [-88.755, 154.159],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.399, -61.931],
                                  [-43.366, 54.43],
                                  [-87.3, 154.3],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-75.934, -72.509],
                                  [-59.847, 51.079],
                                  [-85.971, 154.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-82.746, -82.089],
                                  [-74.766, 48.044],
                                  [-84.769, 154.587],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-88.96, -90.825],
                                  [-88.281, 45.292],
                                  [-83.663, 154.732],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-94.638, -98.75],
                                  [-100.581, 42.76],
                                  [-82.622, 154.877],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-99.873, -106.05],
                                  [-111.789, 40.48],
                                  [-81.68, 155.02],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-104.666, -112.726],
                                  [-122.127, 38.358],
                                  [-80.772, 155.163],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-109.11, -118.839],
                                  [-131.594, 36.424],
                                  [-79.93, 155.302],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-113.236, -124.547],
                                  [-140.348, 34.616],
                                  [-79.155, 155.441],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-117.075, -129.818],
                                  [-148.449, 32.965],
                                  [-78.415, 155.578],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.659, -134.715],
                                  [-155.993, 31.408],
                                  [-77.711, 155.713],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-123.988, -139.299],
                                  [-162.98, 29.977],
                                  [-77.073, 155.845],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-127.092, -143.571],
                                  [-169.503, 28.64],
                                  [-76.471, 155.973],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-130.034, -147.564],
                                  [-175.625, 27.367],
                                  [-75.872, 156.1],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-132.815, -151.305],
                                  [-181.316, 26.187],
                                  [-75.309, 156.225],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-135.373, -154.828],
                                  [-186.667, 25.101],
                                  [-74.814, 156.345],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-137.862, -158.133],
                                  [-191.713, 24.047],
                                  [-74.29, 156.463],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.127, -161.252],
                                  [-196.419, 23.087],
                                  [-73.835, 156.579],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-142.324, -164.182],
                                  [-200.851, 22.158],
                                  [-73.382, 156.691],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.36, -166.926],
                                  [-205.038, 21.291],
                                  [-72.965, 156.8],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-146.297, -169.545],
                                  [-208.949, 20.487],
                                  [-72.554, 156.906],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.136, -171.976],
                                  [-212.678, 19.715],
                                  [-72.176, 157.009],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-149.875, -174.283],
                                  [-216.164, 18.974],
                                  [-71.772, 157.108],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-151.515, -176.496],
                                  [-219.467, 18.294],
                                  [-71.435, 157.205],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-153.088, -178.553],
                                  [-222.588, 17.647],
                                  [-71.101, 157.298],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-154.532, -180.517],
                                  [-225.495, 17.06],
                                  [-70.804, 157.389],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.908, -182.356],
                                  [-228.253, 16.475],
                                  [-70.509, 157.476],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-157.216, -184.07],
                                  [-230.891, 15.921],
                                  [-70.188, 157.562],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.489, -185.722],
                                  [-233.315, 15.428],
                                  [-69.964, 157.642],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-159.632, -187.25],
                                  [-235.651, 14.935],
                                  [-69.682, 157.719],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.771, -188.745],
                                  [-237.836, 14.474],
                                  [-69.435, 157.796],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-161.81, -190.116],
                                  [-239.902, 14.044],
                                  [-69.192, 157.867],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.784, -191.395],
                                  [-241.847, 13.644],
                                  [-68.984, 157.935],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-163.722, -192.609],
                                  [-243.641, 13.275],
                                  [-68.78, 158.002],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.592, -193.763],
                                  [-245.378, 12.907],
                                  [-68.578, 158.064],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-165.427, -194.853],
                                  [-246.965, 12.569],
                                  [-68.381, 158.124],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.196, -195.85],
                                  [-248.462, 12.262],
                                  [-68.219, 158.181],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.93, -196.785],
                                  [-249.902, 11.956],
                                  [-68.029, 158.235],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.596, -197.688],
                                  [-251.191, 11.68],
                                  [-67.873, 158.287],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.226, -198.499],
                                  [-252.421, 11.434],
                                  [-67.753, 158.334],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.853, -199.278],
                                  [-253.563, 11.189],
                                  [-67.604, 158.381],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.383, -199.994],
                                  [-254.615, 10.974],
                                  [-67.49, 158.423],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.877, -200.648],
                                  [-255.61, 10.76],
                                  [-67.349, 158.463],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.368, -201.24],
                                  [-256.515, 10.576],
                                  [-67.272, 158.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.791, -201.832],
                                  [-257.333, 10.392],
                                  [-67.137, 158.535],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.212, -202.362],
                                  [-258.091, 10.239],
                                  [-67.067, 158.567],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.565, -202.829],
                                  [-258.792, 10.086],
                                  [-66.969, 158.595],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.884, -203.234],
                                  [-259.403, 9.963],
                                  [-66.906, 158.622],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.169, -203.607],
                                  [-259.986, 9.841],
                                  [-66.815, 158.647],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.451, -203.95],
                                  [-260.45, 9.749],
                                  [-66.788, 158.669],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.665, -204.261],
                                  [-260.916, 9.657],
                                  [-66.734, 158.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.877, -204.51],
                                  [-261.295, 9.565],
                                  [-66.681, 158.703],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.053, -204.729],
                                  [-261.614, 9.504],
                                  [-66.633, 158.719],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.195, -204.915],
                                  [-261.905, 9.443],
                                  [-66.588, 158.73],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.303, -205.072],
                                  [-262.106, 9.412],
                                  [-66.576, 158.741],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.407, -205.196],
                                  [-262.283, 9.351],
                                  [-66.535, 158.747],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.478, -205.258],
                                  [-262.367, 9.351],
                                  [-66.56, 158.753],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.514, -205.32],
                                  [-262.456, 9.32],
                                  [-66.524, 158.757],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.465, -205.259],
                                  [-262.442, 9.322],
                                  [-66.477, 158.716],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.375, -205.11],
                                  [-262.285, 9.418],
                                  [-66.403, 158.496],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-173.149, -204.777],
                                  [-262.05, 9.55],
                                  [-66.208, 158.159],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.845, -204.356],
                                  [-261.673, 9.715],
                                  [-65.959, 157.703],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-172.491, -203.783],
                                  [-261.195, 9.946],
                                  [-65.628, 157.064],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.99, -203.028],
                                  [-260.554, 10.244],
                                  [-65.185, 156.208],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-171.369, -202.154],
                                  [-259.753, 10.608],
                                  [-64.633, 155.196],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-170.62, -201.004],
                                  [-258.829, 11.069],
                                  [-63.978, 153.964],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-169.742, -199.702],
                                  [-257.726, 11.565],
                                  [-63.189, 152.511],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-168.726, -198.157],
                                  [-256.387, 12.193],
                                  [-62.273, 150.831],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-167.532, -196.336],
                                  [-254.846, 12.952],
                                  [-61.201, 148.861],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-166.124, -194.237],
                                  [-253.018, 13.781],
                                  [-59.916, 146.535],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-164.553, -191.799],
                                  [-250.941, 14.741],
                                  [-58.421, 143.914],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-162.713, -188.988],
                                  [-248.467, 15.869],
                                  [-56.691, 140.902],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-160.625, -185.771],
                                  [-245.665, 17.163],
                                  [-54.698, 137.432],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-158.243, -182.051],
                                  [-242.389, 18.658],
                                  [-52.353, 133.536],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-155.549, -177.793],
                                  [-238.614, 20.325],
                                  [-49.659, 129.055],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-152.461, -172.834],
                                  [-234.193, 22.324],
                                  [-46.491, 123.927],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-148.926, -167.134],
                                  [-229.036, 24.565],
                                  [-42.753, 118.061],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-144.857, -160.461],
                                  [-223.02, 27.179],
                                  [-38.344, 111.404],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-140.227, -152.702],
                                  [-215.89, 30.235],
                                  [-33.119, 103.749],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-134.916, -143.644],
                                  [-207.405, 33.775],
                                  [-26.826, 95],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-128.879, -133.058],
                                  [-197.395, 37.871],
                                  [-19.341, 85.012],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-122.057, -120.741],
                                  [-185.559, 42.63],
                                  [-10.411, 73.76],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-114.466, -106.601],
                                  [-171.732, 48.065],
                                  [0.137, 61.312],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-106.3, -90.803],
                                  [-155.954, 54.085],
                                  [12.315, 47.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-97.911, -73.857],
                                  [-138.663, 60.523],
                                  [25.807, 34.206],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-89.71, -56.506],
                                  [-120.623, 66.987],
                                  [40.028, 20.836],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-82.018, -39.504],
                                  [-102.628, 73.317],
                                  [54.436, 8.426],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-75.047, -23.479],
                                  [-85.296, 79.227],
                                  [68.422, -2.726],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.918, -8.594],
                                  [-68.943, 84.713],
                                  [81.697, -12.551],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-63.508, 5.06],
                                  [-53.717, 89.666],
                                  [94.222, -21.164],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.78, 17.559],
                                  [-39.614, 94.167],
                                  [105.866, -28.729],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-54.62, 28.959],
                                  [-26.533, 98.269],
                                  [116.688, -35.311],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-50.929, 39.46],
                                  [-14.417, 102.019],
                                  [126.788, -41.142],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.648, 49.092],
                                  [-3.139, 105.457],
                                  [136.202, -46.325],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-44.73, 57.941],
                                  [7.328, 108.608],
                                  [145.018, -50.958],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-42.098, 66.188],
                                  [17.083, 111.511],
                                  [153.222, -55.071],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-39.742, 73.765],
                                  [26.231, 114.169],
                                  [160.966, -58.807],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-37.596, 80.873],
                                  [34.767, 116.685],
                                  [168.184, -62.163],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.659, 87.49],
                                  [42.792, 118.979],
                                  [174.983, -65.213],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-33.875, 93.696],
                                  [50.328, 121.113],
                                  [181.43, -68.009],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-32.248, 99.469],
                                  [57.445, 123.151],
                                  [187.47, -70.539],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-30.746, 104.912],
                                  [64.129, 125.031],
                                  [193.22, -72.85],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-29.382, 110.002],
                                  [70.454, 126.796],
                                  [198.608, -75.01],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-28.103, 114.846],
                                  [76.432, 128.445],
                                  [203.732, -77.003],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-26.946, 119.359],
                                  [82.077, 130.022],
                                  [208.567, -78.788],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.86, 123.62],
                                  [87.403, 131.484],
                                  [213.17, -80.517],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.821, 127.635],
                                  [92.471, 132.875],
                                  [217.537, -82.063],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.928, 131.486],
                                  [97.296, 134.196],
                                  [221.669, -83.516],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.028, 135.065],
                                  [101.81, 135.427],
                                  [225.621, -84.834],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.249, 138.462],
                                  [106.116, 136.583],
                                  [229.332, -86.099],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-21.479, 141.693],
                                  [110.237, 137.694],
                                  [232.882, -87.252],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.754, 144.759],
                                  [114.101, 138.71],
                                  [236.226, -88.354],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.121, 147.643],
                                  [117.793, 139.702],
                                  [239.411, -89.39],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.509, 150.361],
                                  [121.266, 140.625],
                                  [242.431, -90.352],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-18.895, 152.942],
                                  [124.59, 141.498],
                                  [245.327, -91.205],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-18.369, 155.375],
                                  [127.737, 142.366],
                                  [248.019, -92.029],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.856, 157.689],
                                  [130.687, 143.103],
                                  [250.629, -92.808],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.381, 159.91],
                                  [133.502, 143.898],
                                  [253.096, -93.542],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.944, 161.94],
                                  [136.199, 144.563],
                                  [255.418, -94.204],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.56, 163.94],
                                  [138.723, 145.24],
                                  [257.595, -94.839],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.148, 165.776],
                                  [141.126, 145.851],
                                  [259.689, -95.448],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-15.786, 167.494],
                                  [143.374, 146.44],
                                  [261.637, -95.985],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-15.44, 169.137],
                                  [145.499, 146.997],
                                  [263.484, -96.497],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-15.126, 170.68],
                                  [147.512, 147.515],
                                  [265.229, -96.982],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.814, 172.15],
                                  [149.367, 148.002],
                                  [266.89, -97.442],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.559, 173.501],
                                  [151.144, 148.467],
                                  [268.432, -97.848],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.289, 174.797],
                                  [152.825, 148.883],
                                  [269.889, -98.229],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.029, 175.975],
                                  [154.392, 149.279],
                                  [271.29, -98.6],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.814, 177.081],
                                  [155.863, 149.671],
                                  [272.543, -98.93],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.61, 178.133],
                                  [157.192, 150.014],
                                  [273.74, -99.267],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.432, 179.113],
                                  [158.469, 150.355],
                                  [274.835, -99.534],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.28, 180.038],
                                  [159.649, 150.629],
                                  [275.873, -99.791],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.091, 180.846],
                                  [160.716, 150.946],
                                  [276.827, -100.023],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.928, 181.601],
                                  [161.747, 151.169],
                                  [277.679, -100.246],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.818, 182.33],
                                  [162.619, 151.435],
                                  [278.504, -100.461],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.687, 182.96],
                                  [163.485, 151.636],
                                  [279.198, -100.666],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.579, 183.519],
                                  [164.225, 151.833],
                                  [279.882, -100.816],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.479, 184.025],
                                  [164.941, 151.983],
                                  [280.465, -100.957],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.386, 184.507],
                                  [165.499, 152.176],
                                  [280.993, -101.071],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.287, 184.89],
                                  [166.067, 152.32],
                                  [281.482, -101.193],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.223, 185.266],
                                  [166.538, 152.445],
                                  [281.899, -101.288],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.198, 185.572],
                                  [166.895, 152.523],
                                  [282.216, -101.39],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.117, 185.826],
                                  [167.263, 152.597],
                                  [282.54, -101.482],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.058, 186.01],
                                  [167.517, 152.67],
                                  [282.764, -101.502],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.067, 186.205],
                                  [167.737, 152.724],
                                  [282.963, -101.574],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.035, 186.315],
                                  [167.89, 152.792],
                                  [283.078, -101.589],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.009, 186.389],
                                  [167.945, 152.796],
                                  [283.186, -101.611],
                                ],
                                c: false,
                              },
                            ],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.35, 0.35, 0.35, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.35, 0.35, 0.35, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [187.807, 109.035],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.795, 109.158],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.774, 109.319],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.728, 109.585],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.665, 109.948],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.609, 110.44],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.5, 111.008],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.41, 111.708],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.325, 112.542],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.196, 113.488],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.075, 114.633],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.956, 115.962],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.865, 117.475],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.754, 119.254],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.742, 121.253],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.73, 123.557],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.887, 126.191],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.15, 129.221],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.626, 132.677],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.437, 136.61],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.777, 141.044],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.809, 145.978],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.802, 151.28],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.17, 156.663],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.152, 158.442],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.964, 159.248],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.986, 159.952],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.236, 160.697],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [225.261, 161.451],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [231.323, 161.011],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [231.429, 159.134],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [230.426, 157.836],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [228.814, 157.011],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [226.825, 156.555],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [224.683, 156.392],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.504, 156.428],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [220.351, 156.587],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [218.206, 156.766],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.17, 156.996],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.171, 157.199],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [212.297, 157.374],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.449, 157.523],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.694, 157.546],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.994, 157.515],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.368, 157.413],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.777, 157.207],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.258, 156.866],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.801, 156.468],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.37, 155.918],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.998, 155.293],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.684, 154.567],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.393, 153.757],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.201, 152.884],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.034, 151.959],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.94, 151.031],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.89, 150.074],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.914, 149.104],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.979, 148.14],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.138, 147.247],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.356, 146.368],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.588, 145.533],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.906, 144.729],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.286, 143.97],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.667, 143.242],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.137, 142.529],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.61, 141.889],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.125, 141.26],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.691, 140.649],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.271, 140.061],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.902, 139.526],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.528, 138.998],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.199, 138.497],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.896, 137.989],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.619, 137.515],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.364, 137.05],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.122, 136.611],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.901, 136.177],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.689, 135.769],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.499, 135.35],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.329, 134.94],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.195, 134.532],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.054, 134.149],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.931, 133.779],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.845, 133.417],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.715, 133.069],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.636, 132.691],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.56, 132.369],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.505, 132.004],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.451, 131.67],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.416, 131.333],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.316, 130.982],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.035, 130.643],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.626, 130.24],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.035, 129.849],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.282, 129.417],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.304, 128.948],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.086, 128.467],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.659, 127.92],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.963, 127.336],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.95, 126.716],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.653, 126.032],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.027, 125.285],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.962, 124.479],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.48, 123.585],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [153.504, 122.608],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.93, 121.524],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.75, 120.304],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.851, 118.95],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.045, 117.418],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.278, 115.677],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.385, 113.672],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.208, 111.365],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.649, 108.675],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.606, 105.5],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.207, 101.723],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.947, 97.161],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.445, 91.61],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.263, 84.889],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.501, 76.948],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.219, 68.015],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.433, 58.703],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.408, 49.586],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.009, 42.151],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.481, 36.388],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.347, 32.004],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.992, 28.733],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.631, 26.238],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.51, 24.368],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.641, 22.934],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.164, 21.797],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.125, 20.908],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.608, 20.197],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.587, 19.592],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.153, 19.101],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.368, 18.707],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.18, 18.37],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.66, 18.061],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.871, 17.818],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.741, 17.593],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.359, 17.407],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.732, 17.242],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.826, 17.081],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.75, 16.958],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.439, 16.858],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.926, 16.744],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.238, 16.658],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.315, 16.558],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.253, 16.475],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.489, 16.275],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-232.578, 16.07],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-234.549, 15.884],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-236.403, 15.694],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-238.132, 15.535],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-239.779, 15.37],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-241.286, 15.224],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-242.735, 15.077],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-244.064, 14.934],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.314, 14.811],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.486, 14.703],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-247.572, 14.574],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-248.604, 14.47],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.54, 14.373],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.419, 14.281],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.221, 14.203],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.978, 14.123],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.651, 14.039],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.291, 13.966],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.861, 13.904],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.367, 13.852],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.834, 13.81],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.232, 13.761],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.621, 13.721],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.916, 13.688],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.175, 13.666],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.407, 13.645],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.59, 13.625],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.712, 13.603],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.8, 13.598],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.829, 13.601],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.743, 13.636],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.554, 13.721],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.257, 13.811],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.882, 13.943],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.38, 14.109],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.758, 14.309],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.022, 14.594],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.125, 14.899],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.06, 15.283],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.844, 15.761],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.356, 16.298],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-247.647, 16.952],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.603, 17.736],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-243.287, 18.661],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.503, 19.798],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-237.239, 21.116],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.385, 22.761],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.749, 24.709],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-223.02, 27.179],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.708, 29.796],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.221, 33.326],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.388, 37.872],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.273, 42.859],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.027, 48.351],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.357, 53.807],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.759, 58.459],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.481, 61.488],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.099, 62.77],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.981, 62.532],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-32.821, 61.39],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.832, 59.786],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.96, 58.202],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.73, 56.985],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 56.226],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.245, 56.022],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.443, 56.325],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.566, 57.081],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.826, 58.2],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.335, 59.623],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.242, 61.223],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.651, 62.945],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.605, 64.817],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.176, 66.709],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.394, 68.626],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.318, 70.53],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.942, 72.467],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.321, 74.311],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.477, 76.151],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.408, 77.984],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.126, 79.715],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.645, 81.409],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.017, 83.074],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.189, 84.627],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.214, 86.165],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.07, 87.622],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [151.809, 89.037],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.392, 90.423],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.834, 91.675],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [159.168, 92.941],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.38, 94.132],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.458, 95.285],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.45, 96.348],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.306, 97.381],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [169.068, 98.356],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.736, 99.28],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.295, 100.118],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.762, 100.967],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.154, 101.749],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.469, 102.464],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.686, 103.175],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.806, 103.784],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.863, 104.413],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.838, 104.975],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.744, 105.495],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.588, 105.974],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.332, 106.411],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.041, 106.816],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.667, 107.178],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.249, 107.52],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.731, 107.81],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.21, 108.075],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.603, 108.311],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.897, 108.481],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.211, 108.629],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.426, 108.761],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.606, 108.875],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.734, 108.968],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [187.807, 109.035],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.795, 109.158],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.774, 109.319],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.728, 109.585],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.665, 109.948],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.609, 110.44],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.5, 111.008],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.41, 111.708],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.325, 112.542],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.196, 113.488],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.075, 114.633],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.956, 115.962],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.865, 117.475],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.754, 119.254],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.742, 121.253],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.73, 123.557],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.887, 126.191],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.15, 129.221],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.626, 132.677],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.437, 136.61],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.777, 141.044],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.809, 145.978],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.802, 151.28],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.17, 156.663],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.152, 158.442],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.964, 159.248],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.986, 159.952],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.236, 160.697],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [225.261, 161.451],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [231.323, 161.011],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [231.429, 159.134],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [230.426, 157.836],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [228.814, 157.011],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [226.825, 156.555],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [224.683, 156.392],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.504, 156.428],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [220.351, 156.587],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [218.206, 156.766],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.17, 156.996],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.171, 157.199],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [212.297, 157.374],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.449, 157.523],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.694, 157.546],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.994, 157.515],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.368, 157.413],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.777, 157.207],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.258, 156.866],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.801, 156.468],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.37, 155.918],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.998, 155.293],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.684, 154.567],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.393, 153.757],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.201, 152.884],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.034, 151.959],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.94, 151.031],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.89, 150.074],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.914, 149.104],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.979, 148.14],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.138, 147.247],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.356, 146.368],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.588, 145.533],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.906, 144.729],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.286, 143.97],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.667, 143.242],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.137, 142.529],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.61, 141.889],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.125, 141.26],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.691, 140.649],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.271, 140.061],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.902, 139.526],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.528, 138.998],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.199, 138.497],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.896, 137.989],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.619, 137.515],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.364, 137.05],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.122, 136.611],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.901, 136.177],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.689, 135.769],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.499, 135.35],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.329, 134.94],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.195, 134.532],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.054, 134.149],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.931, 133.779],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.845, 133.417],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.715, 133.069],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.636, 132.691],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.56, 132.369],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.505, 132.004],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.451, 131.67],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.416, 131.333],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.316, 130.982],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.035, 130.643],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.626, 130.24],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.035, 129.849],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.282, 129.417],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.304, 128.948],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.086, 128.467],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.659, 127.92],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.963, 127.336],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.95, 126.716],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.653, 126.032],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.027, 125.285],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.962, 124.479],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.48, 123.585],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [153.504, 122.608],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.93, 121.524],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.75, 120.304],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.851, 118.95],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [131.045, 117.418],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.278, 115.677],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [114.385, 113.672],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [104.208, 111.365],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [92.649, 108.675],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [79.606, 105.5],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [65.207, 101.723],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.947, 97.161],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.445, 91.61],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.263, 84.889],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.501, 76.948],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-10.219, 68.015],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-25.433, 58.703],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.408, 49.586],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.009, 42.151],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.481, 36.388],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.347, 32.004],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.992, 28.733],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.631, 26.238],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-120.51, 24.368],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-129.641, 22.934],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-138.164, 21.797],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-146.125, 20.908],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.608, 20.197],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-160.587, 19.592],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-167.153, 19.101],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.368, 18.707],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.18, 18.37],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.66, 18.061],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.871, 17.818],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-194.741, 17.593],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.359, 17.407],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.732, 17.242],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.826, 17.081],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.75, 16.958],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.439, 16.858],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.926, 16.744],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-222.238, 16.658],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-225.315, 16.558],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.253, 16.475],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.489, 16.275],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-232.578, 16.07],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-234.549, 15.884],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-236.403, 15.694],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-238.132, 15.535],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-239.779, 15.37],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-241.286, 15.224],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-242.735, 15.077],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-244.064, 14.934],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.314, 14.811],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.486, 14.703],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-247.572, 14.574],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-248.604, 14.47],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.54, 14.373],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.419, 14.281],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.221, 14.203],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.978, 14.123],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.651, 14.039],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.291, 13.966],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.861, 13.904],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.367, 13.852],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.834, 13.81],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.232, 13.761],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.621, 13.721],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.916, 13.688],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.175, 13.666],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.407, 13.645],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.59, 13.625],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.712, 13.603],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.8, 13.598],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.829, 13.601],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.743, 13.636],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.554, 13.721],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-256.257, 13.811],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.882, 13.943],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.38, 14.109],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.758, 14.309],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.022, 14.594],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-253.125, 14.899],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-252.06, 15.283],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.844, 15.761],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-249.356, 16.298],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-247.647, 16.952],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.603, 17.736],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-243.287, 18.661],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.503, 19.798],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-237.239, 21.116],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.385, 22.761],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-228.749, 24.709],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-223.02, 27.179],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-215.708, 29.796],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-207.221, 33.326],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.388, 37.872],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-184.273, 42.859],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-168.027, 48.351],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-148.357, 53.807],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.759, 58.459],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.481, 61.488],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-77.099, 62.77],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-53.981, 62.532],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-32.821, 61.39],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-13.832, 59.786],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.96, 58.202],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.73, 56.985],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 56.226],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.245, 56.022],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.443, 56.325],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.566, 57.081],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [69.826, 58.2],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [77.335, 59.623],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [84.242, 61.223],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [90.651, 62.945],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [96.605, 64.817],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.176, 66.709],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.394, 68.626],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.318, 70.53],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.942, 72.467],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [121.321, 74.311],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.477, 76.151],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [129.408, 77.984],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.126, 79.715],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.645, 81.409],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.017, 83.074],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [143.189, 84.627],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [146.214, 86.165],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [149.07, 87.622],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [151.809, 89.037],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.392, 90.423],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.834, 91.675],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [159.168, 92.941],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.38, 94.132],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.458, 95.285],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.45, 96.348],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.306, 97.381],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [169.068, 98.356],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.736, 99.28],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.295, 100.118],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.762, 100.967],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.154, 101.749],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.469, 102.464],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.686, 103.175],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.806, 103.784],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.863, 104.413],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.838, 104.975],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.744, 105.495],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.588, 105.974],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.332, 106.411],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.041, 106.816],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.667, 107.178],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.249, 107.52],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.731, 107.81],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.21, 108.075],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.603, 108.311],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.897, 108.481],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.211, 108.629],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.426, 108.761],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.606, 108.875],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.734, 108.968],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 12',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 12,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.004, 200.796],
                                  [179.996, 157.599],
                                  [302.394, -87.204],
                                ],
                                c: false,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.005, 200.794],
                                  [179.995, 157.598],
                                  [302.341, -87.144],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.052, 200.712],
                                  [180.064, 157.633],
                                  [302.108, -86.976],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.167, 200.626],
                                  [180.18, 157.654],
                                  [301.718, -86.737],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.342, 200.498],
                                  [180.354, 157.674],
                                  [301.097, -86.328],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.512, 200.327],
                                  [180.549, 157.729],
                                  [300.369, -85.799],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.803, 200.114],
                                  [180.853, 157.821],
                                  [299.36, -85.087],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [1.089, 199.858],
                                  [181.164, 157.897],
                                  [298.132, -84.256],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [1.496, 199.523],
                                  [181.584, 158.009],
                                  [296.671, -83.205],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [1.96, 199.144],
                                  [182.086, 158.169],
                                  [294.929, -82.023],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [2.482, 198.686],
                                  [182.633, 158.301],
                                  [292.917, -80.597],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [3.124, 198.148],
                                  [183.313, 158.455],
                                  [290.524, -78.89],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [3.823, 197.566],
                                  [184.075, 158.694],
                                  [287.785, -76.989],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [4.704, 196.868],
                                  [184.994, 158.917],
                                  [284.615, -74.733],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [5.641, 196.09],
                                  [186.008, 159.198],
                                  [280.951, -72.135],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.822, 195.195],
                                  [187.241, 159.5],
                                  [276.793, -69.156],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.06, 194.147],
                                  [188.58, 159.822],
                                  [272.029, -65.761],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.602, 192.983],
                                  [190.201, 160.263],
                                  [266.56, -61.888],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.326, 191.631],
                                  [192.027, 160.734],
                                  [260.262, -57.361],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.292, 190.126],
                                  [194.123, 161.259],
                                  [253.05, -52.156],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [15.562, 188.362],
                                  [196.55, 161.875],
                                  [244.749, -46.126],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [18.262, 186.338],
                                  [199.419, 162.604],
                                  [235.101, -39.181],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [21.39, 184.022],
                                  [202.732, 163.443],
                                  [223.995, -31.087],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.947, 181.378],
                                  [206.527, 164.428],
                                  [211.162, -21.719],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [29.119, 178.337],
                                  [210.965, 165.542],
                                  [196.344, -10.854],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [33.843, 174.904],
                                  [215.997, 166.782],
                                  [179.632, 1.42],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [39.119, 171.156],
                                  [221.597, 168.157],
                                  [161.232, 14.952],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [44.633, 167.206],
                                  [227.478, 169.591],
                                  [141.803, 29.252],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [50.135, 163.276],
                                  [233.351, 171.062],
                                  [122.246, 43.608],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [55.499, 159.474],
                                  [239.064, 172.384],
                                  [103.402, 57.445],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [60.411, 155.982],
                                  [244.317, 173.595],
                                  [85.866, 70.3],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [64.871, 152.762],
                                  [249.096, 174.609],
                                  [69.861, 82.007],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [68.878, 149.884],
                                  [253.4, 175.538],
                                  [55.37, 92.556],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [72.492, 147.311],
                                  [257.29, 176.306],
                                  [42.281, 102.03],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [75.713, 144.971],
                                  [260.765, 176.926],
                                  [30.396, 110.504],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [78.54, 142.863],
                                  [263.837, 177.418],
                                  [19.65, 118.213],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [81.095, 140.95],
                                  [266.63, 177.831],
                                  [9.833, 125.105],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [83.44, 139.231],
                                  [269.177, 178.149],
                                  [0.894, 131.401],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [85.447, 137.672],
                                  [271.407, 178.322],
                                  [-7.354, 137.065],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [87.303, 136.271],
                                  [273.463, 178.445],
                                  [-14.885, 142.203],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [88.942, 134.96],
                                  [275.311, 178.452],
                                  [-21.922, 146.925],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [90.426, 133.806],
                                  [276.995, 178.391],
                                  [-28.354, 151.179],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [91.688, 132.743],
                                  [278.451, 178.271],
                                  [-34.356, 155.039],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [92.851, 131.77],
                                  [279.818, 177.999],
                                  [-39.96, 158.567],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [93.85, 130.889],
                                  [281.014, 177.663],
                                  [-45.182, 161.756],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [94.683, 130.099],
                                  [282.054, 177.278],
                                  [-50.022, 164.589],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [95.41, 129.401],
                                  [282.982, 176.794],
                                  [-54.575, 167.157],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [95.967, 128.764],
                                  [283.751, 176.16],
                                  [-58.794, 169.488],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [96.477, 128.218],
                                  [284.468, 175.518],
                                  [-62.772, 171.52],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [96.818, 127.734],
                                  [285.026, 174.727],
                                  [-66.477, 173.299],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [97.053, 127.31],
                                  [285.488, 173.884],
                                  [-69.942, 174.874],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [97.188, 126.947],
                                  [285.86, 172.966],
                                  [-73.198, 176.178],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [97.229, 126.669],
                                  [286.129, 171.977],
                                  [-76.259, 177.358],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [97.187, 126.418],
                                  [286.339, 170.937],
                                  [-79.08, 178.352],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [97.134, 126.244],
                                  [286.507, 169.871],
                                  [-81.743, 179.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [96.957, 126.089],
                                  [286.59, 168.811],
                                  [-84.232, 179.941],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [96.793, 125.976],
                                  [286.649, 167.73],
                                  [-86.551, 180.558],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [96.651, 125.899],
                                  [286.728, 166.624],
                                  [-88.745, 181.141],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [96.412, 125.83],
                                  [286.707, 165.547],
                                  [-90.773, 181.618],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [96.208, 125.813],
                                  [286.735, 164.544],
                                  [-92.702, 182.054],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [96.041, 125.774],
                                  [286.756, 163.563],
                                  [-94.451, 182.458],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [95.849, 125.781],
                                  [286.747, 162.623],
                                  [-96.149, 182.809],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [95.633, 125.787],
                                  [286.727, 161.708],
                                  [-97.691, 183.191],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [95.454, 125.812],
                                  [286.739, 160.858],
                                  [-99.142, 183.498],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [95.311, 125.833],
                                  [286.74, 160.029],
                                  [-100.526, 183.77],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [95.14, 125.872],
                                  [286.748, 159.221],
                                  [-101.802, 184.047],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [95, 125.906],
                                  [286.74, 158.494],
                                  [-102.991, 184.285],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [94.827, 125.956],
                                  [286.716, 157.783],
                                  [-104.116, 184.52],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [94.683, 126.002],
                                  [286.715, 157.087],
                                  [-105.156, 184.712],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [94.501, 126.062],
                                  [286.673, 156.424],
                                  [-106.132, 184.877],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [94.406, 126.118],
                                  [286.691, 155.812],
                                  [-107.025, 185.053],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [94.209, 126.17],
                                  [286.626, 155.23],
                                  [-107.877, 185.157],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [94.095, 126.235],
                                  [286.617, 154.674],
                                  [-108.667, 185.267],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [93.938, 126.297],
                                  [286.563, 154.103],
                                  [-109.354, 185.364],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [93.8, 126.371],
                                  [286.523, 153.578],
                                  [-110, 185.424],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [93.616, 126.425],
                                  [286.457, 153.075],
                                  [-110.607, 185.444],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [93.51, 126.509],
                                  [286.422, 152.593],
                                  [-111.153, 185.487],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [93.356, 126.572],
                                  [286.359, 152.131],
                                  [-111.658, 185.488],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [93.154, 126.648],
                                  [286.244, 151.709],
                                  [-112.086, 185.445],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [93.028, 126.72],
                                  [286.18, 151.265],
                                  [-112.491, 185.381],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [92.851, 126.805],
                                  [286.087, 150.838],
                                  [-112.858, 185.311],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [92.687, 126.87],
                                  [286.003, 150.427],
                                  [-113.164, 185.195],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [92.533, 126.948],
                                  [285.904, 150.03],
                                  [-113.415, 185.117],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [92.327, 127.023],
                                  [285.775, 149.671],
                                  [-113.644, 184.968],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [92.193, 127.11],
                                  [285.693, 149.325],
                                  [-113.812, 184.794],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [92.006, 127.194],
                                  [285.556, 148.992],
                                  [-114.005, 184.632],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [91.765, 127.274],
                                  [285.387, 148.631],
                                  [-114.098, 184.42],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [91.596, 127.352],
                                  [285.264, 148.345],
                                  [-114.192, 184.197],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [91.434, 127.426],
                                  [285.147, 148.008],
                                  [-114.248, 183.945],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [91.218, 127.513],
                                  [284.974, 147.705],
                                  [-114.244, 183.705],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [91.008, 127.597],
                                  [284.807, 147.412],
                                  [-114.202, 183.412],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [90.782, 127.615],
                                  [284.544, 147.09],
                                  [-114.137, 183.114],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [90.475, 127.506],
                                  [284.056, 146.777],
                                  [-114.151, 182.811],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [90.102, 127.308],
                                  [283.348, 146.359],
                                  [-114.093, 182.504],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [89.686, 126.919],
                                  [282.417, 145.949],
                                  [-114.073, 182.164],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [89.14, 126.403],
                                  [281.213, 145.472],
                                  [-114.044, 181.819],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [88.511, 125.698],
                                  [279.701, 144.927],
                                  [-114.004, 181.456],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [87.813, 124.84],
                                  [277.866, 144.352],
                                  [-113.977, 181.075],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [86.959, 123.755],
                                  [275.699, 143.671],
                                  [-113.952, 180.663],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [85.996, 122.494],
                                  [273.149, 142.923],
                                  [-113.916, 180.234],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [84.937, 120.98],
                                  [270.178, 142.106],
                                  [-113.904, 179.788],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [83.695, 119.177],
                                  [266.763, 141.184],
                                  [-113.893, 179.299],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [82.256, 117.11],
                                  [262.828, 140.156],
                                  [-113.844, 178.767],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [80.67, 114.691],
                                  [258.3, 139.023],
                                  [-113.832, 178.207],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [78.836, 111.894],
                                  [253.116, 137.747],
                                  [-113.793, 177.593],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [76.766, 108.696],
                                  [247.203, 136.328],
                                  [-113.764, 176.926],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [74.434, 105.009],
                                  [240.424, 134.729],
                                  [-113.759, 176.206],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [71.74, 100.795],
                                  [232.68, 132.912],
                                  [-113.712, 175.409],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [68.646, 95.891],
                                  [223.822, 130.879],
                                  [-113.699, 174.524],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [65.149, 90.248],
                                  [213.578, 128.553],
                                  [-113.631, 173.54],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [61.112, 83.728],
                                  [201.812, 125.899],
                                  [-113.593, 172.446],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [56.445, 76.168],
                                  [188.214, 122.843],
                                  [-113.536, 171.205],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [51.047, 67.393],
                                  [172.463, 119.347],
                                  [-113.468, 169.809],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [44.778, 57.228],
                                  [154.249, 115.338],
                                  [-113.376, 168.235],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [37.611, 45.474],
                                  [133.303, 110.742],
                                  [-113.281, 166.449],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [29.519, 32.204],
                                  [109.553, 105.561],
                                  [-113.183, 164.454],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [20.575, 17.543],
                                  [83.427, 99.87],
                                  [-113.053, 162.289],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.104, 2.017],
                                  [55.835, 93.857],
                                  [-112.906, 160.028],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [1.536, -13.575],
                                  [28.06, 87.818],
                                  [-112.719, 157.767],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.652, -28.618],
                                  [1.356, 82.015],
                                  [-112.559, 155.625],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.257, -42.638],
                                  [-23.52, 76.632],
                                  [-112.369, 153.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.1, -55.484],
                                  [-46.213, 71.704],
                                  [-112.212, 151.85],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-31.253, -67.094],
                                  [-66.765, 67.23],
                                  [-112.018, 150.235],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-37.755, -77.567],
                                  [-85.289, 63.21],
                                  [-111.824, 148.798],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-43.628, -87.042],
                                  [-102.064, 59.567],
                                  [-111.656, 147.512],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.985, -95.691],
                                  [-117.265, 56.264],
                                  [-111.491, 146.351],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-53.888, -103.53],
                                  [-131.094, 53.225],
                                  [-111.315, 145.304],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.411, -110.756],
                                  [-143.713, 50.487],
                                  [-111.156, 144.356],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.543, -117.358],
                                  [-155.347, 47.938],
                                  [-110.974, 143.495],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-66.382, -123.41],
                                  [-166.01, 45.615],
                                  [-110.796, 142.718],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-69.942, -129.062],
                                  [-175.865, 43.443],
                                  [-110.645, 142.004],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-73.26, -134.278],
                                  [-184.999, 41.459],
                                  [-110.474, 141.338],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-76.36, -139.119],
                                  [-193.499, 39.589],
                                  [-110.306, 140.733],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-79.231, -143.66],
                                  [-201.379, 37.869],
                                  [-110.167, 140.176],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-81.909, -147.889],
                                  [-208.738, 36.262],
                                  [-110.031, 139.665],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-84.457, -151.832],
                                  [-215.64, 34.731],
                                  [-109.874, 139.19],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-86.863, -155.537],
                                  [-222.06, 33.312],
                                  [-109.721, 138.74],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-89.065, -159.029],
                                  [-228.107, 32.006],
                                  [-109.609, 138.335],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-91.224, -162.298],
                                  [-233.799, 30.738],
                                  [-109.451, 137.953],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-93.178, -165.379],
                                  [-239.119, 29.582],
                                  [-109.335, 137.594],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-95.079, -168.285],
                                  [-244.121, 28.464],
                                  [-109.209, 137.257],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-96.836, -170.992],
                                  [-248.852, 27.422],
                                  [-109.099, 136.954],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-98.514, -173.586],
                                  [-253.276, 26.454],
                                  [-108.969, 136.662],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-100.101, -175.993],
                                  [-257.491, 25.524],
                                  [-108.867, 136.392],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-101.607, -178.275],
                                  [-261.426, 24.631],
                                  [-108.719, 136.143],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-103.033, -180.47],
                                  [-265.164, 23.813],
                                  [-108.624, 135.905],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-104.393, -182.503],
                                  [-268.695, 23.033],
                                  [-108.521, 135.688],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-105.637, -184.448],
                                  [-271.992, 22.327],
                                  [-108.435, 135.482],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-106.826, -186.268],
                                  [-275.109, 21.622],
                                  [-108.35, 135.286],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-107.96, -187.964],
                                  [-278.092, 20.954],
                                  [-108.22, 135.1],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-109.065, -189.598],
                                  [-280.843, 20.36],
                                  [-108.181, 134.934],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-110.053, -191.107],
                                  [-283.485, 19.767],
                                  [-108.071, 134.779],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-111.038, -192.59],
                                  [-285.959, 19.211],
                                  [-107.989, 134.623],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-111.943, -193.949],
                                  [-288.298, 18.692],
                                  [-107.898, 134.487],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-112.782, -195.209],
                                  [-290.505, 18.21],
                                  [-107.835, 134.361],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-113.592, -196.417],
                                  [-292.542, 17.765],
                                  [-107.764, 134.233],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-114.348, -197.553],
                                  [-294.508, 17.321],
                                  [-107.695, 134.126],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-115.074, -198.637],
                                  [-296.305, 16.914],
                                  [-107.617, 134.018],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-115.736, -199.622],
                                  [-298.005, 16.544],
                                  [-107.568, 133.919],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-116.368, -200.545],
                                  [-299.635, 16.174],
                                  [-107.484, 133.83],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-116.946, -201.442],
                                  [-301.095, 15.841],
                                  [-107.428, 133.74],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-117.495, -202.24],
                                  [-302.495, 15.545],
                                  [-107.401, 133.669],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-118.041, -203.013],
                                  [-303.789, 15.249],
                                  [-107.339, 133.587],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-118.496, -203.723],
                                  [-304.986, 14.99],
                                  [-107.305, 133.524],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-118.922, -204.371],
                                  [-306.112, 14.731],
                                  [-107.237, 133.461],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.346, -204.957],
                                  [-307.143, 14.509],
                                  [-107.233, 133.406],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.714, -205.543],
                                  [-308.066, 14.288],
                                  [-107.158, 133.35],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.081, -206.066],
                                  [-308.929, 14.103],
                                  [-107.148, 133.304],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.392, -206.528],
                                  [-309.722, 13.918],
                                  [-107.104, 133.267],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.665, -206.927],
                                  [-310.419, 13.77],
                                  [-107.087, 133.228],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.908, -207.3],
                                  [-311.081, 13.623],
                                  [-107.036, 133.189],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.15, -207.637],
                                  [-311.611, 13.512],
                                  [-107.05, 133.158],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.336, -207.948],
                                  [-312.143, 13.401],
                                  [-107.029, 133.137],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.521, -208.196],
                                  [-312.568, 13.29],
                                  [-107.01, 133.114],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.677, -208.409],
                                  [-312.933, 13.216],
                                  [-106.982, 133.091],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.804, -208.595],
                                  [-313.264, 13.142],
                                  [-106.957, 133.076],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.892, -208.746],
                                  [-313.498, 13.105],
                                  [-106.959, 133.061],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.989, -208.87],
                                  [-313.688, 13.032],
                                  [-106.937, 133.054],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-122.047, -208.932],
                                  [-313.792, 13.031],
                                  [-106.969, 133.047],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-122.076, -208.995],
                                  [-313.887, 12.995],
                                  [-106.94, 133.038],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-122.035, -208.933],
                                  [-313.866, 12.996],
                                  [-106.879, 133.004],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.969, -208.765],
                                  [-313.672, 13.098],
                                  [-106.763, 132.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.777, -208.412],
                                  [-313.358, 13.229],
                                  [-106.503, 132.524],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.53, -207.952],
                                  [-312.886, 13.4],
                                  [-106.155, 132.138],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-121.251, -207.333],
                                  [-312.263, 13.636],
                                  [-105.683, 131.59],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.848, -206.519],
                                  [-311.441, 13.938],
                                  [-105.064, 130.854],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-120.343, -205.573],
                                  [-310.422, 14.307],
                                  [-104.3, 129.989],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.742, -204.332],
                                  [-309.237, 14.778],
                                  [-103.384, 128.933],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-119.03, -202.932],
                                  [-307.815, 15.291],
                                  [-102.291, 127.682],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-118.212, -201.263],
                                  [-306.1, 15.934],
                                  [-101.015, 126.247],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-117.256, -199.299],
                                  [-304.132, 16.708],
                                  [-99.522, 124.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-116.119, -197.038],
                                  [-301.805, 17.551],
                                  [-97.752, 122.566],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-114.855, -194.406],
                                  [-299.152, 18.538],
                                  [-95.696, 120.328],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-113.377, -191.376],
                                  [-296.015, 19.685],
                                  [-93.322, 117.747],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-111.701, -187.909],
                                  [-292.464, 21.003],
                                  [-90.595, 114.783],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-109.789, -183.901],
                                  [-288.32, 22.533],
                                  [-87.407, 111.461],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-107.639, -179.324],
                                  [-283.55, 24.24],
                                  [-83.747, 107.628],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-105.17, -173.985],
                                  [-277.987, 26.277],
                                  [-79.478, 103.249],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-102.351, -167.863],
                                  [-271.522, 28.559],
                                  [-74.472, 98.251],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-99.112, -160.696],
                                  [-264.006, 31.23],
                                  [-68.609, 92.58],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-95.442, -152.381],
                                  [-255.144, 34.346],
                                  [-61.708, 86.06],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-91.234, -142.681],
                                  [-244.666, 37.952],
                                  [-53.488, 78.617],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-86.475, -131.36],
                                  [-232.367, 42.12],
                                  [-43.793, 70.128],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-81.123, -118.214],
                                  [-217.921, 46.961],
                                  [-32.349, 60.58],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-75.199, -103.152],
                                  [-201.171, 52.487],
                                  [-18.992, 50.035],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-68.858, -86.367],
                                  [-182.221, 58.597],
                                  [-3.773, 38.718],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.383, -68.417],
                                  [-161.633, 65.122],
                                  [12.865, 27.117],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-56.105, -50.086],
                                  [-140.35, 71.668],
                                  [30.179, 15.829],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-50.249, -32.175],
                                  [-119.3, 78.069],
                                  [47.49, 5.377],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-44.974, -15.342],
                                  [-99.19, 84.032],
                                  [64.093, -4.011],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-40.381, 0.251],
                                  [-80.361, 89.564],
                                  [79.697, -12.262],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-36.341, 14.517],
                                  [-62.942, 94.553],
                                  [94.27, -19.505],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-32.843, 27.551],
                                  [-46.916, 99.076],
                                  [107.714, -25.859],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-29.792, 39.411],
                                  [-32.13, 103.202],
                                  [120.121, -31.381],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.089, 50.319],
                                  [-18.505, 106.969],
                                  [131.62, -36.27],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-24.698, 60.299],
                                  [-5.878, 110.421],
                                  [142.272, -40.622],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.592, 69.459],
                                  [5.795, 113.577],
                                  [152.202, -44.516],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-20.694, 77.981],
                                  [16.628, 116.484],
                                  [161.395, -47.961],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.009, 85.797],
                                  [26.76, 119.142],
                                  [170.037, -51.1],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-17.482, 93.121],
                                  [36.183, 121.661],
                                  [178.062, -53.915],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.112, 99.935],
                                  [45.015, 123.949],
                                  [185.594, -56.48],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-14.853, 106.316],
                                  [53.288, 126.085],
                                  [192.712, -58.835],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.708, 112.246],
                                  [61.083, 128.116],
                                  [199.359, -60.956],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.65, 117.834],
                                  [68.388, 129.997],
                                  [205.668, -62.894],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-11.704, 123.047],
                                  [75.29, 131.759],
                                  [211.568, -64.718],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-10.814, 128.011],
                                  [81.795, 133.402],
                                  [217.163, -66.398],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-10.021, 132.631],
                                  [87.93, 134.974],
                                  [222.434, -67.891],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-9.272, 136.985],
                                  [93.703, 136.428],
                                  [227.439, -69.356],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-8.547, 141.09],
                                  [99.191, 137.812],
                                  [232.174, -70.653],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.953, 145.023],
                                  [104.406, 139.127],
                                  [236.656, -71.879],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.326, 148.674],
                                  [109.28, 140.359],
                                  [240.924, -72.989],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.808, 152.139],
                                  [113.922, 141.502],
                                  [244.935, -74.059],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.286, 155.432],
                                  [118.363, 142.611],
                                  [248.762, -75.029],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-5.784, 158.554],
                                  [122.519, 143.618],
                                  [252.356, -75.962],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-5.371, 161.491],
                                  [126.484, 144.605],
                                  [255.785, -76.845],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-4.959, 164.256],
                                  [130.208, 145.525],
                                  [259.026, -77.657],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-4.533, 166.885],
                                  [133.773, 146.391],
                                  [262.127, -78.372],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-4.19, 169.356],
                                  [137.143, 147.253],
                                  [265.012, -79.067],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-3.843, 171.706],
                                  [140.303, 147.985],
                                  [267.804, -79.728],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-3.528, 173.969],
                                  [143.315, 148.776],
                                  [270.441, -80.355],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-3.241, 176.025],
                                  [146.191, 149.437],
                                  [272.922, -80.914],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-2.996, 178.058],
                                  [148.891, 150.108],
                                  [275.247, -81.453],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-2.716, 179.92],
                                  [151.454, 150.713],
                                  [277.477, -81.973],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-2.476, 181.661],
                                  [153.852, 151.3],
                                  [279.55, -82.425],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-2.247, 183.331],
                                  [156.112, 151.848],
                                  [281.515, -82.857],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-2.044, 184.892],
                                  [158.256, 152.367],
                                  [283.372, -83.271],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.828, 186.383],
                                  [160.234, 152.846],
                                  [285.134, -83.666],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.671, 187.753],
                                  [162.122, 153.308],
                                  [286.775, -84.006],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.488, 189.064],
                                  [163.907, 153.718],
                                  [288.32, -84.327],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.314, 190.256],
                                  [165.575, 154.113],
                                  [289.806, -84.642],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.175, 191.377],
                                  [167.139, 154.504],
                                  [291.134, -84.926],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.044, 192.441],
                                  [168.549, 154.843],
                                  [292.403, -85.217],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.933, 193.435],
                                  [169.904, 155.18],
                                  [293.564, -85.439],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.843, 194.372],
                                  [171.155, 155.451],
                                  [294.666, -85.656],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.71, 195.189],
                                  [172.289, 155.769],
                                  [295.673, -85.853],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.596, 195.949],
                                  [173.381, 155.986],
                                  [296.571, -86.045],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.539, 196.69],
                                  [174.306, 156.25],
                                  [297.448, -86.23],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.451, 197.325],
                                  [175.226, 156.449],
                                  [298.18, -86.409],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.382, 197.891],
                                  [176.003, 156.646],
                                  [298.904, -86.532],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.318, 198.4],
                                  [176.764, 156.791],
                                  [299.519, -86.648],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.26, 198.891],
                                  [177.357, 156.984],
                                  [300.077, -86.745],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.182, 199.276],
                                  [177.958, 157.125],
                                  [300.59, -86.848],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.147, 199.655],
                                  [178.455, 157.252],
                                  [301.032, -86.932],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.141, 199.966],
                                  [178.835, 157.326],
                                  [301.368, -87.021],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.078, 200.221],
                                  [179.222, 157.399],
                                  [301.708, -87.103],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.032, 200.409],
                                  [179.493, 157.47],
                                  [301.941, -87.116],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.053, 200.604],
                                  [179.722, 157.526],
                                  [302.155, -87.184],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.029, 200.718],
                                  [179.884, 157.594],
                                  [302.274, -87.194],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.008, 200.79],
                                  [179.942, 157.597],
                                  [302.387, -87.21],
                                ],
                                c: false,
                              },
                            ],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.004, 200.795],
                                  [179.996, 157.598],
                                  [302.393, -87.205],
                                ],
                                c: false,
                              },
                            ],
                            t: 270,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.316, 0.316, 0.316, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.316, 0.316, 0.316, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [199.779, 118.02],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.765, 118.147],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.742, 118.319],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.691, 118.597],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.621, 118.967],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.557, 119.482],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.434, 120.075],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.332, 120.81],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.229, 121.679],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.083, 122.667],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.938, 123.864],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.792, 125.251],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.674, 126.833],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.526, 128.699],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.481, 130.799],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.426, 133.207],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.547, 135.982],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.773, 139.175],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.216, 142.812],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.001, 146.978],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.353, 151.669],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.448, 156.895],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.575, 162.515],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.326, 165.727],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.5, 167.071],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [212.688, 168.303],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [218.271, 169.471],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [226.216, 170.759],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [235.977, 172.167],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [242.483, 172.399],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [241.89, 170.885],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [240.307, 170.051],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [238.195, 169.709],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [235.808, 169.73],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [233.34, 170.024],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [230.888, 170.493],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [228.522, 171.065],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [226.184, 171.629],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [223.986, 172.226],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.856, 172.783],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [219.863, 173.299],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [217.904, 173.774],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.06, 174.124],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.269, 174.408],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [212.561, 174.623],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.908, 174.736],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.303, 174.71],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.77, 174.635],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.275, 174.407],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.836, 174.108],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.436, 173.703],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.055, 173.225],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.773, 172.673],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.526, 172.071],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.338, 171.46],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.199, 170.805],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.124, 170.127],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.084, 169.448],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.131, 168.817],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.247, 168.198],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.376, 167.598],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.592, 167.025],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.872, 166.482],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.162, 165.95],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.528, 165.433],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.917, 164.968],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.339, 164.513],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.815, 164.059],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.304, 163.621],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.858, 163.227],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.404, 162.833],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186, 162.462],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.622, 162.073],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.275, 161.71],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.949, 161.35],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.645, 161.014],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.361, 160.679],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.094, 160.361],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.849, 160.022],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.621, 159.692],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.431, 159.361],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.249, 159.05],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.078, 158.746],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.95, 158.447],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.783, 158.159],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.66, 157.835],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.553, 157.564],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.465, 157.247],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.38, 156.956],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.319, 156.658],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.184, 156.339],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.832, 156.028],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.326, 155.637],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.61, 155.248],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.694, 154.808],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.521, 154.315],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.059, 153.8],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.348, 153.198],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.318, 152.546],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.914, 151.845],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.17, 151.056],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.037, 150.181],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.389, 149.228],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.245, 148.159],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.516, 146.977],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.082, 145.66],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.938, 144.168],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.949, 142.5],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.913, 140.606],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.77, 138.449],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.357, 135.962],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.525, 133.102],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.23, 129.771],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.506, 125.833],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.671, 121.13],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.548, 115.398],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.018, 108.294],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.604, 99.473],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.982, 88.695],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.462, 75.684],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.049, 59.258],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.091, 45.769],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.856, 35.274],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.224, 27.352],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.627, 21.461],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.299, 17.094],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.295, 13.781],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.736, 11.227],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.56, 9.223],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.826, 7.549],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.565, 6.177],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.828, 5.002],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.567, 3.968],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.868, 3.056],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.805, 2.256],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.305, 1.527],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.436, 0.857],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.286, 0.258],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.748, -0.304],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.928, -0.833],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-226.843, -1.304],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-231.455, -1.751],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-235.864, -2.167],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.022, -2.543],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-243.95, -2.915],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-247.684, -3.251],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.152, -3.577],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.462, -3.885],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-257.65, -4.149],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-260.566, -4.423],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-263.389, -4.668],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-266.039, -4.908],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-268.551, -5.118],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-270.913, -5.317],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-273.095, -5.51],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-275.21, -5.696],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-277.147, -5.872],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.977, -6.023],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-280.752, -6.156],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-282.325, -6.307],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-283.831, -6.427],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-285.241, -6.546],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-286.524, -6.662],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-287.749, -6.762],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-288.859, -6.857],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-289.866, -6.954],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-290.797, -7.037],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-291.667, -7.104],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-292.42, -7.163],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-293.145, -7.213],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-293.712, -7.271],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.283, -7.318],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.756, -7.36],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.157, -7.382],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.522, -7.406],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.763, -7.429],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.989, -7.456],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-296.087, -7.463],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-296.237, -7.471],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-296.191, -7.453],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.974, -7.384],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.653, -7.253],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.174, -7.089],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.549, -6.856],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-293.716, -6.564],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-292.68, -6.217],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-291.484, -5.748],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-290.058, -5.235],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-288.338, -4.599],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-286.363, -3.83],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-284.048, -2.97],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-281.443, -1.923],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.367, -0.71],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-274.908, 0.713],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-270.919, 2.413],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-266.379, 4.37],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-261.132, 6.745],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.138, 9.535],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-248.294, 12.942],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.372, 17.074],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-231.219, 22.121],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.775, 28.336],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.872, 36.035],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.504, 45.485],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-180.851, 56.844],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.67, 63.823],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.44, 66.786],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.536, 67.713],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.368, 66.874],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-41.616, 64.915],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.473, 62.29],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.047, 59.516],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.082, 57.049],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.829, 55.127],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.452, 54.011],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.174, 53.778],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.294, 54.388],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.167, 55.684],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.019, 57.513],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.122, 59.639],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.642, 61.954],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.652, 64.44],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.269, 66.935],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.513, 69.443],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.46, 71.905],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.109, 74.374],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.512, 76.712],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.696, 79.02],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.654, 81.293],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.405, 83.434],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.954, 85.507],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [151.358, 87.542],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.564, 89.423],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.617, 91.284],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.507, 93.03],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.28, 94.721],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.89, 96.373],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.368, 97.862],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.734, 99.356],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.972, 100.759],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.081, 102.112],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.098, 103.364],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.983, 104.571],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.768, 105.702],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.459, 106.783],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.039, 107.768],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.528, 108.748],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.94, 109.647],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.281, 110.475],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.515, 111.296],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.649, 112.008],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.718, 112.733],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.706, 113.381],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.631, 113.975],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.489, 114.516],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.246, 115.016],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.965, 115.486],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.597, 115.899],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.186, 116.291],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.677, 116.627],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.164, 116.927],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.562, 117.197],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.86, 117.393],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.177, 117.565],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.396, 117.714],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.577, 117.842],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.769, 117.957],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [199.779, 118.02],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.765, 118.147],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.742, 118.319],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.691, 118.597],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.621, 118.967],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.557, 119.482],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.434, 120.075],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.332, 120.81],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.229, 121.679],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.083, 122.667],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.938, 123.864],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.792, 125.251],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.674, 126.833],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.526, 128.699],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.481, 130.799],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.426, 133.207],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.547, 135.982],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.773, 139.175],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.216, 142.812],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.001, 146.978],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.353, 151.669],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.448, 156.895],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.575, 162.515],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.326, 165.727],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.5, 167.071],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [212.688, 168.303],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [218.271, 169.471],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [226.216, 170.759],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [235.977, 172.167],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [242.483, 172.399],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [241.89, 170.885],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [240.307, 170.051],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [238.195, 169.709],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [235.808, 169.73],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [233.34, 170.024],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [230.888, 170.493],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [228.522, 171.065],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [226.184, 171.629],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [223.986, 172.226],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.856, 172.783],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [219.863, 173.299],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [217.904, 173.774],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.06, 174.124],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.269, 174.408],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [212.561, 174.623],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.908, 174.736],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.303, 174.71],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.77, 174.635],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.275, 174.407],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.836, 174.108],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.436, 173.703],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.055, 173.225],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.773, 172.673],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.526, 172.071],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.338, 171.46],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.199, 170.805],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.124, 170.127],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.084, 169.448],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.131, 168.817],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.247, 168.198],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.376, 167.598],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.592, 167.025],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.872, 166.482],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.162, 165.95],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.528, 165.433],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.917, 164.968],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.339, 164.513],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.815, 164.059],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.304, 163.621],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.858, 163.227],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.404, 162.833],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186, 162.462],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.622, 162.073],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.275, 161.71],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.949, 161.35],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.645, 161.014],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.361, 160.679],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.094, 160.361],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.849, 160.022],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.621, 159.692],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.431, 159.361],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.249, 159.05],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.078, 158.746],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.95, 158.447],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.783, 158.159],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.66, 157.835],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.553, 157.564],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.465, 157.247],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.38, 156.956],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.319, 156.658],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.184, 156.339],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.832, 156.028],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.326, 155.637],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.61, 155.248],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.694, 154.808],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.521, 154.315],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.059, 153.8],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.348, 153.198],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.318, 152.546],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.914, 151.845],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.17, 151.056],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.037, 150.181],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [161.389, 149.228],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.245, 148.159],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.516, 146.977],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.082, 145.66],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.938, 144.168],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.949, 142.5],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.913, 140.606],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.77, 138.449],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [106.357, 135.962],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [94.525, 133.102],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.23, 129.771],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [66.506, 125.833],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.671, 121.13],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.548, 115.398],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.018, 108.294],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.604, 99.473],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-8.982, 88.695],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-23.462, 75.684],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.049, 59.258],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-61.091, 45.769],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.856, 35.274],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-89.224, 27.352],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-101.627, 21.461],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-113.299, 17.094],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-124.295, 13.781],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-134.736, 11.227],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.56, 9.223],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-153.826, 7.549],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-162.565, 6.177],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.828, 5.002],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-178.567, 3.968],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-185.868, 3.056],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-192.805, 2.256],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-199.305, 1.527],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.436, 0.857],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-211.286, 0.258],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-216.748, -0.304],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-221.928, -0.833],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-226.843, -1.304],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-231.455, -1.751],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-235.864, -2.167],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.022, -2.543],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-243.95, -2.915],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-247.684, -3.251],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-251.152, -3.577],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.462, -3.885],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-257.65, -4.149],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-260.566, -4.423],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-263.389, -4.668],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-266.039, -4.908],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-268.551, -5.118],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-270.913, -5.317],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-273.095, -5.51],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-275.21, -5.696],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-277.147, -5.872],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.977, -6.023],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-280.752, -6.156],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-282.325, -6.307],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-283.831, -6.427],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-285.241, -6.546],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-286.524, -6.662],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-287.749, -6.762],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-288.859, -6.857],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-289.866, -6.954],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-290.797, -7.037],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-291.667, -7.104],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-292.42, -7.163],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-293.145, -7.213],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-293.712, -7.271],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.283, -7.318],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.756, -7.36],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.157, -7.382],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.522, -7.406],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.763, -7.429],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.989, -7.456],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-296.087, -7.463],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-296.237, -7.471],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-296.191, -7.453],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.974, -7.384],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.653, -7.253],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.174, -7.089],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.549, -6.856],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-293.716, -6.564],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-292.68, -6.217],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-291.484, -5.748],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-290.058, -5.235],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-288.338, -4.599],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-286.363, -3.83],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-284.048, -2.97],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-281.443, -1.923],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-278.367, -0.71],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-274.908, 0.713],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-270.919, 2.413],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-266.379, 4.37],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-261.132, 6.745],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.138, 9.535],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-248.294, 12.942],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-240.372, 17.074],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-231.219, 22.121],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.775, 28.336],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-208.872, 36.035],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.504, 45.485],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-180.851, 56.844],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-155.67, 63.823],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.44, 66.786],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-95.536, 67.713],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-67.368, 66.874],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-41.616, 64.915],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.473, 62.29],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.047, 59.516],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.082, 57.049],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.829, 55.127],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.452, 54.011],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [61.174, 53.778],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.294, 54.388],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [80.167, 55.684],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.019, 57.513],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [95.122, 59.639],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.642, 61.954],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.652, 64.44],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.269, 66.935],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [118.513, 69.443],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [123.46, 71.905],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [128.109, 74.374],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.512, 76.712],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [136.696, 79.02],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [140.654, 81.293],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.405, 83.434],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.954, 85.507],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [151.358, 87.542],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.564, 89.423],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [157.617, 91.284],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.507, 93.03],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [163.28, 94.721],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.89, 96.373],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.368, 97.862],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [170.734, 99.356],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.972, 100.759],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [175.081, 102.112],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.098, 103.364],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [178.983, 104.571],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.768, 105.702],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.459, 106.783],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.039, 107.768],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.528, 108.748],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.94, 109.647],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.281, 110.475],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.515, 111.296],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.649, 112.008],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.718, 112.733],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.706, 113.381],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.631, 113.975],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.489, 114.516],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.246, 115.016],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.965, 115.486],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.597, 115.899],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.186, 116.291],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.677, 116.627],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.164, 116.927],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.562, 117.197],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.86, 117.393],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.177, 117.565],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.396, 117.714],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.577, 117.842],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.769, 117.957],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 13',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 13,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.995, 215.194],
                                  [191.995, 162.398],
                                  [321.538, -72.743],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [12.054, 215.098],
                                  [192.062, 162.442],
                                  [321.298, -72.569],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [12.172, 214.998],
                                  [192.18, 162.477],
                                  [320.892, -72.327],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [12.35, 214.849],
                                  [192.358, 162.511],
                                  [320.247, -71.909],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [12.525, 214.651],
                                  [192.55, 162.589],
                                  [319.49, -71.37],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [12.822, 214.403],
                                  [192.855, 162.712],
                                  [318.44, -70.647],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.115, 214.106],
                                  [193.166, 162.825],
                                  [317.161, -69.803],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.531, 213.714],
                                  [193.589, 162.982],
                                  [315.643, -68.73],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.005, 213.272],
                                  [194.089, 163.192],
                                  [313.833, -67.528],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.538, 212.735],
                                  [194.639, 163.383],
                                  [311.738, -66.08],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [15.193, 212.102],
                                  [195.319, 163.61],
                                  [309.243, -64.34],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [15.906, 211.42],
                                  [196.074, 163.934],
                                  [306.392, -62.41],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [16.802, 210.597],
                                  [196.996, 164.248],
                                  [303.086, -60.118],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [17.757, 209.678],
                                  [198.002, 164.642],
                                  [299.263, -57.474],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [18.957, 208.62],
                                  [199.236, 165.07],
                                  [294.924, -54.451],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [20.215, 207.375],
                                  [200.563, 165.533],
                                  [289.95, -51.006],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [21.781, 205.991],
                                  [202.18, 166.136],
                                  [284.236, -47.075],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.529, 204.376],
                                  [203.997, 166.801],
                                  [277.656, -42.48],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.521, 202.577],
                                  [206.077, 167.544],
                                  [270.112, -37.205],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [27.821, 200.459],
                                  [208.481, 168.409],
                                  [261.426, -31.087],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [30.552, 198.022],
                                  [211.325, 169.431],
                                  [251.321, -24.049],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [33.714, 195.224],
                                  [214.611, 170.61],
                                  [239.682, -15.85],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [37.306, 192.021],
                                  [218.363, 171.989],
                                  [226.225, -6.364],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [41.517, 188.324],
                                  [222.752, 173.557],
                                  [210.675, 4.639],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [46.283, 184.139],
                                  [227.724, 175.312],
                                  [193.134, 17.061],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [51.603, 179.557],
                                  [233.262, 177.26],
                                  [173.803, 30.756],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [57.165, 174.717],
                                  [239.07, 179.308],
                                  [153.391, 45.234],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [62.718, 169.89],
                                  [244.872, 181.4],
                                  [132.832, 59.769],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [68.136, 165.214],
                                  [250.525, 183.327],
                                  [113.02, 73.79],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [73.107, 160.91],
                                  [255.726, 185.117],
                                  [94.581, 86.821],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [77.629, 156.935],
                                  [260.463, 186.671],
                                  [77.745, 98.711],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [81.702, 153.375],
                                  [264.737, 188.104],
                                  [62.504, 109.432],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [85.387, 150.186],
                                  [268.609, 189.344],
                                  [48.738, 119.08],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [88.684, 147.277],
                                  [272.079, 190.401],
                                  [36.233, 127.727],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [91.592, 144.648],
                                  [275.153, 191.309],
                                  [24.925, 135.611],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [94.233, 142.255],
                                  [277.958, 192.12],
                                  [14.591, 142.678],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [96.671, 140.095],
                                  [280.534, 192.823],
                                  [5.176, 149.158],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [98.778, 138.126],
                                  [282.793, 193.365],
                                  [-3.507, 155.005],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [100.739, 136.345],
                                  [284.894, 193.85],
                                  [-11.442, 160.333],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [102.492, 134.668],
                                  [286.79, 194.214],
                                  [-18.859, 165.255],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [104.097, 133.18],
                                  [288.535, 194.507],
                                  [-25.644, 169.716],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [105.489, 131.794],
                                  [290.063, 194.737],
                                  [-31.974, 173.786],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [106.792, 130.512],
                                  [291.508, 194.82],
                                  [-37.893, 177.547],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [107.941, 129.333],
                                  [292.795, 194.836],
                                  [-43.411, 180.974],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [108.933, 128.259],
                                  [293.934, 194.813],
                                  [-48.528, 184.054],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [109.83, 127.288],
                                  [294.974, 194.699],
                                  [-53.348, 186.89],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [110.568, 126.381],
                                  [295.863, 194.438],
                                  [-57.82, 189.5],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [111.271, 125.578],
                                  [296.714, 194.185],
                                  [-62.047, 191.82],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [111.815, 124.839],
                                  [297.414, 193.785],
                                  [-65.988, 193.904],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.263, 124.163],
                                  [298.025, 193.345],
                                  [-69.683, 195.804],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.619, 123.55],
                                  [298.553, 192.828],
                                  [-73.155, 197.433],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.888, 123.037],
                                  [298.988, 192.257],
                                  [-76.433, 198.953],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.077, 122.545],
                                  [299.36, 191.627],
                                  [-79.467, 200.284],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.256, 122.146],
                                  [299.702, 190.975],
                                  [-82.331, 201.462],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.31, 121.763],
                                  [299.945, 190.326],
                                  [-85.016, 202.563],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.372, 121.428],
                                  [300.171, 189.64],
                                  [-87.523, 203.509],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.448, 121.14],
                                  [300.411, 188.916],
                                  [-89.905, 204.413],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.42, 120.857],
                                  [300.544, 188.211],
                                  [-92.114, 205.205],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.416, 120.65],
                                  [300.711, 187.56],
                                  [-94.214, 205.93],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.439, 120.41],
                                  [300.874, 186.924],
                                  [-96.131, 206.616],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.427, 120.239],
                                  [300.998, 186.298],
                                  [-97.992, 207.228],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.381, 120.068],
                                  [301.098, 185.692],
                                  [-99.687, 207.865],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.362, 119.93],
                                  [301.22, 185.132],
                                  [-101.281, 208.413],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.369, 119.789],
                                  [301.336, 184.57],
                                  [-102.809, 208.901],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.339, 119.679],
                                  [301.44, 184.024],
                                  [-104.222, 209.394],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.333, 119.566],
                                  [301.534, 183.537],
                                  [-105.538, 209.824],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.286, 119.483],
                                  [301.598, 183.062],
                                  [-106.788, 210.254],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.261, 119.396],
                                  [301.68, 182.58],
                                  [-107.942, 210.618],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.192, 119.339],
                                  [301.716, 182.123],
                                  [-109.033, 210.946],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.204, 119.278],
                                  [301.814, 181.704],
                                  [-110.027, 211.284],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.108, 119.214],
                                  [301.816, 181.307],
                                  [-110.975, 211.535],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.09, 119.178],
                                  [301.879, 180.93],
                                  [-111.859, 211.793],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.024, 119.14],
                                  [301.891, 180.525],
                                  [-112.634, 212.024],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.972, 119.129],
                                  [301.914, 180.153],
                                  [-113.362, 212.211],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.87, 119.084],
                                  [301.901, 179.798],
                                  [-114.044, 212.354],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.843, 119.098],
                                  [301.929, 179.459],
                                  [-114.663, 212.514],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.765, 119.078],
                                  [301.919, 179.134],
                                  [-115.237, 212.626],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.635, 119.085],
                                  [301.856, 178.84],
                                  [-115.718, 212.691],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.577, 119.09],
                                  [301.847, 178.512],
                                  [-116.184, 212.723],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.467, 119.122],
                                  [301.799, 178.198],
                                  [-116.604, 212.752],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.365, 119.122],
                                  [301.759, 177.895],
                                  [-116.963, 212.731],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.271, 119.149],
                                  [301.709, 177.603],
                                  [-117.246, 212.738],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.124, 119.173],
                                  [301.62, 177.339],
                                  [-117.514, 212.677],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.046, 119.224],
                                  [301.583, 177.085],
                                  [-117.721, 212.581],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [111.913, 119.273],
                                  [301.488, 176.84],
                                  [-117.945, 212.494],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [111.724, 119.319],
                                  [301.354, 176.559],
                                  [-118.062, 212.355],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [111.604, 119.362],
                                  [301.271, 176.35],
                                  [-118.179, 212.208],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [111.49, 119.404],
                                  [301.192, 176.086],
                                  [-118.252, 212.023],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [111.32, 119.472],
                                  [301.056, 175.847],
                                  [-118.264, 211.847],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [111.155, 119.538],
                                  [300.924, 175.617],
                                  [-118.231, 211.617],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [110.978, 119.539],
                                  [300.688, 175.348],
                                  [-118.182, 211.365],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [110.727, 119.414],
                                  [300.184, 175.086],
                                  [-118.225, 211.092],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [110.43, 119.206],
                                  [299.452, 174.696],
                                  [-118.217, 210.799],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [110.102, 118.809],
                                  [298.453, 174.312],
                                  [-118.255, 210.428],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [109.663, 118.286],
                                  [297.152, 173.845],
                                  [-118.304, 210.036],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [109.149, 117.573],
                                  [295.505, 173.293],
                                  [-118.363, 209.596],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [108.586, 116.716],
                                  [293.485, 172.703],
                                  [-118.451, 209.107],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [107.893, 115.624],
                                  [291.102, 171.984],
                                  [-118.575, 208.543],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [107.106, 114.371],
                                  [288.285, 171.181],
                                  [-118.71, 207.931],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [106.251, 112.867],
                                  [284.988, 170.294],
                                  [-118.898, 207.271],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [105.248, 111.065],
                                  [281.195, 169.279],
                                  [-119.123, 206.509],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [104.068, 109.022],
                                  [276.817, 168.134],
                                  [-119.338, 205.645],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [102.784, 106.62],
                                  [271.764, 166.861],
                                  [-119.634, 204.708],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [101.288, 103.842],
                                  [265.976, 165.415],
                                  [-119.947, 203.642],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [99.606, 100.669],
                                  [259.362, 163.796],
                                  [-120.321, 202.449],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [97.72, 97.022],
                                  [251.772, 161.958],
                                  [-120.783, 201.13],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [95.522, 92.855],
                                  [243.1, 159.858],
                                  [-121.27, 199.63],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [93.003, 88],
                                  [233.168, 157.497],
                                  [-121.871, 197.924],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [90.162, 82.42],
                                  [221.673, 154.784],
                                  [-122.504, 195.987],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [86.881, 75.963],
                                  [208.465, 151.676],
                                  [-123.285, 193.791],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [83.08, 68.496],
                                  [193.199, 148.084],
                                  [-124.177, 191.259],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [78.686, 59.822],
                                  [175.5, 143.963],
                                  [-125.205, 188.365],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [73.582, 49.78],
                                  [155.024, 139.226],
                                  [-126.377, 185.058],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [67.747, 38.155],
                                  [131.47, 133.785],
                                  [-127.744, 181.259],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [61.163, 25.038],
                                  [104.752, 127.639],
                                  [-129.306, 176.972],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [53.881, 10.552],
                                  [75.354, 120.879],
                                  [-131.006, 172.278],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [46.17, -4.784],
                                  [44.303, 113.729],
                                  [-132.8, 167.337],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [38.37, -20.185],
                                  [13.035, 106.544],
                                  [-134.565, 162.363],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [30.894, -35.052],
                                  [-17.036, 99.636],
                                  [-136.294, 157.619],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.887, -48.903],
                                  [-45.067, 93.225],
                                  [-137.857, 153.209],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [17.512, -61.594],
                                  [-70.642, 87.356],
                                  [-139.317, 149.182],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.682, -73.062],
                                  [-93.809, 82.026],
                                  [-140.587, 145.537],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.388, -83.416],
                                  [-114.705, 77.235],
                                  [-141.714, 142.271],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [1.613, -92.77],
                                  [-133.634, 72.894],
                                  [-142.752, 139.328],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-2.741, -101.321],
                                  [-150.795, 68.956],
                                  [-143.683, 136.657],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-6.734, -109.061],
                                  [-166.403, 65.333],
                                  [-144.516, 134.227],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-10.422, -116.203],
                                  [-180.662, 62.069],
                                  [-145.272, 132.013],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-13.776, -122.72],
                                  [-193.807, 59.029],
                                  [-145.939, 129.986],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-16.904, -128.702],
                                  [-205.864, 56.258],
                                  [-146.537, 128.146],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.797, -134.292],
                                  [-217.004, 53.667],
                                  [-147.119, 126.44],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-22.498, -139.444],
                                  [-227.343, 51.3],
                                  [-147.615, 124.841],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-25.027, -144.222],
                                  [-236.961, 49.068],
                                  [-148.077, 123.375],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.356, -148.715],
                                  [-245.885, 47.015],
                                  [-148.524, 122.015],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-29.529, -152.895],
                                  [-254.223, 45.097],
                                  [-148.937, 120.76],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-31.61, -156.782],
                                  [-262.038, 43.269],
                                  [-149.3, 119.584],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-33.571, -160.445],
                                  [-269.311, 41.575],
                                  [-149.629, 118.462],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35.35, -163.902],
                                  [-276.176, 40.015],
                                  [-149.971, 117.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-37.117, -167.129],
                                  [-282.626, 38.5],
                                  [-150.243, 116.478],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-38.701, -170.168],
                                  [-288.668, 37.119],
                                  [-150.528, 115.564],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-40.247, -173.046],
                                  [-294.339, 35.784],
                                  [-150.788, 114.702],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-41.673, -175.711],
                                  [-299.71, 34.538],
                                  [-151.041, 113.918],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-43.041, -178.278],
                                  [-304.736, 33.381],
                                  [-151.245, 113.16],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-44.326, -180.657],
                                  [-309.524, 32.269],
                                  [-151.469, 112.453],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-45.554, -182.911],
                                  [-313.987, 31.202],
                                  [-151.624, 111.797],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-46.724, -185.085],
                                  [-318.239, 30.223],
                                  [-151.818, 111.166],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-47.829, -187.09],
                                  [-322.253, 29.29],
                                  [-151.988, 110.586],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.832, -189.015],
                                  [-326.011, 28.446],
                                  [-152.152, 110.032],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-49.796, -190.814],
                                  [-329.551, 27.602],
                                  [-152.318, 109.503],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-50.721, -192.49],
                                  [-332.942, 26.803],
                                  [-152.416, 108.998],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-51.625, -194.102],
                                  [-336.078, 26.092],
                                  [-152.596, 108.544],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-52.427, -195.59],
                                  [-339.084, 25.382],
                                  [-152.691, 108.115],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-53.227, -197.06],
                                  [-341.898, 24.716],
                                  [-152.805, 107.686],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-53.969, -198.405],
                                  [-344.562, 24.095],
                                  [-152.896, 107.306],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-54.646, -199.644],
                                  [-347.079, 23.519],
                                  [-153.007, 106.951],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-55.302, -200.846],
                                  [-349.402, 22.986],
                                  [-153.094, 106.595],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-55.919, -201.96],
                                  [-351.64, 22.454],
                                  [-153.183, 106.29],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-56.515, -203.038],
                                  [-353.686, 21.967],
                                  [-153.248, 105.983],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-57.046, -204.009],
                                  [-355.627, 21.523],
                                  [-153.334, 105.701],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-57.557, -204.917],
                                  [-357.483, 21.08],
                                  [-153.377, 105.443],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.028, -205.808],
                                  [-359.146, 20.681],
                                  [-153.44, 105.185],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.478, -206.592],
                                  [-360.749, 20.327],
                                  [-153.524, 104.976],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.926, -207.358],
                                  [-362.222, 19.973],
                                  [-153.566, 104.741],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.291, -208.062],
                                  [-363.59, 19.662],
                                  [-153.628, 104.556],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.634, -208.703],
                                  [-364.873, 19.352],
                                  [-153.647, 104.369],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.976, -209.282],
                                  [-366.052, 19.087],
                                  [-153.731, 104.207],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.279, -209.861],
                                  [-367.101, 18.821],
                                  [-153.729, 104.044],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.579, -210.377],
                                  [-368.09, 18.6],
                                  [-153.791, 103.906],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.84, -210.832],
                                  [-368.992, 18.378],
                                  [-153.81, 103.791],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.055, -211.224],
                                  [-369.791, 18.201],
                                  [-153.85, 103.676],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.249, -211.597],
                                  [-370.547, 18.024],
                                  [-153.848, 103.56],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.442, -211.927],
                                  [-371.155, 17.891],
                                  [-153.909, 103.467],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.595, -212.238],
                                  [-371.766, 17.758],
                                  [-153.928, 103.4],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.746, -212.487],
                                  [-372.246, 17.626],
                                  [-153.949, 103.331],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.876, -212.693],
                                  [-372.666, 17.537],
                                  [-153.946, 103.261],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.986, -212.879],
                                  [-373.044, 17.448],
                                  [-153.945, 103.216],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.05, -213.022],
                                  [-373.318, 17.404],
                                  [-153.964, 103.17],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.138, -213.147],
                                  [-373.524, 17.316],
                                  [-153.965, 103.147],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.18, -213.209],
                                  [-373.651, 17.315],
                                  [-154.006, 103.124],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.201, -213.271],
                                  [-373.755, 17.271],
                                  [-153.985, 103.101],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.17, -213.21],
                                  [-373.723, 17.272],
                                  [-153.907, 103.076],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-62.136, -213.018],
                                  [-373.482, 17.382],
                                  [-153.738, 102.919],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.992, -212.642],
                                  [-373.069, 17.512],
                                  [-153.398, 102.691],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.822, -212.136],
                                  [-372.475, 17.688],
                                  [-152.926, 102.391],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.644, -211.463],
                                  [-371.671, 17.929],
                                  [-152.279, 101.957],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.372, -210.579],
                                  [-370.62, 18.235],
                                  [-151.441, 101.368],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-61.023, -209.548],
                                  [-369.327, 18.607],
                                  [-150.414, 100.685],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.62, -208.199],
                                  [-367.812, 19.089],
                                  [-149.175, 99.845],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-60.131, -206.684],
                                  [-365.99, 19.619],
                                  [-147.706, 98.846],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-59.58, -204.869],
                                  [-363.803, 20.277],
                                  [-145.985, 97.713],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.942, -202.736],
                                  [-361.298, 21.066],
                                  [-143.97, 96.381],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-58.168, -200.285],
                                  [-358.347, 21.922],
                                  [-141.601, 94.788],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-57.314, -197.425],
                                  [-354.971, 22.935],
                                  [-138.854, 93.019],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-56.318, -194.138],
                                  [-351.007, 24.098],
                                  [-135.687, 90.968],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-55.19, -190.378],
                                  [-346.521, 25.439],
                                  [-132.059, 88.621],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-53.902, -186.034],
                                  [-341.297, 27.003],
                                  [-127.838, 85.999],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-52.47, -181.087],
                                  [-335.294, 28.748],
                                  [-122.999, 82.955],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-50.818, -175.307],
                                  [-328.32, 30.82],
                                  [-117.389, 79.484],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-48.941, -168.699],
                                  [-320.245, 33.14],
                                  [-110.845, 75.534],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-46.787, -160.966],
                                  [-310.888, 35.864],
                                  [-103.227, 71.048],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-44.368, -152.015],
                                  [-299.912, 39.034],
                                  [-94.312, 65.886],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-41.586, -141.586],
                                  [-287.014, 42.699],
                                  [-83.791, 59.998],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-38.473, -129.437],
                                  [-271.955, 46.93],
                                  [-71.474, 53.28],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-35, -115.366],
                                  [-254.386, 51.842],
                                  [-57.069, 45.729],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-31.188, -99.287],
                                  [-234.168, 57.442],
                                  [-40.433, 37.395],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-27.138, -81.424],
                                  [-211.49, 63.625],
                                  [-21.699, 28.446],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-23.046, -62.393],
                                  [-187.065, 70.213],
                                  [-1.465, 19.267],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-19.138, -43.025],
                                  [-162.049, 76.818],
                                  [19.345, 10.32],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-15.526, -24.164],
                                  [-137.522, 83.265],
                                  [39.897, 2.045],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-12.301, -6.502],
                                  [-114.284, 89.252],
                                  [59.391, -5.406],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-9.551, 9.807],
                                  [-92.697, 94.804],
                                  [77.536, -11.95],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-7.142, 24.681],
                                  [-72.858, 99.805],
                                  [94.322, -17.721],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-5.098, 38.24],
                                  [-54.728, 104.327],
                                  [109.691, -22.789],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-3.347, 50.543],
                                  [-38.091, 108.456],
                                  [123.778, -27.196],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-1.798, 61.84],
                                  [-22.842, 112.22],
                                  [136.745, -31.101],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [-0.44, 72.145],
                                  [-8.773, 115.667],
                                  [148.688, -34.593],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [0.728, 81.592],
                                  [4.179, 118.812],
                                  [159.77, -37.729],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [1.78, 90.364],
                                  [16.151, 121.706],
                                  [169.977, -40.496],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [2.698, 98.395],
                                  [27.314, 124.349],
                                  [179.534, -43.031],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [3.518, 105.91],
                                  [37.66, 126.856],
                                  [188.376, -45.304],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [4.245, 112.897],
                                  [47.33, 129.126],
                                  [196.644, -47.386],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [4.909, 119.427],
                                  [56.364, 131.251],
                                  [204.433, -49.305],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [5.51, 125.49],
                                  [64.854, 133.264],
                                  [211.683, -51.023],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.067, 131.199],
                                  [72.795, 135.133],
                                  [218.543, -52.597],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.544, 136.512],
                                  [80.282, 136.882],
                                  [224.945, -54.093],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [6.999, 141.574],
                                  [87.32, 138.508],
                                  [231, -55.473],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.385, 146.281],
                                  [93.948, 140.067],
                                  [236.695, -56.685],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.758, 150.706],
                                  [100.17, 141.504],
                                  [242.088, -57.899],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.133, 154.88],
                                  [106.08, 142.873],
                                  [247.177, -58.958],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.397, 158.877],
                                  [111.683, 144.174],
                                  [251.993, -59.971],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.721, 162.58],
                                  [116.914, 145.399],
                                  [256.563, -60.884],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.949, 166.096],
                                  [121.888, 146.523],
                                  [260.857, -61.772],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.198, 169.435],
                                  [126.645, 147.624],
                                  [264.946, -62.572],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.453, 172.596],
                                  [131.088, 148.614],
                                  [268.775, -63.347],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.626, 175.57],
                                  [135.32, 149.591],
                                  [272.431, -64.09],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.817, 178.367],
                                  [139.288, 150.501],
                                  [275.879, -64.765],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.037, 181.03],
                                  [143.088, 151.355],
                                  [279.17, -65.354],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.179, 183.525],
                                  [146.675, 152.206],
                                  [282.233, -65.931],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.343, 185.897],
                                  [150.038, 152.928],
                                  [285.193, -66.485],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.486, 188.189],
                                  [153.241, 153.71],
                                  [287.986, -67.017],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.607, 190.26],
                                  [156.29, 154.364],
                                  [290.612, -67.483],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.7, 192.314],
                                  [159.159, 155.023],
                                  [293.071, -67.936],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.836, 194.192],
                                  [161.874, 155.618],
                                  [295.425, -68.377],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.946, 195.946],
                                  [164.417, 156.201],
                                  [297.611, -68.752],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.046, 197.632],
                                  [166.805, 156.736],
                                  [299.683, -69.114],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.128, 199.203],
                                  [169.074, 157.252],
                                  [301.641, -69.465],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.24, 200.705],
                                  [171.17, 157.72],
                                  [303.493, -69.803],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.29, 202.085],
                                  [173.163, 158.177],
                                  [305.222, -70.085],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.378, 203.405],
                                  [175.046, 158.578],
                                  [306.845, -70.354],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.459, 204.603],
                                  [176.809, 158.969],
                                  [308.408, -70.619],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.517, 205.732],
                                  [178.461, 159.357],
                                  [309.802, -70.864],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.569, 206.802],
                                  [179.948, 159.69],
                                  [311.135, -71.115],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.607, 207.804],
                                  [181.377, 160.02],
                                  [312.354, -71.299],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.63, 208.747],
                                  [182.694, 160.286],
                                  [313.513, -71.479],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.703, 209.568],
                                  [183.892, 160.604],
                                  [314.566, -71.648],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.762, 210.329],
                                  [185.04, 160.813],
                                  [315.504, -71.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.762, 211.078],
                                  [186.014, 161.074],
                                  [316.428, -71.973],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.804, 211.713],
                                  [186.985, 161.271],
                                  [317.192, -72.13],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.832, 212.282],
                                  [187.798, 161.466],
                                  [317.95, -72.229],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.857, 212.791],
                                  [188.599, 161.605],
                                  [318.594, -72.324],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.877, 213.288],
                                  [189.226, 161.797],
                                  [319.178, -72.407],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.932, 213.672],
                                  [189.859, 161.934],
                                  [319.711, -72.494],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.936, 214.053],
                                  [190.379, 162.06],
                                  [320.176, -72.568],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.921, 214.366],
                                  [190.78, 162.132],
                                  [320.527, -72.646],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.964, 214.621],
                                  [191.185, 162.201],
                                  [320.881, -72.72],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.996, 214.81],
                                  [191.471, 162.27],
                                  [321.122, -72.727],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.962, 215.004],
                                  [191.708, 162.329],
                                  [321.349, -72.792],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.979, 215.122],
                                  [191.879, 162.395],
                                  [321.472, -72.798],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.993, 215.192],
                                  [191.939, 162.397],
                                  [321.589, -72.808],
                                ],
                                c: false,
                              },
                            ],
                            t: 269,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.283, 0.283, 0.283, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.283, 0.283, 0.283, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [211.505, 126.984],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.49, 127.114],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.466, 127.295],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.411, 127.587],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.334, 127.969],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.264, 128.505],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.13, 129.122],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.02, 129.891],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.904, 130.792],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.746, 131.821],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.581, 133.072],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.413, 134.514],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.273, 136.17],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.095, 138.117],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.022, 140.326],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.933, 142.833],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.028, 145.744],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.224, 149.111],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.643, 152.945],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.419, 157.337],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [212.796, 162.318],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.982, 167.854],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.564, 172.188],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.53, 174.064],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [217.903, 175.79],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.49, 177.409],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [227.652, 179.02],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [236.316, 180.859],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [246.812, 182.959],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [253.797, 183.941],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [252.566, 182.87],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [250.437, 182.541],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [247.915, 182.731],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [245.201, 183.258],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [242.487, 184.037],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [239.834, 184.963],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [237.318, 185.97],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [234.864, 186.943],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [232.587, 187.934],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [230.379, 188.869],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [228.336, 189.757],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [226.352, 190.593],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [224.477, 191.305],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.672, 191.945],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [220.969, 192.521],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [219.312, 193.002],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [217.714, 193.347],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.204, 193.654],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.719, 193.812],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [213.289, 193.911],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.903, 193.901],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.547, 193.835],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.271, 193.686],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.031, 193.492],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.838, 193.285],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.698, 193.019],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.61, 192.719],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.563, 192.408],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.591, 192.124],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.702, 191.843],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.809, 191.554],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.992, 191.285],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.24, 191.031],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.502, 190.761],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.824, 190.504],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.187, 190.275],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.569, 190.055],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.007, 189.813],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.452, 189.58],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.975, 189.378],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.489, 189.167],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.054, 188.973],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.644, 188.75],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.264, 188.54],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.904, 188.329],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.573, 188.136],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.261, 187.938],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.969, 187.748],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.699, 187.527],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.438, 187.312],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.218, 187.091],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.02, 186.886],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.823, 186.68],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.673, 186.474],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.488, 186.276],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.338, 186.034],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.217, 185.843],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.113, 185.603],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.01, 185.381],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.934, 185.149],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.774, 184.885],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.36, 184.623],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.761, 184.264],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.922, 183.892],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.839, 183.454],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.463, 182.946],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.747, 182.401],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.735, 181.744],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.351, 181.017],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.531, 180.224],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.309, 179.312],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [169.63, 178.284],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.35, 177.151],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.499, 175.863],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.971, 174.423],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.633, 172.803],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.483, 170.95],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.378, 168.86],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.111, 166.463],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.637, 163.714],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.828, 160.514],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.597, 156.795],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.023, 152.419],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.386, 147.17],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.269, 140.809],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.711, 132.995],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.479, 123.362],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.318, 111.594],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.303, 94.765],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.706, 71.869],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.097, 52.642],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.247, 37.094],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.355, 25.03],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.215, 16.034],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.103, 9.505],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.977, 4.796],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.612, 1.28],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.912, -1.438],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.692, -3.619],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.919, -5.507],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.614, -7.11],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.796, -8.54],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.398, -9.848],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-190.518, -11.038],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.229, -12.128],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.451, -13.15],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.268, -14.099],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.765, -14.987],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.832, -15.817],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.577, -16.62],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-236.02, -17.357],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-241.143, -18.053],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.032, -18.709],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.63, -19.335],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.983, -19.938],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-259.126, -20.484],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-262.979, -21.006],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-266.628, -21.525],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-270.153, -21.986],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-273.387, -22.437],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.516, -22.849],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-279.444, -23.265],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-282.226, -23.634],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-284.838, -23.979],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-287.256, -24.312],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-289.595, -24.63],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-291.739, -24.937],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-293.756, -25.211],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.716, -25.462],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-297.455, -25.72],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-299.129, -25.932],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-300.69, -26.148],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.112, -26.345],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.466, -26.527],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.694, -26.694],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-305.811, -26.86],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-306.843, -27.005],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-307.805, -27.134],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-308.635, -27.244],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-309.434, -27.346],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-310.06, -27.444],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-310.695, -27.527],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-311.209, -27.609],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-311.659, -27.656],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-312.065, -27.704],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-312.337, -27.739],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-312.578, -27.792],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-312.691, -27.802],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-312.8, -27.798],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-312.586, -27.682],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-312.235, -27.497],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-311.741, -27.244],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-311.088, -26.897],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-310.218, -26.456],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-309.142, -25.932],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-307.899, -25.24],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-306.399, -24.472],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.602, -23.536],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.558, -22.413],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-300.155, -21.155],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-297.448, -19.65],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.262, -17.925],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-290.688, -15.922],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-286.564, -13.569],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-281.874, -10.885],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.467, -7.697],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-270.304, -4.014],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-263.282, 0.385],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.181, 5.593],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.856, 11.795],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-235.244, 19.199],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-223.206, 28.077],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.768, 38.602],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.167, 50.783],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.899, 64.42],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.919, 75.671],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.723, 76.49],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.866, 75.398],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.951, 73.015],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.098, 69.745],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.212, 66.045],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.961, 62.321],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.641, 58.8],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.927, 55.834],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.798, 53.798],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.2, 53.115],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.39, 53.892],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.825, 55.846],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.119, 58.446],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.659, 61.385],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.621, 64.526],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.172, 67.669],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.35, 70.785],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.237, 73.816],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.837, 76.817],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.202, 79.637],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.358, 82.397],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.289, 85.094],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.029, 87.621],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [159.564, 90.053],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.963, 92.425],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.16, 94.622],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [169.217, 96.765],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.102, 98.786],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.881, 100.725],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.491, 102.617],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.975, 104.329],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.349, 106.027],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.592, 107.62],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.707, 109.157],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.73, 110.574],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.626, 111.936],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.411, 113.214],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.108, 114.439],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.7, 115.542],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.196, 116.637],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.608, 117.652],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.953, 118.592],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.199, 119.5],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.342, 120.291],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.413, 121.102],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.401, 121.829],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.334, 122.495],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.192, 123.098],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.955, 123.654],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.681, 124.171],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.313, 124.629],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.904, 125.067],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.398, 125.443],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.888, 125.775],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.287, 126.073],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.585, 126.293],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.904, 126.485],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.123, 126.65],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.304, 126.788],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.436, 126.902],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [211.505, 126.984],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.49, 127.114],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.466, 127.295],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.411, 127.587],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.334, 127.969],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.264, 128.505],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.13, 129.122],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.02, 129.891],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.904, 130.792],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.746, 131.821],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.581, 133.072],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.413, 134.514],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.273, 136.17],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.095, 138.117],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.022, 140.326],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.933, 142.833],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.028, 145.744],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.224, 149.111],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.643, 152.945],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.419, 157.337],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [212.796, 162.318],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.982, 167.854],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.564, 172.188],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.53, 174.064],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [217.903, 175.79],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.49, 177.409],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [227.652, 179.02],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [236.316, 180.859],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [246.812, 182.959],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [253.797, 183.941],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [252.566, 182.87],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [250.437, 182.541],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [247.915, 182.731],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [245.201, 183.258],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [242.487, 184.037],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [239.834, 184.963],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [237.318, 185.97],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [234.864, 186.943],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [232.587, 187.934],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [230.379, 188.869],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [228.336, 189.757],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [226.352, 190.593],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [224.477, 191.305],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.672, 191.945],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [220.969, 192.521],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [219.312, 193.002],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [217.714, 193.347],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.204, 193.654],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.719, 193.812],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [213.289, 193.911],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.903, 193.901],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.547, 193.835],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.271, 193.686],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.031, 193.492],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.838, 193.285],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.698, 193.019],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.61, 192.719],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.563, 192.408],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.591, 192.124],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.702, 191.843],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.809, 191.554],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.992, 191.285],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.24, 191.031],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.502, 190.761],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.824, 190.504],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.187, 190.275],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.569, 190.055],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.007, 189.813],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.452, 189.58],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.975, 189.378],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.489, 189.167],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.054, 188.973],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.644, 188.75],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.264, 188.54],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.904, 188.329],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.573, 188.136],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.261, 187.938],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.969, 187.748],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.699, 187.527],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.438, 187.312],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.218, 187.091],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.02, 186.886],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.823, 186.68],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.673, 186.474],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.488, 186.276],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.338, 186.034],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.217, 185.843],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.113, 185.603],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.01, 185.381],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.934, 185.149],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.774, 184.885],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.36, 184.623],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.761, 184.264],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.922, 183.892],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.839, 183.454],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [185.463, 182.946],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.747, 182.401],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [181.735, 181.744],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.351, 181.017],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [176.531, 180.224],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [173.309, 179.312],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [169.63, 178.284],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [165.35, 177.151],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.499, 175.863],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [154.971, 174.423],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.633, 172.803],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [141.483, 170.95],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [133.378, 168.86],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [124.111, 166.463],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.637, 163.714],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [101.828, 160.514],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.597, 156.795],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.023, 152.419],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.386, 147.17],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.269, 140.809],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.711, 132.995],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.479, 123.362],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.318, 111.594],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-15.303, 94.765],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-34.706, 71.869],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-50.097, 52.642],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-63.247, 37.094],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-75.355, 25.03],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-87.215, 16.034],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.103, 9.505],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-110.977, 4.796],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.612, 1.28],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-133.912, -1.438],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-144.692, -3.619],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-154.919, -5.507],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-164.614, -7.11],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-173.796, -8.54],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-182.398, -9.848],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-190.518, -11.038],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-198.229, -12.128],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.451, -13.15],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-212.268, -14.099],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.765, -14.987],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-224.832, -15.817],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-230.577, -16.62],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-236.02, -17.357],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-241.143, -18.053],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-246.032, -18.709],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.63, -19.335],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-254.983, -19.938],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-259.126, -20.484],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-262.979, -21.006],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-266.628, -21.525],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-270.153, -21.986],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-273.387, -22.437],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.516, -22.849],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-279.444, -23.265],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-282.226, -23.634],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-284.838, -23.979],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-287.256, -24.312],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-289.595, -24.63],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-291.739, -24.937],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-293.756, -25.211],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.716, -25.462],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-297.455, -25.72],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-299.129, -25.932],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-300.69, -26.148],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.112, -26.345],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-303.466, -26.527],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.694, -26.694],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-305.811, -26.86],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-306.843, -27.005],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-307.805, -27.134],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-308.635, -27.244],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-309.434, -27.346],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-310.06, -27.444],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-310.695, -27.527],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-311.209, -27.609],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-311.659, -27.656],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-312.065, -27.704],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-312.337, -27.739],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-312.578, -27.792],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-312.691, -27.802],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-312.8, -27.798],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-312.586, -27.682],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-312.235, -27.497],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-311.741, -27.244],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-311.088, -26.897],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-310.218, -26.456],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-309.142, -25.932],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-307.899, -25.24],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-306.399, -24.472],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.602, -23.536],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.558, -22.413],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-300.155, -21.155],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-297.448, -19.65],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-294.262, -17.925],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-290.688, -15.922],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-286.564, -13.569],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-281.874, -10.885],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.467, -7.697],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-270.304, -4.014],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-263.282, 0.385],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.181, 5.593],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.856, 11.795],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-235.244, 19.199],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-223.206, 28.077],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-209.768, 38.602],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-195.167, 50.783],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.899, 64.42],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-158.919, 75.671],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-122.723, 76.49],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-88.866, 75.398],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-57.951, 73.015],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-30.098, 69.745],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-5.212, 66.045],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.961, 62.321],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.641, 58.8],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [53.927, 55.834],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [68.798, 53.798],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [81.2, 53.115],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [91.39, 53.892],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [99.825, 55.846],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [107.119, 58.446],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [113.659, 61.385],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [119.621, 64.526],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [125.172, 67.669],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [130.35, 70.785],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [135.237, 73.816],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [139.837, 76.817],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [144.202, 79.637],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [148.358, 82.397],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.289, 85.094],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.029, 87.621],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [159.564, 90.053],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.963, 92.425],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [166.16, 94.622],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [169.217, 96.765],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [172.102, 98.786],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.881, 100.725],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.491, 102.617],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.975, 104.329],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [182.349, 106.027],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [184.592, 107.62],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.707, 109.157],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [188.73, 110.574],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.626, 111.936],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [192.411, 113.214],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [194.108, 114.439],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.7, 115.542],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.196, 116.637],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.608, 117.652],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.953, 118.592],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.199, 119.5],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.342, 120.291],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.413, 121.102],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.401, 121.829],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.334, 122.495],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.192, 123.098],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.955, 123.654],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.681, 124.171],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.313, 124.629],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.904, 125.067],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.398, 125.443],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.888, 125.775],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.287, 126.073],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.585, 126.293],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.904, 126.485],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.123, 126.65],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.304, 126.788],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.436, 126.902],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 14',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 14,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.996, 229.596],
                                  [203.996, 167.199],
                                  [340.794, -58.404],
                                ],
                                c: false,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.996, 229.595],
                                  [203.996, 167.198],
                                  [340.735, -58.343],
                                ],
                                c: false,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.056, 229.484],
                                  [204.06, 167.252],
                                  [340.49, -58.162],
                                ],
                                c: false,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.177, 229.371],
                                  [204.182, 167.3],
                                  [340.067, -57.916],
                                ],
                                c: false,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.36, 229.203],
                                  [204.364, 167.348],
                                  [339.401, -57.489],
                                ],
                                c: false,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.541, 228.978],
                                  [204.553, 167.449],
                                  [338.615, -56.939],
                                ],
                                c: false,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.845, 228.697],
                                  [204.861, 167.604],
                                  [337.527, -56.203],
                                ],
                                c: false,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.147, 228.36],
                                  [205.172, 167.754],
                                  [336.198, -55.345],
                                ],
                                c: false,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.573, 227.913],
                                  [205.602, 167.958],
                                  [334.626, -54.246],
                                ],
                                c: false,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [26.059, 227.41],
                                  [206.101, 168.218],
                                  [332.75, -53.021],
                                ],
                                c: false,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [26.606, 226.796],
                                  [206.656, 168.47],
                                  [330.576, -51.549],
                                ],
                                c: false,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [27.275, 226.072],
                                  [207.338, 168.77],
                                  [327.983, -49.774],
                                ],
                                c: false,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [28.005, 225.292],
                                  [208.09, 169.181],
                                  [325.024, -47.811],
                                ],
                                c: false,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [28.921, 224.348],
                                  [209.018, 169.588],
                                  [321.588, -45.48],
                                ],
                                c: false,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [29.896, 223.293],
                                  [210.019, 170.096],
                                  [317.611, -42.784],
                                ],
                                c: false,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [31.12, 222.074],
                                  [211.259, 170.653],
                                  [313.095, -39.713],
                                ],
                                c: false,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [32.403, 220.637],
                                  [212.577, 171.258],
                                  [307.918, -36.212],
                                ],
                                c: false,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [33.996, 219.037],
                                  [214.196, 172.027],
                                  [301.964, -32.218],
                                ],
                                c: false,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [35.773, 217.165],
                                  [216.008, 172.89],
                                  [295.109, -27.548],
                                ],
                                c: false,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [37.798, 215.075],
                                  [218.076, 173.853],
                                  [287.24, -22.196],
                                ],
                                c: false,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [40.132, 212.608],
                                  [220.463, 174.971],
                                  [278.175, -15.985],
                                ],
                                c: false,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [42.901, 209.763],
                                  [223.289, 176.292],
                                  [267.619, -8.845],
                                ],
                                c: false,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [46.103, 206.487],
                                  [226.553, 177.816],
                                  [255.453, -0.531],
                                ],
                                c: false,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [49.739, 202.727],
                                  [230.269, 179.596],
                                  [241.378, 9.082],
                                ],
                                c: false,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [53.997, 198.378],
                                  [234.617, 181.626],
                                  [225.1, 20.235],
                                ],
                                c: false,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [58.813, 193.44],
                                  [239.536, 183.905],
                                  [206.735, 32.815],
                                ],
                                c: false,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [64.187, 188.025],
                                  [245.02, 186.436],
                                  [186.474, 46.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [69.807, 182.293],
                                  [250.763, 189.11],
                                  [165.08, 61.357],
                                ],
                                c: false,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [75.421, 176.568],
                                  [256.504, 191.836],
                                  [143.519, 76.086],
                                ],
                                c: false,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [80.906, 171.013],
                                  [262.107, 194.386],
                                  [122.739, 90.306],
                                ],
                                c: false,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [85.947, 165.895],
                                  [267.264, 196.772],
                                  [103.395, 103.532],
                                ],
                                c: false,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [90.545, 161.159],
                                  [271.971, 198.883],
                                  [85.728, 115.623],
                                ],
                                c: false,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [94.699, 156.913],
                                  [276.227, 200.839],
                                  [69.733, 126.536],
                                ],
                                c: false,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [98.471, 153.102],
                                  [280.094, 202.574],
                                  [55.289, 136.38],
                                ],
                                c: false,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [101.861, 149.62],
                                  [283.572, 204.09],
                                  [42.162, 145.222],
                                ],
                                c: false,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [104.868, 146.465],
                                  [286.664, 205.438],
                                  [30.291, 153.308],
                                ],
                                c: false,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [107.616, 143.584],
                                  [289.496, 206.673],
                                  [19.437, 160.577],
                                ],
                                c: false,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [110.168, 140.976],
                                  [292.118, 207.791],
                                  [9.544, 167.271],
                                ],
                                c: false,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [112.397, 138.589],
                                  [294.426, 208.734],
                                  [0.422, 173.334],
                                ],
                                c: false,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [114.49, 136.422],
                                  [296.591, 209.615],
                                  [-7.919, 178.886],
                                ],
                                c: false,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [116.383, 134.369],
                                  [298.559, 210.372],
                                  [-15.719, 184.047],
                                ],
                                c: false,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [118.139, 132.536],
                                  [300.388, 211.06],
                                  [-22.86, 188.756],
                                ],
                                c: false,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [119.693, 130.818],
                                  [302.013, 211.684],
                                  [-29.523, 193.08],
                                ],
                                c: false,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [121.17, 129.214],
                                  [303.565, 212.169],
                                  [-35.761, 197.122],
                                ],
                                c: false,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [122.506, 127.725],
                                  [304.974, 212.587],
                                  [-41.58, 200.839],
                                ],
                                c: false,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [123.699, 126.351],
                                  [306.244, 212.984],
                                  [-46.979, 204.225],
                                ],
                                c: false,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [124.812, 125.093],
                                  [307.432, 213.3],
                                  [-52.074, 207.39],
                                ],
                                c: false,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [125.78, 123.898],
                                  [308.481, 213.478],
                                  [-56.806, 210.347],
                                ],
                                c: false,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [126.728, 122.819],
                                  [309.509, 213.688],
                                  [-61.291, 213.03],
                                ],
                                c: false,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [127.532, 121.804],
                                  [310.396, 213.759],
                                  [-65.475, 215.498],
                                ],
                                c: false,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [128.254, 120.853],
                                  [311.207, 213.806],
                                  [-69.411, 217.808],
                                ],
                                c: false,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [128.896, 119.966],
                                  [311.942, 213.78],
                                  [-73.11, 219.855],
                                ],
                                c: false,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [129.462, 119.193],
                                  [312.598, 213.723],
                                  [-76.618, 221.812],
                                ],
                                c: false,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [129.955, 118.431],
                                  [313.19, 213.604],
                                  [-79.877, 223.579],
                                ],
                                c: false,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [130.441, 117.779],
                                  [313.766, 213.471],
                                  [-82.957, 225.205],
                                ],
                                c: false,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [130.801, 117.135],
                                  [314.229, 213.337],
                                  [-85.854, 226.758],
                                ],
                                c: false,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [131.165, 116.548],
                                  [314.684, 213.153],
                                  [-88.568, 228.141],
                                ],
                                c: false,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [131.536, 116.015],
                                  [315.145, 212.919],
                                  [-91.155, 229.471],
                                ],
                                c: false,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [131.792, 115.486],
                                  [315.49, 212.695],
                                  [-93.562, 230.683],
                                ],
                                c: false,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [132.061, 115.057],
                                  [315.854, 212.498],
                                  [-95.851, 231.801],
                                ],
                                c: false,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [132.345, 114.58],
                                  [316.216, 212.309],
                                  [-97.956, 232.868],
                                ],
                                c: false,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [132.582, 114.2],
                                  [316.528, 212.097],
                                  [-100.001, 233.839],
                                ],
                                c: false,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [132.771, 113.82],
                                  [316.798, 211.895],
                                  [-101.869, 234.822],
                                ],
                                c: false,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [132.975, 113.487],
                                  [317.081, 211.719],
                                  [-103.624, 235.701],
                                ],
                                c: false,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [133.194, 113.153],
                                  [317.361, 211.512],
                                  [-105.314, 236.49],
                                ],
                                c: false,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [133.364, 112.865],
                                  [317.605, 211.314],
                                  [-106.885, 237.282],
                                ],
                                c: false,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [133.547, 112.575],
                                  [317.845, 211.148],
                                  [-108.345, 237.983],
                                ],
                                c: false,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [133.68, 112.331],
                                  [318.041, 210.99],
                                  [-109.741, 238.685],
                                ],
                                c: false,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [133.825, 112.085],
                                  [318.246, 210.799],
                                  [-111.027, 239.293],
                                ],
                                c: false,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [133.918, 111.883],
                                  [318.398, 210.621],
                                  [-112.249, 239.854],
                                ],
                                c: false,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.083, 111.68],
                                  [318.612, 210.465],
                                  [-113.362, 240.421],
                                ],
                                c: false,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.134, 111.475],
                                  [318.718, 210.32],
                                  [-114.42, 240.884],
                                ],
                                c: false,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.255, 111.315],
                                  [318.885, 210.187],
                                  [-115.416, 241.351],
                                ],
                                c: false,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.322, 111.153],
                                  [318.996, 210.01],
                                  [-116.295, 241.775],
                                ],
                                c: false,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.397, 111.035],
                                  [319.114, 209.851],
                                  [-117.119, 242.148],
                                ],
                                c: false,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.417, 110.869],
                                  [319.184, 209.702],
                                  [-117.889, 242.468],
                                ],
                                c: false,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.506, 110.793],
                                  [319.305, 209.562],
                                  [-118.597, 242.797],
                                ],
                                c: false,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.54, 110.67],
                                  [319.377, 209.43],
                                  [-119.252, 243.073],
                                ],
                                c: false,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.516, 110.591],
                                  [319.391, 209.314],
                                  [-119.798, 243.294],
                                ],
                                c: false,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.561, 110.511],
                                  [319.465, 209.152],
                                  [-120.337, 243.47],
                                ],
                                c: false,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.549, 110.474],
                                  [319.488, 208.997],
                                  [-120.821, 243.644],
                                ],
                                c: false,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.541, 110.39],
                                  [319.516, 208.85],
                                  [-121.244, 243.763],
                                ],
                                c: false,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.539, 110.35],
                                  [319.539, 208.708],
                                  [-121.568, 243.897],
                                ],
                                c: false,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.479, 110.309],
                                  [319.512, 208.581],
                                  [-121.885, 243.966],
                                ],
                                c: false,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.485, 110.311],
                                  [319.542, 208.46],
                                  [-122.139, 243.988],
                                ],
                                c: false,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.433, 110.311],
                                  [319.513, 208.345],
                                  [-122.402, 244.015],
                                ],
                                c: false,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.322, 110.311],
                                  [319.433, 208.182],
                                  [-122.55, 243.985],
                                ],
                                c: false,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.278, 110.308],
                                  [319.41, 208.086],
                                  [-122.698, 243.952],
                                ],
                                c: false,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.237, 110.305],
                                  [319.39, 207.932],
                                  [-122.794, 243.87],
                                ],
                                c: false,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.137, 110.345],
                                  [319.31, 207.792],
                                  [-122.827, 243.792],
                                ],
                                c: false,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [134.04, 110.384],
                                  [319.232, 207.657],
                                  [-122.808, 243.657],
                                ],
                                c: false,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [133.938, 110.359],
                                  [319.041, 207.472],
                                  [-122.78, 243.481],
                                ],
                                c: false,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [133.766, 110.208],
                                  [318.532, 207.292],
                                  [-122.86, 243.265],
                                ],
                                c: false,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [133.57, 109.984],
                                  [317.785, 206.955],
                                  [-122.913, 243.01],
                                ],
                                c: false,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [133.358, 109.571],
                                  [316.721, 206.623],
                                  [-123.019, 242.626],
                                ],
                                c: false,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [133.059, 109.032],
                                  [315.32, 206.187],
                                  [-123.16, 242.201],
                                ],
                                c: false,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [132.689, 108.305],
                                  [313.532, 205.649],
                                  [-123.336, 241.693],
                                ],
                                c: false,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [132.294, 107.443],
                                  [311.311, 205.062],
                                  [-123.556, 241.102],
                                ],
                                c: false,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [131.801, 106.338],
                                  [308.692, 204.318],
                                  [-123.855, 240.382],
                                ],
                                c: false,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [131.228, 105.09],
                                  [305.58, 203.472],
                                  [-124.188, 239.579],
                                ],
                                c: false,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [130.619, 103.59],
                                  [301.921, 202.522],
                                  [-124.607, 238.693],
                                ],
                                c: false,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [129.903, 101.786],
                                  [297.705, 201.417],
                                  [-125.105, 237.636],
                                ],
                                c: false,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [129.033, 99.766],
                                  [292.828, 200.156],
                                  [-125.626, 236.408],
                                ],
                                c: false,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [128.109, 97.379],
                                  [287.183, 198.74],
                                  [-126.278, 235.054],
                                ],
                                c: false,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [127.012, 94.616],
                                  [280.709, 197.114],
                                  [-126.997, 233.486],
                                ],
                                c: false,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [125.787, 91.468],
                                  [273.299, 195.28],
                                  [-127.836, 231.705],
                                ],
                                c: false,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [124.425, 87.862],
                                  [264.786, 193.184],
                                  [-128.839, 229.712],
                                ],
                                c: false,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [122.808, 83.747],
                                  [255.055, 190.774],
                                  [-129.942, 227.419],
                                ],
                                c: false,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [120.961, 78.942],
                                  [243.895, 188.05],
                                  [-131.251, 224.783],
                                ],
                                c: false,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [118.884, 73.431],
                                  [230.968, 184.905],
                                  [-132.693, 221.762],
                                ],
                                c: false,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [116.484, 67.043],
                                  [216.109, 181.287],
                                  [-134.418, 218.313],
                                ],
                                c: false,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [113.691, 59.679],
                                  [198.927, 177.092],
                                  [-136.406, 214.306],
                                ],
                                c: false,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [110.463, 51.117],
                                  [178.992, 172.265],
                                  [-138.699, 209.699],
                                ],
                                c: false,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [106.71, 41.212],
                                  [155.915, 166.701],
                                  [-141.33, 204.407],
                                ],
                                c: false,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [102.421, 29.735],
                                  [129.36, 160.296],
                                  [-144.385, 198.3],
                                ],
                                c: false,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [97.585, 16.79],
                                  [99.225, 153.05],
                                  [-147.863, 191.381],
                                ],
                                c: false,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [92.231, 2.503],
                                  [66.059, 145.069],
                                  [-151.677, 183.779],
                                ],
                                c: false,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [86.559, -12.618],
                                  [31.022, 136.618],
                                  [-155.714, 175.757],
                                ],
                                c: false,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [80.809, -27.802],
                                  [-4.276, 128.12],
                                  [-159.735, 167.659],
                                ],
                                c: false,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [75.32, -42.468],
                                  [-38.234, 119.945],
                                  [-163.65, 159.918],
                                ],
                                c: false,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [70.169, -56.125],
                                  [-69.908, 112.356],
                                  [-167.242, 152.705],
                                ],
                                c: false,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [65.5, -68.639],
                                  [-98.815, 105.406],
                                  [-170.575, 146.105],
                                ],
                                c: false,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [61.21, -79.947],
                                  [-125.008, 99.094],
                                  [-173.545, 140.117],
                                ],
                                c: false,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [57.323, -90.164],
                                  [-148.648, 93.419],
                                  [-176.205, 134.739],
                                ],
                                c: false,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [53.83, -99.382],
                                  [-170.074, 88.276],
                                  [-178.643, 129.883],
                                ],
                                c: false,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [50.65, -107.821],
                                  [-189.506, 83.61],
                                  [-180.849, 125.464],
                                ],
                                c: false,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [47.72, -115.448],
                                  [-207.178, 79.316],
                                  [-182.858, 121.435],
                                ],
                                c: false,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [45.012, -122.494],
                                  [-223.343, 75.447],
                                  [-184.68, 117.755],
                                ],
                                c: false,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [42.57, -128.916],
                                  [-238.244, 71.844],
                                  [-186.338, 114.378],
                                ],
                                c: false,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [40.278, -134.818],
                                  [-251.923, 68.559],
                                  [-187.844, 111.305],
                                ],
                                c: false,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [38.17, -140.337],
                                  [-264.559, 65.486],
                                  [-189.282, 108.449],
                                ],
                                c: false,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [36.193, -145.418],
                                  [-276.302, 62.679],
                                  [-190.558, 105.767],
                                ],
                                c: false,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [34.338, -150.124],
                                  [-287.223, 60.031],
                                  [-191.758, 103.301],
                                ],
                                c: false,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [32.648, -154.561],
                                  [-297.366, 57.595],
                                  [-192.892, 101.008],
                                ],
                                c: false,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [31.071, -158.686],
                                  [-306.846, 55.319],
                                  [-193.95, 98.887],
                                ],
                                c: false,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [29.544, -162.509],
                                  [-315.728, 53.149],
                                  [-194.922, 96.896],
                                ],
                                c: false,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [28.109, -166.125],
                                  [-324, 51.138],
                                  [-195.819, 94.992],
                                ],
                                c: false,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [26.83, -169.543],
                                  [-331.821, 49.286],
                                  [-196.694, 93.26],
                                ],
                                c: false,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.529, -172.722],
                                  [-339.158, 47.487],
                                  [-197.473, 91.614],
                                ],
                                c: false,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.384, -175.714],
                                  [-346.045, 45.847],
                                  [-198.23, 90.053],
                                ],
                                c: false,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.26, -178.561],
                                  [-352.501, 44.261],
                                  [-198.945, 88.579],
                                ],
                                c: false,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [22.229, -181.178],
                                  [-358.623, 42.78],
                                  [-199.628, 87.233],
                                ],
                                c: false,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [21.229, -183.713],
                                  [-364.357, 41.406],
                                  [-200.226, 85.93],
                                ],
                                c: false,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [20.303, -186.061],
                                  [-369.818, 40.084],
                                  [-200.834, 84.713],
                                ],
                                c: false,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [19.408, -188.284],
                                  [-374.902, 38.816],
                                  [-201.348, 83.581],
                                ],
                                c: false,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [18.544, -190.435],
                                  [-379.757, 37.653],
                                  [-201.883, 82.491],
                                ],
                                c: false,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [17.743, -192.408],
                                  [-384.339, 36.544],
                                  [-202.376, 81.487],
                                ],
                                c: false,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [17.027, -194.309],
                                  [-388.64, 35.54],
                                  [-202.838, 80.525],
                                ],
                                c: false,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [16.331, -196.086],
                                  [-392.679, 34.536],
                                  [-203.3, 79.606],
                                ],
                                c: false,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [15.657, -197.737],
                                  [-396.551, 33.585],
                                  [-203.668, 78.729],
                                ],
                                c: false,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.994, -199.326],
                                  [-400.141, 32.74],
                                  [-204.109, 77.937],
                                ],
                                c: false,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.415, -200.791],
                                  [-403.575, 31.895],
                                  [-204.446, 77.187],
                                ],
                                c: false,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.837, -202.245],
                                  [-406.79, 31.104],
                                  [-204.795, 76.437],
                                ],
                                c: false,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.291, -203.575],
                                  [-409.838, 30.364],
                                  [-205.101, 75.772],
                                ],
                                c: false,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [12.808, -204.79],
                                  [-412.72, 29.678],
                                  [-205.419, 75.148],
                                ],
                                c: false,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [12.337, -205.985],
                                  [-415.383, 29.045],
                                  [-205.694, 74.525],
                                ],
                                c: false,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.887, -207.075],
                                  [-417.942, 28.411],
                                  [-205.971, 73.986],
                                ],
                                c: false,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.448, -208.145],
                                  [-420.282, 27.831],
                                  [-206.207, 73.446],
                                ],
                                c: false,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.073, -209.1],
                                  [-422.508, 27.303],
                                  [-206.453, 72.949],
                                ],
                                c: false,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.71, -209.993],
                                  [-424.631, 26.776],
                                  [-206.648, 72.493],
                                ],
                                c: false,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.368, -210.876],
                                  [-426.534, 26.301],
                                  [-206.854, 72.037],
                                ],
                                c: false,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.037, -211.645],
                                  [-428.377, 25.879],
                                  [-207.071, 71.666],
                                ],
                                c: false,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.707, -212.403],
                                  [-430.062, 25.457],
                                  [-207.236, 71.251],
                                ],
                                c: false,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.451, -213.099],
                                  [-431.635, 25.087],
                                  [-207.412, 70.921],
                                ],
                                c: false,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.207, -213.732],
                                  [-433.103, 24.718],
                                  [-207.537, 70.59],
                                ],
                                c: false,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.963, -214.304],
                                  [-434.458, 24.402],
                                  [-207.725, 70.302],
                                ],
                                c: false,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.741, -214.875],
                                  [-435.656, 24.085],
                                  [-207.809, 70.012],
                                ],
                                c: false,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.521, -215.384],
                                  [-436.793, 23.821],
                                  [-207.957, 69.765],
                                ],
                                c: false,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.321, -215.83],
                                  [-437.826, 23.558],
                                  [-208.053, 69.56],
                                ],
                                c: false,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.175, -216.214],
                                  [-438.745, 23.347],
                                  [-208.16, 69.354],
                                ],
                                c: false,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.041, -216.588],
                                  [-439.613, 23.136],
                                  [-208.216, 69.147],
                                ],
                                c: false,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.908, -216.91],
                                  [-440.315, 22.978],
                                  [-208.335, 68.983],
                                ],
                                c: false,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.795, -217.222],
                                  [-441.018, 22.819],
                                  [-208.402, 68.86],
                                ],
                                c: false,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.685, -217.471],
                                  [-441.564, 22.661],
                                  [-208.471, 68.737],
                                ],
                                c: false,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.585, -217.668],
                                  [-442.05, 22.556],
                                  [-208.498, 68.613],
                                ],
                                c: false,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.496, -217.855],
                                  [-442.484, 22.45],
                                  [-208.525, 68.531],
                                ],
                                c: false,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.461, -217.99],
                                  [-442.804, 22.397],
                                  [-208.564, 68.448],
                                ],
                                c: false,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.385, -218.115],
                                  [-443.03, 22.292],
                                  [-208.593, 68.408],
                                ],
                                c: false,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.362, -218.177],
                                  [-443.185, 22.292],
                                  [-208.644, 68.366],
                                ],
                                c: false,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.35, -218.239],
                                  [-443.298, 22.239],
                                  [-208.633, 68.325],
                                ],
                                c: false,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.35, -218.239],
                                  [-443.35, 22.239],
                                  [-208.632, 68.325],
                                ],
                                c: false,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.368, -218.177],
                                  [-443.253, 22.24],
                                  [-208.533, 68.311],
                                ],
                                c: false,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.36, -217.959],
                                  [-442.952, 22.357],
                                  [-208.3, 68.196],
                                ],
                                c: false,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.441, -217.555],
                                  [-442.416, 22.485],
                                  [-207.86, 68.043],
                                ],
                                c: false,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.51, -216.994],
                                  [-441.67, 22.666],
                                  [-207.235, 67.85],
                                ],
                                c: false,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.556, -216.257],
                                  [-440.638, 22.912],
                                  [-206.374, 67.555],
                                ],
                                c: false,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.655, -215.291],
                                  [-439.303, 23.222],
                                  [-205.266, 67.147],
                                ],
                                c: false,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.797, -214.159],
                                  [-437.666, 23.597],
                                  [-203.915, 66.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [7.942, -212.684],
                                  [-435.739, 24.088],
                                  [-202.277, 66.112],
                                ],
                                c: false,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.136, -211.032],
                                  [-433.419, 24.634],
                                  [-200.345, 65.423],
                                ],
                                c: false,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.338, -209.045],
                                  [-430.645, 25.308],
                                  [-198.076, 64.659],
                                ],
                                c: false,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.56, -206.715],
                                  [-427.471, 26.109],
                                  [-195.421, 63.759],
                                ],
                                c: false,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [8.859, -204.04],
                                  [-423.742, 26.976],
                                  [-192.317, 62.659],
                                ],
                                c: false,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.176, -200.914],
                                  [-419.471, 28.015],
                                  [-188.723, 61.464],
                                ],
                                c: false,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.544, -197.326],
                                  [-414.482, 29.192],
                                  [-184.588, 60.057],
                                ],
                                c: false,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [9.958, -193.224],
                                  [-408.84, 30.552],
                                  [-179.86, 58.461],
                                ],
                                c: false,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.434, -188.49],
                                  [-402.284, 32.149],
                                  [-174.382, 56.687],
                                ],
                                c: false,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [10.935, -183.113],
                                  [-394.763, 33.93],
                                  [-168.111, 54.599],
                                ],
                                c: false,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [11.53, -176.825],
                                  [-386.059, 36.031],
                                  [-160.879, 52.223],
                                ],
                                c: false,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [12.194, -169.656],
                                  [-376.014, 38.383],
                                  [-152.48, 49.53],
                                ],
                                c: false,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [12.955, -161.274],
                                  [-364.415, 41.155],
                                  [-142.752, 46.464],
                                ],
                                c: false,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [13.778, -151.599],
                                  [-350.877, 44.371],
                                  [-131.431, 42.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [14.743, -140.344],
                                  [-335.063, 48.085],
                                  [-118.172, 38.872],
                                ],
                                c: false,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [15.776, -127.265],
                                  [-316.695, 52.365],
                                  [-102.757, 34.239],
                                ],
                                c: false,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [16.889, -112.162],
                                  [-295.409, 57.332],
                                  [-84.88, 29.021],
                                ],
                                c: false,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [18.068, -94.961],
                                  [-271.097, 62.988],
                                  [-64.428, 23.249],
                                ],
                                c: false,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [19.282, -75.925],
                                  [-244.056, 69.22],
                                  [-41.645, 17.016],
                                ],
                                c: false,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [20.452, -55.733],
                                  [-215.186, 75.843],
                                  [-17.31, 10.588],
                                ],
                                c: false,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [21.478, -35.266],
                                  [-185.891, 82.476],
                                  [7.442, 4.268],
                                ],
                                c: false,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [22.388, -15.417],
                                  [-157.419, 88.937],
                                  [31.607, -1.593],
                                ],
                                c: false,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.162, 3.093],
                                  [-130.668, 94.918],
                                  [54.286, -6.92],
                                ],
                                c: false,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.727, 20.123],
                                  [-106.012, 100.461],
                                  [75.204, -11.613],
                                ],
                                c: false,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.217, 35.596],
                                  [-83.507, 105.446],
                                  [94.378, -15.806],
                                ],
                                c: false,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.561, 49.667],
                                  [-63.078, 109.94],
                                  [111.805, -19.507],
                                ],
                                c: false,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.801, 62.393],
                                  [-44.436, 114.049],
                                  [127.671, -22.74],
                                ],
                                c: false,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.017, 74.054],
                                  [-27.439, 117.787],
                                  [142.178, -25.621],
                                ],
                                c: false,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.186, 84.659],
                                  [-11.832, 121.209],
                                  [155.467, -28.225],
                                ],
                                c: false,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.28, 94.366],
                                  [2.478, 124.324],
                                  [167.738, -30.584],
                                ],
                                c: false,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.37, 103.361],
                                  [15.65, 127.186],
                                  [178.985, -32.66],
                                ],
                                c: false,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.416, 111.579],
                                  [27.894, 129.798],
                                  [189.474, -34.587],
                                ],
                                c: false,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.437, 119.257],
                                  [39.203, 132.279],
                                  [199.141, -36.317],
                                ],
                                c: false,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.438, 126.39],
                                  [49.741, 134.515],
                                  [208.149, -37.919],
                                ],
                                c: false,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.434, 133.045],
                                  [59.558, 136.616],
                                  [216.607, -39.406],
                                ],
                                c: false,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.425, 139.215],
                                  [68.763, 138.598],
                                  [224.454, -40.729],
                                ],
                                c: false,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.422, 145.02],
                                  [77.351, 140.445],
                                  [231.857, -41.949],
                                ],
                                c: false,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.376, 150.408],
                                  [85.433, 142.169],
                                  [238.751, -43.129],
                                ],
                                c: false,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.348, 155.545],
                                  [93.012, 143.769],
                                  [245.254, -44.219],
                                ],
                                c: false,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.283, 160.317],
                                  [100.136, 145.303],
                                  [251.358, -45.162],
                                ],
                                c: false,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.24, 164.79],
                                  [106.809, 146.714],
                                  [257.125, -46.137],
                                ],
                                c: false,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.228, 169.013],
                                  [113.139, 148.059],
                                  [262.553, -46.973],
                                ],
                                c: false,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.127, 173.053],
                                  [119.129, 149.339],
                                  [267.687, -47.784],
                                ],
                                c: false,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.118, 176.79],
                                  [124.715, 150.549],
                                  [272.543, -48.516],
                                ],
                                c: false,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [25.028, 180.339],
                                  [130.017, 151.646],
                                  [277.105, -49.234],
                                ],
                                c: false,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.978, 183.705],
                                  [135.085, 152.731],
                                  [281.439, -49.877],
                                ],
                                c: false,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.962, 186.888],
                                  [139.809, 153.699],
                                  [285.487, -50.508],
                                ],
                                c: false,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.872, 189.883],
                                  [144.303, 154.659],
                                  [289.354, -51.123],
                                ],
                                c: false,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.822, 192.696],
                                  [148.509, 155.555],
                                  [292.992, -51.673],
                                ],
                                c: false,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.816, 195.379],
                                  [152.537, 156.391],
                                  [296.459, -52.149],
                                ],
                                c: false,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.74, 197.884],
                                  [156.334, 157.225],
                                  [299.685, -52.618],
                                ],
                                c: false,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.706, 200.264],
                                  [159.894, 157.932],
                                  [302.798, -53.077],
                                ],
                                c: false,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.66, 202.573],
                                  [163.28, 158.701],
                                  [305.733, -53.525],
                                ],
                                c: false,
                              },
                            ],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.603, 204.645],
                                  [166.496, 159.342],
                                  [308.49, -53.909],
                                ],
                                c: false,
                              },
                            ],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.531, 206.71],
                                  [169.527, 159.987],
                                  [311.07, -54.287],
                                ],
                                c: false,
                              },
                            ],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.511, 208.592],
                                  [172.388, 160.567],
                                  [313.535, -54.658],
                                ],
                                c: false,
                              },
                            ],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.478, 210.349],
                                  [175.069, 161.142],
                                  [315.822, -54.966],
                                ],
                                c: false,
                              },
                            ],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.439, 212.041],
                                  [177.579, 161.661],
                                  [317.989, -55.268],
                                ],
                                c: false,
                              },
                            ],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.392, 213.612],
                                  [179.967, 162.171],
                                  [320.036, -55.564],
                                ],
                                c: false,
                              },
                            ],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.389, 215.118],
                                  [182.174, 162.625],
                                  [321.967, -55.854],
                                ],
                                c: false,
                              },
                            ],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.325, 216.5],
                                  [184.267, 163.074],
                                  [323.774, -56.084],
                                ],
                                c: false,
                              },
                            ],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.31, 217.82],
                                  [186.243, 163.463],
                                  [325.466, -56.309],
                                ],
                                c: false,
                              },
                            ],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.292, 219.016],
                                  [188.096, 163.847],
                                  [327.095, -56.532],
                                ],
                                c: false,
                              },
                            ],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.262, 220.147],
                                  [189.83, 164.23],
                                  [328.547, -56.745],
                                ],
                                c: false,
                              },
                            ],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.228, 221.216],
                                  [191.389, 164.554],
                                  [329.937, -56.96],
                                ],
                                c: false,
                              },
                            ],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.187, 222.22],
                                  [192.887, 164.876],
                                  [331.206, -57.112],
                                ],
                                c: false,
                              },
                            ],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.139, 223.163],
                                  [194.266, 165.135],
                                  [332.414, -57.262],
                                ],
                                c: false,
                              },
                            ],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.146, 223.982],
                                  [195.523, 165.452],
                                  [333.506, -57.406],
                                ],
                                c: false,
                              },
                            ],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.147, 224.741],
                                  [196.724, 165.651],
                                  [334.479, -57.549],
                                ],
                                c: false,
                              },
                            ],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.087, 225.492],
                                  [197.744, 165.907],
                                  [335.444, -57.689],
                                ],
                                c: false,
                              },
                            ],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.078, 226.124],
                                  [198.763, 166.1],
                                  [336.234, -57.829],
                                ],
                                c: false,
                              },
                            ],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.062, 226.692],
                                  [199.608, 166.291],
                                  [337.022, -57.908],
                                ],
                                c: false,
                              },
                            ],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.045, 227.198],
                                  [200.448, 166.424],
                                  [337.69, -57.985],
                                ],
                                c: false,
                              },
                            ],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.025, 227.698],
                                  [201.106, 166.615],
                                  [338.297, -58.056],
                                ],
                                c: false,
                              },
                            ],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.053, 228.079],
                                  [201.767, 166.746],
                                  [338.846, -58.129],
                                ],
                                c: false,
                              },
                            ],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.026, 228.458],
                                  [202.31, 166.872],
                                  [339.33, -58.196],
                                ],
                                c: false,
                              },
                            ],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.987, 228.772],
                                  [202.729, 166.939],
                                  [339.694, -58.266],
                                ],
                                c: false,
                              },
                            ],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.01, 229.025],
                                  [203.152, 167.006],
                                  [340.06, -58.333],
                                ],
                                c: false,
                              },
                            ],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [24.026, 229.214],
                                  [203.451, 167.071],
                                  [340.306, -58.336],
                                ],
                                c: false,
                              },
                            ],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.978, 229.405],
                                  [203.695, 167.132],
                                  [340.546, -58.399],
                                ],
                                c: false,
                              },
                            ],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                o: [
                                  [0, 0],
                                  [0, 0],
                                  [0, 0],
                                ],
                                v: [
                                  [23.987, 229.527],
                                  [203.875, 167.197],
                                  [340.67, -58.402],
                                ],
                                c: false,
                              },
                            ],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.249, 0.249, 0.249, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.249, 0.249, 0.249, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [2],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [2],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [222.979, 135.887],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.963, 136.019],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.94, 136.21],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.883, 136.514],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.797, 136.913],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.723, 137.472],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.582, 138.11],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.468, 138.911],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.344, 139.844],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.175, 140.916],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.994, 142.221],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.814, 143.714],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.652, 145.45],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.455, 147.468],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.36, 149.787],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.239, 152.409],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.313, 155.464],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.493, 158.991],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.894, 163.031],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.668, 167.673],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [224.091, 172.939],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [225.556, 177.975],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [224.759, 180.302],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [224.797, 182.537],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [226.38, 184.599],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [230.366, 186.565],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [237.152, 188.597],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [246.555, 190.997],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [257.768, 193.826],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [265.271, 195.638],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [263.442, 195.07],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [260.859, 195.309],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [257.986, 196.064],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [255.024, 197.129],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [252.129, 198.416],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [249.353, 199.821],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [246.77, 201.288],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [244.263, 202.694],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [241.979, 204.104],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [239.782, 205.448],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [237.755, 206.738],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [235.823, 207.969],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [233.996, 209.084],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [232.264, 210.123],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [230.642, 211.109],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [229.075, 212.012],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [227.579, 212.785],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [226.181, 213.54],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [224.812, 214.155],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [223.505, 214.728],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.247, 215.195],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.022, 215.627],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [219.873, 215.972],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [218.769, 216.28],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [217.702, 216.575],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.691, 216.796],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [215.718, 216.97],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.781, 217.124],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [213.904, 217.278],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [213.124, 217.427],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [212.319, 217.537],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.585, 217.657],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.907, 217.771],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.246, 217.842],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.623, 217.92],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.055, 217.998],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.489, 218.082],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.976, 218.118],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.459, 218.152],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.03, 218.203],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.582, 218.233],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.185, 218.274],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.81, 218.27],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.459, 218.266],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.128, 218.252],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.827, 218.25],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.546, 218.237],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.283, 218.22],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.039, 218.159],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.792, 218.101],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.591, 218.032],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.423, 217.971],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.243, 217.902],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.111, 217.826],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.945, 217.754],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.805, 217.629],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.621, 217.422],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.53, 217.301],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.469, 217.166],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.311, 216.986],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.861, 216.8],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.19, 216.494],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.247, 216.159],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.014, 215.74],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.449, 215.227],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.491, 214.66],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.187, 213.948],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.451, 213.144],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.22, 212.249],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.527, 211.198],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.303, 209.991],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.403, 208.645],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.864, 207.092],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.559, 205.336],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [155.348, 203.34],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.248, 201.035],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.123, 198.407],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.78, 195.363],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.22, 191.839],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.366, 187.697],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.268, 182.837],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.156, 177.073],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.511, 170.132],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.018, 161.77],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.55, 151.738],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.587, 139.853],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.155, 120.568],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.173, 92.682],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.551, 68.626],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.975, 48.378],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.806, 31.598],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.203, 18.152],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.402, 8.092],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.14, 1.215],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.054, -3.3],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.229, -6.512],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.133, -9.022],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.437, -11.126],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-149.037, -13.056],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.991, -14.775],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.315, -16.373],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.944, -17.892],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.01, -19.308],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.58, -20.647],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.598, -21.92],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.151, -23.12],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.336, -24.261],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.041, -25.331],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.36, -26.388],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-239.355, -27.355],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.003, -28.277],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.37, -29.158],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.41, -30.005],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-260.2, -30.812],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-264.742, -31.557],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-268.979, -32.26],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-272.967, -32.964],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.83, -33.599],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-280.378, -34.209],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-283.795, -34.783],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-286.995, -35.354],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-290.046, -35.866],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-292.905, -36.34],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.555, -36.8],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-298.111, -37.239],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-300.458, -37.663],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.665, -38.04],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.796, -38.399],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-306.691, -38.758],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-308.524, -39.055],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-310.224, -39.361],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-311.782, -39.633],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-313.261, -39.889],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-314.6, -40.121],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-315.82, -40.352],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-316.952, -40.552],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-318.005, -40.734],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-318.912, -40.887],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-319.788, -41.032],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-320.471, -41.163],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-321.172, -41.278],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-321.724, -41.396],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.225, -41.462],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.674, -41.532],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.978, -41.575],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-323.225, -41.653],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-323.357, -41.663],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-323.474, -41.665],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-323.273, -41.512],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.902, -41.287],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.412, -40.964],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-321.751, -40.529],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-320.876, -39.975],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-319.791, -39.316],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-318.548, -38.451],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-317.024, -37.488],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-315.202, -36.322],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-313.15, -34.934],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-310.724, -33.377],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-307.983, -31.528],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.767, -29.418],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-301.172, -26.977],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-297.007, -24.132],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-292.264, -20.906],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-286.79, -17.114],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-280.545, -12.778],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-273.435, -7.648],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-265.208, -1.669],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.731, 5.352],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-244.916, 13.584],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-232.62, 23.254],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.869, 34.46],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.931, 47.104],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.334, 60.849],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.889, 75.094],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.668, 88.577],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.667, 87.208],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.303, 84.561],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.614, 80.98],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.387, 76.86],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.802, 72.531],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.291, 68.132],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.417, 63.821],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.374, 59.743],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.122, 56.262],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.239, 54.162],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.651, 54.855],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.276, 57.734],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.66, 61.407],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.399, 65.3],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.755, 69.138],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.769, 72.873],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.52, 76.462],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.011, 79.966],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.286, 83.236],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.364, 86.415],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.232, 89.491],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.923, 92.366],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [171.408, 95.118],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.772, 97.789],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.932, 100.259],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.956, 102.661],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.819, 104.911],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.569, 107.079],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.161, 109.172],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.626, 111.078],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.986, 112.955],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.214, 114.71],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.312, 116.411],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.326, 117.967],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.212, 119.468],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.98, 120.873],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.674, 122.209],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.255, 123.426],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.74, 124.629],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.148, 125.728],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.488, 126.76],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [212.727, 127.756],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [213.861, 128.625],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.932, 129.496],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [215.913, 130.288],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.843, 131.017],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [217.694, 131.674],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [218.453, 132.279],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [219.178, 132.844],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [219.807, 133.335],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [220.393, 133.812],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [220.885, 134.223],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.374, 134.58],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.772, 134.903],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.067, 135.143],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.383, 135.352],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.602, 135.529],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.782, 135.676],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.913, 135.799],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [222.979, 135.887],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.963, 136.019],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.94, 136.21],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.883, 136.514],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.797, 136.913],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.723, 137.472],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.582, 138.11],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.468, 138.911],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.344, 139.844],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.175, 140.916],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.994, 142.221],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.814, 143.714],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.652, 145.45],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.455, 147.468],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.36, 149.787],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.239, 152.409],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.313, 155.464],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.493, 158.991],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.894, 163.031],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.668, 167.673],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [224.091, 172.939],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [225.556, 177.975],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [224.759, 180.302],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [224.797, 182.537],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [226.38, 184.599],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [230.366, 186.565],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [237.152, 188.597],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [246.555, 190.997],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [257.768, 193.826],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [265.271, 195.638],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [263.442, 195.07],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [260.859, 195.309],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [257.986, 196.064],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [255.024, 197.129],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [252.129, 198.416],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [249.353, 199.821],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [246.77, 201.288],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [244.263, 202.694],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [241.979, 204.104],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [239.782, 205.448],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [237.755, 206.738],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [235.823, 207.969],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [233.996, 209.084],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [232.264, 210.123],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [230.642, 211.109],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [229.075, 212.012],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [227.579, 212.785],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [226.181, 213.54],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [224.812, 214.155],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [223.505, 214.728],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.247, 215.195],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.022, 215.627],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [219.873, 215.972],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [218.769, 216.28],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [217.702, 216.575],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.691, 216.796],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [215.718, 216.97],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.781, 217.124],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [213.904, 217.278],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [213.124, 217.427],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [212.319, 217.537],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.585, 217.657],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.907, 217.771],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.246, 217.842],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.623, 217.92],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [209.055, 217.998],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.489, 218.082],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.976, 218.118],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.459, 218.152],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.03, 218.203],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.582, 218.233],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [206.185, 218.274],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.81, 218.27],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.459, 218.266],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.128, 218.252],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.827, 218.25],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.546, 218.237],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.283, 218.22],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [204.039, 218.159],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.792, 218.101],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.591, 218.032],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.423, 217.971],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.243, 217.902],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.111, 217.826],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.945, 217.754],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.805, 217.629],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.621, 217.422],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.53, 217.301],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.469, 217.166],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.311, 216.986],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.861, 216.8],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [201.19, 216.494],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.247, 216.159],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [199.014, 215.74],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [197.449, 215.227],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [195.491, 214.66],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.187, 213.948],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [190.451, 213.144],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [187.22, 212.249],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.527, 211.198],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [179.303, 209.991],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.403, 208.645],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [168.864, 207.092],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [162.559, 205.336],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [155.348, 203.34],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.248, 201.035],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [138.123, 198.407],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [127.78, 195.363],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [116.22, 191.839],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [103.366, 187.697],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [89.268, 182.837],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [74.156, 177.073],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [58.511, 170.132],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.018, 161.77],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.55, 151.738],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.587, 139.853],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.155, 120.568],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-19.173, 92.682],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-33.551, 68.626],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-44.975, 48.378],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-54.806, 31.598],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-64.203, 18.152],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-74.402, 8.092],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-86.14, 1.215],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-99.054, -3.3],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-112.229, -6.512],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-125.133, -9.022],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-137.437, -11.126],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-149.037, -13.056],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-159.991, -14.775],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-170.315, -16.373],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-179.944, -17.892],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-189.01, -19.308],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-197.58, -20.647],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-205.598, -21.92],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-213.151, -23.12],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-220.336, -24.261],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-227.041, -25.331],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-233.36, -26.388],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-239.355, -27.355],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-245.003, -28.277],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-250.37, -29.158],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.41, -30.005],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-260.2, -30.812],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-264.742, -31.557],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-268.979, -32.26],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-272.967, -32.964],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-276.83, -33.599],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-280.378, -34.209],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-283.795, -34.783],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-286.995, -35.354],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-290.046, -35.866],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-292.905, -36.34],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-295.555, -36.8],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-298.111, -37.239],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-300.458, -37.663],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-302.665, -38.04],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.796, -38.399],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-306.691, -38.758],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-308.524, -39.055],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-310.224, -39.361],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-311.782, -39.633],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-313.261, -39.889],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-314.6, -40.121],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-315.82, -40.352],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-316.952, -40.552],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-318.005, -40.734],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-318.912, -40.887],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-319.788, -41.032],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-320.471, -41.163],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-321.172, -41.278],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-321.724, -41.396],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.225, -41.462],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.674, -41.532],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.978, -41.575],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-323.225, -41.653],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-323.357, -41.663],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-323.474, -41.665],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-323.273, -41.512],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.902, -41.287],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-322.412, -40.964],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-321.751, -40.529],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-320.876, -39.975],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-319.791, -39.316],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-318.548, -38.451],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-317.024, -37.488],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-315.202, -36.322],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-313.15, -34.934],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-310.724, -33.377],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-307.983, -31.528],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-304.767, -29.418],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-301.172, -26.977],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-297.007, -24.132],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-292.264, -20.906],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-286.79, -17.114],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-280.545, -12.778],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-273.435, -7.648],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-265.208, -1.669],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-255.731, 5.352],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-244.916, 13.584],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-232.62, 23.254],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-218.869, 34.46],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-203.931, 47.104],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-188.334, 60.849],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-172.889, 75.094],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-156.668, 88.577],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-116.667, 87.208],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-80.303, 84.561],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-47.614, 80.98],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-18.387, 76.86],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.802, 72.531],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.291, 68.132],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [52.417, 63.821],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [71.374, 59.743],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [88.122, 56.262],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [102.239, 54.162],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [112.651, 54.855],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [120.276, 57.734],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [126.66, 61.407],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [132.399, 65.3],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [137.755, 69.138],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [142.769, 72.873],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [147.52, 76.462],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [152.011, 79.966],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [156.286, 83.236],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [160.364, 86.415],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [164.232, 89.491],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [167.923, 92.366],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [171.408, 95.118],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [174.772, 97.789],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [177.932, 100.259],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [180.956, 102.661],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [183.819, 104.911],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [186.569, 107.079],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [189.161, 109.172],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [191.626, 111.078],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [193.986, 112.955],
                            t: 240,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [196.214, 114.71],
                            t: 241,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [198.312, 116.411],
                            t: 242,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [200.326, 117.967],
                            t: 243,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [202.212, 119.468],
                            t: 244,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [203.98, 120.873],
                            t: 245,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [205.674, 122.209],
                            t: 246,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [207.255, 123.426],
                            t: 247,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [208.74, 124.629],
                            t: 248,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [210.148, 125.728],
                            t: 249,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [211.488, 126.76],
                            t: 250,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [212.727, 127.756],
                            t: 251,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [213.861, 128.625],
                            t: 252,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [214.932, 129.496],
                            t: 253,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [215.913, 130.288],
                            t: 254,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [216.843, 131.017],
                            t: 255,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [217.694, 131.674],
                            t: 256,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [218.453, 132.279],
                            t: 257,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [219.178, 132.844],
                            t: 258,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [219.807, 133.335],
                            t: 259,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [220.393, 133.812],
                            t: 260,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [220.885, 134.223],
                            t: 261,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.374, 134.58],
                            t: 262,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [221.772, 134.903],
                            t: 263,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.067, 135.143],
                            t: 264,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.383, 135.352],
                            t: 265,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.602, 135.529],
                            t: 266,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.782, 135.676],
                            t: 267,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [222.913, 135.799],
                            t: 268,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 270,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 15',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 15,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'rd',
                  nm: 'Round Corners 1',
                  r: { a: 0, k: 40, ix: 1 },
                  ix: 16,
                  mn: 'ADBE Vector Filter - RC',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group-Blend',
              np: 16,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 360,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Trifecta – Small',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [540, 540, 0], ix: 2, l: 2 },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1440,
      h: 880,
      ip: 0,
      op: 360,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
}
